import { NgModule } from "@angular/core";
import { CommonModule } from 'common';
import { SharedModule } from "../shared.module";
import { FinancialSummaryComponent } from "./financial-summary/financial-summary.component";
import { ApplicationSummaryComponent } from "./application-summary/application-summary.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule
  ],
  declarations: [
    FinancialSummaryComponent,
    ApplicationSummaryComponent
  ],
  exports: [
    FinancialSummaryComponent,
    ApplicationSummaryComponent
  ]
})
export class CashFlowAnalysisModule { }
