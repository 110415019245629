import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { ClientFilters, ClientQueryParams } from '../client.data';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ifb-client-list-filter-dialog',
  templateUrl: './client-list-filter-dialog.component.html',
  styleUrls: ['./client-list-filter-dialog.component.css']
})
export class ClientListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: ClientFilters;

  constructor(
    public dialogRef: MatDialogRef<ClientListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder) {

    this._unsubscribeAll = new Subject();
    this.filters = new ClientFilters(data.query);

    this.form = this.formBuilder.group({
      name: this.data.query.name || '',
      oidcAudience: this.data.query.oidcAudience || '',
      active: this.data.query.active || ''
    });
  }

  static show(dialog: MatDialog, query: ClientQueryParams): Observable<any> {
    return dialog.open(ClientListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new ClientFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
