import { QueryParams, QueryResult, Address } from "common";
import { AppBarExpandedTabWithPermission } from "../app.data";
import { readOnly } from "../user/user-permission/user-permission.data";

export interface CustomerData {
    id?: number;
    uniqueId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    ssn?: string;
    active?: boolean;
    loans?: number[];
    loanDetails?: LoanDetails[];
    pendingLoans?: number[];
    createdOn?: Date;
    updatedOn?: Date;
    kba?: KBAData;
    accountType?: string;
    status?: CustomerStatus;
    address?: Address;
    yearOfBarAdmission?: number;
    mobilePhone?: string;
    preferredPhone?: string;
    businessPhone?: string;
    homePhone?: string;
    birthdate?: Date;
    mobilePhoneUnverified?: string;
}

export interface KBAData {
    attemptCount?: number;
    lastError?: string;
    lastVerificationDate?: Date;
    status?: KBAStatus;
    lastConfigurationName: string;
}

export enum CustomerLoanType {
    Loans = 0,
    PendingLoans = 1,
}

export enum KBAStatus {
    None = 'none',
    Success = 'success',
    Failed = 'failed'
}

export const KBAStatusMap = new Map<string, string>([
    [KBAStatus.None, 'Pending'],
    [KBAStatus.Success, 'Verified'],
    [KBAStatus.Failed, 'Failed']
]);

export enum CustomerStatus {
    Inactive = 'inactive',
    Pending = 'pending',
    Registered = 'registered'
}

export const CustomerStatusMap = new Map<string, string>([
    [CustomerStatus.Inactive, 'Inactive'],
    [CustomerStatus.Pending, 'Pending'],
    [CustomerStatus.Registered, 'Registered']
]);

export interface CustomerInfoDto {
    uniqueId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    ssn?: string;
    accountType?: string;
}

export interface CustomerQueryParams extends QueryParams {
    email?: string;
    firstName?: string;
    lastName?: string;
    loanNumber?: string;
    businessName?: string;
    active?: boolean;
    ssn?: string;
}

export interface CustomerQueryResult extends QueryResult<CustomerData> {
}

export interface LoanDetails {
    id: number;
    loanNumber: string;
    businessName: string;
    active: boolean;
    status: string;
}

export const appBarExpandedTabs = {
    tabs: [
        {
            label: 'Profile',
            url: '/customer/profile'
        },
        {
            label: 'Notifications',
            url: '/customer/notifications'
        }
    ]
};

export interface CustomerHintData {
    id: number;
    email: string;
    ssn: string;
}

export interface CustomerHintParams {
    id?: number;
    email?: string;
    ssn?: string;
    sortField?: string;
}

export class CustomerFilters {
    email: any;
    firstName: string;
    lastName: string;
    loanNumber: any;
    businessName: any;
    active: any;
    ssn: any;

    constructor(filters?) {
        filters = filters || {};
        this.email = filters.email || undefined;
        this.firstName = filters.firstName || undefined;
        this.lastName = filters.lastName || undefined;
        this.loanNumber = filters.loanNumber || undefined;
        this.businessName = filters.businessName || undefined;
        this.active = filters.active || undefined;
        this.ssn = filters.ssn || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
    }
}

export const customerAppBarExpandedTabs = (customerId: number): AppBarExpandedTabWithPermission[] => [
    {
        label: 'Profile',
        url: `/customer/${customerId}/profile`,
        permission: [readOnly('servicing-customers')],
        hidden: true
    },
    {
        label: 'Applications',
        url: `/customer/${customerId}/applications`,
        permission: [readOnly('servicing-applications')],
        hidden: true
    }
];

export const customerSFAppBarExpandedTabs = (uniqueId: string): AppBarExpandedTabWithPermission[] => [
    {
        label: 'Profile',
        url: `/customer/sf/${uniqueId}/profile`,
        permission: [readOnly('servicing-customers')],
        hidden: true
    }
];

export interface CustomerComponentData {
    customer?: CustomerData;
    loans?: any[];
}
