import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BankAccount } from 'common';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class BankingService {
  constructor(
    private http: HttpClient,
    private settings: AppSettings
  ) { }

  private readonly baseUrl = `${this.settings.banking.url}`;

  synchronizeBankAccounts(entityId: number): Observable<BankAccount[]> {
    return this.http.put<BankAccount[]>(`${this.baseUrl}/api/banking/${entityId}/synchronize`, null);
  }

  synchronizeBankAccountExtraInfo(entityId: number, syncBankAccountId: number): Observable<BankAccount> {
    return this.http.post<BankAccount>(`${this.baseUrl}/api/banking/${entityId}/syncBankAccounts/${syncBankAccountId}/pull-extra-info`, null);
  }
}
