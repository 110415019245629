import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableHelper, FilterParameter } from 'common';
import { UserRoleFilters, UserRoleQueryParams } from './user-role.data';

@Injectable({
    providedIn: 'root'
})
export class UserRoleFilterService {

    constructor(
        private router: Router
    ) { }

    filter(filters: UserRoleFilters, paginator?: MatPaginator, sort?: MatSort) {
        const query = new UserRoleQueryParams(filters);

        // Set default to first page
        query.skip = undefined;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        this.router.navigate(['admin', 'role'], { replaceUrl: true, queryParams: query });
    }

    addFilterParameters(query: UserRoleQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.name) {
            filters.push({
                id: 'name', name: 'Name', value: query.name.trim()
            });
        }
        if (query.clientId) {
            filters.push({
                id: 'clientId', name: 'Client', value: query.clientId
            });
        }
        if (query.externalId) {
            filters.push({
                id: 'externalId', name: 'External ID', value: query.externalId.trim()
            });
        }
        if (query.active) {
            filters.push({
                id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
            });
        }

        return filters;

    }
}
