import { Component, Input } from '@angular/core';
import { KBAData } from '../../customer.model';

@Component({
  selector: 'ifb-customer-identity-verification-widget',
  templateUrl: './customer-identity-verification-widget.component.html',
  styleUrls: ['./customer-identity-verification-widget.component.scss']
})
export class IdentityVerificationWidgetComponent {

  @Input()
  data: KBAData;

  @Input()
  customerId: number;

  @Input()
  edit: boolean;

  constructor() { }

}
