import { parseNumber } from "../../helpers/parse.helper";
import { QueryParams, QueryResult } from "../../query/query.data";

export const BrokerApiKeysActiveLabel = new Map<boolean, string>([
  [true, "Active"],
  [false, "Inactive"],
]);

export const BrokerApiKeysActiveLabelValues = [
  { label: "Active", value: true },
  { label: "Inactive", value: false }
];

export interface BrokerApiKeyData {
  id: number;
  active?: boolean;
  brokerId: number;
  name: string;
  description?: string;
  clientId: string;
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface CreateBrokerApiKeySuccessData extends BrokerApiKeyData {
  clientSecret: string;
}

export interface CreateBrokerApiKeyData {
  active?: boolean;
  brokerId?: number;
  name: string;
  description?: string;
}

export interface BrokerApiKeyEditData {
  id: number;
  name?: string;
  description?: string;
  active?: boolean;
}

export interface BrokerApiKeyListInputData {
  dataSource: BrokerApiKeyQueryResult;
  writeAllowed: boolean;
  filterData: QueryParams
}

export interface BrokerApiKeyInputData {
  dataSource: BrokerApiKeyData | undefined;
  writeAllowed: boolean;
  isCreating: boolean;
}

export class BrokerApiKeyQueryParams extends QueryParams {
  brokerId: number;

  constructor(params?: any) {
    super(params);

    params = params || {};

    this.brokerId = parseNumber(params.brokerId);
  }
}

export interface BrokerApiKeyQueryResult
  extends QueryResult<BrokerApiKeyData> {}
