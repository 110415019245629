import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ifb-user-role-assignees-list',
  templateUrl: './user-role-assignees-list.component.html',
  styleUrls: ['./user-role-assignees-list.component.css']
})
export class UserRoleAssigneesListComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();
  noDataFound: boolean;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data
      .subscribe(result => {
        this.dataSource.data = result.userRoleAssignees.values;
        this.noDataFound = !(result.userRoleAssignees.totalCount > 0);
      });
  } 

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() { 
    this.dataSource.paginator = this.paginator;
  }

  tableColumns: string[] = [
    'clientName',
    'username',
    'externalId',
    'firstName',
    'middleName',
    'lastName',
    'lastLoginDate_IP',
    'status',
  ];
}  