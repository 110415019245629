<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md padding-bottom-0">
    <div class="card-title">Renewals</div>
    <button mat-icon-button routerLink="renewals" aria-label="Edit">
      <mat-icon class="edit-icon">edit</mat-icon>
    </button>
  </div>
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <span class="secondary-text" mat-line>Renewal for loan</span>
        <p class="item-content">
          <a routerLink="/account/{{data?.application?.renewalForLoanId}}/profile">
            {{data?.application?.renewalForLoanNumber}}
          </a>
        </p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Renewal ready</span>
        <p class="item-content">
          {{data?.application?.renewalReady ? 'Yes' : 'No'}}
        </p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
