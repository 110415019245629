import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SalesDashboardModule } from './sales-dashboard/sales-dashboard.module';
import { SignOutComponent } from './sign-out/sign-out.component';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    SalesDashboardModule
  ],
  declarations: [
    HomeComponent,
    SignOutComponent
  ]
})
export class HomeModule { }
