import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../../config/config.service";
import { BrokerApiKeyData, BrokerApiKeyEditData, BrokerApiKeyQueryParams, BrokerApiKeyQueryResult, CreateBrokerApiKeyData, CreateBrokerApiKeySuccessData } from "./broker-api-key.model";

@Injectable({
  providedIn: "root",
})
export class BrokerApiKeyRemote {
  private readonly brokerApiKeysUrl = `${this.configService.environment.brokers.url}/api/api-keys`;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  query(params: BrokerApiKeyQueryParams): Observable<BrokerApiKeyQueryResult> {
    return this.http.get<BrokerApiKeyQueryResult>(`${this.brokerApiKeysUrl}`, {
      headers: this.configService.environment.cache.longLived,
      params: <any>params,
    });
  }

  get(id: number): Observable<BrokerApiKeyData> {
    return this.http.get<BrokerApiKeyData>(`${this.brokerApiKeysUrl}/${id}`);
  }

  create(brokerApiKey: CreateBrokerApiKeyData): Observable<CreateBrokerApiKeySuccessData> {
    return this.http.post<CreateBrokerApiKeySuccessData>(`${this.brokerApiKeysUrl}`, brokerApiKey);
  }

  put(id: number, brokerApiKey: BrokerApiKeyEditData): Observable<BrokerApiKeyData> {
    return this.http.put<BrokerApiKeyData>(`${this.brokerApiKeysUrl}/${id}`, brokerApiKey);
  }

  delete(id: number): Observable<BrokerApiKeyData> {
    return this.http.delete<BrokerApiKeyData>(`${this.brokerApiKeysUrl}/${id}`);
  }
}
