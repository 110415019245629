import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import {
    AccountQueryResult,
    AccountData,
    AccountWithRenewalData,
    AccountAmortizationData,
    accountAppBarExpandedTabs,
    accountSFAppBarExpandedTabs,
    AccountModificationQueryResult,
    AccountStatementsData
} from "./account.model";
import { AccountService } from "./account.service";
import { AppBarExpandedTab, LoanInfoDto, AccountQueryParams, parseNumber } from "common";
import { UserPermissionService } from '../user/user-permission/user-permission.service';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { AuditQueryParams, AuditQueryResult } from "../shared/audit-trail/audit-trail.model";
import { readOnly } from "../user/user-permission/user-permission.data";
import { CustomerData } from "../customer/customer.model";
import { AuditTrailService } from "../shared/audit-trail/audit-trail.service";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Injectable({
    providedIn: 'root'
})
export class AccountAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            accountAppBarExpandedTabs(parseNumber(route.params.id)).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountSFAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            accountSFAppBarExpandedTabs(route.params.uniqueId).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountResolver implements Resolve<AccountData> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountWithRenewalData> | Promise<AccountWithRenewalData> | AccountWithRenewalData {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return this.service.getWithRenewal(parseNumber(id));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountUniqueIdResolver implements Resolve<AccountData> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountData> | AccountData {
        return this.service.getByUniqueId(route.params.uniqueId);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountInfoResolver implements Resolve<LoanInfoDto> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<LoanInfoDto> | Promise<LoanInfoDto> | LoanInfoDto {
        return this.service.getInfo(parseNumber(route.params.id));
    }
}


@Injectable({
    providedIn: 'root'
})
export class AccountInfoUniqueIdResolver implements Resolve<LoanInfoDto> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<LoanInfoDto> | Promise<LoanInfoDto> | LoanInfoDto {
        return this.service.getInfoByUniqueId(route.params.uniqueId);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountQueryResolver implements Resolve<AccountQueryResult> {
    constructor(private service: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountQueryResult> | Promise<AccountQueryResult> | AccountQueryResult {
        return this.service.query(new AccountQueryParams(this.queryParamsService.init(route.queryParams)));
    }
}


@Injectable({
    providedIn: 'root'
})
export class AccountAmortizationResolver implements Resolve<AccountAmortizationData> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountAmortizationData> | Promise<AccountAmortizationData> | AccountAmortizationData {
        return this.service.getAmortization(parseNumber(route.params.id));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountAmortizationUniqueIdResolver implements Resolve<AccountAmortizationData> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountAmortizationData> | Promise<AccountAmortizationData> | AccountAmortizationData {
        return this.service.getAmortizationByUniqueId(route.params.uniqueId);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountAuditTrailResolver implements Resolve<AuditQueryResult> {
    constructor(private service: AuditTrailService, private userPermissionService: UserPermissionService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<AuditQueryResult> | Promise<AuditQueryResult> | AuditQueryResult {
        const params = this.queryParamsService.init(new AuditQueryParams({ loan: route.params.id, ...route.queryParams }));
        return this.userPermissionService.granted([readOnly('admin-audit-log')])
            .pipe(
                map(res => res),
                switchMap(res => res ? this.service.audit(params) : [])
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountAuditTrailByUniqueIdResolver implements Resolve<AuditQueryResult> {
    constructor(private accountService: AccountService, private service: AuditTrailService, private userPermissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<AuditQueryResult> | Promise<AuditQueryResult> | AuditQueryResult {
        return this.accountService.getByUniqueId(route.params.uniqueId)
            .pipe(
                mergeMap(loan => {
                    const params = new AuditQueryParams({ 
                        ...route.queryParams, 
                        loan: loan.id
                    });
                    return this.userPermissionService.granted([readOnly('admin-audit-log')])
                        .pipe(
                            map(res => res),
                            switchMap(res => res ? this.service.audit(params) : [])
                        )
                })
            )
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountModificationListResolver implements Resolve<AccountModificationQueryResult> {
    constructor(private service: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountModificationQueryResult> | Promise<AccountModificationQueryResult> | AccountModificationQueryResult {
        return this.service.getAccountModificationList(
          parseNumber(route.params.id),
          this.queryParamsService.init(route.queryParams)
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountModificationListByUniqueIdResolver implements Resolve<AccountModificationQueryResult> {
    constructor(private service: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountModificationQueryResult> | Promise<AccountModificationQueryResult> | AccountModificationQueryResult {
        return this.service.getByUniqueId(route.params.uniqueId)
            .pipe(
                map(res => res),
                switchMap(res => res ? this.service.getAccountModificationList(res.id, this.queryParamsService.init(route.queryParams)) : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountByEntityQueryResolver implements Resolve<AccountQueryResult> {
    constructor(private service: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountQueryResult> | Promise<AccountQueryResult> | AccountQueryResult {
        return this.service.query(
          this.queryParamsService.init(new AccountQueryParams({...route.queryParams, entityId: route.params.id}))
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountOwnersResolver implements Resolve<CustomerData[]> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CustomerData[]> | Promise<CustomerData[]> | CustomerData[] {
        return this.service.getOwnersList(parseNumber(route.params.id));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountOwnersByUniqueIdResolver implements Resolve<CustomerData[]> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CustomerData[]> | Promise<CustomerData[]> | CustomerData[] {
        return this.service.getByUniqueId(route.params.uniqueId)
            .pipe(
                map(res => res),
                switchMap(res => res ? this.service.getOwnersList(res.id) : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountStatementResolver implements Resolve<AccountStatementsData> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountStatementsData> | Promise<AccountStatementsData> | AccountStatementsData {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;

        return this.service.getLoanStatementsGeneration(parseNumber(route.params.id)).pipe(map((data: AccountStatementsData) => {
            return {
                loanId: id,
                ...data
            }
        }));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountStatementByUniqueIdResolver implements Resolve<AccountStatementsData> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountStatementsData> | Promise<AccountStatementsData> | AccountStatementsData {
        return this.service.getByUniqueId(route.params.uniqueId)
            .pipe(
                map(res => res),
                switchMap(res => res ? this.service.getLoanStatementsGeneration(res.id).pipe(map((data: AccountStatementsData) => {
                    return {
                        loanId: res.id,
                        ...data
                    }
                })) : null)
            );
    }
}