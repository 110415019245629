import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { MatIconChipComponent } from './mat-icon-chip/mat-icon-chip.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [MatIconChipComponent],
  declarations: [MatIconChipComponent]
})
export class MaterialModule { }
