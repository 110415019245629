import { EntityType, EntityStatus, parseNumber } from "common";
import { readOnly } from "../user/user-permission/user-permission.data";
import { AppBarExpandedTabWithPermission } from "../app.data";

export const EntityTypeLabel = new Map<string, string>([
    [EntityType.SoleProprietorship, 'Sole Proprietorship'],
    [EntityType.LimitedLiabilityCompany, 'Limited Liability Company'],
    [EntityType.Corporation, 'Corporation'],
    [EntityType.LimitedPartnership, 'Limited Partnership'],
    [EntityType.GeneralPartnership, 'General Partnership'],
    [EntityType.NotForProfit, 'Not for Profit'],
    [EntityType.Public, 'Public'],
    [EntityType.LegalPartnership, 'Legal Partnership'],
    [EntityType.Other, 'Other']
]);

export const EntityStatusLabel = new Map<string, string>([
    [EntityStatus.Active, 'Active - Not Contacted'],
    [EntityStatus.Terminated, 'Terminated'],
    [EntityStatus.Submitted, 'Submitted'],
    [EntityStatus.Processing, 'Processing'],
    [EntityStatus.Approved, 'Approved'],
    [EntityStatus.Closed, 'Closed'],
    [EntityStatus.ActiveNurture, 'Active - Nurture'],
    [EntityStatus.ActiveSalesAdmin, 'Active - Sales Admin'],
    [EntityStatus.ActiveBusinessAdvisor, 'Active - Business Advisor'],
    [EntityStatus.InReview, 'In Review'],
    [EntityStatus.MissingInfo, 'Missing Info'],
    [EntityStatus.Probation, 'Probation'],
    [EntityStatus.Modification, 'Modification'],
	[EntityStatus.Workout, 'Workout'],
	[EntityStatus.Bankruptcy, 'Bankruptcy'],
    [EntityStatus.CaseSettled, 'Case Settled'],
	[EntityStatus.Litigation, 'Litigation'],
	[EntityStatus.Delinquent, 'Delinquent'],
	[EntityStatus.PreferredPartner, 'Preferred Partner'],
	[EntityStatus.Reassign, 'Reassign']
]);

export interface EntityHintData {
    businessName: string;
    number: string;
    ein: string;
    id: number;
}

export interface EntityHintParams {
    number?: string;
    businessName?: string;
    ein?: string;
    sortField?: string;
    uniqueId?: string;
    id?: number;
}

export interface CreditData {
    paynet?: number;
    equifax?: number;
}

export const entityAppBarExpandedTabs = (entityId: number): AppBarExpandedTabWithPermission[] => [
    {
        label: 'Profile',
        url: `/company/${entityId}/profile`,
        permission: [readOnly('servicing-entities')],
        hidden: true
    },
    {
        label: 'Accounts',
        url: `/company/${entityId}/accounts`,
        permission: [readOnly('servicing-accounts')],
        hidden: true
    },
    {
        label: 'Applications',
        url: `/company/${entityId}/applications`,
        permission: [readOnly('servicing-applications')],
        hidden: true
    },
    {
        label: 'Bank Accounts',
        url: `/company/${entityId}/banks`,
        permission: [readOnly('servicing-bank-accounts')],
        hidden: true
    },
    {
        label: 'Bank analysis',
        url: `/company/${entityId}/bank-analysis`,
        permission: [readOnly('servicing-bank-analysis'), readOnly('servicing-entities-sensitive-data')],
        allPermissionsRequired: true,
        hidden: true
    },
    {
        label: 'Audit Trail',
        url: `/company/${entityId}/audit`,
        permission: [readOnly('admin-audit-log')],
        hidden: true
    }
];

export class EntityFilters {
    uniqueId: string;
    bankIntegrationUsername: string;
    advisorId: number;
    createdBy: any;
    updatedBy: any;
    types: EntityType[];
    statuses: EntityStatus[];
    number: any;
    businessName: any;
    createdOn: Date;
    createdOnStart: Date;
    createdOnEnd: Date;
    updatedOn: Date;
    updatedOnStart: Date;
    updatedOnEnd: Date;
    active: boolean;
    bankLinkStatus: any;
    lastLinkingAttemptDate: Date;
    lastLinkingAttemptDateFrom: Date;
    lastLinkingAttemptDateTo: Date;
    bankLinkAttempted: boolean;
    isVerified?: boolean;
    ein: any;

    constructor(filters?) {
        filters = filters || {};
        this.uniqueId = filters.uniqueId || undefined;
        this.bankIntegrationUsername = filters.bankIntegrationUsername || undefined;
        this.advisorId = filters.advisorId || undefined;
        this.createdBy = filters.createdBy || undefined;
        this.updatedBy = filters.updatedBy || undefined;
        this.number = filters.number || undefined;
        this.types = filters.types || undefined;
        this.statuses = filters.statuses || undefined;
        this.businessName = filters.name || undefined;
        this.createdOn = filters.createdOn || undefined;
        this.createdOnStart = filters.createdOnStart || undefined;
        this.createdOnEnd = filters.createdOnEnd || undefined;
        this.updatedOn = filters.updatedOn || undefined;
        this.updatedOnStart = filters.updatedOnStart || undefined;
        this.updatedOnEnd = filters.updatedOnEnd || undefined;
        this.active = filters.active === 'true' ? true : (filters.active === 'false' ? false : undefined);
        this.bankLinkStatus = parseNumber(filters.bankLinkStatus);
        this.lastLinkingAttemptDate = filters.lastLinkingAttemptDate || undefined;
        this.lastLinkingAttemptDateFrom = filters.lastLinkingAttemptDateFrom || undefined;
        this.lastLinkingAttemptDateTo = filters.lastLinkingAttemptDateTo || undefined;
        this.bankLinkAttempted = filters.bankLinkAttempted === 'true' ? true : (filters.bankLinkAttempted === 'false' ? false : undefined);
        this.isVerified = filters.isVerified === 'true' ? true : (filters.isVerified === 'false' ? false : undefined);
        this.ein = filters.ein || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'createdOn' || item === 'createdOnStart') {
            this.createdOn = undefined;
            this.createdOnStart = undefined;
            this.createdOnEnd = undefined;
        }

        if (item === 'lastLinkingAttemptDate' || item === 'lastLinkingAttemptDateFrom') {
            this.lastLinkingAttemptDate = undefined;
            this.lastLinkingAttemptDateFrom = undefined;
            this.lastLinkingAttemptDateTo = undefined;
        }
    }
}
