import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { AppBarTitleService, AppBarActionsService, FormHelper, MessageService, EnumHelper, AppBarAction, states, EntityStatus, AppPageService } from 'common';
import { BrokerTier, BrokerType, BrokerTypeLabel, BrokerBaseData } from '../broker.model';
import { BrokerService } from '../broker.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { BrokerCreateDialogComponent } from '../broker-create-dialog/broker-create-dialog.component';

@Component({
  selector: 'ifb-broker-details',
  templateUrl: './broker-details.component.html',
  styleUrls: ['./broker-details.component.scss']
})
export class BrokerDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  form: UntypedFormGroup;
  canEditSensitiveData: boolean;
  readWriteBrokers = false;

  brokerStatusOptions = EnumHelper.getNamesAndValues(EntityStatus);
  brokerTierOptions = EnumHelper.getNamesAndValues(BrokerTier);
  brokerTypeOptions = EnumHelper.getMappedNamesAndValues(BrokerType, BrokerTypeLabel);

  get states() { return states; }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private brokerService: BrokerService,
    private userPermissionService: UserPermissionService,
    private messageService: MessageService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.form = this.formBuilder.group({
      name: [undefined, Validators.compose([Validators.required, Validators.maxLength(255)])],
      dba: [undefined, Validators.maxLength(120)],
      ein: [{ value: undefined, disabled: !this.canEditSensitiveData }],
      brokerId: [undefined, Validators.maxLength(10)],
      tier: [undefined],
      type: [undefined],
      phone: undefined,
      contactEmail: [undefined, Validators.compose([Validators.email, Validators.maxLength(80)])],
      commissionEmail: [undefined, Validators.compose([Validators.email, Validators.maxLength(80)])],
      website: undefined,
      line1: [undefined, Validators.required],
      line2: undefined,
      city: [undefined, Validators.compose([Validators.required, Validators.maxLength(60)])],
      state: [undefined, Validators.required],
      zip: [undefined, Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-?\d{4})?$/)])],
      active: [undefined, Validators.required],
      status: [undefined]
    });
  }

  ngOnInit() {
    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.userPermissionService.granted([readWrite('brokers-brokers-sensitive-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canEditSensitiveData = res;
        if (this.canEditSensitiveData) {
          this.form.controls.ein.setValidators(Validators.compose([Validators.minLength(9), Validators.maxLength(10)]));
          this.form.controls.ein.enable();
        } else {
          this.form.controls.ein.clearValidators();
          this.form.controls.ein.disable();
        }
      });

      this.userPermissionService.granted([readWrite('brokers-brokers')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.readWriteBrokers = res;
      });

    this.appBarTitleService.title = 'Add broker';
    this.onChanges();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty && this.readWriteBrokers) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  cancel() {
    this.appPageService.back();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);

    if (this.saveSub)
      return;

    if (!this.form.valid)
      return;

      BrokerCreateDialogComponent.show(this.dialog, this.form)
      .subscribe(result => {
        if (result) {
          const updateCommand: BrokerBaseData = {
            name: this.form.value.name,
            dba: this.form.value.dba,
            ein: this.form.value.ein,
            brokerId: this.form.value.brokerId,
            tier: this.form.value.tier,
            type: this.form.value.type,
            phone: this.form.value.phone,
            contactEmail: this.form.value.contactEmail,
            commissionEmail: this.form.value.commissionEmail,
            webSite: this.form.value.website,
            address: {
              line1: this.form.value.line1,
              line2: this.form.value.line2,
              city: this.form.value.city,
              state: this.form.value.state,
              zip: this.form.value.zip
            },
            status: this.form.value.status,
            active: this.form.value.active
          };
          this.saveSub = this.brokerService.create(updateCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }
}
