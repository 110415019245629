import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppBarActionsComponent,
  AppBarComponent,
  AppBarExpandedTabsComponent,
  AppBarTitleComponent,
  AppPageComponent,
  AppSidenavComponent,
} from "common";
import { appPageComponentDataDetail, appPageComponentDataMaster } from "../../app.data";
import { AppPageResolver, AppSidenavResolver } from "../../app.resolver";
import { UserMenuComponent } from "../../user/user-menu/user-menu.component";
import { AdminAppBarExpandedTabsResolver } from "../admin.resolver";
import { PromotionListResolver, PromotionResolver } from "./promotion.resolver";
import { PromotionListComponent } from "./promotion-list/promotion-list.component";
import { PromotionDetailsComponent } from "./promotion-details/promotion-details.component";
import { BOAuthGuard } from "../../guards/oauth.guard";

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: "Admin",
  sideNavItem: "admin",
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
      {
        path: "admin/promotions",
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
        },
        children: [
          {
            path: "",
            outlet: "sidenav-content",
            component: AppSidenavComponent,
          },
          {
            path: "",
            component: AppBarComponent,
            outlet: "app-bar",
            children: [
              {
                path: "",
                outlet: "title",
                component: AppBarTitleComponent,
              },
              {
                path: "",
                outlet: "right",
                component: UserMenuComponent,
              },
              {
                path: "",
                outlet: "expanded",
                component: AppBarExpandedTabsComponent,
              },
            ],
          },
          {
            path: "",
            component: PromotionListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              promotionListResult: PromotionListResolver,
            },
          },
        ],
      },
      {
        path: 'admin/promotions/:id',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: PromotionDetailsComponent,
            resolve: {
              promotion: PromotionResolver
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PromotionRoutingModule { }
