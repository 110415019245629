<div class="layout" fxLayout="column" fxLayoutAlign="center center" fxFlexFill.lt-lg
  ngClass.lt-lg="fit-content" fxFlex="100">

  <form [formGroup]="form" ngClass.xs="layout fill">

    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Status </mat-label>
          <mat-select matInput formControlName="status" placeholder="Status">
            <mat-option *ngFor="let option of brokerStatusOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <ifc-datepicker [fc]="form.get('contractDate')" [label]="'Contract date'" [disabled]="false"></ifc-datepicker>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <ifc-datepicker [fc]="form.get('createdOn')" [label]="'Created on'" [disabled]="true"></ifc-datepicker>
      </div>

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Created by </mat-label>
          <input matInput type="text" name="createdBy" formControlName="createdBy">
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <ifc-datepicker [fc]="form.get('updatedOn')" [label]="'Updated on'" [disabled]="true"></ifc-datepicker>
      </div>

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Updated By </mat-label>
          <input matInput type="text" name="updatedBy" formControlName="updatedBy">
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Active </mat-label>
          <mat-select matInput formControlName="active" placeholder="Active">
            <mat-option [value]="true"> Active </mat-option>
            <mat-option [value]="false"> Inactive </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

  </form>

</div>