<ifc-app-scroll-container class="ifc-app-scroll-container-center">
  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <form [formGroup]="form" ngClass.xs="layout fill">

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">
        <mat-form-field appearance="outline" fxFlex="48">
          <mat-label>Status</mat-label>
          <mat-select matInput formControlName="status" placeholder="Status">
            <mat-option *ngFor="let option of kbaStatusOptions" [value]="option.value">{{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Total attempts</mat-label>
          <input matInput type="text" formControlName="attemptCount">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">
        <mat-form-field appearance="outline">
          <mat-label>Last verification date</mat-label>
          <input matInput type="text" formControlName="lastVerificationDate">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last configuration</mat-label>
          <input matInput type="text" formControlName="lastConfigurationName">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl" class="scroll-margin-bottom">
        <mat-form-field appearance="outline">
          <mat-label>Last error</mat-label>
          <textarea matInput rows="3" formControlName="lastError"></textarea>
        </mat-form-field>
      </div>

    </form>
  </div>
</ifc-app-scroll-container>
