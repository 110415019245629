import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper } from "../../helpers/enum.helper";
import { PaymentFrequency, PaymentFrequencyLabel } from "../application.model";

@Pipe({
  name: "paymentFrequencyLabel",
})
export class PaymentFrequencyLabelPipe implements PipeTransform {
  transform(value: PaymentFrequency): string {
    return EnumHelper.getMappedNameFromValue(
      PaymentFrequency,
      PaymentFrequencyLabel,
      value
    );
  }
}
