<div fxLayout="column" fxLayoutAlign="stretch">

  <div class="filter-toolbar" fxLayoutAlign="end center">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div class="layout padding-right-md">
        <button *ngIf="canUpdateModification" mat-icon-button class="secondary-text" routerLink="details"
          matTooltip="Edit">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <ifb-account-notes-sidenav [accountId]="data.account?.id" [notes]="data.account?.notes" [initiator]="data.userCurrent"
    [saveOnDemand]="true" [innerSidenavAndTabbed]="true">

    <ifc-app-scroll-container [default]="true" [@routerTransition]="'fly'">
      <div class="table-container">
        <div class="message-box info" *ngIf="!data.accountModificationQueryResult.totalCount" fxLayout="row"
          fxLayoutGap="12px" fxLayoutAlign="start center">
          <mat-icon class="icon">info</mat-icon>
          <span>No data found</span>
        </div>

        <table *ngIf="data.accountModificationQueryResult.totalCount" mat-table
          [dataSource]="data.accountModificationQueryResult.values">

          <ng-container matColumnDef="placeholder">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="table-cell"></td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Start date</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.startDate | date: 'mediumDate'}} </td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>End date</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.endDate | date: 'mediumDate'}} </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Active</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.active}} </td>
          </ng-container>

          <ng-container matColumnDef="activatedOn">
            <th mat-header-cell *matHeaderCellDef>Activated on</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.activatedOn | date: 'mediumDate'}} </td>
          </ng-container>

          <ng-container matColumnDef="autoPaymentMode">
            <th mat-header-cell *matHeaderCellDef>Auto payment mode</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{getAutopaymentMode(row.autoPaymentMode)}} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef>Rate</th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              <div *ngIf="row.interestRate"> {{row.interestRate| number: '1.2-2'}}%</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="accureInterest">
            <th mat-header-cell *matHeaderCellDef>Accrue interest</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.accrueInterest}} </td>
          </ng-container>

          <ng-container matColumnDef="reAmortize">
            <th mat-header-cell *matHeaderCellDef>Re-amortize</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.reAmortized}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentAmount">
            <th mat-header-cell *matHeaderCellDef>Payment amount</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.paymentAmount | currency}} </td>
          </ng-container>

          <ng-container matColumnDef="repaymentTerm">
            <th mat-header-cell *matHeaderCellDef>Repayment term</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.repaymentTerm}} </td>
          </ng-container>

          <ng-container matColumnDef="drawFee">
            <th mat-header-cell *matHeaderCellDef>Draw fee</th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              <div *ngIf="row.drawFee && row.drawFeeType !== FeeType.Flat">{{row.drawFee | number: '1.2-2'}}%</div>
              <div *ngIf="row.drawFeeType === FeeType.Flat">{{row.drawFee | currency}}</div>
          </ng-container>

          <ng-container matColumnDef="updatedOn">
            <th mat-header-cell *matHeaderCellDef>Updated on</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{row.updatedOn | date : 'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>Notes</th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              <div *ngFor="let note of row.notes">
                <span class="note-content">{{note.text}}</span>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true;" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns;" class="table-row"></tr>

          <ng-container matColumnDef="groupHeader">
            <td colspan="999" mat-cell *matCellDef="let groupBy">{{groupBy.label}}</td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="table-row group-label">
          </tr>

        </table>
      </div>
    </ifc-app-scroll-container>

    <div [hidden]="data.accountModificationQueryResult.totalCount <= 10">
      <mat-paginator #paginator showFirstLastButtons [length]="data.accountModificationQueryResult.totalCount"
        [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)">
      </mat-paginator>
    </div>

  </ifb-account-notes-sidenav>

</div>
