import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper } from "../../helpers/enum.helper";
import { OfferBundleStatus, OfferBundleStatusLabel } from "../models/offer.model";

@Pipe({
  name: "offerBundleStatusLabel",
})
export class OfferBundleStatusLabelPipe implements PipeTransform {
  transform(value: OfferBundleStatus): string {
    return EnumHelper.getMappedNameFromValue(
      OfferBundleStatus,
      OfferBundleStatusLabel,
      value
    );
  }
}
