import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApplicationStatus, LinkingStatus } from 'common';
import { ApplicationData } from '../application.model';

@Component({
  selector: 'ifb-application-app-bar-expanded',
  templateUrl: './application-app-bar-expanded.component.html',
  styleUrls: ['./application-app-bar-expanded.component.scss']
})
export class ApplicationAppBarExpandedComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: ApplicationAppBarExpandedComponentData;
  message: string;
  showError: boolean;
  showWarning: boolean;
  showSuccess: boolean;

  @ViewChild('horizontalStepper', { static: true }) horizontalStepper;

  constructor(private route: ActivatedRoute) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: ApplicationAppBarExpandedComponentData) => { this.data = it; });
    this.dataInit();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dataInit() {
    const application = this.data.application;

    switch (application.sfAppStatus) {
      case ApplicationStatus.ApplicationCreated:
      case ApplicationStatus.Processing:
        this.horizontalStepper.selectedIndex = 0;
        break;
      case ApplicationStatus.ApplicationIncomplete:
        this.horizontalStepper.selectedIndex = 0;
        break;
      case ApplicationStatus.ConditionalOffer:
      case ApplicationStatus.Underwriting:
      case ApplicationStatus.UnderwritingReview:
        this.horizontalStepper.selectedIndex = 1;
        break;
      case ApplicationStatus.UnderwritingMissingInfo:
        this.horizontalStepper.selectedIndex = 1;
        this.message = 'Check for missing information in application.';
        this.showError = true;
        break;
      case ApplicationStatus.Approved:
        this.horizontalStepper.selectedIndex = 2;
        this.message = 'Application approved. Send out offer.';
        this.showSuccess = true;
        break;
      case ApplicationStatus.Accepted:
      case ApplicationStatus.UnderwritingCall:
      case ApplicationStatus.Closing:
      case ApplicationStatus.SalesReview:
      case ApplicationStatus.ClosingIncomplete:
      case ApplicationStatus.PendingSignature:
      case ApplicationStatus.ApplicationComplete:
      case ApplicationStatus.Dormant:
        this.horizontalStepper.selectedIndex = 2;
        break;
      case ApplicationStatus.Declined:
        this.horizontalStepper.selectedIndex = 2;
        this.message = 'Application declined.';
        this.showWarning = true;
        break;
    }

    if (application.bankLinkStatus !== LinkingStatus.Linked) {
      this.message = 'Bank link required to continue application process.';
      this.showError = true;
    }
  }
}

export interface ApplicationAppBarExpandedComponentData {
  application: ApplicationData;
}
