import { Component, Input } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AdvisorHintData } from '../business-advisor.model';
import { AdvisorService } from '../../../advisor/advisor.service';
import { AutocompleteComponent } from 'common';

@Component({
  selector: 'ifb-business-advisor-input',
  templateUrl: '../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class BusinessAdvisorInputComponent extends AutocompleteComponent {

  constructor(private businessAdvisorService: AdvisorService) {
    super();
  }

  label = 'Business advisor';

  @Input()
  showAvatar = true;

  optionFilter: (input: string | AdvisorHintData) => Observable<any[]> =
    input => input
      ? this.businessAdvisorService.queryHints({
        fullName: !!input ? (typeof input !== 'string' ? input.fullName : input) : null,
        sortField: 'fullName'
      })
      : EMPTY;

  optionLabelKey = 'fullName';
}
