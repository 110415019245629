import { Component, OnInit, Input } from '@angular/core';
import { BankLinkStatusLabels, BankVerificationLabels, VerificationStatus } from '../bank.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { takeUntil } from 'rxjs/operators';
import { readOnly } from '../../user/user-permission/user-permission.data';
import { Subject } from 'rxjs';
import { LinkingStatus, EntityData } from 'common';

@Component({
  selector: 'ifb-bank-linking-info-widget',
  templateUrl: './bank-linking-info-widget.component.html',
  styleUrls: ['./bank-linking-info-widget.component.css']
})
export class BankLinkingInfoWidgetComponent implements OnInit {

  constructor(
    private _userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  private _unsubscribeAll: Subject<any>;

  canReadBankAccountVerificationStatus: boolean;

  get BankLinkStatusLabels() { return BankLinkStatusLabels; }
  get BankLinkStatus() { return LinkingStatus; }
  get BankVerificationLabels() { return BankVerificationLabels; }
  get VerificationStatus() { return VerificationStatus; }

  @Input()
  entity: EntityData;

  ngOnInit() {
    this._userPermissionService.granted([readOnly('servicing-bank-accounts-verification')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canReadBankAccountVerificationStatus = res);
  }
}
