import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { FeeType, EnumHelper, LoanStatus } from 'common';
import { AutoPaymentModeMap } from '../account.model';

@Component({
  selector: 'ifb-account-modification-update-dialog',
  templateUrl: './account-modification-update-dialog.component.html',
  styleUrls: ['./account-modification-update-dialog.component.scss']
})
export class AccountModificationUpdateDialogComponent {

  actionGerund: string;
  autoPaymentMode: string;
  maintenanceFeeType: string;
  drawFeeType: string;
  loanStatus: string;

  get FeeType() { return FeeType; }

  constructor(public dialogRef: MatDialogRef<AccountModificationUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.actionGerund = this.data.action.substring(0, this.data.action.length - 1) + "ing";
    this.autoPaymentMode = AutoPaymentModeMap.get(this.data.modificationForm.value.autoPaymentMode);
    this.maintenanceFeeType = EnumHelper.getNameFromValue(FeeType, this.data.modificationForm.value.maintenanceFeeType);
    this.drawFeeType = EnumHelper.getNameFromValue(FeeType, this.data.modificationForm.value.drawFeeType);
    this.loanStatus = EnumHelper.getNameFromValue(LoanStatus, this.data.loanForm.value.loanStatus);
  }

  // eslint-disable-next-line max-len
  static show(dialog: MatDialog, loanNumber: string, modificationForm: UntypedFormGroup, loanForm: UntypedFormGroup, action: string, notes: any[], warning: string): Observable<any> {
    return dialog
      .open(AccountModificationUpdateDialogComponent, {
        width: '510px',
        data: {
          loanNumber: loanNumber,
          modificationForm: modificationForm,
          loanForm: loanForm,
          action: action,
          notes: notes,
          warning: warning
        },
        closeOnNavigation: true
      })
      .afterClosed();
  }

  getAccrueInterest(val: boolean) {
    if (val === undefined)
      return 'Do not override';
    return val ? 'Accrue interest' : 'Do not accrue interest';
  }

}
