import { Pipe, PipeTransform } from "@angular/core";
import { PromotionType } from "./promotion.model";

@Pipe({
  name: "promotionTypeName",
})
export class PromotionTypeNamePipe implements PipeTransform {
  transform(value: PromotionType): string {
    switch (value) {
      case PromotionType.DrawFeeReduction:
        return "Draw fee reduce";
      case PromotionType.DrawFeeOverride:
        return "Draw fee override";
      case PromotionType.ReAmortization:
        return "Loan reamortization";
      case PromotionType.PayoffInterestReduction:
        return "Payoff interest rate discount";
      default:
        return "Unknown";
    }
  }
}
