import { MatDialog } from "@angular/material/dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { EMPTY, Observable, ReplaySubject } from "rxjs";
import { catchError, debounceTime, take } from "rxjs/operators";
import { MatSelect } from "@angular/material/select";
import {
  CompetitorBase,
  CompetitorHintData,
} from "../../models/competitor.model";
import { CompetitorService } from "../../competitor.service";
import { UserPermissionService } from "../../../user/user-permission/user-permission.service";
import { readWrite } from "../../../user/user-permission/user-permission.data";
import { CompetitorsSelectAddDialogComponent } from "./components/competitors-select-add-dialog/competitors-select-add-dialog.component";
import { CompetitorFormStatusData } from "../competitor-form/models/competitor-form-status-data.model";
import { MessageService } from "common";

@UntilDestroy()
@Component({
  selector: "ifb-competitors-select-search",
  templateUrl: "./competitors-select-search.component.html",
  styleUrls: ["./competitors-select-search.component.scss"],
})
export class CompetitorsSelectSearchComponent implements OnInit {
  readWriteCompetitor: boolean;

  @Input()
  selectedCompetitorIds: number[] = [];

  @Input()
  disabled: boolean;

  @Output()
  competitorsChange: EventEmitter<CompetitorHintData[]> = new EventEmitter();

  public competitorsSelectForm: FormControl<CompetitorHintData[]> =
    new FormControl<CompetitorHintData[]>([]);

  public competitorsFilterForm: FormControl<string> = new FormControl<string>(
    ""
  );

  public filteredCompetitors: ReplaySubject<CompetitorHintData[]> =
    new ReplaySubject<CompetitorHintData[]>(1);

  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;

  constructor(
    private competitorService: CompetitorService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getCompetitors().subscribe((data) => {
      const competitors = data?.filter((competitor) =>
        this.selectedCompetitorIds?.some((id) => id === competitor?.id)
      );
      this.initCompetitorsSelectForm(competitors);
      this.initFilteredCompetitors(data);
    });

    this.initCompetitorsFilterForm();
    this.initPermission();
  }

  private initPermission(): void {
    this.userPermissionService
      .granted([readWrite("admin-competitors")])
      .subscribe((res) => {
        this.readWriteCompetitor = res;
      });
  }

  private getCompetitors(
    name: string = null
  ): Observable<CompetitorHintData[]> {
    return this.competitorService.getHints({ sortField: "name", name });
  }

  private initCompetitorsSelectForm(competitors: CompetitorHintData[]) {
    this.competitorsSelectForm.setValue(competitors);

    this.competitorsSelectForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((selectedCompetitors) => {
        this.competitorsChange.emit(selectedCompetitors);
      });
  }

  private initFilteredCompetitors(data: CompetitorHintData[]) {
    this.refreshFilteredCompetitors(data.slice());

    this.filteredCompetitors
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.multiSelect.compareWith = (
          a: CompetitorHintData,
          b: CompetitorHintData
        ) => a && b && a.id === b.id;
      });
  }

  private initCompetitorsFilterForm() {
    this.competitorsFilterForm.valueChanges
      .pipe(debounceTime(600), untilDestroyed(this))
      .subscribe(() => {
        this.filterCompetitors();
      });
  }

  private filterCompetitors() {
    const searchValue = this.competitorsFilterForm.value;

    this.getCompetitors(searchValue).subscribe((data) => {
      this.refreshFilteredCompetitors(data);
    });
  }

  private refreshFilteredCompetitors(data: CompetitorHintData[]) {
    this.filteredCompetitors.next(data);
  }

  onClickAdd(): void {
    CompetitorsSelectAddDialogComponent.show(this.dialog).subscribe(
      (data: CompetitorFormStatusData) => {
        if (data) {
          this.saveCompetitor(data);
        }
      }
    );
  }

  private saveCompetitor(data: CompetitorFormStatusData): void {
    if (!data?.isValid || !data?.value) {
      this.messageService.error("Invalid competitor form data");
      return;
    }
    this.addCompetitorRequest(data.value);
  }

  private addCompetitorRequest(requestData: CompetitorBase): void {
    this.competitorService
      .addCompetitor(requestData)
      .pipe(
        catchError((error) => {
          return this.handleSaveError(error);
        })
      )
      .subscribe(() => this.filterCompetitors());
  }

  private handleSaveError(error: unknown): Observable<never> {
    this.messageService.error(error);
    return EMPTY;
  }
}
