import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatTableHelper, EnumHelper, LinkingStatus, ApplicationQueryParams, FilterParameter, parseNumber } from 'common';
import { ApplicationFilters } from './application.model';
import { TenantService } from '../admin/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationFilterService {

  constructor(private router: Router, private tenantService: TenantService) { }

  filter(filters: ApplicationFilters, paginator?: MatPaginator, sort?: MatSort, entityId?: number, customerId?: number) {
    const query = new ApplicationQueryParams(filters);

    // Set default to first page
    query.skip = undefined;

    query.businessName = (filters.businessName && filters.businessName.businessName)
      ? filters.businessName.businessName : filters.businessName;
    query.loanNumber = (filters.loanNumber && filters.loanNumber.loanNumber)
      ? filters.loanNumber.loanNumber : filters.loanNumber;
    query.applicationNumber = (filters.applicationNumber && filters.applicationNumber.applicationNumber)
      ? filters.applicationNumber.applicationNumber : filters.applicationNumber;
    query.createdOnStart = filters.createdOnStart ? moment(filters.createdOnStart).format('L') : undefined;
    query.createdOnEnd = filters.createdOnEnd ? moment(filters.createdOnEnd).format('L') : undefined;
    query.lastLinkingAttemptDateFrom = filters.lastLinkingAttemptDateFrom
      ? moment(filters.lastLinkingAttemptDateFrom).format('L') : undefined;
    query.lastLinkingAttemptDateTo = filters.lastLinkingAttemptDateTo ? moment(filters.lastLinkingAttemptDateTo).format('L') : undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    if (entityId)
      this.router.navigate([`company/${entityId}/applications`], { replaceUrl: true, queryParams: query });
    else if (customerId)
      this.router.navigate([`customer/${customerId}/applications`], { replaceUrl: true, queryParams: query });
    else
      this.router.navigate(['application'], { replaceUrl: true, queryParams: query });
  }

  addFilterParameters(query: ApplicationQueryParams): FilterParameter[] {
    const filters: FilterParameter[] = [];

    if (query.businessName) {
      filters.push({
        id: 'businessName', name: 'Legal entity name', value: query.businessName.trim()
      });
    }
    if (query.applicationNumber) {
      filters.push({
        id: 'applicationNumber', name: 'Application number', value: query.applicationNumber.trim()
      });
    }
    if (query.loanNumber) {
      filters.push({
        id: 'loanNumber', name: 'Loan number', value: query.loanNumber.trim()
      });
    }
    if (query.stage != undefined) {
      filters.push({
        id: 'stage', name: 'Stage', value: query.stage
      });
    }
    if(query.companyId) {
      this.tenantService.names().subscribe(data => {
        filters.push({
          id: 'companyId', name: 'Brand', value: data.find(e => e.id === parseNumber(query.companyId))?.name ?? query.companyId
        })
      });
    }
    if (query.bankLinkAttempted) {
      filters.push({
        id: 'bankLinkAttempted', name: 'Bank link attempted', value: query.bankLinkAttempted
      });
    }
    if (query.createdOnStart) {
      filters.push({
        id: 'createdOnStart', name: 'Created on',
        value: `${moment(query.createdOnStart).format('L')} - ${moment(query.createdOnEnd).format('L')}`
      });
    }
    if (query.lastLinkingAttemptDateFrom) {
      filters.push({
        id: 'lastLinkingAttemptDateFrom', name: 'Last linking attempt date',
        value: `${moment(query.lastLinkingAttemptDateFrom).format('L')} - ${moment(query.lastLinkingAttemptDateTo).format('L')}`
      });
    }
    if (+query.bankLinkStatus >= 0) {
      filters.push({
        id: 'bankLinkStatus', name: 'Bank link status',
        value: _.startCase(EnumHelper.getNameFromValue(LinkingStatus, +query.bankLinkStatus))
      });
    }

    return filters;
  }
}
