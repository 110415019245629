import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { states } from "common";
import { CompetitorBase } from "../../models/competitor.model";
import { getCompetitorFormModel } from "./data/competitor-form-data";
import { updatedFieldsData } from "./data/competitor-form-update-field-data";
import { CompetitorFormStatusData } from "./models/competitor-form-status-data.model";

@UntilDestroy()
@Component({
  selector: "ifb-competitor-form",
  templateUrl: "./competitor-form.component.html",
  styleUrls: ["./competitor-form.component.scss"],
})
export class CompetitorFormComponent implements OnInit, OnChanges {
  @Input()
  competitor: CompetitorBase;

  @Output()
  formStatusChanged = new EventEmitter<CompetitorFormStatusData>();

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group(
      getCompetitorFormModel(this.formBuilder)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.competitor) {
      this.form.patchValue(changes.competitor.currentValue);
      this.form.markAsPristine();
    }
  }

  get states() {
    return states;
  }

  ngOnInit(): void {
    this.emitStatusData();
    this.form.statusChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => this.emitStatusData());
  }

  private emitStatusData(): void {
    return this.formStatusChanged.emit({
      isValid: this.form.valid,
      dirtyState: this.form.dirty,
      value: this.form.value,
      updatedFields: updatedFieldsData(this.form)
    });
  }
}
