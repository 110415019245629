import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { routerTransition } from "projects/backoffice/src/app/shared/animations/router.animations";
import { OfferEconomicsData } from "./models/offer-economics-data.model";

@UntilDestroy()
@Component({
  selector: "ifb-offer-economics",
  templateUrl: "./offer-economics.component.html",
  animations: routerTransition,
})
export class OfferEconomicsComponent implements OnInit {
  data: OfferEconomicsData;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: OfferEconomicsData) => {
        this.data = data;
      });
  }
}
