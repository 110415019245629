import { QueryResult, QueryParams, BankAccount, LinkingStatus, BankAccountRole } from "common";

export interface BankAccountsListGroupedByInstitutionData {
    institution?: string;
    accounts?: BankAccount[];
}

export interface BankTransactionQueryParams extends QueryParams {
    transactionDateFrom?: any;
    transactionDateTo?: any;
    postedDateTo?: any;
    postedDateFrom?: any;
    status?: any;
    type?: any;
    category?: any;
    fromAmount?: any;
    toAmount?: any;
    active?: any;
}

export interface BankTransactionQueryResult extends QueryResult<BankTransactionData> {
}


export class BankTransactionData {
    id?: number;
    amount?: number;
    accountId?: number;
    customerId?: number;
    status?: string;
    description?: string;
    memo?: string;
    type?: string;
    category?: string;
    city?: string;
    state?: string;
    country?: string;
    postedDate?: Date;
    createdDate?: Date;
    transactionDate?: Date;
}

export interface BankAccountQueryResult extends QueryResult<BankAccount> {
}

export const BankLinkStatusLabels = {};
BankLinkStatusLabels[LinkingStatus.Unlinked] = { color: 'mat-red-bg', icon: 'link_off', label: 'Unlinked' };
BankLinkStatusLabels[LinkingStatus.Linked] = { color: 'mat-green-bg', icon: 'link', label: 'Linked' };
BankLinkStatusLabels[LinkingStatus.Broken] = { color: 'mat-orange-bg', icon: 'link_off', label: 'Broken' };

export enum VerificationStatus {
    Unverified = 0,
    Verified = 1
}

export const BankVerificationLabels = {};
BankVerificationLabels[VerificationStatus.Unverified] = { color: 'mat-red-bg', icon: 'error_outline', label: 'Unverified' };
BankVerificationLabels[VerificationStatus.Verified] = { color: 'mat-indigo-bg', icon: 'done_all', label: 'Verified' };


export enum BankTransactionStatus {
    Active = 'active',
    Pending = 'pending',
    Shadow = 'shadow'
}

export enum BankTransactionType {
    Atm = 'atm',
    Cash = 'cash',
    Check = 'check',
    Credit = 'credit',
    Debit = 'debit',
    Deposit = 'deposit',
    DirectDebit = 'directDebit',
    DirectDeposit = 'directDeposit',
    Dividend = 'dividend',
    Fee = 'fee',
    Interest = 'interest',
    Other = 'other',
    Payment = 'payment',
    PointOfSale = 'pointOfSale',
    RepeatPayment = 'repeatPayment',
    ServiceCharge = 'serviceCharge',
    Transfer = 'transfer',
}

export class BankTransactionFilters {
    transactionDateFrom: any;
    transactionDateTo: any;
    postedDateFrom: any;
    postedDateTo: any;
    fromAmount: any;
    toAmount: any;
    status: any;
    type: any;
    category: any;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.transactionDateFrom = filters.transactionDateFrom || undefined;
        this.transactionDateTo = filters.transactionDateTo || undefined;
        this.postedDateFrom = filters.postedDateFrom || undefined;
        this.postedDateTo = filters.postedDateTo || undefined;
        this.fromAmount = filters.fromAmount || undefined;
        this.toAmount = filters.toAmount || undefined;
        this.status = filters.status || undefined;
        this.type = filters.type || undefined;
        this.category = filters.category || undefined;
        this.active = filters.active === 'true' ? true : (filters.active === 'false' ? false : undefined);
    }

    remove(item: string) {
        this[item] = undefined;
    }
}

export const BankAccountRoleLabel = new Map<number, string>([
    [BankAccountRole.None, 'None'],
    [BankAccountRole.Disbursement, 'Disbursement'],
    [BankAccountRole.Payment, 'Payment'],
    [BankAccountRole.PaymentAndDisbursement, 'Payment and disbursement']
]);
