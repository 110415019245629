export class CompetitorsFilters {
  name: any;
  active: boolean;

  constructor(filters?) {
    filters = filters || {};
    this.name = filters.name || undefined;
    this.active = this.checkIfTrue(filters);
  }

  private checkIfTrue(filters: any): boolean {
    return filters.active === "true"
      ? true
      : this.checkIfFalse(filters);
  }

  private checkIfFalse(filters: any): boolean {
    return filters.active === "false"
      ? false
      : undefined;
  }

  remove(item: string) {
    this[item] = undefined;
  }
}
