<ifb-card>
  <mat-card class="ifc-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md padding-bottom-0">
      <div class="card-title">Account onboarding</div>
    </div>
    <mat-card-content class="layout padding-top">
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Pushed to RYNO</span>
          <span class="item-content">{{data?.pushed}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Registered</span>
          <span class="item-content">{{data?.registered}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Identity verified</span>
          <span class="item-content">{{data?.verified}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Agreement signed</span>
          <span class="item-content">{{data?.signed}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Activated</span>
          <span class="item-content">{{data?.activated}}</span>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ifb-card>