<div>

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button *ngIf="data.bankTransactionQueryResult?.totalCount" mat-icon-button (click)="download()" class="secondary-text" matTooltip="Download">
            <mat-icon>get_app</mat-icon>
          </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button *ngIf="data.bankTransactionQueryResult?.totalCount" mat-menu-item (click)="download()">
              <mat-icon>get_app</mat-icon>
              <span>Download</span>
            </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [detailTabbed]="true">
    <div class="table-container">

      <div class="message-box info" *ngIf="!data.bankTransactionQueryResult?.totalCount" fxLayout="row"
        fxLayoutGap="12px" fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>

      <table mat-table *ngIf="data.bankTransactionQueryResult?.totalCount"
        [dataSource]="data.bankTransactionQueryResult.values" matSort (matSortChange)="sortData($event)">

        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell mat-sort-header="transactionDate" *matHeaderCellDef> Transaction date </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.transactionDate | date}} </td>
        </ng-container>

        <ng-container matColumnDef="postedDate">
          <th mat-header-cell mat-sort-header="postedDate" *matHeaderCellDef> Posted date </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.postedDate | date}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th class="right-align" mat-header-cell mat-sort-header="amount" arrowPosition="before"
            *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let row" class="table-cell right-align">
            {{row.amount | currency:'$'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header="status" *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.status | titlecase}}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell mat-sort-header="type" *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.type}}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell mat-sort-header="description" *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.description}}
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell mat-sort-header="category" *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.category}} </td>
        </ng-container>

        <ng-container matColumnDef="memo">
          <th mat-header-cell mat-sort-header="memo" *matHeaderCellDef> Memo </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.memo}}
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{row.id}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
      </table>

    </div>
  </ifc-app-scroll-container>

  <div [hidden]="!data.bankTransactionQueryResult || data.bankTransactionQueryResult?.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.bankTransactionQueryResult?.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>