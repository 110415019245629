<div [@routerTransition]="animationMode">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button [hidden]="selectedTabIndex !== 0" mat-icon-button (click)="filterDialog()" class="secondary-text"
          matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button [matMenuTriggerFor]="transactionsMenu" mat-icon-button
          *ngIf="readWriteTransaction && canReadAccounts && isOwnerAssigned" class="secondary-text" matTooltip="Add">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button *ngIf="innerTab" [matMenuTriggerFor]="transactionsDownloadMenu" mat-icon-button class="secondary-text" matTooltip="Download">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button *ngIf="!innerTab" mat-icon-button (click)="downloadHistory()" class="secondary-text" matTooltip="Download">
          <mat-icon>get_app</mat-icon>
        </button>
        <mat-menu #transactionsMenu="matMenu">
          <ng-template matMenuContent>
            <button *ngIf="canMakeDraw" mat-menu-item (click)="addTransaction(TransactionTypeNames.Draw)">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <div class="avatar medium mat-light-grey-bg">D</div>
                <span>Draw</span>
              </div>
            </button>
            <button *ngIf="canMakePayment" mat-menu-item (click)="addTransaction(TransactionTypeNames.Payment)">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <div class="avatar medium mat-light-grey-bg">P</div>
                <span>Payment</span>
              </div>
            </button>
            <mat-divider *ngIf="adminEdit && (canMakeDraw || canMakePayment)"></mat-divider>
            <button class="add-transaction-admin" *ngIf="adminEdit" mat-menu-item (click)="addAdminTransaction()">
              Admin Transaction
            </button>
          </ng-template>
        </mat-menu>
        <mat-menu #transactionsDownloadMenu="matMenu">
          <ng-template matMenuContent>
            <button mat-menu-item (click)="downloadHistory()">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-icon>get_app</mat-icon>
                <span>Transaction history</span>
              </div>
            </button>
            <button mat-menu-item (click)="downloadUpcoming()">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-icon>get_app</mat-icon>
                <span>Upcoming payments</span>
              </div>
            </button>
            <button mat-menu-item (click)="downloadFull()">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-icon>get_app</mat-icon>
                <span>Full amortization schedule</span>
              </div>
            </button>
          </ng-template>
        </mat-menu>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button [hidden]="selectedTabIndex !== 0" mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button [matMenuTriggerFor]="transactionsMenu" *ngIf="readWriteTransaction && canReadAccounts" mat-menu-item>
            <mat-icon>add_circle</mat-icon>
            <span>Add</span>
          </button>
          <button *ngIf="innerTab" [matMenuTriggerFor]="transactionsDownloadMenu" mat-menu-item>
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
          </button>
          <button *ngIf="!innerTab" mat-menu-item (click)="downloadHistory()">
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifb-account-notes-sidenav [accountId]="data.account?.id" [notes]="data.account?.notes" [initiator]="data.userCurrent"
    [saveOnDemand]="true" [innerSidenavAndTabbed]="true" [hiddenButton]="!data.account">

    <div [ngClass]="(data.account && data.accountInfo) ? '' : 'hidden-tabs'">
      <mat-tab-group #tabGroup class="transactions-tabs" [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="RECENT">
          <ifc-app-scroll-container [innerTabbedHidden]="!innerTab ? true : false"
            [innerTabbedVisible]="innerTab ? true : false">
            <div class="table-container">

              <div class="message-box info" *ngIf="!data.transactionQueryResult.totalCount" fxLayout="row"
                fxLayoutGap="12px" fxLayoutAlign="start center">
                <mat-icon class="icon">info</mat-icon>
                <span>No data found</span>
              </div>

              <table class="transactions-table" *ngIf="data.transactionQueryResult.totalCount" mat-table matSort multiTemplateDataRows [dataSource]="data.transactionQueryResult.values" (matSortChange)="sortData($event)">

                <ng-container matColumnDef="type-icon">
                  <th scope="col" mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">
                    <div *ngIf="!(row.isFirstDraw && row.type === TransactionType.Draw)" class="avatar medium letter mat-light-grey-bg" [innerHTML]="getAvatarIcon(row.type)"></div>
                    <div *ngIf="row.isFirstDraw && row.type === TransactionType.Draw" class="avatar medium letter mat-indigo-bg" matTooltip="First draw">1</div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="bankLinkingStatus">
                  <th scope="col" mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row" class="table-cell icon-cell" (click)="transactionDetailsLink(row)">
                    <div *ngIf="(row.transferAmount || 0) > 0" [ngClass]="getBankLinkingStatusAvatarClass(row)">
                      <mat-icon matTooltip="{{getBankLinkingStatusTooltip(row)}}" [inline]="true" class="small">{{getBankLinkingStatusAvatarIcon(row)}}</mat-icon>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="welcomeCallStatus">
                  <th scope="col" mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row" class="table-cell icon-cell" (click)="transactionDetailsLink(row)">
                    <div [ngClass]="getConditionsAvatarClass(row)">
                      <mat-icon matTooltip="{{getConditionsTooltip(row)}}" [inline]="true" class="small">
                        {{getConditionsAvatarIcon(row)}}</mat-icon>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="transactionHistory">
                  <th scope="col" mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row" class="table-cell icon-cell" (click)="transactionDetailsLink(row)">
                    <div *ngIf="!row.loanModificationSnapshotId" [ngClass]="getBankVerificationAvatarClass(row)">
                      <mat-icon matTooltip="{{getBankVerificationTooltip(row)}}" [inline]="true" class="small">{{getBankVerificationAvatarIcon(row)}}</mat-icon>
                    </div>
                    <div *ngIf="row.loanModificationSnapshotId" class="avatar medium mat-orange-bg">
                      <mat-icon matTooltip="Under modification" [inline]="true" class="small">error</mat-icon>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="renewalFor">
                  <th scope="col" mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row" class="table-cell icon-cell" (click)="transactionDetailsLink(row)">
                    <mat-icon matTooltip="From {{row.loanNumber}}" *ngIf="data?.account?.id !== row.loanId">call_merge</mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="date">Transaction date</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.date | date: 'medium'}}</td>
                </ng-container>

                <ng-container matColumnDef="rejectReasons">
                  <th scope="col" mat-header-cell *matHeaderCellDef>Reject/Pending reasons</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">
                    <span *ngFor="let reason of showReasons(row.failureReasons)">
                      {{reason}}
                      <br />
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="processDate">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="processDate">Processed date</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.processDate | date: 'shortDate'}}</td>
                </ng-container>

                <ng-container matColumnDef="type">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{showType(row.type)}}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="amount" arrowPosition="before" class="right-align">Amount</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.displayAmount | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="promotion">
                  <th scope="col" mat-header-cell *matHeaderCellDef arrowPosition="before">Promotion</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{getPromotionsString(row)}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">
                    <span *ngIf="row.status === TransactionStatus.Processed">Processed</span>
                    <span *ngIf="row.status === TransactionStatus.Complete">Complete</span>
                    <ifc-status-label [status]="status" [value]="row.status" *ngIf="row.status !== TransactionStatus.Processed && row.status !== TransactionStatus.Complete && row.status !== undefined"></ifc-status-label>
                  </td>
                </ng-container>

                <ng-container matColumnDef="businessName">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="businessName">Legal entity name</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.businessName}}</td>
                </ng-container>

                <ng-container matColumnDef="loanPrincipalBalance">
                  <th scope="col" mat-header-cell *matHeaderCellDef>Outstanding Balance</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.loanPrincipalBalance | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="loanCreditLimit">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="creditLimit">Credit Limit</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.loanCreditLimit | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="loanNumber">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="loanNumber">Loan number</th>
                  <td mat-cell *matCellDef="let row" class="table-cell">
                    <a *ngIf="canReadAccounts" [routerLink]="accountDetailsLink(row)">{{row.loanNumber}}</a>
                    <span *ngIf="!canReadAccounts">{{row.loanNumber}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="disbursedAmount">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="disbursedAmount" arrowPosition="before" class="right-align">Disbursed amount</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.disbursedAmount | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="thirdPartyPayoffAmount">
                  <th scope="col" mat-header-cell *matHeaderCellDef class="right-align">Third party payoff amount</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.thirdPartyPayoffAmount | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="deferredFeeAmount">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="deferredFeeAmount" arrowPosition="before" class="right-align">Deferred fee</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.deferredFeeAmount | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="cashFeeAmount">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="cashFeeAmount" arrowPosition="before" class="right-align">Cash fee</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.cashFeeAmount | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="principalAmount">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="principalAmount" arrowPosition="before" class="right-align">Principal amount</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.principalAmount | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="interestAmount">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="interestAmount" arrowPosition="before" class="right-align">Interest amount</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.interestAmount | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="closingBalance">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="closingBalance" arrowPosition="before" class="right-align">Closing Balance</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.closingBalance | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="outstandingInterest">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="outstandingInterest" arrowPosition="before" class="right-align">Outstanding Interest</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.outstandingInterest | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="outstandingPrincipal">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="outstandingPrincipal" arrowPosition="before" class="right-align">Outstanding Principal</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.outstandingPrincipal | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="creditLimit">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="creditLimit" arrowPosition="before" class="right-align">Credit Limit</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.creditLimit | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="utilization">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="utilization" arrowPosition="before" class="right-align">Utilization</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.utilization | percent:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="totalDeferredFees">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="totalDeferredFees" arrowPosition="before" class="right-align">Total Deferred Fees</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.totalDeferredFees | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="totalCashFees">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="totalCashFees" arrowPosition="before" class="right-align">Total Cash Fees</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.totalCashFees | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="totalFees">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="totalFees" arrowPosition="before" class="right-align">Total Fees</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.totalFees | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="totalInterest">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="totalInterest" arrowPosition="before" class="right-align">Total Interest Cost</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.totalInterest | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="totalPrincipal">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="totalPrincipal" arrowPosition="before" class="right-align">Total Capital Repayment</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.totalPrincipal | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="totalPayback">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="totalPayback" arrowPosition="before" class="right-align">Total Payback</th>
                  <td mat-cell *matCellDef="let row" class="table-cell right-align" (click)="transactionDetailsLink(row)">{{row.totalPayback | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="initiator">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="initiator">Initiated by</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.initiator}}</td>
                </ng-container>

                <ng-container matColumnDef="creator">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created by</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.creator}}</td>
                </ng-container>

                <ng-container matColumnDef="modifier">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="modifiedBy">Modified by</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.modifier}}</td>
                </ng-container>

                <ng-container matColumnDef="advisor">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="advisor">Advisor</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.advisor}}</td>
                </ng-container>

                <ng-container matColumnDef="maintained">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="maintained">Maintained</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.maintained ? 'Yes' : 'No'}}</td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
                  <td mat-cell *matCellDef="let row" class="table-cell" (click)="transactionDetailsLink(row)">{{row.id}}</td>
                </ng-container>

                <ng-container matColumnDef="edit" stickyEnd>
                  <th scope="col" mat-header-cell *matHeaderCellDef></th>
                  <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let row" ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
                    <div *ngIf="canReadTransactions && !readWriteTransaction">
                      <button mat-icon-button (click)="showDetails(row)">
                        <mat-icon *ngIf="expandedElement !== row" ngClass.lt-lg="secondary-text more-actions-menu">expand_more</mat-icon>
                        <mat-icon *ngIf="expandedElement === row" ngClass.lt-lg="secondary-text more-actions-menu">expand_less</mat-icon>
                      </button>
                    </div>
                    <div
                      [ngClass]="(!readWriteTransaction || !canReadAccounts) ? 'hide' : ((CanUserEditTransactionInStatusGrantedPermissions[row.status].hidden) ? 'hide' : '')">
                      <ifb-transaction-edit-menu [tx]="row" ngClass.lt-lg="secondary-text more-actions-menu"
                        [CanUserMoveTransactionToStatusGrantedPermissions]="CanUserMoveTransactionToStatusGrantedPermissions"
                        [expandedDetail]="openedRow" [canEditSubTransactions]="adminEdit" [failureReasons]="data?.failureReasons"
                        [backToListUrl]="backToListUrl"
                        (statusChanged)="fetchData($event)" (expandedDetailChanged)="showDetails(row)" >
                      </ifb-transaction-edit-menu>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let row" [attr.colspan]="getDisplayedColumns()?.length"class="expanded-detail">
                    <div fxLayout="column" class="element-detail" [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">
                      <ifb-transaction-list-expanded-row [adminEdit]="adminEdit" [failureReasons]="data?.failureReasons"
                        [CanUserEditTransactionInStatusGrantedPermissions]="CanUserEditTransactionInStatusGrantedPermissions"
                        [CanUserMoveTransactionToStatusGrantedPermissions]="CanUserMoveTransactionToStatusGrantedPermissions"
                        [transaction]="row" [readWriteTransaction]="readWriteTransaction" [expandedDetail]="openedRow"
                        [canReadAccounts]="canReadAccounts" (statusChanged)="fetchData($event)"
                        (expandedDetailChanged)="showDetails(row)">
                      </ifb-transaction-list-expanded-row>
                      <div class="layout margin-left margin-bottom-sm">
                        <button mat-button (click)="showDetails(row)">Collapse</button>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true" class="table-header"></tr>
                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()" class="table-row" [class.expanded-row]="expandedElement === row"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
              </table>
            </div>

          </ifc-app-scroll-container>
        </mat-tab>
        <mat-tab label="UPCOMING PAYMENTS" *ngIf="data.account?.status !== loanStatus.Closed">
          <ifc-app-scroll-container [innerTabbedHidden]="!innerTab ? true : false"
            [innerTabbedVisible]="innerTab ? true : false">
            <ifb-account-payment-list [paginator]="accountPaymentListPaginator" [data]="data.accountAmortizationInfo">
            </ifb-account-payment-list>
          </ifc-app-scroll-container>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div [hidden]="data.transactionQueryResult.totalCount <= 10 || selectedTabIndex !== 0">
      <mat-paginator #paginator showFirstLastButtons [length]="data.transactionQueryResult.totalCount"
        [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
    </div>

    <div [hidden]="selectedTabIndex !== 1">
      <mat-paginator #paginator2 showFirstLastButtons [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
    </div>

  </ifb-account-notes-sidenav>

</div>
