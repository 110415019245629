import { Component } from '@angular/core';

@Component({
  selector: 'ifb-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {

  constructor() { }

}