import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService, ResourceService } from 'common';
import {
  AdvisorQueryParams,
  AdvisorQueryResult,
  AdvisorData,
  AdvisorHintParams,
  AdvisorHintData
} from './advisor.model';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class AdvisorService {

  private readonly baseUrl = `${this.settings.advisors.url}/api/advisor`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private resourceService: ResourceService,
    private configService: ConfigService
  ) { }

  query(params: AdvisorQueryParams): Observable<AdvisorQueryResult> {
    return this.http.get<AdvisorQueryResult>(`${this.baseUrl}`, {
      headers: this.configService.environment.cache.longLived,
      params: <any>params
    });
  }

  queryHints(query: AdvisorHintParams): Observable<AdvisorHintData[]> {
    return this.http.get<AdvisorHintData[]>(`${this.baseUrl}/hints`, {
      params: <any>query,
      headers: this.configService.environment.cache.longLived
    });
  }

  create(command: AdvisorData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, command);
  }

  update(id: number, updateCommand: AdvisorData): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, updateCommand);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  get(id: number): Observable<AdvisorData> {
    return this.http.get<AdvisorData>(`${this.baseUrl}/${id}`, {
      headers: this.configService.environment.cache.longLived
    });
  }

  download(params: AdvisorQueryParams) {
    this.resourceService.download(`${this.baseUrl}/csv`, "advisors.csv", params);
  }

  saveAvatar(file: File, id: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(`${this.baseUrl}/${id}/avatar`, formData);
  }

}
