import { Component, Input } from '@angular/core';
import { AdvisorData } from '../advisor.model';
import { AppSettings } from '../../../app.settings';

@Component({
  selector: 'ifb-advisor-info-widget',
  templateUrl: './advisor-info-widget.component.html',
  styleUrls: ['./advisor-info-widget.component.scss']
})
export class AdvisorInfoWidgetComponent {

  @Input()
  data: AdvisorData;

  @Input()
  edit: boolean;

  constructor(private settings: AppSettings) { }

  salesforceLink() {
    return this.settings.salesforce.url + this.data.uniqueId;
  }

}
