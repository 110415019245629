import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { TransactionData } from '../transaction.model';
import { EnumHelper, FormHelper, SubtransactionTypeInternalLabel, TransactionStatus, TransactionType } from 'common';
import { UntypedFormGroup } from '@angular/forms';
import { FailureReasonUsage } from "../../admin/failure-reason/failure-reason.model";

@Component({
  selector: 'ifb-transaction-details-update-dialog',
  templateUrl: './transaction-details-update-dialog.component.html',
  styleUrls: ['./transaction-details-update-dialog.component.css']
})
export class TransactionDetailsUpdateDialogComponent {

  status: string;
  statusUpdated: string;
  type: string;
  promotions: string;
  competitors: string;

  get FailureReasonUsage() { return FailureReasonUsage; }
  get TransactionStatus() { return TransactionStatus; }

  constructor(public dialogRef: MatDialogRef<TransactionDetailsUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.status = EnumHelper.getNameFromValue(TransactionStatus, this.data.form.value.status);
    this.statusUpdated = EnumHelper.getNameFromValue(TransactionStatus, this.data.status);
    this.type = EnumHelper.getNameFromValue(TransactionType, this.data.transaction.type);
    this.promotions = this.data.promotions;
    this.competitors = this.data.competitors;
  }

  getSubtransactionTypeName(type: number): string {
    return SubtransactionTypeInternalLabel.get(type);
  }

  save() {
    if (this.data.status === TransactionStatus.Rejected) {
      FormHelper.showInvalidFormFields(this.data.form);
      if (!this.data.form.controls.reasons.valid)
        return;
    }
    this.dialogRef.close(true);
  }

  // eslint-disable-next-line max-len
  static show(dialog: MatDialog, form: UntypedFormGroup, notes: any[], transaction?: TransactionData, status?: TransactionStatus, transactionNew?: boolean, promotions?: string, competitors?: string): Observable<any> {
    return dialog.open(TransactionDetailsUpdateDialogComponent, {
      width: '510px',
      data: {
        form: form,
        notes: notes,
        transaction: transaction,
        status: status,
        transactionNew: transactionNew,
        promotions: promotions,
        competitors
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

}
