import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private snackBar: MatSnackBar) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                () => { },
                (err: HttpEvent<any>) => {
                    if (err instanceof HttpErrorResponse) {
                        const errorId = err.headers.get('X-Error-ID');
                        if (errorId) {
                            const msg = 'Some unexpected error has occurred. For more information, click the button.';
                            const config = { duration: 5000, panelClass: ['mat-orange-bg', 'snackbar', 'error'] };
                            this.snackBar.open(msg, 'More', config)
                                .onAction()
                                .subscribe(() => {
                                    this.router.navigate(['logs'], { queryParams: { errorId: errorId } });
                                    this.snackBar.dismiss();
                                });
                        }
                    }
                }
            ));
    }
}

