import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  TransactionFilters,
  TransactionQueryParams,
  TransactionListComponentData
} from '../transaction.model';
import { EnumHelper, NumberHelper, FilterRange, IdNameData, ObjectHelper, TransactionStatus, TransactionType } from 'common';
import * as moment from 'moment';
import { TenantService } from '../../admin/tenant/tenant.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readOnly } from '../../user/user-permission/user-permission.data';
import { PromotionSelectOptionData } from '../../admin/promotion/models/promotion.model';

@Component({
  selector: 'ifb-transaction-list-filter-dialog',
  templateUrl: './transaction-list-filter-dialog.component.html',
  styleUrls: ['./transaction-list-filter-dialog.component.scss']
})

export class TransactionListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  statusOptions = EnumHelper.getNamesAndStringValues(TransactionStatus);
  transactionTypeOptions = EnumHelper.getNamesAndStringValues(TransactionType);
  form: UntypedFormGroup;
  filters: TransactionFilters;
  hiddenTransactionColumns: boolean;
  companies: IdNameData[] = [];
  readPromotions = false;

  get promotionsOptions(): PromotionSelectOptionData[] {
    return this._promotionOptions;
  }

  _promotionOptions: PromotionSelectOptionData[];
  _promotionOptionsMap: Map<string, string>;

  constructor(
    public dialogRef: MatDialogRef<TransactionListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private tenantService: TenantService,
    private userPermissionService: UserPermissionService
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new TransactionFilters(data.query);

    this._promotionOptions = data.promotionsToSelect;
    this._promotionOptionsMap = new Map(data.promotionsToSelect?.map((r): [string, string] => [r.id, r.name]));

    this.form = this.formBuilder.group({
      loanNumber: this.data.query.loanNumber || '',
      businessName: this.data.query.businessName || '',
      id: [this.data.query.id || undefined, Validators.max(NumberHelper.MaxValue)],
      companyId: +this.data.query.companyId || undefined,
      type: { value: ObjectHelper.makeArray(this.data.query.type), disabled: false },
      status: { value: ObjectHelper.makeArray(this.data.query.status), disabled: false },

      amountFrom: this.data.query.amountFrom || undefined,
      amountTo: this.data.query.amountTo || undefined,

      creditLimitFrom: this.data.query.creditLimitFrom || undefined,
      creditLimitTo: this.data.query.creditLimitTo || undefined,

      promotionIds: { value: ObjectHelper.makeArray(this.data.query.promotionIds), disabled: false },

      transactionDate: this.data.query.transactionDate || undefined,
      fromDate: new UntypedFormControl(this.data.query.fromDate ? new Date(this.data.query.fromDate) : undefined, { validators: null, updateOn: 'blur' }),
      toDate: new UntypedFormControl(this.data.query.toDate ? new Date(this.data.query.toDate) : undefined, { validators: null, updateOn: 'blur' }),

      processDate: this.data.query.processDate || undefined,
      processDateFrom: new UntypedFormControl(this.data.query.processDateFrom ? new Date(this.data.query.processDateFrom) : undefined, { validators: null, updateOn: 'blur' }),
      processDateTo: new UntypedFormControl(this.data.query.processDateTo ? new Date(this.data.query.processDateTo) : undefined, { validators: null, updateOn: 'blur' }),

      principalAmount: this.data.query.principalAmount || undefined,
      principalAmountExcept: this.data.query.principalAmountExcept,
      principalAmountFrom: new UntypedFormControl(this.data.query.principalAmountFrom, { validators: null, updateOn: 'blur' }),
      principalAmountTo: new UntypedFormControl(this.data.query.principalAmountTo, { validators: null, updateOn: 'blur' }),

      interestAmount: this.data.query.interestAmount || undefined,
      interestAmountExcept: this.data.query.interestAmountExcept,
      interestAmountFrom: new UntypedFormControl(this.data.query.interestAmountFrom, { validators: null, updateOn: 'blur' }),
      interestAmountTo: new UntypedFormControl(this.data.query.interestAmountTo, { validators: null, updateOn: 'blur' }),

      deferredFeeAmount: this.data.query.deferredFeeAmount || undefined,
      deferredFeeAmountExcept: this.data.query.deferredFeeAmountExcept,
      deferredFeeAmountFrom: new UntypedFormControl(this.data.query.deferredFeeAmountFrom, { validators: null, updateOn: 'blur' }),
      deferredFeeAmountTo: new UntypedFormControl(this.data.query.deferredFeeAmountTo, { validators: null, updateOn: 'blur' }),

      cashFeeAmount: this.data.query.cashFeeAmount || undefined,
      cashFeeAmountExcept: this.data.query.cashFeeAmountExcept,
      cashFeeAmountFrom: new UntypedFormControl(this.data.query.cashFeeAmountFrom, { validators: null, updateOn: 'blur' }),
      cashFeeAmountTo: new UntypedFormControl(this.data.query.cashFeeAmountTo, { validators: null, updateOn: 'blur' }),

      disbursedAmount: this.data.query.disbursedAmount || undefined,
      disbursedAmountExcept: this.data.query.disbursedAmountExcept ,
      disbursedAmountFrom: new UntypedFormControl(this.data.query.disbursedAmountFrom, { validators: null, updateOn: 'blur' }),
      disbursedAmountTo: new UntypedFormControl(this.data.query.disbursedAmountTo, { validators: null, updateOn: 'blur' }),
    });

    this.userPermissionService.granted([readOnly('servicing-promotions')]).subscribe(res => {
      this.readPromotions = res;
      if(!res)
        this.form.controls.promotionIds.disable({onlySelf: false, emitEvent: false});
    })
  }

  ngOnInit(): void {
    this.tenantService.names().subscribe(data => this.companies = data);

    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new TransactionFilters(data);
      });

    if (this.data.routeData.account && this.data.routeData.accountInfo) {
      this.hiddenTransactionColumns = true;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // eslint-disable-next-line max-len
  static show(dialog: MatDialog, query: TransactionQueryParams, routeData: TransactionListComponentData, canFilterByLoan: boolean): Observable<any> {
    return dialog.open(TransactionListFilterDialogComponent, {
      data: { query: query, routeData: routeData, canFilterByLoan: canFilterByLoan, promotionsToSelect: routeData.promotionsToSelect },
      closeOnNavigation: true,
    }).afterClosed();
  }



  transactionDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      fromDate: dataRange.from,
      toDate: dataRange.to
    });
  }

  processDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      processDateFrom: dataRange.from,
      processDateTo: dataRange.to
    });
  }


  principalAmountSelected(amountRange: FilterRange<number>) {
    this.form.patchValue({
      principalAmountExcept: amountRange.except,
      principalAmountFrom: amountRange.from,
      principalAmountTo: amountRange.to
    });
  }

  interestAmountSelected(amountRange: FilterRange<number>) {
    this.form.patchValue({
      interestAmountExcept: amountRange.except,
      interestAmountFrom: amountRange.from,
      interestAmountTo: amountRange.to
    });
  }

  deferredFeeAmountSelected(amountRange: FilterRange<number>) {
    this.form.patchValue({
      deferredFeeAmountExcept: amountRange.except,
      deferredFeeAmountFrom: amountRange.from,
      deferredFeeAmountTo: amountRange.to
    });
  }

  cashFeeAmountSelected(amountRange: FilterRange<number>) {
    this.form.patchValue({
      cashFeeAmountExcept: amountRange.except,
      cashFeeAmountFrom: amountRange.from,
      cashFeeAmountTo: amountRange.to
    });
  }

  disbursedAmountSelected(amountRange: FilterRange<number>) {
    this.form.patchValue({
      disbursedAmountExcept: amountRange.except,
      disbursedAmountFrom: amountRange.from,
      disbursedAmountTo: amountRange.to
    });
  }
}
