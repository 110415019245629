import { Component, Input } from "@angular/core";
import { OfferDetailsData } from "../../models/offer-details-data.model";

@Component({
  selector: "ifb-offer-details-widget",
  templateUrl: "./offer-details-widget.component.html",
  styleUrls: ["./offer-details-widget.component.scss"],
})
export class OfferDetailsWidgetComponent {
  @Input()
  data: OfferDetailsData;
}
