import { Component, Input } from '@angular/core';
import { NotesSidenavComponent } from '../notes-sidenav.component';
import { ApplicationService } from '../../../application/application.service';

@Component({
  selector: 'ifb-application-notes-sidenav',
  templateUrl: '../notes-sidenav.component.html',
  styleUrls: ['../notes-sidenav.component.scss']
})
export class ApplicationNotesSidenavComponent extends NotesSidenavComponent {

  canEditNotes = true;

  constructor(private applicationService: ApplicationService) {
    super();
  }

  @Input()
  applicationId: number;

  addNote(value: string): void {
    this.applicationService.addNote(this.applicationId, value).subscribe();
  }
}
