import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { routerTransition } from "projects/backoffice/src/app/shared/animations/router.animations";
import { OfferFeesData } from "./models/offer-fees-data.model";

@UntilDestroy()
@Component({
  selector: "ifb-offer-fees",
  templateUrl: "./offer-fees.component.html",
  styleUrls: ["./offer-fees.component.scss"],
  animations: routerTransition,
})
export class OfferFeesComponent implements OnInit {
  data: OfferFeesData;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: OfferFeesData) => {
        this.data = data;
      });
  }
}
