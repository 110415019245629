import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import { Promotion } from "./models/promotion.model";
import {
  PromotionListQueryParams,
  PromotionListQueryResults,
} from "./models/promotions-query.model";
import { PromotionService } from "./promotion.service";

@Injectable({
  providedIn: "root",
})
export class PromotionResolver implements Resolve<Promotion> {
  constructor(private service: PromotionService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Promotion> | Promise<Promotion> | Promotion {
    return route.params.id === "new"
      ? undefined
      : this.service.getPromotion(route.params.id);
  }
}

@Injectable({
  providedIn: "root",
})
export class PromotionListResolver
  implements Resolve<PromotionListQueryResults>
{
  constructor(private service: PromotionService, private queryParamsService: QueryParamsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<PromotionListQueryResults>
    | Promise<PromotionListQueryResults>
    | PromotionListQueryResults {

    return this.service.getPromotionList(
      this.queryParamsService.init(route.queryParams) as PromotionListQueryParams
    );
  }
}
