import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { BankAccountQueryResult, BankTransactionQueryResult } from "./bank.model";
import { BankAccountService } from "./bank.service";
import { map, switchMap } from "rxjs/operators";
import { AccountService } from "../account/account.service";
import { AuditQueryParams, AuditQueryResult } from "../shared/audit-trail/audit-trail.model";
import { AuditTrailService } from "../shared/audit-trail/audit-trail.service";
import { ApplicationService } from "../application/application.service";
import { BankAccount, parseNumber } from "common";
import { BankTransactionsService } from "./bank-transactions.service";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Injectable({
    providedIn: 'root'
})

@Injectable({
    providedIn: 'root'
})
export class BankAccountResolverForLoan implements Resolve<BankAccount> {
    constructor(private service: BankAccountService, private accountService: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankAccount> | Promise<BankAccount> | BankAccount {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        const bankAccountId = route.params.bankAccountId !== undefined
            ? route.params.bankAccountId : route.pathFromRoot[route.pathFromRoot.length - 2].params.bankAccountId;
        return bankAccountId === 'new'
            ? undefined
            : this.accountService.get(parseNumber(id))
                .pipe(
                    map(res => res),
                    switchMap(res => res ? this.service.getBankAccount(res.entityId, parseNumber(bankAccountId)) : null)
                );
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankAccountResolverForEntity implements Resolve<BankAccount> {
    constructor(private service: BankAccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankAccount> | Promise<BankAccount> | BankAccount {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        const bankAccountId = route.params.bankAccountId !== undefined
            ? route.params.bankAccountId : route.pathFromRoot[route.pathFromRoot.length - 2].params.bankAccountId;

        return bankAccountId === 'new'
            ? undefined
            : this.service.getBankAccount(parseNumber(id), parseNumber(bankAccountId));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankAccountResolverForApplication implements Resolve<BankAccount> {
    constructor(private service: BankAccountService, private applicationService: ApplicationService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankAccount> | Promise<BankAccount> | BankAccount {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        const bankAccountId = route.params.bankAccountId !== undefined
            ? route.params.bankAccountId : route.pathFromRoot[route.pathFromRoot.length - 2].params.bankAccountId;
        return bankAccountId === 'new'
            ? undefined
            : this.applicationService.get(id)
                .pipe(
                    map(res => res),
                    switchMap(res => res ? this.service.getBankAccount(res.entityId, parseNumber(bankAccountId)) : null)
                );
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankAccountListResolverForEntity implements Resolve<BankAccountQueryResult> {
    constructor(private service: BankAccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankAccountQueryResult> | Promise<BankAccountQueryResult> | BankAccountQueryResult {
        return this.service.getBankAccountList(parseNumber(route.params.id));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankAccountListResolverForLoan implements Resolve<BankAccountQueryResult> {
    constructor(private service: BankAccountService, private accountService: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankAccountQueryResult> | Promise<BankAccountQueryResult> | BankAccountQueryResult {
        return this.accountService.get(parseNumber(route.params.id))
            .pipe(
                map(res => res),
                switchMap(res => res ? this.service.getBankAccountList(res.entityId) : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankAccountListByUniqueIdResolverForLoan implements Resolve<BankAccountQueryResult> {
    constructor(private service: BankAccountService, private accountService: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankAccountQueryResult> | Promise<BankAccountQueryResult> | BankAccountQueryResult {
        return this.accountService.getByUniqueId(route.params.uniqueId)
            .pipe(
                map(res => res),
                switchMap(res => res ? this.service.getBankAccountList(res.entityId) : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankTransactionListResolverForLoan implements Resolve<BankTransactionQueryResult> {
    constructor(private bankTransactionsService: BankTransactionsService, private accountService: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot):
        Observable<BankTransactionQueryResult> | Promise<BankTransactionQueryResult> | BankTransactionQueryResult {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        const bankAccountId = route.params.bankAccountId !== undefined
            ? route.params.bankAccountId : route.pathFromRoot[route.pathFromRoot.length - 2].params.bankAccountId;
        return this.accountService.get(parseNumber(id))
            .pipe(
                map(res => res),
                switchMap(res => res && bankAccountId !== 'new'
                    ? this.bankTransactionsService.queryBankTransactions(res.entityId, parseNumber(bankAccountId), this.queryParamsService.init(route.queryParams))
                    : of(null))
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankTransactionListResolverForApplication implements Resolve<BankTransactionQueryResult> {
    constructor(private bankTransactionsService: BankTransactionsService, private applicationService: ApplicationService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot):
        Observable<BankTransactionQueryResult> | Promise<BankTransactionQueryResult> | BankTransactionQueryResult {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        const bankAccountId = route.params.bankAccountId !== undefined
            ? route.params.bankAccountId : route.pathFromRoot[route.pathFromRoot.length - 2].params.bankAccountId;
        return this.applicationService.get(id)
            .pipe(
                map(res => res),
                switchMap(res => res && bankAccountId !== 'new'
                    ? this.bankTransactionsService.queryBankTransactions(res.entityId, parseNumber(bankAccountId), this.queryParamsService.init(route.queryParams))
                    : of(null))
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankTransactionListResolverForEntity implements Resolve<BankTransactionQueryResult> {
    constructor(private bankTransactionsService: BankTransactionsService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot):
        Observable<BankTransactionQueryResult> | Promise<BankTransactionQueryResult> | BankTransactionQueryResult {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        const bankAccountId = route.params.bankAccountId !== undefined
            ? route.params.bankAccountId : route.pathFromRoot[route.pathFromRoot.length - 2].params.bankAccountId;
        return bankAccountId === 'new'
            ? of(null)
            : this.bankTransactionsService.queryBankTransactions(parseNumber(id), parseNumber(bankAccountId), this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankAccountAuditTrailResolver implements Resolve<AuditQueryResult> {
    constructor(private service: AuditTrailService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<AuditQueryResult> | Promise<AuditQueryResult> | AuditQueryResult {
        const bankAccountId = route.params.bankAccountId !== undefined
            ? route.params.bankAccountId
            : route.pathFromRoot[route.pathFromRoot.length - 2].params.bankAccountId;
        const params = new AuditQueryParams(this.queryParamsService.init({ ...route.queryParams, bankAccount: bankAccountId }));
        return this.service.audit(params);
    }
}
