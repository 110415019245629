<ifb-card>
  <mat-card class="ifc-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md padding-bottom-0">
      <div class="card-title">Products</div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <button mat-icon-button (click)="prevProduct()">
          <mat-icon class="secondary-text">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-icon-button (click)="nextProduct()">
          <mat-icon class="secondary-text">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content class="layout padding-top">
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Product</span>
          <span class="item-content">{{data[index]?.product}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Average balance</span>
          <span class="item-content">{{data[index]?.balance | currency:'$'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Average loan amount</span>
          <span class="item-content">{{data[index]?.amount | currency:'$'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Average utilization</span>
          <span
            class="item-content">{{data[index]?.utilization / 100 | percent:'1.2-2'}}</span>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ifb-card>