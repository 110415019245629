<h1 mat-dialog-title>{{data.action}} client?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are {{actionGerund | lowercase}} client:
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">

      <div *ngIf="data.form.controls.name && !data.form.controls.name.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.name || 'Null'}}</div>
        <div class="mat-caption"> Client </div>
      </div>

      <div *ngIf="data.form.controls.oidcAudience && !data.form.controls.oidcAudience.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.oidcAudience || 'Null'}}</div>
        <div class="mat-caption"> OIDC audience </div>
      </div>

      <div *ngIf="data.form.controls.oidcLoginAllowed && !data.form.controls.oidcLoginAllowed.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.oidcLoginAllowed ? 'True' : 'False'}}</div>
        <div class="mat-caption"> OIDC login allowed </div>
      </div>

      <div *ngIf="data.form.controls.active && !data.form.controls.active.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.active ? 'True' : 'False'}}</div>
        <div class="mat-caption"> Active </div>
      </div>

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>