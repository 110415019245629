import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerRoutingModule } from './customer-routing.module';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { SharedModule } from '../shared/shared.module';
import { CustomerListFilterDialogComponent } from './customer-list-filter-dialog/customer-list-filter-dialog.component';
import { AccountModule } from '../account/account.module';
import { CustomerEmailInputComponent } from './customer-email-input/customer-email-input.component';
import { CustomerInfoWidgetComponent } from './customer-profile/customer-info-widget/customer-info-widget.component';
import { CustomerAppBarTitleComponent } from './customer-app-bar-title/customer-app-bar-title.component';
import { IdentityVerificationWidgetComponent } from './customer-profile/customer-identity-verification-widget/customer-identity-verification-widget.component';
import { CustomerKBAStatusIconChipComponent } from './customer-kba-status-icon-chip/customer-kba-status-icon-chip.component';
import { CustomerInfoDetailsComponent } from './customer-info-details/customer-info-details.component';
import { CustomerLoansWidgetComponent } from './customer-profile/customer-loans-widget/customer-loans-widget.component';
import { CustomerLoansAssignmentDialogComponent } from './customer-profile/customer-loans-widget/customer-loans-assignment-dialog/customer-loans-assignment-dialog.component';
import { CustomerLoanInputComponent } from './customer-loan-input/customer-loan-input.component';
import { BankModule } from '../bank/bank.module';
import { CustomerUpdateDialogComponent } from './customer-update-dialog/customer-update-dialog.component';
import { CustomerKBADetailsComponent } from './customer-kba-details/customer-kba-details.component';
import { CustomerKBAUpdateDialogComponent } from './customer-kba-update-dialog/customer-kba-update-dialog.component';
import { CustomerSsnInputComponent } from './customer-ssn-input/customer-ssn-input.component';
import { CustomerOtherInfoWidgetComponent } from './customer-profile/customer-other-info-widget/customer-other-info-widget.component';
import { CustomerPhoneStatusIconChipComponent } from './customer-phone-status-icon-chip/customer-phone-status-icon-chip.component';

@NgModule({
    imports: [
        CommonModule,
        CustomerRoutingModule,
        SharedModule,
        AccountModule,
        BankModule
    ],
    exports: [
        CustomerEmailInputComponent,
        CustomerSsnInputComponent,
        CustomerLoanInputComponent
    ],
    declarations: [
        CustomerListComponent,
        CustomerProfileComponent,
        CustomerListFilterDialogComponent,
        CustomerEmailInputComponent,
        CustomerSsnInputComponent,
        CustomerInfoWidgetComponent,
        CustomerAppBarTitleComponent,
        IdentityVerificationWidgetComponent,
        CustomerKBAStatusIconChipComponent,
        CustomerKBADetailsComponent,
        CustomerKBAUpdateDialogComponent,
        CustomerInfoDetailsComponent,
        CustomerLoansWidgetComponent,
        CustomerLoansAssignmentDialogComponent,
        CustomerLoanInputComponent,
        CustomerUpdateDialogComponent,
        CustomerOtherInfoWidgetComponent,
        CustomerPhoneStatusIconChipComponent
    ]
})
export class CustomerExportModule { }
