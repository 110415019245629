import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppBarTitleService, AppBarActionsService, AppBarAction, DialogConfirm, MessageService, EnumHelper, AppPageService } from 'common';
import { FailureReasonService } from '../failure-reason.service';
import { FailureReasonData, FailureReasonUsage, FailureReasonUsageLabel } from '../failure-reason.model';
import { MatDialog } from '@angular/material/dialog';
import { FormHelper } from 'common';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { FailureReasonUpdateDialogComponent } from '../failure-reason-update-dialog/failure-reason-update-dialog.component';

@Component({
  selector: 'ifb-failure-reason-details',
  templateUrl: './failure-reason-details.component.html',
  styleUrls: ['./failure-reason-details.component.scss']
})
export class FailureReasonDetailsComponent implements OnInit, OnDestroy {

  public usage = EnumHelper.getNamesAndValues(FailureReasonUsage).filter(u => u.value != FailureReasonUsage.All);
  private subs: Subscription[] = [];
  private saveSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private failureReasonService: FailureReasonService,
    private userPermissionService: UserPermissionService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private messageService: MessageService,
    private dialog: MatDialog) {

    this.appBarActionsService.actions = [
      { id: 'delete', label: 'Delete', disabled: true, buttonType: 'button' },
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.formInit();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.enable('delete', this.data && this.data.failureReason && this.data.failureReason.id && this.readWriteFailureReason && !this.deleteSub ? true : false);
  }

  cancel() {
    this.appPageService.back();
  }

  data: FailureReasonDetailsComponentData;

  minExpirationDate = new Date(new Date().setDate(new Date().getDate() + 1));

  dataInit(data: FailureReasonDetailsComponentData) {
    this.data = data;
    if (data.failureReason)
      this.form.patchValue(data.failureReason);

    this.appBarTitleService.title = data.failureReason && data.failureReason.id
      ? `Failure reason - ${data.failureReason.name}`
      : 'Create new failure reason';

    this.form.markAsPristine();
    this.actionUpdate();
  }

  delete() {
    if (!this.data || !this.data.failureReason || !this.data.failureReason.id)
      this.appPageService.back();

    DialogConfirm.show(this.dialog, `Delete failure reason?`).subscribe(result => {
      if (result) {
        this.deleteSub = this.failureReasonService.delete(this.data.failureReason.id).subscribe(() => {
          this.appPageService.back();
        });
      }

      this.actionUpdate();
    });
  }

  deleteSub: Subscription;

  form: UntypedFormGroup;

  private formInit() {
    this.form = this.formBuilder.group({
      id: undefined,
      code: [undefined, Validators.compose([Validators.required, Validators.maxLength(8)])],
      externalCode: [undefined, Validators.compose([Validators.maxLength(16)])],
      name: [undefined, Validators.compose([Validators.required, Validators.maxLength(64)])],
      description: undefined,
      usage: [undefined, Validators.required],
      allowToSpecifyCompetitors: [false, Validators.required],
      active: [true, Validators.required],
      createdBy: undefined,
      createdOn: undefined,
      updatedBy: undefined,
      updatedOn: undefined
    });
  }

  readWriteFailureReason: boolean;

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.appBarActionsService.invoking.subscribe(this.actionDispatch.bind(this)),
      this.form.statusChanges.subscribe(this.actionUpdate.bind(this))
    ];

    this.userPermissionService.granted([readWrite('admin-failure-reasons')])
      .subscribe(res => {
        this.readWriteFailureReason = res;

        this.appBarActionsService.enable('save', res);
        this.appBarActionsService.enable('delete',
          this.data && this.data.failureReason && this.data.failureReason.id && res && !this.deleteSub ? true : false);
        this.appBarActionsService.enable('delete', res);

        if (res) {
          this.form.controls.usage.enable();
          this.form.controls.active.enable();
        } else {
          this.form.controls.usage.disable();
          this.form.controls.active.disable();
        }
      });
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;

    if (!this.form.valid)
      return;

    if (!this.form.dirty) {
      this.cancel();
      return;
    }

    if (this.data && this.data.failureReason && this.data.failureReason.id) {
      FailureReasonUpdateDialogComponent.show(this.dialog, this.form, "Update").subscribe(result => {
        if (result) {
          this.saveSub = this.failureReasonService
            .update(this.data.failureReason.id, this.form.value)
            .subscribe({
              next: this.saveSuccessHandler.bind(this),
              error: this.saveErrorHandler.bind(this)
            })
        }
      });
    } else {
      FailureReasonUpdateDialogComponent.show(this.dialog, this.form, "Create").subscribe(result => {
        if (result)
          this.saveSub = this.failureReasonService
            .create(this.form.value)
            .subscribe({
              next: this.saveSuccessHandler.bind(this),
              error: this.saveErrorHandler.bind(this)
            })
      });
    }
  }

  formatUsage(usage: FailureReasonUsage): string {
    return FailureReasonUsageLabel.get(usage);
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.actionUpdate();
    this.appPageService.back();
  }
}

export interface FailureReasonDetailsComponentData {
  failureReason: FailureReasonData;
}
