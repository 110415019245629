import { Component } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AutocompleteComponent } from 'common';
import { AdvisorService } from '../advisor.service';
import { AdvisorHintData } from '../advisor.model';

@Component({
  selector: 'ifb-advisor-email-input',
  templateUrl: '../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class AdvisorEmailInputComponent extends AutocompleteComponent {

  constructor(private advisorService: AdvisorService) {
    super();
  }

  label = 'Email';

  optionFilter: (input: string | AdvisorHintData) => Observable<any[]> =
    input => input
      ? this.advisorService.queryHints({
        email: !!input ? (typeof input !== 'string' ? input.email : input) : null,
        sortField: 'email'
      })
      : EMPTY;

  optionLabelKey = 'email';
}
