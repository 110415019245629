import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { AppBarTitleService, AppBarActionsService, AppBarAction, DialogConfirm, MessageService, FormHelper, AppPageService } from 'common';
import { TokenService } from '../token.service';
import { TokenData } from '../token.model';
import { ClientData } from '../../../auth/client/client.data';
import { MatDialog } from '@angular/material/dialog';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { TokenUpdateDialogComponent } from '../token-update-dialog/token-update-dialog.component';

@Component({
  selector: 'ifb-token-details',
  templateUrl: './token-details.component.html',
  styleUrls: ['./token-details.component.css']
})
export class TokenDetailsComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private tokenService: TokenService,
    private userPermissionService: UserPermissionService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private messageService: MessageService,
    private dialog: MatDialog) {

    this.appBarActionsService.actions = [
      { id: 'delete', label: 'Delete', disabled: true, buttonType: 'button' },
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.formInit();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService
      .enable('delete', this.data && this.data.token && this.data.token.id && this.readWriteToken && !this.deleteSub ? true : false);
  }

  cancel() {
    this.appPageService.back();
  }

  copy(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
  }

  data: TokenDetailsComponentData;

  minExpirationDate = new Date(new Date().setDate(new Date().getDate() + 1));

  dataInit(data: TokenDetailsComponentData) {
    this.data = data;
    if (data.token)
      this.form.patchValue(data.token);

    this.appBarTitleService.title = data.token && data.token.id
      ? `Client ${data.token.clientId}`
      : 'Create new token';

    this.form.markAsPristine();
    this.actionUpdate();
  }

  delete() {
    if (!this.data || !this.data.token || !this.data.token.id)
      this.appPageService.back();

    DialogConfirm.show(this.dialog, `Delete token?`).subscribe(result => {
      if (result) {
        this.deleteSub = this.tokenService.delete(this.data.token.id).subscribe(() => {
          this.appPageService.back();
        });
      }

      this.actionUpdate();
    });
  }

  deleteSub: Subscription;

  form: UntypedFormGroup;

  private formInit() {
    this.form = this.formBuilder.group({
      id: undefined,
      client: [undefined, Validators.required],
      description: undefined,
      expiresAt: new UntypedFormControl(undefined, { validators: Validators.required, updateOn: 'blur' }),
      active: [undefined, Validators.required],
      token: undefined,
      createdBy: undefined,
      createdOn: undefined
    });
  }

  readWriteToken: boolean;

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.appBarActionsService.invoking.subscribe(this.actionDispatch.bind(this)),
      this.form.statusChanges.subscribe(this.actionUpdate.bind(this))
    ];

    this.userPermissionService.granted([readWrite('admin-tokens')])
      .subscribe(res => {
        this.readWriteToken = res;
        this.appBarActionsService.enable('delete', res);
      });
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;

    if (!this.form.valid)
      return;

    if (!this.form.dirty) {
      this.cancel();
      return;
    }

    if (this.data?.token?.id) {
      TokenUpdateDialogComponent.show(this.dialog, this.form, "Update").subscribe(result => {
        if (result) {
          this.saveSub = this.tokenService
            .update(this.data.token.id, this.form.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
          this.actionUpdate();
          this.appPageService.back();
        }
      });
    } else {
      const clientName = this.data.clients.find(_ => _.id === this.form.value.client).name;
      TokenUpdateDialogComponent.show(this.dialog, this.form, "Create", clientName).subscribe(result => {
        if (result)
          this.saveSub = this.tokenService
            .create(this.form.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        this.actionUpdate();
      });
    }
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSub: Subscription;

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveSuccessHandler(token: TokenData) {
    this.saveSubClear();
    if (this.data.token) {
      this.data.token = token;
      this.dataInit(this.data);
      this.actionUpdate();
    } else {
      this.router.navigate(['admin', 'token', token.id], { replaceUrl: true });
    }
  }

}

export interface TokenDetailsComponentData {
  token: TokenData;
  clients: ClientData[];
}
