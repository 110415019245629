import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../account/account.service';
import { AccountBreakdown } from '../../../account/account.model';

@Component({
  selector: 'ifb-account-breakdown-list',
  templateUrl: './account-breakdown-list.component.html',
  styleUrls: ['./account-breakdown-list.component.scss']
})
export class AccountBreakdownListComponent implements OnInit {

  data: AccountBreakdown;

  constructor(private _accountService: AccountService) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this._accountService.breakdownInfo()
      .subscribe((data) => this.data = data);
  }

}
