<div fxLayoutAlign="center center">
  <form [formGroup]="form" class="timer">
    <div fxLayout="row" fxLayoutAlign="strech" fxLayoutGap="10px" fxLayoutGap.xs="">

      <div fxLayout="column" fxLayoutAlign="center center">
        <button mat-button mat-icon-button (click)="changeTime(form.get('hours'), 1, 12)" [disabled]="form.get('hours').value >= maxHours">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>

        <mat-form-field appearance="outline">
          <input matInput formControlName="hours" type="number" (blur)="setDate()">
        </mat-form-field>

        <button mat-button mat-icon-button (click)="changeTime(form.get('hours'), -1, 12)" [disabled]="form.get('hours').value <= 0">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center">
        <p style="font-size: 36px;">
          :
        </p>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center">
        <button mat-button mat-icon-button (click)="changeTime(form.get('minutes'), 5, 59)" [disabled]="form.get('minutes').value >= maxMin">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>

        <mat-form-field appearance="outline">
          <input matInput formControlName="minutes" type="number" (blur)="setDate()">
        </mat-form-field>

        <button mat-button mat-icon-button (click)="changeTime(form.get('minutes'), -5, 59)" [disabled]="form.get('minutes').value <= 0">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" class="meridian">
        <button [ngClass]="!pm ? 'green' :''" mat-mini-fab color="primary" (click)="setTime(false)">AM</button>
        <button [ngClass]="pm ? 'green' :''" mat-mini-fab (click)="setTime(true)">PM</button>
      </div>

    </div>
  </form>
</div>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [disabled]="!form.valid" [mat-dialog-close]="form.dirty ? date : false" color="primary">Ok</button>
</div>