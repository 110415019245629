import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper } from "../../helpers/enum.helper";
import { OfferStatus, OfferStatusLabel } from "../models/offer.model";

@Pipe({
  name: "offerStatusLabel",
})
export class OfferStatusLabelPipe implements PipeTransform {
  transform(value: OfferStatus): string {
    return EnumHelper.getMappedNameFromValue(
      OfferStatus,
      OfferStatusLabel,
      value
    );
  }
}
