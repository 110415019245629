import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { FilterParameter } from 'common';
import { BankingAccountFilters, BankingAccountQueryParams } from '../banking.model';

@Injectable({
    providedIn: 'root'
})
export class BankingAccountListFilterService {
    constructor(private router: Router) { }

    filter(
        provider: string,
        filters: BankingAccountFilters,
        bankingCustomerId?: string,
        paginator?: MatPaginator) {
        const query: BankingAccountQueryParams = filters;

        // Set default to first page
        query.skip = undefined;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }



        this.router.navigate([`banking/${provider}/customers/${bankingCustomerId}/accounts/`], { replaceUrl: true, queryParams: query });

    }

    addFilterParameters(query: BankingAccountQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.onlyPendingAccounts) {
            filters.push({
                id: 'onlyPendingAccounts', name: 'Account status', value: 'Pending'
            });
        }

        return filters;
    }
}
