import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { TransactionQueryResult, TransactionData, TransactionQueryParams } from "./transaction.model";
import { TransactionService } from "./transaction.service";
import {
  AuditQueryParams,
  AuditQueryResult,
} from "../shared/audit-trail/audit-trail.model";
import { UserPermissionService } from "../user/user-permission/user-permission.service";
import { readOnly } from "../user/user-permission/user-permission.data";
import { AccountService } from "../account/account.service";
import { map, switchMap } from "rxjs/operators";
import { AuditTrailService } from "../shared/audit-trail/audit-trail.service";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class TransactionResolver implements Resolve<TransactionData> {
  constructor(private service: TransactionService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TransactionData> | Promise<TransactionData> | TransactionData {
    return route.params.id === "new"
      ? of({})
      : this.service.get(parseNumber(route.params.id));
  }
}

@Injectable({
  providedIn: "root",
})
export class TransactionQueryResolver
  implements Resolve<TransactionQueryResult>
{
  constructor(
    private service: TransactionService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<TransactionQueryResult>
    | Promise<TransactionQueryResult>
    | TransactionQueryResult {
    return this.service.query(new TransactionQueryParams(this.queryParamsService.init(
      route.queryParams
    )));
  }
}

@Injectable({
  providedIn: "root",
})
export class TransactionByLoanQueryResolver
  implements Resolve<TransactionQueryResult>
{
  constructor(
    private service: TransactionService,
    private accountService: AccountService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<any>
    | Promise<TransactionQueryResult>
    | TransactionQueryResult {
    return this.accountService.get(parseNumber(route.params.id)).pipe(
      map((res) => res),
      switchMap((res) => {
        if (!res) {
          return of([]);
        }
        const params = new TransactionQueryParams({
          ...route.queryParams,
          loanId: res.id,
          renewalFor: res.renewalFor,
        });
        return this.service.query(this.queryParamsService.init(params));
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class TransactionByUniqueIdQueryResolver
  implements Resolve<TransactionQueryResult>
{
  constructor(
    private accountService: AccountService,
    private transactionService: TransactionService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<any>
    | Promise<TransactionQueryResult>
    | TransactionQueryResult {
    return this.accountService.getByUniqueId(route.params.uniqueId).pipe(
      map((res) => res),
      switchMap((res) => {
        if (!res) {
          return of([]);
        }
        const params = new TransactionQueryParams({
          ...route.queryParams,
          loanId: res.id,
          renewalFor: res.renewalFor,
        });
        return this.transactionService.query(this.queryParamsService.init(params));
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class TransactionAuditTrailResolver
  implements Resolve<AuditQueryResult>
{
  constructor(
    private service: AuditTrailService,
    private userPermissionService: UserPermissionService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<AuditQueryResult> | AuditQueryResult {
    const params = new AuditQueryParams(
      this.queryParamsService.init({
        ...route.queryParams,
        transaction: route.params.id,
      })
    );
    return this.userPermissionService
      .granted([readOnly("admin-audit-log")])
      .pipe(
        map((res) => res),
        switchMap((res) => (res ? this.service.audit(params) : of([])))
      );
  }
}
