import * as moment from 'moment';

export class MomentJSHelper {

    static getActiveMonthsCurrentYear() {
        const months = [];
        const nowNormalized = moment().startOf("month").add(1, 'month');
        const startDateNormalized = moment(`${moment().year()}-01-01`);

        while (startDateNormalized.isBefore(nowNormalized)) {
            months.push(startDateNormalized.format("MMMM"));
            startDateNormalized.add(1, 'month');
        }

        return months;
    }
}
