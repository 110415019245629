import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  EnumHelper,
  EnumValueLabelPipe,
  FormHelper,
  TransactionStatus,
  TransactionStatusLabel,
  TransactionType
} from "common";
import { FailureReasonUsage } from "../../admin/failure-reason/failure-reason.model";
import { map } from "rxjs/operators";
import { UserPermissionService } from "../../user/user-permission/user-permission.service";
import { readOnly } from "../../user/user-permission/user-permission.data";
import { CompetitorHintData } from "../../competitors/models/competitor.model";
import {DrawRequirementData, DrawRequirementType, DrawRequirementTypeLabel} from "../transaction.model";

@Component({
  selector: 'ifb-transaction-list-update-dialog',
  templateUrl: './transaction-list-update-dialog.component.html',
  styleUrls: ['./transaction-list-update-dialog.component.css']
})
export class TransactionListUpdateDialogComponent {
  canReadCompetitors: boolean;
  isCompetitorsSpecifiable$: Observable<boolean>;
  form: UntypedFormGroup;
  public TransactionStatus = TransactionStatus;
  public TransactionType = TransactionType;
  public showWarning: boolean;
  public drawRequirementWarnings: string[] = [];

  get FailureReasonUsage() { return FailureReasonUsage; }

  constructor(public dialogRef: MatDialogRef<TransactionListUpdateDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly userPermissionService: UserPermissionService,
    private readonly enumValueLabelPipe: EnumValueLabelPipe) {
      this.form = this.formBuilder.group({
        note: [undefined],
        reasons: [undefined],
        competitors: [undefined]
      });

      this.showWarning = this.data.status === TransactionStatus.Approved
        && this.data.drawRequirements?.some((r: DrawRequirementData) => r.required && !r.requirementCompleted);

      if (this.showWarning){
          this.setWarningFlags();
      }

      if (this.showWarning) {
        let noteControl = this.form.get('note');
        noteControl.setValidators(Validators.required);
      }

      this.userPermissionService.granted([readOnly('admin-competitors')])
        .subscribe(res => this.canReadCompetitors = res);

      this.isCompetitorsSpecifiable$ = this.form.valueChanges.pipe(
        map(form => form?.reasons),
        map(selectedReasons =>
          selectedReasons?.some(selectedReason =>
            this.data.failureReasons?.find(reason =>
              reason.code === selectedReason
            )?.allowToSpecifyCompetitors)
        )
      );
    }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (!this.form.valid)
      return;
    this.dialogRef.close(this.form.value);
  }

  onCompetitorsChange(competitors: CompetitorHintData[]) {
    this.form.get('competitors').setValue(competitors);
    this.form.get('competitors').markAsDirty();
    this.form.get('competitors').markAsTouched();
    this.form.get('competitors').updateValueAndValidity();
  }

  showStatus(status: number): string {
    return EnumHelper.getMappedNameFromValue(TransactionStatus, TransactionStatusLabel, status);
  }

  private setWarningFlags() {
    this.data.drawRequirements?.forEach((r: DrawRequirementData) => {
      if (r.required && !r.requirementCompleted) {
        const label = this.enumValueLabelPipe.transform(r.type, DrawRequirementType, DrawRequirementTypeLabel);
        if (label !== null) {
          this.drawRequirementWarnings.push(label);
        }
      }
    });
  }

  static show(
    dialog: MatDialog,
    loanNumber,
    status,
    failureReasons,
    previousStatus,
    transactionType,
    drawRequirements: DrawRequirementData[]
  ): Observable<any> {
    return dialog.open(TransactionListUpdateDialogComponent, {
      width: '510px',
      data: {
        loanNumber,
        status,
        failureReasons,
        previousStatus,
        transactionType,
        drawRequirements
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
