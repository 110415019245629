<div class="table-container mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="paymentNumber">
      <th mat-header-cell *matHeaderCellDef class="right-align">Payment number</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.paymentNumber}}
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{row.date | date: 'shortDate'}}
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{row.type}}
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef class="right-align">Amount</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.amount | currency:'$'}}
    </ng-container>

    <ng-container matColumnDef="principalAmount">
      <th mat-header-cell *matHeaderCellDef class="right-align">Principal Amount</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.principalAmount | currency:'$'}}
    </ng-container>

    <ng-container matColumnDef="interestAmount">
        <th mat-header-cell *matHeaderCellDef class="right-align">Interest Amount</th>
        <td mat-cell *matCellDef="let row" class="table-cell right-align">
          {{row.interestAmount | currency:'$'}}
      </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef class="right-align">Closing Balance</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.balance | currency:'$'}}
    </ng-container>

    <ng-container matColumnDef="interestBalance">
      <th mat-header-cell *matHeaderCellDef class="right-align">Outstanding Interest</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.interestBalance | currency:'$'}}
    </ng-container>

    <ng-container matColumnDef="totalInterest">
      <th mat-header-cell *matHeaderCellDef class="right-align">Total Interest Cost</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.totalInterest | currency:'$'}}
    </ng-container>

    <ng-container matColumnDef="totalPrincipal">
      <th mat-header-cell *matHeaderCellDef class="right-align">Total Capital Repayment</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.totalPrincipal | currency:'$'}}
    </ng-container>

    <ng-container matColumnDef="totalPayback">
      <th mat-header-cell *matHeaderCellDef class="right-align">Total Payback</th>
      <td mat-cell *matCellDef="let row" class="table-cell right-align">
        {{row.totalInterest + row.totalPrincipal | currency:'$'}}
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns" class="table-row"></tr>
  </table>

</div>