<div class="message-box info" *ngIf="noDataFound" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
  <mat-icon class="icon">info</mat-icon>
  <span>No data found</span>
</div>

<div class="table-container" *ngIf="!noDataFound">

  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="clientName">
      <th mat-header-cell *matHeaderCellDef> Client </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
        {{row.clientName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username/email </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
        {{row.username}}
      </td>
    </ng-container>
    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef> External ID </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
        {{row.externalId}}
      </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> First name </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
        {{row.firstName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="middleName">
      <th mat-header-cell *matHeaderCellDef> Middle name </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
        {{row.middleName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Last name </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell"> {{row.lastName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastLoginDate_IP">
      <th mat-header-cell *matHeaderCellDef> Last login time/IP address </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
        {{row.lastLogin | date:'medium'}} {{row.lastLogin && row.lastLoginAddress ? '/' : ''}}
        {{row.lastLoginAddress}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
        {{row.active ? 'Active' : 'Inactive'}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
  </table>

  <div [hidden]="dataSource.data.length <= 10">
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>

</div>