import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import {
  BrokerExData,
  BrokerType,
  BrokerTypeLabel,
  BrokerTier,
  BrokerTierLabel
} from '../broker.model';

@Component({
  selector: 'ifb-broker-other-info-widget',
  templateUrl: './broker-other-info-widget.component.html',
  styleUrls: ['./broker-other-info-widget.component.css']
})
export class BrokerOtherInfoWidgetComponent {

  constructor(private router: Router, private settings: AppSettings) { }

  @Input()
  broker: BrokerExData;

  @Input()
  edit: boolean;

  formatType(type: BrokerType): string {
    return BrokerTypeLabel.get(type);
  }

  formatTier(tier: BrokerTier): string {
    return BrokerTierLabel.get(tier);
  }

  salesforceLink(salesforceId: string) {
    return this.settings.salesforce.url + salesforceId;
  }

  detailsLink() {
    this.router.navigate([`broker/${this.broker.id}/info`]);
  }

}
