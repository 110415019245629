import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { AppBarExpandedTab, EntityQueryParams, EntityQueryResult, parseNumber } from "common";
import { UserPermissionService } from "../user/user-permission/user-permission.service";
import { EntityService } from "../entity/entity.service";
import { AdvisorService } from "./advisor.service";
import { AdvisorData, AdvisorQueryResult, advisorAppBarExpandedTabs } from "./advisor.model";
import { AccountQueryResult } from "../account/account.model";
import { AccountService } from "../account/account.service";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Injectable({
    providedIn: 'root'
})
export class AdvisorResolver implements Resolve<AdvisorData> {
    constructor(private service: AdvisorService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AdvisorData> | Promise<AdvisorData> | AdvisorData {
        return route.params.id === 'new'
            ? undefined
            : this.service.get(parseNumber(route.params.id));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AdvisorQueryResolver implements Resolve<AdvisorQueryResult> {
    constructor(private service: AdvisorService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AdvisorQueryResult>
        | Promise<AdvisorQueryResult> | AdvisorQueryResult {
        return this.service.query(this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AdvisorAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            advisorAppBarExpandedTabs(parseNumber(route.params.id)).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AdvisorEntityQueryResolver implements Resolve<EntityQueryResult> {
    constructor(private service: EntityService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<EntityQueryResult> | Promise<EntityQueryResult> | EntityQueryResult {
        const params = new EntityQueryParams(this.queryParamsService.init(route.queryParams));
        params.advisorId = parseNumber(route.params.id);
        return this.service.query(params);
    }
}

@Injectable({
    providedIn: 'root'
})
export class AdvisorAccountQueryResolver implements Resolve<AccountQueryResult> {
    constructor(private service: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountQueryResult> | Promise<AccountQueryResult> | AccountQueryResult {
        const params = Object.assign({}, route.queryParams);
        params.advisorId = parseNumber(route.params.id);
        return this.service.query(params);
    }
}
