<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">
    
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>Client</mat-label>
        <input *ngIf="!data.clients" matInput formControlName="clientName" type="text" placeholder="Client">
        <mat-select *ngIf="data.clients" matInput formControlName="clientName" placeholder="Client">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let client of data.clients" [value]="client.name">{{client.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="mat-form-field">
        <ifb-user-email-input [fc]="form.get('username')"></ifb-user-email-input>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName" type="text" placeholder="First name">
        <button type="button" *ngIf="form.get('firstName').value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.get('firstName').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last name</mat-label>
        <input matInput formControlName="lastName" type="text" placeholder="Last name">
        <button type="button" *ngIf="form.get('lastName').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('lastName').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>External ID</mat-label>
        <input matInput formControlName="externalId" type="text" placeholder="External ID">
        <button type="button" *ngIf="form.get('externalId').value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.get('externalId').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div>
        <ifc-filter-param-date-range-input [fc]="form.get('lastLogin')" [label]="'Date of last login'"
          (rangeSelected)="lastLoginDateSelected($event)">
        </ifc-filter-param-date-range-input>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      *ngIf="form.get('lastLogin').value === 'custom'" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <ifc-datepicker [fc]="form.get('lastLoginStart')" [label]="'Date of last login start'"></ifc-datepicker>

      <ifc-datepicker [fc]="form.get('lastLoginEnd')" [label]="'Date of last login end'"></ifc-datepicker>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
          <mat-option>All</mat-option>
          <mat-option value="true">Active</mat-option>
          <mat-option value="false">Inactive</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>
