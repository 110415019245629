<ngx-charts-line-chart
  [view]="view"
  [results]="data"
  [scheme]="options.colorScheme"
  [legend]="options.legend"
  [legendTitle]= 'options.legendTitle'
  [legendPosition]= 'options.legendPosition'
  [showXAxisLabel]="options.showXAxisLabel"
  [showYAxisLabel]="options.showYAxisLabel"
  [xAxis]="options.xAxis"
  [yAxis]="options.yAxis"
  [xAxisLabel]="options.xAxisLabel"
  [yAxisLabel]="options.yAxisLabel"
  [timeline]="options.timeline"
  [curve]="options.curve">
</ngx-charts-line-chart>