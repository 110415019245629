<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center"
      fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Client</mat-label>
        <input *ngIf="!data.clients" matInput formControlName="clientId" type="text" placeholder="Client">
        <mat-select *ngIf="data.clients" matInput formControlName="clientId" placeholder="Client">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let client of data.clients" [value]="client.id">{{client.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Role</mat-label>
        <input matInput formControlName="name" type="text" placeholder="Role">
        <button type="button" *ngIf="form.get('name').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('name').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center"
      fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>External ID</mat-label>
        <input matInput formControlName="externalId" type="text" placeholder="External ID">
        <button type="button" *ngIf="form.get('externalId').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('externalId').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
            <mat-option>All</mat-option>
            <mat-option value="true">Active</mat-option>
            <mat-option value="false">Inactive</mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>