import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountData, DelinquentStatus } from '../account.model';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService, AppBarActionsService, MessageService, AppBarAction, DialogConfirm, LoanStatus, EnumHelper, LoanInfoDto, FormHelper, AccountActivationStage, AppPageService, DateHelper } from 'common';
import { AccountService } from '../account.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { ProductData } from '../../product/product.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ifb-account-profile-status-details',
  templateUrl: './account-profile-status-details.component.html',
  styleUrls: ['./account-profile-status-details.component.scss']
})

export class AccountProfileStatusDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  private saveSub: Subscription;
  form: UntypedFormGroup;
  data: AccountStateDetailsComponentData;
  logs: any;

  accountStatusOptions: LoanStatus[] = [];
  private statuses = EnumHelper.getNamesAndValues(LoanStatus);

  delinquentStatuses = EnumHelper.getNamesAndValues(DelinquentStatus);

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
  ) {

    this._unsubscribeAll = new Subject();

    this.formInit();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: !this.form.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.logs = EnumHelper.getNamesAndValues(this.data.account.activationStageLog);

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  formatPascalCase(text: string): string {
    const result = text.replace(/([A-Z])/g, " $1").toLowerCase();
    return result.charAt(1).toUpperCase() + result.slice(2);
  }

  cancel() {
    this.appPageService.back();
  }

  private formInit() {
    this.form = this.formBuilder.group({
      status: [undefined, Validators.required],
      frozenUntil: undefined,
      freezeForDays: undefined,
      delinquentStatus: [undefined, Validators.required],
      activationStage: [{ value: undefined, disabled: true }],
      createdOn: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      agreementSignatureDate: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      renewalDate: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      fullAmortizationStartDateUtc: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      updatedOn: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
    });
  }

  dataInit(data: AccountStateDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Status and activation';

    this.form.reset({
      status: this.data.account.status !== null ? this.data.account.status : '',
      frozenUntil: this.data.account.frozenUntil !== null ? this.data.account.frozenUntil : '',
      freezeForDays: this.data.account.freezeForDays !== null ? this.data.account.freezeForDays : '',
      delinquentStatus: this.data.account.delinquentStatus !== null ? this.data.account.delinquentStatus : DelinquentStatus.Current,
      activationStage: this.data.account.activationStage
        ? EnumHelper.getNameFromValue(AccountActivationStage, this.data.account.activationStage)
        : '',
      createdOn: this.data.account.createdOn || '',
      agreementSignatureDate: this.data.account.openedOn || '',
      fullAmortizationStartDateUtc: DateHelper.getUTCDateAsDateLocal(this.data.account.fullAmortizationStartDateUtc),
      renewalDate: this.data.account.renewalDate || '',
      updatedOn: this.data.account.updatedOn || ''
    });

    const product = data.products.find(p => p.id === data.account.productId);
    this.accountStatusOptions = _.flatMap(this.statuses.filter(i => _.includes(product.allowedLoanStatuses, i.value)));

    this.form.markAsPristine();
    this.actionUpdate();
  }
  
  clear(fc: string, form: UntypedFormGroup) {
    const formControl = form.get(fc);
    formControl.reset();
    formControl.markAsTouched();
    formControl.markAsDirty();
    form.markAsDirty();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    const accountStatus = _.startCase(EnumHelper.getNameFromValue(LoanStatus, this.data.account.status));
    const accountStatusUpdated = _.startCase(EnumHelper.getNameFromValue(LoanStatus, this.form.controls.status.value));
    const delinquentStatus = _.startCase(EnumHelper.getNameFromValue(DelinquentStatus, this.data.account.delinquentStatus));
    const delinquentStatusUpdated = _.startCase(EnumHelper.getNameFromValue(DelinquentStatus, this.form.controls.delinquentStatus.value));
    const freezeForDays = this.data.account.freezeForDays || 0;
    const freezeForDaysUpdated = this.form.controls.freezeForDays.value || 0;
    const fullAmortizationStartDateUtc = this.data.account.fullAmortizationStartDateUtc;
    const fullAmortizationStartDateUtcUpdated = this.form.controls.fullAmortizationStartDateUtc.value  === '' ? null : this.form.controls.fullAmortizationStartDateUtc.value;
    let updateMessage = `You are changing ${this.data.account.loanNumber}’s`;

    if (accountStatus !== accountStatusUpdated)
      updateMessage += ` status from ${accountStatus} to ${accountStatusUpdated}`;
    if (delinquentStatus !== delinquentStatusUpdated) {
      if (accountStatus !== accountStatusUpdated)
        updateMessage += ','
      updateMessage += ` delinquent status from ${delinquentStatus} to ${delinquentStatusUpdated}`;
    }
    if (freezeForDays !== freezeForDaysUpdated)
      updateMessage += `, freeze for days from ${freezeForDays} to ${freezeForDaysUpdated}`;

    if (fullAmortizationStartDateUtc !== fullAmortizationStartDateUtcUpdated)
      updateMessage += `, full amortization start date from ${this.datePipe.transform(fullAmortizationStartDateUtc,'mediumDate') || '[empty]'} 
        to ${this.datePipe.transform(fullAmortizationStartDateUtcUpdated,'mediumDate') || '[empty]'}`;

    updateMessage += '. Are you sure you want to proceed?';

    // eslint-disable-next-line max-len
    DialogConfirm.show(this.dialog, updateMessage)
      .subscribe(result => {
        if (result)
          this.saveSub = this.accountService.updateAccountStatus(this.data.account.id, {
            status: this.form.controls.status.value,
            delinquentStatus: this.form.controls.delinquentStatus.value,
            freezeForDays: this.form.controls.freezeForDays.value,
            fullAmortizationStartDate: DateHelper.getLocalDateAsUTC(this.form.controls.fullAmortizationStartDateUtc.value)
          })
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.accountService.get(this.data.account.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => this.accountService.onCurrentLoanChanged.next(data));
    this.appPageService.back();
  }
}

export interface AccountStateDetailsComponentData {
  account: AccountData;
  accountInfo: LoanInfoDto;
  products: ProductData[];
}
