import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, FilterParameter } from 'common';
import { ApiKeyFilters, ApiKeyQueryParams } from './api-key.model';

@Injectable({
    providedIn: 'root'
})
export class ApiKeyFilterService {

    constructor(
        private router: Router
    ) { }

    filter(filters: ApiKeyFilters, paginator?: MatPaginator, sort?: MatSort) {
        const query: ApiKeyQueryParams = filters;

        // Set default to first page
        query.skip = undefined;

        query.createdOnStart = filters.createdOnStart ? moment(filters.createdOnStart).format('L') : undefined;
        query.createdOnEnd = filters.createdOnEnd ? moment(filters.createdOnEnd).format('L') : undefined;
        query.updatedOnStart = filters.updatedOnStart ? moment(filters.updatedOnStart).format('L') : undefined;
        query.updatedOnEnd = filters.updatedOnEnd ? moment(filters.updatedOnEnd).format('L') : undefined;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        this.router.navigate(['admin', 'apikey'], { replaceUrl: true, queryParams: query });

    }

    addFilterParameters(query: ApiKeyQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.clientId) {
            filters.push({
                id: 'clientId', name: 'Client', value: query.clientId
            });
        }
        if (query.description) {
            filters.push({
                id: 'description', name: 'Description', value: query.description.trim()
            });
        }
        if (query.createdBy) {
            filters.push({
                id: 'createdBy', name: 'Created by', value: query.createdBy.trim()
            });
        }
        if (query.createdOnStart) {
            filters.push({
                id: 'createdOnStart', name: 'Created on',
                value: `${moment(query.createdOnStart).format('L')} - ${moment(query.createdOnEnd).format('L')}`
            });
        }
        if (query.updatedOnStart) {
            filters.push({
                id: 'updatedOnStart', name: 'Updated on',
                value: `${moment(query.updatedOnStart).format('L')} - ${moment(query.updatedOnEnd).format('L')}`
            });
        }

        if (query.active) {
            filters.push({
                id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
            });
        }

        return filters;
    }
}
