import { QueryResult, QueryParams, parseNumber } from "common";

export interface Log {
    id: number;
    timestamp?: string;
    level?: LogLevel;
    threadId?: string;
    processId?: string;
    endpoint?: string;
    hostName?: string;
    logger?: string;
    message?: string;
    errorId?: string;
    exception?: string;
    correlationId?: string;
    principalId?: string;
    principalName?: string;
    entity?: string;
    loan?: string;
    customer?: string;
    exceptionType?: string;
    exceptionTargetSite?: string;
    exceptionHashCode?: string;
}

export interface LogDetailsData {
    log?: Log;
    json?: string;
}

export enum LogLevel {
    Fatal = 'FATAL',
    Error = 'ERROR',
    Warn = 'WARN',
    Info = 'INFO',
    Debug = 'DEBUG'
}

export interface LogGroup {
    groupName?: string;
    groupId?: string;
    messageCount?: Number;
    firstOccurrence?: Date;
    lastOccurrence?: Date;
}

export interface LogGroupQueryResult extends QueryResult<LogGroup> {
    messageCountSum?: number;
}

export enum LogGroupByOption {
    None = '',
    ExceptionType = 'exceptionType',
    ExceptionTargetSite = 'exceptionTargetSite',
    HostName = 'hostName',
    Endpoint = 'endpoint',
    Entity = 'entity',
    Loan = 'loan',
    Customer = 'customer',
    PrincipalId = 'principalId',
    Logger = 'logger',
    Level = 'level'
}

export const LogGroupByLabel = new Map<string, string>([
    [LogGroupByOption.None, 'None'],
    [LogGroupByOption.ExceptionType, 'Exception type'],
    [LogGroupByOption.ExceptionTargetSite, 'Exception stack trace'],
    [LogGroupByOption.HostName, 'Hostname'],
    [LogGroupByOption.Endpoint, 'Endpoint name'],
    [LogGroupByOption.Entity, 'Entity'],
    [LogGroupByOption.Loan, 'Loan'],
    [LogGroupByOption.Customer, 'Customer'],
    [LogGroupByOption.PrincipalId, 'Principal'],
    [LogGroupByOption.Logger, 'Logger'],
    [LogGroupByOption.Level, 'Level'],
]);

export interface LogQueryResult extends QueryResult<Log> {
}

export class LogQueryParams extends QueryParams {
    id?: number;
    groupBy?: any;
    timestamp?: any;
    timestampStart?: any;
    timestampEnd?: any;
    level?: string;
    threadId?: string;
    processId?: string;
    endpoint?: string;
    hostName?: string;
    logger?: string;
    errorId?: string;
    correlationId?: string;
    principalId?: string;
    principalName?: string;
    entityId?: string;
    loanId?: string;
    customerId?: string;
    exceptionHashCode?: string;

    constructor(params?: any) {
        super(params);
        this.id = parseNumber(params.id) || undefined
    }
}

export interface LogFilterOptions {
    levelOptions?: string[];
    loggerOptions?: string[];
    endpointOptions?: string[];
    hostnameOptions?: string[];
}

export class LogFilters {
    id?: any;
    timestamp?: any;
    timestampStart?: any;
    timestampEnd?: any;
    level?: any;
    endpoint?: any;
    hostName?: any;
    logger?: any;
    errorId?: any;
    correlationId?: any;
    principalId?: any;
    principalName?: any;
    entityId?: any;
    loanId?: any;
    customerId?: any;
    groupBy?: any;
    active?: any;
    exceptionHashCode?: any;

    constructor(filters?) {
        filters = filters || {};
        this.id = filters.id || undefined;
        this.timestamp = filters.timestamp || undefined;
        this.timestampStart = filters.timestampStart || undefined;
        this.timestampEnd = filters.timestampEnd || undefined;
        this.level = filters.level || undefined;
        this.endpoint = filters.endpoint || undefined;
        this.hostName = filters.hostName || undefined;
        this.logger = filters.logger || undefined;
        this.errorId = filters.errorId || undefined;
        this.correlationId = filters.correlationId || undefined;
        this.principalId = filters.principalId || undefined;
        this.principalName = filters.principalName || undefined;
        this.entityId = filters.entity || undefined;
        this.loanId = filters.loan || undefined;
        this.customerId = filters.customer || undefined;
        this.groupBy = filters.groupBy || undefined;
        this.active = filters.active || undefined;
        this.exceptionHashCode = filters.exceptionHashCode || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'timestamp' || item === 'timestampStart') {
            this.timestamp = undefined;
            this.timestampStart = undefined;
            this.timestampEnd = undefined;
        }
    }
}
