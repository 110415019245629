import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../../transaction/transaction.service';
import { TransactionQueryParams, TransactionsGroupedByAdvisor } from '../../../transaction/transaction.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MomentJSHelper } from '../../../shared/utils/moment-js-helper';
import { TransactionType } from 'common';

@Component({
  selector: 'ifb-draws-leaderboard-list',
  templateUrl: './draws-leaderboard-list.component.html',
  styleUrls: ['./draws-leaderboard-list.component.scss']
})
export class DrawsLeaderboardListComponent implements OnInit {

  months: string[];
  currentMonth: string;
  data: TransactionsGroupedByAdvisor[] = [];

  constructor(private _transactionService: TransactionService) {
    this.currentMonth = moment().format('MMMM');
    this.months = MomentJSHelper.getActiveMonthsCurrentYear();
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    const filters: TransactionQueryParams = { type: [TransactionType.Draw] };
    if (this.currentMonth) {
      filters.fromDate = moment().month(this.currentMonth).startOf('month').format('L');
      filters.toDate = moment().month(this.currentMonth).endOf('month').format("L");
    }
    this._transactionService.groupedByAdvisor(filters)
      .subscribe((data) => this.data = _.orderBy(data, 'totalAmount', 'desc'));
  }

  filterByMonth(value: string): void {
    this.currentMonth = value;
    this.fetchData();
  }

}
