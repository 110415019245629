import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TransactionData, TransactionStatusChips } from '../transaction.model';
import { UserPermissionItem } from '../../user/user-permission/user-permission.service';
import { SubtransactionTypeInternalLabel, TransactionStatus, TransactionType } from 'common';
import { FailureReasonData } from '../../admin/failure-reason/failure-reason.model';

@Component({
  selector: 'ifb-transaction-list-expanded-row',
  templateUrl: './transaction-list-expanded-row.component.html',
  styleUrls: ['./transaction-list-expanded-row.component.css']
})
export class TransactionListExpandedRowComponent implements OnInit {

  constructor() { }
  get TransactionStatus() { return TransactionStatus; }
  get status() { return TransactionStatusChips; }

  header: Header;

  @Input()
  transaction: TransactionData;

  @Input()
  failureReasons: FailureReasonData;

  @Input()
  expandedDetail: boolean;

  @Output()
  statusChanged = new EventEmitter<any>();

  @Output()
  expandedDetailChanged = new EventEmitter<any>();

  @Input()
  canReadAccounts: boolean;

  @Input()
  adminEdit: boolean;

  @Input()
  CanUserMoveTransactionToStatusGrantedPermissions: UserPermissionItem[];

  @Input()
  CanUserEditTransactionInStatusGrantedPermissions: UserPermissionItem[];

  @Input()
  readWriteTransaction: boolean;

  ngOnInit() {
    this.displayHeader(this.transaction.type);
  }

  displayType(type: number): string {
    return SubtransactionTypeInternalLabel.get(type);
  }

  displayHeader(type: TransactionType): Header {
    switch (type) {
      case TransactionType.Draw:
        return this.header = { text: 'Draw', icon: 'D' };
      case TransactionType.Payment:
        return this.header = { text: 'Payment', icon: 'P' };
      case TransactionType.BalanceTransfer:
        return this.header = { text: 'Balance Transfer', icon: 'B' };
      case TransactionType.Fee:
        return this.header = { text: 'Fee', icon: 'F' };
      case TransactionType.Premium:
        return this.header = { text: 'Premium', icon: '$' };
    }
  }

  emitUpdateEvent(event) {
    this.statusChanged.emit();
  }

  onExpandedDetailChanged() {
    this.expandedDetailChanged.emit();
  }

}

export interface Header {
  text: string;
  icon: string;
}
