import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import * as _ from 'lodash';
import { CustomerLoanType } from '../../../customer.model';
import { LoanStatus, AccountHintData } from 'common';

@Component({
  selector: 'ifb-customer-loans-assignment-dialog',
  templateUrl: './customer-loans-assignment-dialog.component.html',
  styleUrls: ['./customer-loans-assignment-dialog.component.scss']
})
export class CustomerLoansAssignmentDialogComponent {

  title: string;
  loans: any[] = [];
  excludedStatus: LoanStatus;

  constructor(public dialogRef: MatDialogRef<CustomerLoansAssignmentDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    switch (data.type) {
      case CustomerLoanType.PendingLoans:
        this.title = 'Assign new pending account';
        this.excludedStatus = LoanStatus.Frozen;
        break;

      default:
        this.title = 'Assign new account';
        this.excludedStatus = LoanStatus.Pending;
        break;
    }
  }

  form: UntypedFormGroup = this.formBuilder.group({
    loanNumber: ''
  });

  save() {
    this.dialogRef.close(this.loans.length ? _.map(this.loans, 'id') : null);
  }

  static show(dialog: MatDialog, customerId: number, type: CustomerLoanType): Observable<any> {
    return dialog.open(CustomerLoansAssignmentDialogComponent, {
      width: '510px',
      data: {
        customerId: customerId,
        type: type
      },
      closeOnNavigation: true
    }).afterClosed();
  }

  selectedLoan(event: AccountHintData) {
    const existingLoanNumber = this.loans.some(l => l.loanNumber === event.loanNumber);
    if (!existingLoanNumber) {
      this.loans.push({ id: event.id, loanNumber: event.loanNumber, description: `${event.loanNumber} - ${event.businessName}` });
    }
    this.form.controls.loanNumber.setValue('');
  }

}
