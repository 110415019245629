import { UntypedFormControl, Validators } from "@angular/forms";
import {
  AppBarAction,
  EnumHelper,
  FeeType,
  FeeTypeLabel,
  LoanStatus,
  LoanStatusUniqueLabel,
  PromotionType,
  PromotionTypeLabel,
} from "common";
import {
  LinkAccountType,
  LinkAccountTypeLabel,
} from "../enums/linked-account-type.enum";
import { TransactionLimitType } from "../enums/transaction-limit-type.enum";

export const getPromotionFormModel = {
  name: [undefined, Validators.required],
  description: undefined,
  startDate: new UntypedFormControl(undefined, {
    validators: null,
    updateOn: "blur",
  }),
  endDate: new UntypedFormControl(undefined, {
    validators: null,
    updateOn: "blur",
  }),
  productCode: [undefined, Validators.required],
  transactionType: [undefined, Validators.required],
  promotionType: [undefined, Validators.required],
  active: false,
  reductionType: undefined,
  reductionValue: undefined,
};

export const getConditionsFormModel = {
  transactionsPerLoanLimitType: TransactionLimitType.Unlimited,
  transactionsPerLoanLimit: [
    undefined,
    [Validators.min(1), Validators.max(2147483647)],
  ],
  loanUtilizationMin: [undefined, [Validators.min(0), Validators.max(100)]],
  loanUtilizationMax: [undefined, [Validators.min(0), Validators.max(100)]],
  loanBalanceMin: [undefined, Validators.min(0)],
  loanBalanceMax: [undefined, Validators.min(0)],
  transactionAmountMin: [undefined, Validators.min(0)],
  transactionAmountMax: [undefined, Validators.min(0)],
  loanStatuses: undefined,
  priority: [
    undefined,
    [Validators.required, Validators.min(1), Validators.max(2147483647)],
  ],
  message: undefined,
  linkedAccounts: LinkAccountType.AllAccounts,
  exclusivePromotion: [false, Validators.required],
  usePromoCode: [false, Validators.required],
  promoCode: undefined,
  hasLcpPolicy: [false, Validators.required]
};

export const getActionsConfig: AppBarAction[] = [
  {
    id: "delete",
    label: "Delete",
    buttonType: "button",
    disabled: true,
    hidden: true,
  },
  { id: "cancel", label: "Cancel", buttonType: "button" },
  { id: "next", label: "Next", buttonType: "button" },
  { id: "back", label: "Back", buttonType: "button", hidden: true },
  {
    id: "save",
    label: "Save",
    buttonType: "submit",
    buttonAppearance: "flat",
    buttonColor: "primary",
    hidden: true,
    disabled: false,
  },
];

export const getPromotionTypeDrawOptions =
  EnumHelper.getMappedNamesAndValuesFromValues(
    PromotionType,
    PromotionTypeLabel,
    [PromotionType.DrawFeeOverride, PromotionType.DrawFeeReduction]
  );

export const getPromotionTypePaymentOptions =
  EnumHelper.getMappedNamesAndValuesFromValues(
    PromotionType,
    PromotionTypeLabel,
    [PromotionType.ReAmortization, PromotionType.PayoffInterestReduction]
  );

export const getLoanStatusOptions = EnumHelper.getMappedNamesAndValues(
  LoanStatus,
  LoanStatusUniqueLabel
);

export const getFeeTypeOptions = EnumHelper.getMappedNamesAndValues(
  FeeType,
  FeeTypeLabel
);

export const getLinkAccountTypeOptions = EnumHelper.getMappedNamesAndValues(
  LinkAccountType,
  LinkAccountTypeLabel
);
