import { Component, OnInit, Input } from '@angular/core';
import { EnumHelper, EntityData } from 'common';
import { WeekDay } from '@angular/common';

@Component({
  selector: 'ifb-entity-profile-status-widget',
  templateUrl: './entity-profile-status-widget.component.html',
  styleUrls: ['./entity-profile-status-widget.component.scss']
})
export class EntityProfileStatusWidgetComponent  implements OnInit {

  public canEditSensitiveData = false;
  weekDay: string;

  constructor() { }

  ngOnInit() {
    this.weekDay = this.data.createdOn
    ? EnumHelper.getNameFromValue(WeekDay, new Date(this.data.createdOn).getDay())
    : '';
  }

  @Input()
  data: EntityData;

  @Input()
  edit: boolean;
}
