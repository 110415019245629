import { QueryParams, QueryResult } from "common";

export interface ClientData {
    id?: number;
    name?: string;
    oidcAudience?: string;
    oidcLoginAllowed?: boolean;
    administrativeAccess?: boolean;
    active?: boolean;
}

export interface ClientQueryParams extends QueryParams {
    name?: string;
    oidcAudience?: string;
    active?: boolean;
}

export interface ClientQueryResult extends QueryResult<ClientData> {
}

export class ClientFilters {
    name: string;
    oidcAudience: string;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.name = filters.name || undefined;
        this.oidcAudience = filters.oidcAudience || undefined;
        this.active = filters.active === 'true' ? true : (filters.active === 'false' ? false : undefined);
    }

    remove(item: string) {
        this[item] = undefined;
    }
}
