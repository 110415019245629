import { CompetitorsModule } from './../competitors/competitors.module';
import { NgModule } from '@angular/core';
import { CommonModule, NgxCurrencyModule } from 'common';
import { SharedModule } from '../shared/shared.module';

import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionRoutingModule } from './transaction-routing.module';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { TransactionListFilterDialogComponent } from './transaction-list-filter-dialog/transaction-list-filter-dialog.component';
import { AccountModule } from '../account/account.module';
import { TransactionRejectInputComponent } from './transaction-reject-input/transaction-reject-input.component';
import { TransactionListUpdateDialogComponent } from './transaction-list-update-dialog/transaction-list-update-dialog.component';
import { TransactionListExpandedRowComponent } from './transaction-list-expanded-row/transaction-list-expanded-row.component';
import { TransactionDetailsUpdateDialogComponent } from './transaction-details-update-dialog/transaction-details-update-dialog.component';
import { TransactionEditMenuComponent } from './transaction-edit-menu/transaction-edit-menu.component';
import { TransactionCreateDialogComponent } from './transaction-create-dialog/transaction-create-dialog.component';
import { TransactionTimePickerDialogComponent } from './transaction-time-picker-dialog/transaction-time-picker-dialog.component';
import { TransactionAuditTrailComponent } from './transaction-audit-trail/transaction-audit-trail.component';
import { BankModule } from '../bank/bank.module';
import { TransactionBankTransactionsComponent } from './transaction-bank-transactions/transaction-bank-transactions.component';

@NgModule({
    imports: [
        CommonModule,
        TransactionRoutingModule,
        AccountModule,
        SharedModule,
        BankModule,
        CompetitorsModule,
        NgxCurrencyModule.forRoot({
            align: "left",
            allowNegative: false,
            allowZero: true,
            decimal: ".",
            precision: 2,
            prefix: "$",
            suffix: "",
            thousands: ",",
            nullable: true
        })
    ],
    declarations: [
        TransactionListComponent,
        TransactionDetailsComponent,
        TransactionListFilterDialogComponent,
        TransactionRejectInputComponent,
        TransactionListUpdateDialogComponent,
        TransactionListExpandedRowComponent,
        TransactionDetailsUpdateDialogComponent,
        TransactionEditMenuComponent,
        TransactionCreateDialogComponent,
        TransactionTimePickerDialogComponent,
        TransactionAuditTrailComponent,
        TransactionBankTransactionsComponent
    ]
})
export class TransactionModule { }
