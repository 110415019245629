<mat-card class="ifc-card" [ngClass.gt-md]="'card lg'" [ngClass.md]="'card md'" [ngClass.xs]="'xs'">

  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title"> Branding </div>
    <button *ngIf="edit" mat-icon-button aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-card-content class="layout padding-md">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">

      <mat-list>

        <mat-list-item>
          <span class="secondary-text" mat-line> Supported </span>
          <p class="item-content">{{broker?.branding?.supported ? 'True' : 'False' }}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Subdomain </span>
          <p class="item-content">{{broker?.branding?.subdomain || '-'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> LocCalculatorCss </span>
          <p class="item-content">{{broker?.branding?.locCalculatorCss ? 'True' : 'False'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> OfferEmailCss </span>
          <p class="item-content">{{broker?.branding?.offerEmailCss ? 'True' : 'False'}}</p>
        </mat-list-item>

      </mat-list>

    </div>

  </mat-card-content>

</mat-card>