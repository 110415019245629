import { Injectable, InjectionToken } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { Router, Params } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, EnumHelper, FilterParameter } from 'common';
import { BankTransactionFilters, BankTransactionQueryParams, BankTransactionType, BankTransactionStatus } from './bank.model';

export interface IBankTransactionListFilterService {
  getFilters(params: Params): any;
  getBankTransactionFilters(query: any): BankTransactionFilters;
  filter(
    filters: BankTransactionFilters,
    bankAccountId: number,
    companyId: number,
    accountId?: number,
    applicationId?: number,
    paginator?: MatPaginator,
    sort?: MatSort): void;
  addFilterParameters(query: BankTransactionQueryParams): FilterParameter[];
}

export let BankTransactionFilterService = new InjectionToken<IBankTransactionListFilterService>('BankTransactionFilterService');

@Injectable({
  providedIn: 'root'
})
export class BankTransactionListFilterService implements IBankTransactionListFilterService {

  constructor(
    private router: Router
  ) { }

  getFilters(params: Params): any {
    return Object.assign({}, params);
  }

  getBankTransactionFilters(query: any): BankTransactionFilters {
    return new BankTransactionFilters(query);
  }

  filter(
    filters: BankTransactionFilters,
    bankAccountId: number,
    companyId: number,
    accountId?: number,
    applicationId?: number,
    paginator?: MatPaginator,
    sort?: MatSort) {
    const query: BankTransactionQueryParams = filters;

    query.transactionDateFrom = filters.transactionDateFrom ? moment(filters.transactionDateFrom).format('L') : undefined;
    query.transactionDateTo = filters.transactionDateTo ? moment(filters.transactionDateTo).format('L') : undefined;
    query.fromAmount = filters.fromAmount ? filters.fromAmount : undefined;
    query.toAmount = filters.toAmount ? filters.toAmount : undefined;
    query.postedDateFrom = filters.postedDateFrom ? moment(filters.postedDateFrom).format('L') : undefined;
    query.postedDateTo = filters.postedDateTo ? moment(filters.postedDateTo).format('L') : undefined;
    query.status = filters.status ? filters.status : undefined;
    query.type = filters.type ? filters.type : undefined;
    query.category = filters.category ? filters.category : undefined;

    // Set default to first page
    query.skip = undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    if (accountId)
      this.router.navigate([`account/${accountId}/bank-account/${bankAccountId}/transactions`], { replaceUrl: true, queryParams: query });
    else if (applicationId)
        this.router.navigate([`application/${applicationId}/bank-account/${bankAccountId}/transactions`], { replaceUrl: true, queryParams: query });
    else
      this.router.navigate([`company/${companyId}/banks/${bankAccountId}/transactions`], { replaceUrl: true, queryParams: query });
  }

  addFilterParameters(query: BankTransactionQueryParams): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];
    if (query.transactionDateFrom) {
      filters.push({
        id: 'transactionDateFrom', name: 'Transaction date from',
        value: `${moment(query.transactionDateFrom).format('L')}`
      });
    }
    if (query.transactionDateTo) {
      filters.push({
        id: 'transactionDateTo', name: 'Transaction date to',
        value: `${moment(query.transactionDateTo).format('L')}`
      });
    }
    if (query.fromAmount) {
      filters.push({
        id: 'fromAmount', name: 'From amount',
        value: formatCurrency(query.fromAmount, 'en_US', '$')
      });
    }
    if (query.toAmount) {
      filters.push({
        id: 'toAmount', name: 'To amount',
        value: formatCurrency(query.toAmount, 'en_US', '$')
      });
    }
    if (query.postedDateFrom) {
      filters.push({
        id: 'postedDateFrom', name: 'Posted date from',
        value: `${moment(query.postedDateFrom).format('L')}`
      });
    }
    if (query.postedDateTo) {
      filters.push({
        id: 'postedDateTo', name: 'Posted date to',
        value: `${moment(query.postedDateTo).format('L')}`
      });
    }
    if (query.status) {
      filters.push({
        id: 'status', name: 'Status',
        value: EnumHelper.getNameFromValue(BankTransactionStatus, query.status)
      });
    }
    if (query.type) {
      filters.push({
        id: 'type', name: 'Type',
        value: EnumHelper.getNameFromValue(BankTransactionType, query.type)
      });
    }
    if (query.category) {
      filters.push({
        id: 'category', name: 'Category',
        value: query.category
      });
    }
    if (query.active) {
      filters.push({
        id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
      });
    }

    return filters;
  }
}
