import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { applicationAppBarExpandedTabs, ApplicationData, ApplicationRenewalData } from "./application.model";
import { UserPermissionService } from "../user/user-permission/user-permission.service";
import { ApplicationService } from "./application.service";
import { AppBarExpandedTab, ApplicationQueryParams, ApplicationQueryResult } from "common";
import { BankAccountQueryResult } from "../bank/bank.model";
import { BankAccountService } from "../bank/bank.service";
import { CustomerData } from "../customer/customer.model";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Injectable({
    providedIn: 'root'
})
export class ApplicationAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            applicationAppBarExpandedTabs(route.params.id).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationResolver implements Resolve<ApplicationData> {
    constructor(private service: ApplicationService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ApplicationData> | Promise<ApplicationData> | ApplicationData {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return this.service.get(id);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationQueryResolver implements Resolve<ApplicationQueryResult> {
    constructor(private service: ApplicationService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApplicationQueryResult>
        | Promise<ApplicationQueryResult> | ApplicationQueryResult {
        return this.service.query(new ApplicationQueryParams(
          this.queryParamsService.init(route.queryParams))
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationBankAccountListResolver implements Resolve<BankAccountQueryResult> {
    constructor(private service: ApplicationService, private bankAccountService: BankAccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BankAccountQueryResult>
        | Promise<BankAccountQueryResult> | BankAccountQueryResult {
        return this.service.get(route.params.id)
            .pipe(
                switchMap(res => res ? this.bankAccountService.getBankAccountList(res.entityId) : null),
                map(res => res ? res : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationByEntityQueryResolver implements Resolve<ApplicationQueryResult> {
    constructor(private service: ApplicationService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<ApplicationQueryResult> | Promise<ApplicationQueryResult> | ApplicationQueryResult {
        return this.service.query(new ApplicationQueryParams(
          this.queryParamsService.init({ entityId: route.params.id, ...route.queryParams}))
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationOwnersResolver implements Resolve<CustomerData[]> {
    constructor(private service: ApplicationService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CustomerData[]> | Promise<CustomerData[]> | CustomerData[] {
        return this.service.getOwnersList(route.params.id);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationByCustomerQueryResolver implements Resolve<ApplicationQueryResult> {
    constructor(private service: ApplicationService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<ApplicationQueryResult> | Promise<ApplicationQueryResult> | ApplicationQueryResult {
        return this.service.query(new ApplicationQueryParams(
          this.queryParamsService.init({ customerId: route.params.id, ...route.queryParams}))
        );
    }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationRenewalsResolver implements Resolve<ApplicationRenewalData> {
  constructor(private service: ApplicationService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<ApplicationRenewalData> | Promise<ApplicationRenewalData> | ApplicationRenewalData {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return this.service.get(id).pipe(map((data: ApplicationData) => {
          return {
            applicationId: data?.id,
            renewalForLoanId: data?.renewalForLoanId,
            renewalForLoanNumber: data?.renewalForLoanNumber,
            renewalReady: data?.renewalReady
          } as ApplicationRenewalData;
        }))
  }
}


