import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../account/account.service';
import { AccountAverage } from '../../../account/account.model';

@Component({
  selector: 'ifb-product-averages-list',
  templateUrl: './product-averages-list.component.html',
  styleUrls: ['./product-averages-list.component.scss']
})
export class ProductAveragesListComponent implements OnInit {

  data: AccountAverage[] = [];
  index = 0;

  constructor(private _accountService: AccountService) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this._accountService.averagesInfo()
      .subscribe((data) => this.data = data);
  }

  prevProduct() {
    if (this.index > 0)
      this.index -= 1;
  }

  nextProduct() {
    if (this.index < this.data.length - 1)
      this.index += 1;
  }

}
