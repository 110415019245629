import { Component, Input } from "@angular/core";
import { OfferBundleDetailsData } from "../../models/offer-bundle-details-data.model";

@Component({
  selector: "ifb-offer-bundle-status-widget",
  templateUrl: "./offer-bundle-status-widget.component.html",
  styleUrls: ["./offer-bundle-status-widget.component.scss"],
})
export class OfferBundleStatusWidgetComponent {
  @Input()
  data: OfferBundleDetailsData;
}
