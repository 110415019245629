import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AppPageComponent,
    AppSidenavComponent,
    AppBarComponent,
    AppBarTitleComponent,
    AppBarExpandedTabsComponent,
    AppBarActionsComponent,
    AppBarExpandedComponent
} from 'common';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../app.data';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';

import { BOAuthGuard } from '../guards/oauth.guard';
import { AdvisorListComponent } from './advisor-list/advisor-list.component';
import {
    AdvisorQueryResolver,
    AdvisorResolver,
    AdvisorAppBarExpandedTabsResolver,
    AdvisorEntityQueryResolver,
    AdvisorAccountQueryResolver
} from './advisor.resolver';
import { AdvisorProfileComponent } from './advisor-profile/advisor-profile.component';
import { AdvisorAppBarTitleComponent } from './advisor-app-bar-title/advisor-app-bar-title.component';
import { AdvisorInfoDetailsComponent } from './advisor-info-details/advisor-info-details.component';
import { EntityListComponent } from '../entity/entity-list/entity-list.component';
import { AccountListComponent } from '../account/account-list/account-list.component';

const appPageDataMaster = appPageComponentDataMaster({
    appBarTitle: 'Advisors',
    sideNavItem: 'advisor'
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
    {
        path: '',
        canActivate: [BOAuthGuard],
        children: [
            {
                path: 'advisor',
                component: AppPageComponent,
                data: appPageDataMaster,
                resolve: {
                    appPage: AppPageResolver,
                    appSidenav: AppSidenavResolver
                },
                children: [
                    {
                        path: '',
                        outlet: 'sidenav-content',
                        component: AppSidenavComponent
                    },
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: AppBarTitleComponent,
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: UserMenuComponent,
                            }
                        ]
                    },
                    {
                        path: '',
                        component: AdvisorListComponent,
                        runGuardsAndResolvers: "paramsOrQueryParamsChange",
                        resolve: {
                            advisorQueryResult: AdvisorQueryResolver
                        }
                    }
                ]
            }
        ]
    },
    {
        path: 'advisor/:id/profile',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
            appBarExpandedTabs: AdvisorAppBarExpandedTabsResolver,
            appPage: AppPageResolver,
            appSidenav: AppSidenavResolver,
            advisor: AdvisorResolver
        },
        children: [
            {
                path: '',
                outlet: 'sidenav-content',
                component: AppSidenavComponent
            },
            {
                path: '',
                component: AppBarComponent,
                outlet: 'app-bar',
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AdvisorAppBarTitleComponent
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: UserMenuComponent,
                    },
                    {
                        path: '',
                        outlet: 'expanded',
                        component: AppBarExpandedComponent,
                        children: [
                            {
                                path: '',
                                outlet: 'tabs',
                                component: AppBarExpandedTabsComponent,
                            }
                        ]
                    }
                ]
            },
            {
                path: '',
                component: AdvisorProfileComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange"
            }
        ]
    },
    {
        path: 'advisor/:id/companies',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
            appBarExpandedTabs: AdvisorAppBarExpandedTabsResolver,
            appPage: AppPageResolver,
            appSidenav: AppSidenavResolver,
            advisor: AdvisorResolver
        },
        children: [
            {
                path: '',
                outlet: 'sidenav-content',
                component: AppSidenavComponent
            },
            {
                path: '',
                component: AppBarComponent,
                outlet: 'app-bar',
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AdvisorAppBarTitleComponent
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: UserMenuComponent,
                    },
                    {
                        path: '',
                        outlet: 'expanded',
                        component: AppBarExpandedComponent,
                        children: [
                            {
                                path: '',
                                outlet: 'tabs',
                                component: AppBarExpandedTabsComponent,
                            }
                        ]
                    }
                ]
            },
            {
                path: '',
                component: EntityListComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                    entityQueryResult: AdvisorEntityQueryResolver
                }
            }
        ]
    },
    {
        path: 'advisor/:id/accounts',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
            appBarExpandedTabs: AdvisorAppBarExpandedTabsResolver,
            appPage: AppPageResolver,
            appSidenav: AppSidenavResolver,
            advisor: AdvisorResolver
        },
        children: [
            {
                path: '',
                outlet: 'sidenav-content',
                component: AppSidenavComponent
            },
            {
                path: '',
                component: AppBarComponent,
                outlet: 'app-bar',
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AdvisorAppBarTitleComponent
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: UserMenuComponent,
                    },
                    {
                        path: '',
                        outlet: 'expanded',
                        component: AppBarExpandedComponent,
                        children: [
                            {
                                path: '',
                                outlet: 'tabs',
                                component: AppBarExpandedTabsComponent,
                            }
                        ]
                    }
                ]
            },
            {
                path: '',
                component: AccountListComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                    accountQueryResult: AdvisorAccountQueryResolver
                }
            }
        ]
    },
    {
        path: 'advisor/:id',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
            {
                path: '',
                component: AppBarComponent,
                outlet: 'app-bar',
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AppBarTitleComponent,
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarActionsComponent,
                    }
                ]
            },
            {
                path: '',
                component: AdvisorInfoDetailsComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                    advisor: AdvisorResolver
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdvisorRoutingModule { }
