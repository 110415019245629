import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { ApiKeyRoutingModule } from './api-key-routing.module';
import { ApiKeyListComponent } from './api-key-list/api-key-list.component';
import { ApiKeyDetailsComponent } from './api-key-details/api-key-details.component';
import { ApiKeyListFilterDialogComponent } from './api-key-list-filter-dialog/api-key-list-filter-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { UserModule } from '../user/user.module';
import { ApiKeyUpdateDialogComponent } from './api-key-update-dialog/api-key-update-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        ApiKeyRoutingModule,
        UserModule
    ],
    declarations: [
        ApiKeyListComponent,
        ApiKeyDetailsComponent,
        ApiKeyListFilterDialogComponent,
        ApiKeyUpdateDialogComponent
    ]
})
export class ApiKeyModule { }
