import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import { UserQueryResult, UserData } from "./user.model";
import { UserService } from "./user.service";
import { parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class UserResolver implements Resolve<UserData> {
  constructor(private service: UserService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserData> | Promise<UserData> | UserData {
    return route.params.id === "new"
      ? undefined
      : this.service.get(parseNumber(route.params.id));
  }
}

@Injectable({
  providedIn: "root",
})
export class UserQueryResolver implements Resolve<UserQueryResult> {
  constructor(
    private service: UserService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserQueryResult> | Promise<UserQueryResult> | UserQueryResult {
    return this.service.query(this.queryParamsService.init(route.queryParams));
  }
}
