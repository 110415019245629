import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import { ApiKeyQueryResult, ApiKeyData, ApiKeyQueryParams } from "./api-key.model";
import { ApiKeyService } from "./api-key.service";
import { parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class ApiKeyResolver implements Resolve<ApiKeyData> {
  constructor(private service: ApiKeyService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ApiKeyData> | Promise<ApiKeyData> | ApiKeyData {
    return route.params.id === "new"
      ? undefined
      : this.service.get(parseNumber(route.params.id));
  }
}

@Injectable({
  providedIn: "root",
})
export class ApiKeyQueryResolver implements Resolve<ApiKeyQueryResult> {
  constructor(
    private service: ApiKeyService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ApiKeyQueryResult>
    | Promise<ApiKeyQueryResult>
    | ApiKeyQueryResult {
    return this.service.query(new ApiKeyQueryParams(this.queryParamsService.init(route.queryParams)));
  }
}
