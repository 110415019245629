<div [@routerTransition]="'fly'">
  <div class="filter-toolbar">
    <div class="table-top">
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button routerLink="/broker/{{brokerId}}/users/new" class="secondary-text"
          matTooltip="Add">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </div>
  </div>

<ifc-app-scroll-container [default]="true">
  <div
    class="message-box info"
    *ngIf="!data.totalCount"
    fxLayout="row"
    fxLayoutGap="12px"
    fxLayoutAlign="start center"
  >
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <table
    aria-label="Table of broker users"
    *ngIf="data.totalCount"
    mat-table
    matSort
    (matSortChange)="filter()"
    [dataSource]="data.values"
  >
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">
        First name
      </th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
        {{ row.firstName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">
        Last name
      </th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
        {{ row.lastName }}
      </td>
    </ng-container>

      <ng-container matColumnDef="mobilePhone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="mobilePhone">
        Phone
      </th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
          {{ row.mobilePhone | phone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
        <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
          {{ row.email }}
        </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="role">Role</th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
        {{ row.role | enumValueLabel : BrokerUserRole : BrokerUserRoleLabel }}
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="active">
          Status
        </th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
        {{ row.status | enumValueLabel : BrokerUserStatus : BrokerUserStatusLabel }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastLoggedOnUtc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="lastLoggedOnUtc">
        Last login date
      </th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
        {{ row.lastLoggedOnUtc | date : "mediumDate" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="firstAgentApplicationsCount">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="firstAgentApplicationsCount"
      >
        First agent applications
      </th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
        {{ row.firstAgentApplicationsCount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="assignedAgentApplicationsCount">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="assignedAgentApplicationsCount"
      >
        Assigned agent applications
      </th>
      <td mat-cell *matCellDef="let row" (click)="onRowClick(row)" class="table-cell">
        {{ row.assignedAgentApplicationsCount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="overflow">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="readWriteBrokerUsers">
          <button
            class="overflow-button"
            mat-icon-button
            [matMenuTriggerFor]="brokerUserMenu"
            #menuTrigger="matMenuTrigger"
            [ngClass]="{ 'show-overflow-button': menuTrigger.menuOpen }"
            (click)="$event.stopPropagation()"
            (menuOpened)="menuOpenedRow = row"
            (menuClosed)="menuOpenedRow = null"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #brokerUserMenu="matMenu">
              <button mat-menu-item class="menu-item" (click)="onEdit(row.id)">
                <span>Edit</span>
              </button>
              <button *ngIf="row.active" mat-menu-item class="menu-item" (click)="onDisable(row)">
              <span>Disable</span>
            </button>
            <button *ngIf="row.status === BrokerUserStatus.Invited" mat-menu-item (click)="onResendInvitation(row)">
              <span>Resend activation email</span>
            </button>
          </mat-menu>
        </ng-container>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
      class="table-header"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns"
      class="table-row"
    ></tr>
  </table>
</ifc-app-scroll-container>

<div [hidden]="data.totalCount <= 10">
  <mat-paginator
    #paginator
    showFirstLastButtons
    [length]="data.totalCount"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="filter()"
  >
  </mat-paginator>
</div>
</div>
