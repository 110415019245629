import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'common';

@Component({
  selector: 'ifb-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor(private oauthService: OAuthService, private router: Router) { }

  ngOnInit(): void {
    this.oauthService.sessionEndLocal();
  }

  goHome(): void {
    this.router.navigate(['home']);
  }
}
