<h1 mat-dialog-title>Create transaction?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are creating a transaction for:
  </p>

  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.loanNumber?.loanNumber}}</div>
        <div class="mat-caption">Loan Number</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.entityName?.businessName}}</div>
        <div class="mat-caption">Legal entity name</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
          <div>{{type}}</div>
          <div class="mat-caption">Type</div>
        </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.amount | currency}}</div>
        <div class="mat-caption">Amount</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{status}}</div>
        <div class="mat-caption">Status</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{competitors}}</div>
        <div class="mat-caption">Competitor</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.date | date: 'short'}}</div>
        <div class="mat-caption">Transaction date</div>
      </div>
      <div *ngIf="!data.form.controls.promotion.pristine" fxLayout="column" class="layout padding-vertical-md">
        <div>{{promotions}}</div>
        <div class="mat-caption">Promotions</div>
      </div>
    </div>
  </div>

</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">OK</button>
</div>
