<h1 mat-dialog-title>{{data.action}} bank account?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are {{actionGerund | lowercase}} a bank account for {{data.form.controls.businessName.value}}:
  </p>

  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">
      <div *ngIf="!data.form.controls.businessName.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.form.value.businessName}}</div>
        <div class="mat-caption">Legal entity name</div>
      </div>
      <div *ngIf="!data.form.controls.bankName.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.form.value.bankName}}</div>
        <div class="mat-caption">Bank name</div>
      </div>
      <div *ngIf="!data.form.controls.accountNumber.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.form.value.accountNumber}}</div>
        <div class="mat-caption">Bank account number</div>
      </div>
      <div *ngIf="!data.form.controls.routingNumber.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.form.value.routingNumber || 'Null'}}</div>
        <div class="mat-caption">Bank routing number</div>
      </div>
      <div *ngIf="!data.form.controls.bankAccountType.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{accountType}}</div>
        <div class="mat-caption">Bank account type</div>
      </div>
      <div *ngIf="!data.form.controls.bankAccountDesignationEntity.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{bankAccountDesignationEntity}}</div>
        <div class="mat-caption">Bank designation (global)</div>
      </div>
      <div *ngIf="!data.form.controls.verificationStatus.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.form.controls.verificationStatus.value ? 'Verified' : 'Unverified'}}</div>
        <div class="mat-caption">Verification status</div>
      </div>
      <div *ngIf="!data.form.controls.lastCheckDate.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{(data.form.value.lastCheckDate | date) || 'Null'}}</div>
        <div class="mat-caption">Verification date</div>
      </div>
      <div *ngIf="!data.form.controls.screenShareRequired.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.form.value.screenShareRequired ? 'True' : 'False'}}</div>
        <div class="mat-caption">Screen share required</div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>