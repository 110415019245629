import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { FilterParameter } from "common";
import { ApiKeyQueryResult, ApiKeyData, ApiKeyFilters, ApiKeyQueryParams } from "../api-key.model";
import { ApiKeyFilterService } from "../api-key-filter.service";
import { ApiKeyListFilterDialogComponent } from "../api-key-list-filter-dialog/api-key-list-filter-dialog.component";
import { readWrite } from "../../../user/user-permission/user-permission.data";
import { UserPermissionService } from "../../../user/user-permission/user-permission.service";
import { routerTransition } from "../../../shared/animations/router.animations";
import { ClientData } from "../../../auth/client/client.data";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifb-api-key-list",
  templateUrl: "./api-key-list.component.html",
  styleUrls: ["./api-key-list.component.css"],
  animations: routerTransition,
})
export class ApiKeyListComponent implements OnInit {
  filters: ApiKeyFilters;
  activeFilters: FilterParameter[] = [];
  queryParams: ApiKeyQueryParams;

  constructor(
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private _apiKeyFilterService: ApiKeyFilterService,
    private queryParamsService: QueryParamsService
  ) {}

  data: ApiKeyListComponentData;

  readWriteApiKey: boolean;

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((it: ApiKeyListComponentData) => {
        this.data = it;
      });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((it) => {
      this.queryParams = new ApiKeyQueryParams(this.queryParamsService.init(it));
      this.activeFilters = this._apiKeyFilterService.addFilterParameters(
        this.queryParams
      );
    });

    this.userPermissionService
      .granted([readWrite("admin-api-keys")])
      .subscribe((res) => (this.readWriteApiKey = res));

    this.filters = new ApiKeyFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;

    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this._apiKeyFilterService.filter(this.filters);
    });
  }

  filterDialog() {
    ApiKeyListFilterDialogComponent.show(
      this.dialog,
      this.queryParams,
      this.data.clients
    ).subscribe((result) => {
      if (result) {
        this.filters = result;
        this.paginator.firstPage();
        this._apiKeyFilterService.filter(result);
      }
    });
  }

  sortData(sort: Sort) {
    this._apiKeyFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this._apiKeyFilterService.filter(this.filters, this.paginator, this.sort);
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    "clientName",
    "description",
    "createdBy",
    "createdOn",
    "updatedBy",
    "updatedOn",
    "actions",
  ];
}

export interface ApiKeyListComponentData {
  apiKeyQueryResult: ApiKeyQueryResult;
  apiKey: ApiKeyData;
  clients: ClientData[];
}
