<div class="details-table" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="40px">
  <table class="space-between-rows">
    <tr class="row row-header">
      <td class="icon">
        <div class="avatar medium mat-light-grey-bg" [innerHTML]="header?.icon"></div>
      </td>
      <td class="header">{{header?.text}}</td>
      <td class="status-label">
        <ifc-status-label [status]="status" [value]="transaction.status"
          *ngIf="transaction.status !== TransactionStatus.Processed && transaction.status !== TransactionStatus.Complete && transaction.status !== undefined">
        </ifc-status-label>
      </td>
      <td class="menu-button">
        <div
          [ngClass]="(!readWriteTransaction || !canReadAccounts ) ? 'hide' : ((CanUserEditTransactionInStatusGrantedPermissions[transaction.status].hidden) ? 'hide' : '')">
          <ifb-transaction-edit-menu
            [CanUserMoveTransactionToStatusGrantedPermissions]="CanUserMoveTransactionToStatusGrantedPermissions" [failureReasons]="failureReasons"
            [tx]="transaction" [canEditSubTransactions]="adminEdit" [expandedDetail]="expandedDetail"
            (statusChanged)="emitUpdateEvent($event)" (expandedDetailChanged)="onExpandedDetailChanged()">
          </ifb-transaction-edit-menu>
        </div>
      </td>
    </tr>
    <tr class="row" *ngFor="let subtransaction of transaction.subtransactions; index as i">
      <td class="index">{{i+1}}</td>
      <td class="transaction-type" colspan="2">{{displayType(subtransaction.type)}}</td>
      <td class="amount">{{subtransaction.amount | currency}}</td>
    </tr>
  </table>
  <div class="missing-info-list" *ngIf="transaction.missingInformation?.length">
    <div class="header">Missing information</div>
    <ul>
      <li class="secondary-text" *ngFor="let info of transaction.missingInformation">{{info}}</li>
    </ul>
  </div>
</div>
