import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPermissionService } from '../user/user-permission/user-permission.service';
import { adminAppBarExpandedTabs } from './admin.data';

@Injectable({
  providedIn: 'root'
})
export class AdminAppBarExpandedTabsResolver implements Resolve<any> {
  constructor(private permissionService: UserPermissionService) {}

  resolve(): Observable<any> | Promise<any> | any {
    return forkJoin(
      adminAppBarExpandedTabs.map(it => this.permissionService.visibility(it))
    ).pipe(map(tabs => ({ tabs })));
  }
}
