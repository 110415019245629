import { Injectable } from '@angular/core';
import { UserPermissionGroupData } from './user-permission-group.data';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPermissionGroupService } from './user-permission-group.service';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionGroupQueryResolver implements Resolve<UserPermissionGroupData[]> {

  constructor(private service: UserPermissionGroupService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<UserPermissionGroupData[]> | Promise<UserPermissionGroupData[]> | UserPermissionGroupData[] {
    return this.service.queryAll();
  }
}
