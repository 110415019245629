import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { BrokerUsersRoutingModule } from "./broker-users-routing.module";
import { BrokerUserListComponent } from "./features/broker-user-list/broker-user-list.component";
import { SharedModule } from "../../shared/shared.module";
import { BrokerUserDetailsComponent } from './features/broker-user-details/broker-user-details.component';
import { BrokerUserDummyComponent } from "./features/broker-user-dummy/broker-user-dummy.component";

@NgModule({
  declarations: [BrokerUserListComponent, BrokerUserDetailsComponent, BrokerUserDummyComponent],
  imports: [CommonModule, BrokerUsersRoutingModule, SharedModule],
})
export class BrokerUsersModule {}
