import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
  AppBarActionsService,
  AppBarAction,
  FormHelper,
  TransactionTypeString,
  PromotionType,
  FeeType,
  ProductCode,
} from "common";
import { PromotionDetailsData } from "./models/promotion-details-data.model";
import { Promotion } from "./../models/promotion.model";
import { TransactionLimitType } from "./enums/transaction-limit-type.enum";
import { LinkAccountType } from "./enums/linked-account-type.enum";
import { PromotionDetailsStep } from "./enums/promotion-details-step.enum";
import { MatStepper } from "@angular/material/stepper";
import { PromotionDetailsFacade } from "./promotion-details.facade";
import {
  getConditionsFormModel,
  getFeeTypeOptions,
  getLinkAccountTypeOptions,
  getLoanStatusOptions,
  getPromotionFormModel,
  getPromotionTypeDrawOptions,
  getPromotionTypePaymentOptions,
} from "./data/promotion-details-data";
import {
  getProductTypeOptions,
  getTransactionTypeOptions,
} from "../data/promotion-data";

@Component({
  selector: "ifb-promotion-details",
  templateUrl: "./promotion-details.component.html",
  styleUrls: ["./promotion-details.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    PromotionDetailsFacade,
  ],
})
export class PromotionDetailsComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  @ViewChild("stepper") stepper: MatStepper;

  promotionForm: UntypedFormGroup;
  conditionsForm: UntypedFormGroup;

  productTypeOptions = getProductTypeOptions;
  transactionTypeOptions = getTransactionTypeOptions;
  loanStatusOptions = getLoanStatusOptions;
  feeTypeOptions = getFeeTypeOptions;
  linkAccountTypeOptions = getLinkAccountTypeOptions;

  private promotionTypeDrawOptions = getPromotionTypeDrawOptions;
  private promotionTypePaymentOptions = getPromotionTypePaymentOptions;
  promotionTypeOptions = [];

  get FeeType() {
    return FeeType;
  }
  get PromotionType() {
    return PromotionType;
  }
  get TransactionLimitType() {
    return TransactionLimitType;
  }

  get ProductCode() {
    return ProductCode;
  }

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private appBarActionsService: AppBarActionsService,
    private facade: PromotionDetailsFacade
  ) {
    this.initForms();
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.initData.bind(this)),
      this.appBarActionsService.invoking.subscribe(
        this.actionDispatch.bind(this)
      ),
      this.promotionForm.statusChanges.subscribe(
        this.updateActionButtons.bind(this)
      ),
      this.promotionForm.controls["transactionType"].valueChanges.subscribe(
        (transactionType) => this.adjustPromotionType(transactionType)
      ),
      this.promotionForm.controls["promotionType"].valueChanges.subscribe(
        (promotionType) => this.adjustReductionValues(promotionType)
      ),
      this.conditionsForm.controls["transactionsPerLoanLimitType"].valueChanges.subscribe((type) =>
        this.adjustTransactionLimitValidators(type)
      ),
      this.conditionsForm.controls["usePromoCode"].valueChanges.subscribe((type) =>
        this.adjustPromoCodeValidators(type)
      ),
      this.conditionsForm.statusChanges.subscribe(
        this.updateActionButtons.bind(this)
      ),

      this.promotionForm.controls.productCode.valueChanges.subscribe((code) => 
        this.adjustProductCodeDependants(code)
      )
    ];
  }

  private initForms() {
    this.promotionForm = this.formBuilder.group(getPromotionFormModel);
    this.conditionsForm = this.formBuilder.group(getConditionsFormModel);
  }

  private initData(data: PromotionDetailsData) {
    this.facade.init(data.promotion);
    if (data.promotion) {
      this.initFormsData(data.promotion);
    }

    this.promotionForm.markAsPristine();
    this.conditionsForm.markAsPristine();
    this.updateActionButtons();
  }

  private initFormsData(promotion: Promotion) {
    const { reductionType, transactionsPerLoanLimit, linkAccounts, promoCode } = promotion;
    this.initPromotionTypeOptions(promotion.transactionType);

    const promotionFormData = {
      ...promotion,
      reductionType: !!reductionType ? reductionType : FeeType.Percentage,
    };
    this.promotionForm.patchValue(promotionFormData);
    this.initReductionValuesValidators(promotion.promotionType);

    const transactionsPerLoanLimitType =
      !!transactionsPerLoanLimit || transactionsPerLoanLimit === 0
        ? TransactionLimitType.Custom
        : TransactionLimitType.Unlimited;

    const usePromoCode = !!promoCode || promoCode === '' ? true : false;

    const linkedAccounts: LinkAccountType = !!linkAccounts
      ? (linkAccounts as unknown as LinkAccountType)
      : LinkAccountType.AllAccounts;
    this.conditionsForm.patchValue({
      usePromoCode,
      transactionsPerLoanLimitType,
      ...promotion,
      linkedAccounts
    });
    this.initTransactionLimitValidators(transactionsPerLoanLimitType);
    this.initPromoCodeValidators(usePromoCode);
  }

  private isFormDataValid() {
    return this.promotionForm.valid && this.conditionsForm.valid;
  }

  private adjustPromotionType(transactionType: TransactionTypeString) {
    this.initPromotionTypeOptions(transactionType);
    this.promotionForm.controls["promotionType"].setValue(undefined);
  }

  private adjustReductionValues(promotionType: PromotionType) {
    this.promotionForm.controls["reductionType"].setValue(undefined);
    this.promotionForm.controls["reductionType"].markAsDirty();
    this.promotionForm.controls["reductionValue"].setValue(undefined);
    this.promotionForm.controls["reductionValue"].markAsDirty();

    if (
      promotionType === PromotionType.DrawFeeReduction ||
      promotionType === PromotionType.DrawFeeOverride ||
      promotionType === PromotionType.PayoffInterestReduction
    ) {
      this.promotionForm.controls["reductionType"].setValue(FeeType.Percentage);
    }

    this.initReductionValuesValidators(promotionType);
  }

  private adjustTransactionLimitValidators(type: TransactionLimitType) {
    this.conditionsForm.controls["transactionsPerLoanLimit"].setValue(undefined);
    this.conditionsForm.controls["transactionsPerLoanLimit"].markAsDirty();
    this.initTransactionLimitValidators(type);
  }

  private initTransactionLimitValidators(type: TransactionLimitType) {
    if (type === TransactionLimitType.Custom) {
      this.conditionsForm.controls["transactionsPerLoanLimit"].addValidators(
        Validators.required
      );
    } else {
      this.conditionsForm.controls["transactionsPerLoanLimit"].removeValidators(
        Validators.required
      );
    }
    this.conditionsForm.controls[
      "transactionsPerLoanLimit"
    ].updateValueAndValidity();
  }

  private adjustPromoCodeValidators(usePromoCode: boolean) {
    this.conditionsForm.controls["promoCode"].setValue(undefined);
    this.conditionsForm.controls["promoCode"].markAsDirty();
    this.initPromoCodeValidators(usePromoCode);
  }

  private initPromoCodeValidators(usePromoCode: boolean) {
    if (usePromoCode) {
      this.conditionsForm.controls["promoCode"].addValidators(
        Validators.required
      );
    } else {
      this.conditionsForm.controls["promoCode"].removeValidators(
        Validators.required
      );
    }
    this.conditionsForm.controls[
      "promoCode"
    ].updateValueAndValidity();
  }

  private initReductionValuesValidators(promotionType: PromotionType) {
    if (
      promotionType === PromotionType.DrawFeeReduction ||
      promotionType === PromotionType.PayoffInterestReduction
    ) {
      this.promotionForm.controls["reductionType"].setValidators(
        Validators.required
      );
      this.promotionForm.controls["reductionValue"].setValidators(
        Validators.required
      );
    } else {
      this.promotionForm.controls["reductionType"].removeValidators(
        Validators.required
      );
      this.promotionForm.controls["reductionValue"].removeValidators(
        Validators.required
      );
    }
    this.promotionForm.controls["reductionType"].updateValueAndValidity();
    this.promotionForm.controls["reductionValue"].updateValueAndValidity();
  }

  private initPromotionTypeOptions(transactionType: TransactionTypeString) {
    if (transactionType === TransactionTypeString.Draw) {
      this.promotionTypeOptions = this.promotionTypeDrawOptions;
    } else if (transactionType === TransactionTypeString.Payment) {
      this.promotionTypeOptions = this.promotionTypePaymentOptions;
    }
  }

  private adjustProductCodeDependants(code: ProductCode) {
    if(code !== ProductCode.Law)
      this.conditionsForm.controls.hasLcpPolicy.reset(false);
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  next(): void {
    FormHelper.showInvalidFormFields(this.promotionForm);
    this.stepper.next();
  }

  back(): void {
    FormHelper.showInvalidFormFields(this.conditionsForm);
    this.stepper.previous();
  }

  cancel(): void {
    this.facade.cancel();
  }

  delete() {
    this.facade.delete();
  }

  save() {
    if (!this.isFormDataValid()) {
      FormHelper.showInvalidFormFields(this.promotionForm);
      FormHelper.showInvalidFormFields(this.conditionsForm);
      return;
    }

    if (!this.promotionForm.dirty && !this.conditionsForm.dirty) {
      this.cancel();
      return;
    }

    this.facade.save(this.promotionForm, this.conditionsForm);
  }

  onStepChange(event): void {
    if (event.selectedIndex === 1) {
      FormHelper.showInvalidFormFields(this.promotionForm);
      this.facade.updateCurrentStep(PromotionDetailsStep.Conditions);
    } else if (event.selectedIndex === 0) {
      FormHelper.showInvalidFormFields(this.conditionsForm);
      this.facade.updateCurrentStep(PromotionDetailsStep.Promotion);
    }
    this.updateActionButtons();
  }

  private updateActionButtons() {
    return this.facade.updateActionButtons(this.isFormDataValid());
  }

  ngOnDestroy(): void {
    this.subs.forEach((it) => it.unsubscribe());
  }
}
