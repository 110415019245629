import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BankTransactionQueryParams,
  BankTransactionQueryResult
} from './bank.model';
import { ResourceService } from 'common';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class BankTransactionsService {
  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private settings: AppSettings
  ) { }

  private readonly baseUrl = `${this.settings.entities.url}`;

  queryBankTransactions(
    entityId: number,
    bankAccountId: number,
    params: BankTransactionQueryParams): Observable<BankTransactionQueryResult> {

    const url = `${this.baseUrl}/api/bank-transactions/${entityId}/account/${bankAccountId}/transaction/list`;
    return this.http.get<BankTransactionQueryResult>(url, { params: <any>params });
  }

  downloadBankTransactions(entityId: number, bankAccountId: number, params: any) {
    const url = `${this.baseUrl}/api/bank-transactions/${entityId}/account/${bankAccountId}/transaction/csv`;
    this.resourceService.download(url, "bank-transactions.csv", params);
  }
}
