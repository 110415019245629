import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import {
  EnumHelper,
  MessageService,
  AppBarTitleService,
  AppBarActionsService,
  FormHelper,
  DialogConfirm,
  AppBarAction,
  BankAccount,
  BankAccountType,
  AppPageService
} from 'common';
import { BrokerService } from '../broker.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { BrokerData } from '../broker.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ifb-broker-bank-account-details',
  templateUrl: './broker-bank-account-details.component.html',
  styleUrls: ['./broker-bank-account-details.component.css']
})
export class BrokerBankAccountDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  canEditBankAccount = false;
  form: UntypedFormGroup;
  title: string;
  accountType: string;
  saveSub: Subscription;
  data: BrokerBankAccountDetailsComponentData;

  constructor(
    private brokerService: BrokerService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService) {
    this._unsubscribeAll = new Subject();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'delete', label: 'Delete', disabled: true, buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.form = this.formBuilder.group({
      bankName: [undefined, Validators.required],
      accountNumber: [{ value: undefined, disabled: true }, Validators.required],
      routingNumber: [undefined, Validators.required],
      disbursementBankAccount: undefined,
      paymentBankAccount: undefined
    });
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.userPermissionService.granted([readWrite('servicing-bank-accounts')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canEditBankAccount = res;
        if (res && this.data.bankAccount) {
          this.appBarActionsService.enable('delete', true);
        } else if (!res) {
          this.form.disable();
        }
      });

    this.userPermissionService.granted([readWrite('servicing-entities-sensitive-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res)
          this.form.get('accountNumber').enable();
      });

    this.onChanges();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      this.enableSaveAction();
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private dataInit(data: BrokerBankAccountDetailsComponentData) {
    this.data = data;

    this.form.reset({
      name: data.broker ? data.broker.name : '',
      bankName: data.bankAccount ? data.bankAccount.bankName : '',
      accountNumber: data.bankAccount ? data.bankAccount.accountNumber : '',
      routingNumber: data.bankAccount ? data.bankAccount.routingNumber : '',
    });

    this.accountType = this.data.bankAccount ? EnumHelper.getNameFromValue(BankAccountType, this.data.bankAccount.accountType) : null;

    this.title = this.data.bankAccount ? (this.data.bankAccount.bankName ? this.data.bankAccount.bankName : '')
      + ' ' + (this.accountType ? this.accountType : '') + ' ' + this.sanitize(this.data.bankAccount.accountNumber) : '';
    this.appBarTitleService.title = this.data.bankAccount ? this.title : 'Add bank';

    if (!this.data.bankAccount)
      this.appBarActionsService.hide('delete', true);
  }

  sanitize(s: string) {
    return s ? '*' + s.substring(s.length - 4) : null;
  }

  cancel() {
    this.appPageService.back();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);

    if (this.saveSub)
      return;

    if (!this.data || !this.data.broker || !this.form.valid) {
      return;
    }

    DialogConfirm.show(this.dialog, `Save changes to ${this.data.broker.name}?`)
      .subscribe(result => {
        const command: BankAccount = {
          id: this.data.bankAccount ? this.data.bankAccount.id : null,
          bankName: this.form.controls.bankName.value,
          routingNumber: this.form.controls.routingNumber.value,
          accountNumber: this.form.controls.accountNumber.value,
        };
        const that = this;
        if (result && this.data.bankAccount) {
          this.saveSub = that.brokerService
            .updateBrokerBankAccount(that.data.broker.id, command)
            .subscribe(that.saveSuccessHandler.bind(that), that.saveErrorHandler.bind(that));
        } else if (result && !that.data.bankAccount) {
          this.saveSub = that.brokerService
            .createBrokerBankAccount(that.data.broker.id, command)
            .subscribe(that.saveSuccessHandler.bind(that), that.saveErrorHandler.bind(that));
        }
      });
  }

  delete() {
    if (!this.data.bankAccount || !this.data.bankAccount.id || !this.canEditBankAccount)
      return;

    DialogConfirm.show(this.dialog, `Delete ${this.title}?`)
      .subscribe(res => {
        if (res) {
          this.brokerService.deleteBrokerBankAccount(this.data.broker.id, this.data.bankAccount.id)
            .subscribe(() => {
              this.router.navigate([`/broker/${this.data.broker.id}/banks`], { replaceUrl: true });
            });
        }
      });
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }

  enableSaveAction(): void {
    if (this.form.dirty && this.canEditBankAccount) {
      this.appBarActionsService.enable('save', true);
    }
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }
}

export interface BrokerBankAccountDetailsComponentData {
  broker?: BrokerData;
  bankAccount?: BankAccount;
}
