import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SharedModule } from '../shared/shared.module';

import { BankingRoutingModule } from './banking-routing.module';
import { BankingCustomerListComponent } from './banking-customer-list/banking-customer-list.component';
import { BankingAccountListComponent } from './banking-account-list/banking-account-list.component';
import { BankingTransactionListComponent } from './banking-transaction-list/banking-transaction-list.component';
import { BankingCustomerAppBarTitleComponent } from './banking-customer-app-bar-title/banking-customer-app-bar-title.component';
import {
  BankingAccountListFilterDialogComponent
} from './banking-account-list-filter-dialog/banking-account-list-filter-dialog.component';
import {
  BankingTransactionListFilterDialogComponent
} from './banking-transaction-list-filter-dialog/banking-transaction-list-filter-dialog.component';
import {
  BankingCustomerListFilterDialogComponent
} from './banking-customer-list-filter-dialog/banking-customer-list-filter-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        BankingRoutingModule,
        SharedModule
    ],
    declarations: [
        BankingCustomerListComponent,
        BankingAccountListComponent,
        BankingTransactionListComponent,
        BankingCustomerAppBarTitleComponent,
        BankingAccountListFilterDialogComponent,
        BankingTransactionListFilterDialogComponent,
        BankingCustomerListFilterDialogComponent
    ]
})
export class BankingModule { }
