import { Injectable } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { ObjectHelper, MatTableHelper, EnumHelper, FilterParameter, TransactionType, TransactionStatus, parseNumber } from 'common';
import { TransactionQueryParams, TransactionFilters, TransactionListComponentData } from './transaction.model';
import { TenantService } from '../admin/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionFilterService {

  constructor(private router: Router, private tenantService: TenantService)
  {
  }

  filter(filters: TransactionFilters,
    routeData: TransactionListComponentData,
    saleforceId: string,
    paginator?: MatPaginator,
    sort?: MatSort) {
    const query = new TransactionQueryParams(filters);
    query.loanNumber = typeof query.loanNumber === 'object' ? filters.loanNumber.loanNumber : query.loanNumber;
    query.businessName = typeof query.businessName === 'object' ? filters.businessName.businessName : query.businessName;

    // Set default to first page
    query.skip = undefined;

    query.fromDate = filters.fromDate ? moment(filters.fromDate).format('L') : undefined;
    query.toDate = filters.toDate ? moment(filters.toDate).format('L') : undefined;
    query.processDateFrom = filters.processDateFrom ? moment(filters.processDateFrom).format('L') : undefined;
    query.processDateTo = filters.processDateTo ? moment(filters.processDateTo).format('L') : undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    if (saleforceId) {
      this.router.navigate([`/account/sf/${saleforceId}/transactions`], { replaceUrl: true, queryParams: query });
    } else if (routeData.account && routeData.accountInfo) {
      this.router.navigate([`/account/${routeData.account.id}/transactions`], { replaceUrl: true, queryParams: query });
    } else {
      this.router.navigate(['transactions'], { replaceUrl: true, queryParams: query });
    }
  }

  addFilterParameters(query: TransactionQueryParams, promotionsNamesMap: Map<string, string>): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];

    if (query.loanNumber) {
      filters.push({
        id: 'loanNumber', name: 'Loan number', value: query.loanNumber.trim()
      });
    }
    if (query.businessName) {
      filters.push({
        id: 'businessName', name: 'Legal entity name', value: query.businessName.trim()
      });
    }
    if (query.id) {
      filters.push({
        id: 'id', name: 'ID', value: parseNumber(query.id)
      });
    }
    if (query.amountFrom) {
      filters.push({
        id: 'amountFrom', name: 'Amount from', value: formatCurrency(query.amountFrom, 'en_US', '$')
      });
    }
    if (query.amountTo) {
      filters.push({
        id: 'amountTo', name: 'Amount to', value: formatCurrency(query.amountTo, 'en_US', '$')
      });
    }
    if (query.creditLimitFrom) {
      filters.push({
        id: 'creditLimitFrom', name: 'Credit limit from', value: formatCurrency(query.creditLimitFrom, 'en_US', '$')
      });
    }
    if (query.creditLimitTo) {
      filters.push({
        id: 'creditLimitTo', name: 'Credit limit to', value: formatCurrency(query.creditLimitTo, 'en_US', '$')
      });
    }
    if (query.promotionIds) {
      filters.push({
        id: 'promotionIds', name: 'Promotion',
        value: ObjectHelper.makeArray(query.promotionIds).map(i => promotionsNamesMap?.get(i)).join(', ')
      });
    }
    if (query.fromDate) {
      filters.push({
        id: 'fromDate', name: 'From Date',
        value: `${moment(query.fromDate).format('L')} - ${moment(query.toDate).format('L')}`
      });
    }
    if (query.processDateFrom) {
      filters.push({
        id: 'processDateFrom', name: 'Processed Date',
        value: `${moment(query.processDateFrom).format('L')} - ${moment(query.processDateTo).format('L')}`
      });
    }
    if(query.companyId) {
      this.tenantService.names().subscribe(data => {
        filters.push({
          id: 'companyId', name: 'Brand', value: data.find(e => e.id === parseNumber(query.companyId))?.name ?? query.companyId
        })
      });
    }
    if (query.type !== null && typeof query.type !== 'undefined' && <any>query.type !== '') {
      filters.push({
        id: 'type', name: 'Type',
        value: ObjectHelper.makeArray(query.type).map(i => EnumHelper.getNameFromValue(TransactionType, i)).join(', ')
      });
    }
    if (query.status !== null && typeof query.status !== 'undefined' && <any>query.status !== '') {
      filters.push({
        id: 'status', name: 'Status',
        value: ObjectHelper.makeArray(query.status).map(i => EnumHelper.getNameFromValue(TransactionStatus, i)).join(', ')
      });
    }


    if (query.principalAmountFrom !== null && typeof query.principalAmountFrom !== 'undefined') {
      filters.push({
        id: 'principalAmountFrom', name: 'Principal amount above', value: formatCurrency(query.principalAmountFrom, 'en_US', '$')
      });
    }
    if (query.principalAmountTo !== null && typeof query.principalAmountTo !== 'undefined') {
      filters.push({
        id: 'principalAmountTo', name: 'Principal amount below', value: formatCurrency(query.principalAmountTo, 'en_US', '$')
      });
    }
    if (query.principalAmountExcept !== null && typeof query.principalAmountExcept !== 'undefined') {
      filters.push({
        id: 'principalAmountExcept', name: 'Principal amount is not', value: formatCurrency(query.principalAmountExcept, 'en_US', '$')
      });
    }


    if (query.interestAmountFrom !== null && typeof query.interestAmountFrom !== 'undefined') {
      filters.push({
        id: 'interestAmountFrom', name: 'Interest amount above', value: formatCurrency(query.interestAmountFrom, 'en_US', '$')
      });
    }
    if (query.interestAmountTo !== null && typeof query.interestAmountTo !== 'undefined') {
      filters.push({
        id: 'interestAmountTo', name: 'Interest amount below', value: formatCurrency(query.interestAmountTo, 'en_US', '$')
      });
    }
    if (query.interestAmountExcept !== null && typeof query.interestAmountExcept !== 'undefined') {
      filters.push({
        id: 'interestAmountExcept', name: 'Interest amount is not', value: formatCurrency(query.interestAmountExcept, 'en_US', '$')
      });
    }


    if (query.deferredFeeAmountFrom !== null && typeof query.deferredFeeAmountFrom !== 'undefined') {
      filters.push({
        id: 'deferredFeeAmountFrom', name: 'Deferred fee amount above', value: formatCurrency(query.deferredFeeAmountFrom, 'en_US', '$')
      });
    }
    if (query.deferredFeeAmountTo !== null && typeof query.deferredFeeAmountTo !== 'undefined') {
      filters.push({
        id: 'deferredFeeAmountTo', name: 'Deferred fee amount below', value: formatCurrency(query.deferredFeeAmountTo, 'en_US', '$')
      });
    }
    if (query.deferredFeeAmountExcept !== null && typeof query.deferredFeeAmountExcept !== 'undefined') {
      filters.push({
        id: 'deferredFeeAmountExcept', name: 'Deferred fee amount is not', value: formatCurrency(query.deferredFeeAmountExcept, 'en_US', '$')
      });
    }


    if (query.cashFeeAmountFrom !== null && typeof query.cashFeeAmountFrom !== 'undefined') {
      filters.push({
        id: 'cashFeeAmountFrom', name: 'Cash fee amount above', value: formatCurrency(query.cashFeeAmountFrom, 'en_US', '$')
      });
    }
    if (query.cashFeeAmountTo !== null && typeof query.cashFeeAmountTo !== 'undefined') {
      filters.push({
        id: 'cashFeeAmountTo', name: 'Cash fee amount below', value: formatCurrency(query.cashFeeAmountTo, 'en_US', '$')
      });
    }
    if (query.cashFeeAmountExcept !== null && typeof query.cashFeeAmountExcept !== 'undefined') {
      filters.push({
        id: 'cashFeeAmountExcept', name: 'Cash fee amount is not', value: formatCurrency(query.cashFeeAmountExcept, 'en_US', '$')
      });
    }


    if (query.disbursedAmountFrom !== null && typeof query.disbursedAmountFrom !== 'undefined') {
      filters.push({
        id: 'disbursedAmountFrom', name: 'Disbursed amount above', value: formatCurrency(query.disbursedAmountFrom, 'en_US', '$')
      });
    }
    if (query.disbursedAmountTo !== null && typeof query.disbursedAmountTo !== 'undefined') {
      filters.push({
        id: 'disbursedAmountTo', name: 'Disbursed amount below', value: formatCurrency(query.disbursedAmountTo, 'en_US', '$')
      });
    }
    if (query.disbursedAmountExcept !== null && typeof query.disbursedAmountExcept !== 'undefined') {
      filters.push({
        id: 'disbursedAmountExcept', name: 'Disbursed amount is not', value: formatCurrency(query.disbursedAmountExcept, 'en_US', '$')
      });
    }

    return filters;
  }
}
