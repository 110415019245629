import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPermissionData } from './user-permission.data';

export interface UserPermissionItem {
  permission?: UserPermissionData[];  
  allPermissionsRequired?: boolean;
  hidden?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  constructor(private userService: UserService) { }

  visibility(item: UserPermissionItem): Observable<UserPermissionItem> {
    return !item.permission
      ? of(item)
      : this.granted(item.permission, item.allPermissionsRequired).pipe(
        map(granted => {
          item.hidden = !granted;
          return item;
        })
      );
  }

  visibilityList(items: UserPermissionItem[]): Observable<UserPermissionItem[]> {
    return !items
      ? of(items)
      : this.userService.current.pipe(
        map(user => {
          if (
            !user ||
            !user.permissions ||
            !user.permissions.length
          ) {
            items.forEach(it => it.hidden = true);
            return items;
          }

          items.forEach(it => {
            it.hidden = !user.permissions.some(granted =>
              it.permission.some(required =>
                this.match(required, granted)
              )
            );
          });
          return items;
        })
      );
  }

  granted(
    permissionsRequired: UserPermissionData[],
    allPermissionsRequired?: boolean
  ): Observable<boolean> {
    return this.userService.current.pipe(
      map(user => {
        if (
          !user ||
          !user.permissions ||
          !user.permissions.length ||
          !permissionsRequired ||
          !permissionsRequired.length
        )
          return false;

        if (allPermissionsRequired)
        {
          return permissionsRequired.every(required =>
            user.permissions.some(granted =>
              this.match(required, granted)
            )
          );
        }

        return user.permissions.some(granted =>
          permissionsRequired.some(required =>
            this.match(required, granted)
          )
        );
      })
    );
  }

  private match(required: UserPermissionData, granted: UserPermissionData) {
    if (required.id === 'none') return true;
    if (required.id !== granted.id) return false;
    if (required.access === 'none') return true;

    if (
      required.access === 'read-only' &&
      ['read-only', 'read-write'].some(it => it === granted.access)
    )
      return true;

    if (required.access === 'read-write' && granted.access === 'read-write')
      return true;

    return false;
  }
}
