import { Component } from '@angular/core';
import { AutocompleteComponent } from 'common';
import { EntityService } from '../entity.service';
import { EMPTY, Observable } from 'rxjs';
import { EntityHintData } from '../entity.model';

@Component({
    selector: 'ifb-entity-ein-input',
    templateUrl: '../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
    styleUrls: ['../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class EntityEINInputComponent extends AutocompleteComponent {

    constructor(private entityService: EntityService) {
        super();
    }
    mel = null;
    label = 'EIN';

    optionFilter: (input: string | EntityHintData) => Observable<any[]> =
        input => input
            ? this.entityService.queryHints({
                ein: !!input ? (typeof input !== 'string' ? input.ein : input) : null,
                sortField: 'ein',
            })
            : EMPTY;

    optionLabelKey = 'ein';
}
