import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { routerTransition } from "projects/backoffice/src/app/shared/animations/router.animations";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OfferListQueryParams } from "../../models/offer-query.model";
import { OfferFilterService } from "../../offer-filter.service";
import { OfferListData } from "./models/offer-list-data.model";

@Component({
  selector: "ifb-offer-list",
  templateUrl: "./offer-list.component.html",
  styleUrls: ["./offer-list.component.scss"],
  animations: routerTransition,
})
export class OfferListComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<unknown>;

  tableColumns: string[] = [
    "name",
    "productCode",
    "status",
    "amount",
    "paymentFrequency",
    "repaymentTerm",
    "drawTerm",
    "offerRate",
    "uniqueId",
  ];

  data: OfferListData;
  private applicationId: number;
  private offerBundleId: number;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;
  queryParams: OfferListQueryParams;

  constructor(
    private route: ActivatedRoute,
    private offerFilterService: OfferFilterService,
    private queryParamsService: QueryParamsService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: OfferListData) => {
        this.data = data;
      });
    this.route.queryParams
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => {
        this.queryParams = new OfferListQueryParams(this.queryParamsService.init(it));
      });
    this.initPathParams();
  }

  private initPathParams(): void {
    const pathFromRoot = this.route.snapshot.pathFromRoot;

    this.applicationId =
    this.queryParams.id !== undefined
        ?  this.queryParams.id
        : pathFromRoot[pathFromRoot.length - 2].params.id;
    this.offerBundleId =
    this.queryParams.offerBundleId !== undefined
        ?  this.queryParams.offerBundleId
        : pathFromRoot[pathFromRoot.length - 2].params.offerBundleId;

    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  sortData(): void {
    this.filter();
  }

  setPage(): void {
    this.filter();
  }

  private filter(): void {
    this.offerFilterService.filter(
      this.applicationId,
      this.offerBundleId,
      this.paginator,
      this.sort
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
