import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ObjectHelper, MatTableHelper, EnumHelper, LinkingStatus, EntityQueryParams, FilterParameter } from 'common';
import { EntityFilters, EntityTypeLabel, EntityStatusLabel } from './entity.model';

@Injectable({
    providedIn: 'root'
})
export class EntityFilterService {

    constructor(private router: Router) {
    }

    filter(filters: EntityFilters, paginator?: MatPaginator, sort?: MatSort, brokerId?: number, advisorId?: number) {
        const query = new EntityQueryParams(filters);

        // Set default to first page
        query.skip = undefined;

        query.createdOnStart = filters.createdOnStart ? moment(filters.createdOnStart).format('L') : undefined;
        query.createdOnEnd = filters.createdOnEnd ? moment(filters.createdOnEnd).format('L') : undefined;
        query.updatedOnStart = filters.updatedOnStart ? moment(filters.updatedOnStart).format('L') : undefined;
        query.updatedOnEnd = filters.updatedOnEnd ? moment(filters.updatedOnEnd).format('L') : undefined;
        query.lastLinkingAttemptDateFrom = filters.lastLinkingAttemptDateFrom
            ? moment(filters.lastLinkingAttemptDateFrom).format('L') : undefined;
        query.lastLinkingAttemptDateTo = filters.lastLinkingAttemptDateTo
            ? moment(filters.lastLinkingAttemptDateTo).format('L') : undefined;

        query.createdBy = filters.createdBy ? filters.createdBy.fullName : undefined;
        query.updatedBy = filters.updatedBy ? filters.updatedBy.fullName : undefined;
        query.businessName = (filters.businessName && filters.businessName.businessName)
            ? filters.businessName.businessName : filters.businessName;
        query.number = (filters.number && filters.number.number) ? filters.number.number : filters.number;
        query.ein = (filters.ein && filters.ein.ein) ? filters.ein.ein : filters.ein;
        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        if (!brokerId && !advisorId)
            this.router.navigate(['company'], { replaceUrl: true, queryParams: query });
        else if (brokerId)
            this.router.navigate([`broker/${brokerId}/companies`], { replaceUrl: true, queryParams: query });
        else if (advisorId)
            this.router.navigate([`advisor/${advisorId}/companies`], { replaceUrl: true, queryParams: query });
    }

    addFilterParameters(query: EntityQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.uniqueId) {
            filters.push({
                id: 'uniqueId', name: 'Salesforce ID', value: query.uniqueId.trim()
            });
        }

        if (query.bankIntegrationUsername) {
            filters.push({
                id: 'bankIntegrationUsername', name: 'Bank integration username', value: query.bankIntegrationUsername.trim()
            });
        }

        if (query.advisorId) {
            filters.push({
                id: 'advisorId', name: 'Advisor', value: query.advisorId
            });
        }

        if (query.number) {
            filters.push({
                id: 'number', name: 'Entity number', value: query.number.trim()
            });
        }

        if (query.ein) {
            filters.push({
                id: 'ein', name: 'EIN', value: query.ein.trim()
            });
        }

        if (query.types) {
            filters.push({
                id: 'types', name: 'Types', value: ObjectHelper.makeArray(query.types).map(t => EntityTypeLabel.get(t)).join(', ')
            });
        }

        if (query.statuses) {
            filters.push({
                id: 'statuses', name: 'Statuses',
                value: ObjectHelper.makeArray(query.statuses).map(e => EntityStatusLabel.get(e)).join(', ')
            });
        }

        if (query.businessName) {
            filters.push({
                id: 'businessName', name: 'Name', value: query.businessName.trim()
            });
        }

        if (query.createdOnStart) {
            filters.push({
                id: 'createdOnStart', name: 'Created on',
                value: `${moment(query.createdOnStart).format('L')} - ${moment(query.createdOnEnd).format('L')}`
            });
        }

        if (query.updatedOnStart) {
            filters.push({
                id: 'updatedOnStart', name: 'Updated on',
                value: `${moment(query.updatedOnStart).format('L')} - ${moment(query.updatedOnEnd).format('L')}`
            });
        }

        if (query.updatedBy) {
            filters.push({
                id: 'updatedBy', name: 'Updated by', value: query.updatedBy.trim()
            });
        }

        if (query.createdBy) {
            filters.push({
                id: 'createdBy', name: 'Created by', value: query.createdBy.trim()
            });
        }

        if (query.lastLinkingAttemptDateFrom) {
            filters.push({
                id: 'lastLinkingAttemptDateFrom', name: 'Last linking attempt date',
                value: `${moment(query.lastLinkingAttemptDateFrom).format('L')} - ${moment(query.lastLinkingAttemptDateTo).format('L')}`
            });
        }

        if (+query.bankLinkStatus >= 0) {
            filters.push({
                id: 'bankLinkStatus', name: 'Bank link status',
                value: _.startCase(EnumHelper.getNameFromValue(LinkingStatus, +query.bankLinkStatus))
            });
        }

        if(query.bankLinkAttempted) {
            filters.push({
                id: 'bankLinkAttempted', name: 'Bank link attempted', value: query.bankLinkAttempted.toString() === 'true' ? 'Yes' : 'No'
            });
        }

        if (query.isVerified) {
            filters.push({
                id: 'isVerified', name: 'Verification status', value: query.isVerified.toString() === 'true' ? 'Verified' : 'Unverified'
            });
        }
        if (query.active) {
            filters.push({
                id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
            });
        }

        return filters;
    }
}
