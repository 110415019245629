import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CashFlowAnalysisService } from '../cash-flow-analysis.service';

@Component({
  selector: 'ifb-financial-summary',
  templateUrl: '../cash-flow-analysis.component.html',
  styleUrls: ['../cash-flow-analysis.component.scss'],
  // AK: Automatic change detection seems to be causing mutliple refreshes of iframe containing cash flow summary report
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSummaryComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly cashFlowAnalysisService: CashFlowAnalysisService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data && data.entity)
          this.getData(data.entity.id);
      });
  }

  getData(entityId: number): void {
    this.cashFlowAnalysisService.getFinancialSummary(entityId)
      .subscribe(data => {
        this.data = data;
        // AK: Refresh component to show iframe if there is data
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
