<ifc-app-scroll-container>
  <div id="main">
    <mat-tab-group>
      <mat-tab [label]="signature.signer.fullName | uppercase" *ngFor="let signature of documentFile.signatures">
        <ifb-signature [signature]="signature"></ifb-signature>
      </mat-tab>
    </mat-tab-group>
    <div class="message-box info" *ngIf="!documentFile.signatures" fxLayout="row" fxLayoutGap="12px"
      fxLayoutAlign="start center">
      <mat-icon class="icon">info</mat-icon>
      <span>No signatures available</span>
    </div>
  </div>
</ifc-app-scroll-container>