<div>
    <img src="../../../assets/img/ryno-leaving.png" alt="goodbye" />
</div>
<div>
    <h2>
        You have successfully signed out.
    </h2>
    <div>
        It's a good idea to close this browser window.
    </div>
    <div>
        <button mat-button (click)="goHome()">Sign back in</button>
    </div>
</div>