import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppSidenavComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppBarExpandedTabsComponent
} from 'common';
import { appPageComponentDataMaster } from '../app.data';
import { AdminAppBarExpandedTabsResolver } from './admin.resolver';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { AdminComponent } from './admin.component';
import { BOAuthGuard } from '../guards/oauth.guard';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Admin',
  sideNavItem: 'admin'
});

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
    {
      path: 'admin',
      component: AppPageComponent,
      data: appPageDataMaster,
      resolve: {
        appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
        appPage: AppPageResolver,
        appSidenav: AppSidenavResolver
      },
      children: [
        {
          path: '',
          outlet: 'sidenav-content',
          component: AppSidenavComponent
        },
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: UserMenuComponent,
            },
            {
              path: '',
              outlet: 'expanded',
              component: AppBarExpandedTabsComponent
            }
          ]
        },
        {
          path: '',
          component: AdminComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange"
        }
      ]
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
