import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService, AppBarActionsService, MessageService, AppBarAction, EnumHelper, LoanInfoDto, FormHelper, FeeType, PaymentFrequency, PaymentFrequencyLabel, AppPageService } from 'common';
import { AccountService } from '../account.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { AccountData , AccountOfferData } from '../account.model';
import { AccountOfferUpdateDialogComponent } from '../account-offer-update-dialog/account-offer-update-dialog.component';

@Component({
  selector: 'ifb-account-offer-details',
  templateUrl: './account-offer-details.component.html',
  styleUrls: ['./account-offer-details.component.scss']
})
export class AccountOfferDetailsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  private saveSub: Subscription;
  form: UntypedFormGroup;
  data: AccountStateDetailsComponentData;
  get FeeType() { return FeeType; }

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private messageService: MessageService,
    private dialog: MatDialog) {

    this._unsubscribeAll = new Subject();

    this.formInit();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: !this.form.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
  }

  accountOfferPaymentFrequenciesOptions = EnumHelper.getMappedNamesAndValues(PaymentFrequency, PaymentFrequencyLabel);
  feeTypeOptions = EnumHelper.getNamesAndValues(FeeType);

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  dataInit(data: AccountStateDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Offer';

    this.form.reset({
      creditLimit: this.data.account.offer.amount,
      annualRate: this.data.account.offer.interestRate,
      dailyInterestRate: this.data.account.offer.dailyInterestRate,
      weeklyInterestRate: this.data.account.offer.weeklyInterestRate,
      simpleInterestRate: this.data.account.offer.simpleInterestRate,
      drawDownFee: this.data.account.offer.drawDownFee,
      drawDownFeeType: this.data.account.offer.drawDownFeeType,
      paymentFrequency: this.data.account.offer.paymentFrequency,
      drawTerm: this.data.account.offer.drawTerm,
      repaymentTerm: this.data.account.offer.repaymentTerm,
      noPaymentTerms: this.data.account.offer.noPaymentTerms,
      interestOnlyPaymentTerms: this.data.account.offer.interestOnlyPaymentTerms,
      maintenanceFee: this.data.account.offer.maintenanceFee,
      maintenanceFeeType: this.data.account.offer.maintenanceFeeType,
      maintenanceFeeFrequency: this.data.account.offer.maintenanceFeeFrequency,
      originationFee: this.data.account.offer.originationFee,
      originationFeeType: this.data.account.offer.originationFeeType,
      uccFilingFee: this.data.account.offer.uccFilingFee,
      uccFilingFeeType: this.data.account.offer.uccFilingFeeType,
      servicingFee: this.data.account.offer.servicingFee,
      servicingFeeType: this.data.account.offer.servicingFeeType,
      servicingFeeMinimum: this.data.account.offer.servicingFeeMinimum,
      servicingFeeMinimumType: this.data.account.offer.servicingFeeMinimumType,
      stackingFee: this.data.account.offer.stackingFee,
      stackingFeeType: this.data.account.offer.stackingFeeType,
      creditModelId: undefined
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  private formInit() {
    this.form = this.formBuilder.group({
      creditLimit: [undefined, [Validators.required, Validators.min(0.01), Validators.max(999999999999)]],
      annualRate: [undefined, [Validators.required, Validators.min(0.01), Validators.max(9999)]],
      dailyInterestRate: [{ value: undefined, disabled: true }],
      weeklyInterestRate: [{ value: undefined, disabled: true }],
      simpleInterestRate: [{ value: undefined, disabled: true }],
      drawDownFee: [undefined, Validators.required],
      drawDownFeeType: undefined,
      paymentFrequency: undefined,
      drawTerm: [undefined, [Validators.required, Validators.min(0), Validators.max(9999)]],
      repaymentTerm: [undefined, [Validators.required, Validators.min(1), Validators.max(9999)]],
      noPaymentTerms: [undefined, [Validators.required, Validators.min(0), Validators.max(9999)]],
      interestOnlyPaymentTerms: [undefined, [Validators.required, Validators.min(0), Validators.max(9999)]],
      maintenanceFee: [undefined, Validators.required],
      maintenanceFeeType: undefined,
      maintenanceFeeFrequency: undefined,
      originationFee: [undefined, Validators.required],
      originationFeeType: undefined,
      uccFilingFee: [undefined, Validators.required],
      uccFilingFeeType: undefined,
      servicingFee: [undefined, Validators.required],
      servicingFeeType: undefined,
      servicingFeeMinimum: [undefined, Validators.required],
      servicingFeeMinimumType: undefined,
      stackingFee: [undefined, Validators.required],
      stackingFeeType: undefined,
      creditModelId: undefined
    });
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    AccountOfferUpdateDialogComponent.show(this.dialog, this.form)
      .subscribe(result => {
        if (result) {
          const offer: AccountOfferData = {
            drawTerm: this.form.controls.drawTerm.value,
            repaymentTerm: this.form.controls.repaymentTerm.value,
            noPaymentTerms: this.form.controls.noPaymentTerms.value,
            interestOnlyPaymentTerms: this.form.controls.interestOnlyPaymentTerms.value,
            interestRate: this.form.controls.annualRate.value,
            amount: this.form.controls.creditLimit.value,
            paymentFrequency: this.form.controls.paymentFrequency.value,
            drawDownFee: this.form.controls.drawDownFee.value,
            drawDownFeeType: this.form.controls.drawDownFeeType.value,
            maintenanceFee: this.form.controls.maintenanceFee.value,
            maintenanceFeeType: this.form.controls.maintenanceFeeType.value,
            maintenanceFeeFrequency: this.form.controls.maintenanceFeeFrequency.value,
            originationFee: this.form.controls.originationFee.value,
            originationFeeType: this.form.controls.originationFeeType.value,
            uccFilingFee: this.form.controls.uccFilingFee.value,
            uccFilingFeeType: this.form.controls.uccFilingFeeType.value,
            servicingFee: this.form.controls.servicingFee.value,
            servicingFeeType: this.form.controls.servicingFeeType.value,
            servicingFeeMinimum: this.form.controls.servicingFeeMinimum.value,
            servicingFeeMinimumType: this.form.controls.servicingFeeMinimumType.value,
            stackingFee: this.form.controls.stackingFee.value,
            stackingFeeType: this.form.controls.stackingFeeType.value
          };
          this.accountService.updateLoanOffer(this.data.account.id, offer)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }
}

export interface AccountStateDetailsComponentData {
  account: AccountData;
  accountInfo: LoanInfoDto;
}

