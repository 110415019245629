import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifb-customer-kba-status-icon-chip',
  templateUrl: './customer-kba-status-icon-chip.component.html',
  styleUrls: ['./customer-kba-status-icon-chip.component.scss']
})
export class CustomerKBAStatusIconChipComponent {

  constructor() { }

  @Input()
  inverted = false;

  @Input()
  status = 0;

  statusLabels = {
    'none' : { color: 'mat-grey-bg', icon: 'help', label: 'Pending' },
    'success': { color: 'mat-green-bg', icon: 'check_circle', label: 'Verified' },
    'failed': { color: 'mat-red-bg', icon: 'error', label: 'Failed' },
  };
}
