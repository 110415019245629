import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountDocumentListComponent } from '../document/document-list/account-document-list.component';
import { AccountDocumentsUploadComponent } from '../document/document-upload/account-documents-upload.component';
import { ApplicationDocumentListComponent } from './document-list/application-document-list.component';
import {
    AppPageComponent,
    AppSidenavComponent,
    AppBarComponent,
    AppBarExpandedTabsComponent,
    AppBarTitleComponent,
    AppBarActionsComponent
} from 'common';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { ApplicationAppBarTitleComponent } from '../application/application-app-bar-title/application-app-bar-title.component';
import {
    ApplicationAppBarExpandedTabsResolver,
    ApplicationResolver,
} from '../application/application.resolver';
import { UserCurrentResolver } from '../user/user.resolver';
import { ApplicationAppBarExpandedComponent } from '../application/application-app-bar-expanded/application-app-bar-expanded.component';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../app.data';
import {
  AccountUniqueIdResolver,
  AccountInfoUniqueIdResolver,
  AccountAmortizationUniqueIdResolver,
  AccountSFAppBarExpandedTabsResolver,
  AccountAppBarExpandedTabsResolver,
  AccountInfoResolver,
  AccountAmortizationResolver,
  AccountResolver
} from '../account/account.resolver';
import { 
  AccountDocumentByLoanQueryResolver,
  AccountDocumentByUniqueIdQueryResolver,
  ApplicationDocumentByApplicationQueryResolver,
  ApplicationDocumentByUniqueIdQueryResolver,
  DocumentSignaturesResolver
 } from './document.resolver';
import { AccountAppBarExpandedComponent } from '../account/account-app-bar-expanded/account-app-bar-expanded.component';
import { AccountAppBarTitleComponent } from '../account/account-app-bar-title/account-app-bar-title.component';
import { BOAuthGuard } from '../guards/oauth.guard';
import { UserPermissionnNPLEconomics } from '../user/user-permission/user-permission.resolver';
import { ApplicationDocumentsUploadComponent } from './document-upload/application-documents-upload.component';
import { AccountDocumentDetailsComponent } from './document-details/account-document-details.component';

const appPageDataMaster = appPageComponentDataMaster({
    appBarTitle: 'Documents',
    sideNavItem: 'document'
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
      {
          path: 'application/:id/documents',
          canActivate: [BOAuthGuard],
          component: AppPageComponent,
          data: appPageDataMaster,
          resolve: {
            appPage: AppPageResolver,
            appSidenav: AppSidenavResolver,
            application: ApplicationResolver,
            userCurrent: UserCurrentResolver,
            appBarExpandedTabs: ApplicationAppBarExpandedTabsResolver
          },
          children: [
            {
              path: '',
              outlet: 'sidenav-content',
              component: AppSidenavComponent
            },
            {
              path: '',
              component: AppBarComponent,
              outlet: 'app-bar',
              children: [
                {
                  path: '',
                  outlet: 'title',
                  component: ApplicationAppBarTitleComponent
                },
                {
                  path: '',
                  outlet: 'right',
                  component: UserMenuComponent,
                },
                {
                  path: '',
                  outlet: 'expanded',
                  component: ApplicationAppBarExpandedComponent,
                  children: [
                    {
                      path: '',
                      outlet: 'tabs',
                      component: AppBarExpandedTabsComponent,
                    }
                  ]
                }
              ]
            },
            {
              path: '',
              component: ApplicationDocumentListComponent,
              runGuardsAndResolvers: "paramsOrQueryParamsChange",
              resolve: {
                documentQueryResult: ApplicationDocumentByApplicationQueryResolver,
              }
            }
          ]
        },
        {
          path: 'application/sf/:uniqueId/documents',
          canActivate: [BOAuthGuard],
          component: AppPageComponent,
          data: {
            appBar: {
              expandable: true,
              fixed: true,
              logoHidden: true,
            },
            appPage: {
              sidenavEnabled: false
            }
          },
          resolve: {
            appPage: AppPageResolver,
            appSidenav: AppSidenavResolver,
            application: ApplicationResolver,
            appBarExpandedTabs: ApplicationAppBarExpandedTabsResolver
          },
          children: [
            {
              path: '',
              component: AppBarComponent,
              outlet: 'app-bar',
              children: [
                {
                  path: '',
                  outlet: 'expanded',
                  component: ApplicationAppBarExpandedComponent,
                  children: [
                    {
                      path: '',
                      outlet: 'tabs',
                      component: AppBarExpandedTabsComponent,
                    }
                  ]
                }
              ]
            },
            {
              path: '',
              component: ApplicationDocumentListComponent,
              runGuardsAndResolvers: "paramsOrQueryParamsChange",
              resolve: {
                documentQueryResult: ApplicationDocumentByUniqueIdQueryResolver
              }
            }
          ]
        },
  
        {
          path: 'application/:id/documents/upload',
          canActivate: [BOAuthGuard],
          component: AppPageComponent,
          data: appPageDataDetail,
          children: [
            {
              path: '',
              component: AppBarComponent,
              outlet: 'app-bar',
              children: [
                {
                  path: '',
                  outlet: 'title',
                  component: AppBarTitleComponent,
                },
                {
                  path: '',
                  outlet: 'right',
                  component: AppBarActionsComponent,
                }
              ]
            },
            {
              path: '',
              component: ApplicationDocumentsUploadComponent
            }
          ]
        },
        {
          path: 'application/sf/:uniqueId/documents/upload',
          canActivate: [BOAuthGuard],
          component: AppPageComponent,
          data: appPageDataDetail,
          children: [
            {
              path: '',
              component: AppBarComponent,
              outlet: 'app-bar',
              children: [
                {
                  path: '',
                  outlet: 'title',
                  component: AppBarTitleComponent,
                },
                {
                  path: '',
                  outlet: 'right',
                  component: AppBarActionsComponent,
                }
              ]
            },
            {
              path: '',
              component: ApplicationDocumentsUploadComponent
            }
          ]
        },
       {
        path: 'account/:id/documents',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,      
          userCurrent: UserCurrentResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountDocumentListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              documentQueryResult: AccountDocumentByLoanQueryResolver,
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/documents',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
          userCurrent: UserCurrentResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountDocumentListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              documentQueryResult: AccountDocumentByUniqueIdQueryResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/documents/upload',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountDocumentsUploadComponent
          }
        ]
      },
      {
        path: 'account/:id/documents/:documentId',
        component:AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent            
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent
              }
            ]
          },
          {
            path: '',
            component: AccountDocumentDetailsComponent,
            resolve: {
              documentFile: DocumentSignaturesResolver
            } 
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/documents/upload',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountDocumentsUploadComponent
          }
        ]
      },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DocumentRoutingModule { }
