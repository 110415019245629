import { OfferBundleTabsComponent } from './../offer-bundle/offer-bundle-list/components/offer-bundle-tabs/offer-bundle-tabs.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../app.data';
import { BOAuthGuard } from '../guards/oauth.guard';
import {
    AppPageComponent,
    AppSidenavComponent,
    AppBarComponent,
    AppBarExpandedTabsComponent,
    AppBarExpandedComponent,
    AppBarTitleComponent,
    AppBarActionsComponent
} from 'common';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { ApplicationAppBarTitleComponent } from './application-app-bar-title/application-app-bar-title.component';
import { ApplicationListComponent } from './application-list/application-list.component';
import {
    ApplicationQueryResolver,
    ApplicationAppBarExpandedTabsResolver,
    ApplicationResolver,
    ApplicationBankAccountListResolver,
    ApplicationOwnersResolver,
    ApplicationRenewalsResolver,
} from './application.resolver';
import { ApplicationProfileComponent } from './application-profile/application-profile.component';
import { UserCurrentResolver } from '../user/user.resolver';
import { ApplicationSummaryComponent } from '../shared/cash-flow-analysis/application-summary/application-summary.component';
import { BankAccountListComponent } from '../bank/bank-account-list/bank-account-list.component';
import { ApplicationAppBarExpandedComponent } from './application-app-bar-expanded/application-app-bar-expanded.component';
import { BankAccountTabsComponent } from '../bank/bank-account-tabs/bank-account-tabs.component';
import { BankAccountDetailsComponent } from '../bank/bank-account-details/bank-account-details.component';
import { BankAccountTransactionListComponent } from '../bank/bank-account-transaction-list/bank-account-transaction-list.component';
import {
    BankAccountResolverForApplication,
    BankAccountAuditTrailResolver,
    BankTransactionListResolverForApplication
} from '../bank/bank.resolver';
import { EntityAccountResolverForApplication } from '../entity/entity.resolver';
import { BankAccountAuditTrailComponent } from '../bank/bank-account-audit-trail/bank-account-audit-trail.component';
import { OfferBundleListComponent } from '../offer-bundle/offer-bundle-list/offer-bundle-list.component';
import { OfferBundleCheckoutRequirementsResolver, OfferBundleDetailsResolver, OfferBundleListResolver } from '../offer-bundle/offer-bundle.resolver';
import { OfferBundleDetailsComponent } from '../offer-bundle/offer-bundle-list/components/offer-bundle-details/offer-bundle-details.component';
import { OfferListComponent } from '../offer/components/offer-list/offer-list.component';
import { OfferBundleCheckoutRequirementListComponent } from '../offer-bundle/offer-bundle-list/components/offer-bundle-checkout-requirement-list/offer-bundle-checkout-requirement-list.component';
import { OfferDetailsResolver, OfferListResolver } from '../offer/offer.resolver';
import { OfferTabsComponent } from '../offer/components/offer-tabs/offer-tabs.component';
import { OfferFeesComponent } from '../offer/components/offer-tabs/components/offer-fees/offer-fees.component';
import { OfferEconomicsComponent } from '../offer/components/offer-tabs/components/offer-economics/offer-economics.component';
import { OfferDetailsComponent } from '../offer/components/offer-tabs/components/offer-details/offer-details.component';
import { ApplicationRenewalsEditComponent } from './application-renewals-widget/components/application-renewals-edit/application-renewals-edit.component';

const appPageDataMaster = appPageComponentDataMaster({
    appBarTitle: 'Applications',
    sideNavItem: 'application'
});
const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
    {
        path: '',
        canActivate: [BOAuthGuard],
        children: [
            {
                path: 'application',
                component: AppPageComponent,
                data: appPageDataMaster,
                resolve: {
                    appPage: AppPageResolver,
                    appSidenav: AppSidenavResolver,
                },
                children: [
                    {
                        path: '',
                        outlet: 'sidenav-content',
                        component: AppSidenavComponent
                    },
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: AppBarTitleComponent,
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: UserMenuComponent,
                            },
                            {
                                path: '',
                                outlet: 'expanded',
                                component: AppBarExpandedComponent,
                                children: [
                                    {
                                        path: '',
                                        outlet: 'tabs',
                                        component: AppBarExpandedTabsComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '',
                        component: ApplicationListComponent,
                        runGuardsAndResolvers: "paramsOrQueryParamsChange",
                        resolve: {
                            applicationQueryResult: ApplicationQueryResolver
                        }
                    }
                ]
            },
            {
                path: 'application/:id/profile',
                component: AppPageComponent,
                data: appPageDataMaster,
                resolve: {
                    appBarExpandedTabs: ApplicationAppBarExpandedTabsResolver,
                    appPage: AppPageResolver,
                    appSidenav: AppSidenavResolver,
                    application: ApplicationResolver,
                    userCurrent: UserCurrentResolver,
                    owners: ApplicationOwnersResolver
                },
                children: [
                    {
                        path: '',
                        outlet: 'sidenav-content',
                        component: AppSidenavComponent
                    },
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: ApplicationAppBarTitleComponent
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: UserMenuComponent,
                            },
                            {
                                path: '',
                                outlet: 'expanded',
                                component: ApplicationAppBarExpandedComponent,
                                children: [
                                    {
                                        path: '',
                                        outlet: 'tabs',
                                        component: AppBarExpandedTabsComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '',
                        component: ApplicationProfileComponent,
                        runGuardsAndResolvers: "paramsOrQueryParamsChange"
                    }
                ]
            },
            {
              path: 'application/:id/profile/renewals',
              component: AppPageComponent,
              data: appPageDataDetail,
              children: [
                {
                  path: '',
                  component: AppBarComponent,
                  outlet: 'app-bar',
                  children: [
                    {
                      path: '',
                      outlet: 'title',
                      component: AppBarTitleComponent,
                    },
                    {
                      path: '',
                      outlet: 'right',
                      component: AppBarActionsComponent,
                    }
                  ]
                },
                {
                  path: '',
                  component: ApplicationRenewalsEditComponent,
                  resolve: {
                    renewal: ApplicationRenewalsResolver,
                },
                }
              ]
            },
            {
                path: 'application/:id/offer-bundles',
                component: AppPageComponent,
                data: appPageDataMaster,
                resolve: {
                    appBarExpandedTabs: ApplicationAppBarExpandedTabsResolver,
                    appPage: AppPageResolver,
                    appSidenav: AppSidenavResolver,
                    application: ApplicationResolver,
                },
                children: [
                    {
                        path: '',
                        outlet: 'sidenav-content',
                        component: AppSidenavComponent
                    },
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: ApplicationAppBarTitleComponent
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: UserMenuComponent,
                            },
                            {
                                path: '',
                                outlet: 'expanded',
                                component: ApplicationAppBarExpandedComponent,
                                children: [
                                    {
                                        path: '',
                                        outlet: 'tabs',
                                        component: AppBarExpandedTabsComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '',
                        component: OfferBundleListComponent,
                        runGuardsAndResolvers: "paramsOrQueryParamsChange",
                        resolve: {
                          offerBundleListResult: OfferBundleListResolver,
                          application: ApplicationResolver,
                        },
                    }
                ]
            },
            {
              path: 'application/:id/offer-bundle/:offerBundleId',
              component: AppPageComponent,
              data: {
                  appBar: {
                      expandable: false,
                      logoHidden: true,
                  },
                  appBarActions: appPageDataDetail.appBarActions,
                  appPage: {
                      contentClass: 'fill',
                      contentAlignment: 'start-start',
                      contentWithoutPadding: true
                  }
              },
              children: [
                  {
                      path: '',
                      component: AppBarComponent,
                      outlet: 'app-bar',
                      children: [
                          {
                              path: '',
                              outlet: 'title',
                              component: AppBarTitleComponent,
                          },
                          {
                              path: '',
                              outlet: 'right',
                              component: AppBarActionsComponent,
                          }
                      ]
                  },
                  {
                      path: '',
                      component: OfferBundleTabsComponent,
                      resolve: {
                        offerBundle: OfferBundleDetailsResolver
                      },
                      children: [
                          { path: '', pathMatch: 'full', redirectTo: 'details' },
                          {
                              path: 'details',
                              component: OfferBundleDetailsComponent,
                              resolve: {
                                application: ApplicationResolver,
                                offerBundle: OfferBundleDetailsResolver
                              },
                          },
                          {
                              path: 'offers',
                              component: OfferListComponent,
                              runGuardsAndResolvers: "paramsOrQueryParamsChange",
                              resolve: {
                                offerListResult: OfferListResolver,
                              },
                          },
                          {
                              path: 'checkout-requirements',
                              component: OfferBundleCheckoutRequirementListComponent,
                              runGuardsAndResolvers: "paramsOrQueryParamsChange",
                              resolve: {
                                checkoutRequirements: OfferBundleCheckoutRequirementsResolver
                              },
                          }
                      ]
                  }
              ]
            },
            {
              path: 'application/:id/offer-bundle/:offerBundleId/offer/:offerId',
              component: AppPageComponent,
              data: {
                  appBar: {
                      expandable: false,
                      logoHidden: true,
                  },
                  appBarActions: appPageDataDetail.appBarActions,
                  appPage: {
                      contentClass: 'fill',
                      contentAlignment: 'start-start',
                      contentWithoutPadding: true
                  }
              },
              children: [
                  {
                      path: '',
                      component: AppBarComponent,
                      outlet: 'app-bar',
                      children: [
                          {
                              path: '',
                              outlet: 'title',
                              component: AppBarTitleComponent,
                          },
                          {
                              path: '',
                              outlet: 'right',
                              component: AppBarActionsComponent,
                          }
                      ]
                  },
                  {
                      path: '',
                      component: OfferTabsComponent,
                      resolve: {
                        offer: OfferDetailsResolver
                      },
                      children: [
                          { path: '', pathMatch: 'full', redirectTo: 'details' },
                          {
                              path: 'details',
                              component: OfferDetailsComponent,
                              resolve: {
                                application: ApplicationResolver,
                                offerBundle: OfferBundleDetailsResolver,
                                offer: OfferDetailsResolver
                              },
                          },
                          {
                              path: 'economics',
                              component: OfferEconomicsComponent,
                              runGuardsAndResolvers: "paramsOrQueryParamsChange",
                              resolve: {
                                offer: OfferDetailsResolver
                              },
                          },
                          {
                              path: 'fees',
                              component: OfferFeesComponent,
                              runGuardsAndResolvers: "paramsOrQueryParamsChange",
                              resolve: {
                                offer: OfferDetailsResolver
                              },
                          }
                      ]
                  }
              ]
            },
            {
                path: 'application/:id/bank-analysis',
                component: AppPageComponent,
                data: appPageDataMaster,
                resolve: {
                    appBarExpandedTabs: ApplicationAppBarExpandedTabsResolver,
                    appPage: AppPageResolver,
                    appSidenav: AppSidenavResolver,
                    application: ApplicationResolver
                },
                children: [
                    {
                        path: '',
                        outlet: 'sidenav-content',
                        component: AppSidenavComponent
                    },
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: ApplicationAppBarTitleComponent
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: UserMenuComponent,
                            },
                            {
                                path: '',
                                outlet: 'expanded',
                                component: ApplicationAppBarExpandedComponent,
                                children: [
                                    {
                                        path: '',
                                        outlet: 'tabs',
                                        component: AppBarExpandedTabsComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '',
                        component: ApplicationSummaryComponent,
                        runGuardsAndResolvers: "paramsOrQueryParamsChange"
                    }
                ]
            },
            {
                path: 'application/:id/bank-accounts',
                component: AppPageComponent,
                data: appPageDataMaster,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                    appPage: AppPageResolver,
                    appSidenav: AppSidenavResolver,
                    application: ApplicationResolver,
                    userCurrent: UserCurrentResolver,
                    appBarExpandedTabs: ApplicationAppBarExpandedTabsResolver
                },
                children: [
                    {
                        path: '',
                        outlet: 'sidenav-content',
                        component: AppSidenavComponent
                    },
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: ApplicationAppBarTitleComponent
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: UserMenuComponent,
                            },
                            {
                                path: '',
                                outlet: 'expanded',
                                component: ApplicationAppBarExpandedComponent,
                                children: [
                                    {
                                        path: '',
                                        outlet: 'tabs',
                                        component: AppBarExpandedTabsComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '',
                        component: BankAccountListComponent,
                        runGuardsAndResolvers: "paramsOrQueryParamsChange",
                        resolve: {
                            application: ApplicationResolver,
                            bankAccountQueryResult: ApplicationBankAccountListResolver
                        }
                    }
                ]
            },
            {
                path: 'application/:id/bank-account/:bankAccountId',
                component: AppPageComponent,
                data: {
                    appBar: {
                        closeAction: 'application',
                        expandable: false,
                        logoHidden: true,
                    },
                    appBarActions: appPageDataDetail.appBarActions,
                    appPage: {
                        contentClass: 'fill',
                        contentAlignment: 'start-start',
                        contentWithoutPadding: true
                    }
                },
                children: [
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: AppBarTitleComponent,
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: AppBarActionsComponent,
                            }
                        ]
                    },
                    {
                        path: '',
                        component: BankAccountTabsComponent,
                        children: [
                            { path: '', pathMatch: 'full', redirectTo: 'details' },
                            {
                                path: 'details',
                                component: BankAccountDetailsComponent,
                                data: { viewMode: true },
                                resolve: {
                                    bankAccount: BankAccountResolverForApplication,
                                    application: ApplicationResolver,
                                    entityLoans: EntityAccountResolverForApplication
                                }
                            },
                            {
                                path: 'transactions',
                                component: BankAccountTransactionListComponent,
                                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                                resolve: {
                                    bankAccount: BankAccountResolverForApplication,
                                    application: ApplicationResolver,
                                    entityLoans: EntityAccountResolverForApplication,
                                    bankTransactionQueryResult: BankTransactionListResolverForApplication
                                }
                            },
                            {
                                path: 'audit',
                                component: BankAccountAuditTrailComponent,
                                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                                resolve: {
                                    bankAccount: BankAccountResolverForApplication,
                                    application: ApplicationResolver,
                                    entityLoans: EntityAccountResolverForApplication,
                                    auditTrail: BankAccountAuditTrailResolver
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'application/:id/bank-account/:bankAccountId/edit',
                component: AppPageComponent,
                data: {
                    appBar: {
                        closeAction: 'application',
                        expandable: false,
                        logoHidden: true,
                    },
                    appBarActions: appPageDataDetail.appBarActions,
                    appPage: {
                        contentClass: 'fill',
                        contentAlignment: 'start-start',
                        contentWithoutPadding: true
                    }
                },
                children: [
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: AppBarTitleComponent,
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: AppBarActionsComponent,
                            }
                        ]
                    },
                    {
                        path: '',
                        component: BankAccountTabsComponent,
                        children: [
                            { path: '', pathMatch: 'full', redirectTo: 'details' },
                            {
                                path: 'details',
                                component: BankAccountDetailsComponent,
                                resolve: {
                                    bankAccount: BankAccountResolverForApplication,
                                    application: ApplicationResolver,
                                    entityLoans: EntityAccountResolverForApplication
                                }
                            },
                            {
                                path: 'transactions',
                                component: BankAccountTransactionListComponent,
                                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                                resolve: {
                                    bankAccount: BankAccountResolverForApplication,
                                    application: ApplicationResolver,
                                    entityLoans: EntityAccountResolverForApplication,
                                    bankTransactionQueryResult: BankTransactionListResolverForApplication
                                }
                            },
                            {
                                path: 'audit',
                                component: BankAccountAuditTrailComponent,
                                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                                resolve: {
                                    bankAccount: BankAccountResolverForApplication,
                                    application: ApplicationResolver,
                                    entityLoans: EntityAccountResolverForApplication,
                                    auditTrail: BankAccountAuditTrailResolver
                                }
                            }
                        ]
                    }
                ]
            }
        ]

    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ApplicationRoutingModule { }
