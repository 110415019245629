import { Component, OnInit, OnDestroy } from '@angular/core';
import { routerTransition } from '../../shared/animations/router.animations';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite, readOnly } from '../../user/user-permission/user-permission.data';
import { EntityService } from '../entity.service';
import { MatDialog } from '@angular/material/dialog';
import { BusinessAdvisorPickerComponent } from '../../shared/business-advisor/business-advisor-picker/business-advisor-picker.component';
import { CustomerData } from '../../customer/customer.model';
import { EntityExData } from 'common';

@Component({
  selector: 'ifb-entity-profile',
  templateUrl: './entity-profile.component.html',
  styleUrls: ['./entity-profile.component.scss'],
  animations: routerTransition
})
export class EntityProfileComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  data: EntityProfileComponentData;
  saleforceViewMode: boolean;
  canEditEntityInfo: boolean;
  canReadEntityInfo: boolean;
  canEditEntitySensitive: boolean;
  canViewAdvisor: boolean;
  canEditAdvisor: boolean;

  constructor(
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService,
    private entityService: EntityService,
    private dialog: MatDialog) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: EntityProfileComponentData) => this.data = it);

    this.userPermissionService.granted([readWrite('servicing-entities')])
      .subscribe(res => this.canEditEntityInfo = res);

    this.userPermissionService.granted([readOnly('servicing-entities')])
      .subscribe(res => this.canReadEntityInfo = res);

    this.userPermissionService.granted([readWrite('servicing-entities-sensitive-data')])
      .subscribe(res => this.canEditEntitySensitive = res);

    this.userPermissionService.granted([readOnly('servicing-advisors-assignment')])
      .subscribe(res => this.canViewAdvisor = res);

    this.userPermissionService.granted([readWrite('servicing-advisors-assignment')])
      .subscribe(res => this.canEditAdvisor = res);
  }

  changeAdvisor() {
    BusinessAdvisorPickerComponent.show(this.dialog)
      .subscribe(res => {
        if (res) {
          if (!res.id)
            return;

          this.entityService.updateEntityAdvisor(this.data.entity.id, res.id)
            .subscribe(() => {
              this.entityService.get(this.data.entity.id).subscribe(entity => {
                this.data.entity = entity;
              });
            });
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}

export interface EntityProfileComponentData {
  entity: EntityExData;
  owners?: CustomerData[];
}
