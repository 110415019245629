import { Component, Input } from '@angular/core';
import { AccountAppBarExpandedComponentData } from '../account-app-bar-expanded/account-app-bar-expanded.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ifb-account-profile-company-info-widget',
  templateUrl: './account-profile-company-info-widget.component.html',
  styleUrls: ['./account-profile-company-info-widget.component.css']
})
export class AccountProfileCompanyInfoWidgetComponent {

  @Input()
  data: AccountAppBarExpandedComponentData;

  constructor(private router: Router) { }

  openCompanyDetails() {
    this.router.navigate([`/company/${this.data.account.entityId}/profile`]);
  }

}
