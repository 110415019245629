import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientData } from '../../../auth/client/client.data';
import { UserRoleFilters, UserRoleQueryParams } from '../user-role.data';

@Component({
  selector: 'ifb-user-role-list-filter-dialog',
  templateUrl: './user-role-list-filter-dialog.component.html',
  styleUrls: ['./user-role-list-filter-dialog.component.css']
})
export class UserRoleListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: UserRoleFilters;

  constructor(public dialogRef: MatDialogRef<UserRoleListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new UserRoleFilters(data.query);
    this.form = this.formBuilder.group({
      clientId: this.data.query.clientId || '',
      name: this.data.query.name || '',
      externalId: this.data.query.externalId || '',
      active: this.data.query.active || undefined
    });
  }

  static show(dialog: MatDialog, query: UserRoleQueryParams, clients: ClientData[]): Observable<any> {
    return dialog
      .open(UserRoleListFilterDialogComponent, {
        data: { query, clients },
        closeOnNavigation: true,
      })
      .afterClosed();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new UserRoleFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
