import { UntypedFormBuilder, Validators } from "@angular/forms";

export const getCompetitorFormModel = (formBuilder: UntypedFormBuilder) => {
  return {
    name: [undefined, Validators.required],
    description: undefined,
    apiName: [undefined, Validators.maxLength(255)],
    address: formBuilder.group({
      line1: undefined,
      line2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
    }),
    active: false
  };
}