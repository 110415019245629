import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBarComponent, AppBarTitleComponent, AppPageComponent, AppSidenavComponent } from 'common';
import { appPageComponentDataMaster } from '../app.data';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { HomeComponent } from './home.component';
import { BOAuthGuard } from '../guards/oauth.guard';
import { SignOutComponent } from './sign-out/sign-out.component';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Home',
  sideNavItem: 'home'
});

const routes: Routes = [
  {
    path: 'home',
    component: AppPageComponent,
    data: appPageDataMaster,
    canActivate: [BOAuthGuard],
    resolve: {
      appPage: AppPageResolver,
      appSidenav: AppSidenavResolver
    },
    children: [
      {
        path: '',
        outlet: 'sidenav-content',
        component: AppSidenavComponent
      },
      {
        path: '',
        component: AppBarComponent,
        outlet: 'app-bar',
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: UserMenuComponent,
          }
        ]
      },
      {
        path: '',
        component: HomeComponent,
      }
    ]
  },
  {
    path: 'sign-out',
    component: SignOutComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
