import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { CustomerData } from '../../customer/customer.model';

@Component({
  selector: 'ifb-owners-widget',
  templateUrl: './owners-widget.component.html',
  styleUrls: ['./owners-widget.component.scss']
})
export class OwnersWidgetComponent {

  @Input()
  owners: CustomerData[];

  @Input()
  saleforceViewMode: boolean;

  constructor(private router: Router, private settings: AppSettings) { }

  customerDetailsLink(customer, newWindow: boolean = false) {
    const path = this.customerDetailsUri(customer);
    if (newWindow)
      window.open(path);
    else
      this.router.navigate([path]);
  }

  customerDetailsUri(customer) {
    if (this.saleforceViewMode) {
      return `/customer/sf/${customer.uniqueId}/profile`;
    }
    return `/customer/${customer.id}/profile`;
  }

  salesforceLink(salesforceRecordId: string) {
    return this.settings.salesforce.url + salesforceRecordId;
  }

  kbaStatus = {
    'none': 'Pending',
    'success': 'Verified',
    'failed': 'Failed',
  };
}
