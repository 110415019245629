import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UpdateFormFieldType } from "projects/backoffice/src/app/shared/const/enums/update-form-field-type.enum";
import { UpdateFormFieldData } from "../const/models/update-form-field-data.model";

@Component({
  selector: "ifb-update-dialog",
  templateUrl: "./update-dialog.component.html",
  styleUrls: ["./update-dialog.component.scss"],
})
export class UpdateDialogComponent {

  actionGerund: string;
  objectName: string;

  get FieldType() {
    return UpdateFormFieldType;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.actionGerund =
      this.data.action.substring(0, this.data.action.length - 1) + "ing";
    
    this.objectName = this.data.objectName;
  }

  static show(
    dialog: MatDialog,
    updatedFieldsData: UpdateFormFieldData[],
    action: string,
    objectName: string
  ): Observable<any> {
    return dialog
      .open(UpdateDialogComponent, {
        width: "510px",
        data: {
          updatedFieldsData: updatedFieldsData,
          action,
          objectName: objectName
        },
        closeOnNavigation: true,
      })
      .afterClosed();
  }
}

