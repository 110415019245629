import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientData } from '../client.data';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppBarTitleService, AppBarActionsService, AppBarAction, DialogConfirm, FormHelper, MessageService, AppPageService } from 'common';
import { ClientService } from '../client.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientUpdateDialogComponent } from '../client-update-dialog/client-update-dialog.component';

@Component({
  selector: 'ifb-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private saveSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private clientService: ClientService,
    private appBarTitleService: AppBarTitleService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private dialog: MatDialog,
    private messageService: MessageService,) {
    this.formInit();
  }

  ngOnInit() {
    this.appBarActionsService.actions = [
      { id: 'delete', label: 'Delete', buttonType: 'button', disabled: true },
      { id: 'cancel', label: 'Cancel', buttonType: 'button', disabled: false },
      { id: 'save', label: 'Save', buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary', disabled: false }
    ];
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this)),
      this.appBarActionsService.invoking.subscribe(this.actionDispatch.bind(this)),
      this.form.statusChanges.subscribe(this.actionUpdate.bind(this))
    ];
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.enable('delete', this.data && this.data.client && this.data.client.id && !this.deleteSub);
  }

  cancel() {
    this.appPageService.back();
  }

  data: ClientDetailsComponentData;

  dataSet(data: ClientDetailsComponentData) {
    this.data = data;
    if (data.client)
      this.form.patchValue(data.client);

    this.appBarTitleService.title = data.client && data.client.id
      ? `Client ${data.client.name}`
      : 'Add new client';

    this.form.markAsPristine();

    this.actionUpdate();
  }

  delete() {
    if (!this.data || !this.data.client || !this.data.client.id)
      this.appPageService.back();

    DialogConfirm.show(this.dialog, `Delete client ${this.form.value.name}?`).subscribe(result => {
      if (result)
        this.deleteSub = this.clientService.delete(this.data.client.id).subscribe(() => {
          this.appPageService.back();
        });
      this.actionUpdate();
    });
  }

  deleteSub: Subscription;

  form: UntypedFormGroup;

  private formInit() {
    this.form = this.formBuilder.group({
      id: undefined,
      name: [undefined, Validators.compose([Validators.required, Validators.maxLength(64)])],
      oidcAudience: [undefined, Validators.compose([Validators.maxLength(64)])],
      oidcLoginAllowed: [undefined, Validators.required],
      active: [undefined, Validators.required]
    });
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    if (!this.form.dirty) {
      this.cancel();
      return;
    }

    if (this.data?.client?.id) {
      ClientUpdateDialogComponent.show(this.dialog, this.form, "Update").subscribe(res => {
        if (res) {
          this.saveSub = this.clientService.update(this.form.value)
            .subscribe({
              next: this.saveSuccessHandler.bind(this),
              error: this.saveErrorHandler.bind(this)
            });
          this.actionUpdate();
          this.appPageService.back();
        }
      });
    } else {
      ClientUpdateDialogComponent.show(this.dialog, this.form, "Create").subscribe(res => {
        if (res) {
          this.saveSub = this.clientService.create(this.form.value)
          .subscribe({
            next: this.saveSuccessHandler.bind(this),
            error: this.saveErrorHandler.bind(this)
          });
        }
        this.actionUpdate();
      });
    }
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler(client: ClientData) {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;

    if (this.data.client) {
      this.data.client = this.form.value;
      this.dataSet(this.data);
      this.actionUpdate();
    } else {
      this.router.navigate(['admin', 'client', client.id], { replaceUrl: true });
    }
    this.saveSubClear();
    this.appPageService.back();
  }
}

export interface ClientDetailsComponentData {
  client: ClientData;
}
