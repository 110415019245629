<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button mat-icon-button (click)="download(queryParams)" class="secondary-text" matTooltip="Download">
          <mat-icon>get_app</mat-icon>
        </button>
        <button *ngIf="readWriteBroker" mat-icon-button routerLink="/broker/new" class="secondary-text"
          matTooltip="Add">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button mat-menu-item (click)="download(queryParams)">
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
          </button>
          <button mat-menu-item routerLink="/broker/new">
            <mat-icon>add_circle</mat-icon>
            <span>Add</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">

      <div class="message-box info" *ngIf="!data.brokerQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>

      <table *ngIf="data.brokerQueryResult.totalCount" mat-table matSort [dataSource]="data.brokerQueryResult.values"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Legal entity name </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="brokerId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="brokerId"> Broker ID </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.brokerId}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell">
            {{formatType(row.type)}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell">
            {{formatStatus(row.status)}} </td>
        </ng-container>

        <ng-container matColumnDef="tier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="tier"> Tier </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell">
            {{formatTier(row.tier)}} </td>
        </ng-container>

        <ng-container matColumnDef="usersCount">
          <th mat-header-cell *matHeaderCellDef> Users </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.usersCount}} </td>
        </ng-container>

        <ng-container matColumnDef="applicationsCount">
          <th mat-header-cell *matHeaderCellDef> Applications </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.applicationsCount}} </td>
        </ng-container>

        <ng-container matColumnDef="contactEmail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="contactEmail"> Contact email </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.contactEmail}}
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="phone"> Phone </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="advisorName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="advisorName"> Business advisor </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.advisorName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="commission.initialDrawCommission">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="commission.initialDrawCommission"> Initial commission
          </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell">
            {{row.commission !== null ? row.initialDrawCommission : null}} </td>
        </ng-container>

        <ng-container matColumnDef="commission.residualDrawCommission">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="commission.residualDrawCommission"> Residual commission
          </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell">
            {{row.commission !== null ? row.residualDrawCommission : null}} </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy"> Created by </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.createdBy}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Created on </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell">
            {{row.createdOn | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="updatedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedBy"> Updated by </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell"> {{row.updatedBy}}
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedOn"> Updated on </th>
          <td mat-cell *matCellDef="let row" (click)="brokerDetailsLink(row)" class="table-cell">
            {{row.updatedOn | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="uniqueId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="uniqueId"> Salesforce ID </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <a *ngIf="row.uniqueId" href="{{salesforceLink(row.uniqueId)}}" target="_blank"> {{row.uniqueId}} </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th nowrap mat-header-cell *matHeaderCellDef></th>
          <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let row" class="action-icon"
            ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
            <button mat-icon-button (click)="brokerDetailsLink(row, true)">
              <mat-icon>open_in_new</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.brokerQueryResult.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.brokerQueryResult.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>