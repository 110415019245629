import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  CustomerQueryParams,
  CustomerQueryResult,
  CustomerData,
  CustomerHintData,
  CustomerHintParams,
  LoanDetails,
  KBAStatus
} from './customer.model';
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { ResourceService } from 'common';
import { AppSettings } from "../../app.settings";

@Injectable(
  { providedIn: 'root' }
)
export class CustomerService {

  private readonly baseUrl = `${this.settings.customers.url}/api/customer`;

  prevCustomerListUrl: string;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private settings: AppSettings) { }

  get(id: number): Observable<CustomerData> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<CustomerData>(url);
  }

  getByUniqueId(id: number): Observable<CustomerData> {
    return this.http.get<CustomerData>(`${this.baseUrl}/unique/${id}`);
  }

  update(id: number, customer: CustomerData): Observable<CustomerData> {
    return this.http.post<CustomerData>(`${this.baseUrl}/${id}`, customer);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  getLoansById(id: number): Observable<LoanDetails[]> {
    const url = `${this.settings.loans.url}/api/account?customerId=${id}`;
    return this.http.get<LoanDetails[]>(url);
  }

  getAll(): Observable<CustomerData[]> {
    const customerQueryParams: CustomerQueryParams = {};
    if (!customerQueryParams.sort)
      customerQueryParams.sort = '+firstName';

    return this.http.get<CustomerQueryResult>(`${this.baseUrl}`, { params: <any>customerQueryParams }).pipe(
      map(result => result.values)
    );
  }

  query(customerQueryParams: CustomerQueryParams): Observable<CustomerQueryResult> {
    if (!customerQueryParams.sort)
      customerQueryParams.sort = '+firstName';    
    return this.http.get<CustomerQueryResult>(`${this.baseUrl}`, { params: <any>customerQueryParams });
  }

  download(params: CustomerQueryParams) {
    this.resourceService.download(`${this.baseUrl}/csv`, "customers.csv", params);
  }

  queryHints(query: CustomerHintParams): Observable<CustomerHintData[]> {
    return this.http.get<CustomerHintData[]>(`${this.baseUrl}/hints`, {
      params: <any>query
    });
  }

  removeLoanById(customerId: number, loanId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${customerId}/loans/${loanId}`);
  }

  assignLoans(customerId: number, loanIds: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/${customerId}/loans/assignment`, { loanList: loanIds });
  }

  assignPendingLoans(customerId: number, loanIds: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/${customerId}/pending-loans/assignment`, { loanList: loanIds });
  }

  resendInvitation(customerId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/${customerId}/resend-invitation`, null);
  }

  overrideKBA(customerId: number, status: KBAStatus): Observable<any> {
    return this.http.post(`${this.baseUrl}/${customerId}/override-kba`, { verificationStatus: status });
  }
}
