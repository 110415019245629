import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { AuditTrailComponent } from './audit-trail-list/audit-trail.component';
import { AuditTrailRoutingModule } from './audit-trail-routing.module';
import { SharedModule } from '../shared.module';
import { AuditTrailDetailComponent } from './audit-trail-detail/audit-trail-detail.component';
import { AuditTrailListFilterDialogComponent } from './audit-trail-list-filter-dialog/audit-trail-list-filter-dialog.component';
import { AuditTrialEventInputComponent } from './audit-trial-event-input/audit-trial-event-input.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AuditTrailRoutingModule
    ],
    exports: [
        AuditTrailComponent,
        AuditTrailListFilterDialogComponent
    ],
    declarations: [
        AuditTrailComponent,
        AuditTrailDetailComponent,
        AuditTrailListFilterDialogComponent,
        AuditTrialEventInputComponent
    ]
})
export class AuditTrailModule { }
