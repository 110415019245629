import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdvisorFilters } from '../business-advisor.model';

@Component({
  selector: 'ifb-business-advisor-picker',
  templateUrl: './business-advisor-picker.component.html',
  styleUrls: ['./business-advisor-picker.component.scss']
})
export class BusinessAdvisorPickerComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: AdvisorFilters;

  constructor(
    public dialogRef: MatDialogRef<BusinessAdvisorPickerComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this._unsubscribeAll = new Subject();
    this.filters = new AdvisorFilters();

    this.form = this.formBuilder.group({
      fullName: '',
    });
  }

  static show(dialog: MatDialog): Observable<any> {
    return dialog
      .open(BusinessAdvisorPickerComponent, {
        data: {},
        closeOnNavigation: true,
      })
      .afterClosed();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new AdvisorFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
    this._unsubscribeAll.next();
  }
}
