import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FailureReasonUsage, FailureReasonBaseData } from '../../admin/failure-reason/failure-reason.model';
import { Subscription } from 'rxjs';
import { FailureReasonService } from '../../admin/failure-reason/failure-reason.service';

@Component({
  selector: 'ifb-transaction-reject-input',
  templateUrl: './transaction-reject-input.component.html',
  styleUrls: ['./transaction-reject-input.component.css']
})
export class TransactionRejectInputComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  @Input()
  usage: FailureReasonUsage;

  @Input()
  fc: UntypedFormControl;

  @Input()
  disabled: boolean;

  constructor(private _service: FailureReasonService) {}

  get placeholder() { return this.usage == FailureReasonUsage.PendingReason ? 'Pending reason' : 'Reject or decline reason' };
  get required() { return this.usage != FailureReasonUsage.PendingReason };

  failureReasonOptions: FailureReasonBaseData[];

  ngOnInit() {
    this._subscriptions = [
      this._service.list().subscribe(data => {
        this.failureReasonOptions = [];

        data.forEach(r => {
          if (r.usage != null && r.usage.includes(this.usage))
            this.failureReasonOptions.push(r);
        });
      })
    ];
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(it => it.unsubscribe());
  }
}
