<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         class="table-top">
      <ifc-filters-active-list class="layout margin-sm"
                               [filters]="activeFilters"
                               (filterRemoved)="activeFilterRemoved($event)">
      </ifc-filters-active-list>
      <div fxHide.xs
           class="layout padding-right-md">
        <button mat-icon-button
                (click)="openFilterDialog()"
                class="secondary-text"
                matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button *ngIf="readWritePromotions"
                routerLink="/admin/promotions/new"
                mat-icon-button
                class="secondary-text"
                matTooltip="Add">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button mat-icon-button
                class="secondary-text"
                matTooltip="Download"
                (click)="downloadPromotions()">
          <mat-icon>get_app</mat-icon>
        </button>
      </div>
      <div fxHide
           fxShow.xs>
        <button mat-icon-button
                [matMenuTriggerFor]="actionsMenu"
                class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item
                  (click)="openFilterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button mat-menu-item
                  routerLink="/admin/promotions/new">
            <mat-icon>add_circle</mat-icon>
            <span>Add</span>
          </button>
          <button mat-menu-item
                  (click)="downloadPromotions()">
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info"
           *ngIf="!data.promotionListResult?.totalCount"
           fxLayout="row"
           fxLayoutGap="12px"
           fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table class="table"
             *ngIf="data.promotionListResult?.totalCount"
             mat-table
             matSort
             [dataSource]="data.promotionListResult.values"
             (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="name"> Name </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell"> {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="active"> Status </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell">
            {{row.active ? 'Active' : 'Inactive'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="createdOn"> Created on </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell">
            {{row.createdOn | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="productType">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="productType"> Product type </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell">
            {{row.productName}} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="startDate"> Start date </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell">
            {{row.startDate | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="endDate"> End date </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell">
            {{row.endDate | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="transactionType">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="transactionType"> Transaction type </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell">
            {{row.transactionType | transactionTypeName}} </td>
        </ng-container>

        <ng-container matColumnDef="promotionType">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="promotionType"> Promotion type </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/admin/promotions/{{row.id}}"
              class="table-cell">
            {{row.promotionType | promotionTypeName}} </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="tableColumns"
            class="table-header"></tr>
        <tr mat-row
            *matRowDef="let row; columns: tableColumns;"
            class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.promotionListResult?.totalCount <= 10">
    <mat-paginator class="paginator"
                   #paginator
                   showFirstLastButtons
                   [length]="data.promotionListResult.totalCount"
                   [pageSizeOptions]="[10, 20, 50]"
                   (page)="setPage()">
    </mat-paginator>
  </div>

</div>
