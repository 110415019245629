import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ApplicationDocumentService, LoanDocumentService, DocumentQueryResult, parseNumber } from "common";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { SignedLoanDocumentFile } from "projects/common/src/lib/documents/signed-loan-document-file.model";

type resolvedQueryTypes = Observable<DocumentQueryResult> | Promise<DocumentQueryResult> | DocumentQueryResult;

@Injectable({
    providedIn: 'root'
})
export class ApplicationDocumentByApplicationQueryResolver implements Resolve<DocumentQueryResult> {

    constructor(private documentService: ApplicationDocumentService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot)
        : resolvedQueryTypes {
        return this.documentService.query(parseNumber(route.params.id), this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationDocumentByUniqueIdQueryResolver implements Resolve<DocumentQueryResult> {

    constructor(private documentService: ApplicationDocumentService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot)
        : resolvedQueryTypes {
        return this.documentService.queryByUniqueId(route.params.uniqueId, this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountDocumentByLoanQueryResolver implements Resolve<DocumentQueryResult> {

    constructor(private documentService: LoanDocumentService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot)
        : resolvedQueryTypes {
        return this.documentService.query(parseNumber(route.params.id), this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class AccountDocumentByUniqueIdQueryResolver implements Resolve<DocumentQueryResult> {

    constructor(private documentService: LoanDocumentService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot)
        : resolvedQueryTypes {
        return this.documentService.queryByUniqueId(route.params.uniqueId, this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class DocumentSignaturesResolver implements Resolve<SignedLoanDocumentFile> {

    constructor(private documentService: LoanDocumentService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SignedLoanDocumentFile> {
        return this.documentService.getSignatures(parseNumber(route.params.documentId));
    }

}

