import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { OfferListComponent } from "./components/offer-list/offer-list.component";
import { OfferDetailsComponent } from "./components/offer-tabs/components/offer-details/offer-details.component";
import { OfferTabsComponent } from "./components/offer-tabs/offer-tabs.component";
import { OfferEconomicsComponent } from './components/offer-tabs/components/offer-economics/offer-economics.component';
import { OfferFeesComponent } from './components/offer-tabs/components/offer-fees/offer-fees.component';
import { OfferDetailsWidgetComponent } from './components/offer-tabs/components/offer-details/components/offer-details-widget/offer-details-widget.component';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule],
  declarations: [OfferListComponent, OfferDetailsComponent, OfferTabsComponent, OfferEconomicsComponent, OfferFeesComponent, OfferDetailsWidgetComponent],
})
export class OfferModule {}
