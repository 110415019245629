import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Sort, MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { FilterParameter } from 'common';
import { AuditTrailComponentData, AuditTrailData, AuditFilters, AuditQueryParams } from '../audit-trail.model';
import { AuditTrailFilterService } from '../audit-trail-filter.services';
import { AuditTrailListFilterDialogComponent } from '../audit-trail-list-filter-dialog/audit-trail-list-filter-dialog.component';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  @Input()
  detailTabbed: boolean;

  @Input()
  innerTabbed: boolean;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  queryParams: AuditQueryParams;
  data: AuditTrailComponentData;
  filters: AuditFilters;
  activeFilters: FilterParameter[] = [];

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public _auditTrailFilterService: AuditTrailFilterService,
    public queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: AuditTrailComponentData) => {
        this.data = it;
      });

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(params => {
        this.queryParams = new AuditQueryParams(this.queryParamsService.init(params));
        this.activeFilters = this._auditTrailFilterService.addFilterParameters(this.queryParams);
      });

    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  filterDialog() {
    AuditTrailListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this._auditTrailFilterService.filter(result, this.paginator, this.sort);
        }
      });
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this._auditTrailFilterService.filter(this.filters, this.paginator, this.sort);
    });
  }

  sortData(sort: Sort) {
    this._auditTrailFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this._auditTrailFilterService.filter(this.filters, this.paginator, this.sort);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  tableColumns: string[] = [
    'date',
    'event',
    'initiator',
    'edit'
  ];

  getFormattedEvent(e: string): string {
    return _.startCase(e);
  }

  auditDetailsLink(audit: AuditTrailData) {
    return `/audit/${audit.id}}`;
  }
}



