<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="column" fxLayoutAlign="center center" class="header layout padding-md">
    <div class="card-title">
      {{data?.application?.entityName}}
    </div>
  </div>

  <mat-card-content>
    <mat-list>

      <mat-list-item>
        <mat-icon mat-list-icon>business</mat-icon>
        <h4 mat-line>
          <a (click)="openCompanyDetails()">{{data?.application?.entityName || ''}}
            <br /> {{data?.application?.entityNumber || ''}}
          </a>
        </h4>
        <p mat-line>Company</p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>work</mat-icon>
        <h4 mat-line>{{data?.application?.entityDBA || ''}}</h4>
        <p mat-line>DBA</p>
      </mat-list-item>

      <mat-list-item class="business-address">
        <mat-icon mat-list-icon>location_on</mat-icon>
        <h4 mat-line>{{data?.application?.entityAddress?.line1 || ''}}</h4>
        <p mat-line>{{data?.application?.entityAddress?.city}},
          {{data?.application?.entityAddress?.state}}
          {{data?.application?.entityAddress?.zip}}</p>
        <p mat-line>{{data?.application?.entityAddress?.line2}}</p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>receipt</mat-icon>
        <h4 mat-line>{{data.application?.entityEIN}}</h4>
        <p mat-line>Tax ID</p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.application?.entityPhone || ''}}</h4>
        <p mat-line>Primary phone</p>
      </mat-list-item>

    </mat-list>
  </mat-card-content>
</mat-card>