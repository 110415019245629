import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AppBarActionsService } from "../../../../ui/app/app-bar-actions/app-bar-actions.service";

@Injectable()
export class BrokerApiKeyDetailsFacade {
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly appBarActionsService: AppBarActionsService
  ) {}

  initForm() {
    return this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(64)]],
      description: ["", [Validators.maxLength(65536)]],
      active: ["", Validators.required],
    });
  }

  initAppBarActions() {
    this.appBarActionsService.actions = [
      { id: "delete", label: "Delete", disabled: true, buttonType: "button" },
      { id: "cancel", label: "Cancel", buttonType: "button" },
      {
        id: "save",
        label: "Save",
        buttonType: "submit",
        buttonAppearance: "flat",
        buttonColor: "primary",
        disabled: true,
      },
    ];
  }
}
