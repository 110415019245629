import { Component } from '@angular/core';
import { routerTransition } from '../../shared/animations/router.animations';

@Component({
  selector: 'ifb-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss'],
  animations: routerTransition
})
export class SalesDashboardComponent {

  constructor() { }

}
