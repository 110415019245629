import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductData } from './product.model';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private readonly baseUrl = `${this.settings.products.url}/api/product`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings
  ) { }

  getActiveStorage(): ProductData[] {
    return sessionStorage.getItem('products') ? JSON.parse(sessionStorage.getItem('products')) : null;
  }

  setActiveStorage(data): void {
    sessionStorage.setItem('products', JSON.stringify(data));
  }

  getActive(): Observable<ProductData[]> {
    return this.http.get<ProductData[]>(`${this.baseUrl}/active`);
  }

  get(id: string): Observable<ProductData> {
    return this.http.get<ProductData>(`${this.baseUrl}/${id}`);
  }

}

