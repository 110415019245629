import { Component, Input } from '@angular/core';
import { DomHelper, ApplicationStage, ApplicationStageLabel } from 'common';
import { ApplicationProfileComponentData } from '../application-profile/application-profile.component';

@Component({
  selector: 'ifb-application-status-widget',
  templateUrl: './application-status-widget.component.html',
  styleUrls: ['./application-status-widget.component.scss']
})
export class ApplicationStatusWidgetComponent {

  @Input()
  data: ApplicationProfileComponentData;

  constructor() { }

  formatStage(stage: ApplicationStage): string {
    return ApplicationStageLabel.get(stage);
  }

  copy(value: string) {
    DomHelper.copy(value);
  }

}
