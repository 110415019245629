import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { routerTransition } from "projects/backoffice/src/app/shared/animations/router.animations";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OfferBundleCheckoutRequirementListData } from "./models/offer-bundle-checkout-requirement-list-data.model";

@Component({
  selector: "ifb-offer-bundle-checkout-requirement-list",
  templateUrl: "./offer-bundle-checkout-requirement-list.component.html",
  styleUrls: ["./offer-bundle-checkout-requirement-list.component.scss"],
  animations: routerTransition,
})
export class OfferBundleCheckoutRequirementListComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<unknown>;

  tableColumns: string[] = [
    "name",
    "status",
    "requiredDocument",
    "isCreditModel",
    "isManual",
    "isFileUploaded",
    "uniqueId",
  ];

  constructor(
    private route: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();
  }

  data: OfferBundleCheckoutRequirementListData;

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: OfferBundleCheckoutRequirementListData) => {
        this.data = data;
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
