import { Component, OnInit, Input } from '@angular/core';
import { formatPercent } from '@angular/common';

@Component({
  selector: 'ifc-chart-circular-percentage',
  templateUrl: './chart-circular-percentage.component.html',
  styleUrls: ['./chart-circular-percentage.component.scss']
})
export class ChartCircularPercentageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.label = this.label || formatPercent(this.faktor, 'en_US');
  }

  @Input()
  caption1: string;

  @Input()
  caption2: string;

  @Input()
  faktor = 0;

  @Input()
  label: string;

  get percentage() {
    return this.faktor * 100;
  }
}
