import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountData } from '../account.model';
import { AccountService } from '../account.service';

@Component({
  selector: 'ifb-account-app-bar-title',
  templateUrl: './account-app-bar-title.component.html',
  styleUrls: ['./account-app-bar-title.component.scss']
})
export class AccountAppBarTitleComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  title: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService) { }

  accountListNavigate() {
    this.router.navigateByUrl(this.accountService.prevAccountListUrl || '/account');
  }

  data: AccountAppBarTitleComponentData;

  dataSet(data: AccountAppBarTitleComponentData) {
    this.data = data;
    this.title = data.account ? `${data.account.loanNumber} - ${data.account.entityName}; ${data.account.entityNumber}` : '';
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this))
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

}

export interface AccountAppBarTitleComponentData {
  account: AccountData;
}
