import { Validators } from "@angular/forms";
import { AppBarAction, MatErrorData } from "common";
import { AccountService } from "projects/backoffice/src/app/account/account.service";
import { CustomAsyncValidators } from "projects/backoffice/src/app/shared/validators/custom-async-validators";
import { CustomErrorCode } from "projects/backoffice/src/app/shared/validators/custom-error-code.enum";

export const getRenewalsFormModel = (accountService: AccountService) => ({
  renewalForLoanNumber: [
    undefined,
    Validators.required,
    CustomAsyncValidators.checkLoanNumber(accountService),
  ],
  renewalReady: [undefined, Validators.required],
});

export const getActionsConfig: AppBarAction[] = [
  { id: "cancel", label: "Cancel", buttonType: "button" },
  {
    id: "save",
    label: "Save",
    buttonType: "submit",
    buttonAppearance: "flat",
    buttonColor: "primary",
    disabled: false,
  },
];

export const getFormErrorData: MatErrorData[] = [
  {
    errorCode: CustomErrorCode.InvalidLoanNumber,
    message: "Please enter a valid loan number",
  },
];
