import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdNameData, ConfigService } from 'common';
import { AppSettings } from '../../../app.settings';
import { Tenant } from './tenant.model';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private readonly cacheKey: string = 'ideafinancial.bo.tenants';

  private readonly baseUrl = `${this.settings.companies.url}/api/companies`;

  constructor(private http: HttpClient, private settings: AppSettings, private configService: ConfigService) { }

  get(id: number): Observable<Tenant> {
    return this.http.get<Tenant>(`${this.baseUrl}/${id}`, {
      headers: this.configService.environment.cache.longLived
    });
  }

  list(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${this.baseUrl}`, {
      headers: this.configService.environment.cache.longLived
    });
  }

  names(): Observable<IdNameData[]> {
    return this.http.get<IdNameData[]>(`${this.baseUrl}/names`, {
      headers: this.configService.environment.cache.longLived
    });
  }

  update(tenant: Tenant): Observable<Tenant> {
    return this.http.put<Tenant>(`${this.baseUrl}/${tenant.id}`, tenant);
  }
}
