<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <ifc-filter-param-date-range-input [fc]="form.get('timestamp')" [label]="'Date'"
        (rangeSelected)="dateSelected($event)">
      </ifc-filter-param-date-range-input>

      <mat-form-field appearance="outline">
        <mat-label>Level</mat-label>
        <mat-select matInput formControlName="level" placeholder="Level">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of data.options.levelOptions" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('timestamp').value === 'custom'">
      <ifc-datepicker [fc]="form.get('timestampStart')" [label]="'Date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('timestampEnd')" [label]="'Date to'"></ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Endpoint</mat-label>
        <mat-select matInput formControlName="endpoint" placeholder="Endpoint">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of data.options.endpointOptions" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Hostname</mat-label>
        <mat-select matInput formControlName="hostName" placeholder="Hostname">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of data.options.hostnameOptions" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" ngClass.gt-xs="width-100-perc">
      <mat-form-field appearance="outline">
        <mat-label>Logger</mat-label>
        <mat-select matInput formControlName="logger" placeholder="Logger">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of data.options.loggerOptions" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Correlation ID</mat-label>
        <input matInput name="correlationId" formControlName="correlationId" type="text" placeholder="Correlation ID">
        <button type="button" *ngIf="form.controls.correlationId.value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.controls.correlationId.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>ID</mat-label>
        <input matInput name="id" formControlName="id" type="text" placeholder="ID">
        <button type="button" *ngIf="form.controls.id.value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.controls.id.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Principal name</mat-label>
        <input matInput name="principalName" formControlName="principalName" type="text" placeholder="Principal name">
        <button type="button" *ngIf="form.controls.principalName.value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.controls.principalName.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Principal ID</mat-label>
        <input matInput name="principalId" formControlName="principalId" type="text" placeholder="Principal ID">
        <button type="button" *ngIf="form.controls.principalId.value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.controls.principalId.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <ifc-account-loan-number-input [fc]="form.get('loan')" class="mat-form-field"></ifc-account-loan-number-input>

      <ifb-business-name-input [fc]="form.get('entity')" class="mat-form-field"></ifb-business-name-input>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <ifb-customer-email-input [label]="customerInputLabel" [fc]="form.get('customer')" class="mat-form-field"></ifb-customer-email-input>

      <mat-form-field appearance="outline">
        <mat-label>Error ID</mat-label>
        <input matInput name="errorId" formControlName="errorId" type="text" placeholder="Error ID">
        <button type="button" *ngIf="form.controls.errorId.value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.controls.errorId.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
            <mat-option>All</mat-option>
            <mat-option value="true">Active</mat-option>
            <mat-option value="false">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>