import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigService, ApplicationBaseService, ApplicationQueryParams, ApplicationQueryResult } from 'common';
import { CustomerData } from '../customer/customer.model';
import { ApplicationData, ApplicationHintData, ApplicationHintParams, ApplicationRenewalRequestData } from './application.model';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends ApplicationBaseService<ApplicationData> {
    prevApplicationListUrl: string;

    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService)
    }

    query(params: ApplicationQueryParams): Observable<ApplicationQueryResult> {
        if (!params.sort)
            params.sort = '-createdOn';

        return super.query(params);
    }

    addNote(id: number, note: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/${id}/notes`, { note: note });
    }

    getOwnersList(id: number): Observable<CustomerData[]> {
        return this.http.get<CustomerData[]>(`${this.baseUrl}/${id}/owners`);
    }

    updateAdvisor(id: number, advisorId: number) {
        return this.http.put(`${this.baseUrl}/${id}/advisor`, {advisorId : advisorId});
    }

    queryHints(query: ApplicationHintParams): Observable<ApplicationHintData[]> {
        return this.http.get<ApplicationHintData[]>(`${this.baseUrl}/hints`, {
            params: <any>query
        });
    }

    updateRenewal(applicationId: number, request: ApplicationRenewalRequestData): Observable<ApplicationData> {
      return this.http.patch<ApplicationData>(`${this.baseUrl}/${applicationId}/renewal`, request);
  }
}
