import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountAppBarExpandedComponentData } from '../account-app-bar-expanded/account-app-bar-expanded.component';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AccountStateWidgetData } from '../account.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite, readOnly } from '../../user/user-permission/user-permission.data';
import { routerTransition } from '../../shared/animations/router.animations';

@Component({
  selector: 'ifb-account-economics',
  templateUrl: './account-economics.component.html',
  styleUrls: ['./account-economics.component.scss'],
  animations: routerTransition
})

export class AccountEconomicsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  accountStateWidgetData: AccountStateWidgetData = {};
  canEditBalances: boolean;
  canEditOffer: boolean;
  canReadOffer: boolean;
  data: AccountAppBarExpandedComponentData;

  constructor(private route: ActivatedRoute, private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));

    this.userPermissionService.granted([readWrite('servicing-account-states')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canEditBalances = res);

    this.userPermissionService.granted([readWrite('servicing-account-offer')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canEditOffer = res);

    this.userPermissionService.granted([readOnly('servicing-account-offer')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canReadOffer = res);
  }

  dataSet(data: AccountAppBarExpandedComponentData) {
    this.data = data;
    this.accountStateWidgetData = {
      stateData: data.account.maintenanceState, // TODO: edit when other balances are implemented.
      availableFunds: data.account.maintenanceState?.availableBalance,
      lastMaintenanceDate: data.account.lastMaintenanceDate,
      utilization: data.account.maintenanceState?.utilization
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
