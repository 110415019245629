<div fxLayout="row" fxLayoutAlign="start start" ngClass.sm="layout padding-left-md" ngClass.md="layout padding-left-md">
  <a href="#" (click)="$event.preventDefault(); back()">
    <span fxHide.xs>Brokers</span>
    <mat-icon fxHide.gt-xs>chevron_left</mat-icon>
  </a>
  <mat-icon fxHide.xs>chevron_right</mat-icon>
  <div>
    <span class="layout text-transform-uppercase" fxHide.xs>{{title}} </span>
    <span class="layout text-transform-uppercase" fxHide.gt-xs>{{title | truncate : 10}} </span>
  </div>
</div>