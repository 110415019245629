<ng-template #feeTemplate let-name="name" let-value="value" let-type="type">
  <div fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="center center"
        fxLayoutGap="24px">
    <mat-form-field appearance="outline"
                    ngClass.xs="form-field-xs"
                    ngClass.gt-xs="form-field-md">
      <mat-label>{{name}}</mat-label>
      <input matInput
              type="text"
              [value]="value | fee:type"
              disabled />
    </mat-form-field>
    <mat-form-field appearance="outline"
                    ngClass.xs="form-field-xs"
                    ngClass.gt-xs="form-field-md">
      <mat-label>{{name}} type</mat-label>
      <input matInput
              type="text"
              [value]="type | feeTypeLabel"
              disabled />
    </mat-form-field>
  </div>
</ng-template>

<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <div class="layout margin flex-std" [@routerTransition]="'fly'">
    <form class="fees-form">
      
      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Draw fee', value: data.offer.drawDownFee, type: data.offer.drawDownFeeType }"></ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Origination fee', value: data.offer.originationFee, type: data.offer.originationFeeType }"></ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'UCC filing fee', value: data.offer.uccFilingFee, type: data.offer.uccFilingFeeType }"></ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Servicing fee', value: data.offer.servicingFee, type: data.offer.servicingFeeType }"></ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Servicing fee minimum', value: data.offer.servicingFeeMinimum, type: data.offer.servicingFeeMinimumType }"></ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Stacking fee', value: data.offer.stackingFee, type: data.offer.stackingFeeType }"></ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Renewal fee', value: data.offer.renewalFee, type: data.offer.renewalFeeType }"></ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Maintenance fee', value: data.offer.maintenanceFee, type: data.offer.maintenanceFeeType }"></ng-container>

      <div fxLayout="row"
           fxLayout.lt-md="column">
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Maintenance fee frequency</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.maintenanceFeeFrequency | paymentFrequencyLabel"
                 disabled />
        </mat-form-field>
      </div>
    </form>
  </div>
</ifc-app-scroll-container>
