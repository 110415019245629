import { Injectable } from "@angular/core";
import { BrokerApiKeyListActionsInternalService } from "./broker-api-key-actions.internal.service";
import { Observable } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { BrokerApiKeyData, BrokerApiKeyEditData, CreateBrokerApiKeySuccessData } from "../broker-api-key.model";

@Injectable()
export class BrokerApiKeyListActionsService {
  constructor(
    private readonly internalService: BrokerApiKeyListActionsInternalService
  ) {}

  get filter$(): Observable<MatPaginator> {
    return this.internalService.filter$;
  }

  get delete$(): Observable<number> {
    return this.internalService.delete$;
  }

  get statusChanged$(): Observable<BrokerApiKeyData> {
    return this.internalService.statusChanged$;
  }

  get save$(): Observable<BrokerApiKeyEditData> {
    return this.internalService.save$;
  }

  get edit$(): Observable<number> {
    return this.internalService.edit$;
  }

  markCreateAsCompleted(data: CreateBrokerApiKeySuccessData): void {
    this.internalService.markCreateAsCompleted(data);
  }
}
