<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <div class="mat-form-field">
        <ifc-filter-param-date-range-input [fc]="form.get('date')" [label]="'Date'"
          (rangeSelected)="dateSelected($event)">
        </ifc-filter-param-date-range-input>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Initiator</mat-label>
        <input matInput formControlName="initiator" type="text" placeholder="Initiator name">
        <button type="button" *ngIf="form.controls.initiator.value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.controls.initiator.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('date').value === 'custom'">

      <ifc-datepicker [fc]="form.get('dateFrom')" [label]="'From date'"></ifc-datepicker>

      <ifc-datepicker [fc]="form.get('dateTo')" [label]="'To date'"></ifc-datepicker>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <div class="mat-form-field">
        <ifb-audit-trial-event-input [fc]="form.get('event')"></ifb-audit-trial-event-input>
      </div>

    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>