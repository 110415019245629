<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form class="flex column" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Client</mat-label>
      <input matInput formControlName="name" type="text" placeholder="Client">
      <button type="button" *ngIf="form.get('name').value" mat-button matSuffix mat-icon-button aria-label="Clear"
        (click)="form.get('name').setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>OIDC audience</mat-label>
      <input matInput formControlName="oidcAudience" type="text" placeholder="OIDC audience">
      <button type="button" *ngIf="form.get('oidcAudience').value" mat-button matSuffix mat-icon-button aria-label="Clear"
        (click)="form.get('oidcAudience').setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select matInput formControlName="active" placeholder="Status">
        <mat-option>All</mat-option>
        <mat-option value="true">Active</mat-option>
        <mat-option value="false">Inactive</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>
