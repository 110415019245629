import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { TokenRoutingModule } from './token-routing.module';
import { TokenListComponent } from './token-list/token-list.component';
import { TokenDetailsComponent } from './token-details/token-details.component';
import { TokenListFilterDialogComponent } from './token-list-filter-dialog/token-list-filter-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { UserModule } from '../user/user.module';
import { TokenUpdateDialogComponent } from './token-update-dialog/token-update-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        TokenRoutingModule,
        UserModule
    ],
    declarations: [
        TokenListComponent,
        TokenDetailsComponent,
        TokenListFilterDialogComponent,
        TokenUpdateDialogComponent
    ]
})
export class TokenModule { }
