import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, AbstractControl, UntypedFormControl } from '@angular/forms';
import {
  AppBarActionsService,
  AppBarTitleService,
  AppBarAction,
  DialogConfirm,
  MessageService,
  EnumHelper,
  NavigationService,
  FormHelper,
  LoanStatus,
  LoanInfoDto,
  InitiatorType,
  AccountHintData,
  SubtransactionType,
  SubtransactionTypeInternalLabel,
  TransactionStatus,
  TransactionTypeNames,
  TransactionType,
  SubtransactionData,
  PromotionType,
  PromotionInfo,
  TransactionTypeStringLabel,
  AppPageService
} from 'common';
import { formatDate  } from '@angular/common';
import { AccountService } from '../../account/account.service';
import { AccountBalanceData, AccountWithRenewalData } from '../../account/account.model';
import { LoanService } from '../../loan/loan.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  TransactionData,
  TransactionActionCommand,
  CanUserMoveTransactionToStatus,
  CanUserEditTransactionInStatus,
} from '../transaction.model';
import { UserData } from '../../user/user.model';
import { TransactionService } from '../transaction.service';
import { TransactionDetailsData } from './transaction-details.data';
import { TransactionDetailsUpdateDialogComponent } from '../transaction-details-update-dialog/transaction-details-update-dialog.component';
import { UserPermissionItem, UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite, readOnly } from '../../user/user-permission/user-permission.data';
import { TransactionCreateDialogComponent } from '../transaction-create-dialog/transaction-create-dialog.component';
import { TransactionTimePickerDialogComponent } from '../transaction-time-picker-dialog/transaction-time-picker-dialog.component';
import * as _ from 'lodash';
import { FailureReasonUsage, FailureReasonBaseData } from '../../admin/failure-reason/failure-reason.model';
import { NoteCardComponent } from '../../shared/note/note-card/note-card.component';
import { AppSettings } from '../../../app.settings';
import { pairwise, switchMap, tap } from 'rxjs/operators';
import { PromotionSelectOptionData } from '../../admin/promotion/models/promotion.model';
import { CompetitorHintData } from '../../competitors/models/competitor.model';
import { TransactionListUpdateDialogComponent } from '../transaction-list-update-dialog/transaction-list-update-dialog.component';
import { InitiatorData, NoteData } from '../../shared/note/note.model';

@Component({
  selector: 'ifb-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  transactionStatusOptions = EnumHelper.getNamesAndValues(TransactionStatus);
  subtransactionTypeNames = EnumHelper.getMappedNamesAndValues(SubtransactionType, SubtransactionTypeInternalLabel).filter(x => x.name !== undefined);
  canEditTransactionInStatus = false;
  CanUserEditTransactionInStatusGrantedPermissions: UserPermissionItem[] = [];
  CanUserMoveTransactionToStatusGrantedPermissions: UserPermissionItem[] = [];
  canEditDates = false;
  canEditAmount = false;
  canEditLoan = false;
  canAddNote = false;
  editingSubtransactions = false;
  canSetTransactionDateInThePast = false;
  accountIdUpdated = undefined;
  readWriteTransaction = false;
  readPromotions = false;
  includedStatus: LoanStatus = null;
  excludedStatus: LoanStatus = null;
  canViewBankTransactions: boolean;
  canViewAudit: boolean;
  canReadCompetitors: boolean;
  form: UntypedFormGroup;
  selectedIndex: number;
  initiatorOptions: TransactionInitiatorOption[] = [];
  allowToSpecifyCompetitors = (reasonCode: string): boolean => this.data.failureReasons?.find(reason => reason.code === reasonCode)?.allowToSpecifyCompetitors;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private accountService: AccountService,
    private loanService: LoanService,
    private transactionService: TransactionService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private navigationService: NavigationService,
    private settings: AppSettings
  ) {
    this.appBarActionsService.actions = [];

    this.form = this.formBuilder.group({
      accountId: [undefined],
      loanNumber: [undefined, Validators.required],
      entityName: [undefined, Validators.required],
      amount: [undefined],
      date: new UntypedFormControl(null, { validators: Validators.required, updateOn: 'blur' }),
      time: { value: undefined, disabled: true },
      underwritingDate: new UntypedFormControl(null, { validators: null, updateOn: 'blur' }),
      underwritingTime: { value: undefined, disabled: true },
      processDate: new UntypedFormControl(null, { validators: null, updateOn: 'blur' }),
      processTime: { value: undefined, disabled: true },
      financeDate: new UntypedFormControl(null, { validators: null, updateOn: 'blur' }),
      financeTime: { value: undefined, disabled: true },
      completeDate: new UntypedFormControl(null, { validators: null, updateOn: 'blur' }),
      completeTime: { value: undefined, disabled: true },
      cancellationDate: new UntypedFormControl(null, { validators: null, updateOn: 'blur' }),
      cancellationTime: { value: undefined, disabled: true },
      status: [TransactionStatus.Pending.valueOf(), Validators.required],
      requestedBy: [undefined, Validators.required],
      reasons: [undefined],
      competitors: [undefined],
      note: [undefined],
      subtransactions: this.formBuilder.array([]),
      promotion: [undefined]
    });
  }

  @ViewChild(NoteCardComponent) noteCard;

  get FailureReasonUsage() { return FailureReasonUsage; }

  get TransactionStatus() { return TransactionStatus; }

  get TransactionTypeNames() { return TransactionTypeNames; }

  get AccountStatus() { return LoanStatus; }

  get PromotionType() { return PromotionType; }

  get account(): AccountWithRenewalData { return this.data.transactionDetails.account; }

  get accountInfo(): LoanInfoDto { return this.data.transactionDetails.accountInfo; }

  get link() { return `${this.settings.rootSite.url}/account/${this.transactionPrincipal.accountId}/transaction/${this.transactionPrincipal.id}`; }

  get promotionsOptions(): PromotionSelectOptionData[] {
    return this._promotionOptions;
  }

  private _promotionOptions: PromotionSelectOptionData[];

  selectedPromotions: number[];
  selectedCompetitors: CompetitorHintData[];

  get bankAccountId() {
    return this.transactionNew && this.account && this.account.disbursementBankAccountData && this.account.paymentBankAccountData
      ? (this.isTransactionTypeDraw ? this.account.disbursementBankAccountData.id : this.account.paymentBankAccountData.id)
      : (this.transactionPrincipal ? this.transactionPrincipal.bankAccountId : null);
  }

  accountNewBalances: AccountBalanceData = {};

  accountNewBalancesInit() {
    if (!this.transactionNew) return;

    const account = this.data.transactionDetails.account;
    this.accountNewBalances = {};
    this.paymentPrincipal = undefined;
    this.paymentInterest = undefined;
    this.disbursedAmount = undefined;
    this.drawFee = undefined;
    this.promotionToApplyInfo = undefined;
    if (!account || !account.currentState) return;

    this.transferRequestedAmount = parseFloat(this.form.get('amount').value);

    if (isNaN(this.transferRequestedAmount))
      this.accountNewBalancesInitEmpty();
    else {
      this.form.get('amount').setErrors(undefined);
      if (this.isTransactionTypeDraw && !this.editingSubtransactions)
        this.accountNewBalancesInitDraw();
      else if (this.isTransactionTypePayment && !this.editingSubtransactions)
        this.accountNewBalancesInitPayment();
    }
  }

  accountNewBalancesInitDraw() {
    if (this.transferRequestedAmount < this.accountInfo?.minDrawAmount) {
      this.form.get('amount').setErrors({ min: 1 });
    } else if (this.transferRequestedAmount > this.accountInfo?.availableFunds) {
      this.form.get('amount').setErrors({ max: 1 });
    }

    this.loanService
      .drawPreview(this.data.transactionDetails.account.id, this.transferRequestedAmount, this.form.controls.requestedBy.value.type,
        this.form.controls.requestedBy.value.value)
      .subscribe(res => {
        this.disbursedAmount = res.disbursedAmount;
        this.drawFee = res.fee;
        this.promotionToApplyInfo = res.promotionInfo;
        this.paymentInterest = res.interestAmount;
        this.paymentPrincipal = res.principalAmount;
        this.accountNewBalances.balanceOutstanding = res.newOutstandingBalance;
        this.accountNewBalances.fundsAvailable = res.newAvailableFunds;
        this.accountNewBalances.paymentDue = res.newUpcomingPaymentAmount;
        this.accountNewBalances.paymentDueDate = res.newUpcomingPaymentDate;
      },
      error => this.messageService.error(error));
  }

  accountNewBalancesInitEmpty() {
    this.accountNewBalances.balanceOutstanding = this.account.currentState?.principalBalance;
    this.accountNewBalances.fundsAvailable = this.accountInfo?.availableFunds;
    this.accountNewBalances.paymentDue = this.accountInfo?.upcomingPayment;
    this.accountNewBalances.paymentDueDate = this.accountInfo?.paymentDueDate;
  }

  accountNewBalancesInitPayment() {
    if (this.transferRequestedAmount < this.accountInfo?.minPaymentAmount) {
      this.form.get('amount').setErrors({ min: 1 });
    } else if (this.transferRequestedAmount > this.accountInfo?.currentPayoffAmount) {
      this.form.get('amount').setErrors({ max: 1 });
    }

    this.loanService
      .payPreview(this.data.transactionDetails.account.id, this.transferRequestedAmount, this.form.controls.requestedBy.value.type,
        this.form.controls.requestedBy.value.value)
      .subscribe(res => {
        this.paymentInterest = res.interestAmount;
        this.paymentPrincipal = res.principalAmount;
        this.promotionToApplyInfo = res.promotionInfo;
        this.accountNewBalances.balanceOutstanding = res.newOutstandingBalance;
        this.accountNewBalances.fundsAvailable = res.newAvailableFunds;
        this.accountNewBalances.paymentDue = res.newUpcomingPaymentAmount;
        this.accountNewBalances.paymentDueDate = res.newUpcomingPaymentDate;
      },
      error => this.messageService.error(error));
  }

  private add(accumulator, a) {
    return accumulator + a;
  }

  transferAmount: number;

  accountNewBalancesInitTransaction() {

    this.paymentPrincipal = this.subtransactions.filter(_ => _.type === SubtransactionType.PaymentPrincipal)
      .map(_ => _.amount).reduce(this.add, 0);
    this.paymentInterest = this.subtransactions.filter(_ => _.type === SubtransactionType.PaymentInterest)
      .map(_ => _.amount).reduce(this.add, 0);
    this.disbursedAmount = this.subtransactions.filter(_ => _.type === SubtransactionType.DrawDisbursement)
      .map(_ => _.amount).reduce(this.add, 0);
    this.drawFee = this.subtransactions.filter(_ => _.type === SubtransactionType.DrawFee)
      .map(_ => _.amount).reduce(this.add, 0);

    this.transferAmount = this.paymentPrincipal + this.paymentInterest + this.disbursedAmount;
    const balanceDelta = this.disbursedAmount + this.drawFee - this.paymentPrincipal;

    if (this.account) {
      this.accountNewBalances.balanceOutstanding = this.account.currentState?.principalBalance + balanceDelta;
      this.accountNewBalances.fundsAvailable = this.accountInfo?.availableFunds - balanceDelta;

      this.accountNewBalances.paymentDue = this.accountService.calculatePaymentAmount(
        this.accountNewBalances.balanceOutstanding,
        this.accountInfo?.termInterestRate,
        this.disbursedAmount > 0 ? this.accountInfo?.totalNumberOfPayments : this.accountInfo?.numberOfPaymentsLeft,
        this.accountInfo?.termMaintenanceFee
      );
    }
  }

  accountUpdate(hint: AccountHintData) {
    if (this.account && this.account.id === hint.id)
      return;

    this.form.controls.subtransactions.enable();

    forkJoin(
      this.accountService.get(hint.id),
      this.accountService.getInfo(hint.id),
      this.accountService.getOwnersList(hint.id)
    ).subscribe(
      results => {
        this.data.transactionDetails.account = results[0];
        this.data.transactionDetails.accountInfo = results[1];
        this.data.transactionDetails.owners = results[2];
        this.form.patchValue({ accountId: hint.id, loanNumber: hint, entityName: hint });
        if (!this.editingSubtransactions)
          this.accountNewBalancesInit();
        else
          this.accountNewBalancesInitTransaction();
        this.setInitiatorOptions();
      },
      error => {
        this.data.transactionDetails.account = undefined;
        this.data.transactionDetails.accountInfo = undefined;
        this.form.patchValue({ accountId: undefined, loanNumber: undefined, entityName: undefined });
        if (!this.editingSubtransactions)
          this.accountNewBalancesInit();
        else
          this.accountNewBalancesInitTransaction();

        if (error.status === 404) {
          this.form.get('loanNumber').setErrors({ 'notFound': 'Loan not found.' });
        } else {
          this.form.get('loanNumber').setErrors({ 'internalError': 'Internal error.' });
        }
      });

    this.accountIdUpdated = hint.id;
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  cancel() {
    this.appPageService.back();
  }

  get minDate() { return new Date(); }

  data: TransactionDetailsComponentData;

  private dataInit(data: TransactionDetailsComponentData) {
    data.transactionDetails = data.transactionDetails || {};
    data.transactionDetails.transactions = data.transactionDetails.transactions || [];
    this.data = data;

    this.editingSubtransactions = this.data.admin || false;

    this.transactionPrincipal = data.transactionDetails.transactions.find(() => true) || {};

    this._promotionOptions = data.promotionsToSelect;
    this.selectedPromotions = this.transactionPrincipal?.promotions?.map(p => p.id);

    this.form.reset({
      loanNumber: this.account ? { loanNumber: this.account.loanNumber } : '',
      entityName: this.account ? { businessName: this.account.entityName } : '',
      amount: this.transactionPrincipal.amount || undefined,
      date: this.transactionPrincipal.date || new Date(),
      time: this.transactionPrincipal.date
        ? formatDate(this.transactionPrincipal.date, 'shortTime', 'en_US')
        : formatDate(new Date(), 'shortTime', 'en_US'),
      status: this.transactionPrincipal.status || TransactionStatus.Pending.valueOf(),
      reasons: this.transactionPrincipal.failureReasons,
      underwritingDate: this.transactionPrincipal.underwritingDate,
      underwritingTime: this.transactionPrincipal.underwritingDate
        ? formatDate(this.transactionPrincipal.underwritingDate, 'shortTime', 'en_US')
        : undefined,
      processDate: this.transactionPrincipal.processDate,
      processTime: this.transactionPrincipal.processDate
        ? formatDate(this.transactionPrincipal.processDate, 'shortTime', 'en_US')
        : undefined,
      financeDate: this.transactionPrincipal.financeDate,
      financeTime: this.transactionPrincipal.financeDate
        ? formatDate(this.transactionPrincipal.financeDate, 'shortTime', 'en_US')
        : undefined,
      completeDate: this.transactionPrincipal.completeDate,
      completeTime: this.transactionPrincipal.completeDate
        ? formatDate(this.transactionPrincipal.completeDate, 'shortTime', 'en_US')
        : undefined,
      cancellationDate: this.transactionPrincipal.cancellationDate,
      cancellationTime: this.transactionPrincipal.cancellationDate
        ? formatDate(this.transactionPrincipal.cancellationDate, 'shortTime', 'en_US')
        : undefined,
      competitors: this.transactionPrincipal.competitors
    });

    const tx = this.form.controls.subtransactions as UntypedFormArray;
    tx.controls = []; // reset controls array from the FormArray subtransactions

    if (!this.transactionNew)
      this.transactionPrincipal.subtransactions.forEach((t, i) => {
        const f = this.getTxForm();
        f.reset({
          subType: t.type,
          subAmount: t.amount
        });
        tx.push(f);
      });
    else if (this.transactionNew && this.editingSubtransactions)
      this.insertRow();

    if (!this.transactionNew && this.editingSubtransactions)
      this.accountNewBalancesInitTransaction();

    if (this.noteCard)
      this.noteCard.addedNotes = [];

    this.titleInit();

    if (this.data.transactionDetails.account)
      this.setInitiatorOptions();

    this.form.controls.promotion.valueChanges.pipe(pairwise()).subscribe(([prev, next]: [number[], number[]]) => {
      if(prev?.length < next?.length) {
        const diff = next.find(p => !prev.includes(p))
        const selectedPromotion = this.promotionsOptions?.find(p => p.id === diff);
        if(selectedPromotion?.exclusivePromotion)
           this.form.controls.promotion.setValue([diff]);
        else {
          if(this.promotionsOptions?.find(p => prev.some(sp => sp == p.id))?.exclusivePromotion)
          this.form.controls.promotion.setValue(prev);
        }
      }
    })
  }

  getTxForm(): UntypedFormGroup {
    const txForm: UntypedFormGroup = this.formBuilder.group({
      subType: [{ value: undefined, disabled: !this.account }, Validators.required],
      subAmount: [{ value: undefined, disabled: !this.account }, Validators.required]
    });
    return txForm;
  }

  get initiatorName(): string {
    if (this.transactionPrincipal.initiator) {
      return this.transactionPrincipal.initiator;
    } else if (this.userCurrent) {
      return `${this.userCurrent.firstName} ${this.userCurrent.lastName}`;
    }
    return undefined;
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.route.params.subscribe(this.paramsInit.bind(this)),
      this.appBarActionsService.invoking.subscribe(this.actionDispatch.bind(this))
    ];

    this.setPermissions();
    this.onChanges();

    this.selectedIndexChange(_.startsWith(this.navigationService.urlPreviousAfterInit, '/audit') ? 1 : 0);
  }

  selectedIndexChange(val: number) {
    this.selectedIndex = val;
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(form => {
      if (!form.note)
        this.enableSaveAction();
    });

    this.form.get('status').valueChanges.subscribe(status => {
      if ((status === TransactionStatus.Pending
        || status === TransactionStatus.Cancelled)
        && !this.editingSubtransactions) {
        this.form.get('underwritingDate').setValue('');
        this.form.get('underwritingDate').markAsDirty();
        this.form.get('financeDate').setValue('');
        this.form.get('financeDate').markAsDirty();
      }
    });
  }

  enableSaveAction(): void {
    if (this.form.dirty && this.readWriteTransaction) {
      this.appBarActionsService.enable('save', true);
    }
  }

  setPermissions(): void {
    for (let index = 0; index < CanUserMoveTransactionToStatus.length; index++) {
      this.CanUserMoveTransactionToStatusGrantedPermissions[index] = { permission: [CanUserMoveTransactionToStatus[index]], hidden: true };
    }

    for (let index = 0; index < CanUserEditTransactionInStatus.length; index++) {
      this.CanUserEditTransactionInStatusGrantedPermissions[index] = { permission: [CanUserEditTransactionInStatus[index]], hidden: true };
    }

    this.userPermissionService.visibilityList(this.CanUserEditTransactionInStatusGrantedPermissions).subscribe(res => {
      this.canEditTransactionInStatus = !this.transactionNew && !res[this.transactionPrincipal.status].hidden;
    });

    this.userPermissionService.visibilityList(this.CanUserMoveTransactionToStatusGrantedPermissions).subscribe(res => {
      this.transactionStatusOptions = EnumHelper.getNamesAndValues(TransactionStatus).filter(_ =>
        !res[_.value].hidden || this.transactionPrincipal.status === _.value || this.transactionNew);
      if (this.canEditTransactionInStatus && this.data.viewMode && this.data.embeddedView) {
        if (this.transactionStatusOptions.filter(s => s.name === 'Approved').length > 0) {
          this.appBarActionsService.actions.push({ id: 'approve', label: 'Approve', disabled: false, buttonType: 'button' });
        }
        if (this.transactionStatusOptions.filter(s => s.name === 'OnHold').length > 0) {
          this.appBarActionsService.actions.push({ id: 'hold', label: 'Hold', disabled: false, buttonType: 'button' });
        }
        if (this.transactionStatusOptions.filter(s => s.name === 'Rejected').length > 0) {
          this.appBarActionsService.actions.push({ id: 'reject', label: 'Reject', disabled: false, buttonType: 'button' });
        }
        if (this.transactionStatusOptions.filter(s => s.name === 'Cancelled').length > 0) {
          this.appBarActionsService.actions.push({ id: 'cancelTrx', label: 'Cancel', disabled: false, buttonType: 'button' });
        }
      }
    });

    this.userPermissionService.granted([readWrite('servicing-transactions')])
      .subscribe(res => {
        this.readWriteTransaction = res;
        this.appBarActionsService.enable('save', res && this.form.dirty);

        if (!this.readWriteTransaction)
          this.appBarActionsService.actions = [];

        if (this.readWriteTransaction && this.data.viewMode && this.canEditTransactionInStatus) {
          if (!this.data.embeddedView)
            this.appBarActionsService.actions.push({ id: 'edit', label: 'Edit', disabled: false, buttonType: 'button' });
          else if (this.data.embeddedView)
            this.appBarActionsService.actions.push({ id: null, label: 'Edit', disabled: false, buttonType: 'link', link: this.link });
        }

        this.readWriteTransaction && !this.data.viewMode
          ? this.form.controls.underwritingTime.enable() : this.form.controls.underwritingTime.disable();
        this.readWriteTransaction && !this.data.viewMode
          ? this.form.controls.processTime.enable() : this.form.controls.processTime.disable();
        this.readWriteTransaction && !this.data.viewMode
          ? this.form.controls.financeTime.enable() : this.form.controls.financeTime.disable();
        this.readWriteTransaction && !this.data.viewMode
          ? this.form.controls.completeTime.enable() : this.form.controls.completeTime.disable();
        this.readWriteTransaction && !this.data.viewMode
          ? this.form.controls.cancellationTime.enable() : this.form.controls.cancellationTime.disable();
      });

      this.userPermissionService.granted([readOnly('servicing-promotions')])
      .subscribe(res => {
        this.readPromotions = res;

        this.readPromotions && !this.data.viewMode && this.editingSubtransactions
          ? this.form.controls.promotion.enable() : this.form.controls.promotion.disable();
      });

    this.userPermissionService.granted([readWrite('servicing-deleting-transactions')])
      .subscribe(res => {
        if (this.readWriteTransaction && !this.data.viewMode) {
          if (res && !this.transactionNew)
            this.appBarActionsService.actions.push({ id: 'delete', label: 'Delete', disabled: false, buttonType: 'button' });
          this.setAppBarActions();
        }
      });

    this.userPermissionService.granted([readWrite('servicing-editing-transaction-dates')])
      .subscribe(res => {
        this.canEditDates = res;
        this.canEditDates && !this.data.viewMode
          ? this.form.controls.time.enable() : this.form.controls.time.disable();
      });

    this.userPermissionService.granted([readWrite('servicing-editing-transaction-amount')])
      .subscribe(res => {
        this.canEditAmount = res;
        this.canEditAmount && this.readWriteTransaction && !this.data.viewMode
          ? this.form.controls.amount.enable() : this.form.controls.amount.disable();
      });

    this.userPermissionService.granted([readWrite('servicing-editing-transaction-loan')])
      .subscribe(res => this.canEditLoan = res);

    this.userPermissionService.granted([readWrite('servicing-can-set-transaction-date-in-the-past')])
      .subscribe(res => this.canSetTransactionDateInThePast = res);

    this.userPermissionService.granted([readWrite('servicing-editing-transaction-notes')])
      .subscribe(res => {
        this.canAddNote = res;
        this.canAddNote && this.readWriteTransaction ? this.form.controls.note.enable() : this.form.controls.note.disable();
        if (this.canAddNote && this.data.viewMode && this.readWriteTransaction) {
          this.appBarActionsService.actions.push({
            id: 'save',
            label: 'Save',
            disabled: !this.form.dirty,
            buttonType: 'submit',
            buttonAppearance: 'flat',
            buttonColor: 'primary'
          });
        }
      });

    this.userPermissionService.granted([readOnly('servicing-synced-transaction')])
      .subscribe(res => this.canViewBankTransactions = res);

    this.userPermissionService.granted([readOnly('admin-audit-log')])
      .subscribe(res => this.canViewAudit = res && !this.transactionNew);

    this.userPermissionService.granted([readOnly('admin-competitors')])
      .subscribe(res => this.canReadCompetitors = res);
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  private paramsInit(params: TransactionDetailsComponentParams) {
    this.transactionTypeSet(params);
    this.titleInit();
  }

  get disabledTimePicker() {
    return !this.canEditDates
      || (!this.canSetTransactionDateInThePast
        && !!this.form.get('date').value
        && (new Date(this.form.get('date').value) < this.minDate));
  }

  clearMinDateValidationIfNotDirty(abstractControl: AbstractControl) {
    if (!abstractControl.dirty) {
      if (abstractControl.errors)
        delete abstractControl.errors.matDatepickerMin;
      if (abstractControl.errors !== null) {
        if (Object.keys(abstractControl.errors).length === 0) {
          abstractControl.setErrors(null);
          abstractControl.markAsUntouched();
        }
      }
    }
  }

  setAppBarActions() {
    this.appBarActionsService.actions.push({ id: 'cancel', label: 'Cancel', buttonType: 'button' });
    this.appBarActionsService.actions.push({
      id: 'save',
      label: 'Save',
      disabled: !this.form.dirty,
      buttonType: 'submit',
      buttonAppearance: 'flat',
      buttonColor: 'primary'
    });
  }

  edit() {
    this.router.navigate([`/account/${this.transactionPrincipal.accountId}/transaction/${this.transactionPrincipal.id}`], { replaceUrl: true, queryParamsHandling: "preserve" });
  }

  updateStatus(status: TransactionStatus) {
    this.form.controls.status.setValue(status);
    const transactionObs = this.getDrawRequirementsObservable();
    transactionObs.pipe(
      switchMap(_ => {
        return TransactionDetailsUpdateDialogComponent.show(this.dialog, this.form, this.noteCard.addedNotes, this.transactionPrincipal, status);
    })).subscribe(
      result => {
        if (result) {
          this.updateCommand.status = status;
          this.updateCommand.failureReasons = this.form.value.reasons;
          this.updateCommand.id = this.transactionPrincipal.id;
          this.updateCommand.loanId = (this.accountIdUpdated === undefined) ? this.account.id : this.accountIdUpdated;
          this.updateCommand.notes = this.noteCard.addedNotes.map(_ => _.text);
          this.updateCommand.promotions = this.selectedPromotions;
          this.saveSub = this.transactionService
            .update(this.updateCommand)
            .subscribe(this.saveSuccessHandlerEmbeddedModeStatusUpdated.bind(this), this.saveErrorHandler.bind(this));
        }
      },
      error => this.messageService.error(error)
    );
  }

  approve() {
    this.updateStatus(TransactionStatus.Approved);
  }

  reject() {
    this.updateStatus(TransactionStatus.Rejected);
  }

  cancelTrx() {
    this.updateStatus(TransactionStatus.Cancelled);
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);

    this.clearMinDateValidationIfNotDirty(this.form.controls.date);

    if (this.saveSub)
      return;

    if (!this.data || !this.data.transactionDetails.account || !this.data.transactionDetails.account.id || !this.form.valid) {
      return;
    }

    if (this.transactionNew) {
      if (this.isTransactionTypeDraw)
        this.transactionDrawCreate();
      else if (this.isTransactionTypePayment)
        this.transactionPaymentCreate();
      else if (this.editingSubtransactions)
        this.transactionCreate();
      else
        this.messageService.error('Internal error. Transfer type is not defined.');
    } else {
      this.transactionUpdate();
    }

  }

  deleteSub: Subscription;

  delete() {
    if (!this.transactionPrincipal || !this.transactionPrincipal.id)
      return;
    DialogConfirm.show(this.dialog, `Delete transaction?`).subscribe(result => {
      if (result) {
        this.deleteSub = this.transactionService.delete(this.transactionPrincipal.id).subscribe(() => {
          this.appPageService.back();
        });
      }
    });
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  saveSub: Subscription;

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveSuccessHandler() {
    this.saveSubClear();

    const result = this.appPageService.backToIfPossible(true);
    if (!result) {
      this.appPageService.back();
    }
  }

  private saveSuccessHandlerEmbeddedModeStatusUpdated() {
    this.saveSubClear();
    this.transactionService.get(this.transactionPrincipal.id).subscribe(trx => {
      this.data.transactionDetails.transactions = [trx];
      this.dataInit(this.data);
    });
  }

  resetBalances() {
    this.form.controls.subtransactions.disable();
    if (!this.editingSubtransactions) {
      this.paymentPrincipal = null;
      this.paymentInterest = null;
      this.drawFee = null;
      this.promotionToApplyInfo = null;
      this.disbursedAmount = null;
      this.form.get('amount').setErrors(null);
    }
    this.accountNewBalances.balanceOutstanding = null;
    this.accountNewBalances.balanceOutstanding = null;
    this.accountNewBalances.balanceOutstanding = null;
    this.accountNewBalances.balanceOutstanding = null;
    this.accountNewBalances.fundsAvailable = null;
    this.accountNewBalances.paymentDue = null;
    this.accountNewBalances.paymentDueDate = null;
    this.data.transactionDetails.account = null;
  }

  clear(fc: string, resetBalances?: boolean, correspondingFc?: string) {
    const formControl = this.form.get(fc);

    if (correspondingFc) {
      const correspondingFormControl = this.form.get(correspondingFc);
      correspondingFormControl.reset();
    }

    if (resetBalances === true)
      this.resetBalances();

    formControl.reset();
    formControl.markAsTouched();
    formControl.markAsDirty();
    this.form.markAsDirty();

    this.enableSaveAction();

    this.initiatorOptions = [];
  }

  private titleInit() {
    let title;
    if (this.transactionTypeName) {
      title = TransactionTypeStringLabel.get(this.transactionTypeName);
    } else if (this.editingSubtransactions) {
      if (this.transactionNew)
        title = 'Create new transaction';
      else
        title = 'Transaction';
    }

    if (this.transactionPrincipal.date) {
      title += ' - ' + formatDate(this.transactionPrincipal.date, 'medium', 'en_US');
    }

    this.appBarTitleService.title = title;
  }

  pickTime(date: string, time: string) {
    const dateFormControl = this.form.get(date);
    const timeFormControl = this.form.get(time);
    const dialogRef = this.dialog.open(TransactionTimePickerDialogComponent, {
      width: '350px',
      data: {
        date: dateFormControl.value
      },
      closeOnNavigation: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        dateFormControl.setValue(res);
        timeFormControl.setValue(res);
        timeFormControl.markAsDirty();
        this.form.markAsDirty();
        this.enableSaveAction();
      }
    });
  }

  private transactionDrawCreate() {
    TransactionCreateDialogComponent.show(this.dialog, this.form, TransactionType.Draw, this.form.controls.amount.value)
      .subscribe(result => {
        if (result) {
          this.saveSub = this.loanService
            .draw(this.data.transactionDetails.account.id,
              this.transferRequestedAmount,
              this.noteCard.addedNotes.map(_ => _.text),
              ((!this.form.controls.time.pristine || !this.form.controls.date.pristine) ? this.form.value.date : null),
              this.form.controls.requestedBy.value.type,
              this.form.controls.requestedBy.value.value,
              this.form.value.reasons
            )
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  get transactionNew(): boolean {
    return this.transactionPrincipal.id ? false : true;
  }

  private transactionPaymentCreate() {
    TransactionCreateDialogComponent.show(this.dialog, this.form, TransactionType.Payment, this.form.controls.amount.value)
      .subscribe(result => {
        if (result) {
          this.saveSub = this.loanService
            .pay(this.data.transactionDetails.account.id,
              this.transferRequestedAmount,
              this.noteCard.addedNotes.map(_ => _.text),
              ((!this.form.controls.time.pristine || !this.form.controls.date.pristine) ? this.form.value.date : null),
              this.form.controls.requestedBy.value.type,
              this.form.controls.requestedBy.value.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private transactionCreate() {
    const amount = this.subtransactions.map(_ => _.amount).reduce(this.add, 0);
    let transactionType: TransactionType;
    transactionType = this.getTransactionTypeFromSubtransactions(this.subtransactions);
    const promotions = this._promotionOptions?.filter(p => this.selectedPromotions?.some(sp => sp == p.id)).map(p => p.name).join(', ');
    const competitors = this.selectedCompetitors?.map(c => c.name).join(', ');

    TransactionCreateDialogComponent.show(this.dialog, this.form, transactionType, amount, promotions, competitors)
      .subscribe(result => {
        if (result) {
          const createCommand: TransactionActionCommand = this.getTransactionActionCommand();
          createCommand.type = transactionType;
          this.saveSub = this.loanService
            .transaction(this.data.transactionDetails.account.id, createCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  formatSubtransactionType (type: SubtransactionType) : string{
    return SubtransactionTypeInternalLabel.get(type);
  }

  transactionPrincipal: TransactionData;

  private getTransactionTypeFromSubtransactions(subtransactions: SubtransactionData[]) {
    let transactionType: TransactionType;

    if (subtransactions.some(subTrx => _.includes([
      SubtransactionType.PaymentPrincipal,
      SubtransactionType.PaymentInterest
    ], subTrx.type)))
      transactionType = TransactionType.Payment;
    else if (subtransactions.some(subTrx => _.includes([
      SubtransactionType.DrawDisbursement,
      SubtransactionType.InterestCharge
    ], subTrx.type)))
      transactionType = TransactionType.Draw;
    else if (subtransactions.some(subTrx => _.includes([
      SubtransactionType.PrincipalTransfer,
      SubtransactionType.InterestTransfer,
      SubtransactionType.FeeBalanceTransfer,
      SubtransactionType.TotalFeesTransfer
    ], subTrx.type)))
      transactionType = TransactionType.BalanceTransfer;
    else if (subtransactions.some(subTrx => _.includes([
      SubtransactionType.LitigationCostProtectionPremium
    ], subTrx.type)))
      transactionType = TransactionType.Premium;
    else if (subtransactions.some(subTrx => _.includes([
        SubtransactionType.ThirdPartyPayoff
      ], subTrx.type)))
        transactionType = TransactionType.Payoff;
    else
      transactionType = TransactionType.Fee;
    return transactionType;
  }

  insertRow() {
    const tx = this.form.controls.subtransactions as UntypedFormArray;
    const f = this.getTxForm();
    f.reset({
      subType: null,
      subAmount: null
    });
    tx.push(f);
    this.subtransactions.push(new SubtransactionData());
  }

  deleteRow(i: number) {
    this.subtransactions.splice(i, 1);
    const tx = this.form.controls.subtransactions as UntypedFormArray;
    tx.removeAt(i);
    this.accountNewBalancesInitTransaction();
    tx.markAsDirty();
    this.form.markAsDirty();
    this.enableSaveAction();
  }

  getTransactionActionCommand(): TransactionActionCommand {
    const actionCommand: TransactionActionCommand = {
      status: this.form.value.status,
      notes: this.noteCard.addedNotes.map(_ => _.text),
      failureReasons: this.form.value.reasons,
      loanId: (this.accountIdUpdated === undefined) ? this.account.id : this.accountIdUpdated,
      date: (!this.form.controls.time.pristine || !this.form.controls.date.pristine) ? this.form.value.date : null,
      processDate: this.form.value.processDate,
      underwritingDate: this.form.value.underwritingDate,
      financeDate: this.form.value.financeDate,
      completeDate: this.form.value.completeDate,
      cancellationDate: this.form.value.cancellationDate,
      subtransactions: this.editingSubtransactions ? this.subtransactions : null,
      initiatorId: this.form.controls.requestedBy.value.value,
      initiatorType: this.form.controls.requestedBy.value.type,
      promotions: this.selectedPromotions,
      competitors: this.form.value.competitors
    };
    return actionCommand;
  }

  updateCommand: TransactionActionCommand = {};

  private transactionUpdate() {
    const transactionObs = this.getDrawRequirementsObservable();

    transactionObs
      .pipe(
        switchMap((result) => {
          this.transactionPrincipal.type =
            this.getTransactionTypeFromSubtransactions(
              this.transactionPrincipal.subtransactions
            );
          const promotions = this._promotionOptions
            ?.filter((p) => this.selectedPromotions?.some((sp) => sp == p.id))
            .map((p) => p.name)
            .join(", ");
          const competitors = this.selectedCompetitors
            ?.map((c) => c.name)
            .join(", ");
          // eslint-disable-next-line max-len
          return TransactionDetailsUpdateDialogComponent.show(
            this.dialog,
            this.form,
            this.noteCard.addedNotes,
            this.transactionPrincipal,
            null,
            this.transactionNew,
            promotions,
            competitors
          );
        })
      )
      .subscribe(
        {
          next: (result) => {
            if (result) {
              if (!this.data.viewMode) {
                this.updateCommand = this.getTransactionActionCommand();
                this.updateCommand.id = this.transactionPrincipal.id;
                this.updateCommand.amount = this.form.controls.amount.value;
                this.updateCommand.type = this.transactionPrincipal.type;
                this.saveSub = this.transactionService
                  .update(this.updateCommand)
                  .subscribe({
                    next: this.saveSuccessHandler.bind(this),
                    error: this.saveErrorHandler.bind(this)
                  }

                  );
              } else if (this.data.viewMode && this.canAddNote) {
                this.updateCommand.id = this.transactionPrincipal.id;
                this.updateCommand.notes = this.noteCard.addedNotes.map(
                  (_) => _.text
                );
                this.saveSub = this.transactionService
                  .addNotes(this.updateCommand)
                  .subscribe({
                    next: () => this.data.embeddedView
                      ? this.saveSuccessHandlerEmbeddedModeStatusUpdated.bind(this)
                      : this.saveSuccessHandler.bind(this),
                    error: () => this.saveErrorHandler.bind(this)
                  });
              }
            }
          },
          error: (error) => this.messageService.error(error)
        }
      );
  }

  paymentPrincipal: number;
  paymentInterest: number;
  disbursedAmount: number;
  drawFee: number;
  promotionToApplyInfo: PromotionInfo;
  transferRequestedAmount: number;

  private getDrawRequirementsObservable(): Observable<any> {
    const isDrawRequirementsWarn = this.getDrawRequirementsWarn();
    let transactionObs = of(undefined);

    if (isDrawRequirementsWarn && !this.noteCard.addedNotes?.length) {
      transactionObs = TransactionListUpdateDialogComponent.show(
        this.dialog,
        this.transactionPrincipal.loanNumber,
        this.form.get('status')?.value,
        [],
        this.transactionPrincipal.status,
        this.transactionPrincipal.type,
        this.transactionPrincipal.drawRequirements);
    }

    return transactionObs.pipe(
      tap((result) => {
        if (isDrawRequirementsWarn && !result && this.noteCard.addedNotes?.length === 0) {
          throw Error('Welcome call note is required in order to save the transaction.');
        }
        if (isDrawRequirementsWarn && !this.noteCard.addedNotes?.length) {
          this.noteCard.addedNotes = this.noteCard.addedNotes ?? [];
          const fullName = `${this.data.userCurrent.firstName} ${this.data.userCurrent.lastName}`;
          const initiator = new InitiatorData(fullName);
          this.noteCard.addedNotes.push(new NoteData(result.note, initiator));
          this.form.controls.note.markAsDirty();
        }
      })
    );
  }

  private getDrawRequirementsWarn() {
    return this.transactionPrincipal.drawRequirements?.some(drawRequirement => drawRequirement.required && !drawRequirement.requirementCompleted)
      && this.form.get('status')?.value === TransactionStatus.Approved;
  }

  get transferFrom(): string {
    if (!this.account || !this.account.currentState)
      return undefined;

    if (this.transactionPrincipal.type === TransactionType.Draw || this.transactionPrincipal.type === TransactionType.Premium)
      return `${this.account.productName}: ${this.account.loanNumber}`;
    else if (this.transactionPrincipal.type === TransactionType.BalanceTransfer) {
      if (this.transferAmount >= 0)
        return `${this.account.renewalForProductName}: ${this.account.renewalForLoanNumber}`;
      else
        return `${this.account.productName}: ${this.account.loanNumber}`;
    }
    else
      return this.account.disbursementBankAccountData
        ? (this.account.disbursementBankAccountData.bankName ? this.account.disbursementBankAccountData.bankName + ": " : " ")
        + this.account.disbursementBankAccountData.accountNumber
        : "Your bank account on file";
  }

  get transferTo(): string {
    if (!this.account || !this.account.currentState)
      return undefined;

    if (this.transactionPrincipal.type === TransactionType.Draw)
      return this.account.disbursementBankAccountData
        ? (this.account.disbursementBankAccountData.bankName ? this.account.disbursementBankAccountData.bankName + ": " : " ")
        + this.account.disbursementBankAccountData.accountNumber
        : "Your bank account on file";
    else if (this.transactionPrincipal.type === TransactionType.BalanceTransfer) {
      if (this.transferAmount >= 0)
        return `${this.account.productName}: ${this.account.loanNumber}`;
      else
        return this.account.renewedBy
          ? `${this.account.renewedByProductName}: ${this.account.renewedByLoanNumber}`
          : `${this.account.renewalForProductName}: ${this.account.renewalForLoanNumber}`;
    }
    else if (this.transactionPrincipal.type === TransactionType.Premium)
      return 'External account';
    else
      return `${this.account.productName}: ${this.account.loanNumber}`;
  }

  transactionTypeName: TransactionTypeNames;

  isTransactionTypeDraw: boolean;

  isTransactionTypePayment: boolean;

  private transactionTypeSet(params?: TransactionDetailsComponentParams) {
    if (params && params.transactionType) {
      const transactionTypeKey = Object.keys(TransactionTypeNames).find(key => TransactionTypeNames[key] === params.transactionType);
      if (transactionTypeKey && transactionTypeKey in TransactionTypeNames) {
        this.transactionTypeName = TransactionTypeNames[transactionTypeKey];
      } else {
        this.router.navigate(['error', 404]);
        return;
      }
    } else {
      if (this.transactionNew && !this.editingSubtransactions) {
        this.router.navigate(['error', 404]);
        return;
      } else if (!this.editingSubtransactions) {
        switch (this.transactionPrincipal.type) {
          case TransactionType.Draw:
            this.transactionTypeName = TransactionTypeNames.Draw;
            break;
          case TransactionType.Payment:
            this.transactionTypeName = TransactionTypeNames.Payment;
            break;
          case TransactionType.BalanceTransfer:
            this.transactionTypeName = TransactionTypeNames.BalanceTransfer;
            break;
          case TransactionType.Fee:
            this.transactionTypeName = TransactionTypeNames.Fee;
            break;
          case TransactionType.Premium:
            this.transactionTypeName = TransactionTypeNames.Premium;
            break;
          case TransactionType.Payoff:
            this.transactionTypeName = TransactionTypeNames.Payoff;
            break;
          default:
            this.router.navigate(['error', 404]);
            return;
        }
      }
    }

    this.isTransactionTypeDraw = this.transactionTypeName === TransactionTypeNames.Draw;
    this.isTransactionTypePayment = this.transactionTypeName === TransactionTypeNames.Payment;

    if (this.editingSubtransactions)
      this.includedStatus = null;
    else {
      if (!this.transactionNew) {
        this.includedStatus = this.transactionPrincipal.type === TransactionType.Draw ? LoanStatus.Open : null;
      } else if (this.transactionNew) {
        this.includedStatus = this.isTransactionTypeDraw ? LoanStatus.Open : null;
      } else this.includedStatus = null;
    }

  }

  get userCurrent(): UserData { return this.data.transactionDetails.userCurrent; }

  transactionNewSubtransactions: SubtransactionData[] = [];
  get subtransactions(): Array<SubtransactionData> {
    return this.transactionPrincipal.subtransactions
      ? this.transactionPrincipal.subtransactions
      : this.transactionNewSubtransactions;
  }

  onTabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.setPermissions();
    } else if (event.index === 1) {
      this.appBarActionsService.actions = [];
    }
  }

  setInitiatorOptions() {
    let currentUserInitiator = this.createInitiatorFromCurrentUser();

    this.initiatorOptions = [
      this.createInitiatorFromSystem(),
      currentUserInitiator
    ];

    var prevUserInitiator = this.createInitiatorFromTransaction();

    if (prevUserInitiator)
      this.initiatorOptions.push(prevUserInitiator);

    this.initiatorOptions =
    this.initiatorOptions.concat(
      this.createInitiatorsFromAccount()
    );

    let selectedInitiator = (this.transactionPrincipal.initiatorType == InitiatorType.System)
      ? this.initiatorOptions.find(x=>x.type === InitiatorType.System)
      : this.initiatorOptions.find(x=>x.value === this.transactionPrincipal.initiatorId
        && x.type === this.transactionPrincipal.initiatorType);

    this.form.patchValue({requestedBy: selectedInitiator ?? currentUserInitiator});
  }

  createInitiatorsFromAccount(): ConcatArray<TransactionInitiatorOption> {
    return this.data.transactionDetails.owners.map(x => (<TransactionInitiatorOption>{
      name: `${x.firstName} ${x.lastName}`,
      value: x.id,
      type: InitiatorType.Customer
    }));
  }

  createInitiatorFromCurrentUser() {
    return <TransactionInitiatorOption>{
      name: `${this.data.userCurrent.firstName} ${this.data.userCurrent.lastName}`,
      value: this.data.userCurrent.id,
      type: InitiatorType.BackOfficeUser
    };
  }

  createInitiatorFromSystem(): TransactionInitiatorOption {
    return <TransactionInitiatorOption>{
      name: 'RYNO',
      type: InitiatorType.System
    };
  }

  createInitiatorFromTransaction() {
    if (this.transactionPrincipal.initiatorType === InitiatorType.BackOfficeUser
      && this.transactionPrincipal.initiatorId !== this.data.userCurrent.id)
    {
      return <TransactionInitiatorOption>{
        name: `${this.transactionPrincipal.initiator}`,
        value: this.transactionPrincipal.initiatorId,
        type: InitiatorType.BackOfficeUser
      }

    }
    return null;
  }

  onCompetitorsChange(competitors: CompetitorHintData[]) {
    this.selectedCompetitors = competitors;
    const ids = competitors?.map(item => item.id);
    this.form.get('competitors').setValue(ids);
    this.form.get('competitors').markAsDirty();
    this.form.get('competitors').markAsTouched();
    this.form.get('competitors').updateValueAndValidity();
  }
}

export interface TransactionDetailsComponentData {
  transactionDetails: TransactionDetailsData;
  userCurrent: UserData;
  admin: boolean;
  viewMode: boolean;
  embeddedView: boolean;
  promotionsToSelect: PromotionSelectOptionData[];
  failureReasons: FailureReasonBaseData[];
}

export interface TransactionDetailsComponentParams {
  id?: string;
  accountId?: string;
  transactionType?: string;
}

export interface TransactionInitiatorOption {
  name: string,
  type: InitiatorType,
  value?: number;
}
