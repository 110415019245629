
<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title">Broker</div>
  </div>

  <mat-card-content class="layout padding-md">
    <div>
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text" mat-line>Company</span>
          <p class="item-content">{{data.brokerName}}</p>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>