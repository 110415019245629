import { Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentFilters, DocumentQueryParams, DocumentQueryResult, Document, DocumentCategory, DialogConfirm, MessageService, DocumentCategoryLabel } from 'common';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { readWrite, UserPermissionId } from '../../user/user-permission/user-permission.data';
import { UserData } from '../../user/user.model';
import { AccountData } from '../../account/account.model';
import { DocumentsFilterService } from './document-filter.service';
import { DocumentBaseService } from 'common/lib/documents/document-base.service';
import { ApplicationData } from '../../application/application.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Directive()
export abstract class DocumentListComponent<T extends DocumentBaseService> implements OnInit, OnDestroy {
  protected unsubscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: false })
  public paginator: MatPaginator;
  @ViewChild(MatSort)
  public sort: MatSort;

  data: DocumentListComponentData;
  queryParams: DocumentQueryParams;
  dataSource = new MatTableDataSource<Document>();
  readWrite: boolean = false;

  protected deleteSub: Subscription;
  tableColumns = [
    "createdOn",
    "filename",
    "category",
    "actions"
  ];

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected documentService: T,
    protected documentsFilterService: DocumentsFilterService,
    protected messageService: MessageService,
    protected dialog: MatDialog,
    protected userPermissionService: UserPermissionService,
    private queryParamsService: QueryParamsService) {
    this.unsubscribeAll = new Subject();
  }

  abstract ngOnInit();

  onInitBase(userPermissionId: UserPermissionId)
  {
    this.userPermissionService.granted([readWrite(userPermissionId)])
      .subscribe(res => this.readWrite = res);

    this.route.queryParams.pipe(takeUntil(this.unsubscribeAll))
      .subscribe(it => { this.queryParams = new DocumentQueryParams(this.queryParamsService.init(it)); });

    this.dataSource.data = this.data && this.data.documentQueryResult ? this.data.documentQueryResult.values : [];
    this.dataSource.paginator = this.paginator;

    if(this.paginator) {
      this.paginator.pageSize = this.queryParams.limit;
      this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
    }
  }

 ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  sortData() {
    this.filter();
  }

  setPage() {
    this.filter();
  }

  filter() {
    this.documentsFilterService.filter(new DocumentFilters(this.queryParams), this.paginator, this.sort);
  }

  getCategory(category: DocumentCategory) {
    return DocumentCategoryLabel.get(category);
  }

  download(fileId: number, parentRecordId: number) {
    this.documentService.download(fileId, parentRecordId);
  }

  signature(fileId: number, parentRecordId: number) {
    return `/account/${fileId}/documents/${parentRecordId}`;
  }

  delete(fileId: number, parentRecordId: number, filename: string) {
    if(!readWrite) {
      this.messageService.error("You don't have permissions to delete this file");
      return;
    }

    DialogConfirm.show(this.dialog, `Delete file ${filename}?`)
      .subscribe(result => {
        if(result) {
          this.deleteSub = this.documentService.delete(fileId, parentRecordId)
            .subscribe({
              next: this.deleteSuccessHandler.bind(this),
              error: this.deleteErrorHandler.bind(this)
            });
        }
      });
  }

  protected deleteSubClear() {
    if(this.deleteSub)
      this.deleteSub.unsubscribe();
    this.deleteSub = null;
  }

  protected deleteErrorHandler(error: any) {
    this.deleteSubClear();
    this.messageService.error(error);
  }

  protected deleteSuccessHandler() {
    this.deleteSubClear();
    location.reload();
  }
}

export interface DocumentListComponentData {
  userCurrent?: UserData;
  documentQueryResult: DocumentQueryResult;
}

export interface AccountDocumentListComponentData extends DocumentListComponentData {
  account?: AccountData
}

export interface ApplicationDocumentListComponentData extends DocumentListComponentData {
  application?: ApplicationData
}
