import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  BrokerType,
  BrokerTypeLabel,
  BrokerTier,
  BrokerTierLabel
} from '../broker.model';
import { EntityStatus } from 'common';
import { EntityStatusLabel } from '../../entity/entity.model';

@Component({
  selector: 'ifb-broker-create-dialog',
  templateUrl: './broker-create-dialog.component.html',
  styleUrls: ['./broker-create-dialog.component.css']
})
export class BrokerCreateDialogComponent {

  constructor(public dialogRef: MatDialogRef<BrokerCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, form: UntypedFormGroup): Observable<any> {
    return dialog.open(BrokerCreateDialogComponent, {
      width: '510px',
      data: {
        form: form
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

  formatStatus(status: EntityStatus): string {
    return EntityStatusLabel.get(status);
  }

  formatType(type: BrokerType): string {
    return BrokerTypeLabel.get(type);
  }

  formatTier(tier: BrokerTier): string {
    return BrokerTierLabel.get(tier);
  }
}
