import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ProductCode,
  AccountModificationFlagFilterMap,
  AccountOfferPaymentFrequencies
} from './account.model';
import { MatTableHelper, ObjectHelper, EnumHelper, LoanStatus, FilterParameter, AccountActivationStage, AccountFilters, AccountQueryParams, parseNumber } from 'common';
import * as _ from 'lodash';
import { formatCurrency } from '@angular/common';
import { TenantService } from '../admin/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class AccountFilterService {

  constructor(private router: Router, private tenantService: TenantService)
  {
  }

  filter(filters: AccountFilters, paginator?: MatPaginator, sort?: MatSort, brokerId?: number, advisorId?: number, entityId?: number) {
    const query = new AccountQueryParams(filters);
    query.loanNumber = typeof query.loanNumber === 'object' ? filters.loanNumber.loanNumber : query.loanNumber;
    query.businessName = typeof query.businessName === 'object' ? filters.businessName.businessName : query.businessName;
    // eslint-disable-next-line max-len
    query.applicationNumber = typeof query.applicationNumber === 'object' ? filters.applicationNumber.applicationNumber : query.applicationNumber;
    query.description = typeof query.description === 'object' ? filters.description.description : query.description;
    query.active = typeof query.active === 'object' ? filters.active.active : query.active;

    // Set default to first page
    query.skip = undefined;

    query.createdOnFrom = filters.createdOnFrom ? moment(filters.createdOnFrom).format('L') : undefined;
    query.createdOnTo = filters.createdOnTo ? moment(filters.createdOnTo).format('L') : undefined;
    query.renewalOnFrom = filters.renewalOnFrom ? moment(filters.renewalOnFrom).format('L') : undefined;
    query.renewalOnTo = filters.renewalOnTo ? moment(filters.renewalOnTo).format('L') : undefined;
    query.minUpcomingPaymentDate = filters.minUpcomingPaymentDate ? moment(filters.minUpcomingPaymentDate).format('L') : undefined;
    query.maxUpcomingPaymentDate = filters.maxUpcomingPaymentDate ? moment(filters.maxUpcomingPaymentDate).format('L') : undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    if (brokerId)
      this.router.navigate([`broker/${brokerId}/accounts`], { replaceUrl: true, queryParams: query });
    else if (advisorId)
      this.router.navigate([`advisor/${advisorId}/accounts`], { replaceUrl: true, queryParams: query });
    else if (entityId)
      this.router.navigate([`company/${entityId}/accounts`], { replaceUrl: true, queryParams: query });
    else
      this.router.navigate(['account'], { replaceUrl: true, queryParams: query });

  }

  addFilterParameters(query: AccountQueryParams): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];

    if (query.loanNumber) {
      filters.push({
        id: 'loanNumber', name: 'Loan number', value: query.loanNumber.trim()
      });
    }
    if (query.applicationNumber) {
      filters.push({
        id: 'applicationNumber', name: 'Application number', value: query.applicationNumber.trim()
      });
    }
    if (query.description) {
      filters.push({
        id: 'description', name: 'Case name', value: query.description.trim()
      });
    }
    if (query.businessName) {
      filters.push({
        id: 'businessName', name: 'Legal entity name', value: query.businessName.trim()
      });
    }
    if (query.createdOnFrom) {
      filters.push({
        id: 'createdOn', name: 'Date created on',
        value: `${moment(query.createdOnFrom).format('L')} - ${moment(query.createdOnTo).format('L')}`
      });
    }
    if (query.renewalOnFrom) {
      filters.push({
        id: 'renewalOn', name: 'Renewal on',
        value: `${moment(query.renewalOnFrom).format('L')} - ${moment(query.renewalOnTo).format('L')}`
      });
    }
    if (query.productCode) {
      filters.push({
        id: 'productCode', name: 'Product type', value: EnumHelper.getNameFromValue(ProductCode, query.productCode)
      });
    }
    if(query.companyId) {
      this.tenantService.names().subscribe(data => {
        filters.push({
          id: 'companyId', name: 'Brand', value: data.find(e => e.id === parseNumber(query.companyId))?.name ?? query.companyId
        })
      });
    }
    if (+query.status >= 0) {
      filters.push({
        id: 'status', name: 'Status', value: _.startCase(EnumHelper.getNameFromValue(LoanStatus, +query.status))
      });
    }
    if (+query.activationStage >= 0) {
      filters.push({
        id: 'activationStage', name: 'Activation stage', value: EnumHelper.getNameFromValue(AccountActivationStage, +query.activationStage)
      });
    }
    if (query.modificationFlags) {
      filters.push({
        id: 'modificationFlags', name: 'Loan modification',
        value: ObjectHelper.makeArray(query.modificationFlags).map(i => AccountModificationFlagFilterMap.get(i)).join(', ')
      });
    }
    if (+query.paymentFrequency >= 0) {
      filters.push({
        id: 'paymentFrequency', name: 'Payment frequency', value: EnumHelper.getNameFromValue(AccountOfferPaymentFrequencies, +query.paymentFrequency)
      });
    }
    if (query.minUpcomingPaymentDate) {
      filters.push({
        id: 'upcomingPaymentDateOn', name: 'Upcoming payment on',
        value: query.maxUpcomingPaymentDate
          ? `${moment(query.minUpcomingPaymentDate).format('L')} - ${moment(query.maxUpcomingPaymentDate).format('L')}`
          : moment(query.minUpcomingPaymentDate).format('L')
      });
    } else if (query.maxUpcomingPaymentDate) {
      filters.push({
        id: 'upcomingPaymentDateOn', name: 'Upcoming payment on',
        value: moment(query.maxUpcomingPaymentDate).format('L')
      });
    }
    if (query.minBalance) {
      filters.push({
        id: 'minBalance', name: 'Balance from', value: formatCurrency(query.minBalance, 'en_US', '$')
      });
    }
    if (query.maxBalance) {
      filters.push({
        id: 'maxBalance', name: 'Balance to', value: formatCurrency(query.maxBalance, 'en_US', '$')
      });
    }
    if (query.minPaymentAmount) {
      filters.push({
        id: 'minPaymentAmount', name: 'Payment amount from', value: formatCurrency(query.minPaymentAmount, 'en_US', '$')
      });
    }
    if (query.maxPaymentAmount) {
      filters.push({
        id: 'maxPaymentAmount', name: 'Payment amount to', value: formatCurrency(query.maxPaymentAmount, 'en_US', '$')
      });
    }
    if (query.active) {
      filters.push({
          id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
      });
  }

    return filters;
  }
}
