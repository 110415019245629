import { Component, Input } from "@angular/core";
import { ApplicationProfileComponentData } from "../application-profile/application-profile.component";

@Component({
  selector: "ifb-application-renewals-widget",
  templateUrl: "./application-renewals-widget.component.html",
  styleUrls: ["./application-renewals-widget.component.scss"],
})
export class ApplicationRenewalsWidgetComponent {
  @Input()
  data: ApplicationProfileComponentData;
}
