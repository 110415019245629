import {
  AppBarAction,
} from "common";

export const getActionsConfig: AppBarAction[] = [
  {
    id: "delete",
    label: "Delete",
    buttonType: "button",
    disabled: true,
    hidden: true,
  },
  { id: "cancel", label: "Cancel", buttonType: "button" },
  {
    id: "save",
    label: "Save",
    buttonType: "submit",
    buttonAppearance: "flat",
    buttonColor: "primary",
    hidden: false,
    disabled: true,
  },
];
