import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BankAccountQueryResult } from './bank.model';
import { BankAccount } from 'common';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {
  constructor(
    private http: HttpClient,
    private settings: AppSettings
  ) { }

  createBankAccount(entityId: number, bankAccount: BankAccount): Observable<any> {
    return this.http.post(`${this.settings.entities.url}/api/entities/${entityId}/bank-accounts`, bankAccount);
  }

  updateBankAccount(entityId: number, bankAccount: BankAccount): Observable<BankAccount> {
    return this.http.put(`${this.settings.entities.url}/api/entities/${entityId}/bank-accounts`, bankAccount);
  }

  getBankAccount(entityId: number, bankAccountId: number): Observable<BankAccount> {
    return this.http.get<BankAccount>(`${this.settings.entities.url}/api/entities/${entityId}/bank-accounts/${bankAccountId}`);
  }

  getBankAccountList(entityId: number, institutionId?: string): Observable<BankAccountQueryResult> {
    if (!entityId)
      return of(null);

    const url = institutionId
      ? `${this.settings.entities.url}/api/entities/${entityId}/bank-accounts?institutionId=${institutionId}`
      : `${this.settings.entities.url}/api/entities/${entityId}/bank-accounts`;
    return this.http.get<BankAccountQueryResult>(url);
  }

  deleteBankAccount(entityId: number, bankAccountId: number): Observable<any> {
    return this.http.delete(`${this.settings.entities.url}/api/entities/${entityId}/bank-accounts/${bankAccountId}`);
  }
}

