import { Injectable } from "@angular/core";
import {
  hasValue,
  setQueryVarsFromPaginator,
} from "projects/partner/src/Tools";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { BrokerApiKeyQueryParams } from "common";

@Injectable()
export class BrokerApiKeyListFacade {
  constructor(private readonly router: Router) {}

  filter(paginator?: MatPaginator) {
    const query = new BrokerApiKeyQueryParams();

    query.skip = undefined;

    if (hasValue(paginator)) {
      setQueryVarsFromPaginator(query, paginator);
    }

    void this.router.navigate([], {
      queryParams: query,
    });
  }
}
