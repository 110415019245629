<span class="mat-title">Re-amortize {{data?.loan?.loanNumber}}?</span>

<mat-dialog-content>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data?.paymentAmount | currency) || 'Null'}}</div>
        <div class="mat-caption">Payment Amount</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data?.paymentDueDate | date: 'mediumDate') || 'Null'}}</div>
        <div class="mat-caption">Next payment due date</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data?.numberOfPaymentsLeft) || 'Null'}}</div>
        <div class="mat-caption">Number of payments left</div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">OK</button>
</div>
