<h1 mat-dialog-title>Action cannot be done</h1>

<mat-dialog-content>
  <div *ngFor="let loan of data.loansConnected">
    <p>
      {{data.bankAccount.bankName ? data.bankAccount.bankName : 'Your bank account on file'}} - 
      {{data.bankAccount.accountType}} {{bankAccountNumber}} is a {{getBankAccountRole()}}.
      <br>
      To delete {{data.bankAccount.bankName}} - {{data.bankAccount.accountType}} {{bankAccountNumber}},
      set a new {{getBankAccountRole()}} bank.
    </p>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="" color="primary">Ok</button>
</div> 