<mat-card class="ifc-card">

  <div class="header">

    <div id="title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px" class="layout padding">
      <div class="header-icon">
        <ifc-avatar-icon [data]="data.avatar"></ifc-avatar-icon>
      </div>
      <span class="mat-title">{{data.fullName}}</span>
    </div>

    <button *ngIf="edit" mat-mini-fab class="add-button" color="primary" routerLink="/advisor/{{data.id}}"
      aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>

  </div>

  <mat-card-content>
    <mat-list>

      <mat-list-item>
        <mat-icon mat-list-icon>email</mat-icon>
        <h4 mat-line>{{data.email || '-'}}</h4>
        <p mat-line class="mat-caption">Email</p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.phone || '-'}}</h4>
        <p mat-line class="mat-caption">Phone</p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>cloud</mat-icon>
        <h4 mat-line>
          <a *ngIf="data.uniqueId" href="{{salesforceLink(data.uniqueId)}}" target="_blank">
            {{data.uniqueId}}
          </a>
          <span *ngIf="!data.uniqueId">-</span>
        </h4>
        <p mat-line class="mat-caption">Salesforce ID</p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>calendar_today</mat-icon>
        <h4 mat-line>
          <a *ngIf="data.calendarLinkUrl" href="{{data.calendarLinkUrl}}" target="_blank">{{data.calendarLinkUrl}}</a>
          <span *ngIf="!data.calendarLinkUrl">-</span>
        </h4>
        <p mat-line class="mat-caption">Calendar link</p>
      </mat-list-item>

    </mat-list>
  </mat-card-content>

</mat-card>
