import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import {
  MessageService,
  AppBarActionsService,
  AppBarTitleService,
  AppBarAction,
  FormHelper,
  QueryParams,
  AppPageService,
} from 'common';
import { AdvisorData } from '../advisor.model';
import { AdvisorService } from '../advisor.service';
import { AdvisorUpdateDialogComponent } from '../advisor-update-dialog/advisor-update-dialog.component';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';

@Component({
  selector: 'ifb-advisor-info-details',
  templateUrl: './advisor-info-details.component.html',
  styleUrls: ['./advisor-info-details.component.scss']
})
export class AdvisorInfoDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  file: File;
  form: UntypedFormGroup;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  readWriteAdvisors = false;

  @ViewChild('fileToUpload')
  fileToUpload: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private advisorService: AdvisorService,
    private userPermissionService: UserPermissionService,
    private messageService: MessageService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [];

    this.form = this.formBuilder.group({
      firstName: [undefined, Validators.compose([Validators.required, Validators.maxLength(64)])],
      lastName: [undefined, Validators.compose([Validators.required, Validators.maxLength(64)])],
      email: [undefined, Validators.compose([Validators.email, Validators.required, Validators.maxLength(80)])],
      phone: [undefined, Validators.required],
      uniqueId: [undefined, Validators.required],
      createdOn: { value: undefined, disabled: true },
      updatedOn: { value: undefined, disabled: true },
      active: [undefined, Validators.required],
      calendarLinkUrl: { value: undefined }
    });
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.userPermissionService.granted([readWrite('servicing-deleting-advisors')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res)
          this.appBarActionsService.enable('delete', true);
      });

    this.userPermissionService.granted([readWrite('servicing-advisors')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.readWriteAdvisors = res;
      });

    this.setAppBarActions();
    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty && this.readWriteAdvisors) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  data: AdvisorDetailsComponentData;
  dataInit(data: AdvisorDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = this.data.advisor ? 'Advisor info' : 'Add advisor';

    this.form.reset({
      firstName: this.data.advisor ? this.data.advisor.firstName : '',
      lastName: this.data.advisor ? this.data.advisor.lastName : '',
      email: this.data.advisor ? this.data.advisor.email : '',
      phone: this.data.advisor ? this.data.advisor.phone : '',
      uniqueId: this.data.advisor ? this.data.advisor.uniqueId : '',
      createdOn: this.data.advisor ? this.data.advisor.createdOn : '',
      updatedOn: this.data.advisor ? this.data.advisor.updatedOn : '',
      active: this.data.advisor ? this.data.advisor.active : '',
      calendarLinkUrl: this.data.advisor ? this.data.advisor.calendarLinkUrl : ''
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  cancel() {
    this.appPageService.back();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);

    if (this.saveSub || !this.form.valid)
      return;

    AdvisorUpdateDialogComponent.show(this.dialog, this.form, this.data.advisor ? 'Update' : 'Create')
      .subscribe(result => {
        if (result) {
          const command = {
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            email: this.form.value.email,
            phone: this.form.value.phone,
            active: this.form.controls.active.value,
            uniqueId: this.form.value.uniqueId,
            calendarLinkUrl: this.form.value.calendarLinkUrl
          };

          if (this.data.advisor)
            if (this.file)
              this.saveSub = forkJoin({
                update: this.advisorService.update(this.data.advisor.id, command),
                avatar: this.file ? this.advisorService.saveAvatar(this.file, this.data.advisor.id) : null
              }).subscribe({ next: this.saveSuccessHandler.bind(this), error: this.saveErrorHandler.bind(this) })
            else this.saveSub = this.advisorService.update(this.data.advisor.id, command).subscribe({ next: this.saveSuccessHandler.bind(this), error: this.saveErrorHandler.bind(this) })
          else this.saveSub = this.advisorService.create(command)
            .subscribe({ next: this.saveSuccessHandler.bind(this), error: this.saveErrorHandler.bind(this) });
        }
      });
  }

  fileChange(event) {
    if (!this.data || !this.data.advisor)
      return;

    if (this.readWriteAdvisors)
      this.appBarActionsService.enable('save', true);

    const fileList: FileList = event.target.files;
    const fileReader = new FileReader();

    if (fileList.length > 0) {
      this.file = fileList[0];
      fileReader.readAsDataURL(this.file);
      fileReader.onload = (_event) => {
        const res = fileReader.result as string;
        this.data.advisor.avatar = res.split(',')[1]
      }
    }
  }

  delete() {
    if (!this.data.advisor) return;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '510px',
      disableClose: false
    });

    this.confirmDialogRef.componentInstance
      .confirmMessage = `Delete ${this.data.advisor.firstName} ${this.data.advisor.lastName}?`;

    this.confirmDialogRef.afterClosed().subscribe(res => {
      if (res)
        this.advisorService.delete(this.data.advisor.id)
          .subscribe(() => this.deleteSuccessHandler());
    });
  }

  setAppBarActions() {
    if (!this.data.advisor) {
      this.appBarActionsService.actions.push(
        { id: 'cancel', label: 'Cancel', buttonType: 'button' },
        { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' });
    } else if (this.data.advisor) {
      this.appBarActionsService.actions.push(
        { id: 'delete', label: 'Delete', disabled: true, buttonType: 'button' },
        { id: 'cancel', label: 'Cancel', buttonType: 'button' },
        { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' });
    }
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }

  deleteSuccessHandler() {
    const query: QueryParams = {
      active: true
    };
    this.router.navigate(['advisor'], { replaceUrl: true, queryParams: query });
  }

  upload() {
    this.fileToUpload.nativeElement.click();
  }
}

export interface AdvisorDetailsComponentData {
  advisor?: AdvisorData;
}
