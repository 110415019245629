import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Tenant } from "./tenant.model";
import { TenantService } from "./tenant.service";

@Injectable({
    providedIn: 'root'
})
export class TenantResolver implements Resolve<Tenant> {
    constructor(private _service: TenantService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tenant> | Promise<Tenant> | Tenant {
        return this._service.get(route.params.id);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TenantListResolver implements Resolve<Tenant[]> {
    constructor(private _service: TenantService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tenant[]> | Promise<Tenant[]> | Tenant[] {
        return this._service.list();
    }
}
