<form [formGroup]="form">
  <mat-dialog-content class="mat-typography">
    <h3>Set filter</h3>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">
      <div fxFlex
           class="mat-form-field">
        <ifb-competitor-name-input [fc]="form.get('name')"></ifb-competitor-name-input>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select formControlName="active"
                    placeholder="Status">
          <mat-option>All</mat-option>
          <mat-option [value]="'true'">Active</mat-option>
          <mat-option [value]="'false'">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <div mat-dialog-actions
       class="flex row end-center">
    <button mat-button
            [mat-dialog-close]="">Cancel</button>
    <button mat-button
            [mat-dialog-close]="filters"
            cdkFocusInitial
            color="primary"
            [disabled]="form.invalid">Ok</button>
  </div>
</form>
