<mat-card class="ifc-card" [ngClass.gt-md]="'card lg'" [ngClass.md]="'card md'" [ngClass.xs]="'xs'">

  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title"> Commission info </div>
    <button *ngIf="edit" mat-icon-button (click)="detailsLink()" aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-card-content class="layout padding-md">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">

      <mat-list>

        <mat-list-item>
          <span class="secondary-text" mat-line> Pricing plan </span>
          <p class="item-content">{{formatPricingPlan(broker?.pricingPlan)}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Commission plan </span>
          <p class="item-content">{{formatCommissionPlan(broker?.commission?.plan)}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Initial commission </span>
          <p class="item-content">{{broker?.commission?.initialDrawCommission || "-"}}%</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Initial period </span>
          <p class="item-content">{{broker?.commission?.initialCommissionPeriod}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Initial cap </span>
          <p class="item-content">{{broker?.commission?.initialCommissionCap || "-"}}%</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Residual commission </span>
          <p class="item-content">{{broker?.commission?.residualDrawCommission || "-"}}%</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Residual period </span>
          <p class="item-content">{{broker?.commission?.residualCommissionPeriod}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Clawback period </span>
          <p class="item-content">{{broker?.commission?.defaultClawbackPeriod}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Prepayment clawback period </span>
          <p class="item-content">{{broker?.commission?.defaultPrepaymentClawbackPeriod}}</p>
        </mat-list-item>

      </mat-list>

    </div>

  </mat-card-content>

</mat-card>