import { Pipe, PipeTransform } from '@angular/core';
import { AccountData } from '../../account.model';

@Pipe({
  name: 'alligatorDataClass'
})
export class AlligatorDataClassPipe implements PipeTransform {

  transform(account: AccountData): string {
    if(account?.alligatorMatch) {
      return "avatar medium mat-green-bg";
    }
     return "avatar medium mat-red-bg";
  }
}
