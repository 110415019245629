import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ProductService } from "./product.service";
import { ProductData } from "./product.model";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ProductsResolver implements Resolve<ProductData[]> {
    constructor(private service: ProductService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductData[]> | Promise<ProductData[]> | ProductData[] {
        return this.service.getActiveStorage() || this.service.getActive().pipe(
            map((data: ProductData[]) => {
                this.service.setActiveStorage(data);
                return data;
            })
        );
    }
}
