<div class="table-container">

  <div class="message-box info" *ngIf="!data.logGroupQueryResult?.totalCount" fxLayout="row" fxLayoutGap="12px"
    fxLayoutAlign="start center">
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <table *ngIf="data.logGroupQueryResult?.totalCount" mat-table [dataSource]="data?.logGroupQueryResult?.values">

    <ng-container matColumnDef="group">
      <td mat-cell *matCellDef="let row" class="table-cell">
        <mat-card class="layout margin-bottom-sm margin-top-sm">
          <mat-card-header>
            <button *ngIf="canReadWriteLog" mat-icon-button (click)="archive(row.groupName, row.groupId)" class="add-button"
              aria-label="Archive" matTooltip="Archive">
              <mat-icon>archive</mat-icon>
            </button>
            <mat-card-title>
              <h3>{{row.groupName}}</h3>
            </mat-card-title>
            <mat-card-subtitle>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                  <mat-icon>email</mat-icon>
                  <span>
                    <a href="#" (click)="$event.preventDefault(); showLogMessages(row.groupName, row.groupId)">
                      {{row.messageCount}} messages
                    </a>
                  </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                  <mat-icon>access_time</mat-icon>
                  <span>First occurrence: {{row.firstOccurrence | date:'EEE, MM/dd/yyyy h:mm:ss:SS'}}</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                  <mat-icon>access_time</mat-icon>
                  <span>Last occurrence: {{row.lastOccurrence | date:'EEE, MM/dd/yyyy h:mm:ss:SS'}}</span>
                </div>
              </div>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
  </table>

</div>