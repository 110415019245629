import { UntypedFormGroup } from "@angular/forms";
import { UpdateFormFieldType } from "projects/backoffice/src/app/shared/const/enums/update-form-field-type.enum";
import { UpdateFormFieldData } from "projects/backoffice/src/app/shared/const/models/update-form-field-data.model";

export const updatedFieldsData = (
  renewalsForm: UntypedFormGroup,
): UpdateFormFieldData[] => {
  if (!renewalsForm?.controls) {
    return [];
  }

  const renewal = renewalsForm.controls;
  const renewalForLoanNumber = renewal.renewalForLoanNumber?.value;

  return [
    {
      label: "Renewal for loan",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !renewal.renewalForLoanNumber?.pristine,
      value: renewalForLoanNumber?.loanNumber ? renewalForLoanNumber?.loanNumber : renewalForLoanNumber,
    },
    {
      label: "Renewal ready",
      fieldType: UpdateFormFieldType.Boolean,
      isChanged: !renewal.renewalReady?.pristine,
      value: renewal.renewalReady?.value,
    },
  ];
};
