<div class="container">
  <mat-dialog-content class="mat-typography">
    <h1>Add new competitor</h1>
    <ifb-competitor-form (formStatusChanged)="onFormStatusChanged($event)"></ifb-competitor-form>
  </mat-dialog-content>

  <div mat-dialog-actions
       class="flex row end-center">
    <button mat-button
            [mat-dialog-close]="">CANCEL</button>
    <button mat-flat-button
            [mat-dialog-close]="formData"
            [disabled]="!formData?.isValid"
            color="primary">SAVE</button>
  </div>
</div>
