import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifb-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent {

  constructor() { }

  @Input()
  fxLayout: string = 'row wrap';

}
