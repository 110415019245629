import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AppBarActionsComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppPageComponent,
  AppSidenavComponent,
  AppBarExpandedTabsComponent,
  AppBarExpandedComponent
} from 'common';
import { appPageComponentDataDetail, appPageComponentDataMaster } from '../app.data';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import {
  CustomerQueryResolver,
  CustomerResolver,
  CustomerAppBarExpandedTabsResolver,
  CustomerUniqueIdResolver,
  CustomerSFAppBarExpandedTabsResolver
} from './customer.resolver';
import { CustomerAppBarTitleComponent } from './customer-app-bar-title/customer-app-bar-title.component';
import { CustomerInfoDetailsComponent } from './customer-info-details/customer-info-details.component';
import { BOAuthGuard } from '../guards/oauth.guard';
import { ApplicationListComponent } from '../application/application-list/application-list.component';
import { ApplicationByCustomerQueryResolver } from '../application/application.resolver';
import { CustomerKBADetailsComponent } from './customer-kba-details/customer-kba-details.component';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Customers',
  sideNavItem: 'customer'
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
    {
      path: 'customer',
      component: AppPageComponent,
      data: appPageDataMaster,
      resolve: {
        appPage: AppPageResolver,
        appSidenav: AppSidenavResolver
      },
      children: [
        {
          path: '',
          outlet: 'sidenav-content',
          component: AppSidenavComponent
        },
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: UserMenuComponent,
            }
          ]
        },
        {
          path: '',
          component: CustomerListComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            customerQueryResult: CustomerQueryResolver
          }
        }
      ]
    },
    {
      path: 'customer/new',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        }
      ]
    },
    {
      path: 'customer/:id/profile',
      component: AppPageComponent,
      data: appPageDataMaster,
      resolve: {
        appBarExpandedTabs: CustomerAppBarExpandedTabsResolver,
        appPage: AppPageResolver,
        appSidenav: AppSidenavResolver,
        customer: CustomerResolver
      },
      children: [
        {
          path: '',
          outlet: 'sidenav-content',
          component: AppSidenavComponent
        },
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: CustomerAppBarTitleComponent
            },
            {
              path: '',
              outlet: 'right',
              component: UserMenuComponent,
            },
            {
              path: '',
              outlet: 'expanded',
              component: AppBarExpandedComponent,
              children: [
                {
                  path: '',
                  outlet: 'tabs',
                  component: AppBarExpandedTabsComponent,
                }
              ]
            }
          ]
        },
        {
          path: '',
          component: CustomerProfileComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange"
        }
      ]
    },
    {
      path: 'customer/sf/:uniqueId/profile',
      component: AppPageComponent,
      data: {
        appBar: {
          expandable: true,
          fixed: true,
          logoHidden: true,
        },
        appPage: {
          sidenavEnabled: false
        }
      },
      resolve: {
        appBarExpandedTabs: CustomerSFAppBarExpandedTabsResolver,
        customer: CustomerUniqueIdResolver
      },
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'expanded',
              component: AppBarExpandedComponent,
              children: [
                {
                  path: '',
                  outlet: 'tabs',
                  component: AppBarExpandedTabsComponent,
                }
              ]
            }
          ]
        },
        {
          path: '',
          component: CustomerProfileComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange"
        }
      ]
    },
    {
      path: 'customer/:id',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: CustomerInfoDetailsComponent,
          resolve: {
            customer: CustomerResolver
          }
        }
      ]
    },
    {
      path: 'customer/:id/kba',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: CustomerKBADetailsComponent,
          resolve: {
            customer: CustomerResolver
          }
        }
      ]
    },
    {
      path: 'customer/:id/applications',
      component: AppPageComponent,
      data: appPageDataMaster,
      runGuardsAndResolvers: "paramsOrQueryParamsChange",
      resolve: {
        appPage: AppPageResolver,
        appSidenav: AppSidenavResolver,
        customer: CustomerResolver,
        appBarExpandedTabs: CustomerAppBarExpandedTabsResolver
      },
      children: [
        {
          path: '',
          outlet: 'sidenav-content',
          component: AppSidenavComponent
        },
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarExpandedComponent
            },
            {
              path: '',
              outlet: 'right',
              component: UserMenuComponent,
            },
            {
              path: '',
              outlet: 'expanded',
              component: AppBarExpandedComponent,
              children: [
                {
                  path: '',
                  outlet: 'tabs',
                  component: AppBarExpandedTabsComponent,
                }
              ]
            }
          ]
        },
        {
          path: '',
          component: ApplicationListComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            applicationQueryResult: ApplicationByCustomerQueryResolver
          }
        }
      ]
    }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
