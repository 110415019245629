<div fxLayout="column" fxLayoutAlign="stretch">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button mat-icon-button (click)="download()" class="secondary-text" matTooltip="Download"
          *ngIf="data.auditTrail.totalCount">
          <mat-icon>get_app</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button mat-menu-item (click)="download()" *ngIf="data.auditTrail.totalCount">
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>



  <ifb-account-notes-sidenav [accountId]="data.account?.id" [notes]="data.account?.notes" [initiator]="data.userCurrent"
    [saveOnDemand]="true" [innerSidenavAndList]="true" [hiddenButton]="!data.account || data.bankAccount">

    <ifc-app-scroll-container [detailTabbed]="detailTabbed" [innerTabbedHidden]="innerTabbed"
      [@routerTransition]="'fly'">
      <div class="table-container">
        <div class="message-box info" *ngIf="!data.auditTrail.totalCount" fxLayout="row" fxLayoutGap="12px"
          fxLayoutAlign="start center">
          <mat-icon class="icon">info</mat-icon>
          <span>No data found</span>
        </div>

        <table *ngIf="data.auditTrail.totalCount" mat-table matSort [dataSource]="data.auditTrail.values"
          (matSortChange)="sortData($event)">

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Date </th>
            <td mat-cell *matCellDef="let row" routerLink="/audit/{{row.id}}" class="table-cell">
              {{row.date | date: 'medium'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="event"> Event </th>
            <td mat-cell *matCellDef="let row" routerLink="/audit/{{row.id}}" class="table-cell">
              {{getFormattedEvent(row.event)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="initiator">
            <th mat-header-cell *matHeaderCellDef> Initiator </th>
            <td mat-cell *matCellDef="let row" routerLink="/audit/{{row.id}}" class="table-cell">
              {{row.initiator?.fullName}} </td>
          </ng-container>

          <ng-container matColumnDef="edit" stickyEnd>
            <th nowrap mat-header-cell *matHeaderCellDef></th>
            <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let row" class="action-icon"
              ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
              <button mat-icon-button (click)="accountAuditDetailsLink(row, true)">
                <mat-icon>open_in_new</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>

        </table>
      </div>
    </ifc-app-scroll-container>

    <div [hidden]="data.auditTrail.totalCount <= 10">
      <mat-paginator #paginator showFirstLastButtons [length]="data.auditTrail.totalCount"
        [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
    </div>

  </ifb-account-notes-sidenav>

</div>