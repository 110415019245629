import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppSidenavComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppBarExpandedTabsComponent,
  AppBarActionsComponent,
  AppBarExpandedComponent
} from 'common';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../app.data';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import {
  BrokerQueryResolver,
  BrokerAppBarExpandedTabsResolver,
  BrokerResolver,
  BrokerEntityQueryResolver,
  BrokerAccountQueryResolver,
  BrokerBankAccountListGroupedByInstitutionResolver,
  BrokerBankAccountResolver,
} from './broker.resolver';
import { BrokerListComponent } from './broker-list/broker-list.component';
import { BOAuthGuard } from '../guards/oauth.guard';
import { BrokerAppBarTitleComponent } from './broker-app-bar-title/broker-app-bar-title.component';
import { BrokerProfileComponent } from './broker-profile/broker-profile.component';
import { EntityListComponent } from '../entity/entity-list/entity-list.component';
import { AccountListComponent } from '../account/account-list/account-list.component';
import { BrokerCommissionDetailsComponent } from './broker-commission-details/broker-commission-details.component';
import { BrokerCompanyDetailsComponent } from './broker-company-details/broker-company-details.component';
import { BrokerStatusDetailsComponent } from './broker-status-details/broker-status-details.component';
import { BrokerOtherInfoDetailsComponent } from './broker-other-info-details/broker-other-info-details.component';
import { BrokerBankAccountListComponent } from './broker-bank-account-list/broker-bank-account-list.component';
import { BrokerBankAccountDetailsComponent } from './broker-bank-account-details/broker-bank-account-details.component';
import { BrokerDetailsComponent } from './broker-details/broker-details.component';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Brokers',
  sideNavItem: 'broker'
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
      {
        path: 'broker',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              }
            ]
          },
          {
            path: '',
            component: BrokerListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              brokerQueryResult: BrokerQueryResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BrokerDetailsComponent,
            resolve: {
              broker: BrokerResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/profile',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: BrokerAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          broker: BrokerResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: BrokerAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: BrokerProfileComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      },
      {
        path: 'broker/:id/companies',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: BrokerAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          broker: BrokerResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: BrokerAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: EntityListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              entityQueryResult: BrokerEntityQueryResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/accounts',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: BrokerAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          broker: BrokerResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: BrokerAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              accountQueryResult: BrokerAccountQueryResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/banks',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: BrokerAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          broker: BrokerResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: BrokerAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: BrokerBankAccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              bankAccountsListGroupedByInstitution: BrokerBankAccountListGroupedByInstitutionResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/banks/:bankAccountId',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BrokerBankAccountDetailsComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              broker: BrokerResolver,
              bankAccount: BrokerBankAccountResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/commission',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BrokerCommissionDetailsComponent,
            resolve: {
              broker: BrokerResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/company',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BrokerCompanyDetailsComponent,
            resolve: {
              broker: BrokerResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/status',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BrokerStatusDetailsComponent,
            resolve: {
              broker: BrokerResolver
            }
          }
        ]
      },
      {
        path: 'broker/:id/info',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BrokerOtherInfoDetailsComponent,
            resolve: {
              broker: BrokerResolver
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BrokerRoutingModule { }
