import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarAction,
  AppBarActionsService,
} from "../../../../../lib/ui/app/app-bar-actions/app-bar-actions.service";
import { FormHelper } from "../../../../../lib/helpers/form.helper";
import { AppPageService } from "../../../../../lib/ui/app/app-page/app-page.service";
import { BrokerApiKeyListActionsInternalService } from "../../actions/broker-api-key-actions.internal.service";
import { BrokerApiKeyDetailsDialogComponent } from "./components/broker-api-key-details-dialog/broker-api-key-details-dialog.component";
import { MatDialog } from "@angular/material/dialog";

import {
  BrokerApiKeyEditData,
  BrokerApiKeyInputData,
  BrokerApiKeysActiveLabelValues,
  CreateBrokerApiKeySuccessData,
} from "../../broker-api-key.model";
import { BrokerApiKeyDetailsFacade } from "./broker-api-key-details.facade";
import { AppBarTitleService } from "../../../../ui/app/app-bar-title/app-bar-title.service";

@UntilDestroy()
@Component({
  selector: "ifc-broker-api-key-details",
  templateUrl: "./broker-api-key-details.component.html",
  styleUrls: ["./broker-api-key-details.component.scss"],
  providers: [BrokerApiKeyDetailsFacade],
})
export class BrokerApiKeyDetailsComponent implements OnInit {
  private _inputData: BrokerApiKeyInputData;

  @Input()
  get inputData(): BrokerApiKeyInputData {
    return this._inputData;
  }

  set inputData(data: BrokerApiKeyInputData) {
    this._inputData = data;

    if (!this._inputData) {
      return;
    }

    if (this._inputData.isCreating) {
      this.appBarTitleService.title = "Create new Broker API Key";
      return;
    }

    if (this._inputData.writeAllowed) {
      this.appBarActionsService.enable("delete", true);
    }
    this.appBarTitleService.title = `${this.inputData.dataSource.name}`;
    this.form.patchValue({
      name: this.inputData.dataSource.name,
      description: this.inputData.dataSource.description,
      active: this.inputData.dataSource.active,
    });
  }

  form: UntypedFormGroup;

  readonly BrokerApiKeysActiveLabelValues = BrokerApiKeysActiveLabelValues;

  constructor(
    private readonly facade: BrokerApiKeyDetailsFacade,
    private readonly appBarActionsService: AppBarActionsService,
    private readonly appPageService: AppPageService,
    private readonly actionService: BrokerApiKeyListActionsInternalService,
    private readonly dialog: MatDialog,
    private readonly appBarTitleService: AppBarTitleService
  ) {
    this.initForm();
    this.initAppActions();
  }

  ngOnInit(): void {
    this.watchActions();
    this.watchAppBarActions();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  cancel() {
    this.appPageService.back();
  }

  delete() {
    this.actionService.delete(this.inputData.dataSource.id);
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);

    if (!this.form.valid) return;

    const dataToSave: BrokerApiKeyEditData = {
      id: this.inputData.dataSource?.id,
      active: this.form.value.active,
      name: this.form.value.name,
      description: this.form.value.description,
    };

    this.actionService.save(dataToSave);
  }

  private watchActions() {
    this.actionService.markCreateAsComplted$
      .pipe(untilDestroyed(this))
      .subscribe((data: CreateBrokerApiKeySuccessData) => this.onCreated(data));
  }

  private onCreated(data: CreateBrokerApiKeySuccessData) {
    BrokerApiKeyDetailsDialogComponent.show(this.dialog, data).subscribe((_) =>
      this.appPageService.back()
    );
  }

  private initForm() {
    this.form = this.facade.initForm();
  }

  private initAppActions() {
    this.facade.initAppBarActions();
  }

  private watchAppBarActions(): void {
    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe((newStatus: string) => {
        const isEnabled =
          newStatus === "VALID" &&
          this.form.dirty &&
          this.inputData.writeAllowed;
        this.appBarActionsService.enable("save", isEnabled);
      });
  }
}
