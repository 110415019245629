<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title">Bank linking info</div>
  </div>

  <mat-card-content class="layout padding-md">
    <div>
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text" mat-line>Link status</span>
          <ifb-bank-account-link-status-icon-chip [status]="entity?.bankLinkStatus">
          </ifb-bank-account-link-status-icon-chip>
        </mat-list-item>
        <div *ngIf="entity?.bankLinkStatusInfo?.length > 0" class="layout padding-bottom-md">
          <mat-list-item *ngFor="let info of entity.bankLinkStatusInfo" class="auto-height">
            {{info}}
          </mat-list-item>
        </div>
        <mat-divider></mat-divider>
        <mat-list-item *ngIf="canReadBankAccountVerificationStatus">
          <span class="secondary-text" mat-line>Verification status</span>
          <ifb-bank-account-verification-icon-chip [status]="entity?.isVerified ? VerificationStatus.Verified : VerificationStatus.Unverified">
          </ifb-bank-account-verification-icon-chip>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Link attempt count</span>
          <p class="item-content">{{entity?.linkingAttemptCount}} </p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Successful link attempt</span>
          <p class="item-content">{{entity?.successfullLinkingAttemptCount}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Last link attempt date</span>
          <p class="item-content">{{entity?.lastLinkingAttemptDate | date: 'short'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Last sync date</span>
          <p class="item-content">{{entity?.bankIntegrationLastSync | date: 'short'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Link reminder count</span>
          <p class="item-content">{{entity?.linkReminderCount}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Link reminder last displayed date</span>
          <p class="item-content">{{entity?.linkReminderLastDisplayedDate | date: 'short'}}</p>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>