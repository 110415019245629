<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <div class="mat-form-field">
        <ifb-business-advisor-input [fc]="form.get('fullName')" [showAvatar]="false"></ifb-business-advisor-input>
      </div>

      <div class="mat-form-field">
        <ifb-advisor-email-input [fc]="form.get('email')"></ifb-advisor-email-input>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>Unique ID</mat-label>
        <input matInput name="uniqueId" formControlName="uniqueId" type="text" placeholder="Unique ID">
        <button type="button" *ngIf="form.controls.uniqueId.value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.controls.uniqueId.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
            <mat-option>All</mat-option>
            <mat-option value="true">Active</mat-option>
            <mat-option value="false">Inactive</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>