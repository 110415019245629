<ifb-card id="leaderboard">
  <mat-card class="ifc-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md padding-bottom-0">
      <div class="card-title">Leaderboard</div>
      <div fxFlex="25">
        <mat-select [(ngModel)]="currentMonth" placeholder="Month" (selectionChange)="filterByMonth($event.value)">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let i of months" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
    </div>
    <mat-card-content class="content">
      <mat-list>
        <mat-list-item *ngFor="let item of data; let i= index;">
          <span mat-list-icon>{{i+1}}</span>
          <ifc-avatar-icon mat-list-icon [data]="item.advisor?.avatar"></ifc-avatar-icon>
          <a mat-line>{{item.totalAmount | currency:'$'}}</a>
          <p mat-line>{{item.advisor?.fullName}}</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ifb-card>
