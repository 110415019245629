import { Component, Input } from '@angular/core';
import { BrokerExData } from '../broker.model';

@Component({
  selector: 'ifb-broker-branding-widget',
  templateUrl: './broker-branding-widget.component.html',
  styleUrls: ['./broker-branding-widget.component.css']
})
export class BrokerBrandingWidgetComponent {

  constructor() { }

  @Input()
  broker: BrokerExData;

  @Input()
  edit: boolean;

}
