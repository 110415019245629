import { NgModule } from '@angular/core';

import { CommonModule } from 'common';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../admin/user/user.module';

import { AdvisorRoutingModule } from './advisor-routing.module';
import { AdvisorListComponent } from './advisor-list/advisor-list.component';
import { AdvisorListFilterDialogComponent } from './advisor-list-filter-dialog/advisor-list-filter-dialog.component';
import { AdvisorEmailInputComponent } from './advisor-email-input/advisor-email-input.component';
import { AdvisorProfileComponent } from './advisor-profile/advisor-profile.component';
import { AdvisorAppBarTitleComponent } from './advisor-app-bar-title/advisor-app-bar-title.component';
import { AdvisorInfoWidgetComponent } from './advisor-info-widget/advisor-info-widget.component';
import { AdvisorInfoDetailsComponent } from './advisor-info-details/advisor-info-details.component';
import { AdvisorUpdateDialogComponent } from './advisor-update-dialog/advisor-update-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        UserModule,
        AdvisorRoutingModule
    ],
    declarations: [
        AdvisorListComponent,
        AdvisorListFilterDialogComponent,
        AdvisorEmailInputComponent,
        AdvisorProfileComponent,
        AdvisorAppBarTitleComponent,
        AdvisorInfoWidgetComponent,
        AdvisorUpdateDialogComponent,
        AdvisorInfoDetailsComponent
    ]
})

export class AdvisorModule { }

