import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AuditTrailComponent } from '../../shared/audit-trail/audit-trail-list/audit-trail.component';
import { AuditTrailFilterService } from '../../shared/audit-trail/audit-trail-filter.services';
import { AuditQueryParams, AuditTrailData } from '../../shared/audit-trail/audit-trail.model';
import { routerTransition } from '../../shared/animations/router.animations';
import { MatDialog } from '@angular/material/dialog';
import { AuditTrailService } from '../../shared/audit-trail/audit-trail.service';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-transaction-audit-trail',
  templateUrl: '../../shared/audit-trail/audit-trail-list/audit-trail.component.html',
  styleUrls: ['../../shared/audit-trail/audit-trail-list/audit-trail.component.scss'],
  animations: routerTransition
})
export class TransactionAuditTrailComponent extends AuditTrailComponent {

  detailTabbed = true;

  constructor(public route: ActivatedRoute,
    public _auditTrailFilterService: AuditTrailFilterService,
    private auditTrailService: AuditTrailService,
    private router: Router,
    public dialog: MatDialog,
    public queryParamsService: QueryParamsService) {
    super(dialog, route, _auditTrailFilterService, queryParamsService);
  }

  download(): void {
    const params = new AuditQueryParams(
      this.queryParamsService.init({
        ...this.queryParams,
        transaction: this.route.snapshot.params.id,
      })
    );
    this.auditTrailService.auditDownload(params);
  }

  accountAuditDetailsLink(audit: AuditTrailData, newWindow: boolean = false) {
    const path = `/audit/${audit.id}`;

    if (newWindow)
      window.open(path);
    else
      this.router.navigate([path]);
  }

}



