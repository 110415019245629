import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterParameter } from 'common';
import { FailureReasonQueryResult, FailureReasonData, FailureReasonFilters, FailureReasonUsageLabel } from '../failure-reason.model';
import { FailureReasonListFilterDialogComponent } from '../failure-reason-list-filter-dialog/failure-reason-list-filter-dialog.component';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { routerTransition } from '../../../shared/animations/router.animations';
import { FailureReasonFilterService } from '../failure-reason-filter.service';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-failure-reason-list',
  templateUrl: './failure-reason-list.component.html',
  styleUrls: ['./failure-reason-list.component.scss'],
  animations: routerTransition,
})
export class FailureReasonListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  deleteSub: Subscription;

  filters: FailureReasonFilters;
  activeFilters: FilterParameter[] = [];

  constructor(
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private failureReasonFilterService: FailureReasonFilterService,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  data: FailureReasonListComponentData;
  readWriteFailureReason: boolean;

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((it: FailureReasonListComponentData) => {
      this.data = it;
    });
    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(it => {
      this.queryParams = this.queryParamsService.init(it);
      this.activeFilters = this.failureReasonFilterService.addFilterParameters(this.queryParams);
    });

    this.userPermissionService.granted([readWrite('admin-failure-reasons')])
      .subscribe(res => this.readWriteFailureReason = res);

    this.filters = new FailureReasonFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if(!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.failureReasonFilterService.filter(this.filters);
    });
  }

  filterDialog() {
    FailureReasonListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.failureReasonFilterService.filter(result);
        }
      });
  }

  sortData(sort: Sort) {
    this.failureReasonFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this.failureReasonFilterService.filter(this.filters, this.paginator, this.sort);
  }

  formatUsage(data: FailureReasonData): string {
    let value = '';
    data.usage.forEach(u => {
        if(value != '')
            value += ', ';

        value += FailureReasonUsageLabel.get(u);
    });

    return value;
}

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  queryParams: any;

  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    'name',
    'code',
    'externalCode',
    'usage',
    'createdBy',
    'createdOn',
    'updatedBy',
    'updatedOn',
    'status',
    'actions'
  ];

}

export interface FailureReasonListComponentData {
  failureReasonQueryResult: FailureReasonQueryResult;
  failureReason: FailureReasonData;
}
