<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title">Status and activation</div>
    <button *ngIf="edit" mat-icon-button routerLink="status" aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-card-content class="layout padding-md">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text" mat-line>Status</span>
          <ifb-account-status-icon-chip [status]="data.account?.status"></ifb-account-status-icon-chip>
        </mat-list-item>
        <mat-divider *ngIf="data.account?.status === loanStatus.Frozen"></mat-divider>
        <mat-list-item *ngIf="data.account?.status === loanStatus.Frozen">
          <span class="secondary-text" mat-line>Frozen until</span>
          <p class="item-content">{{(data.account.frozenUntil | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Delinquent status</span>
          <p class="item-content">{{getDelinquentStatusLabel(data.account?.delinquentStatus)}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Product type</span>
          <p class="item-content">{{data.account.productName}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Created on</span>
          <p class="item-content">{{(data.account.createdOn | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Activation stage</span>
          <p class="item-content">{{getActivationStage(data.account.activationStage)}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Activation date</span>
          <p class="item-content">{{(data.account.activationDate | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Stage change date</span>
          <p class="item-content">{{(data.account.stageChangeDate | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Agreement signature date</span>
          <p class="item-content">{{(data.account.openedOn | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Renewal date</span>
          <p class="item-content">{{(data.account.renewalDate | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Amortization start date</span>
          <p class="item-content">{{(data.account.fullAmortizationStartDateUtc | date : 'mediumDate' ) || '-'}}</p>
        </mat-list-item>
        <mat-divider *ngIf="data.account?.renewedBy"></mat-divider>
        <mat-list-item *ngIf="data.account?.renewedBy">
          <span class="secondary-text" mat-line>Renewed By</span>
          <p class="item-content"><a routerLink="/account/{{data.account?.renewedBy}}/profile">{{data.account?.renewedByLoanNumber}}</a></p>
        </mat-list-item>
        <mat-divider *ngIf="data.account?.renewalFor"></mat-divider>
        <mat-list-item *ngIf="data.account?.renewalFor">
          <span class="secondary-text" mat-line>Renewal For</span>
          <p class="item-content"><a routerLink="/account/{{data.account?.renewalFor}}/profile">{{data.account?.renewalForLoanNumber}}</a></p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Maturity date</span>
          <p class="item-content">{{(data.account?.maturityDate | date) || ''}} -
            {{data.account.repaymentTerm || '-'}} {{data.account.repaymentTerm === 1
            ? 'month' :
            'months'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Updated on</span>
          <p class="item-content">{{(data.account.updatedOn | date) || '-'}}</p>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
