<div class="content layout" fxLayout="column" fxLayoutAlign="center center" fxFlexFill.lt-lg
  ngClass.lt-lg="fit-content">

  <form [formGroup]="form" ngClass.xs="layout fill">

    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Broker ID </mat-label>
          <input matInput type="text" name="brokerId" formControlName="brokerId">
        </mat-form-field>
      </div>

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Type </mat-label>
          <mat-select matInput formControlName="type" placeholder="type">
            <mat-option *ngFor="let option of brokerTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Tier </mat-label>
          <mat-select matInput formControlName="tier" placeholder="tier">
            <mat-option *ngFor="let option of brokerTierOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <ifc-datepicker [fc]="form.get('establishedDate')" [label]="'Established date'" [disabled]="false"></ifc-datepicker>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label> Salesforce account ID </mat-label>
          <input matInput type="text" name="uniqueId" formControlName="uniqueId">
        </mat-form-field>
      </div>

    </div>

  </form>

</div>