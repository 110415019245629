<ifb-card>
  <ng-template #feeTemplate let-name="name" let-value="value" let-type="type">
    <mat-list-item>
      <span class="secondary-text" mat-line>{{name}}</span>
      <p *ngIf="type === FeeType.Flat" class="item-content">
        {{(value | currency:'$') || '-'}}</p>
      <p *ngIf="value && type === FeeType.Percentage" class="item-content">
        {{value | number: '1.2-2'}}%</p>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <span class="secondary-text" mat-line>{{name}} type</span>
      <p class="item-content">
        {{getName(type, FeeType)}}</p>
    </mat-list-item>
  </ng-template>

  <mat-card class="ifc-card">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md padding-bottom-0">
      <div class="card-title">Offer</div>
      <button *ngIf="edit" mat-icon-button routerLink="offer" aria-label="Edit">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <mat-card-content class="layout padding-md padding-top-0">
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">
        <mat-list class="offer-list">
          <!--Limits-->
          <mat-list-item>
            <span *ngIf="!isTermLoan" class="secondary-text" mat-line>Credit limit</span>
            <span *ngIf="isTermLoan" class="secondary-text" mat-line>Term amount</span>
            <p class="item-content">{{(data.account.offer?.amount | currency:'$') || '-'}}</p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngIf="isLCF">
            <span class="secondary-text" mat-line>MSA credit limit</span>
            <p class="item-content">{{(data.account.loanInfo?.msaCreditLimit | currency:'$') || '-'}}</p>
          </mat-list-item>
          <!--End Limits-->

          <mat-divider></mat-divider>

          <!--Rates-->
          <mat-list-item *ngIf="!isTermLoan">
            <span class="secondary-text" mat-line>Annual interest rate</span>
            <p class="item-content">{{(data.account.offer?.interestRate | number:'1.2-2') || '-'}}%</p>
          </mat-list-item>
          <mat-list-item *ngIf="isTermLoan">
            <span class="secondary-text" mat-line>Factor rate</span>
            <p class="item-content">{{(data.account.offer?.interestRate / 100 + 1 | number:'1.4-4') || '-'}}</p>
          </mat-list-item>
          <!--End Rates-->

          <mat-divider></mat-divider>

          <!--Terms-->
          <mat-list-item>
            <span class="secondary-text" mat-line>Repayment term</span>
            <p class="item-content">{{data.account.offer?.repaymentTerm || '-'}} {{data.account.offer?.repaymentTerm ===
              1
              ? 'month' :
              'months'}}</p>
          </mat-list-item>
          <mat-divider *ngIf="!isTermLoan"></mat-divider>
          <mat-list-item *ngIf="!isTermLoan">
            <span class="secondary-text" mat-line>Draw term</span>
            <p class="item-content">{{data.account.offer?.drawTerm || '-'}} {{data.account.offer?.drawTerm === 1
              ? 'month' :
              'months'}}</p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="secondary-text" mat-line>No payment terms</span>
            <p class="item-content" *ngIf="data.account.offer?.noPaymentTerms">
              {{getTermValue(data.account.offer?.paymentFrequency, data.account.offer?.noPaymentTerms) || '-'}}
              {{getTermName(data.account.offer?.paymentFrequency, data.account.offer?.noPaymentTerms)}}
            </p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="secondary-text" mat-line>Interest only payment terms</span>
            <p class="item-content" *ngIf="data.account.offer?.interestOnlyPaymentTerms">
              {{getTermValue(data.account.offer?.paymentFrequency, data.account.offer?.interestOnlyPaymentTerms) || '-'}}
              {{getTermName(data.account.offer?.paymentFrequency, data.account.offer?.interestOnlyPaymentTerms)}}
            </p>
          </mat-list-item>
          <!--End Terms-->

          <mat-divider></mat-divider>

          <!--Payment Frequency-->
          <mat-list-item>
            <span class="secondary-text" mat-line>Payment frequency</span>
            <p class="item-content">{{getNameFromMap(data.account.offer?.paymentFrequency, PaymentFrequencyLabel, PaymentFrequency)}}
            </p>
          </mat-list-item>
          <!--End Payment Frequency-->

          <!-- Calculated Rates-->
          <mat-divider *ngIf="!isTermLoan"></mat-divider>
          <mat-list-item *ngIf="!isTermLoan">
            <span class="secondary-text" mat-line>Daily interest rate</span>
            <p class="item-content">{{(data.account.offer?.dailyInterestRate | number: '1.4-4') || '-'}}%</p>
          </mat-list-item>
          <mat-divider *ngIf="!isTermLoan"></mat-divider>
          <mat-list-item *ngIf="!isTermLoan">
            <span class="secondary-text" mat-line>Weekly interest rate</span>
            <p class="item-content">{{(data.account.offer?.weeklyInterestRate | number: '1.4-4') || '-'}}%</p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="secondary-text" mat-line>Simple interest rate</span>
            <p class="item-content">{{(data.account.offer?.simpleInterestRate | number: '1.4-4') || '-'}}%</p>
          </mat-list-item>
          <!--End Calculated Rates-->

          <mat-divider></mat-divider>

          <!--Fees-->
          <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Draw fee', value: data.account.offer?.drawDownFee, type: data.account.offer?.drawDownFeeType }"></ng-container>
          <mat-divider></mat-divider>
          <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Origination fee', value: data.account.offer?.originationFee, type: data.account.offer?.originationFeeType }"></ng-container>
          <mat-divider></mat-divider>
          <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'UCC filing fee', value: data.account.offer?.uccFilingFee, type: data.account.offer?.uccFilingFeeType }"></ng-container>
          <mat-divider></mat-divider>
          <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Servicing fee', value: data.account.offer?.servicingFee, type: data.account.offer?.servicingFeeType }"></ng-container>
          <mat-divider></mat-divider>
          <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Servicing fee minimum', value: data.account.offer?.servicingFeeMinimum, type: data.account.offer?.servicingFeeMinimumType }"></ng-container>
          <mat-divider></mat-divider>
          <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Stacking fee', value: data.account.offer?.stackingFee, type: data.account.offer?.stackingFeeType }"></ng-container>
          <mat-divider></mat-divider>
          <ng-container *ngTemplateOutlet="feeTemplate; context: { name: 'Maintenance fee', value: data.account.offer?.maintenanceFee, type: data.account.offer?.maintenanceFeeType }"></ng-container>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="secondary-text" mat-line>Maintenance fee frequency</span>
            <p class="item-content">
              {{getNameFromMap(data.account.offer?.maintenanceFeeFrequency, PaymentFrequencyLabel, PaymentFrequency)}}</p>
          </mat-list-item>
          <!--End Fees-->

          <mat-divider></mat-divider>

          <!--Credit Model ID-->
          <mat-list-item>
            <span class="secondary-text" mat-line>Credit model ID</span>
            <p class="item-content">-</p>
          </mat-list-item>
          <!--End Credit Model ID-->
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>
</ifb-card>