import { Component, Input } from '@angular/core';
import { AccountStatementsData } from '../account.model';

@Component({
  selector: 'ifb-account-statements-widget',
  templateUrl: './account-statements-widget.component.html',
  styleUrls: ['./account-statements-widget.component.scss']
})
export class AccountStatementsWidgetComponent {
  @Input()
  data: AccountStatementsData;

  @Input()
  canEditAccountDocumentsGeneration: boolean;

  @Input()
  canViewAccountDocumentsGeneration: boolean;
}
