import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { EnumHelper, TransactionStatus, TransactionType } from 'common';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ifb-transaction-create-dialog',
  templateUrl: './transaction-create-dialog.component.html',
  styleUrls: ['./transaction-create-dialog.component.css']
})
export class TransactionCreateDialogComponent {

  status: string;
  type: string;
  promotions: string;
  competitors: string;

  constructor(public dialogRef: MatDialogRef<TransactionCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.status = EnumHelper.getNameFromValue(TransactionStatus, this.data.form.value.status);
    this.type = EnumHelper.getNameFromValue(TransactionType, this.data.type);
    this.promotions = this.data.promotions;
    this.competitors = this.data.competitors;
  }

  static show(dialog: MatDialog, form: UntypedFormGroup, type: TransactionType, amount: number, promotions?: string, competitors?: string): Observable<any> {
    return dialog.open(TransactionCreateDialogComponent, {
      width: '510px',
      data: {
        form: form,
        type: type,
        amount: amount,
        promotions: promotions,
        competitors
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
