import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import { FailureReasonData, FailureReasonQueryResult, FailureReasonBaseData, FailureReasonQueryParams } from "./failure-reason.model";
import { FailureReasonService } from "./failure-reason.service";

@Injectable({
    providedIn: 'root'
})
export class FailureReasonResolver implements Resolve<FailureReasonData> {
    constructor(private _service: FailureReasonService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FailureReasonData> | Promise<FailureReasonData> | FailureReasonData {
        return route.params.id === 'new'
            ? undefined
            : this._service.get(route.params.id);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FailureReasonQueryResolver implements Resolve<FailureReasonQueryResult> {
    constructor(private _service: FailureReasonService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FailureReasonQueryResult> | Promise<FailureReasonQueryResult> | FailureReasonQueryResult {
        const params = this.queryParamsService.init(route.queryParams) as FailureReasonQueryParams;

        if(params.createdOnStart) {
            params.createdOnStart = new Date(params.createdOnStart);
            params.createdOnStart.setHours(0, 0, 0, 0);
        }

        if(params.createdOnEnd) {
            params.createdOnEnd = new Date(params.createdOnEnd);
            params.createdOnEnd.setHours(23, 59, 59, 999);
        }

        if(params.updatedOnStart) {
            params.updatedOnStart = new Date(params.updatedOnStart);
            params.updatedOnStart.setHours(0, 0, 0, 0);
        }

        if(params.updatedOnEnd) {
            params.updatedOnEnd = new Date(params.updatedOnEnd);
            params.updatedOnEnd.setHours(23, 59, 59, 999);
        }

        return this._service.query(params);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FailureReasonListResolver implements Resolve<FailureReasonBaseData[]> {
    constructor(private _service: FailureReasonService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FailureReasonBaseData[]> | Promise<FailureReasonBaseData[]> | FailureReasonBaseData[] {
        return this._service.list();
    }
}
