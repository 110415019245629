import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifb-account-modification-icon-chip',
  templateUrl: './account-modification-icon-chip.component.html',
  styleUrls: ['./account-modification-icon-chip.component.css']
})
export class AccountModificationIconChipComponent {

  constructor() { }

  @Input()
  flag: any;

  labels = {
    '1': { color: 'mat-orange-bg', icon: 'report', label: 'Current modification' },
    '2': { color: 'mat-orange-bg', icon: 'report', label: 'Past modification' },
    '3': { color: 'mat-orange-bg', icon: 'report', label: 'Future modification' }
  };
}
