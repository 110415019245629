import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppSidenavComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppBarExpandedTabsComponent,
  AppBarActionsComponent
} from 'common';
import { appPageComponentDataDetail, appPageComponentDataMaster } from '../../app.data';
import { UserMenuComponent } from '../../user/user-menu/user-menu.component';
import { AppPageResolver, AppSidenavResolver } from '../../app.resolver';
import { AdminAppBarExpandedTabsResolver } from '../admin.resolver';
import { TenantListComponent } from './tenant-list/tenant-list.component';
import { TenantListResolver, TenantResolver } from './tenant.resolver';
import { TenantDetailsComponent } from './tenant-details/tenant-details.component';
import { FeatureFlagListResolver } from '../feature-flag/feature-flag.resolver';
import { BOAuthGuard } from '../../guards/oauth.guard';

const appPageDataDetail = appPageComponentDataDetail();
const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Admin',
  sideNavItem: 'admin'
});

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [

      {
        path: 'admin/tenants',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedTabsComponent
              }
            ]
          },
          {
            path: '',
            component: TenantListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              tenantListResult: TenantListResolver
            }
          }
        ]
      },
      {
        path: 'admin/tenants/:id',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: TenantDetailsComponent,
            resolve: {
              tenant: TenantResolver,
              featureFlagListResult: FeatureFlagListResolver
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TenantRoutingModule { }
