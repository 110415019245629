<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title">Stage and status</div>
  </div>

  <mat-card-content class="layout padding-md">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text" mat-line>Stage</span>
          <p class="item-content">{{formatStage(data?.application?.stage)}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Salesforce status</span>       
          <ifb-application-status-icon-chip [status]="data?.application?.sfAppStatus"></ifb-application-status-icon-chip>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Type</span>
          <p class="item-content">{{data?.application?.type}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Created on</span>
          <p class="item-content">{{(data?.application?.createdOn | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Created by</span>
          <p class="item-content">{{data?.application?.createdBy}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Updated on</span>
          <p class="item-content">{{(data?.application?.updatedOn | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Updated by</span>
          <p class="item-content">{{ data?.application?.updatedBy}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item *ngIf="data?.application?.offerPageUrl">
          <span class="secondary-text" mat-line><a href="{{data?.application?.offerPageUrl}}" target="_blank">Offer page
              link</a></span>
          <div class="item-content">
            <button mat-icon-button aria-label="Copy" (click)="copy(data?.application?.offerPageUrl)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>