import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EntityStatus } from 'common';
import { EntityStatusLabel } from '../../entity/entity.model';
import { BrokerExData } from '../broker.model';

@Component({
  selector: 'ifb-broker-status-widget',
  templateUrl: './broker-status-widget.component.html',
  styleUrls: ['./broker-status-widget.component.css']
})
export class BrokerStatusWidgetComponent {

  constructor(private router: Router) { }

  @Input()
  broker: BrokerExData;

  @Input()
  edit: boolean;

  formatStatus(status: EntityStatus): string {
    return EntityStatusLabel.get(status);
  }

  detailsLink() {
    this.router.navigate([`broker/${this.broker.id}/status`]);
  }
}
