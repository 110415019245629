<div>
  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="deleteCustomer()" class="secondary-text" matTooltip="Delete">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="deleteCustomer()">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [default]="true">
    <div class="table-container">

      <div class="message-box info"
        *ngIf="data.bankingAccountQueryResult && !data.bankingAccountQueryResult.totalCount" fxLayout="row"
        fxLayoutGap="12px" fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>

      <table aria-label="List of bank accounts" mat-table [ngClass]="{'clickable': canReadBankingTransactions && !onlyPendingAccounts}"
        *ngIf="data.bankingAccountQueryResult && data.bankingAccountQueryResult.totalCount"
        [dataSource]="data.bankingAccountQueryResult.values">
        <ng-container matColumnDef="routingNumber">
          <th mat-header-cell *matHeaderCellDef> Routing number </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <div *ngIf="achNumbersAvailable(row.type)">
              <span *ngIf="!achNumbers[row.id]" class="table-cell" (click)="achNumbersReveal(row)">
                Click to reveal
              </span>
              <span *ngIf="achNumbers[row.id]">
                {{achNumbers[row.id].routingNumber}}
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="accountNumber">
          <th mat-header-cell *matHeaderCellDef> Account number </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{achNumbers[row.id] ? achNumbers[row.id].accountNumber : row.accountNumber}}
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.type}}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="currency">
          <th mat-header-cell *matHeaderCellDef> Currency </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef> Balance </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.balance | currency:'$'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="balanceDate">
          <th mat-header-cell *matHeaderCellDef> Balance date </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.balanceDate | date}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.status}}
          </td>
        </ng-container>
        <ng-container matColumnDef="institutionName">
          <th mat-header-cell *matHeaderCellDef> Institution name </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.institutionName}} </td>
        </ng-container>
        <ng-container matColumnDef="aggregationSuccessDate">
          <th mat-header-cell *matHeaderCellDef> Aggregation success date </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.aggregationSuccessDate | date}} </td>
        </ng-container>
        <ng-container matColumnDef="aggregationAttemptDate">
          <th mat-header-cell *matHeaderCellDef> Aggregation attempt date </th>
          <td mat-cell *matCellDef="let row" (click)="bankTransactionListLink(row)" class="table-cell">
            {{row.aggregationAttemptDate | date}} </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let row" class="table-cell" style="text-align: right">
            <button *ngIf="canWriteBankingAccount && row.status !== BankingAccountStatus.Pending" mat-icon-button
              class="delete-button" (click)="deleteAccount(row.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div
    [hidden]="!data.bankingAccountQueryResult || (data.bankingAccountQueryResult && data.bankingAccountQueryResult.totalCount <= 10)">
    <mat-paginator showFirstLastButtons
      [length]="data.bankingAccountQueryResult ? data.bankingAccountQueryResult.totalCount : 0"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>