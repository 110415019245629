<mat-card class="ifc-card">

  <div class="header">
    <div id="title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="18px" class="layout padding">
      <div class="header-icon">
        <mat-icon>person</mat-icon>
      </div>
      <span class="mat-title">{{data.firstName}} {{data.lastName}}</span>
    </div>
    <button *ngIf="edit" mat-mini-fab class="add-button" color="primary" routerLink="/customer/{{data.id}}"
      aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>email</mat-icon>
        <h4 mat-line>{{data.email}}</h4>
        <p mat-line class="mat-caption">Email</p>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>assignment_ind</mat-icon>
        <h4 mat-line>{{data.ssn}}</h4>
        <p mat-line class="mat-caption">Social security number</p>
      </mat-list-item>
      <mat-list-item class="address-list-item">
        <mat-icon mat-list-icon>location_on</mat-icon>
        <h4 mat-line>{{data.address?.line1}} {{data.address?.line2}}</h4>
        <p mat-line>{{data.address?.city}}, {{data.address?.state}} {{data.address?.zip}}</p>
      </mat-list-item>
      <mat-list-item *ngIf="data.mobilePhone">
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.mobilePhone}}</h4>
        <p mat-line class="mat-caption">Mobile phone - MFA</p>
      </mat-list-item>
      <mat-list-item *ngIf="data.mobilePhoneUnverified">
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.mobilePhoneUnverified}}</h4>
        <p mat-line class="mat-caption">Mobile phone - MFA</p>
        <ifp-customer-phone-status-icon-chip status="unverified"></ifp-customer-phone-status-icon-chip>
      </mat-list-item>
      <mat-list-item *ngIf="data.preferredPhone">
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.preferredPhone}}</h4>
        <p mat-line class="mat-caption">Preferred phone</p>
      </mat-list-item>
      <mat-list-item *ngIf="data.businessPhone">
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.businessPhone}}</h4>
        <p mat-line class="mat-caption">Business phone</p>
      </mat-list-item>
      <mat-list-item *ngIf="data.homePhone">
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.homePhone}}</h4>
        <p mat-line class="mat-caption">Home phone</p>
      </mat-list-item>
      <mat-list-item *ngIf="data.birthdate">
        <mat-icon mat-list-icon>cake</mat-icon>
        <h4 mat-line>{{data.birthdate | date: 'M/d/yyyy' }}</h4>
        <p mat-line class="mat-caption">Birthdate</p>
      </mat-list-item>
      <mat-list-item *ngIf="data.yearOfBarAdmission">
        <mat-icon mat-list-icon>gavel</mat-icon>
        <h4 mat-line>{{data.yearOfBarAdmission}}</h4>
        <p mat-line class="mat-caption">Year of Bar admission</p>
      </mat-list-item>
      <mat-list-item *ngIf="data?.status === CustomerStatus.Pending && canSendEmail">
        <div class="item-content" fxFlexFill>
          <button class="mat-green" mat-button (click)="resendInvitation()">ACTIVATION REMINDER</button>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>

</mat-card>