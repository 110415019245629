import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppSidenavComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppBarActionsComponent,
  AppBarExpandedTabsComponent,
  AppBarExpandedComponent
} from 'common';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../app.data';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import {
  EntityQueryResolver,
  EntityResolver,
  EntityAppBarExpandedTabsResolver,
  EntityAccountsResolver,
  EntityAuditTrailResolver,
  EntityOwnersResolver
} from './entity.resolver';
import { EntityListComponent } from './entity-list/entity-list.component';
import { EntityProfileComponent } from './entity-profile/entity-profile.component';
import { EntityProfileCompanyInfoComponent } from './entity-profile-company-info/entity-profile-company-info.component';
import { EntityAppBarTitleComponent } from './entity-app-bar-title/entity-app-bar-title.component';
import {
  BankAccountListResolverForEntity,
  BankAccountResolverForEntity,
  BankTransactionListResolverForEntity,
  BankAccountAuditTrailResolver
} from '../bank/bank.resolver';
import { BankAccountListComponent } from '../bank/bank-account-list/bank-account-list.component';
import { BankAccountDetailsComponent } from '../bank/bank-account-details/bank-account-details.component';
import { BankAccountTabsComponent } from '../bank/bank-account-tabs/bank-account-tabs.component';
import { BankAccountTransactionListComponent } from '../bank/bank-account-transaction-list/bank-account-transaction-list.component';
import { BOAuthGuard } from '../guards/oauth.guard';
import { EntityAuditTrailComponent } from './entity-audit-trail/entity-audit-trail.component';
import { BankAccountAuditTrailComponent } from '../bank/bank-account-audit-trail/bank-account-audit-trail.component';
import { FinancialSummaryComponent } from '../shared/cash-flow-analysis/financial-summary/financial-summary.component';
import { AccountListComponent } from '../account/account-list/account-list.component';
import { AccountByEntityQueryResolver } from '../account/account.resolver';
import { ApplicationListComponent } from '../application/application-list/application-list.component';
import { ApplicationByEntityQueryResolver } from '../application/application.resolver';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Companies',
  sideNavItem: 'company'
});
const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
      {
        path: 'company',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              }
            ]
          },
          {
            path: '',
            component: EntityListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              entityQueryResult: EntityQueryResolver
            }
          }
        ]
      },
      {
        path: 'company/:id/profile',
        component: AppPageComponent,
        data: appPageDataMaster,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          appBarExpandedTabs: EntityAppBarExpandedTabsResolver,
          entity: EntityResolver,
          owners: EntityOwnersResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: EntityAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: EntityProfileComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      },
      {
        path: 'company/:id/profile/edit',
        component: AppPageComponent,
        data: appPageDataDetail,
        resolve: {
          entity: EntityResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: EntityProfileCompanyInfoComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      },
      {
        path: 'company/:id/accounts',
        component: AppPageComponent,
        data: appPageDataMaster,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          entity: EntityResolver,
          appBarExpandedTabs: EntityAppBarExpandedTabsResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: EntityAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              accountQueryResult: AccountByEntityQueryResolver
            }
          }
        ]
      },
      {
        path: 'company/:id/applications',
        component: AppPageComponent,
        data: appPageDataMaster,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          entity: EntityResolver,
          appBarExpandedTabs: EntityAppBarExpandedTabsResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: EntityAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: ApplicationListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              applicationQueryResult: ApplicationByEntityQueryResolver
            }
          }
        ]
      },
      {
        path: 'company/:id/banks',
        component: AppPageComponent,
        data: appPageDataMaster,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          entity: EntityResolver,
          appBarExpandedTabs: EntityAppBarExpandedTabsResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: EntityAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: BankAccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              bankAccountQueryResult: BankAccountListResolverForEntity
            }
          }
        ]
      },
      {
        path: 'company/:id/audit',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          entity: EntityResolver,
          appBarExpandedTabs: EntityAppBarExpandedTabsResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: EntityAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: EntityAuditTrailComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              auditTrail: EntityAuditTrailResolver
            }
          }
        ]
      },
      {
        path: 'company/:id/banks/:bankAccountId',
        component: AppPageComponent,
        data: {
          appBar: {
            closeAction: 'company',
            expandable: false,
            logoHidden: true,
          },
          appBarActions: appPageDataDetail.appBarActions,
          appPage: {
            contentClass: 'fill',
            contentAlignment: 'start-start',
            contentWithoutPadding: true
          }
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BankAccountTabsComponent,
            data: { viewMode: true },
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'details' },
              {
                path: 'details',
                component: BankAccountDetailsComponent,
                data: { viewMode: true },
                resolve: {
                  bankAccount: BankAccountResolverForEntity,
                  entity: EntityResolver,
                  entityLoans: EntityAccountsResolver
                }
              },
              {
                path: 'transactions',
                component: BankAccountTransactionListComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForEntity,
                  entity: EntityResolver,
                  entityLoans: EntityAccountsResolver,
                  bankTransactionQueryResult: BankTransactionListResolverForEntity
                }
              },
              {
                path: 'audit',
                component: BankAccountAuditTrailComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForEntity,
                  entity: EntityResolver,
                  entityLoans: EntityAccountsResolver,
                  auditTrail: BankAccountAuditTrailResolver
                }
              }
            ]
          }
        ]
      },
      {
        path: 'company/:id/banks/:bankAccountId/edit',
        component: AppPageComponent,
        data: {
          appBar: {
            closeAction: 'company',
            expandable: false,
            logoHidden: true,
          },
          appBarActions: appPageDataDetail.appBarActions,
          appPage: {
            contentClass: 'fill',
            contentAlignment: 'start-start',
            contentWithoutPadding: true
          }
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BankAccountTabsComponent,
            data: { viewMode: true },
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'details' },
              {
                path: 'details',
                component: BankAccountDetailsComponent,
                data: { label: 'DETAILS' },
                resolve: {
                  bankAccount: BankAccountResolverForEntity,
                  entity: EntityResolver,
                  entityLoans: EntityAccountsResolver
                }
              },
              {
                path: 'transactions',
                component: BankAccountTransactionListComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForEntity,
                  entity: EntityResolver,
                  entityLoans: EntityAccountsResolver,
                  bankTransactionQueryResult: BankTransactionListResolverForEntity
                }
              },
              {
                path: 'audit',
                component: BankAccountAuditTrailComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForEntity,
                  entity: EntityResolver,
                  entityLoans: EntityAccountsResolver,
                  auditTrail: BankAccountAuditTrailResolver
                }
              }
            ]
          }
        ]
      },
      {
        path: 'company/:id/bank-analysis',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          entity: EntityResolver,
          appBarExpandedTabs: EntityAppBarExpandedTabsResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: EntityAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: FinancialSummaryComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntityRoutingModule { }
