import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ifb-advisor-app-bar-title',
  templateUrl: './advisor-app-bar-title.component.html',
  styleUrls: ['./advisor-app-bar-title.component.scss']
})
export class AdvisorAppBarTitleComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  title: string;

  constructor(private route: ActivatedRoute,
    private router: Router) {
    this._unsubscribeAll = new Subject();
  }

  backToAdvisorList() {
    this.router.navigateByUrl('/advisor');
  }

  backToAdvisorDetails() {
    this.router.navigateByUrl(`/advisor/${this.data.advisor.id}/profile`);
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  data: any;

  dataSet(data: any) {
    this.data = data;
    this.title = data.advisor ? `${data.advisor.fullName || ''}` : '';
  }
}
