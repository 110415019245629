<ifc-app-scroll-container>
  <div  id="main" fxLayout="column" fxLayoutAlign="center center">
    <form [formGroup]="form" ngClass.xs="layout fill">
      <ifc-app-card>
        <ifb-card>

          <div fxLayout="column" class="app-card-content">

            <ifb-account-status [allowedLoanStatuses]="accountStatusOptions" [fcStatus]="form.get('status')" [fcFreezeForDays]="form.get('freezeForDays')" [fcFrozenUntil]="form.get('frozenUntil')"></ifb-account-status>

            <mat-form-field appearance="outline">
              <mat-label>Delinquent status</mat-label>
              <mat-select matInput formControlName="delinquentStatus" placeholder="Delinquent status">
                <mat-option *ngFor="let option of delinquentStatuses" [value]="option.value">
                  {{option.name | startCase}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Activation stage</mat-label>
              <input matInput formControlName="activationStage" placeholder="Activation stage">
            </mat-form-field>

            <ifc-datepicker [fc]="form.get('createdOn')" [label]="'Created on'" [disabled]="true"></ifc-datepicker>

            <ifc-datepicker [fc]="form.get('agreementSignatureDate')" [label]="'Agreement signature date'"
              [disabled]="true"></ifc-datepicker>

            <ifc-datepicker [fc]="form.get('renewalDate')" [label]="'Renewal date'" [disabled]="true"></ifc-datepicker>

            <ifc-datepicker [fc]="form.get('fullAmortizationStartDateUtc')" [label]="'Amortization start date'" (clearInput)="clear('fullAmortizationStartDateUtc', form)"></ifc-datepicker>

            <ifc-datepicker [fc]="form.get('updatedOn')" [label]="'Updated on'" [disabled]="true"></ifc-datepicker>

          </div>
        </ifb-card>

        <aside class="flex column app-card-aside">
          <ifb-card>
            <h2 class="mat-display-1 layout margin-bottom">Activation log</h2>
            <mat-list>
              <mat-list-item *ngFor="let log of logs">
                <h4 mat-line>{{log.value | date: 'medium'}}</h4>
                <p mat-line>{{formatPascalCase(log.name)}}</p>
              </mat-list-item>
            </mat-list>
          </ifb-card>
        </aside>
      </ifc-app-card>
    </form>
  </div>
</ifc-app-scroll-container>