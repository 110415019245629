import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, } from '@angular/core';
import { Subscription } from 'rxjs';
import { BankAccountQueryResult, BankLinkStatusLabels } from '../bank.model';
import { ObservableHelper, EnumHelper, BankAccount, LinkingStatus, BankAccountRole, BankAccountType, EntityData } from 'common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readOnly, readWrite } from '../../user/user-permission/user-permission.data';
import { BankAccountService } from '../bank.service';
import { AccountData } from '../../account/account.model';
// eslint-disable-next-line max-len
import { BankAccountSetTransferAccountDialogComponent } from '../bank-account-set-transfer-account-dialog/bank-account-set-transfer-account-dialog.component';
import { LoanService } from '../../loan/loan.service';
import { routerTransition } from '../../shared/animations/router.animations';
import * as _ from 'lodash';
import { UserData } from '../../user/user.model';
import { ApplicationData } from '../../application/application.model';
import { BankingService } from '../banking.service';

@Component({
  selector: 'ifb-bank-account-list',
  templateUrl: './bank-account-list.component.html',
  styleUrls: ['./bank-account-list.component.scss'],
  animations: routerTransition
})
export class BankAccountListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private subs: Subscription[] = [];

  data: BankAccountListComponentData;
  dataSource = new MatTableDataSource<BankAccount>();

  canSynchronizeBankAccounts = false;
  showSyncButton = false;
  canWriteBankAccount = false;
  canReadBankAccountVerificationStatus = false;
  entityId = null;

  get BankAccountRole() { return BankAccountRole; }

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private bankAccountService: BankAccountService,
    private bankingService: BankingService,
    private loanService: LoanService,
    private userPermissionService: UserPermissionService) { }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.userPermissionService.granted([readWrite('servicing-synchronize-synced-customer')])
        .subscribe(res => this.canSynchronizeBankAccounts = res),
      this.userPermissionService.granted([readWrite('servicing-bank-accounts')])
        .subscribe(res => this.canWriteBankAccount = res),
      this.userPermissionService.granted([readOnly('servicing-bank-accounts-verification')])
        .subscribe(res => this.canReadBankAccountVerificationStatus = res)
    ];
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    ObservableHelper.unsubscribeAll(this.subs);
  }

  private dataInit(data: BankAccountListComponentData) {
    this.data = data;
    const bankAccounts = this.data && this.data.bankAccountQueryResult ? this.data.bankAccountQueryResult.values : [];
    this.dataSource = new MatTableDataSource(bankAccounts);
    this.entityId = this.data.entity
      ? this.data.entity.id
      : (this.data.account ? this.data.account.entityId : (this.data.application ? this.data.application.entityId : null));
    this.showSyncButton = this.entityId && (this.data.entity && this.data.entity.bankLinkStatus != LinkingStatus.Unlinked) ? true : false;
  }

  bankAccountDetailsLink(id: string) {
    if (this.data.account) {
      if (this.route.snapshot.params.uniqueId)
        // eslint-disable-next-line max-len
        this.router.navigate([`/account/${this.data.account.id}/bank-account/${id}`], { queryParams: { uniqueId: this.route.snapshot.params.uniqueId } });
      else
        this.router.navigate([`/account/${this.data.account.id}/bank-account/${id}`]);
    } else if (this.data.application)
      this.router.navigate([`/application/${this.data.application.id}/bank-account/${id}`]);
    else if (this.data.entity) {
      this.router.navigate([`/company/${this.data.entity.id}/banks/${id}`]);
    } else return;
  }

  bankAccountEditLink(id: string) {
    if (this.data.account) {
      if (this.route.snapshot.params.uniqueId)
        // eslint-disable-next-line max-len
        this.router.navigate([`/account/${this.data.account.id}/bank-account/${id}/edit`, { uniqueId: this.route.snapshot.params.uniqueId }]);
      else
        this.router.navigate([`/account/${this.data.account.id}/bank-account/${id}/edit`]);
    } else if (this.data.application)
      this.router.navigate([`/application/${this.data.application.id}/bank-account/${id}/edit`]);
    else if (this.data.entity)
      this.router.navigate([`/company/${this.data.entity.id}/banks/${id}/edit`]);
    else if (this.data.application)
      this.router.navigate([`/application/${this.data.application.id}/bank-account/${id}`]);
    else return;
  }

  fetchData(entityId: number) {
    this.bankAccountService.getBankAccountList(entityId)
      .subscribe(res => {
        this.dataSource.data = res ? res.values : [];
      });
  }

  sync() {
    if (this.canSynchronizeBankAccounts)
      this.bankingService.synchronizeBankAccounts(this.entityId)
        .subscribe(() => this.fetchData(this.entityId));
  }

  addBankAccountLink() {
    if (this.data.account)
      this.router.navigate([`/account/${this.data.account.id}/bank-account/new`]);
    else if (this.data.entity)
      this.router.navigate([`/company/${this.data.entity.id}/banks/new`]);
    else if (this.data.application)
      this.router.navigate([`/application/${this.data.application.id}/bank-account/new`]);
    else return;
  }

  getBankAccountType(bankAccountType: string): string {
    let bankTypeName = EnumHelper.getNameFromValue(BankAccountType, bankAccountType);
    return bankTypeName ? bankTypeName : bankAccountType;
  }

  setTransferAccount(role: BankAccountRole, bankAccount: BankAccount) {
    const entityId = this.data.entity ? this.data.entity.id : (this.data.account ? this.data.account.entityId : null);
    const type = EnumHelper.getNameFromValue(BankAccountRole, role);
    BankAccountSetTransferAccountDialogComponent.show(this.dialog, type, bankAccount.bankName, bankAccount.accountNumber)
      .subscribe(res => {
        if (res) {
          this.loanService.updateTransferBankAccount(this.data.account.id, bankAccount.id, role)
            .subscribe(() => { this.fetchData(entityId); });
        }
      });
  }

  getRoleIcons(bankAccount: BankAccount): string[] {
    let role = bankAccount.entityRole;

    switch (role) {
      case BankAccountRole.Payment:
        return ["P"];
      case BankAccountRole.Disbursement:
        return ["D"];
      case BankAccountRole.PaymentAndDisbursement:
        return ["P", "D"];
      default:
        return null;
    }
  }

  getBankVerificationAvatarIcon(bankAccount: BankAccount) {
    if (bankAccount.screenShareRequired)
      return 'screen_share';
    else if (bankAccount.isVerified)
      return 'done_all';
    else if (!bankAccount.isVerified)
      return 'error_outline';
  }

  getBankVerificationAvatarClass(bankAccount: BankAccount) {
    if (bankAccount.screenShareRequired)
      return 'avatar medium mat-orange-bg';
    else if (bankAccount.isVerified)
      return 'avatar medium mat-indigo-bg';
    else if (!bankAccount.isVerified)
      return 'avatar medium mat-red-bg';
  }

  getBankVerificationTooltip(bankAccount: BankAccount) {
    if (bankAccount.screenShareRequired)
      return 'Screen share required';
    else if (bankAccount.isVerified)
      return 'Bank account verified';
    else if (!bankAccount.isVerified)
      return 'Bank account unverified';
  }

  getBankLinkingStatusAvatarIcon(bankAccount: BankAccount) {
    if (bankAccount && bankAccount.linkingStatus !== null)
      return BankLinkStatusLabels[bankAccount.linkingStatus].icon;
    else return BankLinkStatusLabels[LinkingStatus.Unlinked].icon;
  }

  getBankLinkingStatusAvatarClass(bankAccount: BankAccount) {
    if (bankAccount && bankAccount.linkingStatus !== null)
      return 'avatar medium ' + BankLinkStatusLabels[bankAccount.linkingStatus].color;
    else return 'avatar medium ' + BankLinkStatusLabels[LinkingStatus.Unlinked].color;
  }

  getBankLinkingStatusTooltip(bankAccount: BankAccount) {
    return !bankAccount || !bankAccount.linkingStatus
      ? _.startCase(EnumHelper.getNameFromValue(LinkingStatus, LinkingStatus.Unlinked))
      : _.startCase(EnumHelper.getNameFromValue(LinkingStatus, +bankAccount.linkingStatus));
  }
  tableColumns = [
    "role",
    "bankLinkingStatus",
    "verificationStatus",
    "bankName",
    "bankAccountType",
    "accountNumber",
    "balance",
    "ownerName",
    "verificationDate",
    "edit",
  ];
}

export interface BankAccountListComponentData {
  bankAccountQueryResult: BankAccountQueryResult;
  account?: AccountData;
  entity?: EntityData;
  userCurrent?: UserData;
  application?: ApplicationData;
}
