import { Component, Input } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { UserService } from '../user.service';
import { UserHintData } from '../user.model';
import { AutocompleteComponent } from 'common';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readOnly } from '../../../user/user-permission/user-permission.data';

@Component({
  selector: 'ifb-user-fullname-input',
  templateUrl: '../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class UserFullNameInputComponent extends AutocompleteComponent {

  constructor(private userService: UserService, private userPermissionService: UserPermissionService) {
    super();
    this.userPermissionService.granted([readOnly('admin-users')])
      .subscribe(res => this.canReadUsers = res);
  }

  canReadUsers = false;

  @Input()
  label: string;

  optionFilter: (input: string | UserHintData) => Observable<any[]> =
    input => this.canReadUsers ? !!input ? this.userService.queryHints({
      fullName: !!input ? (typeof input !== 'string' ? input.fullName : input) : null,
      sortField: 'username'
    }) : EMPTY 
    : !!input ? of([input]) : EMPTY

  optionLabelKey = 'fullName';
}
