import { ProductCode, PromotionType, TransactionTypeString } from "common";

export class PromotionsFilters {
  name: any;
  createdOn: string;
  createdOnFrom: Date;
  createdOnTo: Date;
  startDate: string;
  startDateFrom: Date;
  startDateTo: Date;
  endDate: string;
  endDateFrom: Date;
  endDateTo: Date;
  productCode: ProductCode;
  transactionType: TransactionTypeString;
  promotionType: PromotionType;
  active: boolean;

  constructor(filters?) {
    filters = filters || {};
    this.name = filters.name || undefined;
    this.createdOn = filters.createdOn || undefined;
    this.createdOnFrom = filters.createdOnFrom || undefined;
    this.createdOnTo = filters.createdOnTo || undefined;
    this.startDate = filters.startDate || undefined;
    this.startDateFrom = filters.startDateFrom || undefined;
    this.startDateTo = filters.startDateTo || undefined;
    this.endDate = filters.endDate || undefined;
    this.endDateFrom = filters.endDateFrom || undefined;
    this.endDateTo = filters.endDateTo || undefined;
    this.productCode = filters.productCode || undefined;
    this.transactionType = filters.transactionType || undefined;
    this.promotionType = filters.promotionType || undefined;
    this.active =
      filters.active === "true"
        ? true
        : filters.active === "false"
        ? false
        : undefined;
  }

  remove(item: string) {
    this[item] = undefined;
    if (item === "createdOn" || item === "createdOnFrom") {
      this.createdOn = undefined;
      this.createdOnFrom = undefined;
      this.createdOnTo = undefined;
    } else if (item === "startDate" || item === "startDateFrom") {
      this.startDate = undefined;
      this.startDateFrom = undefined;
      this.startDateTo = undefined;
    } else if (item === "endDate" || item === "endDateFrom") {
      this.endDate = undefined;
      this.endDateFrom = undefined;
      this.endDateTo = undefined;
    }
  }
}
