import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, FilterParameter } from 'common';
import { FailureReasonFilters, FailureReasonQueryParams, FailureReasonUsageLabel } from './failure-reason.model';

@Injectable({
    providedIn: 'root'
})
export class FailureReasonFilterService {

    constructor(
        private router: Router
    ) {
    }

    filter(filters: FailureReasonFilters, paginator?: MatPaginator, sort?: MatSort) {
        const query: FailureReasonQueryParams = filters;

        // Set default to first page
        query.skip = undefined;

        query.createdOnStart = filters.createdOnStart ? moment(filters.createdOnStart).format('L') : undefined;
        query.createdOnEnd = filters.createdOnEnd ? moment(filters.createdOnEnd).format('L') : undefined;
        query.updatedOnStart = filters.updatedOnStart ? moment(filters.updatedOnStart).format('L') : undefined;
        query.updatedOnEnd = filters.updatedOnEnd ? moment(filters.updatedOnEnd).format('L') : undefined;

        if(paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if(sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }

        this.router.navigate(['admin', 'failurereason'], { replaceUrl: true, queryParams: query });

    }

    addFilterParameters(query: FailureReasonQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if(query.code) {
            filters.push({
                id: 'code', name: 'Code', value: query.code.trim()
            });
        }

        if(query.externalCode) {
            filters.push({
                id: 'externalCode', name: 'External Code', value: query.externalCode.trim()
            });
        }

        if(query.name) {
            filters.push({
                id: 'name', name: 'Name', value: query.name.trim()
            });
        }

        if(query.usage) {
            filters.push({
                id: 'usage', name: 'Usage', value: FailureReasonUsageLabel.get(query.usage)
            });
        }

        if(query.createdBy) {
            filters.push({
                id: 'createdBy', name: 'Created by', value: query.createdBy.trim()
            });
        }

        if(query.createdOnStart) {
            filters.push({
                id: 'createdOnStart', name: 'Created on',
                value: `${moment(query.createdOnStart).format('L')} - ${moment(query.createdOnEnd).format('L')}`
            });
        }

        if(query.updatedBy) {
            filters.push({
                id: 'updatedBy', name: 'Updated by', value: query.updatedBy.trim()
            });
        }

        if(query.updatedOnStart) {
            filters.push({
                id: 'updatedOnStart', name: 'Updated on',
                value: `${moment(query.updatedOnStart).format('L')} - ${moment(query.updatedOnEnd).format('L')}`
            });
        }
        
        if (query.active) {
            filters.push({
                id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
            });
        }

        return filters;
    }
}
