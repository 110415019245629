import { Component, OnInit } from '@angular/core';
import { ChartData } from '../../../shared/charts/charts.model';
import * as shape from 'd3-shape';
import * as _ from 'lodash';
import moment from 'moment';
import { TransactionService } from '../../../transaction/transaction.service';
import { TransactionType } from 'common';

@Component({
  selector: 'ifb-total-draws-card-chart',
  templateUrl: './total-draws-card-chart.component.html',
  styleUrls: ['./total-draws-card-chart.component.scss']
})
export class TotalDrawsCardChartComponent implements OnInit {

  chartData: ChartData = {
    view: [400, 330],
    options: {
      showLabels: true,
      animations: true,
      xAxis: true,
      yAxis: true,
      showYAxisLabel: false,
      showXAxisLabel: false,
      timeline: true,
      legend: true,
      legendTitle: 'Requested Date',
      legendPosition: 'below',
      colorScheme: {
        domain: ['#4a78bc', '#1c9b41']
      },
      barPadding: 2,
      curve: shape.curveBasis
    }
  };

  constructor(private _transactionService: TransactionService) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this._transactionService.groupedByYear({
      fromDate: moment().subtract(1, 'years').startOf('year').format('L'),
      type: [TransactionType.Draw],
    }).subscribe((data) => this.chartData.data = _.chain(data)
      .groupBy('year')
      .map((value, key) => ({
        name: key, series: _.map(value, function (item) {
          return { name: moment(item.month, 'MM').format('MMMM'), value: item.totalAmount };
        })
      }))
      .value());
  }

}
