import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable, } from "rxjs";
import {
    BankingAccountQueryResult,
    BankingTransactionQueryResult,
    BankingCustomerQueryResult,
    BankingCustomer,
    BankingAccount
} from "./banking.model";
import { BankingService } from "./banking.service";

@Injectable({
    providedIn: 'root'
})

@Injectable({
    providedIn: 'root'
})
export class BankingCustomerListResolver implements Resolve<BankingCustomerQueryResult> {
    constructor(private service: BankingService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankingCustomerQueryResult> | Promise<BankingCustomerQueryResult> | BankingCustomerQueryResult {
        return this.service.getCustomers(route.params.provider, this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankingCustomerDetailsResolver implements Resolve<BankingCustomer> {
    constructor(private service: BankingService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankingCustomer> | Promise<BankingCustomer> | BankingCustomer {
        return this.service.getCustomer(route.params.provider, route.params.bankCustomerId);
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankingAccountListResolver implements Resolve<BankingAccountQueryResult> {
    constructor(private service: BankingService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankingAccountQueryResult> | Promise<BankingAccountQueryResult> | BankingAccountQueryResult {
        return this.service.getAccounts(route.params.provider, route.params.bankCustomerId, this.queryParamsService.init(route.queryParams));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankingAccountDetailsResolver implements Resolve<BankingAccount> {
    constructor(private service: BankingService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankingAccount> | Promise<BankingAccount> | BankingAccount {
        return this.service.getAccount(route.params.provider, route.params.bankCustomerId, route.params.bankingAccountId);
    }
}

@Injectable({
    providedIn: 'root'
})
export class BankingTransactionListResolver implements Resolve<BankingTransactionQueryResult> {
    constructor(private service: BankingService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankingTransactionQueryResult> | Promise<BankingTransactionQueryResult> | BankingTransactionQueryResult {
        // eslint-disable-next-line max-len
        return this.service.getTransactions(route.params.provider, route.params.bankCustomerId, route.params.bankingAccountId, this.queryParamsService.init(route.queryParams));
    }
}
