<mat-card>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="card-header">
    <span class="card-title">Other info</span>
    <button mat-icon-button matTooltip="Edit" *ngIf="edit" aria-label="Edit"
      routerLink="/customer/{{data.id}}">
      <mat-icon class="secondary-text">edit</mat-icon>
    </button>
  </div>
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>cloud</mat-icon>
        <a mat-line href="{{data.uniqueId | salesforceUrl}}" target="_blank" rel="noopener">{{data.uniqueId}}</a>
        <p mat-line class="mat-caption">Saleforce ID</p>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>phonelink_lock</mat-icon>
        <h4 mat-line>{{data.accountType}}</h4>
        <p mat-line class="mat-caption">Account type</p>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>account_circle</mat-icon>
        <h4 mat-line>{{data.status | enumValueLabel}}</h4>
        <p mat-line class="mat-caption">Customer status</p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>

</mat-card>