import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { UpdateFormFieldType } from "projects/backoffice/src/app/shared/const/enums/update-form-field-type.enum";
import { UpdateFormFieldData } from "projects/backoffice/src/app/shared/const/models/update-form-field-data.model";

export const updatedFieldsData = (
  competitorForm: UntypedFormGroup,
): UpdateFormFieldData[] => {
  if (!competitorForm?.controls) {
    return [];
  }
  const competitor = competitorForm.controls;
  const address = (<FormGroup>competitor.address).controls;
  return [
    {
      label: "Name",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !competitor.name?.pristine,
      value: competitor.name?.value,
    },
    {
      label: "Description",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !competitor.description?.pristine,
      value: competitor.description?.value,
    },
    {
      label: "Api name",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !competitor.apiName?.pristine,
      value: competitor.apiName?.value,
    }, 
    {
      label: "Street address",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !address.line1?.pristine,
      value: address.line1?.value,
    },   
    {
      label: "Unit/Suite/Apt #",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !address.line2?.pristine,
      value: address.line2?.value,
    },   
    {
      label: "City",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !address.city?.pristine,
      value: address.city?.value,
    },   
    {
      label: "State",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !address.state?.pristine,
      value: address.state?.value,
    },  
    {
      label: "Zip code",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !address.zip?.pristine,
      value: address.zip?.value,
    },
    {
      label: "Active",
      fieldType: UpdateFormFieldType.Boolean,
      isChanged: !competitor.active?.pristine,
      value: competitor.active?.value,
    }
  ];
};
