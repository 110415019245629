import { AppBarCloseAction, AppBarExpandedTab, AppSidenavItem } from "common";
import { readOnly, UserPermissionId, UserPermissionData } from './user/user-permission/user-permission.data';

export interface AppPageComponentDataMasterOptions {
    appBarTitle: string;
    appBarTitleHiddenCollapsed?: boolean;
    sideNavItem: string;
}

export function appPageComponentDataMaster(options: AppPageComponentDataMasterOptions) {
    return {
        appBar: {
            expandable: true,
            fixed: true,
            logoHidden: false,
            showDefaultLogo: true,
            titleHiddenCollapsed: options.appBarTitleHiddenCollapsed
        },
        appBarTitle: {
            content: options.appBarTitle
        },
        appSidenavItem: options.sideNavItem
    };
}

export function appPageComponentDataDetail() {
    return {
        appBar: {
            closeAction: AppBarCloseAction.BACK,
            expandable: false,
            logoHidden: true,
        },
        appBarActions: {
            menuMobile: true
        },
        appPage: {
          contentClass: 'simple',
          contentAlignment: 'center-center',
          contentWithoutPadding: true
        }
    };
}

export interface AppBarExpandedTabWithPermission extends AppBarExpandedTab {
  permission?: UserPermissionData[];
  allPermissionsRequired?: boolean;
}
export interface AppSidenavItemWithPermission extends AppSidenavItem {
  permission?: UserPermissionData[];
}

export const appSidenavItems = (selected: string): AppSidenavItemWithPermission[] => [
  {
    url: '/home',
    label: 'Home',
    icon: 'home',
    selected: selected === 'home',
    hidden: false
  },
  {
    url: '/application',
    label: 'Applications',
    icon: 'assignment',
    selected: selected === 'application',
    permission: [readOnly('servicing-applications')],
    hidden: true
  },
  {
    url: '/account',
    query: { active: true },
    label: 'Accounts',
    icon: 'contacts',
    selected: selected === 'account',
    permission: [readOnly('servicing-accounts')],
    hidden: true
  },
  {
    url: '/customer',
    query: { active: true },
    label: 'Customers',
    icon: 'people',
    selected: selected === 'customer',
    permission: [readOnly('servicing-customers')],
    hidden: true
  },
  {
    url: '/company',
    query: { active: true },
    label: 'Companies',
    icon: 'domain',
    selected: selected === 'company',
    permission: [
      readOnly('servicing-entities')
    ],
    hidden: true
  },
  {
    url: '/transactions',
    label: 'Transactions',
    icon: 'list',
    selected: selected === 'transactions',
    permission: [
      readOnly('servicing-transactions')
    ],
    hidden: true
  },
  {
    url: '/broker',
    query: { active: true },
    label: 'Brokers',
    icon: 'person_outline',
    selected: selected === 'broker',
    permission: [
      readOnly('brokers-brokers')
    ],
    hidden: true
  },
  {
    url: '/advisor',
    query: { active: true },
    label: 'Advisors',
    icon: 'perm_contact_calendar',
    selected: selected === 'advisor',
    permission: [readOnly('servicing-advisors')],
    hidden: true
  },
  {
    url: '/admin',
    label: 'Admin',
    icon: 'public',
    selected: selected === 'admin',
    permission: [
      readOnly('admin-clients'),
      readOnly('admin-roles'),
      readOnly('admin-tokens'),
      readOnly('admin-users'),
      readOnly('admin-failure-reasons')
    ],
    hidden: true
  },
  {
    url: '/banking/finicity/customers',
    query: { },
    label: 'Finicity',
    icon: 'account_circle',
    selected: selected === 'finicity',
    permission: [readOnly('servicing-synced-customer')],
    hidden: true
  },
  {
    url: '/banking/plaid/customers',
    query: { },
    label: 'Plaid',
    icon: 'account_circle',
    selected: selected === 'plaid',
    permission: [readOnly('servicing-synced-customer')],
    hidden: true
  },
  {
    url: '/logs',
    query: { active: true },
    label: 'Logs',
    icon: 'dns',
    selected: selected === 'log',
    permission: [readOnly('admin-logs')],
    hidden: true
  }
];
