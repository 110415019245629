import { Injectable } from '@angular/core';
import {
  TransactionQueryParams,
  TransactionQueryResult,
  TransactionData,
  TransactionActionCommand,
  TransactionsGroupedByAdvisor
} from './transaction.model';
import { ResourceService } from 'common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private readonly serviceUrl = this.settings.transaction.url;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private settings: AppSettings) { }

  get(id: number): Observable<TransactionData> {
    return this.http.get<TransactionData>(`${this.serviceUrl}/api/transactions/${id}`);
  }

  query(params: TransactionQueryParams): Observable<TransactionQueryResult> {
    return this.http.get<TransactionQueryResult>(`${this.serviceUrl}/api/transactions/list`, { params: <any>params });
  }

  groupedByAdvisor(params: TransactionQueryParams): Observable<TransactionsGroupedByAdvisor[]> {
    return this.http.get<any>(`${this.serviceUrl}/api/transactions/grouped-by-advisor`, { params: <any>params });
  }

  groupedByYear(params: TransactionQueryParams): Observable<any> {
    return this.http.get<any>(`${this.serviceUrl}/api/transactions/grouped-by-year`, { params: <any>params });
  }

  download(params: TransactionQueryParams) {
    this.resourceService.download(`${this.serviceUrl}/api/transactions/csv`, "transactions.csv", params);
  }

  downloadHistoryUpcoming(loanId: number) {
    this.resourceService.download(`${this.serviceUrl}/api/transactions/loan/${loanId}/history-upcoming-csv`, "full-amortization.csv");
  }

  update(command: TransactionActionCommand): Observable<any> {
    return this.http.post(`${this.serviceUrl}/api/transactions`, command);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.serviceUrl}/api/transactions/${id}`);
  }

  addNotes(command: TransactionActionCommand): Observable<any> {
    return this.http.post(`${this.serviceUrl}/api/transactions/notes`, command);
  }

  getUnprocessedByLoan(loanId: number): Observable<TransactionData[]> {
    return this.http.get<TransactionData[]>(`${this.serviceUrl}/api/transactions/loan/${loanId}/unprocessed-transactions`);
  }
}
