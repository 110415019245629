import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import {
  BrokerPricingPlanLabel,
  BrokerCommissionPlanLabel,
  BrokerType,
  BrokerTypeLabel,
  BrokerTier,
  BrokerTierLabel
} from '../broker.model';
import { EntityStatus } from 'common';
import { EntityStatusLabel } from '../../entity/entity.model';

@Component({
  selector: 'ifb-broker-update-dialog',
  templateUrl: './broker-update-dialog.component.html',
  styleUrls: ['./broker-update-dialog.component.css']
})
export class BrokerUpdateDialogComponent {

  constructor(public dialogRef: MatDialogRef<BrokerUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, form: UntypedFormGroup, legalEntityName: string, title: string): Observable<any> {
    return dialog.open(BrokerUpdateDialogComponent, {
      width: '510px',
      data: {
        form: form,
        legalEntityName: legalEntityName,
        title: title
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

  formatPricingPlan(plan: string): string {
    return BrokerPricingPlanLabel.get(plan);
  }

  formatCommissionPlan(plan: string): string {
    return BrokerCommissionPlanLabel.get(plan);
  }

  formatStatus(status: EntityStatus): string {
    return EntityStatusLabel.get(status);
  }

  formatType(type: BrokerType): string {
    return BrokerTypeLabel.get(type);
  }

  formatTier(tier: BrokerTier): string {
    return BrokerTierLabel.get(tier);
  }
}
