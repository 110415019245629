import { QueryParams, QueryResult, parseNumber } from 'common';
import { UserRoleData } from '../../user/user-role/user-role.data';

export interface UserData {
    clientName?: string;
    username?: string;
    externalId?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    lastLogin?: Date;
    lastLoginAddress?: string;
    active?: boolean;
    roles?: UserRoleData[];
    prefix?: string;
    suffix?: string;
    emails?: string[];
    phones?: string[];
    addresses?: Address[];
}

export interface Address {
    Address?: string;
    City?: string;
    State?: string;
    Zip?: string;
    Country?: string;
}

export class UserQueryParams extends QueryParams {
    clientName?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    lastLoginStart?: any;
    lastLoginEnd?: any;
    externalId?: string;
    active?: boolean;
    roleId?: number;

    constructor(params?: any) {
      super(params);
      params = params || {};
      this.clientName = params.clientName || undefined;
      this.username = params.username || undefined;
      this.firstName = params.firstName || undefined;
      this.lastName = params.lastName || undefined;
      this.lastLoginStart = params.lastLoginStart || undefined;
      this.lastLoginEnd = params.lastLoginEnd || undefined;
      this.externalId = params.externalId || undefined;
      this.active = params.active || undefined;
      this.roleId = parseNumber(params.roleId) || undefined;
  }
}

export interface UserQueryResult extends QueryResult<UserData> { }

export class UserFilters {
    clientName: string;
    username: any;
    firstName: string;
    lastName: string;
    lastLogin: string;
    lastLoginStart: Date;
    lastLoginEnd: Date;
    externalId: string;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.clientName = filters.clientName || undefined;
        this.username = filters.username || undefined;
        this.firstName = filters.firstName || undefined;
        this.lastName = filters.lastName || undefined;
        this.lastLogin = filters.lastLogin || undefined;
        this.lastLoginStart = filters.lastLoginStart || undefined;
        this.lastLoginEnd = filters.lastLoginEnd || undefined;
        this.externalId = filters.externalId || undefined;
        this.active = filters.active || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'lastLogin' || item === 'lastLoginStart') {
            this.lastLogin = undefined;
            this.lastLoginStart = undefined;
            this.lastLoginEnd = undefined;
        }
    }
}

export interface UserHintData {
    id: number;
    fullName: string;
    email: string;
}

export interface UserHintParams {
    fullName?: string;
    email?: string;
    sortField?: string;
}
