import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { EntityStatus, FilterRange, EnumHelper, ObjectHelper } from 'common';
import { BrokerFilters, BrokerType, BrokerQueryParams, BrokerTypeLabel, BrokerTier, BrokerTierLabel, BrokerPricingPlan, BrokerPricingPlanLabel } from '../broker.model';
import { EntityStatusLabel } from '../../entity/entity.model';

@Component({
  selector: 'ifb-broker-list-filter-dialog',
  templateUrl: './broker-list-filter-dialog.component.html',
  styleUrls: ['./broker-list-filter-dialog.component.scss']
})
export class BrokerListFilterDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    
    form: UntypedFormGroup;
    filters: BrokerFilters;
    brokerTypes = EnumHelper.getMappedNamesAndValues(BrokerType, BrokerTypeLabel).filter(e => e.value != BrokerType.Unknwon);
    entityStatuses = EnumHelper.getMappedNamesAndValues(EntityStatus, EntityStatusLabel);
    brokerPricingPlans = EnumHelper.getMappedNamesAndValues(BrokerPricingPlan, BrokerPricingPlanLabel);
    brokerTiers = EnumHelper.getMappedNamesAndValues(BrokerTier, BrokerTierLabel);
    
    constructor(
        public dialogRef: MatDialogRef<BrokerListFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: UntypedFormBuilder) {
            
        this._unsubscribeAll = new Subject();
        this.filters = new BrokerFilters(data.query);
      
        this.form = this._formBuilder.group({
            uniqueId: this.data.query.uniqueId || '',
            advisorId: this.data.query.advisorId || '',
            types: { value: ObjectHelper.makeArray(this.data.query.types) || '', disabled: false },
            statuses: { value: ObjectHelper.makeArray(this.data.query.statuses) || '', disabled: false },
            name: this.data.query.name || '',
            dba: this.data.query.dba || '',
            ein: this.data.query.ein || '',
            contactEmail: this.data.query.contactEmail || '',
            commissionEmail: this.data.query.commissionEmail || '',
            webSite: this.data.query.webSite || '',
            phone: this.data.query.phone || '',
            establishedDate: this.data.query.establishedDate || '',
            establishedDateStart: new UntypedFormControl(this.data.query.establishedDateStart ? new Date(this.data.query.establishedDateStart) : '', { validators: null, updateOn: 'blur' }),
            establishedDateEnd: new UntypedFormControl(this.data.query.establishedDateEnd ? new Date(this.data.query.establishedDateEnd) : '', { validators: null, updateOn: 'blur' }),
            contractDate: this.data.query.contractDate || '',
            contractDateStart: new UntypedFormControl(this.data.query.contractDateStart ? new Date(this.data.query.contractDateStart) : '', { validators: null, updateOn: 'blur' }),
            contractDateEnd: new UntypedFormControl(this.data.query.contractDateEnd ? new Date(this.data.query.contractDateEnd) : '', { validators: null, updateOn: 'blur' }),
            pricingPlans: { value: ObjectHelper.makeArray(this.data.query.pricingPlans) || '', disabled: false },
            tiers: { value: ObjectHelper.makeArray(this.data.query.tiers) || '', disabled: false },
            createdBy: this.data.query.createdBy || '',
            createdOn: this.data.query.createdOn || '',
            createdOnStart: new UntypedFormControl(this.data.query.createdOnStart ? new Date(this.data.query.createdOnStart) : '', { validators: null, updateOn: 'blur' }),
            createdOnEnd: new UntypedFormControl(this.data.query.createdOnEnd ? new Date(this.data.query.createdOnEnd) : '', { validators: null, updateOn: 'blur' }),
            updatedBy: this.data.query.updatedBy || '',
            updatedOn: this.data.query.updatedOn || '',
            updatedOnStart: new UntypedFormControl(this.data.query.updatedOnStart ? new Date(this.data.query.updatedOnStart) : '', { validators: null, updateOn: 'blur' }),
            updatedOnEnd: new UntypedFormControl(this.data.query.updatedOnEnd ? new Date(this.data.query.updatedOnEnd) : '', { validators: null, updateOn: 'blur' }),
            active: this.data.query.active || '',
        });
    }
    
    static show(dialog: MatDialog, query: BrokerQueryParams): Observable<any> {
        return dialog.open(BrokerListFilterDialogComponent, {
            data: { query },
            closeOnNavigation: true,
        }).afterClosed();
    }

    ngOnInit(): void {
        this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            this.filters = new BrokerFilters(data);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }



    establishedDateSelected(dataRange: FilterRange<moment.Moment>) {
        this.form.patchValue({
            establishedDateStart: dataRange.from,
            establishedDateEnd: dataRange.to
        });
    }

    contractDateSelected(dataRange: FilterRange<moment.Moment>) {
        this.form.patchValue({
            contractDateStart: dataRange.from,
            contractDateEnd: dataRange.to
        });
    }

    createdDateSelected(dataRange: FilterRange<moment.Moment>) {
        this.form.patchValue({
            createdOnStart: dataRange.from,
            createdOnEnd: dataRange.to
        });
    }

    updatedDateSelected(dataRange: FilterRange<moment.Moment>) {
        this.form.patchValue({
            updatedOnStart: dataRange.from,
            updatedOnEnd: dataRange.to
        });
    }
}
