<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <form [formGroup]="form">
    <ifc-app-card>
      <ifb-card>
        <div class="flex column app-card-content">

          <mat-form-field *ngIf="data.token && data.token.id" appearance="outline">
            <mat-label>Client</mat-label>
            <input matInput formControlName="client" [readonly]="true" type="text" placeholder="Client">
          </mat-form-field>

          <mat-form-field *ngIf="data.clients && (!data.token || !data.token.id)" appearance="outline">
            <mat-label>Client</mat-label>
            <mat-select matInput formControlName="client" placeholder="Client">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let client of data.clients" [value]="client.id">{{client.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" type="text" placeholder="Description" specialIsAlphaNumeric
              [readonly]="!readWriteToken">
          </mat-form-field>

          <ifc-datepicker [fc]="form.get('expiresAt')" [label]="'Expiration date'"
            [disabled]="data.token && data.token.id" [min]="minExpirationDate"></ifc-datepicker>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select matInput formControlName="active" placeholder="Status" [disabled]="!readWriteToken">
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Inactive</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="flex row end-center">
            <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
          </div>
        </div>
      </ifb-card>

      <aside class="flex column app-card-aside">
        <ifb-card>
          <h2 class="mat-display-1 layout margin-bottom">Token</h2>

          <mat-form-field appearance="outline" class="layout margin-top">
            <input #token matInput formControlName="token" type="text" [readonly]="true">
          </mat-form-field>

          <div class="flex row end-center">
            <button mat-button type="button" [disabled]="!form.get('token').value" (click)="copy(token)">Copy</button>
          </div>

          <div *ngIf="data.token && data.token.createdBy" class="layout padding-top">
            <div class="value">{{data.token.createdBy}}</div>
            <p class="label">Created by</p>
          </div>

          <div *ngIf="data.token && data.token.createdOn" class="layout">
            <div class="value">{{data.token.createdOn | date: 'medium'}}</div>
            <p class="label">Created on</p>
          </div>
        </ifb-card>
      </aside>
    </ifc-app-card>
  </form>
</ifc-app-scroll-container>