import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { Sort, MatSort } from "@angular/material/sort";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Location } from '@angular/common';
import { routerTransition } from "../../shared/animations/router.animations";
import { UserPermissionService } from "../../user/user-permission/user-permission.service";
import { readWrite } from "../../user/user-permission/user-permission.data";
import {
    BrokerFilters,
    BrokerQueryResult,
    BrokerTypeLabel,
    BrokerType,
    BrokerTier,
    BrokerTierLabel,
    BrokerListItemData
} from "../broker.model";
import { BrokerFilterService } from "../broker-filter.service";
import { BrokerListFilterDialogComponent } from "../broker-list-filter-dialog/broker-list-filter-dialog.component";
import { BrokerService } from "../broker.service";
import { AppSettings } from "../../../app.settings";
import { EntityStatus, FilterParameter } from "common";
import { EntityStatusLabel } from "../../entity/entity.model";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Component({
    selector: 'ifb-broker-list',
    templateUrl: './broker-list.component.html',
    styleUrls: ['./broker-list.component.scss'],
    animations: routerTransition,
})
export class BrokerListComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    deleteSub: Subscription;
    filters: BrokerFilters;
    activeFilters: FilterParameter[] = [];
    queryParams: any;
    data: BrokerListComponentData;
    readWriteBroker: boolean;

    tableColumns: string[] = [
        'name',
        'brokerId',
        'type',
        'status',
        'tier',
        'usersCount',
        'applicationsCount',
        'contactEmail',
        'phone',
        'advisorName',
        'commission.initialDrawCommission',
        'commission.residualDrawCommission',
        'createdBy',
        'createdOn',
        'updatedBy',
        'updatedOn',
        'uniqueId',
        'actions'
    ];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    constructor(
        private _route: ActivatedRoute,
        private _userPermissionService: UserPermissionService,
        private _brokerService: BrokerService,
        private _dialog: MatDialog,
        private _brokerFilterService: BrokerFilterService,
        private _location: Location,
        private _router: Router,
        private _settings: AppSettings,
        private queryParamsService: QueryParamsService) {

        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this._route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((it: BrokerListComponentData) => this.data = it);

        this._route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(it => {
            this.queryParams = this.queryParamsService.init(it);
            this.activeFilters = this._brokerFilterService.addFilterParameters(this.queryParams);
        });

        this._userPermissionService.granted([readWrite('brokers-brokers')]).subscribe(res => this.readWriteBroker = res);

        this.filters = new BrokerFilters(this.queryParams);
        this.paginator.pageSize = this.queryParams.limit;
        this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    activeFilterRemoved(activefilters: FilterParameter[]): void {
        if (!activefilters.length) return;

        activefilters.forEach(item => {
            this.filters.remove(item.id);
            this._brokerFilterService.filter(this.filters);
        });
    }

    formatType(type: BrokerType): string {
        return BrokerTypeLabel.get(type);
    }

    formatStatus(status: EntityStatus): string {
        return EntityStatusLabel.get(status);
    }

    formatTier(tier: BrokerTier): string {
        return BrokerTierLabel.get(tier);
    }

    salesforceLink(salesforceId: string) {
        return this._settings.salesforce.url + salesforceId;
    }

    brokerDetailsLink(broker: BrokerListItemData, newWindow?: boolean) {
        this._brokerService.prevBrokerListUrl = this._location.path().toString();
        const path = `/broker/${broker.id}/profile`;
        if (newWindow)
            window.open(path);
        else
            this._router.navigate([path]);
    }

    filterDialog() {
        BrokerListFilterDialogComponent
            .show(this._dialog, this.queryParams)
            .subscribe(result => {
                if (result) {
                    this.filters = result;
                    this.paginator.firstPage();
                    this._brokerFilterService.filter(result);
                }
            });
    }

    download(params: any) {
        this._brokerService.download(params);
    }

    sortData(sort: Sort) {
        this._brokerFilterService.filter(this.filters, this.paginator, this.sort);
    }

    setPage(event: PageEvent) {
        this._brokerFilterService.filter(this.filters, this.paginator, this.sort);
    }
}

export interface BrokerListComponentData {
    brokerQueryResult: BrokerQueryResult;
}
