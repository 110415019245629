import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableHelper, FilterParameter } from 'common';
import { CustomerQueryParams, CustomerFilters } from './customer.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerFilterService {

  constructor(
    private router: Router
  ) { }

  filter(filters: CustomerFilters, paginator?: MatPaginator, sort?: MatSort) {
    const query: CustomerQueryParams = filters;
    query.email = typeof query.email === 'object' ? filters.email.email : query.email;
    query.loanNumber = typeof query.loanNumber === 'object' ? filters.loanNumber.loanNumber : query.loanNumber;
    query.businessName = typeof query.businessName === 'object' ? filters.businessName.businessName : query.businessName;
    query.active = typeof query.active === 'object' ? filters.active.active : query.active;
    query.ssn = typeof query.ssn === 'object' ? filters.ssn.ssn : query.ssn;

    // Set default to first page
    query.skip = undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    this.router.navigate(['customer'], { replaceUrl: true, queryParams: query });
  }

  addFilterParameters(query: CustomerQueryParams): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];

    if (query.loanNumber) {
      filters.push({
        id: 'loanNumber', name: 'Loan number', value: query.loanNumber.trim()
      });
    }

    if (query.businessName) {
      filters.push({
        id: 'businessName', name: 'Legal entity name', value: query.businessName.trim()
      });
    }
    
    if (query.email) {
      filters.push({
        id: 'email', name: 'Email', value: query.email.trim()
      });
    }

    if (query.ssn) {
      filters.push({
        id: 'ssn', name: 'SSN', value: query.ssn.trim()
      });
    }

    if (query.firstName) {
      filters.push({
        id: 'firstName', name: 'First Name', value: query.firstName.trim()
      });
    }

    if (query.lastName) {
      filters.push({
        id: 'lastName', name: 'Last name', value: query.lastName.trim()
      });
    }

    if (query.active) {
      filters.push({
          id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
      });
  }

    return filters;
  }
}
