<ifc-app-scroll-container>

  <div id="main" fxLayout="column" fxLayoutAlign="start center">

    <mat-horizontal-stepper #horizontalStepper (selectionChange)="onStepChange($event)" *ngIf="!verticalLayout">
      <mat-step>
        <ng-template matStepLabel>Modifications</ng-template>
        <ng-container *ngTemplateOutlet="modifications"></ng-container>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Loan</ng-template>
        <ng-container *ngTemplateOutlet="loan"></ng-container>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Summary</ng-template>
        <ng-container *ngTemplateOutlet="summary"></ng-container>
      </mat-step>
    </mat-horizontal-stepper>

    <mat-vertical-stepper #verticalStepper (selectionChange)="onStepChange($event)" *ngIf="verticalLayout">
      <mat-step>
        <ng-template matStepLabel>Modifications</ng-template>
        <ng-container *ngTemplateOutlet="modifications"></ng-container>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Loan</ng-template>
        <ng-container *ngTemplateOutlet="loan"></ng-container>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Summary</ng-template>
        <ng-container *ngTemplateOutlet="summary"></ng-container>
      </mat-step>
    </mat-vertical-stepper>

    <ng-template #modifications>
      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center start" fxLayoutGap="24px"
        class="layout padding-top">

        <form [formGroup]="modificationForm">
          <div fxLayout.lt-md="column" fxLayoutGap.gt-md="24px" fxLayout="row" fxLayoutAlign="start start">

            <ifb-card>
              <div fxLayout="column" fxLayoutAlign="start start">

                <mat-form-field appearance="outline">
                  <mat-label> Name </mat-label>
                  <input matInput type="text" name="name" formControlName="name">
                </mat-form-field>

                <ifc-datepicker class="form-field-custom-xs" [fc]="modificationForm.get('startDate')"
                  [label]="'Start date'" (clearInput)="clear('startDate', modificationForm)">
                </ifc-datepicker>
                <div class="help-text">Leave blank if the modification starts right away</div>

                <ifc-datepicker class="form-field-custom-xs" [fc]="modificationForm.get('endDate')"
                  [min]="modificationForm.value.startDate || null" [label]="'End date'"
                  (clearInput)="clear('endDate', modificationForm)" (blured)="validateEndDate()">
                </ifc-datepicker>
                <div class="help-text">Leave blank if the modification doesn’t have an end date</div><br />
                <div *ngIf="warning" class="help-text" style="text-align: left;">{{warning}}</div>

                <mat-form-field appearance="outline">
                  <mat-label> Auto-payment mode </mat-label>
                  <mat-select name="autoPaymentMode" formControlName="autoPaymentMode">
                    <mat-option [value]=""> Do not override </mat-option>
                    <mat-option *ngFor="let option of autoPaymentModeOptions" [value]="option.value">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="help-text">Current auto-payment mode: {{getAutoPaymentMode(data.account.autoPayMode)}}</div>

                <mat-form-field
                  *ngIf="this.modificationForm.get('autoPaymentMode').value === AutoPaymentMode.FixedAmount"
                  appearance="outline">
                  <mat-label> Fixed payment amount </mat-label>
                  <input matInput type="text" currencyMask [options]="{precision: 2, suffix: '', prefix: '$'}"
                    name="fixedPaymentAmount" formControlName="fixedPaymentAmount">
                  <button type="button" *ngIf="modificationForm.get('fixedPaymentAmount').value" mat-button matSuffix
                    mat-icon-button aria-label="Clear"
                    (click)="modificationForm.get('fixedPaymentAmount').setValue(undefined)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label> Accrue interest </mat-label>
                  <mat-select name="accrueInterest" formControlName="accrueInterest">
                    <mat-option [value]=""> Do not override </mat-option>
                    <mat-option [value]="true"> Accrue interest </mat-option>
                    <mat-option [value]="false"> Do not accrue interest </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="help-text">Current accrue interest setting:
                  {{getAccrueInterest(data.account.accrueInterest)}}</div>

                <mat-checkbox color="primary" name="active" formControlName="active">Active</mat-checkbox>

              </div>
            </ifb-card>

            <ifb-card>
              <div fxLayout="column" fxLayoutAlign="start start">

                <mat-form-field appearance="outline">
                  <mat-label> Rate </mat-label>
                  <input matInput type="text" currencyMask [options]="{precision: 2, suffix: '%', prefix: ''}"
                    name="interestRate" formControlName="interestRate">
                  <button type="button" *ngIf="modificationForm.get('interestRate').value" mat-button matSuffix
                    mat-icon-button aria-label="Clear"
                    (click)="modificationForm.get('interestRate').setValue(undefined)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div class="help-text">Current rate: {{data.account.interestRate | number: '1.2-2'}}%</div>

                <mat-form-field appearance="outline">
                  <mat-label> Draw fee </mat-label>
                  <input matInput *ngIf="modificationForm.get('drawFeeType').value !== FeeType.Flat" type="text"
                    currencyMask [options]="{precision: 2, suffix: '%', prefix: ''}" name="drawFee"
                    formControlName="drawFee">
                  <input matInput *ngIf="modificationForm.get('drawFeeType').value === FeeType.Flat" type="text"
                    currencyMask name="drawFee" formControlName="drawFee">
                  <button type="button" *ngIf="modificationForm.get('drawFee').value" mat-button matSuffix
                    mat-icon-button aria-label="Clear" (click)="modificationForm.get('drawFee').setValue(undefined)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div class="help-text">Current draw fee:
                  <span
                    *ngIf="data.account.drawDownFeeType !== FeeType.Flat">{{data.account.drawDownFee | number: '1.2-2'}}%</span>
                  <span *ngIf="data.account.drawDownFeeType === FeeType.Flat">{{data.account.drawDownFee}}</span>
                </div>

                <mat-form-field appearance="outline">
                  <mat-label> Draw fee type </mat-label>
                  <mat-select name="drawFeeType" formControlName="drawFeeType">
                    <mat-option [value]=""> Do not override </mat-option>
                    <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="help-text">Current draw fee type: {{getFeeTypeFromValue(data.account.drawDownFeeType)}}
                </div>

                <mat-form-field appearance="outline">
                  <mat-label> Maintenance fee </mat-label>
                  <input matInput *ngIf="modificationForm.get('maintenanceFeeType').value !== FeeType.Flat" type="text"
                    currencyMask [options]="{precision: 2, suffix: '%', prefix: ''}" name="maintenanceFee"
                    formControlName="maintenanceFee">
                  <input matInput *ngIf="modificationForm.get('maintenanceFeeType').value === FeeType.Flat" type="text"
                    currencyMask name="maintenanceFee" formControlName="maintenanceFee">
                  <button type="button" *ngIf="modificationForm.get('maintenanceFee').value" mat-button matSuffix
                    mat-icon-button aria-label="Clear"
                    (click)="modificationForm.get('maintenanceFee').setValue(undefined)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div class="help-text">Current maintenance fee:
                  <span
                    *ngIf="data.account.maintenanceFeeType !== FeeType.Flat">{{data.account.maintenanceFee | number: '1.2-2'}}%</span>
                  <span *ngIf="data.account.maintenanceFeeType === FeeType.Flat">{{data.account.maintenanceFee}}</span>
                </div>

                <mat-form-field appearance="outline">
                  <mat-label> Maintenance fee type </mat-label>
                  <mat-select name="maintenanceFeeType" formControlName="maintenanceFeeType">
                    <mat-option [value]=""> Do not override </mat-option>
                    <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="help-text">Current maintenance fee type:
                  {{getFeeTypeFromValue(data.account.maintenanceFeeType)}}</div>

              </div>
            </ifb-card>

          </div>
        </form>

        <div fxLayout.gt-md="column" fxLayoutAlign.gt-md="start start" fxLayoutGap.gt-md="32px" fxLayout="column"
          fxLayoutAlign="start center">

          <ifb-card>
            <mat-card class="mat-elevation-z0 width-100-perc gray-card">
              <mat-card-content>
                <mat-list>
                  <mat-list-item>
                    <span class="item-title" mat-line> Current payment amount </span>
                    <span class="item-content"> {{currentPaymentAmount | currency:'$'}} </span>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <mat-list-item>
                    <span class="item-title" mat-line> Payment due date </span>
                    <span class="item-content"> {{(currentPaymentDueDate | date) || 'Not Due'}} </span>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <mat-list-item>
                    <span class="item-title" mat-line> Updated on </span>
                    <span class="item-content"> {{(modification?.updatedOn | date) || '-'}} </span>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <mat-list-item>
                    <span class="item-title" mat-line> Updated by </span>
                    <span class="item-content"> {{(modification?.updatedBy?.FullName) || '-'}} </span>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <mat-list-item>
                    <span class="item-title" mat-line> Activated on </span>
                    <span class="item-content"> {{(modification?.activatedOn | date) || '-'}} </span>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
          </ifb-card>

          <ifb-card>
            <ifb-note-card [fc]="modificationForm.get('note')" [notes]="notesSnapshot" [initiator]="data.userCurrent"
              [ngClass.lt-lg]="'layout margin-top'">
            </ifb-note-card>
          </ifb-card>

        </div>
      </div>
    </ng-template>

    <ng-template #loan>
      <div fxLayout="column" fxLayoutGap="24px" class="layout padding-top">
        <form [formGroup]="loanForm">
          <div id="loan-content" fxLayout="column">

            <ifb-account-status [allowedLoanStatuses]="statusOptions" [fcStatus]="loanForm.get('loanStatus')" [fcFreezeForDays]="loanForm.get('freezeForDays')" [fcFrozenUntil]="loanForm.get('frozenUntil')"></ifb-account-status>

            <div fxLayout="row" fxLayoutAlign="start start" class="layout margin-bottom-md">
              <mat-checkbox color="primary" formControlName="reAmortized">Re-amortize</mat-checkbox>
            </div>

            <div *ngIf="loanForm.value.reAmortized">
              <mat-form-field appearance="outline">
                <mat-label>Repayment term</mat-label>
                <input matInput type="text" formControlName="repaymentTerm" numbersOnly>
              </mat-form-field>
              <div class="help-text">Current repayment term:
                {{data.account.repaymentTerm}}</div>
            </div>

          </div>
        </form>
      </div>
    </ng-template>

    <ng-template #summary>
      <div id="summary-content" fxLayout="column" fxLayoutGap="48px">

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center" fxLayoutGap="24px">

          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="28px" fxFlex="50">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
              <span class="green-label-lg">{{newPaymentDue | currency:'$'}}</span>
              <span class="item-title">Next payment due</span>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="4px">
              <span>{{newPaymentDueDate | date:'shortDate'}}</span>
              <span class="item-title">Next payment due date</span>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="4px"
              *ngIf="loanForm.value.reAmortized && loanForm.value.repaymentTerm">
              <span>{{numberOfPaymentsLeft}}</span>
              <span class="item-title">Number of payments left</span>
            </div>
          </div>

          <mat-card fxFlex="50" class="mat-elevation-z0 width-100-perc gray-card">
            <mat-card-header>
              <span class="card-title">Modifications</span>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <span class="item-title" mat-line> Start date </span>
                  <span class="item-content"> {{(modificationForm.value.startDate | date) || '-'}} </span>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <span class="item-title" mat-line> End date </span>
                  <span class="item-content"> {{(modificationForm.value.endDate | date) || '-'}} </span>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                  <span class="item-title" mat-line> Auto-payment mode </span>
                  <span class="item-content"> {{getAutoPaymentMode(modificationForm.value.autoPaymentMode) || '-'}}
                  </span>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>

        </div>

        <mat-card id="upcoming-payments-card" class="mat-elevation-z4">
          <mat-card-header>
            <span class="card-title">Upcoming payments</span>
          </mat-card-header>
          <mat-card-content>
            <mat-tab-group #tabGroup>
              <mat-tab label="MODIFIED">
                <ng-template matTabContent>
                  <ifc-app-scroll-container>
                    <ifb-account-payment-list [paginator]="paginatorModified" [data]="newAccountAmortizationInfo">
                    </ifb-account-payment-list>
                  </ifc-app-scroll-container>
                  <mat-paginator #paginatorModified showFirstLastButtons [pageSizeOptions]="[10, 20, 50]">
                  </mat-paginator>
                </ng-template>
              </mat-tab>
              <mat-tab label="CURRENT">
                <ng-template matTabContent>
                  <ifc-app-scroll-container>
                    <ifb-account-payment-list [paginator]="paginatorCurrent" [data]="data.accountAmortizationInfo">
                    </ifb-account-payment-list>
                  </ifc-app-scroll-container>
                  <mat-paginator #paginatorCurrent showFirstLastButtons [pageSizeOptions]="[10, 20, 50]">
                  </mat-paginator>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </mat-card-content>
        </mat-card>

      </div>
    </ng-template>

  </div>

</ifc-app-scroll-container>
