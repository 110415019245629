import { Component, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Note, InitiatorData, NoteData } from '../note.model';

@Component({
  selector: 'ifb-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss']
})
export class NoteCardComponent implements OnChanges {

  private _notes: Note[];

  public addedNotes: Note[] = [];

  @Input()
  set notes(notes: Note[]) { this._notes = notes; }

  @Input()
  initiator: any;

  @Input()
  disabled: boolean;

  @Input()
  hideButtons: boolean;

  @Input()
  fc: UntypedFormControl;

  @Input()
  showNotes = true;

  @Input()
  saveOnDemand: boolean;

  @Output()
  save = new EventEmitter<any>();

  rows = 1;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled)
      this.fc.disable();
    else
      this.fc.enable();
  }

  get notes() {
    return this.addedNotes.concat(this._notes ? this._notes : []);
  }

  addNote() {
    if (!this.initiator.firstName || !this.initiator.lastName)
      return;

    const text = this.fc.value;
    const fullName = `${this.initiator.firstName} ${this.initiator.lastName}`;
    const initiator = new InitiatorData(fullName);
    const note: Note = new NoteData(text, initiator);
    this.addedNotes.push(note);
    this.fc.setValue('');

    if (this.saveOnDemand)
      this.save.emit(text);

    this.changeRows(1);
  }

  changeRows(value: number) {
    this.rows = value;
  }

}
