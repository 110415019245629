import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterParameter } from 'common';
import { ClientQueryResult, ClientFilters } from '../client.data';
import { ClientListFilterDialogComponent } from '../client-list-filter-dialog/client-list-filter-dialog.component';
import { ClientFilterService } from '../client-filter.services';
import { routerTransition } from '../../../shared/animations/router.animations';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css'],
  animations: routerTransition,
})
export class ClientListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  clientSecrets = {};

  filters: ClientFilters;
  activeFilters: FilterParameter[] = [];
  readWriteClient: boolean;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private _clientFilterService: ClientFilterService,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  clientSecretSave(id: string, secret: string) {
    this.clientSecrets[id] = secret;
  }

  data: ClientListComponentData;

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((it: ClientListComponentData) => this.data = it);
    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(it => {
      this.queryParams = this.queryParamsService.init(it);
      this.activeFilters = this._clientFilterService.addFilterParameters(this.queryParams);
    });

    this.userPermissionService.granted([readWrite('admin-clients')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.readWriteClient = res);

    this.filters = new ClientFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex =  this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this._clientFilterService.filter(this.filters);
    });
  }

  filterDialog() {
    ClientListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this._clientFilterService.filter(result);
        }
      });
  }

  sortData(sort: Sort) {
    this._clientFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this._clientFilterService.filter(this.filters, this.paginator, this.sort);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  queryParams: any;

  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    'name',
    'oidcAudience',
    'oidcLoginAllowed',
    'active',
    'actions'
  ];

}

export interface ClientListComponentData {
  clientQueryResult: ClientQueryResult;
}
