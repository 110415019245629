import { UntypedFormControl } from "@angular/forms";
import { PromotionListQueryParams } from "../../../models/promotions-query.model";

export const getFormModel = (query: PromotionListQueryParams) => {
  return {
    name: query.name || undefined,
    active: query.active || undefined,

    createdOn: query.createdOn || undefined,
    createdOnFrom: new UntypedFormControl(
      query.createdOnFrom ? new Date(query.createdOnFrom) : undefined,
      { validators: null, updateOn: "blur" }
    ),
    createdOnTo: new UntypedFormControl(
      query.createdOnTo ? new Date(query.createdOnTo) : undefined,
      { validators: null, updateOn: "blur" }
    ),

    startDate: query.startDate || undefined,
    startDateFrom: new UntypedFormControl(
      query.startDateFrom ? new Date(query.startDateFrom) : undefined,
      { validators: null, updateOn: "blur" }
    ),
    startDateTo: new UntypedFormControl(
      query.startDateTo ? new Date(query.startDateTo) : undefined,
      { validators: null, updateOn: "blur" }
    ),

    endDate: query.endDate || undefined,
    endDateFrom: new UntypedFormControl(
      query.endDateFrom ? new Date(query.endDateFrom) : undefined,
      { validators: null, updateOn: "blur" }
    ),
    endDateTo: new UntypedFormControl(
      query.endDateTo ? new Date(query.endDateTo) : undefined,
      { validators: null, updateOn: "blur" }
    ),

    productCode: query.productCode || undefined,
    transactionType: query.transactionType || undefined,
    promotionType: query.promotionType || undefined,
  };
};
