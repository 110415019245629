import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { AppBarTitleService, AppBarActionsService, AppBarAction, EnumHelper, FormHelper, MessageService, AppPageService } from 'common';
import { BrokerExData, BrokerCommissionPlan, BrokerPricingPlan } from '../broker.model';
import { MatDialog } from '@angular/material/dialog';
import { BrokerService } from '../broker.service';
import { BrokerUpdateDialogComponent } from '../broker-update-dialog/broker-update-dialog.component';

@Component({
  selector: 'ifb-broker-commission-details',
  templateUrl: './broker-commission-details.component.html',
  styleUrls: ['./broker-commission-details.component.scss']
})
export class BrokerCommissionDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  form: UntypedFormGroup;

  pricingPlanOptions = EnumHelper.getNamesAndValues(BrokerPricingPlan);
  commissionPlanOptions = EnumHelper.getNamesAndValues(BrokerCommissionPlan);

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private brokerService: BrokerService,
    private messageService: MessageService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.form = this.formBuilder.group({
      pricingPlan: undefined,
      plan: undefined,
      initialDrawCommission: undefined,
      initialCommissionPeriod: undefined,
      initialCommissionCap: undefined,
      residualDrawCommission: undefined,
      residualCommissionPeriod: undefined,
      defaultClawbackPeriod: undefined,
      defaultPrepaymentClawbackPeriod: undefined
    });
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  data: BrokerCommissionDetailsComponentData;

  dataInit(data: BrokerCommissionDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Commission info';

    this.form.reset({
      pricingPlan: this.data.broker.pricingPlan || '',
      plan: this.data.broker.commission ? this.data.broker.commission.plan : '',
      initialDrawCommission: this.data.broker.commission ? this.data.broker.commission.initialDrawCommission : null,
      initialCommissionPeriod: this.data.broker.commission ? this.data.broker.commission.initialCommissionPeriod : '',
      initialCommissionCap: this.data.broker.commission ? this.data.broker.commission.initialCommissionCap : null,
      residualDrawCommission: this.data.broker.commission ? this.data.broker.commission.residualDrawCommission : null,
      residualCommissionPeriod: this.data.broker.commission ? this.data.broker.commission.residualCommissionPeriod : '',
      defaultClawbackPeriod: this.data.broker.commission ? this.data.broker.commission.defaultClawbackPeriod : '',
      defaultPrepaymentClawbackPeriod: this.data.broker.commission ? this.data.broker.commission.defaultPrepaymentClawbackPeriod : ''
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    BrokerUpdateDialogComponent.show(this.dialog, this.form, this.data.broker.name, 'commission')
      .subscribe(result => {
        if (result) {
          const updateCommand = {
            pricingPlan: this.form.value.pricingPlan,
            commission: {
              plan: this.form.value.plan,
              initialDrawCommission: this.form.value.initialDrawCommission,
              initialCommissionPeriod: this.form.value.initialCommissionPeriod,
              initialCommissionCap: this.form.value.initialCommissionCap,
              residualDrawCommission: this.form.value.residualDrawCommission,
              residualCommissionPeriod: this.form.value.residualCommissionPeriod,
              defaultClawbackPeriod: this.form.value.defaultClawbackPeriod,
              defaultPrepaymentClawbackPeriod: this.form.value.defaultPrepaymentClawbackPeriod
            }
          };
          this.saveSub = this.brokerService.updateCommission(this.data.broker.id, updateCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }
}

export interface BrokerCommissionDetailsComponentData {
  broker?: BrokerExData;
}
