import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AppBarTitleService,
  EnumHelper,
  OfferStatus,
  OfferStatusLabel,
} from "common";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "ifb-offer-tabs",
  templateUrl: "./offer-tabs.component.html",
  styleUrls: ["./offer-tabs.component.scss"],
})
export class OfferTabsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  navLinks = [];
  data: any;

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService
  ) {
    this._unsubscribeAll = new Subject();
    this.navLinks = [
      { path: "details", label: "DETAILS" },
      { path: "economics", label: "ECONOMICS" },
      { path: "fees", label: "FEES" },
    ];
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: unknown) => this.initData(data));
  }

  private initData(data: any) {
    this.data = data;
    this.data.appBar.closeAction = `application/${this.route.snapshot.params.id}/offer-bundle/${this.route.snapshot.params.offerBundleId}/offers`;
    const statusLabel = this.prepareStatusLabel(data.offer.status);
    this.appBarTitleService.title = `${data.offer.name} - ${statusLabel}`;
  }

  private prepareStatusLabel(status: OfferStatus) {
    return EnumHelper.getMappedNameFromValue(
      OfferStatus,
      OfferStatusLabel,
      status
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
