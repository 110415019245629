import { catchError } from "rxjs/operators";
import { UntypedFormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";
import {
  AppBarActionsService,
  AppPageService,
  MessageService,
} from "common";
import { EMPTY } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { AccountService } from "../../../account.service";
import { AccountStatementsUpdateDialogComponent } from "../account-statements-update-dialog/account-statements-update-dialog.component";
import { AccountStatementsRequestData } from "../../../account.model";

@Injectable()
export class AccountStatementsEditFacade {
  constructor(
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private accountService: AccountService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  updateActionButtons(isFormValid: boolean) {
    this.appBarActionsService.enable("save", isFormValid);
  }

  cancel(): void {
    this.appPageService.back();
  }

  save(loanId: number, statementsForm: UntypedFormGroup) {
    this.appBarActionsService.enable("save", false);
    const formValues = statementsForm?.value;
    const generateStatements = formValues?.generateStatements;
    const generateStatementsExpirationDate = formValues?.generateStatementsExpirationDate;
    this.showUpdateDialog(loanId, generateStatements, generateStatementsExpirationDate);
  }

  private showUpdateDialog(
    loanId: number,
    generateStatements: boolean,
    generateStatementsExpirationDate: Date
  ) {
    AccountStatementsUpdateDialogComponent.show(
      this.dialog
    ).subscribe((result) => {
      if (!result) {
        this.appBarActionsService.enable("save", true);
        return;
      }

      const requestData: AccountStatementsRequestData = {
        generateStatements,
        generateStatementsExpirationDate,
      };

      this.updateStatementsRequest(loanId, requestData);
    });
  }

  private updateStatementsRequest(loanId: number, requestData: AccountStatementsRequestData): void {
    this.accountService.updateLoanStatementsGenearation(loanId, requestData)
      .pipe(
        catchError((error) => {
          return this.handleSaveError(error);
        })
      )
      .subscribe(() => {
        this.appPageService.back();
      });
  }

  private handleSaveError(error: unknown) {
    this.appBarActionsService.enable("save", true);
    this.messageService.error(error);
    return EMPTY;
  }
}
