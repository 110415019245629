import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { AppSidenavItem, AppSidenavSubItem } from "./app-sidenav.data";
import { AppPageService } from "../app-page/app-page.service";
import { AppSidenavService } from "./app-sidenav.service";
import { delay } from "rxjs/operators";

@Component({
  selector: "ifc-app-sidenav",
  templateUrl: "./app-sidenav.component.html",
  styleUrls: ["./app-sidenav.component.scss"],
})
export class AppSidenavComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  data: AppSidenavComponentData;
  items = [];
  trackByFn = null;

  constructor(
    private route: ActivatedRoute,
    private appPageService: AppPageService,
    private service: AppSidenavService
  ) {}

  emitItemSelectedEvent() {
    this.appPageService.itemSelectedSubject.next();
  }

  dataSet(data: AppSidenavComponentData) {
    this.data = data;
    if (this.data?.appSidenav?.items) {
      this.items = this.data.appSidenav.items.filter((it) => !it.hidden);
    }
    if (this.data?.appSidenav?.trackByFn) {
      this.trackByFn = this.data.appSidenav.trackByFn;
    }
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this)),
      this.service.itemsChange.pipe(delay(100)).subscribe((it) => {
        this.items = it.filter((it) => !it.hidden);
      }),
    ];
  }

  ngOnDestroy() {
    this.subs.forEach((it) => it.unsubscribe());
  }
}

export interface AppSidenavComponentData {
  appSidenav: {
    items: AppSidenavItem[];
    trackByFn?: (
      index: number,
      item: AppSidenavItem | AppSidenavSubItem
    ) => any;
  };
}
