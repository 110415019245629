<ng-template #feeTemplate let-name="name" let-value="value" let-type="type" let-valueControl="valueControl" let-typeControl="typeControl">
  <div *ngIf="valueControl && !valueControl.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
    <div *ngIf="type === FeeType.Flat">
      {{value | currency:'$'}}</div>
    <div *ngIf="type === FeeType.Percentage">
      {{value}}%</div>
    <div class="mat-caption">{{name}}</div>
  </div>

  <div *ngIf="typeControl && !typeControl.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
    <div>{{getFeeType(type)}}</div>
    <div class="mat-caption">{{name}} type</div>
  </div>
</ng-template>

<h1 mat-dialog-title>Update account offer?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are updating account offer:
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">

      <div *ngIf="data.form.controls.creditLimit && !data.form.controls.creditLimit.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.creditLimit | currency:'$'}}</div>
        <div class="mat-caption"> Credit limit </div>
      </div>

      <div *ngIf="data.form.controls.annualRate && !data.form.controls.annualRate.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.annualRate || 'Null'}}%</div>
        <div class="mat-caption"> Annual interest rate </div>
      </div>

      <div *ngIf="data.form.controls.repaymentTerm && !data.form.controls.repaymentTerm.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.repaymentTerm || 'Null'}}</div>
        <div class="mat-caption"> Repayment term </div>
      </div>

      <div *ngIf="data.form.controls.drawTerm && !data.form.controls.drawTerm.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.drawTerm || 'Null'}}</div>
        <div class="mat-caption"> Draw term </div>
      </div>

      <div *ngIf="data.form.controls.noPaymentTerms && !data.form.controls.noPaymentTerms.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.noPaymentTerms || 'Null'}}</div>
        <div class="mat-caption"> No payment terms </div>
      </div>

      <div *ngIf="data.form.controls.interestOnlyPaymentTerms && !data.form.controls.interestOnlyPaymentTerms.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.interestOnlyPaymentTerms || 'Null'}}</div>
        <div class="mat-caption"> Interest only payment terms </div>
      </div>

      <div *ngIf="data.form.controls.paymentFrequency && !data.form.controls.paymentFrequency.pristine"
        fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{gatPaymentFrequency(data.form.value.paymentFrequency)}}</div>
        <div class="mat-caption"> Payment frequency </div>
      </div>

      <ng-container *ngTemplateOutlet="feeTemplate; context: {
        name: 'Draw fee',
        value: data.form.value.drawDownFee,
        type: data.form.value.drawDownFeeType,
        valueControl: data.form.controls.drawDownFee,
        typeControl: data.form.controls.drawDownFeeType }">
      </ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: {
        name: 'Maintenance fee',
        value: data.form.value.maintenanceFee,
        type: data.form.value.maintenanceFeeType,
        valueControl: data.form.controls.maintenanceFee,
        typeControl: data.form.controls.maintenanceFeeType }">
      </ng-container>

      <div *ngIf="data.form.controls.maintenanceFeeFrequency && !data.form.controls.maintenanceFeeFrequency.pristine"
        fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{gatPaymentFrequency(data.form.value.maintenanceFeeFrequency)}}</div>
        <div class="mat-caption"> Maintenance fee frequency </div>
      </div>

      <ng-container *ngTemplateOutlet="feeTemplate; context: {
        name: 'Origination fee',
        value: data.form.value.originationFee,
        type: data.form.value.originationFeeType,
        valueControl: data.form.controls.originationFee,
        typeControl: data.form.controls.originationFeeType }">
      </ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: {
        name: 'UCC filing fee',
        value: data.form.value.uccFilingFee,
        type: data.form.value.uccFilingFeeType,
        valueControl: data.form.controls.uccFilingFee,
        typeControl: data.form.controls.uccFilingFeeType }">
      </ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: {
        name: 'Servicing fee',
        value: data.form.value.servicingFee,
        type: data.form.value.servicingFeeType,
        valueControl: data.form.controls.servicingFee,
        typeControl: data.form.controls.servicingFeeFeeType }">
      </ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: {
        name: 'Servicing fee minimum',
        value: data.form.value.servicingFeeMinimum,
        type: data.form.value.servicingFeeMinimumType,
        valueControl: data.form.controls.servicingFeeMinimum,
        typeControl: data.form.controls.servicingFeeFeeMinimumType }">
      </ng-container>

      <ng-container *ngTemplateOutlet="feeTemplate; context: {
        name: 'Stacking fee',
        value: data.form.value.stackingFee,
        type: data.form.value.stackingFeeType,
        valueControl: data.form.controls.stackingFee,
        typeControl: data.form.controls.stackingFeeType }">
      </ng-container>

      <div *ngIf="data.form.controls.creditModelId && !data.form.controls.creditModelId.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.creditModelId || 'Null'}}</div>
        <div class="mat-caption"> Credit model ID </div>
      </div>

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>