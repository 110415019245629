import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifb-account-status-icon-chip',
  templateUrl: './account-status-icon-chip.component.html',
  styleUrls: ['./account-status-icon-chip.component.scss']
})
export class AccountStatusIconChipComponent {

  constructor() { }

  @Input()
  status = 0;

  statusLabels = {
    '0': { color: 'mat-grey-bg', icon: 'help', label: 'Pending' },
    '1': { color: 'mat-green-bg', icon: 'check_circle', label: 'Open' },
    '2': { color: 'mat-red-bg', icon: 'error', label: 'Closed' },
    '3': { color: 'mat-orange-bg', icon: 'pause_circle_filled', label: 'Frozen' },
    '4': { color: 'mat-orange-bg', icon: 'check_circle', label: 'In progress no payments' },
    '5': { color: 'mat-orange-bg', icon: 'check_circle', label: 'In progress interest only payments' },
    '6': { color: 'mat-orange-bg', icon: 'check_circle', label: 'Repayment' },
    '10': { color: 'mat-orange-bg', icon: 'update', label: 'Pending renewal' },
    '11': { color: 'mat-red-bg', icon: 'error', label: 'Pending closure' },
    '20': { color: 'mat-purple-bg', icon: 'warning', label: 'Declined' },
    '21': { color: 'mat-orange-bg', icon: 'redo', label: 'Renewal declined' },
    '30': { color: 'mat-orange-bg', icon: 'pause_circle_filled', label: 'Delinquent' },
    '40': { color: 'mat-orange-bg', icon: 'redo', label: 'Workout' },
    '42': { color: 'mat-orange-bg', icon: 'redo', label: 'Payment relief' },
    '50': { color: 'mat-orange-bg', icon: 'redo', label: 'Write-off' },
    '51': { color: 'mat-red-bg', icon: 'error', label: 'Paid off' }
  };
}
