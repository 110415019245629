import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifb-callback', 
  template: ''
})
export class CallbackComponent implements OnInit, AfterViewInit {

  private queryParams: UserSignInComponentQueryParams;

  constructor(private route: ActivatedRoute, private router: Router, private oauthService: OAuthService) {
  }

  ngOnInit() {
      this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((it: UserSignInComponentQueryParams) => {
        this.queryParams = it;
      })
  }

  ngAfterViewInit() {
    if(this.queryParams?.accessToken) {
      this.sessionBegin();
    }

    if(this.oauthService.authenticated) {
      this.redirect();
    }
  }

  private sessionBegin() {
    this.oauthService.sessionBegin(
      '#$oidc',
      this.queryParams.accessToken,
      this.queryParams.expiresIn,
      this.queryParams.refreshToken);
  }

  private redirect() {
    try { this.router.navigateByUrl(this.queryParams.redirectUrl, { replaceUrl: true }); }
    catch { this.router.navigate(['home']); }
  }
}

export interface UserSignInComponentQueryParams {
  redirectUrl?: string;
  accessToken?: string;
  expiresIn?: number;
  refreshToken?: string;
}