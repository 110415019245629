<div class="content">
  <mat-tab-group>
    <mat-tab label="ROLE SETTINGS">
      <div class="flex column center-center">
        <ifb-card>
          <form>
            <ifc-app-card>
              <div class="flex column center-end">

                <mat-checkbox labelPosition="before" color="primary" class="expand-all-checkbox"
                  [indeterminate]="expandAllIndeterminate" [checked]="expandAllChecked" (change)="expandAllToggle()">
                  Expand all</mat-checkbox>

                <mat-accordion [multi]="true" displayMode="flat">
                  <mat-expansion-panel *ngFor="let group of data.permissionGroups" [expanded]="group.expanded"
                    (opened)="group.expanded = true" (closed)="group.expanded = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{group.name}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <mat-form-field appearance="outline">
                      <mat-label>Assignment to group</mat-label>
                      <mat-select matInput placeholder="Client" value="custom"
                        (selectionChange)="permissionGroupSet(group, $event.value)">
                        <mat-option value="none">None</mat-option>
                        <mat-option value="read-only">Read only</mat-option>
                        <mat-option value="read-write">Read/write</mat-option>
                        <mat-option value="custom" [disabled]="true">Custom</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th class="table-header-cell">None</th>
                          <th class="table-header-cell">Read only</th>
                          <th class="table-header-cell">Read/write</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-template ngFor let-permission [ngForOf]="group.permissions">
                          <tr>
                            <td class="table-cell-permission-name">{{permission.name}}</td>
                            <td class="table-cell-permission-radio">
                              <mat-radio-button *ngIf="permission.allowedAccessType.indexOf('none') !== -1"
                                [name]="permission.id" color="primary" value="none"
                                [checked]="permissionChecked(permission.id, 'none')"
                                (change)="permissionSet(permission.id, 'none', permission.name)"></mat-radio-button>
                            </td>
                            <td class="table-cell-permission-radio">
                              <mat-radio-button *ngIf="permission.allowedAccessType.indexOf('read-only') !== -1"
                                [name]="permission.id" color="primary" value="read-only"
                                [checked]="permissionChecked(permission.id, 'read-only')"
                                (change)="permissionSet(permission.id, 'read-only', permission.name)"></mat-radio-button>
                            </td>
                            <td class="table-cell-permission-radio">
                              <mat-radio-button *ngIf="permission.allowedAccessType.indexOf('read-write') !== -1"
                                [name]="permission.id" color="primary" value="read-write"
                                [checked]="permissionChecked(permission.id, 'read-write')"
                                (change)="permissionSet(permission.id, 'read-write', permission.name)"></mat-radio-button>
                            </td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>

                  </mat-expansion-panel>
                </mat-accordion>

                <div class="flex column center-start role-status-select layout margin-top padding-left">
                  <div class="mat-expansion-panel-header-title">
                    Role status
                  </div>

                  <form [formGroup]="form">
                    <mat-form-field appearance="outline" class="layout margin-top">
                      <mat-label>Status</mat-label>
                      <mat-select matInput formControlName="status" placeholder="Status">
                        <mat-option [value]="true">Active</mat-option>
                        <mat-option [value]="false">Inactive</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>

                <div class="actions flex row end-center">
                  <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
                </div>
              </div>

              <aside class="flex column">
                <ifb-card>
                  <!--<h2 class="mat-display-1 layout margin-bottom">{{data.role.name}} role</h2>-->
                  <div class="layout margin-bottom">
                    <div>{{data.role.client}}</div>
                    <div class="mat-caption mat-hint">Client</div>
                  </div>
                  <div class="layout margin-bottom">
                    <div>{{data.role.externalId}}</div>
                    <div class="mat-caption mat-hint">External ID</div>
                  </div>
                  <div class="layout margin-bottom">
                    <div>{{data.role.active ? 'Active' : 'Inactive'}}</div>
                    <div class="mat-caption mat-hint">Status</div>
                  </div>
                </ifb-card>
              </aside>
            </ifc-app-card>
          </form>
        </ifb-card>

      </div>

    </mat-tab>
    <mat-tab label="USERS ASSIGNED TO THE ROLE">
      <ifb-user-role-assignees-list></ifb-user-role-assignees-list>
    </mat-tab>
  </mat-tab-group>
</div>