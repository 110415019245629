import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  FilterParameter,
  MatTableHelper,
} from "common";
import { CompetitorListQueryParams } from "./models/competitors-query.model";
import { CompetitorsFilters } from "./models/competitors-filter.model";

@Injectable({
  providedIn: "root",
})
export class CompetitorsFilterService {
  constructor(private router: Router) {}

  filter(filters: CompetitorsFilters, paginator?: MatPaginator, sort?: MatSort) {
    const query: CompetitorListQueryParams = <any>filters;

    // Set default to first page
    query.skip = undefined;

    query.name =
      typeof query.name === "object" ? filters.name.name : query.name;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }

    this.router.navigate(["admin", "competitors"], {
      replaceUrl: true,
      queryParams: query,
    });
  }

  addFilterParameters(query: CompetitorListQueryParams): FilterParameter[] {
    const filters: FilterParameter[] = [];

    if (query.name) {
      filters.push({
        id: "name",
        name: "Name",
        value: query.name.trim(),
      });
    }

    if (query.active) {
      filters.push({
        id: "active",
        name: "Status",
        value: query.active.toString() === "true" ? "Active" : "Inactive",
      });
    }

    return filters;
  }
}
