import { UntypedFormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  AppBarActionsService,
  AppBarTitleService,
  AppPageService,
  DialogConfirm,
  LoanStatus,
  MessageService,
} from "common";
import { catchError } from "rxjs/operators";
import { readWrite } from "../../../user/user-permission/user-permission.data";
import { UserPermissionService } from "../../../user/user-permission/user-permission.service";
import { PromotionService } from "../promotion.service";
import { PromotionDetailsStep } from "./enums/promotion-details-step.enum";
import { EMPTY } from "rxjs";
import { Promotion, PromotionBase } from "../models/promotion.model";
import { LinkAccountType } from "./enums/linked-account-type.enum";
import { Router } from "@angular/router";
import { getActionsConfig } from "./data/promotion-details-data";
import { UpdateDialogComponent } from "../../../shared/update-dialog/update-dialog.component";
import { updatedFieldsData } from "./components/promotion-details-update-dialog/promotion-details-update-field-data";

@Injectable()
export class PromotionDetailsFacade {
  private readWritePromotion: boolean;
  private isUpdateMode: boolean;
  private currentStep: PromotionDetailsStep = PromotionDetailsStep.Promotion;
  private currentPromotion: Promotion;

  constructor(
    private dialog: MatDialog,
    private promotionService: PromotionService,
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private userPermissionService: UserPermissionService,
    private messageService: MessageService,
    private router: Router,
    private appPageService: AppPageService
  ) {}

  init(currentPromotion: Promotion) {
    this.isUpdateMode = !!currentPromotion?.id;
    this.currentPromotion = currentPromotion;
    this.initPermission();

    this.appBarActionsService.actions = getActionsConfig;
    this.appBarTitleService.title = this.isUpdateMode
      ? `Promotion - ${currentPromotion.name}`
      : "Create new promotion";
  }

  private initPermission(): void {
    this.userPermissionService
      .granted([readWrite("servicing-promotions")])
      .subscribe((res) => {
        this.readWritePromotion = res;
        this.updateDeleteButton();
      });
  }

  cancel(): void {
    this.appPageService.back();
  }

  delete() {
    if (!this.currentPromotion.id) {
      this.cancel();
    }

    this.appBarActionsService.enable("delete", false);
    DialogConfirm.show(this.dialog, `Delete promotion?`).subscribe((result) => {
      if (result) {
        this.deletePromotionRequest(this.currentPromotion.id);
      } else {
        this.updateDeleteButton();
      }
    });
  }

  private deletePromotionRequest(promotionId: number) {
    this.promotionService
      .deletePromotion(promotionId)
      .pipe(
        catchError((error) => {
          return this.handleDeleteError(error);
        })
      )
      .subscribe(() => {
        this.appPageService.back();
      });
  }

  private handleDeleteError(error: unknown) {
    this.updateDeleteButton();
    this.messageService.error(error);
    return EMPTY;
  }

  updateActionButtons(isFormDataValid: boolean) {
    const isSaveButtonEnabled = this.readWritePromotion && isFormDataValid;
    this.appBarActionsService.enable("save", isSaveButtonEnabled);

    if (this.currentStep === PromotionDetailsStep.Promotion) {
      this.appBarActionsService.hide("next", false);
      this.appBarActionsService.hide("back", true);
      this.appBarActionsService.hide("save", true);
    } else if (this.currentStep === PromotionDetailsStep.Conditions) {
      this.appBarActionsService.hide("next", true);
      this.appBarActionsService.hide("back", false);
      this.appBarActionsService.hide("save", false);
    }
  }

  updateCurrentStep(currentStep: PromotionDetailsStep) {
    this.currentStep = currentStep;
  }

  private updateDeleteButton() {
    this.appBarActionsService.hide("delete", !this.isUpdateMode);
    this.appBarActionsService.enable("delete", this.readWritePromotion);
  }
  save(promotionForm: UntypedFormGroup, conditionsForm: UntypedFormGroup) {
    this.appBarActionsService.enable("save", false);
    const action = this.isUpdateMode ? "Update" : "Create";
    const requestData = this.prepareRequestData(promotionForm, conditionsForm);
    this.showUpdateDialog(action, requestData, promotionForm, conditionsForm);
  }

  private prepareRequestData(
    promotionForm: UntypedFormGroup,
    conditionsForm: UntypedFormGroup
  ): PromotionBase {
    const linkedAccountFormValue: LinkAccountType =
      conditionsForm.value?.linkedAccounts;
    const loanStatuses: LoanStatus[] = conditionsForm.value?.loanStatuses;

    return {
      ...promotionForm.value,
      ...conditionsForm.value,
      linkAccounts:
        linkedAccountFormValue === LinkAccountType.AllAccounts
          ? null
          : linkedAccountFormValue,
      loanStatuses: loanStatuses?.length === 0 ? null : loanStatuses
    };
  }

  private showUpdateDialog(
    action: string,
    requestData: PromotionBase,
    promotionForm: UntypedFormGroup,
    conditionsForm: UntypedFormGroup
  ) {
    UpdateDialogComponent.show(
      this.dialog,
      updatedFieldsData(promotionForm, conditionsForm),
      action,
      "promotion"
    ).subscribe((result) => {
      if (!result) {
        return;
      }

      if (this.isUpdateMode) {
        this.updatePromotionRequest(requestData);
        return;
      }

      this.addPromotionRequest(requestData);
    });
  }

  private addPromotionRequest(requestData: PromotionBase): void {
    this.promotionService
      .addPromotion(requestData)
      .pipe(
        catchError((error) => {
          return this.handleSaveError(error);
        })
      )
      .subscribe((promotion: Promotion) => {
        this.appPageService.back();
      });
  }

  private updatePromotionRequest(requestData: PromotionBase): void {
    this.promotionService
      .updatePromotion(this.currentPromotion.id, requestData)
      .pipe(
        catchError((error) => {
          return this.handleSaveError(error);
        })
      )
      .subscribe(() => {
        this.appPageService.back();
      });
  }

  private handleSaveError(error: unknown) {
    this.appBarActionsService.enable("save", true);
    this.messageService.error(error);
    return EMPTY;
  }
}
