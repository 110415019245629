import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FilterParameter } from 'common';
import { BankingCustomerFilters, BankingCustomerQueryParams } from '../banking.model';

@Injectable({
  providedIn: 'root'
})
export class BankingCustomerListFilterService {
  constructor(
    private router: Router
  ) { }

  filter(provider: string, filters: BankingCustomerFilters, paginator?: MatPaginator, sort?: MatSort) {
    const query: BankingCustomerQueryParams = filters;
    query.username = filters.username;
    query.name = filters.name;

    // Set default to first page
    query.skip = undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = sort.direction;
    }


    this.router.navigate([`banking/${provider}/customers`], { replaceUrl: true, queryParams: query });
  }

  addFilterParameters(query: BankingCustomerQueryParams): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];

    if (query.username) {
      filters.push({
        id: 'username', name: 'Username',
        value: query.username
      });
    }
    if (query.name) {
      filters.push({
        id: 'name', name: 'Name',
        value: query.name
      });
    }
    return filters;
  }
}
