import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  UserRoleData,
  UserRoleQueryParams,
  UserRoleQueryResult,
  RoleUpdateRequestDto,
} from "./user-role.data";
import { Observable } from "rxjs";
import { UserQueryParams, UserQueryResult } from "../../admin/user/user.model";
import { AppSettings } from "../../../app.settings";

@Injectable({
  providedIn: "root",
})
export class UserRoleService {
  constructor(private http: HttpClient, private settings: AppSettings) {}

  get(id: number): Observable<UserRoleData> {
    return this.http.get<UserRoleData>(
      `${this.settings.oauth.url}/api/role/${id}`
    );
  }

  query(params: UserRoleQueryParams): Observable<UserRoleQueryResult> {
    if (!params.sort) {
      params.sort = "+name";
    }

    return this.http.get<UserRoleQueryResult>(
      `${this.settings.oauth.url}/api/role/query`,
      { params: <any>params }
    );
  }

  usersList(params: UserQueryParams): Observable<UserQueryResult> {
    return this.http.get<UserQueryResult>(
      `${this.settings.oauth.url}/api/user/query`,
      { params: <any>params }
    );
  }

  updatePermissions(
    id: number,
    roleUpdateRequestDto: RoleUpdateRequestDto
  ): Observable<UserRoleData> {
    return this.http.post<UserRoleData>(
      `${this.settings.oauth.url}/api/role/${id}/permissions`,
      roleUpdateRequestDto
    );
  }
}
