<ifc-app-scroll-container>
  <div fxLayout.gt-md="row" fxLayoutAlign.gt-md="center center" fxLayoutGap.gt-md="48px" fxLayout="column"
    fxLayoutAlign="start center" fxFlexFill.lt-lg ngClass.lt-lg="fit-content">

    <form [formGroup]="form" class="layout padding-top">

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Credit limit</mat-label>
          <input matInput type="text" currencyMask formControlName="creditLimit">
          <mat-error *ngIf="form.get('creditLimit').errors | hasControlError:['required']">Credit limit is required</mat-error>
          <mat-error *ngIf="form.get('creditLimit').errors | hasControlError:['min']">Credit limit minimum value is 0.01</mat-error>
          <mat-error *ngIf="form.get('creditLimit').errors | hasControlError:['max']">Credit limit maximum value is 999999999999.00</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Annual interest rate</mat-label>
          <input matInput type="text" currencyMask [options]="{precision: 2, suffix: '%', prefix: ''}"
            formControlName="annualRate">
          <mat-error *ngIf="form.get('annualRate').errors | hasControlError:['required']">Annual interest rate is required</mat-error>
          <mat-error *ngIf="form.get('annualRate').errors | hasControlError:['min']">Annual interest rate minimum value is 0.01</mat-error>
          <mat-error *ngIf="form.get('annualRate').errors | hasControlError:['max']">Annual interest rate maximum value is 9999.00</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Repayment term</mat-label>
          <input matInput type="number" formControlName="repaymentTerm">
          <mat-error *ngIf="form.get('repaymentTerm').errors | hasControlError:['required']">Repayment term is required</mat-error>
          <mat-error *ngIf="form.get('repaymentTerm').errors | hasControlError:['min']">Repayment term minimum value is 1</mat-error>
          <mat-error *ngIf="form.get('repaymentTerm').errors | hasControlError:['max']">Repayment term maximum value is 9999</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Draw term</mat-label>
          <input matInput type="number" formControlName="drawTerm">
          <mat-error *ngIf="form.get('drawTerm').errors | hasControlError:['required']">Draw term is required</mat-error>
          <mat-error *ngIf="form.get('drawTerm').errors | hasControlError:['min']">Draw term minimum value is 0</mat-error>
          <mat-error *ngIf="form.get('drawTerm').errors | hasControlError:['max']">Draw term maximum value is 9999</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>No payment terms</mat-label>
          <input matInput type="number" formControlName="noPaymentTerms">
          <mat-error *ngIf="form.get('noPaymentTerms').errors | hasControlError:['required']">No payment terms is required</mat-error>
          <mat-error *ngIf="form.get('noPaymentTerms').errors | hasControlError:['min']">No payment terms minimum value is 0</mat-error>
          <mat-error *ngIf="form.get('noPaymentTerms').errors | hasControlError:['max']">No payment terms maximum value is 9999</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Interest only payment terms</mat-label>
          <input matInput type="number" formControlName="interestOnlyPaymentTerms">
          <mat-error *ngIf="form.get('interestOnlyPaymentTerms').errors | hasControlError:['required']">Interest only payment terms is required</mat-error>
          <mat-error *ngIf="form.get('interestOnlyPaymentTerms').errors | hasControlError:['min']">Interest only payment terms minimum value is 0</mat-error>
          <mat-error *ngIf="form.get('interestOnlyPaymentTerms').errors | hasControlError:['max']">Interest only payment terms maximum value is 9999</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Payment frequency</mat-label>
          <mat-select matInput formControlName="paymentFrequency" placeholder="Payment frequency">
            <mat-option *ngFor="let option of accountOfferPaymentFrequenciesOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Daily simple interest rate</mat-label>
          <input matInput type="text" currencyMask [options]="{precision: 4, suffix: '%', prefix: ''}"
            formControlName="dailyInterestRate">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Weekly simple interest rate</mat-label>
          <input matInput type="text" currencyMask [options]="{precision: 4, suffix: '%', prefix: ''}"
            formControlName="weeklyInterestRate">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Simple interest rate</mat-label>
          <input matInput type="text" currencyMask [options]="{precision: 4, suffix: '%', prefix: ''}"
            formControlName="simpleInterestRate">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Draw fee</mat-label>
          <input matInput *ngIf="form.controls.drawDownFeeType.value !== FeeType.Flat" type="text" currencyMask
            [options]="{precision: 2, suffix: '%', prefix: ''}" name="drawDownFee" formControlName="drawDownFee">
          <input matInput *ngIf="form.controls.drawDownFeeType.value === FeeType.Flat" type="text" currencyMask
            name="drawDownFee" formControlName="drawDownFee">
          <mat-error *ngIf="form.get('drawDownFee').errors | hasControlError:['required']">Draw fee is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Draw fee type</mat-label>
          <mat-select matInput formControlName="drawDownFeeType" placeholder="Draw fee type">
            <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Origination fee</mat-label>
          <input matInput *ngIf="form.controls.originationFeeType.value !== FeeType.Flat" type="text" currencyMask
            [options]="{precision: 2, suffix: '%', prefix: ''}" name="originationFee" formControlName="originationFee">
          <input matInput *ngIf="form.controls.originationFeeType.value === FeeType.Flat" type="text" currencyMask
            name="originationFee" formControlName="originationFee">
          <mat-error *ngIf="form.get('originationFee').errors | hasControlError:['required']">Origination fee is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Origination fee type</mat-label>
          <mat-select matInput formControlName="originationFeeType" placeholder="Origination fee type">
            <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>UCC filing fee</mat-label>
          <input matInput *ngIf="form.controls.uccFilingFeeType.value !== FeeType.Flat" type="text" currencyMask
            [options]="{precision: 2, suffix: '%', prefix: ''}" name="uccFilingFee" formControlName="uccFilingFee">
          <input matInput *ngIf="form.controls.uccFilingFeeType.value === FeeType.Flat" type="text" currencyMask
            name="uccFilingFee" formControlName="uccFilingFee">
          <mat-error *ngIf="form.get('uccFilingFee').errors | hasControlError:['required']">UCC filing fee is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>UCC filing fee type</mat-label>
          <mat-select matInput formControlName="uccFilingFeeType" placeholder="UCC filing fee type">
            <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Servicing fee</mat-label>
          <input matInput *ngIf="form.controls.servicingFeeType.value !== FeeType.Flat" type="text" currencyMask
            [options]="{precision: 2, suffix: '%', prefix: ''}" name="servicingFee" formControlName="servicingFee">
          <input matInput *ngIf="form.controls.servicingFeeType.value === FeeType.Flat" type="text" currencyMask
            name="servicingFee" formControlName="servicingFee">
          <mat-error *ngIf="form.get('servicingFee').errors | hasControlError:['required']">Servicing fee is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Servicing fee type</mat-label>
          <mat-select matInput formControlName="servicingFeeType" placeholder="Servicing fee type">
            <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Servicing fee minimum</mat-label>
          <input matInput *ngIf="form.controls.servicingFeeMinimumType.value !== FeeType.Flat" type="text" currencyMask
            [options]="{precision: 2, suffix: '%', prefix: ''}" name="servicingFeeMinimum" formControlName="servicingFeeMinimum">
          <input matInput *ngIf="form.controls.servicingFeeMinimumType.value === FeeType.Flat" type="text" currencyMask
            name="servicingFeeMinimum" formControlName="servicingFeeMinimum">
            <mat-error *ngIf="form.get('servicingFeeMinimum').errors | hasControlError:['required']">Servicing fee minimum is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Servicing fee type</mat-label>
          <mat-select matInput formControlName="servicingFeeMinimumType" placeholder="Servicing fee minimum type">
            <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Stacking fee</mat-label>
          <input matInput *ngIf="form.controls.stackingFeeType.value !== FeeType.Flat" type="text" currencyMask
            [options]="{precision: 2, suffix: '%', prefix: ''}" name="stackingFee" formControlName="stackingFee">
          <input matInput *ngIf="form.controls.stackingFeeType.value === FeeType.Flat" type="text" currencyMask
            name="stackingFee" formControlName="stackingFee">
          <mat-error *ngIf="form.get('stackingFee').errors | hasControlError:['required']">Stacking fee is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Stacking fee type</mat-label>
          <mat-select matInput formControlName="stackingFeeType" placeholder="Stacking fee type">
            <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Maintenance fee</mat-label>
          <input matInput *ngIf="form.controls.maintenanceFeeType.value !== FeeType.Flat" type="text" currencyMask
            [options]="{precision: 2, suffix: '%', prefix: ''}" name="maintenanceFee" formControlName="maintenanceFee">
          <mat-error *ngIf="form.get('maintenanceFee').errors | hasControlError:['required']">Maintenance fee is required</mat-error>
          <input matInput *ngIf="form.controls.maintenanceFeeType.value === FeeType.Flat" type="text" currencyMask
            name="maintenanceFee" formControlName="maintenanceFee">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Maintenance fee type</mat-label>
          <mat-select matInput formControlName="maintenanceFeeType" placeholder="Maintenance fee type">
            <mat-option *ngFor="let option of feeTypeOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Maintenance fee frequency</mat-label>
          <mat-select matInput formControlName="maintenanceFeeFrequency" placeholder="Maintenance fee frequency">
            <mat-option *ngFor="let option of accountOfferPaymentFrequenciesOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Credit model ID</mat-label>
          <input matInput type="text" formControlName="creditModelId">
        </mat-form-field>
      </div>

      <div style="padding-bottom: 50px;" class="flex row end-center">
        <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
      </div>

    </form>

  </div>
</ifc-app-scroll-container>