import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminRoutingModule } from "./admin-routing.module";
import { UserModule } from "./user/user.module";
import { TokenModule } from "./token/token.module";
import { AdminComponent } from "./admin.component";
import { FailureReasonModule } from "./failure-reason/failure-reason.module";
import { ApiKeyModule } from "./api-key/api-key.module";
import { FeatureFlagModule } from "./feature-flag/feature-flag.module";
import { TenantModule } from "./tenant/tenant.module";
import { PromotionModule } from "./promotion/promotion.module";

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    UserModule,
    TokenModule,
    ApiKeyModule,
    FailureReasonModule,
    FeatureFlagModule,
    TenantModule,
    PromotionModule,
  ],
  declarations: [AdminComponent],
})
export class AdminModule {}
