import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FilterRange } from 'common';
import { UserFilters, UserData, UserQueryParams } from '../user.model';

@Component({
  selector: 'ifb-user-list-filter-dialog',
  templateUrl: './user-list-filter-dialog.component.html',
  styleUrls: ['./user-list-filter-dialog.component.css']
})
export class UserListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: UserFilters;

  constructor(public dialogRef: MatDialogRef<UserListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new UserFilters(data.query);
    this.form = this.formBuilder.group({
      clientName: this.data.query.clientName || '',
      username: this.data.query.username || '',
      firstName: this.data.query.firstName || '',
      lastName: this.data.query.lastName || '',
      lastLogin: this.data.query.lastLogin || '',
      lastLoginStart: new UntypedFormControl(this.data.query.lastLoginStart ? new Date(this.data.query.lastLoginStart) : '', { validators: null, updateOn: 'blur' }),
      lastLoginEnd: new UntypedFormControl(this.data.query.lastLoginEnd ? new Date(this.data.query.lastLoginEnd) : '', { validators: null, updateOn: 'blur' }),
      externalId: this.data.query.externalId || '',
      active: this.data.query.active || undefined
    });
  }

  static show(dialog: MatDialog, query: UserQueryParams, clients: UserData[]): Observable<any> {
    return dialog.open(UserListFilterDialogComponent, {
      data: { query, clients },
      closeOnNavigation: true,
    }).afterClosed();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new UserFilters(data);
      });
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  lastLoginDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      lastLoginStart: dataRange.from,
      lastLoginEnd: dataRange.to
    });
  }

}
