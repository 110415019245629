<div [@routerTransition]="'fly'">
  <div *ngIf="readWriteBrokerApiKeys">
    <div class="table-top">
      <div fxHide.xs class="layout padding-right-md">
        <button
          mat-icon-button
          routerLink="/broker/{{ brokerId }}/api-keys/new"
          class="secondary-text"
          matTooltip="Add"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <ifc-broker-api-key-list [inputData]="data"></ifc-broker-api-key-list>
</div>
