import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { AccountWithRenewalData, ProductCode, AccountAmortizationInfo, AccountStatementsData } from '../account.model';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { formatCurrency } from '@angular/common';
import { AccountService } from '../account.service';
import { takeUntil } from 'rxjs/operators';
import { UserData } from '../../user/user.model';
import { CustomerData } from '../../customer/customer.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { LoanStatus, LoanInfoDto, FeeType, PaymentFrequencyLabel, AccountModificationFlag, LiquidityEvent } from 'common';


@Component({
  selector: 'ifb-account-app-bar-expanded',
  templateUrl: './account-app-bar-expanded.component.html',
  styleUrls: ['./account-app-bar-expanded.component.scss']
})
export class AccountAppBarExpandedComponent implements OnInit, OnDestroy, AfterContentInit {

  private _unsubscribeAll: Subject<any>;

  fundsAvailable: string;
  fundsAvailableFaktor: number;
  data: AccountAppBarExpandedComponentData;
  paymentFrequency: string;
  accountStatus: LoanStatus;
  accountInfo: LoanInfoDto;
  accessNPLEconomics: boolean;

  get FeeType() { return FeeType; }
  get LoanStatus() { return LoanStatus };

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private userPermissionService: UserPermissionService
  ) {
    this._unsubscribeAll = new Subject();
  }

  get ProductCode() { return ProductCode; }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));

    this.userPermissionService.granted([readWrite('servicing-account-npl-economics')])
      .subscribe(res => this.accessNPLEconomics = res);
  }

  ngAfterContentInit(): void {
    this.accountService.obsCurrentLoan
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res) {
          this.refreshData(res);
        }
      });
  }

  refreshData(accountData): void {
    this.accountService.getInfo(accountData.id).subscribe(accountInfoData => {
      this.dataSet({
        account: accountData,
        accountInfo: accountInfoData
      });
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dataSet(data: AccountAppBarExpandedComponentData) {
    this.data = { ...data };
    this.accountStatus = this.data.account ? this.data.account.status : null;
    this.accountInfo = this.data.accountInfo;

    if (data.account && data.account.offer) {
      this.paymentFrequency = PaymentFrequencyLabel.get(data.account.offer.paymentFrequency);
    }

    this.fundsAvailable = formatCurrency(this.data.accountInfo?.availableFunds, 'en_US', '$', undefined, '1.0-0');
    this.fundsAvailableFaktor = this.data.accountInfo?.availableFunds / this.data.accountInfo?.creditLimit;

    if(data.account.statementGeneration) {
      this.data.accountStatements = {
        alligatorMatch: data.account.alligatorMatch,
        loanId: data.account.id,
        ...data.account.statementGeneration
      }
    }
  }

  getOutstandingBalance(): number {
    return this.accountService.getOutstandingBalance(this.data.account);
  }

  showModificationChipState() {
    return this.data.account.modificationFlag !== null
      && this.data.account.modificationFlag !== AccountModificationFlag.None;
  }

  isNPLLoan(): boolean {
    return this.accountService.isNPLLoan(this.data?.account?.status);
  }

}

export interface AccountAppBarExpandedComponentData {
  account: AccountWithRenewalData;
  accountInfo: LoanInfoDto;
  accountAmortizationInfo?: AccountAmortizationInfo[];
  userCurrent?: UserData;
  owners?: CustomerData[];
  liquidityEvent?: LiquidityEvent;
  accountStatements?: AccountStatementsData;
}
