import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { UntypedFormGroup } from '@angular/forms';
import { BankAccountRole, BankAccountType, EnumHelper } from "common";
import { BankAccountRoleLabel } from "../bank.model";

@Component({
  selector: 'ifb-bank-account-update-dialog',
  templateUrl: './bank-account-update-dialog.component.html',
  styleUrls: ['./bank-account-update-dialog.component.scss']
})
export class BankAccountUpdateDialogComponent {
  actionGerund: string;
  accountType: string;
  bankAccountDesignationEntity: string;

  get BankAccountType() { return BankAccountType; }

  constructor(public dialogRef: MatDialogRef<BankAccountUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.actionGerund = this.data.action.substring(0, this.data.action.length - 1) + "ing";
    this.accountType = EnumHelper.getNameFromValue(BankAccountType, this.data.form.controls.bankAccountType.value);
    let designationOptions = EnumHelper.getMappedNamesAndValues(BankAccountRole, BankAccountRoleLabel).filter(_ => _.name !== undefined);
    this.bankAccountDesignationEntity = this.data.form.controls.bankAccountDesignationEntity.value
    ? designationOptions.filter(_ => _.value === this.data.form.controls.bankAccountDesignationEntity.value)[0].name
    : null;
  }

  static show(dialog: MatDialog, form: UntypedFormGroup, action: string): Observable<any> {
    return dialog.open(BankAccountUpdateDialogComponent, {
      width: '510px',
      data: {
        form: form,
        action: action
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

}
