import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { routerTransition } from '../../shared/animations/router.animations';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';

@Component({
  selector: 'ifb-advisor-profile',
  templateUrl: './advisor-profile.component.html',
  styleUrls: ['./advisor-profile.component.css'],
  animations: routerTransition
})
export class AdvisorProfileComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  canEditAdvisor = false;

  constructor(
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));

    this.userPermissionService.granted([readWrite('servicing-advisors')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canEditAdvisor = res;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  data: any;
  dataSet(data: any) {
    this.data = data;
  }
}
