<div class="content layout padding-top" fxLayout.gt-md="row" fxLayoutAlign.gt-md="center start" fxLayoutGap.gt-md="48px"
  fxLayout="column" fxLayoutAlign="start center" fxFlexFill.lt-lg ngClass.lt-lg="fit-content">

  <!-- #region Form -->
  <form [formGroup]="form">

    <div fxLayout.gt-md="row" fxLayoutAlign.gt-md="start start" fxLayoutGap.gt-md="48px" fxLayout="column"
      fxLayoutAlign="start center">
      <div>

        <div fxLayout="column" fxLayoutAlign="start start" [ngClass.xl]="'form-field-lg'" [ngClass.lg]="'form-field-md'"
          [ngClass.lt-lg]="'form-field-lg'" [ngClass.xs]="'form-field-xs'">

          <mat-form-field appearance="outline">
            <mat-label>Legal entity name</mat-label>
            <input matInput type="text" name="businessName" formControlName="businessName"
              placeholder="Legal entity name">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Bank name</mat-label>
            <input matInput type="text" name="bankName" formControlName="bankName" placeholder="Bank name">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Bank account type</mat-label>
            <mat-select matInput name="bankAccountType" formControlName="bankAccountType"
              placeholder="Bank account type">
              <mat-option *ngFor="let option of bankAccountTypeOptions" [value]="option.value">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Account number</mat-label>
            <input matInput type="text" name="accountNumber" formControlName="accountNumber"
              placeholder="Account number">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Routing number</mat-label>
            <input matInput type="text" name="routingNumber" formControlName="routingNumber"
              placeholder="Routing number">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Bank designation (global)</mat-label>
            <mat-select matInput name="bankAccountDesignationEntity" formControlName="bankAccountDesignationEntity" placeholder="Bank designation (global)">
              <mat-option *ngFor="let option of globalDesignationOptions" [value]="option.value">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Verification status</mat-label>
            <mat-select matInput name="verificationStatus" formControlName="verificationStatus"
              placeholder="Verification status">
              <mat-option [value]="true"> Verified </mat-option>
              <mat-option [value]="false"> Unverified </mat-option>
            </mat-select>
          </mat-form-field>

          <ifc-datepicker [fc]="form.get('lastCheckDate')" [label]="'Verification date'"
            [disabled]="!canEditVerificationDate" (clearInput)="clearDate('lastCheckDate')"></ifc-datepicker>

          <button mat-button *ngIf="canPullExtraInfo" (click)="syncExtraInfo()" [disabled]="data.viewMode">
            <mat-icon class="layout margin-right-sm">sync</mat-icon>Sync account
          </button>

        </div>

      </div>
    </div>

  </form>
  <!-- #endregion -->

  <!-- #region Bank account details-->
  <div>
    <mat-card [ngClass.xl]="'card lg'" [ngClass.lg]="'card md'" [ngClass.lt-lg]="'card lg'" [ngClass.xs]="'card xs'"
      class="mat-elevation-z0">
      <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
        <span class="card-title">Bank account details</span>
      </div>
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <span class="item-title secondary-text" mat-line>Link status</span>
            <ifb-bank-account-link-status-icon-chip [status]="linkStatus"></ifb-bank-account-link-status-icon-chip>
          </mat-list-item>
          <mat-list-item *ngIf="data.bankAccount?.syncedBankAccount?.providerMsg" class="full-height">
            <p mat-line>{{data.bankAccount?.syncedBankAccount?.providerMsg}}</p>
          </mat-list-item>
          <mat-list-item class="full-height" *ngIf="data.bankAccount?.missingInformation?.length">
            <div>
              <div class="header">Missing information</div>
              <ul>
                <li class="secondary-text" *ngFor="let info of data.bankAccount.missingInformation">
                  {{info}}
                </li>
              </ul>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="item-title secondary-text" mat-line>Created on</span>
            <span class="item-content">{{(data.bankAccount?.createdOn | date) || '-'}}</span>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="item-title secondary-text" mat-line>Created by</span>
            <span class="item-content">{{data.bankAccount?.createdBy || '-'}}</span>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="item-title secondary-text" mat-line>Updated on</span>
            <span class="item-content">{{(data.bankAccount?.updatedOn | date) || '-'}}</span>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="item-title secondary-text" mat-line>Updated by</span>
            <span class="item-content">{{data.bankAccount?.updatedBy || '-'}}</span>
          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
      </mat-card-content>
    </mat-card>

    <form [formGroup]="form" class="layout padding-top scroll-margin-bottom">
      <mat-checkbox color="primary" formControlName="screenShareRequired">Screen share required</mat-checkbox>
    </form>

  </div>
  <!-- #endregion -->

  <!-- #region Bank integration account details-->
  <mat-card *ngIf="data.bankAccount && data.bankAccount?.syncedBankAccount" [ngClass.xl]="'card lg'"
    [ngClass.lg]="'card md'" [ngClass.lt-lg]="'card lg'" [ngClass.xs]="'card xs'" class="mat-elevation-z0">
    <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
      <span class="card-title">Bank integration details</span>
    </div>
    <mat-card-content>
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Balance</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.balance | currency: '$'}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Balance date</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.balanceDate | date}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Type</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.type}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Name</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.name}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Bank name</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.institutionName}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Account number</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.accountNumber}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Routing number</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.routingNumber}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="full-height">
          <span class="item-title secondary-text" mat-line>Account owner</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.ownerName || '-'}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="full-height">
          <span class="item-title secondary-text" mat-line>Account owner address</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.ownerAddress || '-'}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Status</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.status | titlecase}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Created date</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.createdDate | date }}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Currency</span>
          <span class="item-content">{{data.bankAccount?.syncedBankAccount?.currency }}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Customer ID</span>
          <span class="item-content">
            <a title="{{data.bankAccount?.syncedBankAccount?.providerName}} customer" routerLink="{{providerCustomerLink}}">
              {{data.bankAccount?.syncedBankAccount?.customerId}}
            </a>
          </span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Account ID</span>
          <span class="item-content">
            <a title="{{data.bankAccount?.syncedBankAccount?.providerName}} account" routerLink="{{providerAccountLink}}">
              {{data.bankAccount?.syncedBankAccount?.id}}
            </a>
          </span>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
  <!-- #endregion -->

</div>