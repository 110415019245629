<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center"
      fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Show only pending accounts</mat-label>
        <mat-select formControlName="onlyPendingAccounts">
          <mat-option [value]="true">True</mat-option>
          <mat-option [value]="false">False</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>