import { NgModule } from "@angular/core";
import { CommonModule } from 'common';
import { SharedModule } from "../shared/shared.module";
import { UserModule } from "../admin/user/user.module";
import { EntityRoutingModule } from "./entity-routing.module";
import { EntityListComponent } from "./entity-list/entity-list.component";
import { EntityListFilterDialogComponent } from "./entity-list-filter-dialog/entity-list-filter-dialog.component";
import { EntityProfileComponent } from './entity-profile/entity-profile.component';
import { EntityProfileStatusWidgetComponent } from './entity-profile-status-widget/entity-profile-status-widget.component';
import { EntityProfileCompanyInfoWidgetComponent } from './entity-profile-company-info-widget/entity-profile-company-info-widget.component';
import { EntityProfileCompanyInfoComponent } from './entity-profile-company-info/entity-profile-company-info.component';
import { EntityProfileBrokerWidgetComponent } from './entity-profile-broker-widget/entity-profile-broker-widget.component';
import { EntityAppBarTitleComponent } from './entity-app-bar-title/entity-app-bar-title.component';
import { EntityNumberInputComponent } from "./entity-number-input/entity-number-input.component";
import { EntityProfileCashFlowWidgetComponent } from "./entity-profile-cash-flow-widget/entity-profile-cash-flow-widget.component";
import { AccountModule } from "../account/account.module";
import { BankModule } from "../bank/bank.module";
import { EntityAuditTrailComponent } from './entity-audit-trail/entity-audit-trail.component';
import { EntityInfoUpdateDialogComponent } from './entity-info-update-dialog/entity-info-update-dialog.component';
import { EntityEINInputComponent } from "./entity-ein-input/entity-ein-input.component";
import { EntityWelcomeCallWidgetComponent } from './entity-welcome-call-widget/entity-welcome-call-widget.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        UserModule,
        EntityRoutingModule,
        AccountModule,
        BankModule
    ],
    exports: [
        EntityNumberInputComponent,
        EntityEINInputComponent
    ],
    declarations: [
        EntityNumberInputComponent,
        EntityEINInputComponent,
        EntityProfileCashFlowWidgetComponent,
        EntityListComponent,
        EntityListFilterDialogComponent,
        EntityProfileComponent,
        EntityProfileStatusWidgetComponent,
        EntityProfileCompanyInfoWidgetComponent,
        EntityProfileCompanyInfoComponent,
        EntityProfileBrokerWidgetComponent,
        EntityAppBarTitleComponent,
        EntityAuditTrailComponent,
        EntityInfoUpdateDialogComponent,
        EntityWelcomeCallWidgetComponent
    ]
})
export class CompanyModule { }
