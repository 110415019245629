export interface Note {
    initiator: Initiator;
    date: Date;
    text: string;
}

export class NoteData implements Note {
    constructor(public text: string, public initiator: Initiator) {
        this.date = new Date();
    }
    date: Date;
}

export interface Initiator {
    FullName: string;
}

export class InitiatorData implements Initiator {
    constructor(public FullName: string) { }
}
