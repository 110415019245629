import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ResourceService, EntityBaseData,
  EntityData,
  EntityExData,
  EntityQueryParams,
  EntityQueryResult
} from 'common';
import { CustomerData } from '../customer/customer.model';
import { AppSettings } from '../../app.settings';
import { EntityHintData, EntityHintParams } from './entity.model';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  private readonly baseUrl = `${this.settings.entities.url}/api/entity`;

  constructor(private http: HttpClient, private resourceService: ResourceService, private settings: AppSettings) { }

  prevEntityListUrl: string;

  query(params: EntityQueryParams): Observable<EntityQueryResult> {
    if (!params.sort)
      params.sort = '+name';
    return this.http.get<EntityQueryResult>(`${this.baseUrl}/query`, {
      params: <any>params
    });
  }

  queryHints(query: EntityHintParams): Observable<EntityHintData[]> {
    return this.http.get<EntityHintData[]>(`${this.baseUrl}/hints`, {
      params: <any>query
    });
  }

  get(id: number): Observable<EntityExData> {
    return this.http.get<EntityExData>(`${this.baseUrl}/${id}`);
  }

  download(params: EntityQueryParams) {
    this.resourceService.download(`${this.baseUrl}/csv`, "entities.csv", params);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  update(id: number, entity: EntityBaseData): Observable<EntityData> {
    return this.http.patch<EntityData>(`${this.baseUrl}/${id}`, entity);
  }

  create(entity: EntityBaseData): Observable<EntityData> {
    return this.http.post<EntityData>(`${this.baseUrl}`, entity);
  }

  updateEntityAdvisor(entityId: number, advisorId: number): Observable<any> {
    return this.http.patch(`${this.baseUrl}/${entityId}/advisor`, {advisorId: advisorId});
  }

  getOwnersList(id: number): Observable<CustomerData[]> {
    return this.http.get<CustomerData[]>(`${this.baseUrl}/${id}/owners`);
  }

}

