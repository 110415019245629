import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuditTrailData } from "./audit-trail.model";
import { AuditTrailService } from "./audit-trail.service";

@Injectable({
    providedIn: 'root'
})
export class AuditTrailResolver implements Resolve<AuditTrailData> {
    constructor(private service: AuditTrailService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<AuditTrailData> | Promise<AuditTrailData> | AuditTrailData {
        return this.service.getById(route.params.id);
    }
}
