<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info" *ngIf="!data.userQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table *ngIf="data.userQueryResult.totalCount" mat-table matSort [dataSource]="data.userQueryResult.values"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="clientName"> Client </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
            {{row.clientName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="username"> Username/email </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
            {{row.username}}
          </td>
        </ng-container>
        <ng-container matColumnDef="externalId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="externalId"> External ID </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
            {{row.externalId}}
          </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName"> First name </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
            {{row.firstName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="middleName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="middleName"> Middle name </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell">
            {{row.middleName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName"> Last name </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell"> {{row.lastName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastLoginDate_IP">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="lastLogin"> Last login time/IP address </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell"> {{row.lastLogin
            |
            date:'medium'}} {{row.lastLogin && row.lastLoginAddress ? '/' : ''}} {{row.lastLoginAddress}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="active"> Status </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/user/{{row.id}}" class="table-cell"> {{row.active ?
            'Active' : 'Inactive'}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.userQueryResult.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.userQueryResult.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>
