<form [formGroup]="form">
  <mat-dialog-content class="mat-typography">
    <h3>Set filter</h3>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">
      <div fxFlex
           class="mat-form-field">
        <ifb-promotion-name-input [fc]="form.get('name')"></ifb-promotion-name-input>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select formControlName="active"
                    placeholder="Status">
          <mat-option>All</mat-option>
          <mat-option [value]="'true'">Active</mat-option>
          <mat-option [value]="'false'">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">
      <ifc-filter-param-date-range-input [fc]="form.get('createdOn')"
                                         [label]="'Created on'"
                                         (rangeSelected)="createdOnDateSelected($event)">
      </ifc-filter-param-date-range-input>
      <mat-form-field appearance="outline">
        <mat-label>Product type</mat-label>
        <mat-select formControlName="productCode"
                    placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of productTypeOptions"
                      [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px"
         *ngIf="form.get('createdOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('createdOnFrom')"
                      [label]="'Created on from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('createdOnTo')"
                      [label]="'Created on to'"></ifc-datepicker>
    </div>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">
      <ifc-filter-param-date-range-input [fc]="form.get('startDate')"
                                         [label]="'Start date'"
                                         (rangeSelected)="startDateSelected($event)">
      </ifc-filter-param-date-range-input>
      <ifc-filter-param-date-range-input [fc]="form.get('endDate')"
                                         [label]="'End date'"
                                         (rangeSelected)="endDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px"
         *ngIf="form.get('startDate').value === 'custom'">
      <ifc-datepicker [fc]="form.get('startDateFrom')"
                      [label]="'Start date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('startDateTo')"
                      [label]="'Start date to'"></ifc-datepicker>
    </div>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px"
         *ngIf="form.get('endDate').value === 'custom'">
      <ifc-datepicker [fc]="form.get('endDateFrom')"
                      [label]="'End date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('endDateTo')"
                      [label]="'End date to'"></ifc-datepicker>
    </div>

    <div fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Transaction type</mat-label>
        <mat-select formControlName="transactionType"
                    placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of transactionTypeOptions"
                      [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Promotion type</mat-label>
        <mat-select formControlName="promotionType"
                    placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of promotionTypeOptions"
                      [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions
       class="flex row end-center">
    <button mat-button
            [mat-dialog-close]="">Cancel</button>
    <button mat-button
            [mat-dialog-close]="filters"
            cdkFocusInitial
            color="primary"
            [disabled]="form.invalid">Ok</button>
  </div>
</form>
