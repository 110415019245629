import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ObjectHelper, EntityType, EntityQueryParams, EntityStatus, FilterRange, LinkingStatus, EnumHelper } from 'common';
import { EntityFilters, EntityTypeLabel, EntityStatusLabel } from '../entity.model';

@Component({
    selector: 'ifb-entity-list-filter-dialog',
    templateUrl: './entity-list-filter-dialog.component.html',
    styleUrls: ['./entity-list-filter-dialog.component.scss']
})
export class EntityListFilterDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    form: UntypedFormGroup;
    filters: EntityFilters;
    entityTypes = EnumHelper.getMappedNamesAndValues(EntityType, EntityTypeLabel);
    entityStatuses = EnumHelper.getMappedNamesAndValues(EntityStatus, EntityStatusLabel);
    bankLinkStatusOptions = EnumHelper.getNamesAndValues(LinkingStatus);

    constructor(
        public dialogRef: MatDialogRef<EntityListFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: UntypedFormBuilder) {

        this._unsubscribeAll = new Subject();
        this.filters = new EntityFilters(data.query);

        this.form = this._formBuilder.group({
            uniqueId: this.data.query.uniqueId || '',
            advisorId: this.data.query.advisorId || '',
            types: { value: ObjectHelper.makeArray(this.data.query.types) || '', disabled: false },
            statuses: { value: ObjectHelper.makeArray(this.data.query.statuses) || '', disabled: false },
            name: this.data.query.businessName || '',
            number: this.data.query.number || '',
            createdOn: this.data.query.createdOn || '',
            createdBy: this.data.query.createdBy || '',
            createdOnStart: new UntypedFormControl(this.data.query.createdOnStart ? new Date(this.data.query.createdOnStart) : '', { validators: null, updateOn: 'blur' }),
            createdOnEnd: new UntypedFormControl(this.data.query.createdOnEnd ? new Date(this.data.query.createdOnEnd) : '', { validators: null, updateOn: 'blur' }),
            updatedOn: this.data.query.updatedOn || '',
            updatedBy: this.data.query.updatedBy || '',
            updatedOnStart: new UntypedFormControl(this.data.query.updatedOnStart ? new Date(this.data.query.updatedOnStart) : '', { validators: null, updateOn: 'blur' }),
            updatedOnEnd: new UntypedFormControl(this.data.query.updatedOnEnd ? new Date(this.data.query.updatedOnEnd) : '', { validators: null, updateOn: 'blur' }),
            active: this.data.query.active || '',
            bankLinkStatus: +this.data.query.bankLinkStatus >= 0 ? +this.data.query.bankLinkStatus : undefined,
            lastLinkingAttemptDate: this.data.query.lastLinkingAttemptDate || '',
            lastLinkingAttemptDateFrom: new UntypedFormControl(this.data.query.lastLinkingAttemptDateFrom ? new Date(this.data.query.lastLinkingAttemptDateFrom) : '', { validators: null, updateOn: 'blur' }),
            lastLinkingAttemptDateTo: new UntypedFormControl(this.data.query.lastLinkingAttemptDateTo ? new Date(this.data.query.lastLinkingAttemptDateTo) : '', { validators: null, updateOn: 'blur' }),
            bankLinkAttempted: this.data.query.bankLinkAttempted || '',
            isVerified: this.data.query.isVerified || '',
            ein: this.data.query.ein || '',
        });
    }

    static show(dialog: MatDialog, query: EntityQueryParams): Observable<any> {
        return dialog.open(EntityListFilterDialogComponent, {
            data: { query },
            closeOnNavigation: true,
        }).afterClosed();
    }

    ngOnInit(): void {
        this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.filters = new EntityFilters(data);
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }



    createdDateSelected(dataRange: FilterRange<moment.Moment>) {
        this.form.patchValue({
            createdOnStart: dataRange.from,
            createdOnEnd: dataRange.to
        });
    }

    updatedDateSelected(dataRange: FilterRange<moment.Moment>) {
        this.form.patchValue({
            updatedOnStart: dataRange.from,
            updatedOnEnd: dataRange.to
        });
    }

    lastLinkingAttemptDateSelected(dataRange: FilterRange<moment.Moment>) {
        this.form.patchValue({
            lastLinkingAttemptDateFrom: dataRange.from,
            lastLinkingAttemptDateTo: dataRange.to
        });
    }


}
