<mat-card class="ifc-card" [ngClass.xs]="'xs'">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md padding-bottom-0">
      <div class="card-title">Statements</div>
      <button mat-icon-button *ngIf="canEditAccountDocumentsGeneration" routerLink="statements" aria-label="Edit">
        <mat-icon class="edit-icon">edit</mat-icon>
      </button>
    </div>
    <mat-card-content>
      <mat-list>
        <mat-list-item>
          <span class="secondary-text" mat-line>Alligator data</span>
            <ifb-account-alligator-data-icon-chip
              [alligatorDataSync]="data?.alligatorMatch"></ifb-account-alligator-data-icon-chip>
        </mat-list-item>
        <mat-list-item *ngIf="canViewAccountDocumentsGeneration">
            <span class="secondary-text" mat-line>Statement generation</span>
            <p class="item-content">
                 {{data | accountStatementGenerationLabel}}
            </p>
        </mat-list-item>
        <mat-list-item *ngIf="canViewAccountDocumentsGeneration && data?.generateStatements">
          <span class="secondary-text" mat-line>Expires</span>
          <p class="item-content">
            {{data?.generateStatementsExpirationDate | date: "hh:mm a - MMM dd, yyyy"}}
          </p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
  