import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppBarTitleService, AppPageService } from 'common';
import { UserData } from '../user.model';
import { UserRoleData } from '../../../user/user-role/user-role.data';

@Component({
  selector: 'ifb-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})

export class UserDetailsComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService) { }

  cancel() {
    this.appPageService.back();
  }

  data: UserDetailsComponentData;
  roles: UserRoleData[];

  dataSet(data: UserDetailsComponentData) {
    this.data = data;
    this.roles = data.user.roles;
    this.appBarTitleService.title = data.user.firstName && data.user.lastName ? data.user.firstName + ' ' + data.user.lastName : '';
  }

  phoneFormat(phone: string) {
    return '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6);
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this))
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }
}

export interface UserDetailsComponentData {
  user: UserData;
  roles: UserRoleData[];
}

