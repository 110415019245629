import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { AccountAppBarExpandedComponent } from "./account-app-bar-expanded/account-app-bar-expanded.component";
import { AccountAppBarTitleComponent } from "./account-app-bar-title/account-app-bar-title.component";
import { AccountEconomicsComponent } from "./account-economics/account-economics.component";
import { AccountListFilterCustomDateDialogComponent } from "./account-list-filter-dialog/account-list-filter-custom-date-dialog/account-list-filter-custom-date-dialog.component";
import { AccountListFilterDialogComponent } from "./account-list-filter-dialog/account-list-filter-dialog.component";
import { AccountListComponent } from "./account-list/account-list.component";
import { AccountRoutingModule } from "./account-routing.module";
import { AccountStatusIconChipComponent } from "./account-status-icon-chip/account-status-icon-chip.component";
import { AccountPaymentListComponent } from "./account-payment-list/account-payment-list.component";
import { AccountStateBalanceDetailsComponent } from "./account-state-balance-details/account-state-balance-details.component";
import { AccountStateWidgetComponent } from "./account-state-widget/account-state-widget.component";
import { AccountProfileComponent } from "./account-profile/account-profile.component";
import { AccountProfileOtherInfoWidgetComponent } from "./account-profile-other-info-widget/account-profile-other-info-widget.component";
import { AccountProfileCompanyInfoWidgetComponent } from "./account-profile-company-info-widget/account-profile-company-info-widget.component";
import { AccountProfileCompanyInfoComponent } from "./account-profile-company-info/account-profile-company-info.component";
import { AccountProfileStatusWidgetComponent } from "./account-profile-status-widget/account-profile-status-widget.component";
import { AccountProfileStatusDetailsComponent } from "./account-profile-status-details/account-profile-status-details.component";
import { AccountOfferDetailsComponent } from "./account-offer-details/account-offer-details.component";
import { AccountOfferWidgetComponent } from "./account-offer-widget/account-offer-widget.component";
import { AccountAuditTrailComponent } from "./account-audit-trail/account-audit-trail.component";
import { CashFlowAnalysisModule } from "../shared/cash-flow-analysis/cash-flow-analysis.module";
import { ReAmortizeDialogComponent } from "./account-state-balance-details/re-amortize-dialog/re-amortize-dialog.component";
import { AccountOtherInfoDetailsComponent } from "./account-profile-other-info-details/account-profile-other-info-details.component";
import { AccountModificationListComponent } from "./account-modification-list/account-modification-list.component";
import { AccountModificationDetailsComponent } from "./account-modification-details/account-modification-details.component";
import { AccountModificationUpdateDialogComponent } from "./account-modification-update-dialog/account-modification-update-dialog.component";
import { AccountModificationIconChipComponent } from "./account-modification-icon-chip/account-modification-icon-chip.component";
import { AccountOfferUpdateDialogComponent } from "./account-offer-update-dialog/account-offer-update-dialog.component";
import { AccountStateBalanceUpdateDialogComponent } from "./account-state-balance-update-dialog/account-state-balance-update-dialog.component";
import { AccountStatusComponent } from "./account-status/account-status.component";
import { AccountLiquidityEventWidgetComponent } from './account-liquidity-event-widget/account-liquidity-event-widget.component';
import { AccountLiquidityEventAddComponent } from './account-liquidity-event-add/account-liquidity-event-add.component';
import { AccountStatementsWidgetComponent } from './account-statements-widget/account-statements-widget.component';
import { AccountStatementsEditComponent } from './account-statements-widget/components/account-statements-edit/account-statements-edit.component';
import { AccountStatementsUpdateDialogComponent } from './account-statements-widget/components/account-statements-update-dialog/account-statements-update-dialog.component';
import { AccountStatementGenerationLabelPipe } from './account-statements-widget/pipes/account-statement-generation-label.pipe';
import { AccountAlligatorDataIconChipComponent } from "./account-statements-widget/components/account-alligator-data-icon-chip/account-alligator-data-icon-chip.component";
import { AlligatorDataClassPipe } from "./account-list/pipes/alligator-data-class.pipe";
import { AlligatorDataIconPipe } from "./account-list/pipes/alligator-data-icon.pipe";
import { AlligatorDataTooltipPipe } from "./account-list/pipes/alligator-data-tooltip.pipe";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    AccountRoutingModule,
    CashFlowAnalysisModule,
    UppyAngularDashboardModule,
  ],
  exports: [AccountPaymentListComponent, AccountStatusIconChipComponent],
  declarations: [
    AccountAppBarExpandedComponent,
    AccountAppBarTitleComponent,
    AccountEconomicsComponent,
    AccountListComponent,
    AccountListFilterCustomDateDialogComponent,
    AccountListFilterDialogComponent,
    AccountStatusIconChipComponent,
    AccountPaymentListComponent,
    AccountStateBalanceDetailsComponent,
    AccountStateWidgetComponent,
    AccountProfileComponent,
    AccountProfileOtherInfoWidgetComponent,
    AccountProfileCompanyInfoWidgetComponent,
    AccountProfileCompanyInfoComponent,
    AccountOfferWidgetComponent,
    AccountOfferDetailsComponent,
    AccountProfileStatusWidgetComponent,
    AccountProfileStatusDetailsComponent,
    AccountAuditTrailComponent,
    ReAmortizeDialogComponent,
    AccountOtherInfoDetailsComponent,
    AccountModificationListComponent,
    AccountModificationDetailsComponent,
    AccountModificationUpdateDialogComponent,
    AccountModificationIconChipComponent,
    AccountOfferUpdateDialogComponent,
    AccountStateBalanceUpdateDialogComponent,
    AccountStatusComponent,
    AccountLiquidityEventWidgetComponent,
    AccountLiquidityEventAddComponent,
    AccountStatementsWidgetComponent,
    AccountStatementsEditComponent,
    AccountStatementsUpdateDialogComponent,
    AccountStatementGenerationLabelPipe,
    AccountAlligatorDataIconChipComponent,
    AlligatorDataClassPipe,
    AlligatorDataIconPipe,
    AlligatorDataTooltipPipe
  ],
})
export class AccountModule {}
