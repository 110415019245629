<div>
  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [default]="true">
    <div class="table-container">
      <div class="message-box info" *ngIf="!data.bankCustomerQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table aria-label="List of bank customers" mat-table *ngIf="data.bankCustomerQueryResult.totalCount"
        [dataSource]="data.bankCustomerQueryResult.values">
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef> Created date </th>
          <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankCustomerAccountListLink(row.id)">
            {{row.createdDate | date}}
          </td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> Username </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <a href="/company?bankIntegrationUsername={{row.username}}">{{row.username}}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankCustomerAccountListLink(row.id)">
            {{row.id}}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankCustomerAccountListLink(row.id)">
            {{row.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="bank">
          <th mat-header-cell *matHeaderCellDef> Bank </th>
          <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankCustomerAccountListLink(row.id)">
            {{row.bank}}
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let row" class="table-cell" style="text-align: right">
            <button *ngIf="canDeleteBankCustomer" mat-icon-button class="delete-button" (click)="delete(row.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" [ngClass]="{'table-row': canReadBankingAccount}"></tr>
      </table>

    </div>
  </ifc-app-scroll-container>

  <div [hidden]="!data.bankCustomerQueryResult || data.bankCustomerQueryResult.totalCount <= 10">
    <mat-paginator showFirstLastButtons [length]="data.bankCustomerQueryResult.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>