import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { FeatureFlag, FeatureFlagService } from "common";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagListResolver implements Resolve<FeatureFlag[]> {
    constructor(private _service: FeatureFlagService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FeatureFlag[]> | Promise<FeatureFlag[]> | FeatureFlag[] {
        return route.params.id
            ? this._service.listByTenant(route.params.id)
            : this._service.list();
    }
}
