import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarActionsService,
  AppBarAction,
  FormHelper,
  AppBarTitleService,
  MatErrorData,
} from "common";
import {
  getActionsConfig,
  getFormErrorData,
  getRenewalsFormModel,
} from "./data/application-renewals-edit-data";
import { ApplicationRenewalsEditFacade } from "./application-renewals-edit.facade";
import { ApplicationRenewalsEditData } from "./models/application-renewals-edit-data.model";
import { AccountService } from "projects/backoffice/src/app/account/account.service";

@UntilDestroy()
@Component({
  selector: "ifb-application-renewals-edit",
  templateUrl: "./application-renewals-edit.component.html",
  styleUrls: ["./application-renewals-edit.component.scss"],
  providers: [ApplicationRenewalsEditFacade],
})
export class ApplicationRenewalsEditComponent implements OnInit {
  renewalsForm: UntypedFormGroup;
  data: ApplicationRenewalsEditData;
  formErrorData: MatErrorData[];

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private facade: ApplicationRenewalsEditFacade,
    private accountService: AccountService
  ) {
    this.initData();
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.updateData.bind(this));

    this.renewalsForm.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(this.updateActionButtons.bind(this));

    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
  }

  private initData() {
    this.renewalsForm = this.formBuilder.group(
      getRenewalsFormModel(this.accountService)
    );
    this.formErrorData = getFormErrorData;
  }

  private updateData(data: ApplicationRenewalsEditData) {
    this.data = data;

    const formData = {
      renewalForLoanNumber: this.data.renewal?.renewalForLoanNumber,
      renewalReady: this.data.renewal?.renewalReady,
    };
    this.renewalsForm.patchValue(formData);

    this.appBarTitleService.title = "Renewals";
    this.appBarActionsService.actions = getActionsConfig;
  }

  updateActionButtons() {
    if (!this.renewalsForm.pending) {
      this.facade.updateActionButtons(this.renewalsForm.valid);
    }
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  cancel(): void {
    this.facade.cancel();
  }

  save() {
    if (!this.renewalsForm.valid) {
      FormHelper.showInvalidFormFields(this.renewalsForm);
      return;
    }

    if (!this.renewalsForm.dirty) {
      this.cancel();
      return;
    }

    this.facade.save(this.data.renewal?.applicationId, this.renewalsForm);
  }
}
