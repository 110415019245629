import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material/material.module';
import { ContentSliderComponent } from './content-slider/content-slider.component';
import { AccountBaseService, CommonModule } from 'common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkExpandedRowDirective } from './cdk-expanded-row/cdk-expanded-row.directive';
import { NgChartsModule } from 'ng2-charts';
import { DateAdapter } from '@angular/material/core';
import { FedDateAdapter } from '../../../../common/src/lib/ui/datepicker/date-adapter.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TermLoanChartComponent } from './charts/term-loan-chart/term-loan-chart.component';
import { BusinessAdvisorWidgetComponent } from './business-advisor/business-advisor-widget/business-advisor-widget.component';
import { BusinessAdvisorPickerComponent } from './business-advisor/business-advisor-picker/business-advisor-picker.component';
import { BusinessAdvisorInputComponent } from './business-advisor/business-advisor-input/business-advisor-input.component';
import { BusinessNameInputComponent } from './business-name-input/business-name-input.component';
import { CardComponent } from './card/card.component';
import { PanelComponent } from './panel/panel.component';
import { NoteCardComponent } from './note/note-card/note-card.component';
import { AccountNotesSidenavComponent } from './notes-sidenav/account-notes-sidenav/account-notes-sidenav.component';
import { VerticalBarChartComponent } from './charts/vertical-bar-chart/vertical-bar-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GroupedHorizontalBarChartComponent } from './charts/grouped-horizontal-bar-chart/grouped-horizontal-bar-chart.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { HorizontalBartChartComponent } from './charts/horizontal-bart-chart/horizontal-bart-chart.component';
import { VerticalStackedBarChartComponent } from './charts/vertical-stacked-bar-chart/vertical-stacked-bar-chart.component';
import { ApplicationNotesSidenavComponent } from './notes-sidenav/application-notes-sidenav/application-notes-sidenav.component';
import { OwnersWidgetComponent } from './owners-widget/owners-widget.component';
import { AccountService } from '../account/account.service';
import { SalesforceUrlPipe } from './pipes/salesforce-url.pipe';
import { UpdateDialogComponent } from './update-dialog/update-dialog.component';
import { SignatureComponent } from './document/signature/signature.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        NgChartsModule,
        NgxChartsModule,
        RouterModule
    ],
    exports: [
        MaterialModule,
        ContentSliderComponent,
        FlexLayoutModule,
        CdkExpandedRowDirective,
        TermLoanChartComponent,
        BusinessAdvisorWidgetComponent,
        BusinessAdvisorPickerComponent,
        BusinessAdvisorInputComponent,
        BusinessNameInputComponent,
        CardComponent,
        PanelComponent,
        NoteCardComponent,
        AccountNotesSidenavComponent,
        VerticalBarChartComponent,
        GroupedHorizontalBarChartComponent,
        LineChartComponent,
        HorizontalBartChartComponent,
        VerticalStackedBarChartComponent,
        ApplicationNotesSidenavComponent,
        OwnersWidgetComponent,
        SalesforceUrlPipe,
        UpdateDialogComponent,
        SignatureComponent
    ],
    declarations: [
        ContentSliderComponent,
        CdkExpandedRowDirective,
        ConfirmDialogComponent,
        TermLoanChartComponent,
        BusinessAdvisorWidgetComponent,
        BusinessAdvisorPickerComponent,
        BusinessAdvisorInputComponent,
        BusinessNameInputComponent,
        CardComponent,
        PanelComponent,
        VerticalBarChartComponent,
        GroupedHorizontalBarChartComponent,
        LineChartComponent,
        HorizontalBartChartComponent,
        VerticalStackedBarChartComponent,
        PanelComponent,
        NoteCardComponent,
        AccountNotesSidenavComponent,
        ApplicationNotesSidenavComponent,
        OwnersWidgetComponent,
        SalesforceUrlPipe,
        UpdateDialogComponent,
        SignatureComponent
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: FedDateAdapter
        },
        {
            provide: AccountBaseService,
            useClass: AccountService
        }
    ]
})
export class SharedModule { }
