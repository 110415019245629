<div class="layout" fxLayout="column" fxLayoutAlign="center center" fxFlexFill.lt-lg ngClass.lt-lg="fit-content"
  fxFlex="100">

  <form [formGroup]="form" ngClass.xs="layout fill">

    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label>Bank name</mat-label>
          <input matInput type="text" name="bankName" formControlName="bankName" placeholder="Bank name" maxlength="128">
        </mat-form-field>
      </div>

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label>Routing number</mat-label>
          <input matInput type="text" customMask="000000000" name="routingNumber" formControlName="routingNumber" placeholder="Routing number" maxlength="9">
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label>Account number</mat-label>
          <input matInput type="text" customMask="0000000000000000000000000000000000000000000000000000000000000000" name="accountNumber" formControlName="accountNumber" placeholder="Account number" maxlength="64">
        </mat-form-field>
      </div>

    </div>

  </form>

</div>
