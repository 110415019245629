<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <mat-stepper #stepper
               (selectionChange)="onStepChange($event)">
    <mat-step label="Promotion"
              [stepControl]="promotionForm"
              errorMessage="Invalid data">
      <ng-container *ngTemplateOutlet="promotionStep"></ng-container>
    </mat-step>

    <mat-step label="Conditions"
              [stepControl]="conditionsForm"
              errorMessage="Invalid data">
      <ng-container *ngTemplateOutlet="conditionsStep"></ng-container>
    </mat-step>
  </mat-stepper>
</ifc-app-scroll-container>
<div class="actions">
  <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
</div>

<ng-template #promotionStep>
  <form [formGroup]="promotionForm">
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Name</mat-label>
        <input matInput
               formControlName="name"
               type="text"
               placeholder="Name"
               maxlength="32" />
      </mat-form-field>

      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Product type</mat-label>
        <mat-select matInput
                    formControlName="productCode"
                    placeholder="Product type">
          <mat-option *ngFor="let option of productTypeOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      class="description">
        <mat-label>Description</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="7"
                  formControlName="description"></textarea>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <ifc-datepicker [fc]="promotionForm.get('startDate')"
                      class="date"
                      [label]="'Start date'"></ifc-datepicker>
      <ifc-datepicker [fc]="promotionForm.get('endDate')"
                      class="date"
                      [label]="'End date'"
                      [min]="promotionForm.get('startDate')?.value"></ifc-datepicker>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Transaction type</mat-label>
        <mat-select matInput
                    formControlName="transactionType"
                    placeholder="Transaction type">
          <mat-option *ngFor="let option of transactionTypeOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Promotion type</mat-label>
        <mat-select matInput
                    formControlName="promotionType"
                    placeholder="Promotion type">
          <mat-option *ngFor="let option of promotionTypeOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="promotionForm.get('promotionType')?.value === PromotionType.DrawFeeReduction">
        <mat-label>Draw fee reduction type</mat-label>
        <mat-select matInput
                    formControlName="reductionType"
                    placeholder="Draw fee reduction type">
          <mat-option *ngFor="let option of feeTypeOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
            <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="promotionForm.get('promotionType')?.value === PromotionType.DrawFeeOverride">
        <mat-label>Draw fee override type</mat-label>
        <mat-select matInput
                    formControlName="reductionType"
                    placeholder="Draw fee override type">
          <mat-option *ngFor="let option of feeTypeOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="promotionForm.get('promotionType')?.value === PromotionType.PayoffInterestReduction">
        <mat-label>Interest rate discount type</mat-label>
        <mat-select matInput
                    formControlName="reductionType"
                    placeholder="Interest rate discount type">
          <mat-option *ngFor="let option of feeTypeOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="promotionForm.get('promotionType')?.value === PromotionType.DrawFeeReduction">
        <mat-label>Draw fee reduction value</mat-label>
        <input matInput
               *ngIf="promotionForm.get('reductionType')?.value !== FeeType.Flat"
               formControlName="reductionValue"
               type="text"
               currencyMask
               [options]="{precision: 2, suffix: '%', prefix: ''}"
               placeholder="Draw fee reduction value" />
        <input matInput
               *ngIf="promotionForm.get('reductionType')?.value === FeeType.Flat"
               formControlName="reductionValue"
               type="text"
               currencyMask
               placeholder="Draw fee reduction value" />
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="promotionForm.get('promotionType')?.value === PromotionType.DrawFeeOverride">
        <mat-label>Draw fee override value</mat-label>
        <input matInput
               *ngIf="promotionForm.get('reductionType')?.value !== FeeType.Flat"
               formControlName="reductionValue"
               type="text"
               currencyMask
               [options]="{precision: 2, suffix: '%', prefix: ''}"
               placeholder="Draw fee reduction value" />
        <input matInput
               *ngIf="promotionForm.get('reductionType')?.value === FeeType.Flat"
               formControlName="reductionValue"
               type="text"
               currencyMask
               placeholder="Draw fee override value" />
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="promotionForm.get('promotionType')?.value === PromotionType.PayoffInterestReduction">
        <mat-label>Interest rate discount value</mat-label>
        <input matInput
               *ngIf="promotionForm.get('reductionType')?.value !== FeeType.Flat"
               formControlName="reductionValue"
               type="text"
               currencyMask
               [options]="{precision: 2, suffix: '%', prefix: ''}"
               placeholder="Interest rate discount value" />
        <input matInput
               *ngIf="promotionForm.get('reductionType')?.value === FeeType.Flat"
               formControlName="reductionValue"
               type="text"
               currencyMask
               placeholder="Interest rate discount value" />
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start center"
         fxLayoutGap="24px">
      <div class="mat-form-field-wrapper">
        <mat-checkbox color="primary"
                      formControlName="active">Active</mat-checkbox>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #conditionsStep>
  <form [formGroup]="conditionsForm">

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between center"
         fxLayoutGap="24px">
      <div class="mat-form-field-wrapper mat-form-field-appearance-outline" fxLayout="row" fxLayoutAlign="start center">
        <mat-checkbox color="primary" class="mat-form-field-infix"
                      formControlName="usePromoCode">Use promo code</mat-checkbox>
      </div>

      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="conditionsForm.get('usePromoCode')?.value === true">
        <mat-label>Promo code</mat-label>
        <input matInput
              formControlName="promoCode"
              type="text"
              placeholder="Promo code"
              maxlength="16" />
      </mat-form-field>

    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start start"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Transaction limit per loan</mat-label>
        <mat-select matInput
                    formControlName="transactionsPerLoanLimitType"
                    placeholder="Transaction limit per loan">
          <mat-option value="custom">Custom</mat-option>
          <mat-option value="unlimited">Unlimited</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      *ngIf="conditionsForm.get('transactionsPerLoanLimitType')?.value === TransactionLimitType.Custom">
        <mat-label>Transaction limit</mat-label>
        <input matInput
               formControlName="transactionsPerLoanLimit"
               currencyMask
               [options]="{precision: 0, suffix: '', prefix: ''}"
               inputmode="numeric"
               placeholder="Transaction limit" />
        <mat-error *ngIf="conditionsForm.get('transactionsPerLoanLimit').hasError('min')">
          Minimum value is 1
        </mat-error>
      </mat-form-field>

    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Loan utilization from</mat-label>
        <input matInput
               formControlName="loanUtilizationMin"
               currencyMask
               inputmode="numeric"
               [options]="{precision: 0, suffix: '%', prefix: ''}"
               placeholder="Loan utilization from" />
        <mat-error *ngIf="conditionsForm.get('loanUtilizationMin').hasError('max')">
          Please enter a value in the range 0 - 100
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Loan utilization to</mat-label>
        <input matInput
               formControlName="loanUtilizationMax"
               currencyMask
               inputmode="numeric"
               [options]="{precision: 0, suffix: '%', prefix: ''}"
               placeholder="Loan utilization to" />
        <mat-error *ngIf="conditionsForm.get('loanUtilizationMax').hasError('max')">
          Please enter a value in the range 0 - 100
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Loan balance from</mat-label>
        <input matInput
               formControlName="loanBalanceMin"
               currencyMask
               [options]="{precision: 2, suffix: '', prefix: ''}"
               inputmode="numeric"
               placeholder="Loan balance from"
               maxlength="20"/>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Loan balance to</mat-label>
        <input matInput
               formControlName="loanBalanceMax"
               currencyMask
               [options]="{precision: 2, suffix: '', prefix: ''}"
               inputmode="numeric"
               placeholder="Loan balance to"
               maxlength="20" />
      </mat-form-field>
    </div>


    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Amount from</mat-label>
        <input matInput
               formControlName="transactionAmountMin"
               currencyMask
               [options]="{precision: 2, suffix: '', prefix: ''}"
               inputmode="numeric"
               placeholder="Amount from"
               maxlength="20"/>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Amount to</mat-label>
        <input matInput
               formControlName="transactionAmountMax"
               currencyMask
               [options]="{precision: 2, suffix: '', prefix: ''}"
               inputmode="numeric"
               placeholder="Amount to"
               maxlength="20"/>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Loan status</mat-label>
        <mat-select matInput
                    multiple
                    formControlName="loanStatuses"
                    placeholder="Loan status">
          <mat-option *ngFor="let option of loanStatusOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Priority</mat-label>
        <input matInput
               formControlName="priority"
               currencyMask
               [options]="{precision: 0, suffix: '', prefix: ''}"
               inputmode="numeric"
               placeholder="Priority" />
        <mat-error *ngIf="conditionsForm.get('priority').hasError('min') && !conditionsForm.get('priority').hasError('required')">
          Minimum value is 1
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start center"
         fxLayoutGap="24px">

      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Linked account</mat-label>
        <mat-select matInput
                    formControlName="linkedAccounts"
                    placeholder="Linked account">
          <mat-option *ngFor="let option of linkAccountTypeOptions"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row"
           fxLayout.lt-md="column"
           fxLayoutAlign="start center"
           *ngIf="promotionForm.get('productCode')?.value === ProductCode.Law">
        <div class="mat-form-field-wrapper">
          <mat-checkbox color="primary"
                        formControlName="hasLcpPolicy">Has litigation cost protection policy</mat-checkbox>
        </div>
      </div>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="start center"
         fxLayoutGap="24px">

      <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
        <mat-label>Customer message data</mat-label>
        <input matInput
          formControlName="message"
          type="text"
          placeholder="Customer message data" />
      </mat-form-field>

      <div fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="start center">
        <div class="mat-form-field-wrapper">
          <mat-checkbox color="primary"
                        formControlName="exclusivePromotion">Exclusive promotion</mat-checkbox>
        </div>
      </div>
    </div>
  </form>
</ng-template>
