import { Component, Input } from '@angular/core';
import { AccountAppBarExpandedComponentData } from '../account-app-bar-expanded/account-app-bar-expanded.component';
import { ProductCode } from '../account.model';
import { AppSettings } from '../../../app.settings';

@Component({
  selector: 'ifb-account-profile-other-info-widget',
  templateUrl: './account-profile-other-info-widget.component.html',
  styleUrls: ['./account-profile-other-info-widget.component.scss']
})
export class AccountProfileOtherInfoWidgetComponent {

  @Input()
  data: AccountAppBarExpandedComponentData;

  @Input()
  edit: boolean;

  get productCode() { return ProductCode; }

  constructor(private settings: AppSettings) {
  }

  salesforceLink(salesforceRecordId: string) {
    return this.settings.salesforce.url + salesforceRecordId;
  }

}

