import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerData, CustomerComponentData } from '../customer.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readOnly, readWrite } from '../../user/user-permission/user-permission.data';
import { routerTransition } from '../../shared/animations/router.animations';


import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifb-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
  animations: routerTransition
})
export class CustomerProfileComponent implements OnInit {
  customerInfoData: CustomerData;
  canEditCustomerInfo: boolean;
  canEditCustomerKBA: boolean;
  saleforceViewMode: boolean;
  canReadCustomerIdentificationData: boolean;

  constructor(private route: ActivatedRoute, private userPermissionService: UserPermissionService) {}

  ngOnInit() {
    this.route.params.pipe(untilDestroyed(this))
      .subscribe(params => this.saleforceViewMode = params.uniqueId !== undefined);

    this.route.data.pipe(untilDestroyed(this))
      .subscribe(this.dataSet.bind(this));

    this.userPermissionService.granted([readWrite('servicing-customers')])
      .pipe(untilDestroyed(this))
      .subscribe(res => this.canEditCustomerInfo = res);

    this.userPermissionService.granted([readWrite('servicing-customers-identification-data')])
      .pipe(untilDestroyed(this))
      .subscribe(res => this.canEditCustomerKBA = res);

    this.userPermissionService.granted([readOnly('servicing-customers-identification-data')])
      .pipe(untilDestroyed(this))
      .subscribe(res => this.canReadCustomerIdentificationData = res);
  }

  dataSet(data: CustomerComponentData) {
    if (data?.customer) {
      this.customerInfoData = data.customer;
      this.customerInfoData.accountType = _.trim(data.customer.accountType, '#$');
    }
  }
}
