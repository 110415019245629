import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomerStatus } from '../customer.model';
import { EnumHelper } from 'common';

@Component({
  selector: 'ifb-customer-update-dialog',
  templateUrl: './customer-update-dialog.component.html',
  styleUrls: ['./customer-update-dialog.component.css']
})
export class CustomerUpdateDialogComponent {

  constructor(public dialogRef: MatDialogRef<CustomerUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, form: UntypedFormGroup): Observable<any> {
    return dialog.open(CustomerUpdateDialogComponent, {
      width: '510px',
      data: { form: form },
      closeOnNavigation: true,
    }).afterClosed();
  }

  statusName(value: CustomerStatus): string {
    return EnumHelper.getNameFromValue(CustomerStatus, value);
  }
}
