<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <div class="layout margin" [@routerTransition]="'fly'">
    <ifb-panel id="sales-dashboard">

      <div fxLayout="column">
        <ifb-new-draws-card-chart></ifb-new-draws-card-chart>
        <ifb-total-draws-card-chart></ifb-total-draws-card-chart>
        <ifb-product-averages-list></ifb-product-averages-list>
      </div>

      <div fxLayout="column">
        <ifb-residual-draws-card-chart></ifb-residual-draws-card-chart>
        <ifb-account-onboarding-list></ifb-account-onboarding-list>
        <ifb-account-breakdown-list></ifb-account-breakdown-list>
      </div>

      <div fxLayout="column">
        <ifb-draws-leaderboard-list></ifb-draws-leaderboard-list>
        <ifb-account-onboarding-chart class="last-widget"></ifb-account-onboarding-chart>
      </div>

    </ifb-panel>
  </div>
</ifc-app-scroll-container>
