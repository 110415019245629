import { Component, Input } from '@angular/core';
import { VerticalBarChartOptions } from '../charts.model';

@Component({
  selector: 'ifb-vertical-stacked-bar-chart',
  templateUrl: './vertical-stacked-bar-chart.component.html',
  styleUrls: ['./vertical-stacked-bar-chart.component.scss']
})
export class VerticalStackedBarChartComponent {

  @Input()
  options: VerticalBarChartOptions;

  @Input()
  view: string[];

  @Input()
  data: any;

  constructor() { }

}
