<div [@routerTransition]="'fly'">

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info"
           *ngIf="!data.checkoutRequirements?.length"
           fxLayout="row"
           fxLayoutGap="12px"
           fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table class="table"
             *ngIf="data.checkoutRequirements?.length"
             mat-table
             [dataSource]="data.checkoutRequirements"
             aria-describedby="checkout requirements list">
        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef> Name </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"> {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell
              *matHeaderCellDef> Status </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            {{row.status | checkoutRequirementStatusLabel}}
          </td>
        </ng-container>

        <ng-container matColumnDef="requiredDocument">
          <th mat-header-cell
              *matHeaderCellDef> Required document </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            {{row.requiredDocument}} </td>
        </ng-container>

        <ng-container matColumnDef="isCreditModel">
          <th mat-header-cell
              *matHeaderCellDef>Is from credit model </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            {{row.isCreditModel ? "Yes" : "No"}} </td>
        </ng-container>

        <ng-container matColumnDef="isManual">
          <th mat-header-cell
              *matHeaderCellDef>Is manual</th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            {{row.isManual ? "Yes" : "No"}} </td>
        </ng-container>

        <ng-container matColumnDef="isFileUploaded">
          <th mat-header-cell
              *matHeaderCellDef>Is file uploaded</th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            {{row.isFileUploaded ? "Yes" : "No"}} </td>
        </ng-container>

        <ng-container matColumnDef="uniqueId">
          <th mat-header-cell
              *matHeaderCellDef> Salesforce ID </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            <a class="salesforce-url"
               *ngIf="row?.uniqueId"
               href="{{row.uniqueId | salesforceUrl}}"
               target="_blank"
               rel="noopener">
              {{row.uniqueId}}
            </a>
          </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="tableColumns"
            class="table-header"></tr>
        <tr mat-row
            *matRowDef="let row; columns: tableColumns;"
            class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>
</div>
