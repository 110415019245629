import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterParameter, EnumHelper } from 'common';
import {
  LogGroupByOption,
  LogFilters,
  LogQueryResult,
  LogQueryParams,
  LogGroupQueryResult,
  LogGroupByLabel,
  LogFilterOptions
} from '../log.model';
import { LogFilterService } from '../log-filter.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { LogListFilterDialogComponent } from '../log-list-filter-dialog/log-list-filter-dialog.component';
import { LogService } from '../log.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-log-shell',
  templateUrl: './log-shell.component.html',
  styleUrls: ['./log-shell.component.scss']
})
export class LogShellComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  grouped = false;
  expanded = false;
  showExpandAllButton = false;
  canReadWriteLog = false;
  filters: LogFilters;
  activeFilters: FilterParameter[] = [];
  queryParams: any;
  activeGroupBy: any;
  data: LogGroupedComponentData;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  logGroupByOptions = EnumHelper.getMappedNamesAndValues(LogGroupByOption, LogGroupByLabel);
  pageChangedSubject: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private logFilterService: LogFilterService,
    private logService: LogService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: LogGroupedComponentData) => this.data = it);

    this.route.queryParams
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.grouped = this.queryParams.groupBy !== undefined;
        this.activeFilters = this.logFilterService.addFilterParameters(this.queryParams);
        this.filters = new LogFilters(this.queryParams);
      });

    this.userPermissionService.granted([readWrite('admin-logs')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canReadWriteLog = res);

    this.setPaginator(this.grouped ? this.paginatorGrouped : this.paginatorList);
  }

  private setPaginator(paginator: MatPaginator) {
    paginator.pageSize = this.queryParams.limit;
    paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  group(groupBy: LogGroupByOption) {
    if (!groupBy)
      this.setGroup(undefined, false);
    else this.setGroup(groupBy, true);
  }

  setGroup(groupBy: LogGroupByOption, isGrouped: boolean) {
    this.grouped = isGrouped;
    this.activeGroupBy = groupBy;
    this.queryParams = Object.assign({}, this.queryParams, { groupBy: groupBy });
    this.logFilterService.filter(this.queryParams, isGrouped ? this.paginatorGrouped : this.paginatorList, this.sort);
  }

  archiveAll() {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '510px',
      disableClose: true
    });

    this.confirmDialogRef.componentInstance.confirmMessage = "Are you sure you want to archive all logs?";

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result)
        this.logService.archiveAll(this.queryParams).subscribe(() => {
          this.fetchData();
        });
    });
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  fetchData() {
    if (this.grouped)
      this.logService.grouped(new LogQueryParams(this.queryParams))
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(res => this.data.logGroupQueryResult = res);
    else this.logService.query(new LogQueryParams(this.queryParams))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.data.logQueryResult = res);
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.logFilterService.filter(this.filters);
    });

    if (this.filters.groupBy === undefined) {
      this.grouped = false;
      this.activeGroupBy = undefined;
    }
  }

  filterDialog() {
    LogListFilterDialogComponent.show(this.dialog, this.queryParams, this.data.logFilterOptions).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result) {
          result.entity = result.entity ? result.entity.id : undefined;
          result.loan = result.loan ? result.loan.id : undefined;
          result.customer = result.customer ? result.customer.id : undefined;
          this.filters = result;
          if (this.grouped) {
            this.filters = Object.assign({}, this.filters, { groupBy: this.activeGroupBy });
          }
          this.paginatorList.firstPage();
          this.paginatorGrouped.firstPage();
          this.logFilterService.filter(this.filters, this.grouped ? this.paginatorGrouped : this.paginatorList, this.sort);
        }
      });
  }

  onNestedFiltered(params: LogQueryParams) {
    this.filters = null;
    this.filters = Object.assign({}, this.filters, params);
    this.paginatorList.firstPage();
    this.logFilterService.filter(this.filters, this.paginatorList, this.sort);
  }

  onShowMessagesSelected(params: LogQueryParams) {
    this.grouped = false;
    this.activeGroupBy = undefined;
    this.onNestedFiltered(params);
  }

  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild('paginatorList', { static: true })
  paginatorList: MatPaginator;

  @ViewChild('paginatorGrouped', { static: true })
  paginatorGrouped: MatPaginator;

  setPage(event: PageEvent) {
    this.pageChangedSubject.next();
    if (this.grouped) {
      this.filters.groupBy = this.activeGroupBy;
    }
    this.logFilterService.filter(this.filters, this.grouped ? this.paginatorGrouped : this.paginatorList, this.sort);
  }
}

export interface LogGroupedComponentData {
  logGroupQueryResult?: LogGroupQueryResult;
  logQueryResult?: LogQueryResult;
  logFilterOptions?: LogFilterOptions;
}

