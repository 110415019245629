import { Pipe, PipeTransform } from '@angular/core';
import { AccountData } from '../../account.model';

@Pipe({
  name: 'alligatorDataIcon'
})
export class AlligatorDataIconPipe implements PipeTransform {

  transform(account: AccountData): string {
    return account?.alligatorMatch ? "check" : "sync_problem";

  }
}
