import { Component, Input } from '@angular/core';
import { DocumentSignatureData } from 'common/lib/documents/signed-loan-document-file.model';

@Component({
  selector: 'ifb-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent {

  @Input() signature: DocumentSignatureData;

  getLatLong() {
    return [this.signature.position.latitude, this.signature.position.longitude].filter(Boolean).join(",");
  }

  getAddress() {
    return [this.signature.position.city, this.signature.position.region, this.signature.position.postalCode, this.signature.position.country]
                  .filter(Boolean).join(", ");
  }

  getHostAsnOrg() {
    return [this.signature.hostName, this.signature.asn, this.signature.organization].filter(Boolean).join(", ");
  }

  getTimeZone() {
    return [this.signature.timeZone.name, this.signature.timeZone.utcOffset].filter(Boolean).join(", ");
  }
}
