import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppPageService,
  BrokerApiKeyData,
  BrokerApiKeyEditData,
  BrokerApiKeyInputData,
  BrokerApiKeyListActionsService,
  CreateBrokerApiKeySuccessData,
  MessageService,
  parseNumber,
} from "common";
import { readWrite } from "projects/backoffice/src/app/user/user-permission/user-permission.data";
import { UserPermissionService } from "projects/backoffice/src/app/user/user-permission/user-permission.service";
import { BrokerApiKeysFacade } from "../../domain/+state/broker-api-keys-facade";
import { ActivatedRoute } from "@angular/router";
import { BrokerApiKeyDetailsFacade } from "./broker-api-key-details.facade";
import { map } from "rxjs/operators";
import { forkJoin, of } from "rxjs";

@UntilDestroy()
@Component({
  selector: "ifb-broker-api-key-details",
  templateUrl: "./broker-api-key-details.component.html",
  styleUrls: ["./broker-api-key-details.component.scss"],
  providers: [BrokerApiKeyDetailsFacade],
})
export class BrokerApiKeyDetailsComponent implements OnInit {
  private brokerId: number;

  data: BrokerApiKeyInputData;

  constructor(
    private readonly userPermissionService: UserPermissionService,
    private readonly actionService: BrokerApiKeyListActionsService,
    private readonly brokerApiKeysFacade: BrokerApiKeysFacade,
    private readonly brokerApiKeyDetailsFacade: BrokerApiKeyDetailsFacade,
    private readonly messageService: MessageService,
    private readonly appPageService: AppPageService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initData();
    this.watchActions();
  }

  private initData() {
    forkJoin([this.initPermissions(), this.initRouteParams()])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([permissions, params]) =>
          (this.data = {
            dataSource: params.apiKeyData,
            isCreating: params.isCreating,
            writeAllowed: permissions,
          })
      );
  }

  private initPermissions() {
    return this.userPermissionService
      .granted([readWrite("brokers-broker-api-keys")])
      .pipe(untilDestroyed(this));
  }

  private watchActions() {
    this.actionService.save$
      .pipe(untilDestroyed(this))
      .subscribe((data: BrokerApiKeyEditData) => this.onSave(data));

    this.actionService.delete$
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => this.onDelete(id));
  }

  private initRouteParams() {
    let isCreating = true;
    let apiKeyData: BrokerApiKeyData = undefined;
    this.brokerId = parseNumber(this.route.snapshot.paramMap.get("brokerId"));

    const brokerApiKeyIdRaw = this.route.snapshot.paramMap.get("id");
    if (brokerApiKeyIdRaw === "new") {
      return of({ apiKeyData, isCreating });
    }

    isCreating = false;
    const brokerApiKeyId = parseNumber(brokerApiKeyIdRaw);
    return this.brokerApiKeysFacade.getBrokerApiKeyById(brokerApiKeyId).pipe(
      untilDestroyed(this),
      map((data) => {
        apiKeyData = data;
        return { apiKeyData, isCreating };
      })
    );
  }

  private onSave(data: BrokerApiKeyEditData) {
    if (this.data.isCreating) {
      this.brokerApiKeysFacade
        .createBrokerApiKey(
          this.brokerApiKeyDetailsFacade.prepareCreateRequest(
            data,
            this.brokerId
          )
        )
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (next) => this.handleSuccessCreateBrokerApiKey(next),
          error: () => this.handleErrorCreateBrokerApiKey(),
        });
      return;
    }

    this.brokerApiKeysFacade
      .updateBrokerApiKey(data.id, { ...data })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.handleSuccessUpdateBrokerApiKey(),
        error: () => this.handleErrorUpdateBrokerApiKey(),
      });
  }

  private onDelete(id: number) {
    this.brokerApiKeysFacade.removeBrokerApiKey(id).subscribe({
      next: () => {
        this.messageService.success(
          `Broker API key has been successfully removed.`
        );
        this.appPageService.back();
      },
      error: () =>
        this.messageService.error(`Broker API key cannot be removed.`),
    });
  }

  private handleSuccessCreateBrokerApiKey(data: CreateBrokerApiKeySuccessData) {
    this.messageService.success(
      "Broker API Key has been successfully created."
    );
    this.actionService.markCreateAsCompleted(data);
  }

  private handleErrorCreateBrokerApiKey() {
    this.messageService.error("Broker API Key cannot be created.");
  }

  private handleSuccessUpdateBrokerApiKey() {
    this.messageService.success(
      "Broker API Key has been successfully updated."
    );
    this.appPageService.back();
  }

  private handleErrorUpdateBrokerApiKey() {
    this.messageService.error("Broker API Key cannot be updated.");
  }
}
