<div id="main" fxLayout="column" fxLayoutAlign="start start">

  <nav mat-tab-nav-bar>
    <a *ngFor="let link of navLinks" mat-tab-link #rla="routerLinkActive"
      [active]="isViewInitialized && isLinkActive(rla)" [routerLink]="link.path" [queryParams]="link.query"
      routerLinkActive>
      {{link.label}}
    </a>
  </nav>

  <ifc-app-scroll-container>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </ifc-app-scroll-container>

</div>