import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import {
  AppBarTitleService,
  AppBarActionsService,
  AppBarAction,
  FormHelper,
  MessageService,
  EnumHelper,
  AppPageService
} from 'common';
import { BrokerExData, BrokerTier, BrokerType, BrokerTypeLabel } from '../broker.model';
import { BrokerService } from '../broker.service';
import { BrokerUpdateDialogComponent } from '../broker-update-dialog/broker-update-dialog.component';

@Component({
  selector: 'ifb-broker-other-info-details',
  templateUrl: './broker-other-info-details.component.html',
  styleUrls: ['./broker-other-info-details.component.css']
})
export class BrokerOtherInfoDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  form: UntypedFormGroup;

  brokerTierOptions = EnumHelper.getNamesAndValues(BrokerTier);
  brokerTypeOptions = EnumHelper.getMappedNamesAndValues(BrokerType, BrokerTypeLabel);

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private brokerService: BrokerService,
    private messageService: MessageService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.form = this.formBuilder.group({
      brokerId: { value: undefined, disabled: true },
      type: undefined,
      tier: undefined,
      establishedDate: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      uniqueId: { value: undefined, disabled: true }
    });
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  data: BrokerOtherInfoDetailsComponentData;

  dataInit(data: BrokerOtherInfoDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Other info';

    this.form.reset({
      brokerId: this.data.broker.brokerId,
      type: this.data.broker.type,
      tier: this.data.broker.tier,
      establishedDate: this.data.broker.establishedDate,
      uniqueId: this.data.broker.uniqueId
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;


    BrokerUpdateDialogComponent.show(this.dialog, this.form, this.data.broker.name, 'broker')
      .subscribe(result => {
        if (result) {
          const updateCommand = {
            type: this.form.value.type,
            tier: this.form.value.tier,
            establishedDate: this.form.value.establishedDate
          };
          this.saveSub = this.brokerService.updateInfo(this.data.broker.id, updateCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }
}

export interface BrokerOtherInfoDetailsComponentData {
  broker?: BrokerExData;
}

