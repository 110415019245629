import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppBarExpandedTabWithPermission } from '../app.data';

@Component({
    selector: 'ifb-admin',
    template: ''
})
export class AdminComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;

    constructor(private route: ActivatedRoute, private router: Router) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
            if (!data) return;
            const tab: AppBarExpandedTabWithPermission = data.appBarExpandedTabs
                && data.appBarExpandedTabs.tabs.length
                ? data.appBarExpandedTabs.tabs.filter(it => !it.hidden)[0] : undefined;
            if (tab) {
                this.router.navigate([tab.url], { queryParams: tab.query });
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
