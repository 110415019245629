import { Injectable } from '@angular/core';
import { TokenQueryParams, TokenQueryResult, TokenData } from './token.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private http: HttpClient, private settings: AppSettings) { }

  get(id: number): Observable<TokenData> {
    return this.http.get<TokenData>(`${this.settings.oauth.url}/api/token/${id}`);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.settings.oauth.url}/api/token/${id}`);
  }

  update(id: number, token: TokenData): Observable<TokenData> {
    return this.http.put<TokenData>(`${this.settings.oauth.url}/api/token/${id}`, token);
  }

  query(params: TokenQueryParams): Observable<TokenQueryResult> {
    return this.http.get<TokenQueryResult>(`${this.settings.oauth.url}/api/token/query`, { params: <any>params });
  }

  create(token: TokenData): Observable<TokenData> {
    return this.http.post<TokenData>(`${this.settings.oauth.url}/api/token`, token);
  }
}
