<ifc-app-scroll-container [default]="true">
  <div
    class="message-box info"
    *ngIf="!inputData.dataSource.totalCount"
    fxLayout="row"
    fxLayoutGap="12px"
    fxLayoutAlign="start center"
  >
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <table
    aria-label="Table of broker api keys"
    *ngIf="inputData.dataSource.totalCount"
    mat-table
    matSort
    [dataSource]="inputData.dataSource.values"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef>Created by</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.createdBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef>Created on</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.createdOn | date : "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedBy">
      <th mat-header-cell *matHeaderCellDef>Updated by</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.updatedBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedOn">
      <th mat-header-cell *matHeaderCellDef>Updated on</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.updatedOn | date : "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>Active</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.active | booleanValueLabel : BrokerApiKeysActiveLabel }}
      </td>
    </ng-container>

    <ng-container matColumnDef="clientId">
      <th mat-header-cell *matHeaderCellDef>Client ID</th>
      <td mat-cell *matCellDef="let row" class="table-cell">
        {{ row.clientId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="overflow">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="inputData.writeAllowed">
          <button
            class="overflow-button"
            mat-icon-button
            [matMenuTriggerFor]="brokerApiKeyMenu"
            #menuTrigger="matMenuTrigger"
            [ngClass]="{ 'show-overflow-button': menuTrigger.menuOpen }"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #brokerApiKeyMenu="matMenu">
            <button
              mat-menu-item
              class="menu-item"
              (click)="onEditClick(row.id)"
            >
              <span> Edit </span>
            </button>
            <button
              mat-menu-item
              class="menu-item"
              (click)="onStatusChangeClick(row)"
            >
              <span> {{ row.active ? "Deactive" : "Active" }}</span>
            </button>
            <button
              mat-menu-item
              class="menu-item"
              (click)="onDeleteClick(row.id)"
            >
              <span> Delete </span>
            </button>
          </mat-menu>
        </ng-container>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
      class="table-header"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns"
      class="table-row"
      (click)="onEditClick(row.id)"
    ></tr>
  </table>

</ifc-app-scroll-container>

<div [hidden]="inputData.dataSource.totalCount <= 10">
  <mat-paginator
    #paginator
    showFirstLastButtons
    [length]="inputData.dataSource.totalCount"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onFilter()"
  >
  </mat-paginator>
</div>
