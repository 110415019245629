<h1 mat-dialog-title>Update entity?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are updating entity:
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">

      <div *ngIf="data.form.controls.entityName && !data.form.controls.entityName.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.entityName || 'Null'}}</div>
        <div class="mat-caption"> Legal entity name </div>
      </div>

      <div *ngIf="data.form.controls.entityDBA && !data.form.controls.entityDBA.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.entityDBA || 'Null'}}</div>
        <div class="mat-caption"> DBA </div>
      </div>

      <div *ngIf="data.form.controls.line1 && !data.form.controls.line1.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.line1 || 'Null'}}</div>
        <div class="mat-caption"> Address </div>
      </div>

      <div *ngIf="data.form.controls.line2 && !data.form.controls.line2.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.line2 || 'Null'}}</div>
        <div class="mat-caption"> Unit/Suite/Apt # </div>
      </div>

      <div *ngIf="data.form.controls.city && !data.form.controls.city.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.city || 'Null'}}</div>
        <div class="mat-caption"> City </div>
      </div>

      <div *ngIf="data.form.controls.state && !data.form.controls.state.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.state || 'Null'}}</div>
        <div class="mat-caption"> State </div>
      </div>

      <div *ngIf="data.form.controls.zip && !data.form.controls.zip.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.zip || 'Null'}}</div>
        <div class="mat-caption"> Zipcode </div>
      </div>

      <div *ngIf="data.form.controls.entityPhone && !data.form.controls.entityPhone.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.entityPhone || 'Null'}}</div>
        <div class="mat-caption"> Primary phone </div>
      </div>

      <div *ngIf="data.form.controls.taxId && !data.form.controls.taxId.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.taxId || 'Null'}}</div>
        <div class="mat-caption"> Tax ID </div>
      </div>

      <div *ngIf="data.form.controls.website && !data.form.controls.website.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.website || 'Null'}}</div>
        <div class="mat-caption"> Website </div>
      </div>

      <div *ngIf="data.form.controls.email && !data.form.controls.email.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.email || 'Null'}}</div>
        <div class="mat-caption"> Email </div>
      </div>

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>