import { QueryParams, QueryResult, Address, EntityStatus, parseNumber } from "common";
import { AppBarExpandedTabWithPermission } from "../app.data";
import { readOnly } from "../user/user-permission/user-permission.data";

export enum BrokerType {
    Unknwon = 'unknown',
    ReferralPartner = 'referral-partner',
    BrokerPartner = 'broker-partner',
    StrategicPartner = 'strategic-partner',
    ISOPartnerDirect = 'iso-partner-direct',
    ISOAndBrokerPartner = 'iso-and-broker-partner'
}

export const BrokerTypeLabel = new Map<string, string>([
    [BrokerType.Unknwon, 'Unknown'],
    [BrokerType.ReferralPartner, 'Referral partner'],
    [BrokerType.BrokerPartner, 'Broker partner'],
    [BrokerType.StrategicPartner, 'Strategic partner'],
    [BrokerType.ISOPartnerDirect, 'ISO partner direct'],
    [BrokerType.ISOAndBrokerPartner, 'ISO and broker partner']
]);

export enum BrokerPricingPlan {
    Special = 'special',
    Direct = 'direct',
    Partner = 'partner'
}

export const BrokerPricingPlanLabel = new Map<string, string>([
    [BrokerPricingPlan.Special, 'Special'],
    [BrokerPricingPlan.Direct, 'Direct'],
    [BrokerPricingPlan.Partner, 'Partner']
]);

export enum BrokerTier {
    Silver = 'silver',
    Gold = 'gold',
    Platinum = 'platinum'
}

export const BrokerTierLabel = new Map<string, string>([
    [BrokerTier.Silver, 'Silver'],
    [BrokerTier.Gold, 'Gold'],
    [BrokerTier.Platinum, 'Platinum']
]);

export enum BrokerCommissionPlan {
    Original = 'original',
    Accelerated = 'accelerated'
}

export const BrokerCommissionPlanLabel = new Map<string, string>([
    [BrokerCommissionPlan.Original, 'Original'],
    [BrokerCommissionPlan.Accelerated, 'Accelerated']
]);

export interface BrokerBrandingData {
    supported?: boolean;
    directCustomerCommunications?: boolean;
    subdomain?: string;
    locCalculatorCss?: string;
    offerEmailCss?: string;
}

export interface BrokerCommissionData {
    plan?: BrokerCommissionPlan;
    initialDrawCommission?: number;
    initialCommissionPeriod?: number;
    initialCommissionCap?: number;
    residualDrawCommission?: number;
    residualCommissionPeriod?: number;
    defaultClawbackPeriod?: number;
    defaultPrepaymentClawbackPeriod?: number;
}

export interface BrokerBaseData {
    id?: number;
    uniqueId?: string;
    advisorId?: number;
    brokerId?: number;
    type?: BrokerType;
    status?: EntityStatus;
    name?: string;
    dba?: string;
    ein?: string;
    contactEmail?: string;
    commissionEmail?: string;
    webSite?: string;
    phone?: string;
    address?: Address;
    establishedDate?: Date;
    contractDate?: Date;
    pricingPlan?: BrokerPricingPlan;
    tier?: BrokerTier;
    branding?: BrokerBrandingData;
    commission?: BrokerCommissionData;
    active?: boolean;
    apiEnabled?: boolean;
}

export interface BrokerData extends BrokerBaseData {
    createdOn?: Date;
    createdBy?: string;
    updatedOn?: Date;
    updatedBy?: string;
}

export interface BrokerExData extends BrokerData {
    advisorFirstName?: string;
    advisorLastName?: string;
    advisorName?: string;
    advisorEmail?: string;
    advisorPhone?: string;
    applicationsCountTotal?: number;
    applicationsCountLast7Days?: number;
    applicationsCountLast30Days?: number;
    usersCount?: number;
}

export interface BrokerListItemData {
    id?: number;
    uniqueId?: string;
    brokerId?: number;
    name?: string;
    type?: BrokerType;
    status?: EntityStatus;
    contactEmail?: string;
    phone?: string;
    advisorName?: string;
    initialDrawCommission?: number;
    residualDrawCommission?: number;
    createdOn?: Date;
    createdBy?: string;
    updatedOn?: Date;
    updatedBy?: string;
    tier?: BrokerTier;
    usersCount?: number;
    applicationsCount?: number;
}

export class BrokerQueryParams extends QueryParams {
    uniqueId?: string;
    advisorId?: number;
    types?: BrokerType[];
    statuses?: EntityStatus[];
    name?: string;
    dba?: string;
    ein?: string;
    contactEmail?: string;
    commissionEmail?: string;
    webSite?: string;
    phone?: string;
    establishedDateStart?: any;
    establishedDateEnd?: any;
    contractDateStart?: any;
    contractDateEnd?: any;
    pricingPlans?: BrokerPricingPlan[];
    tiers?: BrokerTier[];
    createdBy?: string;
    createdOnStart?: any;
    createdOnEnd?: any;
    updatedBy?: string;
    updatedOnStart?: any;
    updatedOnEnd?: any;

    constructor(params?: any) {
      super(params)

      params = params || {};
      this.uniqueId = params.uniqueId || undefined;
      this.advisorId = parseNumber(params.advisorId) || undefined;
      this.types = params.types || undefined;
      this.statuses = params.statuses || undefined;
      this.name = params.name || undefined;
      this.dba = params.dba || undefined;
      this.ein = params.ein || undefined;
      this.contactEmail = params.contactEmail || undefined;
      this.commissionEmail = params.commissionEmail || undefined;
      this.webSite = params.webSite || undefined;
      this.phone = params.phone || undefined;
      this.establishedDateStart = params.establishedDateStart || undefined;
      this.establishedDateEnd = params.establishedDateEnd || undefined;
      this.contractDateStart = params.contractDateStart || undefined;
      this.contractDateEnd = params.contractDateEnd || undefined;
      this.pricingPlans = params.pricingPlans || undefined;
      this.tiers = params.tiers || undefined;
      this.createdBy = params.createdBy ||  undefined;
      this.createdOnStart = params.createdOnStart || undefined;
      this.createdOnEnd = params.createdOnEnd || undefined;
      this.updatedBy = params.updatedBy || undefined;
      this.updatedOnStart = params.updatedOnStart || undefined;
      this.updatedOnEnd = params.updatedOnEnd || undefined;
    }
}

export interface BrokerQueryResult extends QueryResult<BrokerListItemData> {
}

export class BrokerFilters {
    uniqueId: string;
    advisorId: number;
    types: BrokerType[];
    statuses: EntityStatus[];
    name: string;
    dba: string;
    ein: string;
    contactEmail: string;
    commissionEmail: string;
    webSite: string;
    phone: string;
    establishedDate: Date;
    establishedDateStart: Date;
    establishedDateEnd: Date;
    contractDate: Date;
    contractDateStart: Date;
    contractDateEnd: Date;
    pricingPlans: BrokerPricingPlan[];
    tiers: BrokerTier[];
    createdBy: string;
    createdOn: Date;
    createdOnStart: Date;
    createdOnEnd: Date;
    updatedBy: string;
    updatedOn: Date;
    updatedOnStart: Date;
    updatedOnEnd: Date;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.uniqueId = filters.uniqueId || undefined;
        this.advisorId = filters.advisorId || undefined;
        this.types = filters.types || undefined;
        this.statuses = filters.statuses || undefined;
        this.name = filters.name || undefined;
        this.dba = filters.dba || undefined;
        this.ein = filters.ein || undefined;
        this.contactEmail = filters.contactEmail || undefined;
        this.commissionEmail = filters.commissionEmail || undefined;
        this.webSite = filters.webSite || undefined;
        this.phone = filters.phone || undefined;
        this.establishedDate = filters.establishedDate || undefined;
        this.establishedDateStart = filters.establishedDateStart || undefined;
        this.establishedDateEnd = filters.establishedDateEnd || undefined;
        this.contractDate = filters.contractDate || undefined;
        this.contractDateStart = filters.contractDateStart || undefined;
        this.contractDateEnd = filters.contractDateEnd || undefined;
        this.pricingPlans = filters.pricingPlans || undefined;
        this.tiers = filters.tiers || undefined;
        this.createdBy = filters.createdBy ? filters.createdBy.fullName : undefined;
        this.createdOn = filters.createdOn || undefined;
        this.createdOnStart = filters.createdOnStart || undefined;
        this.createdOnEnd = filters.createdOnEnd || undefined;
        this.updatedBy = filters.updatedBy ? filters.updatedBy.fullName : undefined;
        this.updatedOn = filters.updatedOn || undefined;
        this.updatedOnStart = filters.updatedOnStart || undefined;
        this.updatedOnEnd = filters.updatedOnEnd || undefined;
        this.active = filters.active === 'true' ? true : (filters.active === 'false' ? false : undefined);
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'establishedDate' || item === 'establishedDateStart') {
            this.establishedDate = undefined;
            this.establishedDateStart = undefined;
            this.establishedDateEnd = undefined;
        } else if (item === 'contractDate' || item === 'contractDateStart') {
            this.contractDate = undefined;
            this.contractDateStart = undefined;
            this.contractDateEnd = undefined;
        } else if (item === 'createdOn' || item === 'createdOnStart') {
            this.createdOn = undefined;
            this.createdOnStart = undefined;
            this.createdOnEnd = undefined;
        } else if (item === 'updatedOn' || item === 'updatedOnStart') {
            this.updatedOn = undefined;
            this.updatedOnStart = undefined;
            this.updatedOnEnd = undefined;
        }
    }
}

export const brokerAppBarExpandedTabs = (brokerId: number): AppBarExpandedTabWithPermission[] => [
    {
        label: 'Profile',
        url: `/broker/${brokerId}/profile`,
        permission: [readOnly('brokers-brokers')],
        hidden: true
    },
    {
        label: 'Companies',
        url: `/broker/${brokerId}/companies`,
        permission: [readOnly('servicing-entities')],
        hidden: true
    },
    {
        label: 'Accounts',
        url: `/broker/${brokerId}/accounts`,
        permission: [readOnly('servicing-accounts')],
        hidden: true
    },
    {
        label: 'Banks',
        url: `/broker/${brokerId}/banks`,
        permission: [readOnly('servicing-bank-accounts')],
        hidden: true
    },
    {
        label: 'Users',
        url: `/broker/${brokerId}/users`,
        permission: [readOnly('brokers-broker-users')],
        hidden: true
    },
    {
        label: "API Keys",
        url: `/broker/${brokerId}/api-keys`,
        permission: [readOnly('brokers-broker-api-keys')],
        hidden: true
    }
];
