import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { TenantListComponent } from './tenant-list/tenant-list.component';
import { TenantRoutingModule } from './tenant-routing.module';
import { TenantDetailsComponent } from './tenant-details/tenant-details.component';
import { SharedModule } from '../../shared/shared.module';
import { UserModule } from '../user/user.module';
import { FeatureFlagModule } from '../feature-flag/feature-flag.module';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        UserModule,
        TenantRoutingModule,
        FeatureFlagModule
    ],
    declarations: [
        TenantListComponent,
        TenantDetailsComponent,
    ]
})
export class TenantModule { }
