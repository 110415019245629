import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppBarTitleService, AppBarActionsService } from 'common';
import { AuditTrailData } from '../audit-trail.model';

@Component({
  selector: 'ifb-audit-trail-detail',
  templateUrl: './audit-trail-detail.component.html',
  styleUrls: ['./audit-trail-detail.component.scss']
})
export class AuditTrailDetailComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  downloadJsonHref: any;

  constructor(private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private appBarActionsService: AppBarActionsService,
    private sanitizer: DomSanitizer
  ) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [];

    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => {
        this.data = it.auditTrail;
        this.generateDownloadJsonUri();
        this.appBarActionsService.actions.push({
          id: 'json',
          label: 'Download',
          disabled: false,
          buttonType: 'download',
          name: `${this.titleInit()}.json`,
          link: this.downloadJsonHref,
          icon: 'get_app'
        });
      });
  }

  data: AuditTrailData;

  ngOnInit() {
    this.appBarTitleService.title = this.titleInit();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  titleInit(): string {
    // eslint-disable-next-line max-len
    return `${_.startCase(this.data.event)}, ${formatDate(this.data.date, 'medium', 'en_US')}, ${this.data.initiator.fullName}`;
  }

  getJson(): JSON {
    return JSON.parse(this.data.json);
  }

  generateDownloadJsonUri(): void {
    const jsonData = JSON.stringify(this.getJson());
    const uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(jsonData));
    this.downloadJsonHref = uri;
  }

}
