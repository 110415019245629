<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <div class="flex row center-center">
    <ifc-app-card>
      <div class="flex column">
        <div class="flex row start-center top-div">
          <mat-icon>email</mat-icon>
          <p class="layout margin-left">{{data.user.emails && data.user.emails.length > 0 ? data.user.emails[0] : '-'}}
          </p>
        </div>
        <div class="flex row start-center">
          <mat-icon>email</mat-icon>
          <p class="layout margin-left">{{data.user.emails && data.user.emails.length > 1 ? data.user.emails[1] : '-'}}
          </p>
        </div>
        <div class="flex row start-center">
          <mat-icon>phone</mat-icon>
          <p class="layout margin-left">
            {{data.user.phones && data.user.phones.length > 0 ? phoneFormat(data.user.phones[0]) : '-'}}</p>
        </div>
        <div class="flex row start-center">
          <mat-icon>phone</mat-icon>
          <p class="layout margin-left">
            {{data.user.phones && data.user.phones.length > 1 ? phoneFormat(data.user.phones[1]) : '-'}}</p>
        </div>
        <div class="flex row start-center">
          <mat-icon>location_on</mat-icon>
          <p class="layout margin-left">
            {{data.user.addresses &&  data.user.addresses.length > 0 ? data.user.addresses[0].Address+', '+data.user.addresses[0].City+', '+data.user.addresses[0].State +' '+ data.user.addresses[0].Zip : '-'}}
          </p>
        </div>
        <div class="flex row start-center">
          <mat-icon>location_on</mat-icon>
          <p class="layout margin-left">
            {{data.user.addresses &&  data.user.addresses.length > 1 ? data.user.addresses[1].Address+', '+data.user.addresses[1].City+', '+data.user.addresses[1].State +' '+ data.user.addresses[1].Zip : '-'}}
          </p>
        </div>
        <div class="roles">
          <mat-list>
            <h3 *ngIf="roles && roles.length > 0" mat-subheader>Roles</h3>
            <mat-list-item *ngFor="let role of roles">
              <mat-icon mat-list-icon>fiber_manual_record</mat-icon>
              <p mat-line>{{role.name}} </p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>

      <aside fxLayout="column" fxLayoutGap="24px">
        <ifb-card>
          <h2 class="layout margin-bottom" [ngClass.gt-md]="'mat-display-3'" [ngClass.lt-lg]="'mat-display-2'">User
            information</h2>
          <div fxLayout="row" fxLayoutGap="24px" fxLayout.lt-lg="column">
            <div fxFlex="auto" fxFlex.gt-md="60%">
              <div>
                {{(data.user.lastLogin) ? (data.user.lastLogin | date:'medium') : ''}}{{data.user.lastLogin && data.user.lastLoginAddress ? '/' : ''}}{{data.user.lastLoginAddress ? data.user.lastLoginAddress : ''}}
              </div>
              <div class="mat-caption">Last login time/IP address</div>
            </div>
            <div fxFlex="auto" fxFlex.gt-md="40%">
              <div>{{data.user.externalId ? data.user.externalId : '-'}}</div>
              <div class="mat-caption">External ID</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="24px" fxLayout.lt-lg="column">
            <div fxFlex="auto" fxFlex.gt-md="60%">
              <div>{{data.user.username ? data.user.username : '-'}}</div>
              <div class="mat-caption">Username/email</div>
            </div>
            <div fxFlex="auto" fxFlex.gt-md="40%">
              <div>{{data.user.clientName ? data.user.clientName : '-'}}</div>
              <div class="mat-caption">Client</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="24px" fxLayout.lt-lg="column">
            <div fxFlex="auto" fxFlex.gt-md="60%">
              <div>{{data.user.firstName ? data.user.firstName : '-'}}</div>
              <div class="mat-caption">First name</div>
            </div>
            <div fxFlex="auto" fxFlex.gt-md="40%">
              <div>{{data.user.lastName ? data.user.lastName : '-'}}</div>
              <div class="mat-caption">Last name</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="24px" fxLayout.lt-lg="column">
            <div fxFlex="auto" fxFlex.gt-md="60%">
              <div>{{data.user.middleName ? data.user.middleName : '-'}}</div>
              <div class="mat-caption">Middle name</div>
            </div>
            <div fxFlex="auto" fxFlex.gt-md="40%">
              <div>{{data.user.active ? "Active" : 'Inactive'}}</div>
              <div class="mat-caption">Status</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="24px" fxLayout.lt-lg="column">
            <div fxFlex="auto" fxFlex.gt-md="60%">
              <div>{{data.user.prefix ? data.user.prefix : '-'}}</div>
              <div class="mat-caption">Prefix</div>
            </div>
            <div fxFlex="auto" fxFlex.gt-md="40%">
              <div>{{data.user.suffix ? data.user.suffix : '-'}}</div>
              <div class="mat-caption">Suffix</div>
            </div>
          </div>
        </ifb-card>

      </aside>
    </ifc-app-card>
  </div>
</ifc-app-scroll-container>