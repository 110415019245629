import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BankingCustomerFilters, BankingCustomerQueryParams, BankingCustomerType } from '../banking.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { EnumHelper } from 'common';

@Component({
  selector: 'ifb-banking-customer-list-filter-dialog',
  templateUrl: './banking-customer-list-filter-dialog.component.html',
  styleUrls: ['./banking-customer-list-filter-dialog.component.css']
})
export class BankingCustomerListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  form: UntypedFormGroup;
  filters: BankingCustomerFilters;
  bankCustomerTypeOptions = EnumHelper.getNamesAndValues(BankingCustomerType);

  constructor(public dialogRef: MatDialogRef<BankingCustomerListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new BankingCustomerFilters(data.query);
    this.form = this.formBuilder.group({
      username: this.data.query.username || undefined,
      name: this.data.query.name || undefined,
      type: this.data.query.type || undefined,
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new BankingCustomerFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  static show(dialog: MatDialog, query: BankingCustomerQueryParams): Observable<any> {
    return dialog.open(BankingCustomerListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }

}
