<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button *ngIf="readWriteClient" mat-icon-button routerLink="/admin/client/new" class="secondary-text" matTooltip="Add">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button *ngIf="readWriteClient" mat-menu-item routerLink="/admin/client/new">
            <mat-icon>add_circle</mat-icon>
            <span>Add</span>
          </button>
        </mat-menu>
      </div>
    </div>

  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info" *ngIf="!data.clientQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table *ngIf="data.clientQueryResult.totalCount" mat-table matSort [dataSource]="data.clientQueryResult.values"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="clientName"> Client </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/client/{{row.id}}" class="table-cell"> {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="oidcAudience">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="oidcAudience"> OIDC audience </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/client/{{row.id}}" class="table-cell">
            {{row.oidcAudience}}
          </td>
        </ng-container>

        <ng-container matColumnDef="oidcLoginAllowed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="oidcLoginAllowed"> OIDC login allowed </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/client/{{row.id}}" class="table-cell">
            {{row.oidcLoginAllowed ? 'Yes' : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="active"> Status </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/client/{{row.id}}" class="table-cell"> {{row.active ?
            'Active' : 'Inactive'}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="actions" style="width: 50px;"> </th>
          <td mat-cell *matCellDef="let row" class="table-cell" routerLink="/admin/client/{{row.id}}">
            <button mat-icon-button class="action-edit">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>

      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.clientQueryResult.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.clientQueryResult.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>
