<h1 mat-dialog-title>{{data.action}} loan modification?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are {{actionGerund | lowercase}} modification for {{data.loanNumber}}:
  </p>

  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">
      <div *ngIf="!data.modificationForm.controls.name.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md overflow-hidden">
        <div class="overflow-hidden">{{data.modificationForm.value.name}}</div>
        <div class="mat-caption">Name</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.startDate.pristine || data.warning" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data.modificationForm.value.startDate | date) || 'Null'}}</div>
        <div class="mat-caption">Start date</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.endDate.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{(data.modificationForm.value.endDate | date) || 'Null'}}</div>
        <div class="mat-caption">End date</div>
      </div>
      <div *ngIf="data.warning" fxLayout="column" fxFlex="100" fxFlex.gt-sm="100">
        <div *ngIf="data.warning" class="mat-caption">{{data.warning}}</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.autoPaymentMode.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{autoPaymentMode || 'Do not override'}}</div>
        <div class="mat-caption">Auto payment mode</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.fixedPaymentAmount.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>${{data.modificationForm.value.fixedPaymentAmount}}</div>
        <div class="mat-caption">Fixed payment amount</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.accrueInterest.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{getAccrueInterest(data.modificationForm.value.accrueInterest)}}</div>
        <div class="mat-caption">Accrue interest</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.interestRate.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.modificationForm.value.interestRate | number}}</div>
        <div class="mat-caption">Interest rate</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.drawFee.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div *ngIf="data.modificationForm.value.drawFeeType !== FeeType.Flat">
          {{data.modificationForm.value.drawFee | number}}%</div>
        <div *ngIf="data.modificationForm.value.drawFeeType === FeeType.Flat">
          {{data.modificationForm.value.drawFee | currency}}</div>
        <div class="mat-caption">Draw fee</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.drawFeeType.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{drawFeeType || 'Do not override'}}</div>
        <div class="mat-caption">Draw fee type</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.maintenanceFee.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div *ngIf="data.modificationForm.value.maintenanceFeeType !== FeeType.Flat">
          {{data.modificationForm.value.maintenanceFee | number}}%</div>
        <div *ngIf="data.modificationForm.value.maintenanceFeeType === FeeType.Flat">
          {{data.modificationForm.value.maintenanceFee | currency}}</div>
        <div class="mat-caption">Maintenance fee</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.maintenanceFeeType.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{maintenanceFeeType  || 'Do not override'}}</div>
        <div class="mat-caption">Maintenance fee type</div>
      </div>
      <div *ngIf="!data.modificationForm.controls.active.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.modificationForm.value.active}}</div>
        <div class="mat-caption">Active</div>
      </div>
      <div *ngIf="!data.loanForm.controls.loanStatus.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{loanStatus | startCase}}</div>
        <div class="mat-caption">Status</div>
      </div>
      <div *ngIf="!data.loanForm.controls.reAmortized.pristine && data.loanForm.value.reAmortized" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div class="mat-caption" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <mat-icon class="mat-green">check_box</mat-icon>
          <span>Re-amortize</span>
        </div>
      </div>
      <div *ngIf="!data.loanForm.controls.repaymentTerm.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.loanForm.value.repaymentTerm}}</div>
        <div class="mat-caption">Repayment Term</div>
      </div>
      <div *ngIf="!data.loanForm.controls.freezeForDays.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.loanForm.value.freezeForDays}}</div>
        <div class="mat-caption">Freeze for days</div>
      </div>
      <div *ngIf="!data.loanForm.controls.frozenUntil.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50"
        class="layout padding-vertical-md">
        <div>{{data.loanForm.value.frozenUntil | date: 'shortDate'}}</div>
        <div class="mat-caption">Frozen until</div>
      </div>
      <div *ngIf="data.notes && data.notes.length" fxLayout="column" fxFlex="100" class="layout padding-vertical-md">
        <div class="mat-caption margin-bottom-5">Notes:</div>
        <div class="notes-list" *ngFor="let note of data.notes">
          <div class="overflow-hidden">
            <span>{{note.text}}</span> <br />
            <span class="mat-caption">{{note.date | date: 'medium'}}</span>
            <br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>