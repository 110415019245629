<div fxLayout="row" fxLayoutAlign="start start" ngClass.sm="layout padding-left-md" ngClass.md="layout padding-left-md">
  <a href="#" (click)="$event.preventDefault(); backToBankCustomerList()">
    <span fxHide.xs>Banking customers</span>
    <mat-icon fxHide.gt-xs>chevron_left</mat-icon>
  </a>
  <mat-icon fxHide.xs *ngIf="data.bankingCustomer">chevron_right</mat-icon>
  <a href="#" (click)="$event.preventDefault(); backToBankCustomerBankAccountList()">
    <span class="layout text-transform-uppercase" fxHide.xs>{{title}} </span>
    <span class="layout text-transform-uppercase" fxHide.gt-xs>{{title | truncate : 10}} </span>
  </a>
  <mat-icon *ngIf="data.bankingAccount && (data.bankingCustomer.name)">chevron_right</mat-icon>
  <span *ngIf="data.bankingAccount" class="layout text-transform-uppercase" fxHide.xs>{{bankAccountName}}
  </span>
  <span *ngIf="data.bankingAccount" class="layout text-transform-uppercase" fxHide.gt-xs>
    {{bankAccountName | truncate : 10}}
  </span>
</div>