import { UserData } from "./user.model";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "./user.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class UserCurrentResolver implements Resolve<UserData> {
    constructor(private service: UserService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserData> | Promise<UserData> | UserData {
        return this.service.current;
    }
}
