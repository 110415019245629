<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <div class="mat-form-field">
        <ifb-application-number-input [fc]="form.get('applicationNumber')"></ifb-application-number-input>
      </div>
      
      <div class="mat-form-field">
        <ifc-account-loan-number-input [fc]="form.get('loanNumber')"></ifc-account-loan-number-input>
      </div>      

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <div class="mat-form-field">
        <ifb-business-name-input [disabled]="!data.canFilterByEntity" [fc]="form.get('businessName')" class="mat-form-field"></ifb-business-name-input>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Stage</mat-label>
        <mat-select formControlName="stage" placeholder="All">
          <mat-option [value]="">All</mat-option>
          <mat-option *ngFor="let option of applicationStageOptions" [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>Brand</mat-label>
        <mat-select formControlName="companyId" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <ifc-filter-param-date-range-input [fc]="form.get('createdOn')" [label]="'Created on'" (rangeSelected)="createdDateSelected($event)">
      </ifc-filter-param-date-range-input>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('createdOn').value === 'custom'">     

      <ifc-datepicker [fc]="form.get('createdOnStart')" [label]="'Created date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('createdOnEnd')" [label]="'Created date to'"></ifc-datepicker>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>Bank link attempted</mat-label>
        <mat-select formControlName="bankLinkAttempted" placeholder="All">
          <mat-option>All</mat-option>
          <mat-option [value]="'true'">Yes</mat-option>
          <mat-option [value]="'false'">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Bank link status</mat-label>
        <mat-select formControlName="bankLinkStatus" placeholder="All">
          <mat-option [value]="">All</mat-option>
          <mat-option *ngFor="let option of bankLinkStatusOptions" [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      
      <ifc-filter-param-date-range-input [fc]="form.get('lastLinkingAttemptDate')" [label]="'Last linking attempt date'" (rangeSelected)="lastLinkingAttemptDateSelected($event)">
      </ifc-filter-param-date-range-input>

      <ifc-datepicker [fc]="form.get('lastLinkingAttemptDateFrom')" [label]="'Last linking attempt date from'" *ngIf="form.get('lastLinkingAttemptDate').value === 'custom'">
      </ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('lastLinkingAttemptDate').value === 'custom'">      
      <ifc-datepicker [fc]="form.get('lastLinkingAttemptDateTo')" [label]="'Last linking attempt date to'">
      </ifc-datepicker>
    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>