import { Component, OnInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  BrokerApiKeyData,
  BrokerApiKeyListActionsService,
  BrokerApiKeyListInputData,
  BrokerApiKeyQueryParams,
  MessageService,
  QueryParamsService,
  parseNumber,
} from "common";
import { ActivatedRoute, Router } from "@angular/router";
import { UserPermissionService } from "projects/backoffice/src/app/user/user-permission/user-permission.service";
import { readWrite } from "projects/backoffice/src/app/user/user-permission/user-permission.data";
import { BrokerApiKeysFacade } from "../../domain/+state/broker-api-keys-facade";
import { routerTransition } from "projects/backoffice/src/app/shared/animations/router.animations";
import { BrokerApiKeyListFacade } from "./broker-api-key-list.facade";

@UntilDestroy()
@Component({
  selector: "ifb-broker-api-key-list",
  templateUrl: "./broker-api-key-list.component.html",
  styleUrls: ["./broker-api-key-list.component.scss"],
  providers: [BrokerApiKeyListFacade],
  animations: routerTransition,
})
export class BrokerApiKeyListComponent implements OnInit {
  private brokerId: number;
  private queryParams: BrokerApiKeyQueryParams;

  readWriteBrokerApiKeys: boolean;
  data: BrokerApiKeyListInputData = {
    dataSource: {
      totalCount: 0,
      values: [],
    },
    writeAllowed: false,
    filterData: undefined,
  };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly queryParamsService: QueryParamsService,
    private readonly userPermissionService: UserPermissionService,
    private readonly brokerApiKeysFacade: BrokerApiKeysFacade,
    private readonly brokerApiKeyListFacade: BrokerApiKeyListFacade,
    private readonly actionService: BrokerApiKeyListActionsService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.watchActions();
    this.brokerId = parseNumber(this.route.snapshot.paramMap.get("id"));
    this.userPermissionService
      .granted([readWrite("brokers-broker-api-keys")])
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.readWriteBrokerApiKeys = res));

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((it) => {
      this.queryParams = {
        brokerId: this.brokerId,
        ...this.queryParamsService.init(it),
      };

      this.refreshBrokerApiKeys();
    });
  }

  private refreshBrokerApiKeys() {
    const queryParams: BrokerApiKeyQueryParams = {
      brokerId: this.brokerId,
      limit: this.queryParams.limit,
      skip: this.queryParams.skip,
      sort: this.queryParams.sort,
    };

    this.brokerApiKeysFacade.getBrokerApiKeys(queryParams).subscribe((res) => {
      this.data = {
        dataSource: res,
        writeAllowed: this.readWriteBrokerApiKeys,
        filterData: this.queryParams
      };
    });
  }

  private watchActions() {
    this.actionService.filter$
      .pipe(untilDestroyed(this))
      .subscribe((paginator: MatPaginator) => this.onFilter(paginator));

    this.actionService.delete$
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => this.onDelete(id));

    this.actionService.statusChanged$
      .pipe(untilDestroyed(this))
      .subscribe((row: BrokerApiKeyData) => this.onStatusChanged(row));

    this.actionService.edit$
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => this.onEdit(id));
  }

  onStatusChanged(row: BrokerApiKeyData) {
    this.brokerApiKeysFacade
      .updateBrokerApiKey(row.id, { ...row, active: !row.active })
      .subscribe({
        next: () => {
          this.refreshBrokerApiKeys();
          this.messageService.success(
            `Broker API key has been successfully ${
              row.active ? "deactivated." : "activated."
            }`
          );
        },
        error: () =>
          this.messageService.error(
            `Broker API key cannot be ${
              row.active ? "deactivated." : "activated."
            }`
          ),
      });
  }

  onDelete(id: number) {
    this.brokerApiKeysFacade.removeBrokerApiKey(id).subscribe({
      next: () => {
        this.refreshBrokerApiKeys();
        this.messageService.success(
          `Broker API key has been successfully removed.`
        );
      },
      error: () =>
        this.messageService.error(`Broker API key cannot be removed.`),
    });
  }

  onFilter(paginator: MatPaginator) {
    this.brokerApiKeyListFacade.filter(paginator);
  }

  private onEdit(id: number) {
    this.router.navigate([`/broker/${this.brokerId}/api-keys/${id}`]);
  }
}
