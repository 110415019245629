<div class="content">
    <mat-tab-group>

        <mat-tab label="GENERAL INFO">
            <form [formGroup]="form" fxLayout="column" fxLayoutAlign="center center" class="layout padding-top">

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="12px">
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>Name</mat-label>
                        <input matInput readonly formControlName="name" type="text" placeholder="Name">
                    </mat-form-field>
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>DBA</mat-label>
                        <input matInput readonly formControlName="dba" type="text" placeholder="DBA">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="12px">
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>Care of</mat-label>
                        <input matInput readonly formControlName="careOf" type="text" placeholder="Care of">
                    </mat-form-field>
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>Phone</mat-label>
                        <input matInput readonly formControlName="phone" customMask="(000)000-0000" type="text"
                            placeholder="Phone">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="12px">
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>Email</mat-label>
                        <input matInput readonly formControlName="email" type="text" placeholder="Email">
                    </mat-form-field>
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>URL</mat-label>
                        <input matInput readonly formControlName="url" type="text" placeholder="URL">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>Street address</mat-label>
                        <input #inlineInput matInput readonly formControlName="street" type="text">
                    </mat-form-field>
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>Unit/Suite/Apt #</mat-label>
                        <input matInput readonly formControlName="unitNumber" type="text">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
                        <mat-label>City</mat-label>
                        <input #cityInput matInput readonly formControlName="city" type="text">
                    </mat-form-field>
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-half-md">
                        <mat-label>State</mat-label>
                        <mat-select matInput disabled type="text" formControlName="state">
                            <mat-option *ngFor="let state of states" [value]="state.value">{{state.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-half-md">
                        <mat-label>Zip code</mat-label>
                        <input matInput readonly formControlName="zipCode" type="text">
                        <mat-error> Incorrect zip code format. </mat-error>
                    </mat-form-field>
                </div>

            </form>
        </mat-tab>

        <mat-tab label="FEATURES">
            <ifb-feature-flag-list></ifb-feature-flag-list>
        </mat-tab>

    </mat-tab-group>
</div>
