<div class="ifc-card-elevation layout margin-top" [class.mat-elevation-z4]="true" [ngClass.gt-md]="'card lg'"
  [ngClass.md]="'card md'" [ngClass.xs]="'xs'">

  <div class="card-header-primary" [class.mat-elevation-z4]="true" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <div class="card-title" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px">
        <span class="mat-title">{{broker?.name}}</span>
      </div>
    </div>
    <button *ngIf="edit" (click)="detailsLink()" mat-icon-button aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <div class="card-body">
    <mat-list>

      <mat-list-item>
        <mat-icon mat-list-icon>work</mat-icon>
        <h4 mat-line>{{broker?.dba || '-'}}</h4>
        <p mat-line> DBA </p>
      </mat-list-item>

      <mat-list-item class="business-address">
        <mat-icon mat-list-icon>location_on</mat-icon>
        <h4 mat-line>{{broker?.address?.line1 || '-'}}</h4>
        <h5 mat-line>
          {{broker?.address?.city ? (broker?.address?.city + ',') : ''}}
          {{broker?.address?.state}}
          {{broker?.address?.zip}}
        </h5>
        <h5 mat-line>{{broker?.address?.line2}}</h5>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>receipt</mat-icon>
        <h4 mat-line>{{broker?.ein || '-'}}</h4>
        <p mat-line> Tax ID </p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{broker?.phone | phone}}</h4>
        <p mat-line> Primary phone </p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>desktop_windows</mat-icon>
        <h4 mat-line>{{broker?.webSite || '-'}}</h4>
        <p mat-line> Website </p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>mail</mat-icon>
        <h4 mat-line>{{broker?.contactEmail || '-'}}</h4>
        <p mat-line> Contact email </p>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon>mail</mat-icon>
        <h4 mat-line>{{broker?.commissionEmail || '-'}}</h4>
        <p mat-line> Commission email </p>
      </mat-list-item>

    </mat-list>
  </div>
</div>