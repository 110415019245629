import { NgModule } from '@angular/core';

import { CommonModule } from 'common';
import { SharedModule } from '../shared/shared.module';
import { LogAppBarTitleComponent } from './log-app-bar-title/log-app-bar-title.component';
import { LogListComponent } from './log-list/log-list.component';
import { LogRoutingModule } from './log-routing.module';
import { LogGroupedListComponent } from './log-grouped-list/log-grouped-list.component';
import { LogListFilterDialogComponent } from './log-list-filter-dialog/log-list-filter-dialog.component';
import { LogShellComponent } from './log-shell/log-shell.component';
import { AccountModule } from '../account/account.module';
import { CustomerExportModule } from '../customer/customer.module';
import { LogDetailsComponent } from './log-details/log-details.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LogRoutingModule,
        AccountModule,
        CustomerExportModule
    ],
    declarations: [
        LogAppBarTitleComponent,
        LogListComponent,
        LogGroupedListComponent,
        LogListFilterDialogComponent,
        LogShellComponent,
        LogDetailsComponent
    ]
})
export class LogModule { }
