import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppBarAction, AppBarActionsService, AppBarTitleService, AppPageService, DialogConfirm } from 'common';
import { Subscription } from 'rxjs';
import { UserPermissionGroupData } from '../../user-permission-group/user-permission-group.data';
import { UserRoleData, RoleUpdateRequestDto } from '../user-role.data';
import { UserRoleService } from '../user-role.service';
import { UserPermissionData, UserPermissionAccess, UserPermissionId } from '../../user-permission/user-permission.data';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserQueryParams } from '../../../admin/user/user.model';
import { UserRoleUpdateDialogComponent } from '../user-role-update-dialog/user-role-update-dialog.component';

@Component({
  selector: 'ifb-user-role-details',
  templateUrl: './user-role-details.component.html',
  styleUrls: ['./user-role-details.component.scss']
})
export class UserRoleDetailsComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  updatedPermissions: UserPermissionData[] = [];

  constructor(
    private route: ActivatedRoute,
    private roleService: UserRoleService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog) {
    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  cancel() {
    this.appPageService.back();
  }

  data: RoleDetailsComponentData;
  form: UntypedFormGroup = this.formBuilder.group({ status: undefined });
  params: UserQueryParams;

  dataSet(data: RoleDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = `${data.role.name} role settings`;
    this.dirty = false;

    this.form.reset({
      status: this.data.role.active,
    });
  }

  dirty = false;

  get expandAllChecked() {
    return this.expandedCount === this.data.permissionGroups.length;
  }

  get expandAllIndeterminate() {
    const expandedCount = this.expandedCount;
    return expandedCount > 0
      && expandedCount !== this.data.permissionGroups.length;
  }

  expandAllToggle() {
    const expanded = !this.expandAllChecked;
    this.data.permissionGroups.forEach(it => it.expanded = expanded);
  }

  get expandedCount() {
    return this.data.permissionGroups.filter(it => it.expanded).length;
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this)),
      this.appBarActionsService.invoking.subscribe(this.actionDispatch.bind(this)),
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  permissionChecked(id: string, value: string): boolean {
    const permission = this.data.role.permissions.find(it => it.id === id);
    return permission && permission.access === value;
  }

  permissionGroupSet(group: UserPermissionGroupData, value: UserPermissionAccess) {
    group.permissions
      .filter(def => def.allowedAccessType.find(it => it === value))
      .forEach(def => this.permissionSet(def.id, value, def.name));
  }

  permissionSet(id: UserPermissionId, value: UserPermissionAccess, name: string) {
    const permission: UserPermissionData = this.data.role.permissions
      .find(it => it.id === id) || { id: undefined, access: undefined };

    if (!permission.id) {
      permission.id = id;
      this.data.role.permissions.push(permission);
    }

    permission.access = value;
    this.dirty = true;

    this.updatedPermissions.push(Object.assign(permission, { name: name }));
  }

  save() {
    if (this.saveSub) return;

    if (!this.data || !this.data.role || !this.data.role.id) return;

    if (!this.form.dirty && !this.dirty) {
      this.cancel();
      return;
    }

    UserRoleUpdateDialogComponent.show(this.dialog, this.updatedPermissions).subscribe(result => {
      if (result) {
        const roleUpdateRequestDto: RoleUpdateRequestDto = {
          permissions: this.data.role.permissions,
          active: this.form.controls.status.value
        };
        this.saveSub = this.roleService
          .updatePermissions(this.data.role.id, roleUpdateRequestDto)
          .subscribe(this.saveSuccess.bind(this));
      }
    });
  }

  private saveSub: Subscription;

  private saveSuccess(role: UserRoleData) {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;

    this.data.role = role;
    this.dataSet(this.data);

    this.appPageService.back();
  }
}

export interface RoleDetailsComponentData {
  role: UserRoleData;
  permissionGroups: UserPermissionGroupData[];
}
