import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LitigationService } from './litigation.service';
import { LiquidityEvent } from 'common';

@Injectable({
  providedIn: 'root'
})
export class AccountLiquidityResolver implements Resolve<any> {
  constructor(private litigationService: LitigationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<LiquidityEvent> | Promise<LiquidityEvent> | LiquidityEvent {
    return this.litigationService.getLiquidityEventForLoan(route.params.id);
  }
}
