import { QueryResult, QueryParams } from "common";

export enum BankingAccountStatus {
    Active = 'active',
    Pending = 'pending'
}

export interface BankingAccount
{
    accountNumber?: string,
    routingNumber?: string,
    aggregationAttemptDate?: Date,
    aggregationSuccessDate?: Date,
    balance: number,
    balanceDate?: Date,
    currency?: string,
    customerId?: string,
    customerMessage?: string,
    id?: string,
    institutionId?: string,
    institutionLoginId?: string,
    institutionName?: string,
    name?: string,
    providerStatus?: string,
    status?: string,
    statusMessage?: string,
    type?: string
}

export interface BankingAccountAchNumbers
{
    accountNumber?: string,
    routingNumber?: string,
}

export interface BankingAccountQueryResult extends QueryResult<BankingAccount> {
}

export interface BankingAccountQueryParams extends QueryParams {
    onlyPendingAccounts?: boolean;
}

export class BankingAccountFilters {
    onlyPendingAccounts: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.onlyPendingAccounts = filters.onlyPendingAccounts || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
    }
}

export interface BankingCustomer {
    id?: string;
    name?: string;
    username?: string;
    bank?: string,
    createdDate?: string;
}

export enum BankingCustomerType {
    Active = 'active',
    Testing = 'testing'
}

export interface BankingCustomerQueryParams extends QueryParams {
    username?: string;
    name?: string;
}

export interface BankingCustomerQueryResult extends QueryResult<BankingCustomer> {
}

export class BankingCustomerFilters {
    username?: string;
    name?: string;

    constructor(filters?) {
        filters = filters || {};
        this.username = filters.username || undefined;
        this.name = filters.name || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
    }
}

export interface BankingTransaction {
    id?: number;
    amount?: number;
    accountId?: string;
    customerId?: string;
    status?: string;
    description?: string;
    memo?: string;
    type?: string;
    postedDate?: Date;
    transactionDate?: Date;
    category?: string;
}

export enum BankingTransactionType {
    Atm = 'atm',
    Cash = 'cash',
    Check = 'check',
    Credit = 'credit',
    Debit = 'debit',
    Deposit = 'deposit',
    DirectDebit = 'directDebit',
    DirectDeposit = 'directDeposit',
    Dividend = 'dividend',
    Fee = 'fee',
    Interest = 'interest',
    Other = 'other',
    Payment = 'payment',
    PointOfSale = 'pointOfSale',
    RepeatPayment = 'repeatPayment',
    ServiceCharge = 'serviceCharge',
    Transfer = 'transfer',
}

export enum BankingTransactionStatus {
    Active = 'active',
    Pending = 'pending',
    Shadow = 'shadow'
}

export interface BankingTransactionCategorization {
    merchant?: string;
    category?: string;
    city?: string;
    state?: string;
    country?: string;
}

export interface BankingTransactionQueryParams extends QueryParams {
    fromDate?: any;
    toDate?: any;
    includePending?: boolean;
}

export interface BankingTransactionQueryResult extends QueryResult<BankingTransaction> {
}

export class BankingTransactionFilters {
    fromDate: number;
    toDate: number;
    includePending: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.fromDate = filters.fromDate || undefined;
        this.toDate = filters.toDate || undefined;
        this.includePending = filters.includePending || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
    }
}
