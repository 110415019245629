import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserPermissionService } from '../../user-permission/user-permission.service';
import { Observable } from 'rxjs';
import { UserRoleService } from '../user-role.service';
import { UserQueryResult, UserQueryParams } from '../../../admin/user/user.model';
import { switchMap, map } from 'rxjs/operators';
import { readOnly } from '../../user-permission/user-permission.data';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleAssigneesResolver implements Resolve<UserQueryResult> {

  params: UserQueryParams;

  constructor(private userRoleService: UserRoleService, private userPermissionService: UserPermissionService, private queryParamsService: QueryParamsService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<UserQueryResult> | Promise<UserQueryResult> | UserQueryResult {

    const query = new UserQueryParams(this.queryParamsService.init({ roleId: route.params.id }));

    return this.userPermissionService.granted([readOnly('admin-users')])
      .pipe(
        switchMap(res => res ?
          this.userRoleService.usersList(query).pipe(map(result => result)) : [])
      );
  }
}
