import { QueryParams, QueryResult, parseNumber } from "common";
import { UserPermissionData } from "../user-permission/user-permission.data";

export interface UserRoleData {
    id: number;
    name: string;
    client?: string;
    externalId?: string;
    active?: boolean;
    permissions: UserPermissionData[];
}

export interface RoleUpdateRequestDto {
    active?: boolean;
    permissions: UserPermissionData[];
}

export class UserRoleQueryParams extends QueryParams {
    clientId?: number;
    name?: string;
    externalId?: string;
    active?: boolean;

    constructor(params?: any) {
      super(params);

      params = params || {};
      this.clientId = parseNumber(params.clientId) || undefined;
      this.name = params.name || undefined;
      this.externalId = params.externalId || undefined;
      this.active = params.active || undefined;
    }
}

export interface UserRoleQueryResult extends QueryResult<UserRoleData> {
}

export class UserRoleFilters {
    clientId: number;
    name: string;
    externalId: string;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.clientId = filters.clientId || undefined;
        this.name = filters.name || undefined;
        this.externalId = filters.externalId || undefined;
        this.active = filters.active || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
    }
}
