import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { EnumHelper, FeeType, PaymentFrequency, PaymentFrequencyLabel } from 'common';

@Component({
  selector: 'ifb-account-offer-update-dialog',
  templateUrl: './account-offer-update-dialog.component.html',
  styleUrls: ['./account-offer-update-dialog.component.css']
})
export class AccountOfferUpdateDialogComponent {

  constructor(public dialogRef: MatDialogRef<AccountOfferUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  get FeeType() { return FeeType; }

  static show(dialog: MatDialog, form: UntypedFormGroup): Observable<any> {
    return dialog.open(AccountOfferUpdateDialogComponent, {
      width: '510px',
      data: { form: form },
      closeOnNavigation: true,
    }).afterClosed();
  }

  getFeeType(value: any) {
    return EnumHelper.getNameFromValue(FeeType, value);
  }

  gatPaymentFrequency(value: any) {
    return EnumHelper.getMappedNameFromValue(PaymentFrequency, PaymentFrequencyLabel, value);
  }
}
