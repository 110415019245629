import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Sort, MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subscription, Subject } from 'rxjs';
import { ObservableHelper, FilterParameter } from 'common';
import { BankingTransactionListFilterService } from '../banking-transaction-list-filter-dialog/banking-transaction-list-filter.service';
// eslint-disable-next-line max-len
import { BankingTransactionListFilterDialogComponent } from '../banking-transaction-list-filter-dialog/banking-transaction-list-filter-dialog.component';
import { BankingTransactionQueryResult, BankingTransactionQueryParams, BankingTransactionFilters, BankingAccount } from '../banking.model';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-banking-transaction-list',
  templateUrl: './banking-transaction-list.component.html',
  styleUrls: ['./banking-transaction-list.component.css']
})
export class BankingTransactionListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private subs: Subscription[] = [];

  filters: BankingTransactionFilters;
  activeFilters: FilterParameter[] = [];
  queryParams: BankingTransactionQueryParams;
  data: BankTransactionListComponentData;
  bankingCustomerId: string;
  bankingAccountId: string;
  provider: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _bankingTransactionListFilterService: BankingTransactionListFilterService,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.route.queryParams.subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.activeFilters = this._bankingTransactionListFilterService.addFilterParameters(this.queryParams);
      })
    ];

    this.filters = new BankingTransactionFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this._bankingTransactionListFilterService
        .filter(
          this.provider,
          this.filters,
          this.data.bankingAccount.id,
          this.bankingCustomerId,
          this.paginator,
          this.sort);
    });
  }

  ngOnDestroy() {
    ObservableHelper.unsubscribeAll(this.subs);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  filterDialog() {
    BankingTransactionListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this._bankingTransactionListFilterService
            .filter(
              this.provider,
              this.filters,
              this.data.bankingAccount.id,
              this.bankingCustomerId,
              this.paginator,
              this.sort);
        }
      });
  }

  private dataInit(data: BankTransactionListComponentData) {
    this.data = data;
    this.bankingCustomerId = this.route.snapshot.paramMap.get("bankCustomerId");
    this.provider = this.route.snapshot.paramMap.get("provider");
  }

  refresh() {
    this._bankingTransactionListFilterService
      .filter(
        this.provider,
        this.filters,
        this.data.bankingAccount.id,
        this.bankingCustomerId,
        this.paginator,
        this.sort);
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    'transactionDate',
    'postedDate',
    'amount',
    'status',
    'type',
    'description',
    'category',
    'memo',
    'id'
  ];
}

export interface BankTransactionListComponentData {
  bankTransactionQueryResult: BankingTransactionQueryResult;
  bankingAccount: BankingAccount;
}
