<ifc-app-scroll-container class="scroll-container" [widgetsWithTopHeader]="true">
  <form [formGroup]="form">
    <div *ngIf="inputData?.isCreating" class="single-card">
      <ng-container *ngTemplateOutlet="formFields"></ng-container>
    </div>
    <ifc-app-card *ngIf="!inputData?.isCreating">
      <ifc-broker-api-key-card>
        <div class="flex column app-card-content">
          <ng-container *ngTemplateOutlet="formFields"></ng-container>
        </div>
      </ifc-broker-api-key-card>

      <aside class="flex column app-card-aside">
        <ifc-broker-api-key-card>
          <h2 class="mat-display-1 layout margin-bottom">API Key</h2>
          <div
            *ngIf="inputData?.dataSource?.createdBy"
            class="layout padding-top"
          >
            <div class="value">{{ inputData.dataSource.createdBy }}</div>
            <p class="label">Created by</p>
          </div>

          <div *ngIf="inputData?.dataSource?.createdOn" class="layout">
            <div class="value">
              {{ inputData.dataSource.createdOn | date : "medium" }}
            </div>
            <p class="label">Created on</p>
          </div>
        </ifc-broker-api-key-card>
      </aside>
    </ifc-app-card>
    <ng-template #formFields>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          type="text"
          placeholder="Name"
          specialIsAlphaNumeric
          [readonly]="!inputData?.writeAllowed"
        />
      </mat-form-field>
    
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input
          matInput
          formControlName="description"
          type="text"
          placeholder="Description"
          specialIsAlphaNumeric
          [readonly]="!inputData.writeAllowed"
        />
      </mat-form-field>
    
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select
          [disabled]="!inputData.writeAllowed"
          required
          formControlName="active"
        >
          <mat-option
            *ngFor="let option of BrokerApiKeysActiveLabelValues"
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-error>Please select a status.</mat-error>
      </mat-form-field>
    
      <div class="flex row end-center">
        <ifc-app-bar-actions
          [menuMobileAllowed]="false"
        ></ifc-app-bar-actions>
      </div>
    </ng-template>
  </form>
</ifc-app-scroll-container>
