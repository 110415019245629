import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CustomerService } from '../../customer.service';
import { Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import { CustomerLoansAssignmentDialogComponent } from './customer-loans-assignment-dialog/customer-loans-assignment-dialog.component';
import { CustomerLoanType } from '../../customer.model';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { map, takeUntil } from 'rxjs/operators';
import { EnumHelper, LoanStatus, LoanInfoDto } from 'common';

@Component({
  selector: 'ifb-customer-loans-widget',
  templateUrl: './customer-loans-widget.component.html',
  styleUrls: ['./customer-loans-widget.component.scss']
})
export class CustomerLoansWidgetComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  @Input()
  edit: boolean;

  @Input()
  customerId: number;

  @Input()
  saleforceViewMode: boolean;

  loans: any[];
  pendingLoans: any[];

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  canAddLoans: boolean;
  canAddPendingLoans: boolean;

  constructor(private router: Router,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    this.getData();

    this.userPermissionService.granted([readWrite('servicing-customers-loans')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canAddLoans = res);

    this.userPermissionService.granted([readWrite('servicing-customers-pending-loans')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canAddPendingLoans = res);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getData() {
    this.fetchData().pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.loans = res.loans;
        this.pendingLoans = res.pendingLoans;
      });
  }

  fetchData() {
    return forkJoin([
      this.customerService.getLoansById(this.customerId),
      this.customerService.get(this.customerId)
    ]).pipe(
      map(results => ({
        loans: results[0] ? results[0].filter(l => results[1].loans.includes(l.id)) : [],
        pendingLoans: results[0] ? results[0].filter(l => results[1].pendingLoans.includes(l.id)) : []
      }))
    );
  }

  getStatus(statusId: number): string {
    return _.startCase(EnumHelper.getNameFromValue(LoanStatus, statusId));
  }

  showDeleteConfirmDialog(loan): void {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '510px',
      disableClose: false
    });

    this.confirmDialogRef.componentInstance
      .confirmMessage = `Are you sure you want to remove account assignment ${loan.loanNumber} ${loan.entityName}?`;
  }

  deleteLoan(loan) {
    this.showDeleteConfirmDialog(loan);

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customerService.removeLoanById(this.customerId, loan.id)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => this.getData());
      }
      this.confirmDialogRef = null;
    });
  }

  deletePendingLoan(loan) {
    this.showDeleteConfirmDialog(loan);

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customerService.removeLoanById(this.customerId, loan.id)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => this.getData());
      }
      this.confirmDialogRef = null;
    });
  }

  assignLoans() {
    CustomerLoansAssignmentDialogComponent.show(this.dialog, this.customerId, CustomerLoanType.Loans)
      .subscribe(data => {
        if (!data)
          return;
        this.customerService.assignLoans(this.customerId, data)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => this.getData());
      });
  }

  assignPendingLoans() {
    CustomerLoansAssignmentDialogComponent.show(this.dialog, this.customerId, CustomerLoanType.PendingLoans)
      .subscribe(data => {
        if (!data)
          return;
        this.customerService.assignPendingLoans(this.customerId, data)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => this.getData());
      });
  }

  goToAccountDetails(loan, newWindow: boolean = false) {
    const path = this.accountDetailsLink(loan);
    if (newWindow)
      window.open(path);
    else {
      this.router.navigate([path]);
    }
  }

  accountDetailsLink(loan) {
    let path = `/account/${loan.id}/profile`;
    if (this.saleforceViewMode) {
      path = `/account/sf/${loan.uniqueId}/profile`;
    }
    return path;
  }

  getUtilization(loanInfo: LoanInfoDto): number {
    return Math.round((1 - loanInfo.availableFunds / loanInfo.creditLimit) * 100);
  }

}
