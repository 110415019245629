<mat-card class="ifc-card" [ngClass.xs]="'xs'">
    <mat-card-content class="layout padding-md">
      <div fxLayout="column" fxLayoutAlign="start " fxFlexFill>
        <div fxLayout="row" fxLayoutAlign="space-between start">
  
          <mat-card-header fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="space-between center">
            <ifc-avatar-icon [data]="data?.advisorAvatar"></ifc-avatar-icon>
            <div>
              <div fxLayout="column">
                <span>{{data?.advisorName}}</span>
                <span class="secondary-text mat-body-1">Business Advisor</span>
              </div>
              <p class="layout margin-bottom-0 margin-top-sm">{{data?.advisorEmail}}</p>
              <p class="layout margin-top-0">{{data?.advisorPhone}}</p>
            </div>
          </mat-card-header>
  
          <button *ngIf="canEdit()" mat-icon-button (click)="editAdvisor()" aria-label="Edit">
            <mat-icon>edit</mat-icon>
          </button>
  
        </div>
      </div>
    </mat-card-content>
  </mat-card>