import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterParameter } from 'common';
import { UserRoleQueryResult, UserRoleFilters, UserRoleQueryParams } from '../user-role.data';
import { ClientData } from '../../../auth/client/client.data';
import { UserRoleFilterService } from '../user-role-filter.service';
import { UserRoleListFilterDialogComponent } from '../user-role-list-filter-dialog/user-role-list-filter-dialog.component';
import { routerTransition } from '../../../shared/animations/router.animations';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: ['./user-role-list.component.css'],
  animations: routerTransition
})
export class UserRoleListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  filters: UserRoleFilters;
  activeFilters: FilterParameter[] = [];
  showPaginator: boolean;
  queryParams: UserRoleQueryParams;

  constructor(private route: ActivatedRoute,
    private _userRoleFilterService: UserRoleFilterService,
    private dialog: MatDialog,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }
  data: ClientListComponentData;

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((it: ClientListComponentData) => {
      this.data = it;
    });
    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(it => {
      this.queryParams = new UserRoleQueryParams(this.queryParamsService.init(it));
      this.activeFilters = this._userRoleFilterService.addFilterParameters(this.queryParams);
    });

    this.filters = new UserRoleFilters(this.queryParams);
    this.showPaginator = this.data.roleQueryResult.totalCount > 10;
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex =  this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this._userRoleFilterService.filter(this.filters);
    });
  }

  filterDialog() {
    UserRoleListFilterDialogComponent.show(this.dialog, new UserRoleFilters(this.queryParams), this.data.clients)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this._userRoleFilterService.filter(result);
        }
      });
  }

  sortData(sort: Sort) {
    this._userRoleFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this._userRoleFilterService.filter(this.filters, this.paginator, this.sort);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    'name',
    'client',
    'externalId',
    'active',
    'actions'
  ];
}

export interface ClientListComponentData {
  clients: ClientData[];
  roleQueryResult: UserRoleQueryResult;
}
