<ifc-app-scroll-container class="ifc-app-scroll-container-center">
  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <form [formGroup]="form" ngClass.xs="layout fill">

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">
        <mat-form-field appearance="outline">
          <mat-label>First name</mat-label>
          <input matInput type="text" formControlName="firstName">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last name</mat-label>
          <input matInput type="text" formControlName="lastName">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Social security number</mat-label>
          <input *ngIf="canEditSensitiveData" matInput type="text" formControlName="ssn" customMask="000-00-0000">
          <input *ngIf="!canEditSensitiveData" matInput type="text" formControlName="ssn">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">
        <mat-form-field appearance="outline">
          <mat-label>Salesforce ID</mat-label>
          <input matInput type="text" formControlName="uniqueId">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Account type</mat-label>
          <input matInput type="text" formControlName="accountType">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl" class="scroll-margin-bottom">
        <mat-form-field appearance="outline" fxFlex="48">
          <mat-label> Status</mat-label>
          <mat-select matInput formControlName="status" placeholder="Status">
            <mat-option *ngFor="let option of customerStatusOptions" [value]="option.value">{{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>
  </div>
</ifc-app-scroll-container>
