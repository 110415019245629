import { Competitor } from "../../../models/competitor.model";

export interface CompetitorDetailsComponentData {
  competitor: Competitor;
  workMode: WorkModeEnum;
  isReadWritePermission: boolean;
}

export enum WorkModeEnum {
  Edit,
  Create
}
