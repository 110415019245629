<ifb-card>
  <mat-card class="ifc-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md padding-bottom-0">
      <div class="card-title">Total draws</div>
    </div>
    <mat-card-content class="content">
      <ifb-line-chart [options]="chartData.options" [view]="chartData.view" [data]="chartData.data"></ifb-line-chart>
    </mat-card-content>
  </mat-card>
</ifb-card>
