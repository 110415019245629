import { Injectable } from '@angular/core';
import { LogFilters, LogQueryParams, LogGroupByLabel } from './log.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, FilterParameter } from 'common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogFilterService {

  constructor(private router: Router) { }

  filter(filters: LogFilters, paginator?: MatPaginator, sort?: MatSort) {
    const query: LogQueryParams = filters;

    query.timestampStart = filters.timestampStart ? moment(filters.timestampStart).format('L') : undefined;
    query.timestampEnd = filters.timestampEnd ? moment(filters.timestampEnd).format('L') : undefined;

    query.skip = undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    this.router.navigate(['logs'], { replaceUrl: true, queryParams: query });
  }

  addFilterParameters(query: LogQueryParams): FilterParameter[] {
    // Refresh filters chip list
    const filters: FilterParameter[] = [];

    if (query.level) {
      filters.push({
        id: 'level', name: 'Level', value: query.level.trim()
      });
    }

    if (query.threadId) {
      filters.push({
        id: 'thread', name: 'Thread', value: query.threadId.trim()
      });
    }

    if (query.processId) {
      filters.push({
        id: 'processId', name: 'Process ID', value: query.processId.trim()
      });
    }

    if (query.endpoint) {
      filters.push({
        id: 'endpoint', name: 'Endpoint name', value: query.endpoint.trim()
      });
    }

    if (query.hostName) {
      filters.push({
        id: 'hostName', name: 'Host name', value: query.hostName.trim()
      });
    }

    if (query.logger) {
      filters.push({
        id: 'logger', name: 'Logger', value: query.logger.trim()
      });
    }

    if (query.errorId) {
      filters.push({
        id: 'errorId', name: 'Error ID', value: query.errorId.trim()
      });
    }

    if (query.correlationId) {
      filters.push({
        id: 'correlationId', name: 'Correlation ID', value: query.correlationId.trim()
      });
    }

    if (query.principalId) {
      filters.push({
        id: 'principalId', name: 'Principal ID', value: query.principalId.trim()
      });
    }

    if (query.principalName) {
      filters.push({
        id: 'principalName', name: 'Principal name', value: query.principalName.trim()
      });
    }

    if (query.entityId) {
      filters.push({
        id: 'entity', name: 'Entity ID', value: query.entityId.trim()
      });
    }

    if (query.loanId) {
      filters.push({
        id: 'loan', name: 'Loan ID', value: query.loanId.trim()
      });
    }

    if (query.customerId) {
      filters.push({
        id: 'customer', name: 'Customer ID', value: query.customerId.trim()
      });
    }

    if (query.id) {
      filters.push({
        id: 'id', name: 'ID', value: query.id
      });
    }

    if (query.timestampStart) {
      filters.push({
        id: 'timestampStart', name: 'Date',
        value: `${moment(query.timestampStart).format('L')} - ${moment(query.timestampEnd).format('L')}`
      });
    }

    if (query.groupBy) {
      filters.push({
        id: 'groupBy', name: 'Grouped by',
        value: LogGroupByLabel.get(query.groupBy.trim())
      });
    }

    if (query.exceptionHashCode) {
      filters.push({
        id: 'exceptionHashCode', name: 'Exception hash code',
        value: query.exceptionHashCode.trim()
      });
    }
    if (query.active) {
      filters.push({
        id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
      });
    }
    return filters;
  }

}
