<mat-card class="ifc-card" ngClass.xs="xs">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md padding-bottom-0">
        <span class="mat-title">Accounts</span>
        <button id="add-icon" mat-icon-button matTooltip="Add" *ngIf="edit && (canAddLoans || canAddPendingLoans)"
          [matMenuTriggerFor]="menu">
          <mat-icon class="secondary-text">add_circle</mat-icon>
        </button>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="assignLoans()" *ngIf="canAddLoans">
          <span>Add account</span>
        </button>
        <button mat-menu-item (click)="assignPendingLoans()" *ngIf="canAddPendingLoans">
          <span>Add pending account</span>
        </button>
      </mat-menu>

      <div fxLayout="column">
        <mat-list>
          <mat-list-item *ngFor="let loan of loans; let i= index;">
            <span class="mat-caption" mat-list-icon>{{i+1}}</span>
            <a mat-line [routerLink]="accountDetailsLink(loan)">{{loan.loanNumber}}</a>
            <p mat-line>{{loan.entityName}}</p>
            <p mat-line class="secondary-text">{{getStatus(loan.status)}} - {{loan.productName}}</p>
            <div *ngIf="getUtilization(loan.loanInfo); let utilization" matTooltip="Utilization {{utilization}}%"
              class="utilization-bar">
              <mat-progress-bar mode="determinate" value="{{utilization}}"
                [ngClass]="{'blue': utilization < 33, 'teal': utilization < 66, 'orange': utilization > 66}">
              </mat-progress-bar>
            </div>
            <button class="delete-icon" mat-icon-button matTooltip="Delete" ngClass.gt-xs="xl" *ngIf="edit"
              (click)="deleteLoan(loan)">
              <mat-icon class="secondary-text">delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>

        <span *ngIf="pendingLoans?.length" class="mat-caption layout padding-md padding-bottom-0">Pending accounts</span>

        <mat-list>
          <mat-list-item *ngFor="let loan of pendingLoans; let i= index;">
            <span class="mat-caption" mat-list-icon>{{i+1}}</span>
            <a mat-line [routerLink]="accountDetailsLink(loan)">{{loan.loanNumber}}</a>
            <p mat-line>{{loan.businessName}}</p>
            <p mat-line class="secondary-text">{{getStatus(loan.status)}} - {{loan.productName}}</p>
            <div *ngIf="getUtilization(loan.loanInfo); let utilization" matTooltip="Utilization {{utilization}}%"
              class="utilization-bar">
              <mat-progress-bar mode="determinate" value="{{utilization}}"
                [ngClass]="{'blue': utilization < 33, 'teal': utilization < 66, 'orange': utilization > 66}">
              </mat-progress-bar>
            </div>
            <button class="delete-icon" mat-icon-button matTooltip="Delete" ngClass.gt-xs="xl" *ngIf="edit"
              (click)="deletePendingLoan(loan)">
              <mat-icon class="secondary-text">delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>

      </div>
    </div>
  </mat-card-content>
</mat-card>
