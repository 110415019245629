import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UserPermissionData } from '../../user-permission/user-permission.data';

@Component({
  selector: 'ifb-user-role-update-dialog',
  templateUrl: './user-role-update-dialog.component.html',
  styleUrls: ['./user-role-update-dialog.component.css']
})
export class UserRoleUpdateDialogComponent {

  constructor(public dialogRef: MatDialogRef<UserRoleUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, permissions: UserPermissionData[]): Observable<any> {
    return dialog.open(UserRoleUpdateDialogComponent, {
      width: '510px',
      data: { permissions: permissions },
      closeOnNavigation: true
    }).afterClosed();
  }
}
