import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FeatureFlag, FeatureFlagService } from 'common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routerTransition } from '../../../shared/animations/router.animations';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';

@Component({
  selector: 'ifb-feature-flag-list',
  templateUrl: './feature-flag-list.component.html',
  styleUrls: ['./feature-flag-list.component.scss'],
  animations: routerTransition,
})
export class FeatureFlagListComponent implements OnInit, AfterViewInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService,
    private service: FeatureFlagService) {
    this._unsubscribeAll = new Subject();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  tenantId: any;
  data: FeatureFlagListComponentData;
  readWriteFeatureFlag: boolean;
  dataSource = new MatTableDataSource<FeatureFlag>();
  updatedFeatures: FeatureFlag[] = [];

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: FeatureFlagListComponentData) => {
        this.data = it;
        if (this.data)
          this.dataSource = new MatTableDataSource(this.data.featureFlagListResult);
      });

    this.route.params.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((params: any) => { this.tenantId = params.id });

    this.userPermissionService.granted([readWrite('admin-feature-flags')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.readWriteFeatureFlag = res);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  update(event, id) {
    this.service.update(id, event.checked)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: () => this.service.list()
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(res => this.dataSource = new MatTableDataSource(res))
      })
  }

  updateForTenant(featureFlag: FeatureFlag, event?) {
    const index = this.updatedFeatures.findIndex(_ => _.id === featureFlag.id);
    featureFlag.valueForTenant = event ? event.checked : (featureFlag?.valueForTenant ?? featureFlag.value);
    if (index >= 0) {
      this.updatedFeatures[index].override = featureFlag.override;
      this.updatedFeatures[index].valueForTenant = featureFlag.valueForTenant;
    } else {
      this.updatedFeatures.push(featureFlag);
    }
  }

  getDisplayedColumns(): string[] {
    return this.tableColumns
      .filter(cd => !this.tenantId ? cd.mainList : true)
      .map(cd => cd.def);
  }

  tableColumns = [
    { def: 'code', mainList: true },
    { def: 'description', mainList: true },
    { def: 'createdOn', mainList: true },
    { def: 'override', mainList: false },
    { def: 'value', mainList: true }
  ];
}

export interface FeatureFlagListComponentData {
  featureFlagListResult: FeatureFlag[];
}
