import { Component, Input } from '@angular/core';
import { ApplicationProfileComponentData } from '../application-profile/application-profile.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ifb-application-company-info-widget',
  templateUrl: './application-company-info-widget.component.html',
  styleUrls: ['./application-company-info-widget.component.scss']
})
export class ApplicationCompanyInfoWidgetComponent {

  @Input()
  data: ApplicationProfileComponentData;

  constructor(private router: Router) { }

  openCompanyDetails() {
    this.router.navigate([`/company/${this.data.application.entityId}/profile`]);
  }

}
