<button mat-icon-button [matMenuTriggerFor]="editMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #editMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [routerLink]="transactionDetailsLink(tx)" [queryParams]="transactionDetailsQueryParams">
      <mat-icon>edit</mat-icon> Edit
    </button>
    <button *ngIf="canEditSubTransactions" mat-menu-item [routerLink]="transactionDetailsLinkAdmin(tx)" [queryParams]="transactionDetailsQueryParams">
      <mat-icon>border_color</mat-icon> Admin edit
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onExpandedDetailChanged()">
      <div *ngIf="!expandedDetail">
        <mat-icon>expand_more</mat-icon> Expand
      </div>
      <div *ngIf="expandedDetail">
        <mat-icon>expand_less</mat-icon> Collapse
      </div>
    </button>
    <button mat-menu-item (click)="viewNewTab(tx)">
      <mat-icon>open_in_new</mat-icon>
      Open In New
    </button>
    <mat-divider></mat-divider>
    <button *ngIf="tx.type === TransactionType.Draw && tx.status === TransactionStatus.Pending" mat-menu-item
      (click)="updateDialog(TransactionStatus.Pending)">
      <mat-icon>help_outlined</mat-icon> Add pending reason
    </button>
    <button
      [ngClass]="CanUserMoveTransactionToStatusGrantedPermissions[TransactionStatus.Approved].hidden ? 'hide' : ''"
      mat-menu-item (click)="updateDialog(TransactionStatus.Approved)">
      <mat-icon>check</mat-icon> Approve
    </button>
    <button
      [ngClass]="CanUserMoveTransactionToStatusGrantedPermissions[TransactionStatus.OnHold].hidden ? 'hide' : ''"
      mat-menu-item (click)="updateDialog(TransactionStatus.OnHold)">
      <mat-icon>question_mark</mat-icon> Hold
    </button>
    <button
      [ngClass]="CanUserMoveTransactionToStatusGrantedPermissions[TransactionStatus.Rejected].hidden ? 'hide' : ''"
      mat-menu-item (click)="updateDialog(TransactionStatus.Rejected)">
      <mat-icon>block</mat-icon> Reject
    </button>
    <button
      [ngClass]="CanUserMoveTransactionToStatusGrantedPermissions[TransactionStatus.Declined].hidden ? 'hide' : ''"
      mat-menu-item (click)="updateDialog(TransactionStatus.Declined)">
      <mat-icon>warning</mat-icon> Decline
    </button>
    <button
      [ngClass]="CanUserMoveTransactionToStatusGrantedPermissions[TransactionStatus.Cancelled].hidden ? 'hide' : ''"
      mat-menu-item (click)="updateDialog(TransactionStatus.Cancelled)">
      <mat-icon>highlight_off</mat-icon> Cancel
    </button>
  </ng-template>
</mat-menu>
