import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BrokerExData } from '../broker.model';

@Component({
  selector: 'ifb-broker-api-widget',
  templateUrl: './broker-api-widget.component.html',
  styleUrls: ['./broker-api-widget.component.scss']
})
export class BrokerApiWidgetComponent {

  @Input()
  broker: BrokerExData;

  @Input()
  canEdit: boolean;

  @Output()
  edit = new EventEmitter<boolean>();

  update(isChecked: boolean) {
    this.edit.emit(isChecked);
  }
}
