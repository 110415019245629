import { Component, Input } from "@angular/core";
import { DomHelper, WelcomeCall } from "common";

@Component({
  selector: "ifb-entity-welcome-call-widget",
  templateUrl: "./entity-welcome-call-widget.component.html",
})
export class EntityWelcomeCallWidgetComponent {
  @Input()
  welcomeCallData: WelcomeCall;

  public copy(value: string) {
    DomHelper.copy(value);
  }
}