<ifc-app-scroll-container>

  <div id="advisor-info-details" fxLayout="column" fxLayoutAlign="center center">

    <form [formGroup]="form" ngClass.xs="layout fill">

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        class="layout padding-bottom">
        <ifc-avatar-icon [data]="data.advisor?.avatar"></ifc-avatar-icon>
        <div *ngIf="readWriteAdvisors">
          <button mat-mini-fab type="button" matTooltip="Profile" color="primary" class="profile-button"
            ngClass.lt-sm="xs" (click)="upload()">
            <mat-icon>photo_camera</mat-icon>
          </button>
          <input #fileToUpload type="file" (change)="fileChange($event)" style="display:none;" accept='image/*'>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <mat-form-field appearance="outline">
            <mat-label> First name </mat-label>
            <input matInput type="text" name="firstName" formControlName="firstName">
          </mat-form-field>
        </div>
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <mat-form-field appearance="outline">
            <mat-label> Last name </mat-label>
            <input matInput type="text" name="lastName" formControlName="lastName">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" *ngIf="data.advisor" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px"
        fxLayout.xs="column">
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <ifc-datepicker [fc]="form.get('createdOn')" [label]="'Created on'" [disabled]="true">
          </ifc-datepicker>
        </div>
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <ifc-datepicker [fc]="form.get('updatedOn')" [label]="'Updated on'" [disabled]="true">
          </ifc-datepicker>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <mat-form-field appearance="outline">
            <mat-label> Phone </mat-label>
            <input matInput type="text" customMask="(000)000-0000" name="phone" formControlName="phone">
          </mat-form-field>
        </div>
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <mat-form-field appearance="outline">
            <mat-label> Email </mat-label>
            <input matInput type="email" name="email" formControlName="email">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <mat-form-field appearance="outline">
            <mat-label> Active </mat-label>
            <mat-select matInput formControlName="active" placeholder="Active">
              <mat-option [value]="true"> Active </mat-option>
              <mat-option [value]="false"> Inactive </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <mat-form-field appearance="outline">
            <mat-label> Advisor Salesforce ID </mat-label>
            <input matInput type="text" name="uniqueId" formControlName="uniqueId">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">
        <div [ngClass.xl]="'form-field-xl'" [ngClass.gt-xs]="'form-field-md'" [ngClass.xs]="'form-field-xs'" fxFlex>
          <mat-form-field appearance="outline">
            <mat-label>Calendar link</mat-label>
            <input matInput type="text" formControlName="calendarLinkUrl">
          </mat-form-field>
        </div>
      </div>

    </form>

  </div>

</ifc-app-scroll-container>
