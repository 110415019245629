<div class="table-container">

  <div class="message-box info" *ngIf="!data.logQueryResult?.totalCount" fxLayout="row" fxLayoutGap="12px"
    fxLayoutAlign="start center">
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <table *ngIf="data.logQueryResult?.totalCount" mat-table [dataSource]="data.logQueryResult?.values">
    <ng-container matColumnDef="log">

      <td mat-cell *matCellDef="let row; let i = index" class="table-cell">
        <mat-card class="layout margin-bottom-sm margin-top-sm">

          <mat-card-header>

            <button *ngIf="canReadWriteLog" mat-icon-button (click)="archive(row.id)"
              class="add-button" aria-label="Archive" matTooltip="{{row.active !== false ? 'Archive' : 'Unarchive'}}">
              <mat-icon>archive</mat-icon>
            </button>

            <mat-card-title>
              <h3><span class="pointer" (click)="filter('logger', row.logger)">{{row.logger}}</span></h3>
            </mat-card-title>

            <mat-card-subtitle>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                  <mat-icon>access_time</mat-icon> <span>{{row.timestamp | date:'EEE, MM/dd/yyyy h:mm:ss a'}}</span>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px"
                  matTooltip="Filter by level: {{row.level}}">
                  <mat-icon [ngClass]="getLevelIconAvatarClass(row.level)"> {{getLevelIcon(row.level)}}</mat-icon>
                  <span class="pointer" (click)="filter('level', row.level)">{{row.level}}</span>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px"
                  matTooltip="Filter by id: {{row.id}}">
                  <mat-icon>dns</mat-icon>
                  <span class="pointer" (click)="filter('id', row.id)">{{substring(row.id.toString())}}</span>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px"
                  matTooltip="Filter by endpoint: {{row.endpoint}}">
                  <mat-icon>input</mat-icon>
                  <span class="pointer" (click)="filter('endpoint', row.endpoint)">{{row.endpoint}}</span>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px"
                  matTooltip="Filter by correlation id: {{row.correlationId}}">
                  <mat-icon>link</mat-icon>
                  <span class="pointer"
                    (click)="filter('correlationId', row.correlationId)">{{substring(row.correlationId)}}</span>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px"
                  matTooltip="Filter by hostName: {{row.hostName}}">
                  <mat-icon>desktop_windows</mat-icon>
                  <span class="pointer" (click)="filter('hostName', row.hostName)">{{row.hostName}}</span>
                </div>

                <div *ngIf="row.entity && row.entity !== '(null)'" fxLayout="row" fxLayoutAlign="center center"
                  fxLayoutGap="6px" matTooltip="Filter by entity id: {{row.entityId}}">
                  <mat-icon>domain</mat-icon>
                  <span class="pointer" (click)="filter('entity', row.entity)">{{substring(row.entity)}}</span>
                  <button mat-icon-button (click)="detailsLink('company', row.entity)">
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                </div>

                <div *ngIf="row.loan && row.loan !== '(null)'" fxLayout="row" fxLayoutAlign="center center"
                  fxLayoutGap="6px" matTooltip="Filter by loan id: {{row.loanId}}">
                  <mat-icon>contacts</mat-icon>
                  <span class="pointer" (click)="filter('loan', row.loanId)">{{substring(row.loanId)}}</span>
                  <button mat-icon-button (click)="detailsLink('account', row.loanId)">
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                </div>

                <div *ngIf="row.customer && row.customer !== '(null)'" fxLayout="row" fxLayoutAlign="center center"
                  fxLayoutGap="6px" matTooltip="Filter by customer id: {{row.customerId}}">
                  <mat-icon>people</mat-icon>
                  <span class="pointer" (click)="filter('customer', row.customer)">{{substring(row.customerId)}}</span>
                  <button mat-icon-button (click)="detailsLink('customer', row.customerId)">
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                </div>

              </div>

            </mat-card-subtitle>

          </mat-card-header>

          <mat-card-content>

            <div *ngIf="!hasException(row)">
            <div *ngIf="!hasMultipleLines(row.message) || expanded[i]">
                <div fxLayout="row" fxLayoutAlign="start start" class="code-container">

                  <div *ngIf="!isJson(row.message)">
                {{getJsonMessage(row.message)}}
              </div>

                  <div *ngIf="isJson(row.message)">
                <pre *ngIf="isJson(row.message)">{{getJsonMessage(row.message) | json}}</pre>
              </div>

            </div>
              </div>
            </div>

            <div *ngIf="hasException(row)">
              <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12px" class="code-container">

                <div *ngIf="!isJson(row.message)">
                  {{getJsonMessage(row.message)}}
                </div>

                <div *ngIf="isJson(row.message)">
                  <pre *ngIf="isJson(row.message)">{{getJsonMessage(row.message) | json}}</pre>
                </div>

                <div *ngIf="expanded[i]">
                  <div *ngIf="!isJson(row.exception)">
                    {{getJsonMessage(row.exception)}}
                  </div>

                  <div *ngIf="isJson(row.exception)">
                    <pre *ngIf="isJson(row.exception)">{{getJsonMessage(row.exception) | json}}</pre>
                  </div>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
              <button *ngIf="hasException(row) || hasMultipleLines(row.message)" mat-button
                class="layout margin-top-sm" (click)="toggle(i)">
                {{expanded[i] ? 'Collapse' : 'Expand'}}
              </button>
              <button mat-button class="layout margin-top-sm" [routerLink]="logDetailsLink(row.id)">
                Details
              </button>
            </div>

          </mat-card-content>
        </mat-card>
      </td>

    </ng-container>
    <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
  </table>

</div>