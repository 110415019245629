import { Component, Input } from '@angular/core';
import { VerticalBarChartOptions } from '../charts.model';

@Component({
  selector: 'ifb-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})
export class VerticalBarChartComponent {

  @Input()
  options: VerticalBarChartOptions;

  @Input()
  view: string[];

  @Input()
  data: any;

  public yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);

  constructor() { }

  yAxisTickFormatting(val) {
    return '$' + val.toLocaleString();
  }

}
