import { Input, Directive } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UserData } from '../../user/user.model';

@Directive()
export abstract class NotesSidenavComponent {

  notesOpened: boolean;
  noteInput: UntypedFormControl;
  canEditNotes: boolean;

  @Input()
  notes: string[];

  @Input()
  initiator: UserData;

  @Input()
  saveOnDemand: boolean;

  @Input()
  innerSidenav: boolean;

  @Input()
  innerSidenavAndList: boolean;

  @Input()
  innerSidenavAndTabbed: boolean;

  @Input()
  hiddenButton: boolean;

  constructor() {
    this.noteInput = new UntypedFormControl('');
  }

  abstract addNote(value: string): void;

}
