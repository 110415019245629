<h1 mat-dialog-title>
    Sync {{data.succeeded ? 'successful' : 'unsuccessful'}}
</h1>

<mat-dialog-content>

    {{data.succeeded ? 'Retrieving' : 'Unable to retrieve'}} data from {{data.bankAccount?.bankName}} -
    {{data.bankAccount?.accountType | titlecase}}

    <div *ngIf="!data.succeeded">
        {{data?.error?.error.error}}
        <br>
        {{data?.error?.error.error_description}}
    </div>

</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
    <button mat-button [mat-dialog-close] color="primary">Ok</button>
</div>