import { QueryResult, QueryParams, BankAccount, parseNumber } from "common";
import { AccountData } from "../../account/account.model";
import { UserData } from "../../user/user.model";

export interface AuditTrailData {
    id: string;
    date: Date;
    event: string;
    initiator: Initiator;
    json: string;
}

export interface Initiator {
    id: string;
    uniqueId: string;
    type: string;
    email: string;
    firstName: string;
    lastName: string;
    shortName: string;
    fullName: string;
}

export class AuditQueryParams extends QueryParams {
    entity?: number;
    loan?: number;
    bankAccount?: number;
    transaction?: number;
    dateFrom?: string;
    dateTo?: string;
    event?: any;
    eventName?: string;
    initiator?: string;

    constructor(params?: any) {
      super(params)
      params = params || {};

      this.entity = parseNumber(params.entity) || undefined;
      this.loan = parseNumber(params.loan) || undefined;
      this.bankAccount = parseNumber(params.bankAccount) || undefined;
      this.transaction = parseNumber(params.transaction) || undefined;
      this.dateFrom = params.dateFrom || undefined;
      this.dateTo = params.dateTo || undefined;
      this.event = params.event || undefined;
      this.eventName = params.eventName || undefined;
      this.initiator = params.initiator || undefined;
  }
}

export interface AuditQueryResult extends QueryResult<AuditTrailData> { }

export interface AuditTrailComponentData {
    auditTrail: AuditQueryResult;
    account?: AccountData;
    userCurrent?: UserData;
    bankAccount?: BankAccount;
}

export interface AuditTrailEventHint {
    name?: string;
    value?: string;
}

export class AuditFilters {
    entity?: any;
    loan?: any;
    bankAccount?: any;
    dateFrom?: any;
    dateTo?: any;
    event?: any;
    initiator?: any;

    constructor(filters?) {
        filters = filters || {};
        this.entity = filters.entity || undefined;
        this.loan = filters.loan || undefined;
        this.bankAccount = filters.bankAccount || undefined;
        this.dateFrom = filters.dateFrom || undefined;
        this.dateTo = filters.dateTo || undefined;
        this.event = filters.event || undefined;
        this.initiator = filters.initiator || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'dateFrom') {
            this.dateFrom = undefined;
            this.dateTo = undefined;
        }
    }
}
