import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPermissionGrantedDirective } from './user-permission-granted.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [UserPermissionGrantedDirective]
})
export class UserPermissionModule { }
