import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { UserRoutingModule } from './user-routing.module';
import { UserPermissionModule } from './user-permission/user-permission.module';
import { UserPermissionGroupModule } from './user-permission-group/user-permission-group.module';
import { UserRoleModule } from './user-role/user-role.module';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { CallbackComponent } from './callback/callback.component';

@NgModule({
  imports: [
    CommonModule,
    UserRoleModule,
    UserRoutingModule,
    UserPermissionModule,
    UserPermissionGroupModule
  ],
  declarations: [CallbackComponent, UserMenuComponent]
})
export class UserModule { }
