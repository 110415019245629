export enum LinkAccountType {
  AllAccounts = "all-accounts",
  LinkedAccounts = "linked-accounts",
  LinkingAccounts = "linking-accounts",
}

export const LinkAccountTypeLabel = new Map<string, string>([
  [LinkAccountType.AllAccounts, "Linked or unlinked"],
  [LinkAccountType.LinkedAccounts, "Linked previously or during promotion"],
  [LinkAccountType.LinkingAccounts, "Linked during promotion only"],
]);
