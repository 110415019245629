<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button mat-icon-button (click)="download(queryParams)" class="secondary-text" matTooltip="Download">
          <mat-icon>get_app</mat-icon>
        </button>
        <button *ngIf="readWriteAdvisor" mat-icon-button routerLink="/advisor/new" class="secondary-text"
          matTooltip="Add">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item name="filter" (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button mat-menu-item name="download" (click)="download(queryParams)">
            <mat-icon>get_app</mat-icon>
            <span>Download</span>
          </button>
          <button *ngIf="readWriteAdvisor" name="add" mat-menu-item routerLink="/advisor/new">
            <mat-icon>add_circle</mat-icon>
            <span>Add</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">

      <div class="message-box info" *ngIf="!data.advisorQueryResult?.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>

      <table mat-table matSort [dataSource]="data.advisorQueryResult?.values" *ngIf="data.advisorQueryResult?.totalCount" (matSortChange)="sortData($event)">

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName"> First name </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.firstName}} </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName"> Last name </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.lastName}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.email}} </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Created on </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.createdOn | date}}
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedOn"> Updated on </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.updatedOn | date}}
          </td>
        </ng-container>

        <ng-container matColumnDef="uniqueId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="uniqueId"> Salesforce ID </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <a *ngIf="row.uniqueId" href="{{salesforceLink(row.uniqueId)}}" target="_blank"> {{row.uniqueId}} </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="active"> Active </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.active}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>

      </table>

    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.advisorQueryResult?.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.advisorQueryResult?.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>