import { CurrencyPipe, PercentPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { FeeType } from "../application.model";

@Pipe({
  name: "fee",
})
export class FeePipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe
  ) {}

  transform(value: number, feeType: FeeType): string {
    switch (feeType) {
      case FeeType.Percentage: {
        return this.percentPipe.transform(value / 100, "1.2-2");
      }
      case FeeType.Flat: {
        return this.currencyPipe.transform(value, "USD", "symbol", "1.2-2");
      }
      default: {
        return value?.toString();
      }
    }
  }
}
