import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { EnumHelper, FilterRange } from 'common';
import { FailureReasonFilters, FailureReasonQueryParams, FailureReasonUsage, FailureReasonUsageLabel } from '../failure-reason.model';

@Component({
  selector: 'ifb-failure-reason-list-filter-dialog',
  templateUrl: './failure-reason-list-filter-dialog.component.html',
  styleUrls: ['./failure-reason-list-filter-dialog.component.scss']
})
export class FailureReasonListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: FailureReasonFilters;
  usage = EnumHelper.getNamesAndValues(FailureReasonUsage);

  constructor(public dialogRef: MatDialogRef<FailureReasonListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder) {

    this._unsubscribeAll = new Subject();
    this.filters = new FailureReasonFilters(data.query);


    this.form = this.formBuilder.group({
      code: this.data.query.code || '',
      externalCode: this.data.query.externalCode || '',
      name: this.data.query.name || '',
      usage: this.data.query.usage || null,
      createdBy: this.data.query.createdBy || '',
      createdOn: this.data.query.createdOn || '',
      createdOnStart: new UntypedFormControl(this.data.query.createdOnStart ? new Date(this.data.query.createdOnStart) : '', { validators: null, updateOn: 'blur' }),
      createdOnEnd: new UntypedFormControl(this.data.query.createdOnEnd ? new Date(this.data.query.createdOnEnd) : '', { validators: null, updateOn: 'blur' }),
      updatedBy: this.data.query.updatedBy || '',
      updatedOn: this.data.query.updatedOn || '',
      updatedOnStart: new UntypedFormControl(this.data.query.updatedOnStart ? new Date(this.data.query.updatedOnStart) : '', { validators: null, updateOn: 'blur' }),
      updatedOnEnd: new UntypedFormControl(this.data.query.updatedOnEnd ? new Date(this.data.query.updatedOnEnd) : '', { validators: null, updateOn: 'blur' }),
      active: this.data.query.active || '',
    });

    this.usage.forEach(u => {
        if(u.value == FailureReasonUsage.All)
            u.value = null;
    });
  }

  static show(dialog: MatDialog, query: FailureReasonQueryParams): Observable<any> {
    return dialog.open(FailureReasonListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new FailureReasonFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }



  createdDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      createdOnStart: dataRange.from,
      createdOnEnd: dataRange.to
    });
  }

  updatedDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      updatedOnStart: dataRange.from,
      updatedOnEnd: dataRange.to
    });
  }

  formatUsage(usage: FailureReasonUsage): string {
      return !usage ? 'All' : FailureReasonUsageLabel.get(usage);
  }
}
