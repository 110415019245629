import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentFilters, DialogConfirm, LoanDocumentService, MessageService, DialogHtml } from 'common';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';
import { takeUntil } from 'rxjs/operators';
import { routerTransition } from '../../shared/animations/router.animations';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { DocumentsFilterService } from './document-filter.service';
import { DocumentListComponent, AccountDocumentListComponentData } from './document-list-base.component';

@Component({
  selector: 'ifb-account-documents',
  templateUrl: './account-document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  animations: routerTransition
})

export class AccountDocumentListComponent extends DocumentListComponent<LoanDocumentService> implements OnInit, OnDestroy {

  override data: AccountDocumentListComponentData;

  constructor(
    route: ActivatedRoute,
    router: Router,
    userPermissionService: UserPermissionService,
    documentService: LoanDocumentService,
    accountDocumentsFilterService: DocumentsFilterService,
    messageService: MessageService,
    dialog: MatDialog,
    queryParamsService: QueryParamsService) {
      super(route, router, documentService, accountDocumentsFilterService, messageService, dialog, userPermissionService, queryParamsService);
  }

  override ngOnInit() {
    this.route.data.pipe(takeUntil(this.unsubscribeAll))
      .subscribe((it: AccountDocumentListComponentData) => {
        this.data = it;
        this.dataSource.data = this.data && this.data.documentQueryResult ? this.data.documentQueryResult.values : [];
      });

    this.onInitBase('servicing-account-documents');
  }

  override filter() {
    this.documentsFilterService.filter(new DocumentFilters(this.queryParams), this.paginator, this.sort);
  }

  clearCache() {
    DialogConfirm.show(this.dialog, `This will clear the agreement cache for account ${this.data.account?.loanNumber}. Proceed?`)
      .subscribe(result => {
        if(result) {
          const clearCacheSub = this.documentService.clearCache(this.data.account?.id).subscribe({
            next: (result: number) => {
              clearCacheSub.unsubscribe();
              DialogHtml.show(this.dialog,
                result === 0
                  ? `Document cache was not cleared because there are no cached documents for account ${this.data.account?.loanNumber}.`
                  : `Document cache was cleared for account ${this.data.account?.loanNumber}. ${result} document${result === 1 ? ' was' : 's were'} removed.`
              );
            },
            error: (error: any) => {
              clearCacheSub.unsubscribe();
              this.messageService.error(error);
            }
          });
        }
      });
  }
}
