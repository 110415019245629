import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBarActionsService, AppBarTitleService, DialogConfirm, LoanDocumentService, MessageService, parseNumber } from 'common';
import { SignedLoanDocumentFile } from 'common/lib/documents/signed-loan-document-file.model';
import { AppBarAction } from 'projects/common/src/lib/ui/app/app-bar-actions/app-bar-actions.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { UserPermissionId, readWrite } from '../../user/user-permission/user-permission.data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifb-account-document-details',
  templateUrl: './account-document-details.component.html',
  styleUrls: ['./account-document-details.component.scss']
})
export class AccountDocumentDetailsComponent implements OnInit {
  loanId: number = parseNumber(this.route.snapshot.params.id);
  documentFile: SignedLoanDocumentFile;
  readWrite: boolean = false;
  userPermissionId: UserPermissionId = "servicing-account-documents";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appBarTitleService: AppBarTitleService,
    private appBarActionsService: AppBarActionsService,
    private documentService: LoanDocumentService,
    private userPermissionService: UserPermissionService,
    private messageService: MessageService,
    private dialog: MatDialog) {
      this.appBarActionsService.actions = [
        { id: 'cancel', label: 'Cancel', buttonType: 'button'  },
        { id: 'download', label: 'download', buttonType: 'icon', icon: 'get_app' },
        { id: 'delete', label: 'delete', buttonType: 'icon', icon: 'delete'}
      ];
     }

  ngOnInit(): void {
    this.dataInit();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  delete() {
    if(!this.readWrite) {
      this.messageService.error("You don't have permissions to delete this file");
      return;
    }

    DialogConfirm.show(this.dialog, `Delete file ${this.documentFile.filename}?`)
      .subscribe(result => {
        if(result) {
          this.documentService.delete(this.documentFile.id, null)
            .pipe(untilDestroyed(this))
            .subscribe(()=> {
              this.navigateBack();
            });
        }
      });
  }

  download() {
    this.documentService.download(this.documentFile.id, this.loanId);
  }

  cancel() {
    this.navigateBack();
  }

  private dataInit() {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data:any) => {
      this.appBarTitleService.title = data.documentFile.filename;
      this.documentFile = data.documentFile;
      this.appBarActionsService.actions.find(x=>x.id == 'delete').hidden = !this.documentFile.canDelete;
      this.userPermissionService.granted([readWrite(this.userPermissionId)])
      .subscribe(res => {
        this.readWrite = res
      });
    });

    this.appBarActionsService.invoking.pipe(untilDestroyed(this))
    .subscribe(this.actionDispatch.bind(this));
  }

  private navigateBack() {
    this.router.navigate([`account/${this.loanId}/documents`]);
  }
}
