import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BOAuthGuard } from "../../../guards/oauth.guard";
import {
  AppPageComponent,
  AppBarExpandedTabsComponent,
  AppBarExpandedComponent,
  AppBarComponent,
  AppSidenavComponent,
  AppBarTitleComponent,
  AppBarActionsComponent,
} from "common";
import {
  appPageComponentDataDetail,
  appPageComponentDataMaster
} from "../../../app.data";
import { UserMenuComponent } from "../../../user/user-menu/user-menu.component";
import { BrokerAppBarTitleComponent } from "../../../broker/broker-app-bar-title/broker-app-bar-title.component";
import { BrokerAppBarExpandedTabsResolver } from "../../../broker/broker.resolver";
import { AppPageResolver, AppSidenavResolver } from "../../../app.resolver";
import { BrokerApiKeyListComponent } from "./features/broker-api-key-list/broker-api-key-list.component";
import { BrokerApiKeyDetailsComponent } from "./features/broker-api-key-details/broker-api-key-details.component";
const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: "Broker API Keys",
  sideNavItem: "broker api key",
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: "",
    canActivate: [BOAuthGuard],
    children: [
      {
        path: "broker/:id/api-keys",
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: BrokerAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
        },
        children: [
          {
            path: "",
            outlet: "sidenav-content",
            component: AppSidenavComponent,
          },
          {
            path: "",
            component: AppBarComponent,
            outlet: "app-bar",
            children: [
              {
                path: "",
                outlet: "title",
                component: BrokerAppBarTitleComponent,
              },
              {
                path: "",
                outlet: "right",
                component: UserMenuComponent,
              },
              {
                path: "",
                outlet: "expanded",
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: "",
                    outlet: "tabs",
                    component: AppBarExpandedTabsComponent,
                  },
                ],
              },
            ],
          },
          {
            path: "",
            component: BrokerApiKeyListComponent,
          },
        ],
      },      
      {
        path: "broker/:brokerId/api-keys/:id",
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: "",
            component: AppBarComponent,
            outlet: "app-bar",
            children: [
              {
                path: "",
                outlet: "title",
                component: AppBarTitleComponent,
              },
              {
                path: "",
                outlet: "right",
                component: AppBarActionsComponent,
              },
            ],
          },
          {
            path: "",
            component: BrokerApiKeyDetailsComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BrokerApiKeysRoutingModule { }
