<ifc-app-scroll-container>
  <form [formGroup]="statementsForm">
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Alligator data</mat-label>
        <mat-select matInput
                    formControlName="alligatorMatch"
                    placeholder="Alligator data">
          <mat-option [value]="true">Match</mat-option>
          <mat-option [value]="false">Mismatch</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px"
         *ngIf="viewStatementsPermission">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Statement generation</mat-label>
        <mat-select matInput
                    formControlName="generateStatements"
                    placeholder="Statement generation"
                    [disabled]="!editStatementsPermission">
          <mat-option [value]="null">Auto</mat-option>
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px"
         *ngIf="statementsForm.get('generateStatements').value === true && viewStatementsPermission">
      <ifc-datepicker ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md"
                      [fc]="statementsForm.get('generateStatementsExpirationDate')"
                      [label]="'Expiration date'"
                      [disabled]="!editStatementsPermission"
                      (clearInput)="clear('generateStatementsExpirationDate', 'generateStatementsExpirationTime')"
                      [min]="minStatementGenerationExpirationDate">
      </ifc-datepicker>
    </div>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px"
         *ngIf="statementsForm.get('generateStatements').value === true && viewStatementsPermission">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Expiration time</mat-label>
        <input matInput
               formControlName="generateStatementsExpirationTime"
               type="text"
               placeholder="Expiration time"
               [value]="statementsForm.get('generateStatementsExpirationDate').value | date: 'shortTime'" 
               readonly>
        <button type="button" 
                mat-button
                matSuffix
                mat-icon-button
                [disabled]="!editStatementsPermission"
                (click)="pickTime('generateStatementsExpirationDate', 'generateStatementsExpirationTime')">
          <mat-icon>access_time</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
</ifc-app-scroll-container>
<div class="actions">
  <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
</div>