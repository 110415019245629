import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import {
    CustomerQueryResult,
    CustomerData,
    customerAppBarExpandedTabs,
    customerSFAppBarExpandedTabs, LoanDetails
} from "./customer.model";
import { CustomerService } from "./customer.service";
import { AppBarExpandedTab } from "common";
import { UserPermissionService } from "../user/user-permission/user-permission.service";
import { map } from "rxjs/operators";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";


@Injectable({
    providedIn: 'root'
})
export class CustomerResolver implements Resolve<CustomerData> {
    constructor(private service: CustomerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CustomerData> | Promise<CustomerData> | CustomerData {
        return route.params.id === 'new'
            ? undefined
            : this.service.get(route.params.id);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomerUniqueIdResolver implements Resolve<CustomerData> {
    constructor(private service: CustomerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CustomerData> | Promise<CustomerData> | CustomerData {
        return this.service.getByUniqueId(route.params.uniqueId);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomerQueryResolver implements Resolve<CustomerQueryResult> {
    constructor(private service: CustomerService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CustomerQueryResult> | Promise<CustomerQueryResult> | CustomerQueryResult {
        return this.service.query(this.queryParamsService.init(route.queryParams));
    }
}


@Injectable({
    providedIn: 'root'
})
export class CustomerAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            customerAppBarExpandedTabs(route.params.id).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomerSFAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            customerSFAppBarExpandedTabs(route.params.uniqueId).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomerLoansResolver implements Resolve<LoanDetails[]> {
    constructor(private service: CustomerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<LoanDetails[]> | Promise<LoanDetails[]> | LoanDetails[] {
        return this.service.getLoansById(route.params.id);
    }
}
