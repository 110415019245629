import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class CashFlowAnalysisService {

  constructor(private http: HttpClient, private settings: AppSettings) { }

  getFinancialSummary(entityId: number): Observable<any> {
    return this.http.get<any>(`${this.settings.doc.url}/api/data-sciense/financial-summary/${entityId}`);
  }

  getApplicationSummary(sfApplicationId: string): Observable<any> {
    return this.http.get<any>(`${this.settings.doc.url}/api/data-sciense/application-summary/${sfApplicationId}`);
  }

}
