import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AppBarTitleService,
  AppBarActionsService,
  ConfigService,
  OAuthService,
  DocumentCategory,
  CacheService,
  AppPageService,
} from "common";
import { takeUntil } from "rxjs/operators";
import { UserPermissionService } from "../../user/user-permission/user-permission.service";
import { DocumentsUploadBaseComponent } from "./documents-upload-base.component";
import * as _ from "lodash";

@Component({
  selector: "ifb-application-documents-upload",
  templateUrl: "./documents-upload-base.component.html",
  styleUrls: ["./documents-upload-base.component.scss"],
})
export class ApplicationDocumentsUploadComponent
  extends DocumentsUploadBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private applicationId: number;
  private applicationUniqueId: string;
  private allowedCategories: DocumentCategory[];
  private baseUrl: string;
  constructor(
    protected route: ActivatedRoute,
    protected appBarTitleService: AppBarTitleService,
    protected appBarActionsService: AppBarActionsService,
    protected appPageService: AppPageService,
    protected userPermissionService: UserPermissionService,
    protected configService: ConfigService,
    protected authService: OAuthService,
    protected cacheService: CacheService
  ) {
    super(
      route,
      appBarTitleService,
      appBarActionsService,
      appPageService,
      userPermissionService,
      configService,
      authService
    );
    this.allowedCategories = [
      DocumentCategory.Other,
      DocumentCategory.ApplicationSupporting,
      DocumentCategory.InsurancePolicy,
      DocumentCategory.BankStatement,
      DocumentCategory.TaxReturn,
    ];
    this.selectedCategory = DocumentCategory.BankStatement;
    this.baseUrl = `${this.configService.environment.applications.url}/api/application`;
  }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((params) => {
        this.applicationId = params.id;
        this.applicationUniqueId = params.uniqueId;
        this.setupUppy();
      });

    this.ngOnInitBase("servicing-application-documents");
    this.categories = _.flatMap(
      this.categories.filter((i) => _.includes(this.allowedCategories, i.value))
    );
  }

  onUploadResult(event: any): void {
    this.submitting = false;

    if (event.failed && event.failed.length > 0) return;

    //we need to explicitly invalidate cache here because file uploading does not go through CacheInterceptor, thus no implicit cache invalidation
    this.cacheService.invalidateUrl(
      `${this.baseUrl}/${this.applicationId}/documents`
    );
    this.cacheService.invalidateUrl(
      `${this.baseUrl}/sf/${this.applicationUniqueId}/documents`
    );
  }

  protected override setupUppy(): void {
    this.initUppy(this.prepareUppyEndpoint());
  }

  protected override updateUppyEndpoint(): void {
    this.updateUppyEndpointBase(this.prepareUppyEndpoint());
  }

  private prepareUppyEndpoint(): string {
    const endpoint = `${this.configService.environment.applications?.url}/api/application/`;

    if (this.applicationUniqueId) {
      return (endpoint + `sf/${this.applicationUniqueId}/documents/${this.selectedCategory}`);
    }
    return (endpoint + `${this.applicationId}/documents/${this.selectedCategory}`);
  }
}
