import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBarTitleService, AppBarActionsService, AppBarAction, EnumHelper, BankAccount, BankAccountType, BankAccountRole, MessageService, FormHelper, BankAccountHelper, EntityExData, AppPageService, EnumValue } from 'common';
import { BankAccountRoleLabel } from '../bank.model';
import { MatDialog } from '@angular/material/dialog';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { BankAccountService } from '../bank.service';
import { BankAccountUpdateDialogComponent } from '../bank-account-update-dialog/bank-account-update-dialog.component';
import { BankAccountDeleteDialogComponent } from '../bank-account-delete-dialog/bank-account-delete-dialog.component';
import { BankTransactionQueryResult } from '../../bank/bank.model';
import { BankAccountCannotDeleteDialogComponent } from '../bank-account-cannot-delete-dialog/bank-account-cannot-delete-dialog.component';
import { ApplicationData } from '../../application/application.model';
import { BankAccountSyncDialogComponent } from '../bank-account-sync-dialog/bank-account-sync-dialog.component';
import { AccountData } from '../../account/account.model';
import { BankingService } from '../banking.service';

@Component({
  selector: 'ifb-bank-account-details',
  templateUrl: './bank-account-details.component.html',
  styleUrls: ['./bank-account-details.component.scss']
})
export class BankAccountDetailsComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  bankAccountTypeOptions = EnumHelper.getNamesAndValues(BankAccountType);
  loanDesignationOptions: EnumValue[] = null;
  globalDesignationOptions: EnumValue[] = null;
  canEditBankAccount = false;
  canPullExtraInfo = false;
  loansConnected: AccountData[] = [];
  accountType: string;
  form: UntypedFormGroup;
  linkStatus: number;
  canEditVerificationDate: boolean;
  setAsUncheckedAfterDate: Date;

  constructor(
    private bankAccountService: BankAccountService,
    private bankingService: BankingService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService
  ) {
    this.appBarActionsService.actions = [];

    this.form = this.formBuilder.group({
      businessName: { value: undefined, disabled: true },
      bankName: [undefined, Validators.compose([Validators.required, Validators.maxLength(128)])],
      bankAccountType: [undefined, Validators.required],
      accountNumber: [undefined, Validators.compose([Validators.required, Validators.maxLength(128)])],
      routingNumber: [undefined, Validators.maxLength(9)],
      verificationStatus: { value: undefined, disabled: true },
      lastCheckDate: new UntypedFormControl(null, { validators: null, updateOn: 'blur' }),
      screenShareRequired: undefined,
      bankAccountDesignationEntity: undefined
    });
  }

  get BankAccountRole() { return BankAccountRole; }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.appBarActionsService.invoking.subscribe(this.actionDispatch.bind(this)),
      this.userPermissionService.granted([readWrite('servicing-bank-accounts')])
        .subscribe(res => {
          this.canEditBankAccount = res;
          if (res)
            this.appBarActionsService.enable('edit', true);
        }),
      this.userPermissionService.granted([readWrite('servicing-bank-accounts-deletion')])
        .subscribe(res => {
          if (res)
            this.appBarActionsService.enable('delete', true);
        }),
      this.userPermissionService.granted([readWrite('servicing-pull-synced-bank-account-extra-info')])
        .subscribe(res => {
            this.canPullExtraInfo = res;
        })
    ];
    this.onChanges();
  }

  editingFailedIntegrationAttempt = false;

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      this.enableSaveAction();
    });

    this.form.controls.lastCheckDate.valueChanges.subscribe(input => {
      if (input) {
        const lastCheckDate = new Date(input);
        if (lastCheckDate >= this.setAsUncheckedAfterDate
          && this.form.controls.accountNumber.valid && this.form.controls.accountNumber.value.length > 3
          && this.form.controls.routingNumber.value && this.form.controls.routingNumber.value.length === 9) {
          this.form.controls.verificationStatus.setValue(true);
          if (!this.data.bankAccount || (this.data.bankAccount && !this.data.bankAccount.isVerified))
            this.form.controls.verificationStatus.markAsDirty();
        } else if (lastCheckDate < this.setAsUncheckedAfterDate) {
          this.form.controls.verificationStatus.setValue(false);
          if (!this.data.bankAccount || (this.data.bankAccount && this.data.bankAccount.isVerified))
            this.form.controls.verificationStatus.markAsDirty();
        }
      } else {
        this.form.controls.verificationStatus.setValue(false);
        if (!this.data.bankAccount || (this.data.bankAccount && this.data.bankAccount.isVerified))
          this.form.controls.verificationStatus.markAsDirty();
      }
    });

    this.form.controls.accountNumber.valueChanges.subscribe(input => {
      if (!input || (input && input.length < 3)) {
        this.form.controls.verificationStatus.setValue(false);
        if (this.data.bankAccount && this.data.bankAccount.isVerified)
          this.form.controls.verificationStatus.markAsDirty();
      } else if (input && input.length > 3
        && (this.form.controls.routingNumber.value && this.form.controls.routingNumber.value.length === 9)
        && (new Date(this.form.controls.lastCheckDate.value) > this.setAsUncheckedAfterDate)) {
        this.form.controls.verificationStatus.setValue(true);
        if (!this.data.bankAccount && (this.data.bankAccount && !this.data.bankAccount.isVerified))
          this.form.controls.verificationStatus.markAsDirty();
      }
    });

    this.form.controls.routingNumber.valueChanges.subscribe(input => {
      if (!input || (input && input.length !== 9)) {
        this.form.controls.verificationStatus.setValue(false);
        if (this.data.bankAccount && this.data.bankAccount.isVerified)
          this.form.controls.verificationStatus.markAsDirty();
      } else if ((input && input.length === 9)
        && (this.form.controls.accountNumber.valid && this.form.controls.accountNumber.value.length > 3)
        && (new Date(this.form.controls.lastCheckDate.value) > this.setAsUncheckedAfterDate)) {
        this.form.controls.verificationStatus.setValue(true);
        if (!this.data.bankAccount && (this.data.bankAccount && !this.data.bankAccount.isVerified))
          this.form.controls.verificationStatus.markAsDirty();
      }
    });
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  data: BankAccountDetailsComponentData;

  private dataInit(data: BankAccountDetailsComponentData) {
    this.data = data;
    const obj = data.account || data.application;

    this.form.reset({
      businessName: obj ? obj.entityName : data.entity ? data.entity.name : '',
      bankName: data.bankAccount ? data.bankAccount.bankName : '',
      accountNumber: data.bankAccount ? data.bankAccount.accountNumber : '',
      routingNumber: data.bankAccount ? data.bankAccount.routingNumber : '',
      bankAccountType: data.bankAccount ? data.bankAccount.accountType : '',
      verificationStatus: data.bankAccount ? data.bankAccount.isVerified : '',
      lastCheckDate: data.bankAccount ? data.bankAccount.lastCheckDate : '',
      screenShareRequired: data.bankAccount ? (data.bankAccount.screenShareRequired ? true : false) : false,
      bankAccountDesignationEntity: data.bankAccount ? data.bankAccount.entityRole : null
    });

    let bankAccountName = EnumHelper.getNameFromValue(BankAccountType, this.data.bankAccount?.accountType);
    if(!bankAccountName)
      bankAccountName = this.data.bankAccount?.accountType;

    this.accountType = bankAccountName ? bankAccountName : null;

    this.appBarTitleService.title = this.data.bankAccount
      ? (this.data.bankAccount.bankName ? this.data.bankAccount.bankName : '')
      + ' - ' + (this.accountType ? this.accountType : '') + ' '
      + BankAccountHelper.sanitizeBankAccountNumber(this.data.bankAccount.accountNumber)
      : 'Add new bank account';

    this.linkStatus = this.data && this.data.bankAccount ? this.data.bankAccount.linkingStatus : null;
    this.setAsUncheckedAfterDate = (this.data && this.data.bankAccount && this.data.bankAccount.setAsUncheckedAfterDate)
      ? new Date(this.data.bankAccount.setAsUncheckedAfterDate) : null;

    this.setFormState();
    this.setBankAccountTypeOptions();
    this.setAppBarActions();
  }

  private setFormState() {
    if (this.data.viewMode && this.data.bankAccount) {
      this.form.disable();
    } else if (this.data.bankAccount && !this.data.viewMode) {
      if (!this.data.bankAccount.isBasicDataEditable) {
        this.form.controls.bankName.disable();
        this.form.controls.bankAccountType.disable();
        this.form.controls.routingNumber.disable();
      }

      this.userPermissionService.granted([readWrite('servicing-entities-sensitive-data')])
        .subscribe(res => {
          if (!res || !this.data.bankAccount.isBasicDataEditable)
            this.form.controls.accountNumber.disable();
        });
    }

    this.userPermissionService.granted([readWrite('servicing-bank-accounts-verification')])
      .subscribe(res => {
        if (this.data.viewMode && this.data.bankAccount)
          this.canEditVerificationDate = false;
        else this.canEditVerificationDate = res;
      });
  }

  setBankAccountTypeOptions() {
    const currentGlobalDesignation: BankAccountRole = (this.data.bankAccount && this.data.account &&
        this.data.account.id)
        ? this.data.bankAccount.entityRole : null;
    this.globalDesignationOptions = this.filterDesignationOptions(currentGlobalDesignation);
  }

  cancel() {
    this.appPageService.back();
  }

  edit() {
    if (this.data.account) {
      if (this.route.snapshot.queryParams.uniqueId)
        // eslint-disable-next-line max-len
        this.router.navigate([`/account/${this.data.account.id}/bank-account/${this.data.bankAccount.id}/edit`], { replaceUrl: true, queryParams: { uniqueId: this.route.snapshot.queryParams.uniqueId } });
      else
        this.router.navigate([`/account/${this.data.account.id}/bank-account/${this.data.bankAccount.id}/edit`], { replaceUrl: true });
    } else if (this.data.application)
      // eslint-disable-next-line max-len
      this.router.navigate([`/application/${this.data.application.id}/bank-account/${this.data.bankAccount.id}/edit`], { replaceUrl: true });
    else if (this.data.entity)
      this.router.navigate([`/company/${this.data.entity.id}/banks/${this.data.bankAccount.id}/edit`], { replaceUrl: true });
    else return;
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);

    if (this.saveSub)
      return;

    if (!this.data || (!this.data.account && !this.data.entity && !this.data.application) || !this.form.valid) {
      return;
    }

    BankAccountUpdateDialogComponent.show(this.dialog, this.form, this.data.bankAccount ? "Update" : "Create")
      .subscribe(result => {
        const command: BankAccount = {
          id: this.data.bankAccount ? this.data.bankAccount.id : null,
          bankName: this.form.controls.bankName.value,
          routingNumber: this.form.controls.routingNumber.value,
          accountNumber: this.form.controls.accountNumber.value,
          accountType: this.form.controls.bankAccountType.value,
          lastCheckDate: this.form.controls.lastCheckDate.value,
          screenShareRequired: this.form.controls.screenShareRequired.value,
          entityRole: this.form.controls.bankAccountDesignationEntity.value ?? BankAccountRole.None
        };
        const _this = this;
        const entityId = _this.data?.entity?.id || _this.data?.account?.entityId || _this.data?.application?.entityId;
        if (result && this.data.bankAccount) {
          this.saveSub = _this.bankAccountService
            .updateBankAccount(entityId, command)
            .subscribe(_this.saveSuccessHandler.bind(_this), _this.saveErrorHandler.bind(_this));
        } else if (result && !_this.data.bankAccount) {
          this.saveSub = _this.bankAccountService
            .createBankAccount(entityId, command)
            .subscribe(_this.saveSuccessHandler.bind(_this), _this.saveErrorHandler.bind(_this));
        }
      });
  }

  delete() {
    if (!this.data.bankAccount || !this.data.bankAccount.id || !this.canEditBankAccount)
      return;

    let haveAnyRole = false;

    if (this.data.entityLoans && Object.keys(this.data.entityLoans).length !== 0 && this.data.bankAccount.entityRole !== BankAccountRole.None)
        haveAnyRole = true;

    if (haveAnyRole) {
      BankAccountCannotDeleteDialogComponent.show(this.dialog, this.data.bankAccount)
        .subscribe(() => this.detailPage());
    } else {
      BankAccountDeleteDialogComponent.show(this.dialog, this.data.bankAccount)
        .subscribe(res => {
          if (res) {
            const companyId = this.data?.account?.entityId || this.data?.entity?.id || this.data?.application?.entityId;
            this.bankAccountService.deleteBankAccount(companyId, this.data.bankAccount.id)
              .subscribe(() => this.detailPage());
          }
        });
    }
  }

  syncExtraInfo() {
    if (!this.canPullExtraInfo)
      return;

    const entityId = this.data.account ? this.data.account.entityId : (this.data.entity ? this.data.entity.id : this.data.application.entityId);
    const accountId = this.data.bankAccount && this.data.bankAccount.syncedBankAccount
      ? this.data.bankAccount.syncedBankAccount.id : null;
    this.bankingService.synchronizeBankAccountExtraInfo(entityId, accountId)
      .subscribe(
        res => {
          this.data.bankAccount = res;

          this.form.controls.accountNumber.reset(res.accountNumber);
          this.form.controls.routingNumber.reset(res.routingNumber);
          BankAccountSyncDialogComponent.show(this.dialog, this.data.bankAccount, true)
        },
        err => {
          BankAccountSyncDialogComponent.show(this.dialog, this.data.bankAccount, false, err)
        })
  }

  private detailPage(): void {
    if (this.data.account)
      this.router.navigate([`/account/${this.data.account.id}/bank-account`], { replaceUrl: true });
    else if (this.data.application)
      this.router.navigate([`/application/${this.data.application.id}/bank-accounts`], { replaceUrl: true });
    else if (this.data.entity)
      this.router.navigate([`/company/${this.data.entity.id}/banks`], { replaceUrl: true });
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  saveSub: Subscription;

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    if (this.data.account) {
      this.router.navigate([`/account/${this.data.account.id}/bank-account`], { replaceUrl: true });
    } else if (this.data.application)
      this.router.navigate([`/application/${this.data.application.id}/bank-accounts`], { replaceUrl: true });
    else if (this.data.entity)
      this.router.navigate([`/company/${this.data.entity.id}/banks`], { replaceUrl: true });
    else this.appPageService.back();
  }

  private filterDesignationOptions(currentDesignation: BankAccountRole): EnumValue[]
  {
    let designationOptions = EnumHelper.getMappedNamesAndValues(BankAccountRole, BankAccountRoleLabel).filter(_ => _.name !== undefined);
    const paymentAndDisbursement = designationOptions.filter(_ => _.value === BankAccountRole.PaymentAndDisbursement);

    if (currentDesignation === BankAccountRole.PaymentAndDisbursement) {
      designationOptions = paymentAndDisbursement;
    } else if (currentDesignation === BankAccountRole.Payment || currentDesignation === BankAccountRole.Disbursement) {
      designationOptions = paymentAndDisbursement;
      designationOptions.push({ name: EnumHelper.getNameFromValue(BankAccountRole, currentDesignation), value: currentDesignation });
    }

    return designationOptions;
  }

  setAppBarActions() {
    if (!this.data.bankAccount) {
      this.appBarActionsService.actions.push(
        { id: 'cancel', label: 'Cancel', buttonType: 'button' },
        { id: 'save', label: 'Save', disabled: false, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' });
    } else if (this.data.bankAccount && this.data.viewMode) {
      this.appBarActionsService.actions.push(
        { id: 'edit', label: 'Edit', disabled: true, buttonType: 'button' },
        { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' });
    } else if (this.data.bankAccount && !this.data.viewMode) {
      this.appBarActionsService.actions.push(
        { id: 'cancel', label: 'Cancel', buttonType: 'button' },
        { id: 'delete', label: 'Delete', disabled: true, buttonType: 'button' },
        { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' });
    }
  }

  enableSaveAction(): void {
    if (!this.data.viewMode && this.form.dirty && this.canEditBankAccount) {
      this.appBarActionsService.enable('save', true);
    }
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  clearDate(fc: string) {
    if (!fc) return;

    this.form.get(fc).markAsDirty();
    this.enableSaveAction();
  }

  get providerCustomerLink(): string {
    return `/banking/${this.data.bankAccount?.syncedBankAccount?.providerName?.toLowerCase()}/customers/${this.data.bankAccount?.syncedBankAccount?.customerId}/accounts`;
  }

  get providerAccountLink(): string {
    return `${this.providerCustomerLink}/${this.data.bankAccount?.syncedBankAccount?.id}/transactions`;
  }
}

export interface BankAccountDetailsComponentData {
  bankAccount: BankAccount;
  account?: AccountData;
  application?: ApplicationData;
  viewMode?: boolean;
  entity?: EntityExData;
  entityLoans?: AccountData[];
  bankTransactionQueryResult?: BankTransactionQueryResult;
}
