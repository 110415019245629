<div class="account-app-bar-expanded">
  <ifb-content-slider>
    <div style="display: table">
      <div class="flex row center-center">
        <div fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="24px">
          <ifb-account-status-icon-chip [status]="accountStatus"></ifb-account-status-icon-chip>
          <ifb-account-modification-icon-chip *ngIf="showModificationChipState()"
            [flag]="data.account.modificationFlag"></ifb-account-modification-icon-chip>
        </div>
        <ng-container *ngIf="!isNPLLoan() || accessNPLEconomics">
          <div
            *ngIf="data.account.productCode === ProductCode['Line of Credit'] || data.account.productCode === ProductCode['Case Financing']">
            <ifc-chart-circular-percentage [faktor]="fundsAvailableFaktor" [label]="fundsAvailable" caption1="Available"
              caption2="funds" class="layout margin-left"></ifc-chart-circular-percentage>
          </div>
          <div *ngIf="data.account.productCode === ProductCode['Term Loan']">
            <ifb-term-loan-chart [infoData]="accountInfo"></ifb-term-loan-chart>
          </div>
        </ng-container>

        <ng-container *ngIf="!isNPLLoan() || accessNPLEconomics">
          <div class="layout margin-left">
            <div>
              <div class="cell-value">{{getOutstandingBalance() | currency:'$'}}</div>
              <div class="mat-caption">Outstanding balance</div>
            </div>
            <div class="layout margin-top">
              <div class="cell-value">{{data.accountInfo.upcomingPayment | currency:'$'}}</div>
              <div class="mat-caption">Upcoming payment</div>
            </div>
          </div>

          <div class="layout margin-left">
            <div>
              <div class="cell-value">{{data.account.offer.amount | currency:'$'}}</div>
              <div
                *ngIf="data.account.productCode === ProductCode['Line of Credit'] || data.account.productCode === ProductCode['Case Financing']"
                class="mat-caption">Credit limit</div>
              <div *ngIf="data.account.productCode === ProductCode['Term Loan']" class="mat-caption">Loan amount</div>
            </div>
            <div class="layout margin-top">
              <div class="cell-value">{{data.accountInfo.paymentDueDate | date}}</div>
              <div class="mat-caption">Payment due date</div>
            </div>
          </div>

          <mat-divider [vertical]="true" class="layout margin-left"></mat-divider>

        </ng-container>

        <div class="layout margin-left">
          <div>
            <div class="cell-value">{{data.account.repaymentTerm}} months</div>
            <div class="mat-caption">Term length</div>
          </div>
          <div class="layout margin-top">
            <div class="cell-value">{{(data.account.openedOn | date) || '-'}}</div>
            <div class="mat-caption">Agreement signed date</div>
          </div>
        </div>

        <div class="layout margin-left">
          <div
            *ngIf="data.account.productCode === ProductCode['Line of Credit'] || data.account.productCode === ProductCode['Case Financing']">
            <div class="cell-value">{{data.account.interestRate | number:'1.2-2'}}%</div>
            <div class="mat-caption">Annual interest rate</div>
          </div>
          <div *ngIf="data.account.productCode === ProductCode['Term Loan']">
            <div class="cell-value">{{data.account.interestRate / 100 + 1 | number:'1.4-4'}}</div>
            <div class="mat-caption">Factor rate</div>
          </div>
          <div class="layout margin-top">
            <div class="cell-value">{{(data.account.renewalDate | date) || '-'}}</div>
            <div class="mat-caption">Renewal date</div>
          </div>
        </div>

        <div class="layout margin-left">
          <div>
            <ng-template [ngIf]="data.account.productCode !== ProductCode['Term Loan']" [ngIfElse]="originationFee">
              <div class="cell-value" *ngIf="data.account.offer?.drawDownFeeType === FeeType.Percentage">
                <span *ngIf="data.account.offer?.drawDownFee">{{data.account.offer?.drawDownFee | number: '1.2-2'}}%</span>
                <span *ngIf="!data.account.offer?.drawDownFee">-</span>
              </div>
              <div class="cell-value" *ngIf="data.account.offer?.drawDownFeeType === FeeType.Flat">
                {{data.account.offer?.drawDownFee | currency}}</div>
              <div class="mat-caption">Draw fee</div>
            </ng-template>
            <ng-template #originationFee>
              <div class="cell-value" *ngIf="data.account.offer?.originationFeeType === FeeType.Percentage">
                <span *ngIf="data.account.offer?.originationFee">{{data.account.offer?.originationFee | number: '1.2-2'}}%</span>
                <span *ngIf="!data.account.offer?.originationFee">-</span>
              </div>
              <div class="cell-value" *ngIf="data.account.offer?.originationFeeType === FeeType.Flat">
                {{data.account.offer?.originationFee | currency}}</div>
              <div class="mat-caption">Origination fee</div>
            </ng-template>
          </div>
          <div class="layout margin-top">
            <div class="cell-value">{{data.account.updatedOn | date}}</div>
            <div class="mat-caption">Updated on</div>
          </div>
        </div>

        <div class="layout margin-left">
          <div>
            <div class="cell-value">{{paymentFrequency}}</div>
            <div class="mat-caption">Payment frequency</div>
          </div>
          <div class="layout margin-top">
            <div class="cell-value"> {{ (data.account.noInterestAccrual === true ? 'True': 'False')}} </div>
            <div class="mat-caption">No accrual interest</div>
          </div>
        </div>

        <div class="layout margin-left">
          <div>
            <div class="cell-value">{{data.account.createdOn | date}}</div>
            <div class="mat-caption">Created on</div>
          </div>
          <div class="layout margin-top">
            <div class="cell-value"> {{ (data.account.noAutoPayments === true ? 'True': 'False')}} </div>
            <div class="mat-caption">No auto-payment</div>
          </div>
        </div>
      </div>
    </div>
  </ifb-content-slider>
</div>

<router-outlet name="tabs"></router-outlet>
