<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md padding-bottom-0">
    <div class="card-title">Other info</div>
    <button *ngIf="edit" mat-icon-button routerLink="other-info" aria-label="Edit">
      <mat-icon class="secondary-text">edit</mat-icon>
    </button>
  </div>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngIf="data.account?.productCode === productCode['Case Financing']" class="full-text-container">
        <span class="item-title secondary-text mat-list-item-full-text" mat-line>{{data.account?.description}}</span>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item *ngIf="data.account?.productCode === productCode['Case Financing']">
        <span class="secondary-text" mat-line>Case number</span>
        <p class="item-content" title="{{data.account?.externalId}}">{{data.account?.externalId}}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item *ngIf="data.account?.productCode === productCode['Case Financing']">
        <span class="secondary-text" mat-line>Policy number</span>
        <p class="item-content" title="{{data.account?.policyNumber}}">{{data.account?.policyNumber}}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item *ngIf="data.account?.productCode === productCode['Case Financing']">
        <span class="secondary-text" mat-line>Policy limit</span>
        <p class="item-content">{{data.account?.policyLimit | currency:'$'}}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item>
        <span class="secondary-text" mat-line>Loan number</span>
        <p class="item-content">{{data.account?.loanNumber}}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item>
        <span class="secondary-text" mat-line>Salesforce Loan ID</span>
        <a class="item-content" *ngIf="data.account.uniqueId" href="{{salesforceLink(data.account.uniqueId)}}"
          rel="noopener noreferrer" target="_blank">
          {{data.account.uniqueId}}
        </a>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item>
        <span class="secondary-text" mat-line>Application number</span>
        <p class="item-content">{{data.account?.applicationNumber}}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item>
        <span class="secondary-text" mat-line>Salesforce Application ID</span>
        <a class="item-content" *ngIf="data.account.applicationUniqueId" rel="noopener noreferrer"
          href="{{salesforceLink(data.account.applicationUniqueId)}}" target="_blank">
          {{data.account.applicationUniqueId}}
        </a>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>