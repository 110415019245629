import { Injectable } from '@angular/core';
import { ClientQueryParams, ClientQueryResult, ClientData } from './client.data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient, private settings: AppSettings) { }

  create(client: ClientData): Observable<ClientData> {
    return this.http.post<ClientData>(`${this.settings.oauth.url}/api/client`, client);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.settings.oauth.url}/api/client/${id}`);
  }

  get(id: number): Observable<ClientData> {
    return this.http.get<ClientData>(`${this.settings.oauth.url}/api/client/${id}`);
  }

  getAll(params: ClientQueryParams): Observable<ClientData[]> {
    return this.http.get<ClientQueryResult>(`${this.settings.oauth.url}/api/client/query`, { params: <any>params }).pipe(
      map(result => result.values)
    );
  }

  query(params: ClientQueryParams): Observable<ClientQueryResult> {
    if(!params.sort) {
      params.sort = '+clientName';
    }

    return this.http.get<ClientQueryResult>(`${this.settings.oauth.url}/api/client/query`, { params: <any>params });
  }

  update(client: ClientData): Observable<ClientData> {
    return this.http.put<ClientData>(`${this.settings.oauth.url}/api/client/${client.id}`, client);
  }
}
