import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { AppBarTitleService, AppBarActionsService, MessageService, AppBarAction, FormHelper, states, EntityBaseData, EntityExData, AppPageService } from 'common';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { EntityService } from '../../entity/entity.service';
import { EntityInfoUpdateDialogComponent } from '../entity-info-update-dialog/entity-info-update-dialog.component';

@Component({
  selector: 'ifb-entity-profile-company-info',
  templateUrl: './entity-profile-company-info.component.html',
  styleUrls: ['./entity-profile-company-info.component.scss']
})
export class EntityProfileCompanyInfoComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  public canEditSensitiveData = false;

  private saveSub: Subscription;
  form: UntypedFormGroup;
  data: EntityExData;

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private messageService: MessageService,
    private userPermissionService: UserPermissionService,
    private entityService: EntityService,
    private dialog: MatDialog) {

    this._unsubscribeAll = new Subject();

    this.formInit();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: !this.form.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

  }

  get states() { return states; }

  ngOnInit() {
    this.userPermissionService.granted([readWrite('servicing-entities-sensitive-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canEditSensitiveData = res;
        if (this.canEditSensitiveData) {
          // eslint-disable-next-line max-len
          this.form.controls.taxId.setValidators(Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(10)]));
          this.form.controls.taxId.enable();
        } else {
          this.form.controls.taxId.clearValidators();
          this.form.controls.taxId.disable();
        }
      });

    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  dataInit(data: any) {
    this.data = data.entity;
    this.appBarTitleService.title = 'Company info';

    this.form.reset({
      entityName: this.data.name || '',
      entityDBA: this.data.dba || '',
      line1: this.data.address.line1 || '',
      line2: this.data.address.line2 || '',
      city: this.data.address.city || '',
      state: this.data.address.state || '',
      zip: this.data.address.zip || '',
      entityPhone: this.data.phone || '',
      taxId: this.data.ein || '',
      website: this.data.webSite,
      email: (this.data.emails.length > 0) ? this.data.emails[0].email : '',
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  private formInit() {
    this.form = this.formBuilder.group({
      entityName: [undefined, Validators.compose([Validators.required, Validators.maxLength(255)])],
      entityDBA: [undefined, Validators.maxLength(120)],
      line1: [undefined, Validators.compose([Validators.required, Validators.maxLength(60)])],
      line2: undefined,
      city: [undefined, Validators.compose([Validators.required, Validators.maxLength(60)])],
      state: [undefined, Validators.required],
      zip: [undefined, Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-?\d{4})?$/)])],
      entityPhone: [undefined, Validators.required],
      taxId: [{ value: undefined, disabled: !this.canEditSensitiveData }],
      website: undefined,
      email: undefined,
    });
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    EntityInfoUpdateDialogComponent.show(this.dialog, this.form)
      .subscribe(result => {
        if (result) {
          const updateCommand: EntityBaseData = {
            address: {
              line1: this.form.value.line1,
              line2: this.form.value.line2,
              city: this.form.value.city,
              state: this.form.value.state,
              zip: this.form.value.zip
            },
            phone: this.form.value.entityPhone,
            dba: this.form.value.entityDBA,
            name: this.form.value.entityName,
            ein: this.form.value.taxId,
            webSite: this.form.value.website,
            emails: [{email: this.form.value.email}]
          };
          this.saveSub = this.entityService.update(this.data.id, updateCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }
}

