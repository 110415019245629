import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { readOnly } from '../../user/user-permission/user-permission.data';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';

@Component({
  selector: 'ifb-bank-account-tabs',
  templateUrl: './bank-account-tabs.component.html',
  styleUrls: ['./bank-account-tabs.component.scss']
})
export class BankAccountTabsComponent implements OnInit, AfterViewInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  isViewInitialized = false;
  navLinks = [];
  data: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userPermissionService: UserPermissionService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this._unsubscribeAll = new Subject();

    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));
  }

  ngOnInit() {
    this.navLinks = [{ path: 'details', label: 'DETAILS' }];

    if (this.route.snapshot.params.bankAccountId !== 'new') {
      this.navLinks.push({ path: 'audit', label: 'AUDIT TRAIL' });
      this.userPermissionService.granted([readOnly('servicing-synced-transaction')])
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(res => {
          if (res)
            this.navLinks.push({ path: 'transactions', label: 'TRANSACTIONS', query: { active: true } });
        });
    }
  }

  ngAfterViewInit() {
    this.isViewInitialized = true;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private dataInit(data: any) {
    this.data = data;

    if (this.data.appBar.closeAction.includes('company'))
      this.data.appBar.closeAction = `company/${this.route.snapshot.params.id}/banks`;
    else if (this.data.appBar.closeAction.includes('application'))
      this.data.appBar.closeAction = `application/${this.route.snapshot.params.id}/bank-accounts`;
    else if (this.data.appBar.closeAction.includes('account')) {
      const uniqueId = this.route.snapshot.params.uniqueId || this.route.snapshot.queryParams.uniqueId;
      this.data.appBar.closeAction = uniqueId
        ? `account/sf/${uniqueId}/bank-account`
        : `account/${this.route.snapshot.params.id}/bank-account`;
    }
  }

  isLinkActive(rla: RouterLinkActive): boolean {
    const routerLink = rla.linksWithHrefs ? rla.linksWithHrefs.first : null;
    return routerLink ? this.router.isActive(routerLink.urlTree, false) : false;
  }
}
