<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div class="header">
    <div class="layout padding-md">
      <div class="content" fxLayout="column" fxLayoutAlign="center center" >
        <div class="header-icon">
          <mat-icon>business</mat-icon>
        </div>
        <div class="card-title">
          {{data.name}}
        </div>
      </div>
    </div>
    <button mat-mini-fab class="add-button" color="primary" routerLink="edit" aria-label="Edit" *ngIf="editable">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>work</mat-icon>
        <h4 mat-line>{{data.dba || '-'}}</h4>
        <p mat-line>DBA</p>
      </mat-list-item>
      <mat-list-item class="business-address">
        <mat-icon mat-list-icon>location_on</mat-icon>
        <h4 mat-line>{{data.address?.line1}}</h4>
        <p mat-line>{{data.address?.city}}, {{data.address?.state}}
          {{data.address?.zip}}</p>
        <p mat-line>{{data.address?.line2}}</p>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>receipt</mat-icon>
        <h4 mat-line>{{data.ein}}</h4>
        <p mat-line>Tax ID</p>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>{{data.phone || '-'}}</h4>
        <p mat-line>Primary phone</p>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>desktop_windows</mat-icon>
        <h4 mat-line>{{data.webSite || '-'}}</h4>
        <p mat-line>Website</p>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>email</mat-icon>
        <h4 mat-line>{{(data.emails && data.emails[0]?.email) ? data.emails[0].email : '-'}}</h4>
        <p mat-line>Email</p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
