import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { Subscription } from "rxjs";
import { FilterParameter } from "common";
import { TokenQueryResult, TokenData, TokenFilters, TokenQueryParams } from "../token.model";
import { TokenService } from "../token.service";
import { TokenFilterService } from "../token-filter.service";
import { TokenListFilterDialogComponent } from "../token-list-filter-dialog/token-list-filter-dialog.component";
import { readWrite } from "../../../user/user-permission/user-permission.data";
import { UserPermissionService } from "../../../user/user-permission/user-permission.service";
import { routerTransition } from "../../../shared/animations/router.animations";
import { ClientData } from "../../../auth/client/client.data";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifb-token-list",
  templateUrl: "./token-list.component.html",
  styleUrls: ["./token-list.component.css"],
  animations: routerTransition,
})
export class TokenListComponent implements OnInit {
  deleteSub: Subscription;

  filters: TokenFilters;
  activeFilters: FilterParameter[] = [];
  queryParams: TokenQueryParams;

  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private _tokenFilterService: TokenFilterService,
    private queryParamsService: QueryParamsService
  ) {}

  data: TokenListComponentData;
  tokenRevealed = {};

  tokenReveal(id: number) {
    this.tokenService.get(id).subscribe((token) => this.tokenSave(id, token));
  }

  tokenSave(id, token) {
    this.tokenRevealed[id] = token;
  }

  readWriteToken: boolean;

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((it: TokenListComponentData) => {
        this.data = it;
      });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((it) => {
      this.queryParams = new TokenQueryParams(this.queryParamsService.init(it));
      this.activeFilters = this._tokenFilterService.addFilterParameters(
        this.queryParams
      );
    });

    this.userPermissionService
      .granted([readWrite("admin-tokens")])
      .subscribe((res) => (this.readWriteToken = res));

    this.filters = new TokenFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this._tokenFilterService.filter(this.filters);
    });
  }

  filterDialog() {
    TokenListFilterDialogComponent.show(
      this.dialog,
      this.queryParams,
      this.data.clients
    ).subscribe((result) => {
      if (result) {
        this.filters = result;
        this.paginator.firstPage();
        this._tokenFilterService.filter(result);
      }
    });
  }

  sortData(sort: Sort) {
    this._tokenFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this._tokenFilterService.filter(this.filters, this.paginator, this.sort);
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;



  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    "client",
    "description",
    "createdBy",
    "createdOn",
    "expiresAt",
    "status",
    "token",
    "actions",
  ];
}

export interface TokenListComponentData {
  tokenQueryResult: TokenQueryResult;
  token: TokenData;
  clients: ClientData[];
}
