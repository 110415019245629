import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService,
  AppPageService,
  DialogConfirm,
  MessageService,
} from "common";
import { catchError, map, tap } from "rxjs/operators";
import { readWrite } from "../../../user/user-permission/user-permission.data";
import { UserPermissionService } from "../../../user/user-permission/user-permission.service";
import { EMPTY, Observable } from "rxjs";
import { Competitor, CompetitorBase } from "../../models/competitor.model";
import { getActionsConfig } from "./data/competitor-details-data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UpdateDialogComponent } from "../../../shared/update-dialog/update-dialog.component";
import { UpdateFormFieldData } from "../../../shared/const/models/update-form-field-data.model";
import * as _ from 'lodash'
import { CompetitorService } from "../../competitor.service";
import { CompetitorDetailsComponentData, WorkModeEnum } from "./models/competitor-details-data.model";

@UntilDestroy()
@Injectable()
export class CompetitorDetailsFacade {

  constructor(
    private dialog: MatDialog,
    private competitorService: CompetitorService,
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private userPermissionService: UserPermissionService,
    private messageService: MessageService,
    private appPageService: AppPageService
  ) {}

  init(currentCompetitor: Competitor): Observable<CompetitorDetailsComponentData> {
    return this.userPermissionService
    .granted([readWrite("admin-competitors")]).pipe(
      map(permission => {
        return { 
          competitor: currentCompetitor,
          workMode: currentCompetitor?.id ? WorkModeEnum.Edit : WorkModeEnum.Create,
          isReadWritePermission: permission
        };
      }), 
      tap(data => {
        this.updateDeleteButton(data);
        this.appBarTitleService.title = data.workMode === WorkModeEnum.Edit ?
        `Competitor - ${currentCompetitor.name}`
        : "Add new competitor";
      }));
  }

  getActionsConfig(): AppBarAction[] {
    return _.cloneDeep(getActionsConfig);
  }

  cancel(): void {
    this.appPageService.back();
  }

  delete(data: CompetitorDetailsComponentData) {
    if (!data?.competitor?.id) {
      this.cancel();
    }

    this.appBarActionsService.enable("delete", false);
    DialogConfirm.show(this.dialog, `Delete competitor?`)
    .pipe(untilDestroyed(this))
    .subscribe((result) => {
      if (result) {
        this.deleteCompetitorRequest(data?.competitor?.id, data);
      }
    });
  }

  private deleteCompetitorRequest(competitorId: number, data: CompetitorDetailsComponentData) {
    this.competitorService
      .deleteCompetitor(competitorId)
      .pipe(
        catchError((error) => {
          return this.handleDeleteError(error, data);
        })
      )
      .subscribe(() => {
        this.appPageService.back();
      });
  }

  private handleDeleteError(error: unknown, data: CompetitorDetailsComponentData) {
    this.updateDeleteButton(data);
    this.messageService.error(error);
    return EMPTY;
  }

  updateSaveButton(isFormDataValid: boolean, data: CompetitorDetailsComponentData) {
    this.appBarActionsService.enable("save", isFormDataValid && data.isReadWritePermission);
  }

  private updateDeleteButton(data: CompetitorDetailsComponentData) {
    this.appBarActionsService.hide("delete", !data.isReadWritePermission || data.workMode !== WorkModeEnum.Edit);
    this.appBarActionsService.enable("delete", data.isReadWritePermission && data.workMode === WorkModeEnum.Edit);
  }

  save(competitorToSave: CompetitorBase, data: CompetitorDetailsComponentData, updatedFieldsData: UpdateFormFieldData[]) {
    if(updatedFieldsData?.find(_ => _.isChanged) === undefined) {
      return;
    }

    this.appBarActionsService.enable("save", false);
    const action = data.workMode === WorkModeEnum.Edit ? "Update" : "Create";
    this.showUpdateDialog(action, competitorToSave, updatedFieldsData, data);
  }

  private showUpdateDialog(
    action: string,
    competitorToSave: CompetitorBase,
    updatedFieldsData: UpdateFormFieldData[],
    data: CompetitorDetailsComponentData
  ) {
    UpdateDialogComponent.show(
      this.dialog,
      updatedFieldsData,
      action,
      "competitor"
    )
    .pipe(untilDestroyed(this))
    .subscribe((result) => {
      if (!result) {
        this.appBarActionsService.enable("save", true);
        return;
      }

      if (data.workMode === WorkModeEnum.Edit) {
        this.updateCompetitorRequest(data.competitor.id, competitorToSave);
        return;
      }

      this.addCompetitorRequest(competitorToSave);
    });
  }

  private addCompetitorRequest(competitor: CompetitorBase): void {
    this.competitorService
      .addCompetitor(competitor)
      .pipe(
        catchError((error) => {
          return this.handleSaveError(error);
        })
      )
    .subscribe(_ => this.appPageService.back());
  }

  private updateCompetitorRequest(competitorId: number, competitor: CompetitorBase): void {
    this.competitorService
      .updateCompetitor(competitorId, competitor)
      .pipe(
        catchError((error) => {
          return this.handleSaveError(error);
        })
      )
      .subscribe(_ => this.appPageService.back());
  }

  private handleSaveError(error: unknown) {
    this.appBarActionsService.enable("save", true);
    this.messageService.error(error);
    return EMPTY;
  }
}
