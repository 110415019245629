import { Component, Input } from '@angular/core';
import { EntityData } from 'common';

@Component({
  selector: 'ifb-entity-profile-company-info-widget',
  templateUrl: './entity-profile-company-info-widget.component.html',
  styleUrls: ['./entity-profile-company-info-widget.component.scss']
})
export class EntityProfileCompanyInfoWidgetComponent {

  constructor() { }

  @Input()
  data: EntityData;

  @Input()
  editable: boolean;

}
