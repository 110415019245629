<mat-dialog-content class="mat-typography">
    <h3>Set filter</h3>
    <form [formGroup]="form">
  
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
  
        <div class="mat-form-field">
          <ifb-business-name-input [fc]="form.get('name')" class="mat-form-field"></ifb-business-name-input>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select matInput multiple formControlName="statuses" placeholder="Status">
            <mat-option *ngFor="let s of entityStatuses" [value]="s.value">{{s.name}}</mat-option>
          </mat-select>
          <button type="button" *ngIf="form.get('statuses').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('statuses').setValue(null)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>       
  
      </div> 
      
      <!-- Commented temporarily - LOC-1199 -->
      <!-- <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
        <mat-form-field appearance="outline">
          <mat-label>SalesforceId</mat-label>
          <input matInput formControlName="uniqueId" type="text" placeholder="SalesforceId">
          <button type="button" *ngIf="form.get('uniqueId').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('uniqueId').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        
        <div class="mat-form-field">
          <ifb-user-fullname-input [fc]="form.get('createdBy')" [label]="'Created by'"></ifb-user-fullname-input>
        </div>  
      </div> -->
  
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

        <ifc-filter-param-date-range-input [fc]="form.get('createdOn')" [label]="'Created on'" (rangeSelected)="createdDateSelected($event)">
        </ifc-filter-param-date-range-input>

        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select matInput formControlName="active" placeholder="Status">
              <mat-option>All</mat-option>
              <mat-option value="true">Active</mat-option>
              <mat-option value="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>

      </div>      

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('createdOn').value === 'custom'">
        <ifc-datepicker [fc]="form.get('createdOnStart')" [label]="'Created date from'"></ifc-datepicker>
        <ifc-datepicker [fc]="form.get('createdOnEnd')" [label]="'Created date to'"></ifc-datepicker>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
        <ifb-entity-number-input [fc]="form.get('number')" class="mat-form-field"></ifb-entity-number-input>

        <ifb-entity-ein-input [fc]="form.get('ein')" class="mat-form-field"></ifb-entity-ein-input>


      </div>
      
      <!-- Commented temporarily - LOC-1199 -->
      <!--<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
        <ifc-filter-param-date-range-input [fc]="form.get('updatedOn')" [label]="'Updated on'" (rangeSelected)="updatedDateSelected($event)">
        </ifc-filter-param-date-range-input>
        
        <div class="mat-form-field">
          <ifb-user-fullname-input [fc]="form.get('updatedBy')" [label]="'Updated by'"></ifb-user-fullname-input>
        </div>
        
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('updatedOn').value === 'custom'">
        <ifc-datepicker [fc]="form.get('updatedOnStart')" [label]="'Updated date from'"></ifc-datepicker>
        <ifc-datepicker [fc]="form.get('updatedOnEnd')" [label]="'Updated date to'"></ifc-datepicker>
      </div> -->

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

        <mat-form-field appearance="outline">
          <mat-label>Bank link status</mat-label>
          <mat-select formControlName="bankLinkStatus" placeholder="All">
            <mat-option [value]="">All</mat-option>
            <mat-option *ngFor="let option of bankLinkStatusOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Bank link attempted</mat-label>
          <mat-select formControlName="bankLinkAttempted" placeholder="All">
            <mat-option>All</mat-option>
            <mat-option [value]="'true'">Yes</mat-option>
            <mat-option [value]="'false'">No</mat-option>
          </mat-select>
        </mat-form-field>

      </div>   

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
          
        <ifc-filter-param-date-range-input [fc]="form.get('lastLinkingAttemptDate')" [label]="'Last linking attempt date'" (rangeSelected)="lastLinkingAttemptDateSelected($event)">
        </ifc-filter-param-date-range-input>

        <mat-form-field appearance="outline">
          <mat-label>Verification status</mat-label>
          <mat-select formControlName="isVerified" placeholder="All">
            <mat-option>All</mat-option>
            <mat-option [value]="'false'">Unverified</mat-option>
            <mat-option [value]="'true'">Verified</mat-option>
          </mat-select>
        </mat-form-field>
      
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px"  
      *ngIf="form.get('lastLinkingAttemptDate').value === 'custom'">
        <ifc-datepicker [fc]="form.get('lastLinkingAttemptDateFrom')" [label]="'Last linking attempt date from'"></ifc-datepicker>
        <ifc-datepicker [fc]="form.get('lastLinkingAttemptDateTo')" [label]="'Last linking attempt date to'"></ifc-datepicker>
      </div>
      
    </form>
  </mat-dialog-content>
  
  <div mat-dialog-actions class="flex row end-center">
    <button mat-button [mat-dialog-close]="">Cancel</button>
    <button mat-button [mat-dialog-close]="filters" color="primary" cdkFocusInitial>Ok</button>
  </div>
  