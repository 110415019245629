<ngx-charts-bar-vertical
  [view]="view"
  [results]="data"
  [scheme]="options.colorScheme"
  [gradient]="options.gradient"
  [xAxis]="options.showXAxis"
  [yAxis]="options.showYAxis"
  [legend]="options.showLegend"
  [showXAxisLabel]="options.showXAxisLabel"
  [showYAxisLabel]="options.showYAxisLabel"
  [xAxisLabel]="options.xAxisLabel"
  [yAxisLabel]="options.yAxisLabel"
  [yAxisTickFormatting]="yAxisTickFormattingFn">
</ngx-charts-bar-vertical>
