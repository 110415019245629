<div [@routerTransition]="'fly'">

    <div class="filter-toolbar">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
        <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters" (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
        <div fxHide.xs class="layout padding-right-md">
          <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
            <mat-icon>filter_list</mat-icon>
          </button>
          <button mat-icon-button (click)="download(queryParams)" class="secondary-text" matTooltip="Download">
            <mat-icon>get_app</mat-icon>
          </button>
        </div>
        <div fxHide fxShow.xs>
          <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <button mat-menu-item (click)="filterDialog()">
              <mat-icon>filter_list</mat-icon>
              <span>Filter</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  
    <ifc-app-scroll-container [default]="true">
      <div class="table-container">
        <div class="message-box info" *ngIf="!data.entityQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
          <mat-icon class="icon">info</mat-icon>
          <span>No data found</span>
        </div>
        <table *ngIf="data.entityQueryResult.totalCount" mat-table matSort [dataSource]="data.entityQueryResult.values" (matSortChange)="sortData($event)">

          <ng-container matColumnDef="bankLinkStatus">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> 
              <div [ngClass]="getBankLinkingStatusAvatarClass(row)">
                <mat-icon [inline]="true" class="small" matTooltip="{{getBankLinkingStatusTooltip(row)}}">{{getBankLinkingStatusAvatarIcon(row)}}</mat-icon>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="verificationStatus">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell">
              <div  *ngIf="canReadBankAccountVerificationStatus" [ngClass]="getBankVerificationAvatarClass(row)">
                <mat-icon matTooltip="{{getBankVerificationTooltip(row)}}" [inline]="true" class="small">
                  {{getBankVerificationAvatarIcon(row)}}</mat-icon>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Legal entity name </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="entityNumber"> Entity number </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.entityNumber}} </td>
          </ng-container>
  
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{formatStatus(row.status)}} </td>
          </ng-container>
  
          <ng-container matColumnDef="loanNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="loanNumber"> Loan Number </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.loanNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="phone"> Phone </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.phone}} </td>
          </ng-container>        
  
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell">
              <span *ngFor="let email of row.emails">                
                  <span>{{email.email}}</span> <br />
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="totalPrincipalBalance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="totalCurrentPrincipalBalance" arrowPosition="before" class="right-align"> Total principal balance </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell">
              {{row.totalCurrentPrincipalBalance | currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="msaOfferAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="msaTerms.offerAmount" arrowPosition="before" class="right-align"> Offer amount </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell">
              {{row.msaTerms.offerAmount | currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="msaAvailableBalance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="msaTerms.currentAvailableBalance" arrowPosition="before" class="right-align"> Available funds </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell">
              {{row.msaTerms.currentAvailableBalance | currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="advisorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="advisorName"> Business advisor </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.advisorName}} </td>
          </ng-container>
  
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy"> Created by </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.createdBy}} </td>
          </ng-container>
  
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Created on </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.createdOn | date:'medium'}} </td>
          </ng-container>
  
          <ng-container matColumnDef="updatedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedBy"> Updated by </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.updatedBy}} </td>
          </ng-container>
  
          <ng-container matColumnDef="updatedOn"> 
            <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedOn"> Updated on </th>
            <td mat-cell *matCellDef="let row" routerLink="{{profileLink(row)}}" class="table-cell"> {{row.updatedOn | date:'medium'}} </td>
          </ng-container>
  
          <ng-container matColumnDef="uniqueId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="uniqueId"> Salesforce ID </th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              <a *ngIf="row.uniqueId" href="{{salesforceLink(row.uniqueId)}}" target="_blank"> {{row.uniqueId}} </a>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="actions" stickyEnd>
            <th nowrap mat-header-cell *matHeaderCellDef></th>
            <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let row" class="action-icon"  ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
                <button mat-icon-button (click)="companyDetailsLink(row, true);">
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true;" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" class="table-row"></tr>
        </table>
      </div>
    </ifc-app-scroll-container>
  
    <div [hidden]="data.entityQueryResult.totalCount <= 10">
      <mat-paginator #paginator showFirstLastButtons [length]="data.entityQueryResult.totalCount"
        [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
    </div>
  
  </div>
  