import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuditFilters, AuditQueryParams } from '../audit-trail.model';
import { FilterRange } from 'common';
import * as moment from 'moment';
import { AuditTrailService } from '../audit-trail.service';

@Component({
  selector: 'ifb-audit-trail-list-filter-dialog',
  templateUrl: './audit-trail-list-filter-dialog.component.html',
  styleUrls: ['./audit-trail-list-filter-dialog.component.css']
})
export class AuditTrailListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: AuditFilters;

  constructor(public dialogRef: MatDialogRef<AuditTrailListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private auditService: AuditTrailService
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new AuditFilters(data.query);
    this.form = this.formBuilder.group({
      date: this.data.query.dateFrom || undefined,
      dateFrom: new UntypedFormControl(this.data.query.dateFrom ? new Date(this.data.query.dateFrom) : undefined, { validators: null, updateOn: 'blur' }),
      dateTo: new UntypedFormControl(this.data.query.dateTo ? new Date(this.data.query.dateTo) : undefined, { validators: null, updateOn: 'blur' }),
      event: this.data.query.eventName ? this.getEventNameData() : '',
      initiator: this.data.query.initiator || undefined
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => { this.filters = new AuditFilters(data); });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dateSelected(dataRange: FilterRange<moment.Moment>): void {
    this.form.patchValue({
      dateFrom: dataRange.from,
      dateTo: dataRange.to
    });
  }

  getEventNameData(): void {
    this.auditService.hints({
      eventName: this.data.query.eventName,
    }).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.form.controls.event.setValue(res[0]);
      });
  }

  static show(dialog: MatDialog, query: AuditQueryParams): Observable<any> {
    return dialog.open(AuditTrailListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
