<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title">Status and activation</div>
  </div>

  <mat-card-content class="layout padding-md">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text" mat-line>Status</span>
          <p class="item-content">{{data.status | titlecase}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Entity number</span>
          <p class="item-content">{{data.entityNumber}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Created on</span>
          <p class="item-content">{{data.createdOn | date}} - {{weekDay}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Created by</span>
          <p class="item-content">{{data.createdBy}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Updated on</span>
          <p class="item-content">{{(data.updatedOn | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Updated By</span>
          <p class="item-content">{{(data.updatedBy)}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text" mat-line>Active</span>
          <p class="item-content">{{(data?.active.toString()) | titlecase}}</p>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>