<ifc-app-scroll-container [widgetsWithTopHeader]="true"
                          ngClass="layout ">

  <div class="layout margin flex-std"
       [@routerTransition]="'fly'">

    <ifb-panel>
      <ng-container *ngIf="customerInfoData; else noData">
        <ifb-card>
          <ifb-customer-info-widget [data]="customerInfoData"
                                    [edit]="canEditCustomerInfo">
          </ifb-customer-info-widget>
        </ifb-card>
        <ifb-card>
          <ifp-customer-other-info-widget [data]="customerInfoData"
                                          [edit]="canEditCustomerInfo">
          </ifp-customer-other-info-widget>
          <ifb-customer-loans-widget [edit]="canEditCustomerInfo"
                                     [customerId]="customerInfoData?.id"
                                     [saleforceViewMode]="saleforceViewMode">
          </ifb-customer-loans-widget>
          <ifb-customer-identity-verification-widget *ngIf="canReadCustomerIdentificationData"
                                                     [data]="customerInfoData?.kba"
                                                     [customerId]="customerInfoData.id"
                                                     [edit]="canEditCustomerKBA">
          </ifb-customer-identity-verification-widget>
        </ifb-card>
      </ng-container>
      <ng-template #noData>No customer data</ng-template>
    </ifb-panel>
  </div>
</ifc-app-scroll-container>
