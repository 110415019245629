import { UntypedFormControl, Validators } from "@angular/forms";
import { AppBarAction } from "common";

export const getStatementsFormModel = () => ({
  alligatorMatch: { value: undefined, disabled: true },
  generateStatements: [Validators.required],
  generateStatementsExpirationDate: new UntypedFormControl(null, { validators: null, updateOn: 'blur' }),
  generateStatementsExpirationTime: { value: undefined }, 
});

export const getActionsConfig: AppBarAction[] = [
  { id: "cancel", label: "Cancel", buttonType: "button" },
  {
    id: "save",
    label: "Save",
    buttonType: "submit",
    buttonAppearance: "flat",
    buttonColor: "primary",
    disabled: true,
  },
];