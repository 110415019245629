<div class="content layout" fxLayout="column" fxLayoutAlign="center center" fxFlexFill.lt-lg
  ngClass.lt-lg="fit-content">

  <form [formGroup]="form" ngClass.xs="layout fill">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Pricing Plan </mat-label>
        <mat-select name="pricingPlan" formControlName="pricingPlan">
          <mat-option *ngFor="let option of pricingPlanOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Commision Plan </mat-label>
        <mat-select name="plan" formControlName="plan">
          <mat-option *ngFor="let option of commissionPlanOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Initial Draw Commission </mat-label>
        <input matInput type="text" currencyMask [options]="{precision: 2, suffix: '%', prefix: ''}" name="initialDrawCommission" formControlName="initialDrawCommission">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Initial Commission Period </mat-label>
        <input matInput type="text" name="initialCommissionPeriod" formControlName="initialCommissionPeriod">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Initial Commission Cap </mat-label>
        <input matInput type="text" currencyMask [options]="{precision: 2, suffix: '%', prefix: ''}" name="initialCommissionCap" formControlName="initialCommissionCap">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Residual Draw Commission </mat-label>
        <input matInput type="text" currencyMask [options]="{precision: 2, suffix: '%', prefix: ''}" [options]="{precision: 2, suffix: '%', prefix: ''}" name="residualDrawCommission" formControlName="residualDrawCommission">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Residual Commission Period </mat-label>
        <input matInput type="text" name="residualCommissionPeriod" formControlName="residualCommissionPeriod">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Default Clawback Period </mat-label>
        <input matInput type="text" name="defaultClawbackPeriod" formControlName="defaultClawbackPeriod">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="odd-form-field">

      <mat-form-field appearance="outline">
        <mat-label> Default Prepayment Clawback Period </mat-label>
        <input matInput type="text" name="defaultPrepaymentClawbackPeriod" formControlName="defaultPrepaymentClawbackPeriod">
      </mat-form-field>

    </div>

  </form>

</div>