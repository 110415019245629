import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { CompetitorDetailsComponent } from "./components/competitor-details/competitor-details.component";
import { CompetitorListComponent } from "./components/competitor-list/competitor-list.component";
import { CompetitorListFilterDialogComponent } from "./components/competitor-list/components/competitor-list-filter-dialog/competitor-list-filter-dialog.component";
import { CompetitorNameInputComponent } from "./components/competitor-list/components/competitor-name-input/competitor-name-input.component";
import { SharedModule } from "../shared/shared.module";
import { CompetitorRoutingModule } from "./competitor-routing.module";
import { CompetitorFormComponent } from "./components/competitor-form/competitor-form.component";
import { CompetitorsSelectSearchComponent } from "./components/competitors-select-search/competitors-select-search.component";
import { CompetitorsSelectAddDialogComponent } from "./components/competitors-select-search/components/competitors-select-add-dialog/competitors-select-add-dialog.component";

@NgModule({
  imports: [CommonModule, SharedModule, CompetitorRoutingModule],
  declarations: [
    CompetitorsSelectSearchComponent,
    CompetitorFormComponent,
    CompetitorListComponent,
    CompetitorListFilterDialogComponent,
    CompetitorNameInputComponent,
    CompetitorDetailsComponent,
    CompetitorsSelectAddDialogComponent,
  ],
  exports: [CompetitorsSelectSearchComponent],
})
export class CompetitorsModule {}
