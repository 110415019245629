import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { appSidenavItems } from './app.data';
import { UserPermissionService } from './user/user-permission/user-permission.service';
import { UserService } from './user/user.service';
import { ObjectHelper } from 'common';

@Injectable({
  providedIn: 'root'
})
export class AppPageResolver implements Resolve<any> {
  constructor(private userService: UserService) {}

  resolve(): Observable<any> | Promise<any> | any {
    return this.userService.current.pipe(
      map(user => ({
        contentAlignment: 'stretch-start',
        contentClass: 'fill',
        sidenavEnabled: true,
        sidenavTitle: `${user?.firstName} ${user?.lastName}`,
        sidenavSubtitle: ObjectHelper.getListFromArray(user?.roles, 'name', ','),
        contentWithoutPadding: true
      }))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppSidenavResolver implements Resolve<any> {
  constructor(private permissionService: UserPermissionService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const items = appSidenavItems(route.data.appSidenavItem);

    return forkJoin(items.map(it => this.permissionService.visibility(it))).pipe(
      map(it => ({ items: it }))
    );
  }
}
