import { Component, Input } from '@angular/core';
import { CustomerData } from '../../customer.model';

@Component({
  selector: 'ifp-customer-other-info-widget',
  templateUrl: './customer-other-info-widget.component.html',
  styleUrls: ['./customer-other-info-widget.component.scss']
})
export class CustomerOtherInfoWidgetComponent {

  @Input()
  data: CustomerData;

  @Input()
  edit: boolean;
}
