import { Component, Input } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { ApplicationProfileComponentData } from '../application-profile/application-profile.component';

@Component({
  selector: 'ifb-application-other-info-widget',
  templateUrl: './application-other-info-widget.component.html',
  styleUrls: ['./application-other-info-widget.component.scss']
})
export class ApplicationOtherInfoWidgetComponent {

  @Input()
  data: ApplicationProfileComponentData;

  constructor(private settings: AppSettings) { }

  salesforceLink(salesforceRecordId: string) {
    return this.settings.salesforce.url + salesforceRecordId;
  }

}
