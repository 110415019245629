import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { ObjectHelper, MatTableHelper, FilterParameter } from 'common';
import { EntityStatusLabel } from '../entity/entity.model';
import {
    BrokerFilters,
    BrokerQueryParams,
    BrokerTypeLabel,
    BrokerPricingPlanLabel,
    BrokerTierLabel
} from './broker.model';

@Injectable({
    providedIn: 'root'
})
export class BrokerFilterService {

    constructor(private router: Router) {
    }

    filter(filters: BrokerFilters, paginator?: MatPaginator, sort?: MatSort) {
        const query = new BrokerQueryParams(filters);

        // Set default to first page
        query.skip = undefined;

        query.establishedDateStart = filters.establishedDateStart ? moment(filters.establishedDateStart).format('L') : undefined;
        query.establishedDateEnd = filters.establishedDateEnd ? moment(filters.establishedDateEnd).format('L') : undefined;
        query.contractDateStart = filters.contractDateStart ? moment(filters.contractDateStart).format('L') : undefined;
        query.contractDateEnd = filters.contractDateEnd ? moment(filters.contractDateEnd).format('L') : undefined;
        query.createdOnStart = filters.createdOnStart ? moment(filters.createdOnStart).format('L') : undefined;
        query.createdOnEnd = filters.createdOnEnd ? moment(filters.createdOnEnd).format('L') : undefined;
        query.updatedOnStart = filters.updatedOnStart ? moment(filters.updatedOnStart).format('L') : undefined;
        query.updatedOnEnd = filters.updatedOnEnd ? moment(filters.updatedOnEnd).format('L') : undefined;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        this.router.navigate(['broker'], { replaceUrl: true, queryParams: query });

    }

    addFilterParameters(query: BrokerQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.uniqueId) {
            filters.push({
                id: 'uniqueId', name: 'Salesforce ID', value: query.uniqueId.trim()
            });
        }

        if (query.advisorId) {
            filters.push({
                id: 'advisorId', name: 'Advisor', value: query.advisorId
            });
        }

        if (query.types) {
            filters.push({
                id: 'types', name: 'Types', value: ObjectHelper.makeArray(query.types).map(t => BrokerTypeLabel.get(t)).join(', ')
            });
        }

        if (query.statuses) {
            filters.push({
                // eslint-disable-next-line max-len
                id: 'statuses', name: 'Statuses', value: ObjectHelper.makeArray(query.statuses).map(e => EntityStatusLabel.get(e)).join(', ')
            });
        }

        if (query.name) {
            filters.push({
                id: 'name', name: 'Name', value: query.name.trim()
            });
        }

        if (query.dba) {
            filters.push({
                id: 'dba', name: 'DBA', value: query.dba.trim()
            });
        }

        if (query.ein) {
            filters.push({
                id: 'ein', name: 'Tax ID', value: query.ein.trim()
            });
        }

        if (query.contactEmail) {
            filters.push({
                id: 'contactEmail', name: 'Contact Email', value: query.contactEmail.trim()
            });
        }

        if (query.commissionEmail) {
            filters.push({
                id: 'commissionEmail', name: 'Commission Email', value: query.commissionEmail.trim()
            });
        }

        if (query.webSite) {
            filters.push({
                id: 'webSite', name: 'Web Site', value: query.webSite.trim()
            });
        }

        if (query.phone) {
            filters.push({
                id: 'phone', name: 'Phone', value: query.phone.trim()
            });
        }

        if (query.establishedDateStart) {
            filters.push({
                id: 'establishedDateStart', name: 'Established',
                value: `${moment(query.establishedDateStart).format('L')} - ${moment(query.establishedDateEnd).format('L')}`
            });
        }

        if (query.contractDateStart) {
            filters.push({
                id: 'contractDateStart', name: 'Contract date',
                value: `${moment(query.contractDateStart).format('L')} - ${moment(query.contractDateEnd).format('L')}`
            });
        }

        if (query.pricingPlans) {
            filters.push({
                // eslint-disable-next-line max-len
                id: 'pricingPlans', name: 'Pricing Plans', value: ObjectHelper.makeArray(query.pricingPlans).map(p => BrokerPricingPlanLabel.get(p)).join(', ')
            });
        }

        if (query.tiers) {
            filters.push({
                id: 'tiers', name: 'Tiers', value: ObjectHelper.makeArray(query.tiers).map(t => BrokerTierLabel.get(t)).join(', ')
            });
        }

        if (query.createdBy) {
            filters.push({
                id: 'createdBy', name: 'Created by', value: query.createdBy.trim()
            });
        }

        if (query.createdOnStart) {
            filters.push({
                id: 'createdOnStart', name: 'Created on',
                value: `${moment(query.createdOnStart).format('L')} - ${moment(query.createdOnEnd).format('L')}`
            });
        }

        if (query.updatedBy) {
            filters.push({
                id: 'updatedBy', name: 'Updated by', value: query.updatedBy.trim()
            });
        }

        if (query.updatedOnStart) {
            filters.push({
                id: 'updatedOnStart', name: 'Updated on',
                value: `${moment(query.updatedOnStart).format('L')} - ${moment(query.updatedOnEnd).format('L')}`
            });
        }

        if (query.active) {
            filters.push({
                id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
            });
        }

        return filters;
    }
}
