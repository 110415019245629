import { Component, Input } from '@angular/core';
import { BankLinkStatusLabels } from '../bank.model';

@Component({
  selector: 'ifb-bank-account-link-status-icon-chip',
  templateUrl: './bank-account-link-status-icon-chip.component.html',
  styleUrls: ['./bank-account-link-status-icon-chip.component.css']
})
export class BankAccountLinkStatusIconChipComponent {

  constructor() { }

  get statusLabels() { return BankLinkStatusLabels; }

  @Input()
  status = 0;
}
