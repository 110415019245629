import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ObservableHelper,
  EntityData,
  FilterParameter,
  PaymentFrequency,
  PaymentFrequencyLabel,
  FeeType,
  AccountFilters,
  AccountModificationFlag,
  AccountModificationFlagMap
} from 'common';
import {
  AccountQueryResult,
  AccountData,
  ProductCode,
  AutoPaymentModeMap,
  AutoPaymentMode
} from '../account.model';
import { AccountService } from '../account.service';
import { AccountFilterService } from '../account-filter.service';
import { AccountListFilterDialogComponent } from '../account-list-filter-dialog/account-list-filter-dialog.component';
import { BrokerData } from '../../broker/broker.model';
import { AdvisorData } from '../../advisor/advisor.model';
import { AppSettings } from '../../../app.settings';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private subs: Subscription[] = [];

  filters: AccountFilters;
  activeFilters: FilterParameter[] = [];
  brokerId: number;
  advisorId: number;
  entityId: number;

  get FeeType() { return FeeType; }

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private settings: AppSettings,
    private _accountFilterService: AccountFilterService,
    private _location: Location,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  accountDetailsLink(account: AccountData, newWindow: boolean = false) {
    this.accountService.prevAccountListUrl = this._location.path().toString();
    const path = `/account/${account.id}/profile`;
    if (newWindow)
      window.open(path);
    else
      this.router.navigate([path]);
  }

  getaccountOfferPaymentFrequenciesLabel(paymentFrequency: PaymentFrequency) {
    return PaymentFrequencyLabel.get(paymentFrequency);
  }

  data: AccountListComponentData;

  get ProductCode() { return ProductCode; }

  getLoanModification(type: AccountModificationFlag): string {
    return AccountModificationFlagMap.get(type);
  }

  getAutoPaymentMode(mode: AutoPaymentMode): string {
    return AutoPaymentModeMap.get(mode);
  }

  private dataInit(data: AccountListComponentData) {
    this.data = data;
    this.brokerId = this.data.broker ? this.data.broker.id : null;
    this.advisorId = this.data.advisor ? this.data.advisor.id : null;
    this.entityId = this.data.entity ? this.data.entity.id : null;
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.route.queryParams.subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.activeFilters = this._accountFilterService.addFilterParameters(this.queryParams);
        this.filters = new AccountFilters(this.queryParams);
      })
    ];
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this._accountFilterService.filter(this.filters, null, null, this.brokerId, this.advisorId, this.entityId);
    });
  }

  filterDialog() {
    AccountListFilterDialogComponent.show(this.dialog, this.queryParams, this.entityId ? false : true)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.filter();
        }
      });
  }

  sortData(sort: Sort) {
    this.filter();
  }

  setPage(event: PageEvent) {
    this.filter();
  }

  filter() {
    this._accountFilterService.filter(this.filters, this.paginator, this.sort, this.brokerId, this.advisorId, this.entityId);
  }

  ngOnDestroy() {
    ObservableHelper.unsubscribeAll(this.subs);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  queryParams: any;

  salesforceLink(salesforceRecordId: string) {
    return this.settings.salesforce.url + salesforceRecordId;
  }

  download(params: any) {
    const queryParams = Object.assign({}, params);
    queryParams.brokerId = this.brokerId;
    queryParams.advisorId = this.advisorId;
    queryParams.entityId = this.entityId;
    this.accountService.download(queryParams);
  }

  @ViewChild(MatSort)
  sort: MatSort;

  getDisplayedColumns(): string[] {
    return this.tableColumns
      .filter(cd => this.data.advisor && this.data.advisor.id ? cd.showInAdvisorTab : true)
      .filter(cd => this.data.entity && this.data.entity.id ? cd.showInCompanyTab : true)
      .map(cd => cd.def);
  }


  tableColumns = [
    { def: 'alligatorDataIcon', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'loanNumber', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'entityName', showInAdvisorTab: true, showInCompanyTab: false },
    { def: 'status', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'productType', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'description', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'creditLimit', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'balance', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'msaCreditLimit', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'msaAvailableFunds', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'modification', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'accruedInterest', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'principal', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'paymentAmount', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'paymentDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'interestRate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'drawDownFee', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'drawTerm', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'repaymentTerm', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'paymentFrequency', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'advisorName', showInAdvisorTab: false, showInCompanyTab: true },
    { def: 'createdOn', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'activationDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'maturityDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'renewalDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'paymentDay', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'lastAutoPaymentAmount', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'lastAutoPaymentDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'autoPayMode', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'accrueInterest', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'lastReAmortizationDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'modificationStartDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'modificationEndDate', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'originalPaymentAmount', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'originalRepaymentTerm', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'applicationNumber', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'salesForceID', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'salesForceApplicationID', showInAdvisorTab: true, showInCompanyTab: true },
    { def: 'edit', showInAdvisorTab: true, showInCompanyTab: true }
  ];

}

export interface AccountListComponentData {
  broker?: BrokerData;
  advisor?: AdvisorData;
  entity?: EntityData;
  accountQueryResult: AccountQueryResult;
}
