import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifb-bank-account-set-transfer-account-dialog',
  templateUrl: './bank-account-set-transfer-account-dialog.component.html',
  styleUrls: ['./bank-account-set-transfer-account-dialog.component.css']
})
export class BankAccountSetTransferAccountDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<BankAccountSetTransferAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, type: string, bankName: string, accountNumber: string): Observable<any> {
    return dialog.open(BankAccountSetTransferAccountDialogComponent, {
      width: '500px',
      height: '180px',
      data: {
        type: type,
        bankName: bankName,
        accountNumber: accountNumber
      },
      closeOnNavigation: false,
    }).afterClosed();
  }

}
