import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import { CompetitorService } from "./competitor.service";
import { Competitor } from "./models/competitor.model";
import { CompetitorListQueryParams, CompetitorListQueryResults } from "./models/competitors-query.model";

@Injectable({
  providedIn: "root",
})
export class CompetitorListResolver
  implements Resolve<CompetitorListQueryResults>
{
  constructor(private service: CompetitorService, private queryParamsService: QueryParamsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<CompetitorListQueryResults>
    | Promise<CompetitorListQueryResults>
    | CompetitorListQueryResults {

    return this.service.getCompetitorList(
      this.queryParamsService.init(route.queryParams) as CompetitorListQueryParams
    );
  }
}

@Injectable({
    providedIn: "root",
})
export class CompetitorResolver implements Resolve<Competitor> {
    constructor(private service: CompetitorService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        _: RouterStateSnapshot
    ):
      Observable<Competitor>
    | Promise<Competitor>
    | Competitor {
        return route.params.id === "new"
            ? undefined
            : this.service.getCompetitor(route.params.id);
    }
}
