import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppBarAction, AppBarTitleService, AppBarActionsService, MessageService, FormHelper, EnumHelper, AppPageService } from 'common';
import { CustomerComponentData, CustomerInfoDto, KBAStatus, KBAStatusMap } from '../customer.model';
import { CustomerService } from '../customer.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { CustomerKBAUpdateDialogComponent } from '../customer-kba-update-dialog/customer-kba-update-dialog.component';

@Component({
  selector: 'ifb-customer-kba-details',
  templateUrl: './customer-kba-details.component.html',
  styleUrls: ['./customer-kba-details.component.scss']
})
export class CustomerKBADetailsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  public canEditIdentificationData = false;

  private saveSub: Subscription;
  form: UntypedFormGroup;
  data: CustomerComponentData;

  kbaStatusOptions = EnumHelper.getMappedNamesAndValues(KBAStatus, KBAStatusMap);

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private messageService: MessageService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog) {

    this._unsubscribeAll = new Subject();

    this.formInit();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: !this.form.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
  }

  ngOnInit() {
    this.userPermissionService.granted([readWrite('servicing-customers-identification-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canEditIdentificationData = res;
        if (this.canEditIdentificationData) {
          // eslint-disable-next-line max-len
          this.form.controls.status.setValidators(Validators.required);
          this.form.controls.status.enable();
        } else {
          this.form.controls.status.clearValidators();
          this.form.controls.status.disable();
        }
      });

    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  dataInit(data: CustomerComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Identity Verification';

    if (data.customer.kba) {
      this.form.patchValue(data.customer.kba);
    }

    this.form.markAsPristine();
    this.actionUpdate();
  }


  private formInit() {
    this.form = this.formBuilder.group({
      status: [{ value: undefined, disabled: !this.canEditIdentificationData }],
      attemptCount: [{ value: undefined, disabled: true }],
      lastVerificationDate: [{ value: undefined, disabled: true }],
      lastError: [{ value: undefined, disabled: true }],
      lastConfigurationName: [{ value: undefined, disabled: true }]
    });
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub || !this.canEditIdentificationData)
      return;
    if (!this.form.valid)
      return;

    CustomerKBAUpdateDialogComponent.show(this.dialog, this.form)
      .subscribe(result => {
        if (result) {
          this.saveSub = this.customerService.overrideKBA(this.data.customer.id, this.form.controls.status.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error.error ? (error.error.Message ?? error.error) : error);
  }

  private saveSuccessHandler(info: CustomerInfoDto) {
    this.saveSubClear();
    this.appPageService.back();
  }
}
