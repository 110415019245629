import { Component } from '@angular/core';

@Component({
  selector: 'ifc-app-bar-expanded',
  templateUrl: './app-bar-expanded.component.html'
})
export class AppBarExpandedComponent {

  constructor() { }

}
