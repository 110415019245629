<div fxLayout="column" fxLayoutAlign="stretch">

  <div class="filter-toolbar" *ngIf="readWrite">
    <div fxLayout="row" fxLayoutAlign="end center" fxFlexFill>
      <div class="layout padding-right-md">
        <button mat-icon-button class="secondary-text" matTooltip="Clear cache" (click)="clearCache()">
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
      <div class="layout padding-right-md">
        <button mat-icon-button class="secondary-text" matTooltip="Add files" routerLink="upload">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <ifb-account-notes-sidenav [accountId]="data.account?.id" [notes]="data.account?.notes" [initiator]="data.userCurrent"
    [saveOnDemand]="true" [innerSidenavAndTabbed]="true" [hiddenButton]="!data.account">
    <ng-container *ngTemplateOutlet="main"></ng-container>
  </ifb-account-notes-sidenav>

  <ng-template #main>
    <ifc-app-scroll-container [default]="true" [@routerTransition]="'fly'">
      <div class="table-container">

        <div class="message-box info" *ngIf="!data.documentQueryResult || !data.documentQueryResult.totalCount"
          fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
          <mat-icon class="icon">info</mat-icon>
          <span>No data found</span>
        </div>

        <table *ngIf="data.documentQueryResult.totalCount" mat-table matSort [dataSource]="dataSource"
          (matSortChange)="sortData()" aria-label="Accounts documents list">
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Date </th>
            <td mat-cell *matCellDef="let document" class="table-cell">
              <span>{{document.uploadDate | date:"medium"}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="filename"> Name </th>
            <td mat-cell *matCellDef="let document" class="table-cell">
              <span>{{document.filename}} ({{document.length | filesize}})</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="category"> Type </th>
            <td mat-cell *matCellDef="let document" class="table-cell">
              {{getCategory(document.category)}} </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef mat-sort-header="actions" style="width: 40px"></th>
            <td mat-cell *matCellDef="let row" class="table-cell" ngClass.gt-md="action-icon actions-menu"
              ngClass.lt-lg="actions-menu-xs">
              <div fxLayout="row" fxLayoutAlign="center start">
                <button mat-icon-button [matMenuTriggerFor]="contextMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #contextMenu="matMenu">
                  <ng-template matMenuContent>
                    <button mat-menu-item [routerLink]="['/', 'account', row.loanId, 'documents', row.id]">
                      <mat-icon>get_app</mat-icon> Signature Information
                    </button>
                    <button mat-menu-item (click)="download(row.id, data.account?.loanInfo?.id)">
                      <mat-icon>get_app</mat-icon> Download
                    </button>
                    <button mat-menu-item (click)="delete(row.id, data.account?.loanInfo?.id, row.filename)">
                      <mat-icon>delete</mat-icon>
                      Delete
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
        </table>
      </div>
    </ifc-app-scroll-container>

    <div [hidden]="data.documentQueryResult.totalCount <= 10">
      <mat-paginator #paginator showFirstLastButtons [length]="data.documentQueryResult.totalCount"
        [pageSizeOptions]="[10, 20, 50]" (page)="setPage()"></mat-paginator>
    </div>
  </ng-template>
</div>