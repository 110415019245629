import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppSidenavComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppBarActionsComponent,
  AppBarExpandedTabsComponent
} from 'common';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../../app.data';
import { UserMenuComponent } from '../../user/user-menu/user-menu.component';
import { TokenListComponent } from './token-list/token-list.component';
import { TokenDetailsComponent } from './token-details/token-details.component';
import { TokenQueryResolver, TokenResolver } from './token.resolver';
import { AppPageResolver, AppSidenavResolver } from '../../app.resolver';
import { ClientAllResolver, ClientActiveResolver } from '../../auth/client/client.resolver';
import { AdminAppBarExpandedTabsResolver } from '../admin.resolver';
import { BOAuthGuard } from '../../guards/oauth.guard';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Admin',
  sideNavItem: 'admin'
});
const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
      {
        path: 'admin/token',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedTabsComponent
              }
            ]
          },
          {
            path: '',
            component: TokenListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              tokenQueryResult: TokenQueryResolver,
              clients: ClientAllResolver
            }
          }
        ]
      },
      {
        path: 'admin/token/:id',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: TokenDetailsComponent,
            resolve: {
              token: TokenResolver,
              clients: ClientActiveResolver
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TokenRoutingModule { }
