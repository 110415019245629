<ifc-app-scroll-container>
    <ng-container *ngTemplateOutlet="competitor"></ng-container>
</ifc-app-scroll-container>

<ng-template #competitor>
  <form [formGroup]="form" class="layout padding">
      <div fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="center center"
            fxLayoutGap="24px">
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Name</mat-label>
          <input matInput
                  formControlName="name"
                  type="text"
                  placeholder="Name"
                  maxlength="256" />
        </mat-form-field>

        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Api name</mat-label>
          <input matInput
                  formControlName="apiName"
                  type="text"
                  placeholder="Name"
                  maxlength="255" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="24px">
        <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md whole-line">
          <mat-label>Description</mat-label>
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="7"
            formControlName="description"></textarea>
        </mat-form-field>
      </div>
  <div formGroupName="address">
      <div fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="center center"
            fxLayoutGap="24px">
          <mat-form-field appearance="outline"
                          ngClass.xs="form-field-xs"
                          ngClass.gt-xs="form-field-md">
          <mat-label>Street address</mat-label>
          <input matInput
                  formControlName="line1"
                  type="text"
                  placeholder="Street address"
                  maxlength="128" />
          </mat-form-field>

          <mat-form-field appearance="outline"
                          ngClass.xs="form-field-xs"
                          ngClass.gt-xs="form-field-md">
          <mat-label>Unit/Suite/Apt #</mat-label>
          <input matInput
                  formControlName="line2"
                  type="text"
                  placeholder="Unit/Suite/Apt #"
                  maxlength="128" />
          </mat-form-field>
      </div>

      <div fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="center center"
            fxLayoutGap="24px">
          <mat-form-field appearance="outline"
                          ngClass.xs="form-field-xs"
                          ngClass.gt-xs="form-field-md">
          <mat-label>City</mat-label>
          <input matInput
                  formControlName="city"
                  type="text"
                  placeholder="City"
                  maxlength="64" />
          </mat-form-field>

          <div fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutAlign="center center"
                fxLayoutGap="24px">
              <mat-form-field appearance="outline"
                              ngClass.xs="form-field-xs"
                              ngClass.lt-md="form-field-md"
                              ngClass.gt-sm="form-field-half-md-24-gap">
              <mat-label>State</mat-label>
              <mat-select matInput formControlName="state" placeholder="State">
                  <mat-option *ngFor="let state of states" [value]="state.value">
                    {{state.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline"
                              ngClass.xs="form-field-xs"
                              ngClass.lt-md="form-field-md"
                              ngClass.gt-sm="form-field-half-md-24-gap">
              <mat-label>Zip code</mat-label>
              <input matInput
                      formControlName="zip"
                      type="text"
                      placeholder="Zip code"
                      maxlength="12" />
              </mat-form-field>
          </div>
      </div>
  </div>
      <div fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="start center"
            fxLayoutGap="24px">
        <div class="mat-form-field-wrapper">
          <mat-checkbox color="primary"
                        formControlName="active">Active</mat-checkbox>
        </div>
      </div>
  </form>
</ng-template>
