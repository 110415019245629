<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutGap="18px">

        <span class="mat-display-2">Add liquidity event</span>
        <form [formGroup]="liquidityForm">
            <div fxLayout="column" class="app-card-content">
                <mat-form-field appearance="outline"
                    ngClass.xs="form-field-xs"
                    ngClass.gt-xs="form-field-md">
                    <mat-label>Liquidity event</mat-label>
                    <mat-select matInput
                        formControlName="resolutionStatus"
                        placeholder="Liquidity event"
                        >
                        <mat-option *ngFor="let option of resolutionStatusOptions" [value]="option.value">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ifc-datepicker [fc]="liquidityForm.get('resolutionDate')" [label]="'Date of outcome'" [disabled]="false"></ifc-datepicker>
            </div>
        </form>
        <div mat-dialog-actions class="flex row end-center">
            <button mat-button [mat-dialog-close]="">Cancel</button>
            <button mat-button (click)="confirm()" [disabled]="!isFormDataValid()" cdkFocusInitial color="primary">Ok</button>
          </div>
    </div>
</mat-dialog-content>