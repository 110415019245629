import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ChartData } from '../../../shared/charts/charts.model';
import { TransactionService } from '../../../transaction/transaction.service';
import { TransactionsGroupedByAdvisor } from '../../../transaction/transaction.model';
import { MomentJSHelper } from '../../../shared/utils/moment-js-helper';
import { TransactionType } from 'common';

@Component({
  selector: 'ifb-new-draws-card-chart',
  templateUrl: './new-draws-card-chart.component.html',
  styleUrls: ['./new-draws-card-chart.component.scss']
})
export class NewDrawsCardChartComponent implements OnInit {

  months: string[];
  currentMonth: string;

  chartData: ChartData = {
    view: [400, 330],
    options: {
      showXAxis: true,
      showYAxis: true,
      gradient: false,
      showLegend: false,
      showXAxisLabel: true,
      showYAxisLabel: false,
      colorScheme: {
        domain: ['#1c9b41']
      }
    }
  };

  constructor(private _transactionService: TransactionService) {
    this.currentMonth = moment().format('MMMM');
    this.months = MomentJSHelper.getActiveMonthsCurrentYear();
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this._transactionService.groupedByAdvisor({
      fromDate: moment().month(this.currentMonth).startOf('month').format('L'),
      toDate: moment().month(this.currentMonth).endOf('month').format("L"),
      loanCreatedFrom: moment().month(this.currentMonth).startOf('month').format('L'),
      type: [TransactionType.Draw]
    }).subscribe((data) =>
      this.chartData.data = _.map(data, function (item: TransactionsGroupedByAdvisor) {
        return { name: item.advisor ? item.advisor.fullName : '', value: item.totalAmount };
      }));
  }

  filterByMonth(value: string): void {
    this.currentMonth = value;
    this.fetchData();
  }

}
