import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AppBarTitleService, AppBarActionsService, AppBarAction, DialogConfirm, MessageService, FormHelper, AppPageService } from 'common';
import { ApiKeyService } from '../api-key.service';
import { ApiKeyData } from '../api-key.model';
import { ClientData } from '../../../auth/client/client.data';
import { MatDialog } from '@angular/material/dialog';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { ApiKeyUpdateDialogComponent } from '../api-key-update-dialog/api-key-update-dialog.component';

@Component({
  selector: 'ifb-api-key-details',
  templateUrl: './api-key-details.component.html',
  styleUrls: ['./api-key-details.component.css']
})
export class ApiKeyDetailsComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiKeyService: ApiKeyService,
    private userPermissionService: UserPermissionService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private messageService: MessageService,
    private dialog: MatDialog) {

    this.appBarActionsService.actions = [
      { id: 'delete', label: 'Delete', disabled: true, buttonType: 'button' },
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.formInit();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.enable('delete', this.data && this.data.apiKey && this.data.apiKey.id && this.readWriteApiKey && !this.deleteSub ? true : false);
  }

  cancel() {
    this.appPageService.back();
  }

  copy(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
  }

  data: ApiKeyDetailsComponentData;

  dataInit(data: ApiKeyDetailsComponentData) {
    this.data = data;
    if (data.apiKey)
      this.form.patchValue(data.apiKey);

    this.appBarTitleService.title = data.apiKey && data.apiKey.id
      ? `Api Key for ${data.apiKey.clientName}`
      : 'Create new api key';

    this.form.markAsPristine();
    this.actionUpdate();
  }

  delete() {
    if (!this.data || !this.data.apiKey || !this.data.apiKey.id)
      this.appPageService.back();

    DialogConfirm.show(this.dialog, `Delete api key?`).subscribe(result => {
      if (result) {
        this.deleteSub = this.apiKeyService.delete(this.data.apiKey.id).subscribe(() => {
          this.appPageService.back();
        });
      }

      this.actionUpdate();
    });
  }

  deleteSub: Subscription;

  form: UntypedFormGroup;

  private formInit() {

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(),
      clientId: new UntypedFormControl(Validators.required),
      description: new UntypedFormControl(),
      active: new UntypedFormControl(Validators.required),
      secret: new UntypedFormControl(Validators.maxLength(64)),
      createdBy: new UntypedFormControl(),
      createdOn: new UntypedFormControl()
    });
  }

  readWriteApiKey: boolean;

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.appBarActionsService.invoking.subscribe(this.actionDispatch.bind(this)),
      this.form.statusChanges.subscribe(this.actionUpdate.bind(this))
    ];

    this.userPermissionService.granted([readWrite('admin-api-keys')])
      .subscribe(res => {
        this.readWriteApiKey = res;
        this.appBarActionsService.enable('delete', res);
      });
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;

    if (!this.form.valid)
      return;

    if (!this.form.dirty) {
      this.cancel();
      return;
    }

    if (this.data?.apiKey?.id) {
      ApiKeyUpdateDialogComponent.show(this.dialog, this.form, "Update").subscribe(result => {
        if (result) {
          this.saveSub = this.apiKeyService
            .update(this.data.apiKey.id, this.form.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
          this.actionUpdate();
          this.appPageService.back();
        }
      });
    } else {
      const name = this.data.clients.find(_ => _.id === this.form.value.clientId).name;
      ApiKeyUpdateDialogComponent.show(this.dialog, this.form, "Create", name).subscribe(result => {
        if (result)
          this.saveSub = this.apiKeyService
            .create(this.form.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        this.actionUpdate();
      });
    }
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSub: Subscription;

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveSuccessHandler(apiKey: ApiKeyData) {
    this.saveSubClear();
    if (apiKey) {
      this.data.apiKey = apiKey;
      this.dataInit(this.data);
      this.actionUpdate();
      this.form.get('clientId').disable();
    } else {
      this.router.navigate(['admin', 'apikey', apiKey.id], { replaceUrl: true });
    }
  }

}

export interface ApiKeyDetailsComponentData {
  apiKey: ApiKeyData;
  clients: ClientData[];
}
