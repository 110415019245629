import { NgModule } from "@angular/core";
import {
  CommonModule,
  BrokerApiKeyModule as CommonBrokerApiKeyModule,
} from "common";
import { BrokerApiKeyListComponent } from "./features/broker-api-key-list/broker-api-key-list.component";
import { SharedModule } from "../../../shared/shared.module";
import { BrokerApiKeysRoutingModule } from "./broker-api-keys-routing.module";
import { BrokerApiKeyDetailsComponent } from "./features/broker-api-key-details/broker-api-key-details.component";

@NgModule({
  declarations: [BrokerApiKeyListComponent, BrokerApiKeyDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    BrokerApiKeysRoutingModule,
    CommonBrokerApiKeyModule,
  ],
})
export class BrokerApiKeysModule {}
