<h1 mat-dialog-title>Update role?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are updating role:
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">

      <div *ngFor="let permisson of data.permissions" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{permisson.access}}</div>
        <div class="mat-caption"> {{permisson.name}} </div>
      </div>

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>