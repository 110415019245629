import { Pipe, PipeTransform } from "@angular/core";
import { AppSettings } from "projects/backoffice/src/app.settings";

@Pipe({
  name: "salesforceUrl",
})
export class SalesforceUrlPipe implements PipeTransform {
  constructor(private settings: AppSettings) {}
  transform(salesforceRecordId: string): string {
    return this.settings.salesforce.url + salesforceRecordId;
  }
}
