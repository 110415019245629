import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../account/account.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ChartData } from '../../../shared/charts/charts.model';
import { MomentJSHelper } from '../../../shared/utils/moment-js-helper';
import { AccountQueryParams } from 'common';

@Component({
  selector: 'ifb-account-onboarding-chart',
  templateUrl: './account-onboarding-chart.component.html',
  styleUrls: ['./account-onboarding-chart.component.scss']
})
export class AccountOnboardingChartComponent implements OnInit {

  months: string[];
  currentMonth: string;

  chartData: ChartData = {
    view: [400, 330],
    options: {
      showXAxis: true,
      showYAxis: true,
      gradient: false,
      showLegend: true,
      legendTitle: 'Legend',
      legendPosition: 'below',
      showXAxisLabel: true,
      showYAxisLabel: false,
      colorScheme: {
        domain: ['#747474', '#dbe9f1', '#4a78bc', '#e28f26', '#1c9b41']
      }
    }
  };

  constructor(private _accountService: AccountService) {
    this.currentMonth = moment().format('MMMM');
    this.months = MomentJSHelper.getActiveMonthsCurrentYear();
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    const filters = new AccountQueryParams();
    if (this.currentMonth) {
      filters.createdOnFrom = moment().month(this.currentMonth).startOf('month').format('L');
      filters.createdOnTo = moment().month(this.currentMonth).endOf('month').format("L");
    }
    this._accountService.onboardingByAdvisorInfo(filters).subscribe((data: any) => {
      this.chartData.data = _.chain(data)
        .map(value => ({
          name: value.advisor, series: this.mapValues(value.values)
        }))
        .value();
    });
  }

  filterByMonth(value: string): void {
    this.currentMonth = value;
    this.fetchData();
  }

  private mapValues(obj) {
    const p = _.union(_.toPairs(obj));
    return _.map(p, function (item) {
      return { name: item[0], value: item[1] };
    });
  }

}
