import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { FilterParameter } from 'common';
import { BankingTransactionFilters, BankingTransactionQueryParams } from '../banking.model';

@Injectable({
    providedIn: 'root'
})
export class BankingTransactionListFilterService {
    constructor(
        private router: Router
    ) { }

    filter(
        provider: string,
        filters: BankingTransactionFilters,
        bankingAccountId: string,
        bankingCustomerId: string,
        paginator?: MatPaginator,
        sort?: MatSort) {
        const query: BankingTransactionQueryParams = filters;

        query.fromDate = filters.fromDate ? moment(filters.fromDate).format('L') : undefined;
        query.toDate = filters.toDate ? moment(filters.toDate).format('L') : undefined;

        // Set default to first page
        query.skip = undefined;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = sort.direction;
        }


        // eslint-disable-next-line max-len
        this.router.navigate([`banking/${provider}/customers/${bankingCustomerId}/accounts/${bankingAccountId}/transactions`], { replaceUrl: true, queryParams: query });
    }

    addFilterParameters(query: BankingTransactionQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];
        if (query.fromDate) {
            filters.push({
                id: 'fromDate', name: 'From Date',
                value: `${moment(query.fromDate).format('L')}`
            });
        }
        if (query.toDate) {
            filters.push({
                id: 'toDate', name: 'To Date',
                value: `${moment(query.toDate).format('L')}`
            });
        }
        if (query.includePending) {
            filters.push({
                id: 'includePending', name: 'Include pending',
                value: query.includePending
            });
        }

        return filters;
    }
}
