import { Component, Input } from '@angular/core';
import { BrokerExData } from '../broker.model';
import { Router } from '@angular/router';

@Component({
  selector: 'ifb-broker-company-widget',
  templateUrl: './broker-company-widget.component.html',
  styleUrls: ['./broker-company-widget.component.css']
})
export class BrokerCompanyWidgetComponent {

  constructor(private router: Router) { }

  @Input()
  broker: BrokerExData;

  @Input()
  edit: boolean;

  detailsLink() {
    this.router.navigate([`broker/${this.broker.id}/company`]);
  }
}
