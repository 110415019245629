import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FailureReasonData, FailureReasonQueryParams, FailureReasonQueryResult, FailureReasonUsage, FailureReasonBaseData } from './failure-reason.model';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class FailureReasonService {

  private readonly baseUrl = `${this.settings.failurereasons.url}/api/failurereasons`;

  constructor(private http: HttpClient, private settings: AppSettings) { }

  list(usage?: FailureReasonUsage): Observable<FailureReasonBaseData[]> {
    return this.http.get<FailureReasonBaseData[]>(`${this.baseUrl}/list?usage=${usage == null ? '' : usage}`);
  }

  query(params: FailureReasonQueryParams): Observable<FailureReasonQueryResult> {
    return this.http.post<FailureReasonQueryResult>(`${this.baseUrl}/query`, params);
  }

  get(id: number): Observable<FailureReasonData> {
    return this.http.get<FailureReasonData>(`${this.baseUrl}/${id}`);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  update(id: number, reason: FailureReasonData): Observable<FailureReasonData> {
    return this.http.put<FailureReasonData>(`${this.baseUrl}/${id}`, reason);
  }

  create(reason: FailureReasonData): Observable<FailureReasonData> {
    return this.http.post<FailureReasonData>(`${this.baseUrl}`, reason);
  }
}
