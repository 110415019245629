import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routerTransition } from '../../../shared/animations/router.animations';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { Tenant } from '../tenant.model';

@Component({
  selector: 'ifb-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss'],
  animations: routerTransition,
})
export class TenantListComponent implements OnInit, AfterViewInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  data: TenantListComponentData;
  readWriteTenant: boolean;
  dataSource = new MatTableDataSource<Tenant>();

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: TenantListComponentData) => {
        this.data = it;
        if (this.data)
          this.dataSource = new MatTableDataSource(this.data.tenantListResult);
      });

    this.userPermissionService.granted([readWrite('admin-tenants')])
      .subscribe(res => this.readWriteTenant = res);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  tableColumns: string[] = [
    'overridden',
    'name',
    'dba',
    'phone',
    'email',
    'url',
    'address'
  ];

}

export interface TenantListComponentData {
  tenantListResult: Tenant[];
}
