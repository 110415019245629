import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, FilterParameter } from 'common';
import { UserQueryParams, UserFilters } from './user.model';

@Injectable({
    providedIn: 'root'
})
export class UserFilterService {

    constructor(
        private router: Router
    ) { }

    filter(filters: UserFilters, paginator?: MatPaginator, sort?: MatSort) {
        const query = new UserQueryParams(filters);
        query.username = typeof query.username === 'object' ? filters.username.username : query.username;
        query.active = typeof query.active === 'object' ? filters.active : query.active;

        // Set default to first page
        query.skip = undefined;

        query.lastLoginStart = filters.lastLoginStart ? moment(filters.lastLoginStart).format('L') : undefined;
        query.lastLoginEnd = filters.lastLoginEnd ? moment(filters.lastLoginEnd).format('L') : undefined;


        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        this.router.navigate(['admin', 'user'], { replaceUrl: true, queryParams: query });

        this.addFilterParameters(query);
    }

    addFilterParameters(query: UserQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.clientName) {
            filters.push({
                id: 'clientName', name: 'Client', value: query.clientName.trim()
            });
        }
        if (query.username) {
            filters.push({
                id: 'username', name: 'Email', value: query.username.trim()
            });
        }
        if (query.firstName) {
            filters.push({
                id: 'firstName', name: 'First name', value: query.firstName.trim()
            });
        }
        if (query.lastName) {
            filters.push({
                id: 'lastName', name: 'Last name', value: query.lastName.trim()
            });
        }
        if (query.lastLoginStart) {
            filters.push({
                id: 'lastLoginStart', name: 'Date of last login',
                value: `${moment(query.lastLoginStart).format('L')} - ${moment(query.lastLoginEnd).format('L')}`
            });
        }
        if (query.externalId) {
            filters.push({
                id: 'externalId', name: 'External ID', value: query.externalId.trim()
            });
        }

        if (query.active) {
            filters.push({
                id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
            });
        }

        return filters;
    }
}
