import { Injectable } from '@angular/core';
import { UserPermissionGroupData } from './user-permission-group.data';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionGroupService {

  constructor(private http: HttpClient, private settings: AppSettings) { }

  queryAll(): Observable<UserPermissionGroupData[]> {
    return this.http.get<UserPermissionGroupData[]>(`${this.settings.oauth.url}/api/permission/query`)
  }
}
