import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NumberHelper } from 'common';
import { AppSettings } from '../../app.settings';
import { LogQueryResult, LogQueryParams, LogGroupQueryResult, LogFilterOptions, LogDetailsData } from './log.model';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient, private settings: AppSettings) { }

  query(params: LogQueryParams): Observable<LogQueryResult> {
    return this.http.get<LogQueryResult>(`${this.settings.log.url}/api/log/query`, { params: <any>params });
  }

  grouped(params: LogQueryParams): Observable<LogGroupQueryResult> {
    return this.http.get<LogGroupQueryResult>(`${this.settings.log.url}/api/log/grouped`, { params: <any>params });
  }

  get(id: number): Observable<LogDetailsData> {
    return this.http.get<LogDetailsData>(`${this.settings.log.url}/api/log/${id}`);
  }

  archive(id: number): Observable<any> {
    return this.http.put<any>(`${this.settings.log.url}/api/log/${id}/archive`, null);
  }

  archiveAll(params: LogQueryParams): Observable<any> {
    params = Object.assign({}, params, { limit: NumberHelper.MaxValue, skip: 0 });
    return this.http.put<any>(`${this.settings.log.url}/api/log/archive`, null, { params: <any>params });
  }

  getFilterOptions(): Observable<LogFilterOptions> {
    return this.http.get<any>(`${this.settings.log.url}/api/log/filter-options`);
  }
}
