import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { AppBarTitleService } from 'common';
import { Log, LogDetailsData } from '../log.model';

@Component({
  selector: 'ifb-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.scss']
})
export class LogDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
  }

  data: LogDetailsComponentData;

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => { this.data = it; });
    this.appBarTitleService.title = this.data.details.log.logger + ', '
      + formatDate(this.data.details.log.timestamp, 'EEE, MM/dd/yyyy h:mm:ss:SS a', 'en_US');
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getJson(): JSON {
    return JSON.parse(this.data.details.json);
  }

}

export interface LogDetailsComponentData {
  details?: LogDetailsData;
}
