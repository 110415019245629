import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { routerTransition } from "../../shared/animations/router.animations";
import { OfferBundleListQueryParams } from "../models/offer-bundle-query.model";
import { OfferBundleFilterService } from "../offer-bundle-filter.service";
import { OfferBundleListData } from "./models/offer-bundle-list-data.model";

@Component({
  selector: "ifb-offer-bundle-list",
  templateUrl: "./offer-bundle-list.component.html",
  styleUrls: ["./offer-bundle-list.component.scss"],
  animations: routerTransition,
})
export class OfferBundleListComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<unknown>;

  tableColumns: string[] = [
    "name",
    "status",
    "expressCheckout",
    "uniqueId",
  ];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  queryParams: OfferBundleListQueryParams;

  constructor(
    private route: ActivatedRoute,
    private offerBundleFilterService: OfferBundleFilterService,
    private queryParamsService: QueryParamsService
  ) {
    this._unsubscribeAll = new Subject();
  }

  data: OfferBundleListData;

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: OfferBundleListData) => {
        this.data = data;
      });
    this.route.queryParams.subscribe(it => {
      this.queryParams = this.queryParamsService.init(it);
    });
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  filter(): void {
    this.offerBundleFilterService.filter(
      this.data.application.id,
      this.paginator,
      this.sort
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
