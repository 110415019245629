import { NgModule } from "@angular/core";
import { UiModule } from "../../ui/ui.module";
import { BrokerApiKeyListComponent } from "./components/broker-api-key-list/broker-api-key-list.component";
import { BrokerApiKeyDetailsComponent } from "./components/broker-api-key-details/broker-api-key-details.component";
import { BrokerApiKeyCardComponent } from "./components/broker-api-key-details/components/broker-api-key-card/broker-api-key-card.component";
import { BrokerApiKeyDetailsDialogComponent } from "./components/broker-api-key-details/components/broker-api-key-details-dialog/broker-api-key-details-dialog.component";
import { BrokerApiKeyListActionsInternalService } from "./actions/broker-api-key-actions.internal.service";
import { BrokerApiKeyListActionsService } from "./actions/broker-api-key-actions.service";

@NgModule({
  imports: [UiModule],
  declarations: [
    BrokerApiKeyListComponent,
    BrokerApiKeyDetailsComponent,
    BrokerApiKeyCardComponent,
    BrokerApiKeyDetailsDialogComponent,
  ],
  exports: [BrokerApiKeyListComponent, BrokerApiKeyDetailsComponent],
  providers: [
    BrokerApiKeyListActionsInternalService,
    BrokerApiKeyListActionsService,
  ],
})
export class BrokerApiKeyModule {}
