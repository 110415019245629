<svg viewBox="0 0 36 36" class="white">
      <path class="circle-bg" d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831" />
      <path class="circle" [attr.stroke-dasharray]="percentage + ', 100'" d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831" />
      <text x="18" y="17" class="label">{{label}}</text>

      <text *ngIf="caption1" x="18" y="22" class="caption">{{caption1}}</text>
      <text *ngIf="caption2" x="18" y="26" class="caption">{{caption2}}</text>
</svg>