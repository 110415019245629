import { ChartConfiguration } from "chart.js";

export const getBarChartData = (
  principalData: number[],
  interestData: number[],
  colors: string[],
  labels: string[]
): ChartConfiguration<"bar">["data"] => {
  return {
    datasets: [
      {
        label: "Principal balance",
        borderColor: "transparent",
        hoverBackgroundColor: "rgb(255, 99, 132)",
        backgroundColor: colors,
        data: [...principalData],
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      {
        label: "Interest balance",
        borderColor: "transparent",
        backgroundColor: colors,
        hoverBackgroundColor: "rgb(54, 162, 235)",
        data: [...interestData],
        categoryPercentage: 1.0,
        barPercentage: 0.9,
      },
    ],
    labels,
  };
};

export const getBarChartOptions: ChartConfiguration<"bar">["options"] = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: "nearest",
      callbacks: {
        label: function (tooltipItem) {
          const yLabel = tooltipItem.parsed.y;
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });

          return formatter.format(yLabel);
        },
        labelColor: function (context) {
          return {
            borderColor:
              context.datasetIndex === 0
                ? "rgb(255, 99, 132)"
                : "rgb(54, 162, 235)",
            backgroundColor:
              context.datasetIndex === 0
                ? "rgb(255, 99, 132)"
                : "rgb(54, 162, 235)",
          };
        },
      },
    },
  },
  responsive: false,
  maintainAspectRatio: false,
  interaction: {
    intersect: true,
    mode: "index",
  },
  scales: {
    x: {
      display: true,
      stacked: false,
      grid: {
        display: false,
        borderColor: "white",
      },
      ticks: {
        display: false,
      },
    },
    y: {
      ticks: {
        font: {
          size: 12,
        },
        callback: function (value, index, values) {
          return "$" + <number>value / 1000 + "k";
        },
        color: "white",
        maxTicksLimit: 5,
      },
      min: 0,
      grid: {
        display: true,
        lineWidth: 0,
        color: "rgba(255,255,255,0)",
        borderColor: "transparent",
      },
      title: {
        display: true,
        color: "#FFFFFF",
        font: {
          size: 10,
        },
      },
    },
  },
};
