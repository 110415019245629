import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterParameter } from 'common';
import { ClientData } from '../../../auth/client/client.data';
import { UserQueryResult, UserFilters, UserQueryParams } from '../user.model';
import { UserFilterService } from '../user-filter.service';
import { UserListFilterDialogComponent } from '../user-list-filter-dialog/user-list-filter-dialog.component';
import { routerTransition } from '../../../shared/animations/router.animations';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  animations: routerTransition
})
export class UserListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  filters: UserFilters;
  activeFilters: FilterParameter[] = [];
  queryParams: UserQueryParams;

  constructor(private route: ActivatedRoute,
    private _userFilterService: UserFilterService,
    private dialog: MatDialog,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  data: UserListComponentData;

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((it: UserListComponentData) => this.data = it);
    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(it => {
      this.queryParams = new UserQueryParams(this.queryParamsService.init(it));
      this.activeFilters = this._userFilterService.addFilterParameters(this.queryParams);
    });

    this.filters = new UserFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex =  this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this._userFilterService.filter(this.filters);
    });
  }

  filterDialog() {
    UserListFilterDialogComponent.show(this.dialog, new UserFilters(this.queryParams), this.data.clients)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this._userFilterService.filter(result);
        }
      });
  }

  sortData(sort: Sort) {
    this._userFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this._userFilterService.filter(this.filters, this.paginator, this.sort);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    'clientName',
    'username',
    'externalId',
    'firstName',
    'middleName',
    'lastName',
    'lastLoginDate_IP',
    'status',
  ];

}

export interface UserListComponentData {
  userQueryResult: UserQueryResult;
  clients: ClientData[];
}
