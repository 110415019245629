import { QueryParams, QueryResult } from "common";
import { AppBarExpandedTabWithPermission } from "../app.data";
import { readOnly } from "../user/user-permission/user-permission.data";

export interface AdvisorData {
    id?: number;
    uniqueId?: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    createdOn?: Date;
    updatedOn?: Date;
    active?: boolean;
    calendarLinkUrl?: string;
    avatar?: string;
}

export interface AdvisorQueryParams extends QueryParams {
    uniqueId?: any;
    fullName?: any;
    email?: any;
}

export interface AdvisorHintData {
    email: string;
    fullName: string;
}

export interface AdvisorHintParams {
    email?: string;
    fullName?: string;
    sortField?: string;
}

export class AdvisorFilters {
    uniqueId: any;
    fullName: any;
    email: any;
    active: any;

    constructor(filters?) {
        filters = filters || {};
        this.uniqueId = filters.uniqueId || undefined;
        this.fullName = filters.fullName || undefined;
        this.email = filters.email || undefined;
        this.active = filters.active || undefined;
    }

    remove(item: string) {
        this[item] = undefined;
    }
}

export interface AdvisorQueryResult extends QueryResult<AdvisorData> {
}

export const advisorAppBarExpandedTabs = (id: number): AppBarExpandedTabWithPermission[] => [
    {
        label: 'Profile',
        url: `/advisor/${id}/profile`,
        permission: [readOnly('servicing-advisors')],
        hidden: true
    },
    {
        label: 'Companies',
        url: `/advisor/${id}/companies`,
        permission: [readOnly('servicing-entities')],
        hidden: true
    },
    {
        label: 'Accounts',
        url: `/advisor/${id}/accounts`,
        permission: [readOnly('servicing-accounts')],
        hidden: true
    }
];
