import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BrokerService } from '../broker.service';
import { BrokerData } from '../broker.model';

@Component({
  selector: 'ifb-broker-app-bar-title',
  templateUrl: './broker-app-bar-title.component.html',
  styleUrls: ['./broker-app-bar-title.component.scss']
})
export class BrokerAppBarTitleComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  title: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private brokerService: BrokerService) {
    this._unsubscribeAll = new Subject();
  }

  back() {
    this.router.navigateByUrl(this.brokerService.prevBrokerListUrl || '/broker');
  }

  data: BrokerComponentData;

  dataSet(data: BrokerComponentData) {
    this.data = data;
    this.title = data.broker && data.broker.name ? data.broker.name : '';
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

export interface BrokerComponentData {
  broker: BrokerData;
}
