<h1 mat-dialog-title>Broker API created</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    This message will only be displayed once. Save the information below in a
    secure place.
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">
      <div
        *ngIf="data.apiKey?.id"
        fxLayout="column"
        fxFlex.gt-sm="100"
      >
        <mat-form-field appearance="outline">
          <mat-label>Client ID</mat-label>
          <input
            class="copy-input"
            matInput
            value="{{ data.apiKey.clientId }}"
            type="text"
            [readonly]="true"
          />
          <button
            mat-icon-button
            matSuffix
            aria-label="Copy client ID button"
            (click)="copy(data.apiKey.clientId)"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div
        *ngIf="data.apiKey?.id"
        fxLayout="column"
        fxFlex.gt-sm="100"
      >
        <mat-form-field appearance="outline">
          <mat-label>Client secret</mat-label>
          <input
            class="copy-input"
            matInput
            value="{{ data.apiKey.clientSecret }}"
            type="text"
            [readonly]="true"
          />
          <button
            mat-icon-button
            matSuffix
            aria-label="Copy client secret button"
            (click)="copy(data.apiKey.clientSecret)"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>
