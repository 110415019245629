import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AccountData } from "../../account.model";

@Component({
  selector: 'ifb-re-amortize-dialog',
  templateUrl: './re-amortize-dialog.component.html',
  styleUrls: ['./re-amortize-dialog.component.scss']
})
export class ReAmortizeDialogComponent {

  constructor(public dialogRef: MatDialogRef<ReAmortizeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, paymentAmount: number, numberOfPaymentsLeft: number, paymentDueDate: Date): Observable<any> {
    return dialog.open(ReAmortizeDialogComponent, {
      width: '510px',
      data: {
        paymentAmount: paymentAmount,
        numberOfPaymentsLeft: numberOfPaymentsLeft,
        paymentDueDate: paymentDueDate
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
