import { Component, Input, OnChanges } from '@angular/core';
import { Chart, ChartConfiguration } from "chart.js";
import { LoanInfoDto } from 'common';
import { getBarChartOptions, getBarChartData } from './term-loan-bar-chart-data';

@Component({
  selector: 'ifb-term-loan-chart',
  templateUrl: './term-loan-chart.component.html'
})
export class TermLoanChartComponent implements OnChanges {

  private dataTermPrincipalBalance: number[] = [];
  private dataTermInterestBalance: number[] = [];
  private labelTerm: string[] = [];
  private colors: string[] = [];

  @Input()
  infoData: LoanInfoDto;

  barChartData: ChartConfiguration<"bar">["data"] = null;
  barChartOptions: ChartConfiguration<"bar">["options"] = null;

  ngOnChanges(): void {
    if (this.infoData) {
      this.dataInit();
      this.barChartData = this.prepareBarChartData();
      this.barChartOptions = this.prepareBarChartOptions();
    }
  }

  private prepareBarChartData(): ChartConfiguration<"bar">["data"] {
    return getBarChartData(this.dataTermPrincipalBalance, this.dataTermInterestBalance, this.colors, this.labelTerm);
  }

  private prepareBarChartOptions(): ChartConfiguration<"bar">["options"] {
    return getBarChartOptions;
  }

  private dataInit() {
    this.labelTerm = [];
    this.colors = [];
    this.dataTermInterestBalance = [];
    this.dataTermPrincipalBalance = [];

    Chart.defaults.color = 'white';

    if (this.infoData?.principalBalance && this.infoData?.paymentAmount && !this.infoData?.noAutoPayments) {

      const totalInterest = Math.round((this.infoData.amortizedBalance || this.infoData.creditLimit) * this.infoData.interestRate * 100.0) / 100.0;
      const totalNop = this.infoData.totalNumberOfPayments;
      const denominator = totalNop / 2 * (totalNop + 1);
      const termPayment = this.infoData.paymentAmount;

      let interestBalance = totalInterest;
      let principalBalance = this.infoData.amortizedBalance || this.infoData.creditLimit;

      for (let idx = 0; idx < totalNop; idx++) {
        const interestPayment = Math.round(totalInterest * (totalNop - idx) / denominator * 100.0) / 100.0;
        const principalPayment = termPayment - interestPayment;

        interestBalance -= interestPayment;
        principalBalance -= principalPayment;

        this.labelTerm.push("Payment " + (idx + 1));
        this.colors.push(idx === totalNop - this.infoData.numberOfPaymentsLeft ? "#E28F26" : "rgba(255, 255, 255, 0.87)");
        this.dataTermInterestBalance.push(interestBalance);
        this.dataTermPrincipalBalance.push(principalBalance);
      }
    }
  }
}
