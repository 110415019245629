  <mat-tab-group (selectedTabChange)="onTabChanged($event)" [selectedIndex]="selectedIndex"
    (selectedIndexChange)="selectedIndexChange($event)">
    <mat-tab label="DETAILS">
      <ng-template matTabContent>
        <div id="main" fxLayout="column" fxLayoutAlign="start start">
          <ifc-app-scroll-container>
            <div class="content" fxLayout.gt-md="row" fxLayoutAlign.gt-md="center start" fxLayoutGap.gt-md="48px"
              fxLayout="column" fxLayoutAlign="start center" fxFlexFill.lt-lg ngClass.lt-lg="fit-content">

              <!-- Form -->
              <form [formGroup]="form">
                <div fxLayout.gt-md="row wrap" fxLayoutGap.gt-md="24px" fxLayout="column" fxLayoutAlign="start start">
                  <ifb-card>
                    <div fxLayout="column" fxLayoutAlign="start start" ngClass="width-100-perc">

                      <ifc-account-loan-number-input [fc]="form.get('loanNumber')"
                        [readonly]="data.viewMode || !transactionNew && !canEditLoan || !readWriteTransaction"
                        (autocompleteOptionSelected)="accountUpdate($event)" (clearInput)="clear('entityName', true)"
                        [disabled]="data.viewMode || !readWriteTransaction" [includedStatus]="includedStatus"
                        [excludedStatus]="excludedStatus" [excludeLoansWithNoOwnerAssigned]="true" [excludeLoansWithResolvedCase]="true">
                      </ifc-account-loan-number-input>

                      <ifc-account-business-name-input [fc]="form.get('entityName')"
                        [readonly]="data.viewMode || !transactionNew && !canEditLoan || !readWriteTransaction"
                        (autocompleteOptionSelected)="accountUpdate($event)" (clearInput)="clear('loanNumber', true)"
                        [disabled]="data.viewMode || !readWriteTransaction" [includedStatus]="includedStatus"
                        [excludedStatus]="excludedStatus" [excludeLoansWithNoOwnerAssigned]="true" [excludeLoansWithResolvedCase]="true">
                      </ifc-account-business-name-input>

                      <div class="layout margin-bottom width-100-perc" *ngIf="editingSubtransactions || data.viewMode">
                        <table class="space-between-rows width-100-perc">

                          <tr class="row-header">
                            <td class="index"></td>
                            <td class="item-title secondary-text">Transaction type</td>
                            <td class="item-title secondary-text align-right">Amount</td>
                            <td class="index"></td>
                          </tr>

                          <tr class="row" formArrayName="subtransactions"
                            *ngFor="let subtransaction of subtransactions; index as i">
                            <ng-container [formGroupName]="i">

                              <td class="index">{{i+1}}</td>

                              <td class="type-select" *ngIf="!data.viewMode">
                                <mat-form-field floatLabel="never">
                                  <mat-select placeholder="Add type" [(value)]="subtransaction.type" formControlName="subType">
                                    <mat-option [value]="type.value" *ngFor="let type of subtransactionTypeNames">
                                      {{type.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                              <td class="type-view" *ngIf="data.viewMode">
                                <span [ngClass]="{'mat-light-grey': !subtransaction.display}">
                                  {{formatSubtransactionType(subtransaction.type)}}
                                </span>
                              </td>

                              <td class="amount align-right" *ngIf="!data.viewMode">
                                <mat-form-field floatLabel="never">
                                  <input matInput currencyMask [options]="{align : 'right', allowNegative: true}"
                                    type="text" [(ngModel)]="subtransaction.amount" formControlName="subAmount"
                                    (blur)="accountNewBalancesInitTransaction()">
                                </mat-form-field>
                              </td>

                              <td class="align-right" *ngIf="data.viewMode">
                                <span [ngClass]="{'mat-light-grey': !subtransaction.display}">
                                  {{subtransaction.amount | currency: '$'}}
                                </span>
                              </td>

                              <td class="delete" *ngIf="!data.viewMode">
                                <button type="button" mat-icon-button (click)="deleteRow(i)">
                                  <mat-icon class="delete-icon">delete</mat-icon>
                                </button>
                              </td>

                              <td class="index" *ngIf="data.viewMode"></td>

                            </ng-container>

                          </tr>

                          <tr class="row" *ngIf="!data.viewMode">
                            <td colspan="4" class="add-icon">
                              <button type="button" mat-icon-button (click)="insertRow()">
                                <mat-icon>add_circle</mat-icon>
                              </button>
                            </td>
                          </tr>

                          <tr class="row" *ngIf="data.viewMode">
                            <td class="index"></td>
                            <td>Total</td>
                            <td class="align-right">{{transactionPrincipal.displayAmount | currency:'$'}}</td>
                            <td class="index"></td>
                          </tr>

                        </table>
                      </div>

                      <mat-form-field appearance="outline" *ngIf="!editingSubtransactions && !data.viewMode">
                        <mat-label>Amount</mat-label>
                        <input matInput [required]="!editingSubtransactions" formControlName="amount" type="text"
                          placeholder="Amount" currencyMask (blur)="accountNewBalancesInit()" autocomplete="off">
                        <mat-hint *ngIf="account && accountInfo && isTransactionTypeDraw">Enter an amount between
                          {{accountInfo.minDrawAmount | currency:'$'}} and
                          {{accountInfo.availableFunds | currency:'$'}}.</mat-hint>
                        <mat-hint *ngIf="account && accountInfo && isTransactionTypePayment">Enter an amount between
                          {{accountInfo.minPaymentAmount | currency:'$'}} and
                          {{accountInfo.currentPayoffAmount | currency:'$'}}.</mat-hint>
                        <mat-error
                          *ngIf="account && isTransactionTypeDraw && (form.get('amount').hasError('min') || form.get('amount').hasError('max'))">
                          Enter
                          an amount between {{accountInfo.minDrawAmount | currency:'$'}} and
                          {{accountInfo.availableFunds | currency:'$'}}.</mat-error>
                        <mat-error
                          *ngIf="account && isTransactionTypePayment && (form.get('amount').hasError('min') || form.get('amount').hasError('max'))">
                          Enter an amount between {{accountInfo.minPaymentAmount | currency:'$'}} and
                          {{accountInfo.currentPayoffAmount | currency:'$'}}.
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Requested By</mat-label>
                        <mat-select matInput formControlName="requestedBy" [disabled]="data.viewMode || !readWriteTransaction || initiatorOptions.length === 0">
                            <mat-option *ngFor="let option of initiatorOptions" [value]="option">
                              {{option.name}}
                            </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select matInput formControlName="status" placeholder="Status"
                          [disabled]="data.viewMode || (transactionNew && !editingSubtransactions) || !readWriteTransaction">
                          <mat-option *ngFor="let option of transactionStatusOptions" [value]="option.value">
                            {{option.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <ifb-transaction-reject-input *ngIf="this.form.get('status').value === TransactionStatus.Rejected"
                        [disabled]="data.viewMode" [fc]="form.get('reasons')"
                        [usage]="FailureReasonUsage.ResidualUnderwriting">
                      </ifb-transaction-reject-input>

                      <ifb-transaction-reject-input *ngIf="this.form.get('status').value === TransactionStatus.Declined"
                        [disabled]="data.viewMode" [fc]="form.get('reasons')"
                        [usage]="FailureReasonUsage.NACHAResponse">
                      </ifb-transaction-reject-input>

                      <ifb-transaction-reject-input
                      *ngIf="(this.editingSubtransactions || transactionTypeName === TransactionTypeNames.Draw) && this.form.get('status').value === TransactionStatus.Pending"
                      [disabled]="data.viewMode" [fc]="form.get('reasons')"
                      [usage]="FailureReasonUsage.PendingReason">
                      </ifb-transaction-reject-input>

                      <ifb-competitors-select-search *ngIf="canReadCompetitors && form.get('status').value === TransactionStatus.Rejected && form.get('reasons').value?.some(allowToSpecifyCompetitors)"
                        [disabled]="data.viewMode || (transactionNew && !editingSubtransactions) || !readWriteTransaction"
                        [selectedCompetitorIds]="transactionPrincipal.competitors"
                        (competitorsChange)="onCompetitorsChange($event)">
                      </ifb-competitors-select-search>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="24px">
                        <ifc-datepicker class="form-field-custom-xs" [fc]="form.get('date')"
                          [label]="'Transaction date'"
                          [disabled]="data.viewMode || !canEditDates || !readWriteTransaction"
                          [min]="!canSetTransactionDateInThePast ? minDate : null"
                          (blured)="clearMinDateValidationIfNotDirty(form.get('date'))"
                          (clearInput)="clear('date', false, 'time')">
                        </ifc-datepicker>

                        <mat-form-field class="form-field-custom-xs" appearance="outline">
                          <mat-label>Transaction time</mat-label>
                          <input matInput formControlName="time" type="text" placeholder="Transaction time"
                            [value]="form.get('date').value | date: 'shortTime'" readonly>
                          <button type="button" mat-button matSuffix mat-icon-button (click)="pickTime('date', 'time')"
                            [disabled]="data.viewMode || disabledTimePicker || !readWriteTransaction">
                            <mat-icon>access_time</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                    </div>

                  </ifb-card>
                  <ifb-card *ngIf="!transactionNew || editingSubtransactions">
                    <div class="dates-container">
                      <div fxLayout="row" fxLayoutAlign="start strech" fxLayoutGap="24px" fxLayoutGap.xs=""
                        fxLayout.xs="column">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="24px">
                          <ifc-datepicker class="form-field-custom-xs" [fc]="form.get('underwritingDate')"
                            [label]="'UW date'" [disabled]="data.viewMode || !canEditDates || !readWriteTransaction"
                            (clearInput)="clear('underwritingDate', false, 'underwritingTime')">
                          </ifc-datepicker>
                        </div>

                        <mat-form-field appearance="outline" class="form-field-custom-xs">
                          <mat-label>UW time</mat-label>
                          <input matInput formControlName="underwritingTime" type="text" placeholder="UW time"
                            [value]="form.get('underwritingDate').value | date: 'shortTime'" readonly>
                          <button type="button" mat-button matSuffix mat-icon-button
                            (click)="pickTime('underwritingDate', 'underwritingTime')"
                            [disabled]="data.viewMode || disabledTimePicker || !readWriteTransaction">
                            <mat-icon>access_time</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="24px">
                        <ifc-datepicker class="form-field-custom-xs" [fc]="form.get('financeDate')"
                          [label]="'Finance date'" [disabled]="data.viewMode || !canEditDates || !readWriteTransaction"
                          (clearInput)="clear('financeDate', false, 'financeTime')">
                        </ifc-datepicker>

                        <mat-form-field class="form-field-custom-xs" appearance="outline">
                          <mat-label>Finance time</mat-label>
                          <input matInput formControlName="financeTime" type="text" placeholder="Finance time"
                            [value]="form.get('financeDate').value | date: 'shortTime'" readonly>
                          <button type="button" mat-button matSuffix mat-icon-button
                            (click)="pickTime('financeDate', 'financeTime')"
                            [disabled]="data.viewMode || disabledTimePicker || !readWriteTransaction">
                            <mat-icon>access_time</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="24px">
                        <ifc-datepicker class="form-field-custom-xs" [fc]="form.get('processDate')"
                          [label]="'Processed date'"
                          [disabled]="data.viewMode || !canEditDates || !readWriteTransaction"
                          (clearInput)="clear('processDate', false, 'processTime')">
                        </ifc-datepicker>

                        <mat-form-field class="form-field-custom-xs" appearance="outline">
                          <mat-label>Processed time</mat-label>
                          <input matInput formControlName="processTime" type="text" placeholder="Processed time"
                            [value]="form.get('processDate').value | date: 'shortTime'" readonly>
                          <button type="button" mat-button matSuffix mat-icon-button
                            (click)="pickTime('processDate', 'processTime')"
                            [disabled]="data.viewMode || disabledTimePicker || !readWriteTransaction">
                            <mat-icon>access_time</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="24px">
                        <ifc-datepicker class="form-field-custom-xs" [fc]="form.get('completeDate')"
                          [label]="'Complete date'" [disabled]="data.viewMode || !canEditDates || !readWriteTransaction"
                          (clearInput)="clear('completeDate', false, 'completeTime')">
                        </ifc-datepicker>

                        <mat-form-field appearance="outline" class="form-field-custom-xs">
                          <mat-label>Complete time</mat-label>
                          <input matInput formControlName="completeTime" type="text" placeholder="Complete time"
                            [value]="form.get('completeDate').value | date: 'shortTime'" readonly>
                          <button type="button" mat-button matSuffix mat-icon-button
                            (click)="pickTime('completeDate', 'completeTime')"
                            [disabled]="data.viewMode || disabledTimePicker || !readWriteTransaction">
                            <mat-icon>access_time</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="24px">
                        <ifc-datepicker class="form-field-custom-xs" [fc]="form.get('cancellationDate')"
                          [label]="'Cancellation date'"
                          [disabled]="data.viewMode || !canEditDates || !readWriteTransaction"
                          (clearInput)="clear('cancellationDate', false, 'cancellationTime')">
                        </ifc-datepicker>

                        <mat-form-field class="form-field-custom-xs" appearance="outline">
                          <mat-label>Cancellation time</mat-label>
                          <input matInput formControlName="cancellationTime" type="text" placeholder="Cancellation time"
                            [value]="form.get('cancellationDate').value | date: 'shortTime'" readonly>
                          <button type="button" mat-button matSuffix mat-icon-button
                            (click)="pickTime('cancellationDate', 'cancellationTime')"
                            [disabled]="data.viewMode || disabledTimePicker || !readWriteTransaction">
                            <mat-icon>access_time</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div fxLayout="column" fxLayoutGap="0px" class="form-field-custom-xl" *ngIf="editingSubtransactions">
                        <mat-form-field appearance="outline">
                          <mat-label>Promotion</mat-label>
                          <mat-select matInput multiple formControlName="promotion" placeholder="Promotion" [(ngModel)]="selectedPromotions">
                            <mat-option *ngFor="let option of promotionsOptions" [value]="option.id">{{option.exclusivePromotion ? '*' : ''}}{{option.name}}</mat-option>
                          </mat-select>
                          <button type="button" *ngIf="form.get('promotion').value" mat-button matSuffix mat-icon-button aria-label="Clear"
                            (click)="form.get('promotion').setValue(null)"
                            [disabled]="data.viewMode || !readPromotions">
                            <mat-icon>close</mat-icon>
                          </button>
                          <mat-hint>* Exclusive promotions cannot be combined with other promotions</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </ifb-card>

                </div>
              </form>
              <!-- End Form -->

              <div fxLayout.gt-md="{{!transactionNew || editingSubtransactions ? 'column' : 'row'}}"
                fxLayoutAlign.gt-md="start start"
                fxLayoutGap.gt-md="{{!transactionNew || editingSubtransactions ? '32px' : '48px'}}" fxLayout="column"
                fxLayoutAlign="start center">

                <!-- Transfer details -->
                <ifb-card>
                  <mat-card class="mat-elevation-z0 width-100-perc">
                    <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
                      <span class="card-title">Transfer details</span>
                    </div>
                    <mat-card-content>
                      <mat-list>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>Transfer from</span>
                          <span class="item-content"> {{transferFrom || 'Null'}}</span>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>Transfer to</span>
                          <span class="item-content"> {{transferTo || 'Null'}}</span>
                        </mat-list-item>
                        <div *ngIf="transactionNew && (transactionTypeName !== TransactionTypeNames.Draw)">
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>Principal payment</span>
                            <span class="item-content">{{(paymentPrincipal | currency:'$') || 'Null'}}</span>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>Interest payment</span>
                            <span class="item-content">{{(paymentInterest | currency:'$') || 'Null'}}</span>
                          </mat-list-item>
                          <mat-divider *ngIf="promotionToApplyInfo?.promotionType === PromotionType.ReAmortization"></mat-divider>
                          <mat-list-item *ngIf="promotionToApplyInfo?.promotionType === PromotionType.ReAmortization">
                            <span class="item-title secondary-text">Loan will be re-amortized after payment is made</span>
                          </mat-list-item>
                          <mat-divider *ngIf="promotionToApplyInfo?.promotionType === PromotionType.PayoffInterestReduction"></mat-divider>
                          <mat-list-item *ngIf="promotionToApplyInfo?.promotionType === PromotionType.PayoffInterestReduction">
                            <span class="item-title secondary-text" mat-line >Payoff interest reducement</span>
                            <span class="item-content">{{(promotionToApplyInfo.interestRateReducement | currency:'$') || 'Null'}}</span>
                          </mat-list-item>
                        </div>
                        <div *ngIf="transactionNew && (transactionTypeName !== TransactionTypeNames.Payment)">
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>Disbursed amount</span>
                            <span class="item-content">{{(disbursedAmount | currency:'$') || 'Null'}}</span>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>Draw fee</span>
                            <span class="item-content">{{(drawFee | currency:'$') || 'Null'}}</span>
                          </mat-list-item>
                          <mat-divider *ngIf="promotionToApplyInfo && promotionToApplyInfo !== null"></mat-divider>
                          <mat-list-item *ngIf="promotionToApplyInfo && promotionToApplyInfo !== null">
                          <span class="item-title secondary-text" mat-line >Promotional fee reducement</span>
                          <span class="item-content">{{(promotionToApplyInfo.drawFeeReducement | currency:'$') || 'Null'}}</span>
                        </mat-list-item>
                        </div>
                        <div *ngIf="transactionNew">
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="card-sub-header">New account balances</span>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>New outstanding balance</span>
                            <span class="item-content">
                              {{(accountNewBalances.balanceOutstanding | currency:'$') || 'Null'}}
                            </span>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>New available funds</span>
                            <span
                              class="item-content">{{(accountNewBalances.fundsAvailable | currency:'$') || 'Null'}}</span>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>New payment due</span>
                            <span
                              class="item-content">{{(accountNewBalances.paymentDue | currency:'$') || 'Null'}}</span>
                          </mat-list-item>
                          <mat-divider></mat-divider>
                          <mat-list-item>
                            <span class="item-title secondary-text" mat-line>Payment due date</span>
                            <span class="item-content">{{(accountNewBalances.paymentDueDate | date) || 'Null'}}</span>
                          </mat-list-item>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>Processed date</span>
                          <span class="item-content">{{(transactionPrincipal.processDate | date) || 'Null'}}</span>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>Initiated by</span>
                          <span class="item-content">{{initiatorName || 'Null'}}</span>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>Created by</span>
                          <span class="item-content">{{transactionPrincipal.creator || 'Null'}}</span>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>Modified by</span>
                          <span class="item-content">{{transactionPrincipal.modifier || 'Null'}}</span>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>Maintained</span>
                          <span
                            class="item-content">{{(transactionPrincipal.maintained ? 'Yes' : 'No') || 'Null'}}</span>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                          <span class="item-title secondary-text" mat-line>ID</span>
                          <span class="item-content">{{transactionPrincipal.id || 'Null'}}</span>
                        </mat-list-item>
                      </mat-list>
                    </mat-card-content>
                  </mat-card>
                </ifb-card>
                <!-- End Transfer details -->

                <!-- Notes -->
                <ifb-card>
                  <ifb-note-card [fc]="form.get('note')" [notes]="transactionPrincipal.notes"
                    [initiator]="data.userCurrent" [ngClass.lt-lg]="'layout margin-top'">
                  </ifb-note-card>
                </ifb-card>
                <!-- End Notes -->

              </div>
            </div>
          </ifc-app-scroll-container>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="BANK TRANSACTIONS" *ngIf="canViewBankTransactions">
      <ng-template matTabContent>
        <ifb-transaction-bank-transactions [account]="account" [bankAccountId]="bankAccountId"
          [postedDateTo]="transactionPrincipal.underwritingDate" [active]="true"></ifb-transaction-bank-transactions>
      </ng-template>
    </mat-tab>

    <mat-tab label="AUDIT TRAIL" *ngIf="canViewAudit">
      <ng-template matTabContent>
        <ifb-transaction-audit-trail></ifb-transaction-audit-trail>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
