import { Injectable } from "@angular/core";
import { BrokerApiKeyEditData, CreateBrokerApiKeyData } from "common";

@Injectable()
export class BrokerApiKeyDetailsFacade {
  constructor() {}

  prepareCreateRequest(data: BrokerApiKeyEditData, brokerId: number): CreateBrokerApiKeyData {
    return {
      active: data.active,
      brokerId: brokerId,
      description: data.description,
      name: data.name
    }
  }
}
