import { Component, Input } from '@angular/core';
import { BankVerificationLabels } from '../bank.model';

@Component({
  selector: 'ifb-bank-account-verification-icon-chip',
  templateUrl: './bank-account-verification-icon-chip.component.html',
  styleUrls: ['./bank-account-verification-icon-chip.component.css']
})
export class BankAccountVerificationIconChipComponent {

  constructor() { }

  get statusLabels() { return BankVerificationLabels; }

  @Input()
  status = 0;

}
