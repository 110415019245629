import { Injectable } from '@angular/core';
import { UserData } from './user.model';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { OAuthService } from 'common';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private settings: AppSettings, private oauthService: OAuthService) { }

  private _current = this.http.get<UserData>(`${this.settings.oauth.url}/api/user`).pipe(share());

  get current() {
    return this.oauthService.authenticated ? this._current : of(null);
  }
}
