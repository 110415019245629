import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  subscription: Subscription;

  constructor(private router: Router) {}

  public init() {
    if (!this.subscription) {
      this.subscription = this.router.events.subscribe(this.routerEventHandler.bind(this));
    }
  }

  add(url: string) {
    if (!url || url === '/') return;
    const history = this.history;
    const newUrl = !history.length || history[history.length - 1] !== url;
    const ignoredUrl = this.urlsIgnored.some(ignored => url.startsWith(ignored));
    if (newUrl && !ignoredUrl) {
      history.push(url);
    }
    this.history = history;
  }

  get history(): any[] {
    let history = [];
    const historySessionString = sessionStorage.getItem('navigation.history');
    if (historySessionString) {
      try {
        history = JSON.parse(historySessionString);
        if (!Array.isArray(history)) {
          history = [];
        }
      } catch (e) {
        console.error(e);
      }
    }
    return history;
  }

  set history(history: any[]) {
    if (!history || !Array.isArray(history)) {
      history = [];
    }
    if (history.length > this.historyLengthMax) {
      history.splice(0, history.length - this.historyLengthMax);
    }
    sessionStorage.setItem('navigation.history', JSON.stringify(history));
  }

  historyLengthMax = 20;

  navigationEndHandler(event: NavigationStart) {
    this.add(event.url);
  }

  routerEventHandler(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      this.navigationEndHandler(event);
    }
  }

  urlsIgnored = ['/sign-in', '/sign-out', '/mfa'];

  get urlPrevious() {
    const history = this.history;
    return history.length ? history[history.length - 1] : undefined;
  }

  get urlPreviousAfterInit() {
    const history = this.history;
    return history.length ? history[history.length - 2] : undefined;
  }
}
