<div fxLayout="column" fxLayoutAlign="stretch">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="end center" fxFlexFill>
      <div fxHide.xs class="layout padding-right-md">
        <button *ngIf="canSynchronizeBankAccounts && showSyncButton" mat-icon-button (click)="sync()"
          class="secondary-text" matTooltip="Synchronize">
          <mat-icon>sync</mat-icon>
        </button>
        <button *ngIf="canWriteBankAccount" mat-icon-button (click)="addBankAccountLink()" class="secondary-text"
          matTooltip="Add bank account">
          <mat-icon>add_cicrcle</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button *ngIf="canSynchronizeBankAccounts && showSyncButton" mat-menu-item (click)="sync()">
            <mat-icon>sync</mat-icon>
            <span>Synchronize accounts</span>
          </button>
          <button *ngIf="canWriteBankAccount" mat-menu-item (click)="addBankAccountLink()">
            <mat-icon>add_cicrcle</mat-icon>
            <span>Add bank account</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifb-account-notes-sidenav [accountId]="data.account?.id" [notes]="data.account?.notes" [initiator]="data.userCurrent"
    [saveOnDemand]="true" [innerSidenavAndTabbed]="true" [hiddenButton]="!data.account" *ngIf="data.account">
    <ng-container *ngTemplateOutlet="main"></ng-container>
  </ifb-account-notes-sidenav>

  <ifb-application-notes-sidenav [applicationId]="data.application?.id" [notes]="data.application?.notes"
    [initiator]="data.userCurrent" [saveOnDemand]="true" [innerSidenavAndTabbed]="true"
    [hiddenButton]="!data.application" *ngIf="data.application">
    <ng-container *ngTemplateOutlet="main"></ng-container>
  </ifb-application-notes-sidenav>

  <div *ngIf="!data.account && !data.application && data.entity">
    <ng-container *ngTemplateOutlet="main"></ng-container>
  </div>

  <ng-template #main>
    <ifc-app-scroll-container [default]="true" [@routerTransition]="'fly'">
      <div class="table-container">

        <div class="message-box info" *ngIf="!data.bankAccountQueryResult?.totalCount" fxLayout="row" fxLayoutGap="12px"
          fxLayoutAlign="start center">
          <mat-icon class="icon">info</mat-icon>
          <span>No data found</span>
        </div>

        <table mat-table *ngIf="data.bankAccountQueryResult?.totalCount" [dataSource]="dataSource">

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <div *ngFor="let icon of getRoleIcons(row)" class="avatar medium mat-light-grey-bg">{{icon}}</div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="bankLinkingStatus">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              <div [ngClass]="getBankLinkingStatusAvatarClass(row)">
                <mat-icon [inline]="true" class="small" matTooltip="{{getBankLinkingStatusTooltip(row)}}">
                  {{getBankLinkingStatusAvatarIcon(row)}}</mat-icon>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="verificationStatus">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              <div *ngIf="row.isVerifiable && canReadBankAccountVerificationStatus" [ngClass]="getBankVerificationAvatarClass(row)">
                <mat-icon matTooltip="{{getBankVerificationTooltip(row)}}" [inline]="true" class="small">
                  {{getBankVerificationAvatarIcon(row)}}</mat-icon>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="bankName">
            <th mat-header-cell *matHeaderCellDef> Bank name </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              {{row.bankName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="bankAccountType">
            <th mat-header-cell *matHeaderCellDef> Account type </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              {{getBankAccountType(row.accountType)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="accountNumber">
            <th mat-header-cell *matHeaderCellDef> Account number </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              {{row.accountNumber}}
            </td>
          </ng-container>

          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef> Balance </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              {{(row.balance | currency: '$') || '-'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ownerName">
            <th mat-header-cell *matHeaderCellDef>Account owner</th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              {{row.syncedBankAccount?.ownerName || '-'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="verificationDate">
            <th mat-header-cell *matHeaderCellDef> Verification date </th>
            <td mat-cell *matCellDef="let row" class="table-cell" (click)="bankAccountDetailsLink(row.id)">
              {{(row.lastCheckDate | date) || '-'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="edit" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row" class="table-cell right-align">
              <button mat-icon-button *ngIf="data.account && canWriteBankAccount" [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{account: row}" class="menu-button">
                <mat-icon>more_vert</mat-icon>
              </button>
            </td>
          </ng-container>

          <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-account="account">
              <button mat-menu-item (click)="bankAccountEditLink(account.id)">Edit</button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="setTransferAccount(BankAccountRole.Disbursement, account)">
                Set as disbursement bank</button>
              <button mat-menu-item (click)="setTransferAccount(BankAccountRole.Payment, account)">
                Set as payment bank</button>
            </ng-template>
          </mat-menu>

          <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
        </table>
      </div>
    </ifc-app-scroll-container>

    <div [hidden]="data?.bankAccountQueryResult?.totalCount <= 10">
      <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
    </div>
  </ng-template>
</div>