import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LogQueryResult, LogLevel, LogQueryParams, Log } from '../log.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { LogService } from '../log.service';

@Component({
  selector: 'ifb-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss']
})
export class LogListComponent implements OnInit, OnDestroy, OnChanges {

  private _unsubscribeAll: Subject<any>;
  private pageChangedSubscription: Subscription;

  data: LogListComponentData;
  queryParams: any;
  canReadWriteLog = false;
  expanded = [];
  tableColumns: string[] = ['log'];

  get LogLevel() { return LogLevel; }

  @Input()
  expandAll: boolean;

  @Input()
  pageChanged: Observable<void>;

  @Output()
  archived = new EventEmitter<any>();

  @Output()
  filtered = new EventEmitter<LogQueryParams>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logService: LogService,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: LogListComponentData) => this.data = it);

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => { this.queryParams = it; });

    this.userPermissionService.granted([readWrite('admin-logs')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canReadWriteLog = res);

    this.pageChangedSubscription = this.pageChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => this.expanded = []);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data && this.data.logQueryResult && this.data.logQueryResult.totalCount)
      for (let index = 0; index < this.data.logQueryResult.values.length; index++) {
        this.expanded[index] = this.expandAll;
      }
  }

  archive(id: number) {
    this.logService.archive(id).subscribe(() => {
      this.archived.emit();
    });
  }

  filter(property: string, value: string) {
    const params: LogQueryParams = {};
    params[property] = value;
    this.filtered.emit(params);
  }

  substring(input: string): string {
    return (!input || input === '' || input.length < 8)
      ? input
      : (input.startsWith('"') ? input.substring(input.length - 8) : input.substring(input.length - 8));
  }

  detailsLink(type: string, id: number) {
    this.router.navigate([`/${type}/${id}/profile`]);
  }

  logDetailsLink(id: number) {
    return `/logs/${id}`;
  }

  getLevelIcon(level: any) {
    switch (level) {
      case LogLevel.Fatal:
        return 'report';
      case LogLevel.Error:
        return 'error';
      case LogLevel.Warn:
        return 'warning';
      case LogLevel.Debug:
        return 'bug_report';
      case LogLevel.Info:
        return 'info';
      default:
        break;
    }
  }

  getLevelIconAvatarClass(level: LogLevel) {
    switch (level) {
      case LogLevel.Fatal:
      case LogLevel.Error:
        return 'mat-red-icon';
      case LogLevel.Warn:
        return 'mat-orange-icon';
      case LogLevel.Debug:
        return 'mat-gray-icon';
      case LogLevel.Info:
        return 'mat-indigo-icon';
      default:
        break;
    }
  }

  hasException(log: Log) {
    return log.level === LogLevel.Error && (log.exception && log.exception !== '');
  }

  hasMultipleLines(message: string) {
    return message.includes('\n') || this.isJson(message);
  }

  toggle(i: number) {
    this.expanded[i] = !this.expanded[i];
  }

  isJson(message: string) {
    return message !== null && message.startsWith('{');
  }

  getJsonMessage(text: any): string {
    return this.isJson(text) ? JSON.parse(text) : text;
  }

}

export interface LogListComponentData {
  logQueryResult?: LogQueryResult;
}
