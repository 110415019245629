import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { AccountQueryParams, AppBarExpandedTab, BankAccount, EntityQueryParams, EntityQueryResult, parseNumber } from "common";
import { BrokerExData, BrokerQueryResult, brokerAppBarExpandedTabs, BrokerQueryParams } from "./broker.model";
import { BrokerService } from "./broker.service";
import { UserPermissionService } from "../user/user-permission/user-permission.service";
import { EntityService } from "../entity/entity.service";
import { AccountQueryResult } from "../account/account.model";
import { AccountService } from "../account/account.service";
import { BankAccountsListGroupedByInstitutionData } from "../bank/bank.model";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Injectable({
    providedIn: 'root'
})
export class BrokerResolver implements Resolve<BrokerExData> {
    constructor(private _service: BrokerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BrokerExData> | Promise<BrokerExData> | BrokerExData {
        return route.params.id === 'new'
            ? undefined
            : this._service.get(parseNumber(route.params.id));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrokerQueryResolver implements Resolve<BrokerQueryResult> {
    constructor(private _service: BrokerService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<BrokerQueryResult>
        | Promise<BrokerQueryResult> | BrokerQueryResult {
        const params = new BrokerQueryParams(this.queryParamsService.init(route.queryParams));

        if (params.establishedDateStart) {
            params.establishedDateStart = new Date(params.establishedDateStart);
            params.establishedDateStart.setHours(0, 0, 0, 0);
        }

        if (params.establishedDateEnd) {
            params.establishedDateEnd = new Date(params.establishedDateEnd);
            params.establishedDateEnd.setHours(23, 59, 59, 999);
        }

        if (params.contractDateStart) {
            params.contractDateStart = new Date(params.contractDateStart);
            params.contractDateStart.setHours(0, 0, 0, 0);
        }

        if (params.contractDateEnd) {
            params.contractDateEnd = new Date(params.contractDateEnd);
            params.contractDateEnd.setHours(23, 59, 59, 999);
        }

        if (params.createdOnStart) {
            params.createdOnStart = new Date(params.createdOnStart);
            params.createdOnStart.setHours(0, 0, 0, 0);
        }

        if (params.createdOnEnd) {
            params.createdOnEnd = new Date(params.createdOnStart);
            params.createdOnEnd.setHours(23, 59, 59, 999);
        }

        if (params.updatedOnStart) {
            params.updatedOnStart = new Date(params.updatedOnStart);
            params.updatedOnStart.setHours(0, 0, 0, 0);
        }

        if (params.updatedOnEnd) {
            params.updatedOnEnd = new Date(params.updatedOnEnd);
            params.updatedOnEnd.setHours(23, 59, 59, 999);
        }

        return this._service.query(params);
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrokerAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            brokerAppBarExpandedTabs(route.params.id).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrokerEntityQueryResolver implements Resolve<EntityQueryResult> {
    constructor(private _service: EntityService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<EntityQueryResult> | Promise<EntityQueryResult> | EntityQueryResult {

        const params = new EntityQueryParams(this.queryParamsService.init(route.queryParams));

        if (params.createdOnStart) {
            params.createdOnStart = new Date(params.createdOnStart);
            params.createdOnStart.setHours(0, 0, 0, 0);
        }

        if (params.createdOnEnd) {
            params.createdOnEnd = new Date(params.createdOnEnd);
            params.createdOnEnd.setHours(23, 59, 59, 999);
        }

        if (params.updatedOnStart) {
            params.updatedOnStart = new Date(params.updatedOnStart);
            params.updatedOnStart.setHours(0, 0, 0, 0);
        }

        if (params.updatedOnEnd) {
            params.updatedOnEnd = new Date(params.updatedOnEnd);
            params.updatedOnEnd.setHours(23, 59, 59, 999);
        }

        params.brokerId = parseNumber(route.params.id);

        return this._service.query(params);
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrokerAccountQueryResolver implements Resolve<AccountQueryResult> {
    constructor(private service: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountQueryResult> | Promise<AccountQueryResult> | AccountQueryResult {
        return this.service.query(new AccountQueryParams(this.queryParamsService.init({ brokerId: route.params.id, ...route.queryParams})));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrokerBankAccountListGroupedByInstitutionResolver implements Resolve<BankAccountsListGroupedByInstitutionData[]> {
    constructor(private service: BrokerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        // eslint-disable-next-line max-len
        Observable<BankAccountsListGroupedByInstitutionData[]> | Promise<BankAccountsListGroupedByInstitutionData[]> | BankAccountsListGroupedByInstitutionData[] {
        return this.service.getBrokerBankAccountListGrouped(parseNumber(route.params.id));
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrokerBankAccountResolver implements Resolve<BankAccount> {
    constructor(private service: BrokerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<BankAccount> | Promise<BankAccount> | BankAccount {
        return route.params.bankAccountId === 'new'
            ? undefined
            : this.service.getBrokerBankAccount(parseNumber(route.params.id), parseNumber(route.params.bankAccountId));
    }
}
