<mat-card class="ifc-card" [ngClass.xs]="'xs'">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
      <div class="card-title">Welcome call</div>
    </div>
  
    <mat-card-content class="layout padding-md">
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">
        <mat-list class="activation-list">
          <mat-list-item>
            <span class="secondary-text" mat-line>Required</span>
            <p class="item-content">{{welcomeCallData?.required === true ? 'Yes' : 'No'}}</p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="secondary-text" mat-line>Complete</span>
            <p class="item-content">{{welcomeCallData?.isComplete === true ? 'Yes' : 'No'}}</p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="secondary-text" mat-line>Completed on</span>
            <p class="item-content">{{(welcomeCallData?.completedOn | date ) || '-'}}</p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <span class="secondary-text" mat-line>RingDNA link</span>
            <div class="item-content">
                <button mat-icon-button *ngIf="welcomeCallData?.ringDnaLink" aria-label="Copy" (click)="copy(welcomeCallData?.ringDnaLink)">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
</mat-card>
