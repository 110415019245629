import { Injectable } from "@angular/core";
import { BrokerUsersRemote } from "../../infrastructure/broker-users.remote";
import {
  BrokerUserData,
  BrokerUsersQueryParams,
  BrokerUsersQueryResult,
  CreateBrokerUserRequest,
  UpdateBrokerUserRequest,
  UpdateBrokerUserStatusRequest,
} from "../models/broker-user.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BrokerUserFacade {
  constructor(
    private readonly remote: BrokerUsersRemote
  ) {}

  updateBrokerUserStatus(
    request: UpdateBrokerUserStatusRequest
  ): Observable<BrokerUserData> {
    return this.remote.update({
      id: request.id,
      active: request.active,
    });
  } 

  updateBrokerUser(
    request: UpdateBrokerUserRequest
  ): Observable<BrokerUserData> {
    return this.remote.update({...request});
  }

  createBrokerUser(
    request: CreateBrokerUserRequest
  ): Observable<BrokerUserData> {
    return this.remote.create({...request});
  }

  getBrokerUsers(
    params: BrokerUsersQueryParams
  ): Observable<BrokerUsersQueryResult> {
    return this.remote.query(params);
  }

  getBrokerUserById(id: number) : Observable<BrokerUserData> {
    return this.remote.get(id);
  }

  resendInvitation(id: number): Observable<void> {
    return this.remote.resendInvitation(id);
  }
}
