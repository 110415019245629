import { Component, Input, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LoanStatus } from 'common';

@Component({
  selector: 'ifb-account-status',
  templateUrl: './account-status.component.html',
  styleUrls: ['./account-status.component.css']
})
export class AccountStatusComponent implements AfterViewChecked {

  loanStatus = LoanStatus;

  @Input()
  allowedLoanStatuses: LoanStatus[] = [];

  @Input()
  fcStatus: UntypedFormControl;

  @Input()
  fcFreezeForDays: UntypedFormControl;

  @Input()
  fcFrozenUntil: UntypedFormControl;

  updatingfreezeForDays = false;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
    this.fcFrozenUntil.valueChanges.subscribe(input => {
      if (this.updatingfreezeForDays)
        return;
      this.updatingfreezeForDays = true;
      if (input) {
        const today = new Date();
        const frozenUntil = new Date(input);
        frozenUntil.setHours(today.getHours());
        frozenUntil.setMinutes(today.getMinutes());
        frozenUntil.setSeconds(today.getSeconds());
        
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        const diffDays = Math.round((frozenUntil.getTime() - today.getTime()) / oneDay);
        this.fcFreezeForDays.setValue(diffDays);
        this.fcFreezeForDays.markAsDirty();
      } else {
        this.fcFreezeForDays.setValue(null);
        this.fcFreezeForDays.markAsDirty();
      }
      this.updatingfreezeForDays = false;
    });

    this.fcFreezeForDays.valueChanges.subscribe(input => {
      if (this.updatingfreezeForDays)
        return;
      this.updatingfreezeForDays = true;
      if (input) {
        const freezeForDays = input;
        const today = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const freezeFor = oneDay * freezeForDays;
        const frozenUntil = new Date(today.getTime() + freezeFor);

        this.fcFrozenUntil.setValue(frozenUntil);
        this.fcFrozenUntil.markAsDirty();
      } else {
        this.fcFrozenUntil.setValue(null);
        this.fcFrozenUntil.markAsDirty();
      }
      this.updatingfreezeForDays = false;
    });
  }
}
