import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BankingCustomer } from '../banking.model';
import { AppPageService, SyncedBankAccount } from 'common';

@Component({
  selector: 'ifb-banking-customer-app-bar-title',
  templateUrl: './banking-customer-app-bar-title.component.html',
  styleUrls: ['./banking-customer-app-bar-title.component.scss']
})
export class BankingCustomerAppBarTitleComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  title: string;
  bankAccountName: string;

  constructor(private route: ActivatedRoute,
    private appPageService: AppPageService,
    private router: Router) {
    this._unsubscribeAll = new Subject();
  }

  backToBankCustomerList() {
    this.router.navigate([`/banking`]);
  }

  backToBankCustomerBankAccountList() {
    this.appPageService.back();
  }

  data: BankCustomerAppBarTitleComponentData;

  dataSet(data: BankCustomerAppBarTitleComponentData) {
    this.data = data;
    this.title = data.bankingCustomer ? `${data.bankingCustomer.name || ''}` : '';
    this.bankAccountName = data.bankingAccount
      ? `${data.bankingAccount.institutionName + ' -' || ''} ${data.bankingAccount.name || ''}` : '';
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}

export interface BankCustomerAppBarTitleComponentData {
  bankingAccount?: SyncedBankAccount;
  bankingCustomer?: BankingCustomer;
}
