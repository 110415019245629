import { Injectable } from "@angular/core";
import { AppSidenavItem } from "./app-sidenav.data";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppSidenavService {
  private _items: AppSidenavItem[] = [];

  get items(): AppSidenavItem[] {
    return this.items;
  }
  set items(value: AppSidenavItem[]) {
    this._items = value;
    this.itemsSubject.next(value);
  }

  private itemsSubject = new Subject<AppSidenavItem[]>();
  get itemsChange() {
    return this.itemsSubject;
  }
}
