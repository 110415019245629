import { trigger, animate, style, transition, keyframes } from '@angular/animations';

export const routerTransition = [
    trigger('routerTransition', [
        transition("void => fly", [
            style({ transform: 'translateX(100%)' }),
            animate('0.4s ease-in-out', style({ transform: 'translateX(0%)' }))
        ])
    ]),
    trigger('routerTransition', [
        transition("void => fade", [
            animate(300, keyframes([
                style({ opacity: 0 }),
                style({ opacity: 1 })
            ]))
        ])
    ])
];