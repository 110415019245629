import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { LoanStatus } from 'common';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { AccountStateWidgetData, AccountData } from '../account.model';
import { AccountService } from '../account.service';
import { getBarChartData, getBarChartOptions } from './account-state-widget-bar-chart-data';
import { ProductCode } from '../account.model';

@Component({
  selector: 'ifb-account-state-widget',
  templateUrl: './account-state-widget.component.html',
  styleUrls: ['./account-state-widget.component.scss']
})
export class AccountStateWidgetComponent implements OnInit, OnChanges {

  @Input() accountState: AccountStateWidgetData;
  @Input() account: AccountData;
  @Input() label: string;
  @Input() edit: boolean;

  barChartData: ChartConfiguration<"bar">["data"] = null;
  barChartOptions: ChartConfiguration<"bar">["options"] = null;

  isLCF: boolean;

  get LoanStatus() { return LoanStatus };
  accessNPLEconomics: boolean;

  constructor(private accountService: AccountService, private userPermissionService: UserPermissionService) { }

  ngOnInit(): void {
    this.isLCF = this.account.productCode === ProductCode['Case Financing'];

    this.userPermissionService.granted([readWrite('servicing-account-npl-economics')])
      .subscribe(res => this.accessNPLEconomics = res);
  }

  ngOnChanges(): void {
    this.initChart();
  }

  private initChart() {
    this.barChartData = getBarChartData([this.accountState?.utilization], [this.label]);
    this.barChartOptions = getBarChartOptions;
  }

  getOutstandingBalance(): number {
    return this.accountService.getOutstandingBalance(this.account);
  }

  isNPLLoan(): boolean{
    return this.accountService.isNPLLoan(this.account?.status);
  }

}
