import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppPageService, FormHelper } from 'common';
import { AccountData, ProductCode, LoanOtherInfoUpdateDto } from '../account.model';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService, AppBarActionsService, MessageService, AppBarAction, DialogConfirm } from 'common';
import { AccountService } from '../account.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'ifb-account-profile-other-info-details',
  templateUrl: './account-profile-other-info-details.component.html',
  styleUrls: ['./account-profile-other-info-details.component.scss']
})

export class AccountOtherInfoDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  form: UntypedFormGroup;
  data: AccountOtherInfoDetailsComponentData;

  get productCode() { return ProductCode; }

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private messageService: MessageService,
    private dialog: MatDialog) {

    this._unsubscribeAll = new Subject();

    this.formInit();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: !this.form.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  dataInit(data: AccountOtherInfoDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = `${this.data.account.loanNumber} - Other info`;

    this.form.reset({
      description: this.data.account.description,
      externalId: this.data.account.externalId,
      policyNumber: this.data.account.policyNumber,
      policyLimit: this.data.account.policyLimit,
      loanNumber: this.data.account.loanNumber,
      uniqueId: this.data.account.uniqueId,
      applicationNumber: this.data.account.applicationNumber,
      applicationUniqueId: this.data.account.applicationUniqueId
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  private formInit() {
    this.form = this.formBuilder.group({
      description: [undefined, Validators.compose([Validators.maxLength(256)])],
      externalId: [undefined, Validators.compose([Validators.maxLength(64)])],
      policyNumber: [undefined, Validators.compose([Validators.maxLength(64)])],
      policyLimit: undefined,
      loanNumber: [{ value: undefined, disabled: true }],
      uniqueId: [{ value: undefined, disabled: true }],
      applicationNumber: [{ value: undefined, disabled: true }],
      applicationUniqueId: [{ value: undefined, disabled: true }]
    });
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub || !this.form.valid)
      return;

    DialogConfirm.show(this.dialog, `Save changes to ${this.data.account.loanNumber}?`)
      .subscribe(result => {
        if (result) {
          const update: LoanOtherInfoUpdateDto = {
            externalId: this.form.controls.externalId.value,
            description: this.form.controls.description.value,
            policyNumber: this.form.controls.policyNumber.value,
            policyLimit: this.form.controls.policyLimit.value
          };
          this.accountService.updateLoanOtherInfo(this.data.account.id, update)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }
}

export interface AccountOtherInfoDetailsComponentData {
  account: AccountData;
}
