import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from "@angular/forms";

@Component({
  selector: 'ifb-transaction-time-picker-dialog',
  templateUrl: './transaction-time-picker-dialog.component.html',
  styleUrls: ['./transaction-time-picker-dialog.component.scss']
})
export class TransactionTimePickerDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TransactionTimePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder) {
  }

  date: Date;
  originalDate: number;
  initHour: number;
  initMin: number;
  pm = false;
  maxHours = 12;
  maxMin = 59;

  ngOnInit() {
    this.date = new Date(this.data.date);
    this.originalDate = (new Date(this.data.date)).getTime();
    this.initHour = (this.date.getHours() > 12) ? (this.date.getHours() - 12) : this.date.getHours();
    this.initMin = this.date.getMinutes();

    this.pm = this.date.getHours() > 12;

    this.form.get('hours').setValue(this.initHour);
    this.form.get('minutes').setValue(this.initMin);

    this.setDate();
  }

  form: UntypedFormGroup = this.formBuilder.group({
    hours: [undefined, Validators.compose([Validators.max(12), Validators.min(0)])],
    minutes: [undefined, Validators.compose([Validators.max(59), Validators.min(0)])]
  });

  setDate() {
    this.date.setHours((this.pm ? (this.form.controls.hours.value + 12) : this.form.controls.hours.value));
    this.date.setMinutes(this.form.controls.minutes.value);

    if (this.date.getTime() !== this.originalDate)
      this.form.markAsDirty();
    else
      this.form.markAsPristine();
  }

  setTime(pm: boolean) {
    pm ? this.pm = true : this.pm = false;
    this.setDate();
  }

  changeTime(fc: AbstractControl, accumulator: number, max: number) {
    let tmp = fc.value;
    tmp += accumulator;
    if (tmp >= 0 && tmp <= max) {
      fc.setValue(tmp);
      this.setDate();
    }
  }
}
