import { catchError, map } from "rxjs/operators";
import { UntypedFormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";
import {
  AccountBaseService,
  AppBarActionsService,
  AppPageService,
  FormHelper,
  MessageService,
} from "common";
import { ApplicationRenewalRequestData } from "../../../application.model";
import { EMPTY, Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ApplicationService } from "../../../application.service";
import { UpdateDialogComponent } from "projects/backoffice/src/app/shared/update-dialog/update-dialog.component";
import { updatedFieldsData } from "../application-renewals-update-dialog/application-renewals-update-field-data";

@Injectable()
export class ApplicationRenewalsEditFacade {
  constructor(
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private accountService: AccountBaseService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private applicationService: ApplicationService
  ) {}

  updateActionButtons(isFormValid: boolean) {
    this.appBarActionsService.enable("save", isFormValid);
  }

  cancel(): void {
    this.appPageService.back();
  }

  save(applicationId: number, renewalsForm: UntypedFormGroup) {
    this.appBarActionsService.enable("save", false);
    const formValues = renewalsForm?.value;
    const renewalForLoanNumber = formValues?.renewalForLoanNumber?.loanNumber ?? formValues?.renewalForLoanNumber;
    const renewalForLoanId = formValues?.renewalForLoanNumber?.id;

    if (!!renewalForLoanId) {
      this.showUpdateDialog(applicationId, renewalsForm, renewalForLoanId);
      return;
    }

    this.getRenewalForLoanId(renewalForLoanNumber).subscribe(
      (renewalForLoanId: number) => {
        if (!renewalForLoanId) {
          FormHelper.showInvalidFormFields(renewalsForm);
          return;
        }

        this.showUpdateDialog(applicationId, renewalsForm, renewalForLoanId);
      }
    );
  }

  private getRenewalForLoanId(loanNumber: string): Observable<number> {
    return this.accountService
      .queryHints({ loanNumber, sortField: "loanNumber" })
      .pipe(
        catchError((error) => this.handleRenewalDataError(error)),
        map((data) => data?.find(item => item.loanNumber === loanNumber)?.id)
      );
  }

  private handleRenewalDataError(error: unknown) {
    this.messageService.error(error);
    return EMPTY;
  }

  private showUpdateDialog(
    applicationId: number,
    renewalsForm: UntypedFormGroup,
    renewalForLoanId: number,
  ) {
    UpdateDialogComponent.show(
      this.dialog,
      updatedFieldsData(renewalsForm),
      "Update",
      "application renewal"
    ).subscribe((result) => {
      if (!result) {
        this.appBarActionsService.enable("save", true);
        return;
      }

      const requestData: ApplicationRenewalRequestData = {
        renewalForLoanId,
        renewalReady: renewalsForm?.value?.renewalReady,
      };

      this.updateRenewalRequest(applicationId, requestData);
    });
  }

  private updateRenewalRequest(applicationId: number, requestData: ApplicationRenewalRequestData): void {
    this.applicationService.updateRenewal(applicationId, requestData)
      .pipe(
        catchError((error) => {
          return this.handleSaveError(error);
        })
      )
      .subscribe(() => {
        this.appPageService.back();
      });
  }

  private handleSaveError(error: unknown) {
    this.appBarActionsService.enable("save", true);
    this.messageService.error(error);
    return EMPTY;
  }
}
