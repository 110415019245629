import { Component, OnInit, Input } from '@angular/core';
import { AccountAppBarExpandedComponentData } from '../account-app-bar-expanded/account-app-bar-expanded.component';
import { ProductCode } from '../account.model';
import { EnumHelper, PaymentFrequency, FeeType, PaymentFrequencyLabel } from 'common';

@Component({
  selector: 'ifb-account-offer-widget',
  templateUrl: './account-offer-widget.component.html',
  styleUrls: ['./account-offer-widget.component.css']
})
export class AccountOfferWidgetComponent implements OnInit {

  isTermLoan: boolean;
  isLCF: boolean;

  constructor() { }

  @Input()
  data: AccountAppBarExpandedComponentData;

  @Input()
  edit: boolean;

  get PaymentFrequency() { return PaymentFrequency; }
  get PaymentFrequencyLabel() { return PaymentFrequencyLabel; }
  get FeeType() { return FeeType; }

  getNameFromMap(value: number, map: any, e: any) {
    return EnumHelper.getMappedNameFromValue(e, map, value);
  }

  getName(value: number, e: any) {
    return EnumHelper.getNameFromValue(e, value);
  }

  ngOnInit() {
    this.isTermLoan = this.data.account.productCode === ProductCode['Term Loan'];
    this.isLCF = this.data.account.productCode === ProductCode['Case Financing'];
  }

  getTermValue(frequency?: PaymentFrequency, value?: number): number {
    switch(frequency) {
      case PaymentFrequency.BiWeekly:
        return value * 2;

      default:
        return value;
    }
  }

  getTermName(frequency?: PaymentFrequency, value?: number): string {
    switch(frequency) {
      case PaymentFrequency.Monthly:
        return this.getTermValue(frequency, value) < 2 ? 'month' : 'months';

      default:
        return this.getTermValue(frequency, value) < 2 ? 'week' : 'weeks';
    }
  }
}
