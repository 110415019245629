import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountBaseService } from '../account-base-service';
import { AutocompleteComponent } from '../../ui/autocomplete/autocomplete.component';
import { LoanStatus } from '../../loan/loan.model';
import { AccountHintData } from '../account.model';
import { ProductCode } from '../../product/product.model';

@Component({
  selector: 'ifc-account-business-name-input',
  templateUrl: '../../ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../ui/autocomplete/autocomplete.component.css']
})
export class AccountBusinessNameInputComponent extends AutocompleteComponent {
  
  constructor(private accountService: AccountBaseService) {
    super();
  }
  
  @Input()
  productCode: ProductCode;

  @Input()
  excludedProductCode: ProductCode;

  @Input()
  includedStatus: LoanStatus;

  @Input()
  excludedStatus: LoanStatus;

  @Input()
  excludeLoansWithNoOwnerAssigned: boolean;

  @Input()
  excludeLoansWithResolvedCase: boolean;

  label = 'Legal entity name';

  optionFilter: (input: string | AccountHintData) => Observable<any[]> =
    input => this.accountService.queryHints({
      businessName: !!input ? (typeof input !== 'string' ? input.businessName : input) : null,
      sortField: 'businessName',
      includedStatus: this.includedStatus,
      excludedStatus: this.excludedStatus,
      productCode: this.productCode,
      excludedProductCode: this.excludedProductCode,
      excludeLoansWithNoOwnerAssigned: this.excludeLoansWithNoOwnerAssigned,
      excludeLoansWithResolvedCase: this.excludeLoansWithResolvedCase
    })

  optionLabelKey = 'businessName';
}
