  <div class="main" fxLayoutAlign="center center">

    <div class="account-app-bar-expanded" fxLayout="row" fxLayoutAlign="center center" fxHide.xs>

      <div class="chip-container" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="13px">
        <ifb-bank-account-link-status-icon-chip [status]="data?.application?.bankLinkStatus">
        </ifb-bank-account-link-status-icon-chip>
        <div class="chip-label">Bank link status</div>
      </div>

      <mat-divider [vertical]="true"></mat-divider>

      <mat-horizontal-stepper #horizontalStepper>
        <mat-step>
          <ng-template matStepLabel>Application</ng-template>
          <ng-container *ngTemplateOutlet="messages"></ng-container>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Underwriting</ng-template>
          <ng-container *ngTemplateOutlet="messages"></ng-container>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Decision</ng-template>
          <ng-container *ngTemplateOutlet="messages"></ng-container>
        </mat-step>
      </mat-horizontal-stepper>

    </div>

    <ng-template #messages>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div id="info-icon" *ngIf="showWarning || showError || showSuccess"
          [ngClass]="{'mat-orange-bg': showError, 'mat-pink-bg': showWarning, 'mat-green-bg': showSuccess }">
          <mat-icon>{{showWarning ? 'warning' : (showSuccess ? 'done' : 'priority_high')}}</mat-icon>
        </div>
        <div>{{message}}</div>
      </div>
    </ng-template>

  </div>

  <router-outlet name="tabs"></router-outlet>
