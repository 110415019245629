import { Component, OnInit, OnDestroy } from '@angular/core';
import { routerTransition } from '../../shared/animations/router.animations';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AccountAppBarExpandedComponentData } from '../account-app-bar-expanded/account-app-bar-expanded.component';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite, readOnly } from '../../user/user-permission/user-permission.data';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '../account.service';
import { BusinessAdvisorPickerComponent } from '../../shared/business-advisor/business-advisor-picker/business-advisor-picker.component';
import { EntityService } from '../../entity/entity.service';
import { AppSettings } from '../../../app.settings';
import { EntityData, ProductCode } from 'common';

@Component({
  selector: 'ifb-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
  animations: routerTransition
})

export class AccountProfileComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: AccountAppBarExpandedComponentData;
  canEditAccountInfo: boolean;
  saleforceViewMode: boolean;
  canEditAccountStatus: boolean;
  canReadAccountStatus: boolean;
  canEditAccountOtherInfo: boolean;
  canViewAdvisor: boolean;
  canEditAdvisor: boolean;
  canViewDocumentGeneration: boolean;
  canEditDocumentGeneration: boolean;
  entity: EntityData;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private settings: AppSettings,
    private entityService: EntityService) {
    this._unsubscribeAll = new Subject();
  }

  get productCodeOptions() {
    return ProductCode;
  }

  salesforceLink(salesforceRecordId: string) {
    return this.settings.salesforce.url + salesforceRecordId;
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(params => this.saleforceViewMode = params.uniqueId !== undefined);

    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));

    this.userPermissionService.granted([readWrite('servicing-accounts')])
      .subscribe(res => this.canEditAccountInfo = res);

    this.userPermissionService.granted([readOnly('servicing-account-status')])
      .subscribe(res => this.canReadAccountStatus = res);

    this.userPermissionService.granted([readWrite('servicing-account-status')])
      .subscribe(res => this.canEditAccountStatus = res);

    this.userPermissionService.granted([readOnly('servicing-advisors-assignment')])
      .subscribe(res => this.canViewAdvisor = res);

    this.userPermissionService.granted([readWrite('servicing-advisors-assignment')])
      .subscribe(res => this.canEditAdvisor = res);

    this.userPermissionService.granted([readWrite('servicing-account-other-info')])
      .subscribe(res => this.canEditAccountOtherInfo = res);

      this.userPermissionService.granted([readOnly('servicing-account-documents-generation')])
      .subscribe(res => this.canViewDocumentGeneration = res);

    this.userPermissionService.granted([readWrite('servicing-account-documents-generation')])
      .subscribe(res => this.canEditDocumentGeneration = res);
  }

  dataSet(data: AccountAppBarExpandedComponentData) {
    this.data = {
      account: data.account,
      accountInfo: data.accountInfo,
      userCurrent: data.userCurrent,
      owners: data.owners,
      liquidityEvent: data.liquidityEvent,
      accountStatements: {
        alligatorMatch: data.account.alligatorMatch,
        loanId: data.account.id,
        ...data.account.statementGeneration
      }
    };
    
    this.entityService.get(this.data.account.entityId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.entity = res;
      });
  }

  changeAdvisor() {
    BusinessAdvisorPickerComponent.show(this.dialog)
      .subscribe(res => {
        if (res) {
          if (!res.id)
            return;

          this.accountService.updateLoanAdvisor(this.data.account.id, res.id)
            .subscribe(() => {
              this.refreshAccountData();
            });
        }
      });
  }

  refreshAccountData() {
    this.accountService.get(this.data.account.id).subscribe(account => {
      this.data.account = account;
      this.accountService.onCurrentLoanChanged.next(account)
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
