import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper } from "../../helpers/enum.helper";
import { FeeType, FeeTypeLabel } from "../application.model";

@Pipe({
  name: "feeTypeLabel",
})
export class FeeTypeLabelPipe implements PipeTransform {
  transform(value: FeeType): string {
    return EnumHelper.getMappedNameFromValue(
      FeeType,
      FeeTypeLabel,
      value
    );
  }
}
