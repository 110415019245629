import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPageComponent, AppBarComponent, AppBarTitleComponent, AboutComponent, AboutService } from 'common';

import { appPageComponentDataDetail } from '../app.data';
import { BOAuthGuard } from '../guards/oauth.guard';

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
    {
        path: 'about',
        component: AppPageComponent,
        data: appPageDataDetail,
        canActivate: [BOAuthGuard],
        children: [
            {
                path: '',
                component: AppBarComponent,
                outlet: 'app-bar',
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AppBarTitleComponent,
                    } 
                ]
            },
            {
                path: '',
                component: AboutComponent,
                resolve: {
                    about: () => inject(AboutService).internalNotes()
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AboutPageRoutingModule { }
