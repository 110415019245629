<ifc-app-scroll-container>

  <div class="message-box info" *ngIf="!(data?.bankAccountsListGroupedByInstitution.length > 0)" fxLayout="row" fxLayoutGap="12px"
    fxLayoutAlign="start center">
    <mat-icon class="icon">info</mat-icon>
    <span>No data found</span>
  </div>

  <div fxLayout="column" fxLayoutAlign="start center" ngClass.gt-xs="layout margin-md" ngClass.xs="layout margin-sm">

    <div fxLayout="row wrap" fxLayoutAlign="center start" fxFlex="100">

      <div class="widget-space"
        [ngClass]="{'w-520': data?.bankAccountsListGroupedByInstitution?.length <= 2, 'w-500': data?.bankAccountsListGroupedByInstitution?.length > 2}"
        *ngFor="let group of data?.bankAccountsListGroupedByInstitution" fxHide.lt-md
        [fxHide.lg]="data?.bankAccountsListGroupedByInstitution?.length > 2"></div>

      <div class="widget-space w-600" fxHide [fxShow.lg]="data?.bankAccountsListGroupedByInstitution?.length > 2"></div>

    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center start" fxFlex="100" class="layout margin-md">

      <div *ngFor="let group of data?.bankAccountsListGroupedByInstitution" fxLayout="column" class="widget">
        <ifb-broker-bank-account-widget [broker]="data?.broker" [accountsGroupedByInstitution]="group">
        </ifb-broker-bank-account-widget>
      </div>

    </div>

    <div fxLayout="column" *ngIf="canReadWriteBank">
      <button mat-button (click)="addBankAccountLink()">
        <mat-icon>add_circle</mat-icon> <span class="btn-label">Add bank</span>
      </button>
    </div>

  </div>

</ifc-app-scroll-container>