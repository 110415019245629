import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CustomerData, CustomerStatus } from '../../customer.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerService } from '../../customer.service';
import { Subject, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readWrite } from '../../../user/user-permission/user-permission.data';


@Component({
  selector: 'ifb-customer-info-widget',
  templateUrl: './customer-info-widget.component.html',
  styleUrls: ['./customer-info-widget.component.scss']
})
export class CustomerInfoWidgetComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  @Input()
  data: CustomerData;

  @Input()
  edit: boolean;

  get CustomerStatus() { return CustomerStatus; }

  private saveSub: Subscription;

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  canSendEmail: boolean;

  constructor(
    public dialog: MatDialog,
    private customerService: CustomerService,
    public snackBar: MatSnackBar,
    private userPermissionService: UserPermissionService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.userPermissionService.granted([readWrite('servicing-customers-registration-data')])
      .subscribe(res => this.canSendEmail = res);
  }

  showConfirmDialog(): void {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '510px',
      disableClose: false
    });

    this.confirmDialogRef.componentInstance
      // eslint-disable-next-line max-len
      .confirmMessage = `Send an email to ${this.data.firstName} ${this.data.lastName} at ${this.data.email} with instructions to activate their account?`;
  }

  resendInvitation() {
    if (this.saveSub)
      return;

    this.showConfirmDialog();

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveSub = this.customerService.resendInvitation(this.data.id)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
      }
      this.confirmDialogRef = null;
    });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub = null;
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.snackBar.open("An email has been sent successfully.", "OK", { duration: 4000, panelClass: ['mat-green-bg'] });
  }

  private saveErrorHandler(response: any) {
    this.saveSubClear();
    const message = response.error ? response.error.Message : 'Error ocurred while trying to sent email';
    this.snackBar.open(message, 'Error', { duration: 4000, panelClass: ['mat-red-bg'] });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
