import { Pipe, PipeTransform } from "@angular/core";
import { Address } from "./address.model";
import { StringHelper } from "../helpers/string.helper";

@Pipe({
  name: "address",
})
export class AddressPipe implements PipeTransform {
  transform(address: Address): string {
    return StringHelper.addressFormat(address);
  }
}
