import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserRoleData, UserRoleQueryParams, UserRoleQueryResult } from "./user-role.data";
import { UserRoleService } from "./user-role.service";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class UserRoleResolver implements Resolve<UserRoleData> {
  constructor(private service: UserRoleService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserRoleData> | Promise<UserRoleData> | UserRoleData {
    return route.params.id === "new"
      ? undefined
      : this.service.get(parseNumber(route.params.id));
  }
}

@Injectable({
  providedIn: "root",
})
export class UserRoleQueryResolver implements Resolve<UserRoleQueryResult> {
  constructor(
    private service: UserRoleService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<UserRoleQueryResult>
    | Promise<UserRoleQueryResult>
    | UserRoleQueryResult {
    return this.service.query(new UserRoleQueryParams(this.queryParamsService.init(route.queryParams)));
  }
}
