<div>

  <div class="filter-toolbar">

    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-s" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>

      <div fxHide.xs class="layout padding-right-m">
        <button mat-button *ngIf="showExpandAllButton" (click)="toggle()" class="secondary-text icon-button" [disabled]="grouped">
          <mat-icon [inline]="true">{{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
          <span>{{expanded ? 'Collapse' : 'Expand'}} all</span>
        </button>
        <button mat-button [matMenuTriggerFor]="groupMenu" class="secondary-text icon-button">
          <mat-icon [inline]="true">clear_all</mat-icon>
          <span>Group by</span>
        </button>
        <button mat-button (click)="filterDialog()" class="secondary-text icon-button">
          <mat-icon [inline]="true">filter_list</mat-icon>
          <span>Filter</span>
        </button>
        <button mat-button *ngIf="canReadWriteLog" (click)="archiveAll()" class="secondary-text icon-button">
          <mat-icon [inline]="true">archive</mat-icon>
          <span>Archive all
            ({{grouped ? data?.logGroupQueryResult?.messageCountSum : data?.logQueryResult?.totalCount}})</span>
        </button>
      </div>

      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button *ngIf="showExpandAllButton" mat-menu-item (click)="toggle()">
            <mat-icon>{{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
            <span>{{expanded ? 'Collapse' : 'Expand'}} all</span>
          </button>
          <button mat-menu-item [matMenuTriggerFor]="groupMenu">
            <mat-icon>clear_all</mat-icon>
            <span>Group by</span>
          </button>
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button mat-menu-item *ngIf="canReadWriteLog" (click)="archiveAll()">
            <mat-icon>archive</mat-icon>
            <span>Archive all</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <mat-menu #groupMenu="matMenu">
      <button mat-menu-item *ngFor="let option of logGroupByOptions"
        [ngClass]="{selected: activeGroupBy === option.value}" (click)="group(option.value)"> {{option.name}}
      </button>
    </mat-menu>

  </div>

  <ifc-app-scroll-container [default]="true">
    <div *ngIf="!grouped">
      <ifb-log-list (archived)="fetchData($event)" (filtered)="onNestedFiltered($event)" [expandAll]="expanded" 
        [pageChanged]="pageChangedSubject"></ifb-log-list>
    </div>
    <div *ngIf="grouped">
      <ifb-log-grouped-list [activeGroupBy]="activeGroupBy" (archived)="fetchData($event)"
        (showMessagesSelected)="onShowMessagesSelected($event)">
      </ifb-log-grouped-list>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="grouped || (!grouped && data?.logQueryResult?.totalCount <= 10)">
    <mat-paginator #paginatorList showFirstLastButtons [length]="data?.logQueryResult?.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)">
    </mat-paginator>
  </div>

  <div [hidden]="!grouped || (grouped && data?.logGroupQueryResult?.totalCount <= 0)">
    <mat-paginator #paginatorGrouped showFirstLastButtons [length]="data?.logGroupQueryResult?.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)">
    </mat-paginator>
  </div>

</div>