import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BankAccount, BankAccountHelper } from 'common';

@Component({
  selector: 'ifb-bank-account-delete-dialog',
  templateUrl: './bank-account-delete-dialog.component.html',
  styleUrls: ['./bank-account-delete-dialog.component.css']
})
export class BankAccountDeleteDialogComponent {

  bankAccountNumber: string;

  constructor(public dialogRef: MatDialogRef<BankAccountDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bankAccountNumber = BankAccountHelper.sanitizeBankAccountNumber(this.data.bankAccount.accountNumber);
  }

  static show(dialog: MatDialog, bankAccount: BankAccount): Observable<any> {
    return dialog.open(BankAccountDeleteDialogComponent, {
      width: '510px',
      data: {
        bankAccount: bankAccount
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

}

