import { QueryParams, QueryResult } from "common";

export enum FailureReasonUsage {
    All = 'none',
    OriginalUnderwriting = 'underwriting-original',
    ResidualUnderwriting = 'underwriting-residual',
    UnderwritingReview = 'underwriting-review',
    NACHAResponse = 'nacha-response',
    PendingReason = 'pending-reason',
}

export const FailureReasonUsageLabel = new Map<string, string>([
    [FailureReasonUsage.All, 'All'],
    [FailureReasonUsage.OriginalUnderwriting, 'Original underwriting'],
    [FailureReasonUsage.ResidualUnderwriting, 'Residual underwriting'],
    [FailureReasonUsage.UnderwritingReview, 'Underwriting review'],
    [FailureReasonUsage.NACHAResponse, 'NACHA response'],
    [FailureReasonUsage.PendingReason, 'Pending reason']
]);

export interface FailureReasonQueryParams extends QueryParams {
    code?: string;
    externalCode?: string;
    name?: string;
    usage?: FailureReasonUsage;
    createdBy?: string;
    createdOnStart?: any;
    createdOnEnd?: any;
    updatedBy?: string;
    updatedOnStart?: any;
    updatedOnEnd?: any;
}

export interface FailureReasonBaseData {
    code?: string;
    externalCode?: string;
    name?: string;
    usage?: FailureReasonUsage[];
    allowToSpecifyCompetitors?: boolean;
}

export interface FailureReasonData extends FailureReasonBaseData {
    id?: number;
	description?: string;
    active?: boolean;
    createdBy?: string;
    createdOn?: Date;
    updatedBy?: string;
    updatedOn?: Date;
}

export interface FailureReasonQueryResult extends QueryResult<FailureReasonData>
{
}

export class FailureReasonFilters {
    code: string;
    externalCode: string;
    name: string;
    usage: FailureReasonUsage;
    createdBy: string;
    createdOn: Date;
    createdOnStart: Date;
    createdOnEnd: Date;
    updatedBy: string;
    updatedOn: Date;
    updatedOnStart: Date;
    updatedOnEnd: Date;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.code = filters.code || undefined;
        this.externalCode = filters.externalCode || undefined;
        this.name = filters.name || undefined;
        this.usage = filters.usage || undefined;
        this.createdBy =  filters.createdBy ? filters.createdBy.fullName ? filters.createdBy.fullName : filters.createdBy : undefined;
        this.createdOn = filters.createdOn || undefined;
        this.createdOnStart = filters.createdOnStart || undefined;
        this.createdOnEnd = filters.createdOnEnd || undefined;
        this.updatedBy =  filters.updatedBy ? filters.updatedBy.fullName ? filters.updatedBy.fullName : filters.updatedBy : undefined;
        this.updatedOn = filters.updatedOn || undefined;
        this.updatedOnStart = filters.updatedOnStart || undefined;
        this.updatedOnEnd = filters.updatedOnEnd || undefined;
        this.active = filters.active === 'true' ? true : (filters.active === 'false' ? false : undefined);
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'createdOn' || item === 'createdOnStart') {
            this.createdOn = undefined;
            this.createdOnStart = undefined;
            this.createdOnEnd = undefined;
        } else if (item === 'updatedOn' || item === 'updatedOnStart') {
            this.updatedOn = undefined;
            this.updatedOnStart = undefined;
            this.updatedOnEnd = undefined;
        }
    }
}