import { NgModule } from '@angular/core';
import { AboutModule, CommonModule } from 'common';

import { AboutPageRoutingModule } from './about-page-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    AboutModule,
    SharedModule,
    AboutPageRoutingModule
  ]
})
export class AboutPageModule { }
