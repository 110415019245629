import { Component, Input } from '@angular/core';
import { AutocompleteComponent } from 'common';
import { Observable, EMPTY } from 'rxjs';
import { CustomerService } from '../customer.service';
import { CustomerHintData } from '../customer.model';

@Component({
  selector: 'ifb-customer-ssn-input',
  templateUrl: '../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class CustomerSsnInputComponent extends AutocompleteComponent {

  constructor(private customerService: CustomerService) {
    super();
  }

  @Input()
  label = 'SSN';

  optionFilter: (input: string | CustomerHintData) => Observable<any[]> =
    input => input
      ? this.customerService.queryHints({
        ssn: !!input ? (typeof input !== 'string' ? input.ssn : input) : null,
        sortField: 'ssn'
      })
      : EMPTY;

  optionLabelKey = 'ssn';
}
