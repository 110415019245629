import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { OfferBundleListComponent } from "./offer-bundle-list/offer-bundle-list.component";
import { OfferBundleTabsComponent } from "./offer-bundle-list/components/offer-bundle-tabs/offer-bundle-tabs.component";
import { OfferBundleDetailsComponent } from "./offer-bundle-list/components/offer-bundle-details/offer-bundle-details.component";
import { OfferBundleCheckoutRequirementListComponent } from "./offer-bundle-list/components/offer-bundle-checkout-requirement-list/offer-bundle-checkout-requirement-list.component";
import { OfferBundleStatusWidgetComponent } from './offer-bundle-list/components/offer-bundle-details/components/offer-bundle-status-widget/offer-bundle-status-widget.component';
import { OfferBundleApplicationWidgetComponent } from './offer-bundle-list/components/offer-bundle-details/components/offer-bundle-application-widget/offer-bundle-application-widget.component';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule],
  declarations: [
    OfferBundleListComponent,
    OfferBundleTabsComponent,
    OfferBundleDetailsComponent,
    OfferBundleCheckoutRequirementListComponent,
    OfferBundleStatusWidgetComponent,
    OfferBundleApplicationWidgetComponent,
  ],
})
export class OfferBundleModule {}
