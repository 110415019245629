import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateHelper, EnumHelper, LiquidityEvent, ResolutionStatus } from 'common';
import { switchMap, tap } from 'rxjs/operators';
import { LitigationService } from '../../litigation/litigation.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { AccountLiquidityEventAddComponent } from '../account-liquidity-event-add/account-liquidity-event-add.component';

@UntilDestroy()
@Component({
  selector: 'ifb-account-liquidity-event-widget',
  templateUrl: './account-liquidity-event-widget.component.html',
  styleUrls: ['./account-liquidity-event-widget.component.scss']
})
export class AccountLiquidityEventWidgetComponent implements OnInit {

  @Input() loanId: number;
  @Input() data: LiquidityEvent;

  @Output()
  updated = new EventEmitter<any>();

  get resolutionStatusName(){
    return this.data.resolutionStatus ? EnumHelper.getNameFromValue(ResolutionStatus, this.data.resolutionStatus) : null;
  }

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private litigationService: LitigationService
    ) {}

  ngOnInit(): void {
    this.litigationService.getLiquidityEventForLoan(this.loanId)
      .pipe(untilDestroyed(this))
      .subscribe(res => this.data = res);
  }

  addLiquidityEventDialog() {
    AccountLiquidityEventAddComponent.show(this.dialog, this.data)
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          if (data) {
            return this.litigationService.saveLiquidityEvent(this.data.litigationId, this.loanId, data.resolutionStatus, DateHelper.getLocalDateAsUTC(data.resolutionDate))
          }
        }),
        tap(() => this.updated.emit()),
        switchMap(() => this.litigationService.getLiquidityEventForLoan(this.loanId)))
      .subscribe(res => this.data = res);
  }

  delete() {
    this.showConfirmDialog();

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.litigationService.deleteLiquidityEvent(this.data.litigationId, this.loanId)
        .pipe(
          untilDestroyed(this),
          switchMap(() => this.litigationService.getLiquidityEventForLoan(this.loanId)))
        .subscribe(res => this.data = res);
      }
      this.confirmDialogRef = null;
    });
  }

  showConfirmDialog(): void {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '510px',
      disableClose: false
    });
    this.confirmDialogRef.componentInstance
      .confirmMessage = `Are you sure you want to remove ${this.resolutionStatusName} resolution?`;
  }
}
