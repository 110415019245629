<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <div class="layout margin flex-std" [@routerTransition]="'fly'">
    <ifb-panel>
      <ifb-card fxFlexOrder="0">
        <div fxLayout="column" fxLayoutGap.md="24px" fxLayoutGap.lg="24px" ngClass="width-100-perc">
          <ifb-card class="padding-top-0">
            <ifb-entity-profile-status-widget [data]="data.entity" [edit]="canEditEntityInfo">
            </ifb-entity-profile-status-widget>
            <ifb-owners-widget [owners]="data.owners"></ifb-owners-widget>
          </ifb-card>

          <ifb-card fxHide fxShow.md="true" fxShow.lg="true">
            <ifb-entity-profile-broker-widget [data]="data.entity" [edit]="canEditEntityInfo">
            </ifb-entity-profile-broker-widget>
            <ifb-bank-linking-info-widget [entity]="data.entity"></ifb-bank-linking-info-widget>
            <ifb-business-advisor-widget *ngIf="canViewAdvisor" [data]="data.entity" [canEditAdvisor]="canEditAdvisor"
              (edit)="changeAdvisor()">
            </ifb-business-advisor-widget>
          </ifb-card>
        </div>
      </ifb-card>

      <ifb-card>
        <div fxLayout="column" fxLayoutGap.md="24px" fxLayoutGap.lg="24px">
          <ifb-card class="padding-top-0">
            <ifb-entity-profile-company-info-widget [data]="data.entity" [editable]="canEditEntityInfo">
            </ifb-entity-profile-company-info-widget>
          </ifb-card>

          <ifb-card fxHide fxShow.md="true" fxShow.lg="true">
            <ifb-entity-welcome-call-widget [welcomeCallData]="data.entity?.welcomeCall"></ifb-entity-welcome-call-widget>
          </ifb-card>
        </div>
      </ifb-card>

      <ifb-card fxShow fxHide.md="true" fxHide.lg="true">
        <ifb-entity-profile-broker-widget [data]="data.entity" [edit]="canEditEntityInfo">
        </ifb-entity-profile-broker-widget>
        <ifb-bank-linking-info-widget [entity]="data.entity"></ifb-bank-linking-info-widget>
      </ifb-card>

      <ifb-card fxShow fxHide.md="true" fxHide.lg="true">
        <ifb-entity-welcome-call-widget [welcomeCallData]="data.entity?.welcomeCall"></ifb-entity-welcome-call-widget>
        <ifb-business-advisor-widget *ngIf="canViewAdvisor" [data]="data.entity" [canEditAdvisor]="canEditAdvisor"
        (edit)="changeAdvisor()">
      </ifb-business-advisor-widget>
      </ifb-card>

    </ifb-panel>
  </div>
</ifc-app-scroll-container>
