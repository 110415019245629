import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "projects/backoffice/src/app.settings";
import { OfferListQueryParams, OfferListQueryResults } from "./models/offer-query.model";
import { ApplicationOffer } from "common";

@Injectable({
  providedIn: "root",
})
export class OfferService {
  private readonly baseUrl = `${this.settings.applications.url}/api/offer`;

  constructor(private http: HttpClient, private settings: AppSettings) {}

  getOfferList(
    params: OfferListQueryParams
  ): Observable<OfferListQueryResults> {
    return this.http.get<OfferListQueryResults>(`${this.baseUrl}/list`, {
      params: <any>params,
    });
  }

  getOffer(id: number): Observable<ApplicationOffer> {
    return this.http.get<ApplicationOffer>(`${this.baseUrl}/${id}`);
  }
}
