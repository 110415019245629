import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import * as moment from "moment";
import {
  EnumHelper,
  FilterParameter,
  MatTableHelper,
  ProductCode,
  ProductCodeLabel,
  PromotionType,
  PromotionTypeLabel,
  TransactionTypeString,
  TransactionTypeStringLabel
} from "common";
import { PromotionListQueryParams } from "./models/promotions-query.model";
import { PromotionsFilters } from "./models/promotions-filter.model";

@Injectable({
  providedIn: "root",
})
export class PromotionsFilterService {
  constructor(private router: Router) {}

  filter(filters: PromotionsFilters, paginator?: MatPaginator, sort?: MatSort) {
    // TODO[promotions] get rid of PromotionsFilters class, refactor filter logic (use interfaces instead)
    const query: PromotionListQueryParams = <any>filters;

    // Set default to first page
    query.skip = undefined;

    query.name =
      typeof query.name === "object" ? filters.name.name : query.name;
    query.createdOnFrom = filters.createdOnFrom
      ? moment(filters.createdOnFrom).format("L")
      : undefined;
    query.createdOnTo = filters.createdOnTo
      ? moment(filters.createdOnTo).format("L")
      : undefined;
    query.startDateFrom = filters.startDateFrom
      ? moment(filters.startDateFrom).format("L")
      : undefined;
    query.startDateTo = filters.startDateTo
      ? moment(filters.startDateTo).format("L")
      : undefined;
    query.endDateFrom = filters.endDateFrom
      ? moment(filters.endDateFrom).format("L")
      : undefined;
    query.endDateTo = filters.endDateTo
      ? moment(filters.endDateTo).format("L")
      : undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }

    this.router.navigate(["admin", "promotions"], {
      replaceUrl: true,
      queryParams: query,
    });
  }

  addFilterParameters(query: PromotionListQueryParams): FilterParameter[] {
    // Refresh filters chip list
    // TODO[promotions] implement filter generator - reusable implementation with config models per feature
    const filters: FilterParameter[] = [];

    if (query.name) {
      filters.push({
        id: "name",
        name: "Name",
        value: query.name.trim(),
      });
    }

    if (query.active) {
      filters.push({
        id: "active",
        name: "Status",
        value: query.active.toString() === "true" ? "Active" : "Inactive",
      });
    }

    if (query.createdOnFrom) {
      filters.push({
        id: "createdOnFrom",
        name: "Created on",
        value: `${moment(query.createdOnFrom).format("L")} - ${moment(
          query.createdOnTo
        ).format("L")}`,
      });
    }

    if (query.productCode) {
      filters.push({
        id: "productCode",
        name: "Product type",
        value: EnumHelper.getMappedNameFromValue(
          ProductCode,
          ProductCodeLabel,
          query.productCode
        ),
      });
    }

    if (query.startDateFrom) {
      filters.push({
        id: "startDateFrom",
        name: "Start date",
        value: `${moment(query.startDateFrom).format("L")} - ${moment(
          query.startDateTo
        ).format("L")}`,
      });
    }

    if (query.endDateFrom) {
      filters.push({
        id: "endDateFrom",
        name: "End date",
        value: `${moment(query.endDateFrom).format("L")} - ${moment(
          query.endDateTo
        ).format("L")}`,
      });
    }

    if (query.transactionType) {
      filters.push({
        id: "transactionType",
        name: "Transaction Type",
        value: EnumHelper.getMappedNameFromValue(
          TransactionTypeString,
          TransactionTypeStringLabel,
          query.transactionType
        ),
      });
    }

    if (query.promotionType) {
      filters.push({
        id: "promotionType",
        name: "Promotion Type",
        value: EnumHelper.getMappedNameFromValue(
          PromotionType,
          PromotionTypeLabel,
          query.promotionType
        ),
      });
    }

    return filters;
  }
}
