import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppBarTitleService, AppBarActionsService, AppBarAction, DialogConfirm, MessageService, FeatureFlagService, states, AppPageService } from 'common';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { Tenant } from '../tenant.model';
import { readWrite } from '../../../user/user-permission/user-permission.data';
import { FeatureFlagListComponent } from '../../feature-flag/feature-flag-list/feature-flag-list.component';

@Component({
  selector: 'ifb-tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.component.scss']
})
export class TenantDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private _saveSub: Subscription;

  form: UntypedFormGroup;
  data: TenantDetailsComponentData;
  canReadWrite: boolean;

  get states() { return states; }

  @ViewChild(FeatureFlagListComponent) featureFlagListComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private service: FeatureFlagService,
    private userPermissionService: UserPermissionService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.formInit();
  }

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.userPermissionService.granted([readWrite('admin-tenants'), readWrite('admin-feature-flags')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canReadWrite = res;
        this.actionUpdate();
      });
  }

  dataInit(data: TenantDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = this.data.tenant ? `Tenant ${this.data.tenant.name}` : 'Tenant info';

    if (this.data.tenant) {
      this.form.reset({
        name: this.data.tenant.name || '',
        dba: this.data.tenant.dba || '',
        careOf: this.data.tenant.careOf || '',
        phone: this.data.tenant.phone || '',
        email: this.data.tenant.infoEmail || '',
        url: this.data.tenant.url || '',
        street: this.data.tenant.headquartersAddress ? this.data.tenant.headquartersAddress.line1 : '',
        city: this.data.tenant.headquartersAddress ? this.data.tenant.headquartersAddress.city : '',
        state: this.data.tenant.headquartersAddress ? this.data.tenant.headquartersAddress.state : '',
        zipCode: this.data.tenant.headquartersAddress ? this.data.tenant.headquartersAddress.zip : '',
        unitNumber: this.data.tenant.headquartersAddress ? this.data.tenant.headquartersAddress.line2 : ''
      });
    } else {
      this.form.reset();
    }

    this.form.markAsPristine();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private actionUpdate() {
    this.appBarActionsService.enable('save', this.canReadWrite);
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  cancel() {
    this.appPageService.back();
  }

  save() {
    DialogConfirm.show(this.dialog, `Save changes to ${this.data.tenant.name}?`)
      .subscribe(res => {
        if (res)
          this.service.updateByTenant(this.data.tenant.id, this.featureFlagListComponent.updatedFeatures)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({ next: () => this.router.navigate(['/admin/tenants']) })
      });
  }

  private saveSubClear() {
    if (this._saveSub)
      this._saveSub.unsubscribe();
    this._saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }

  private formInit() {
    this.form = this.formBuilder.group({
      name: undefined,
      dba: undefined,
      careOf: undefined,
      phone: [undefined, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
      email: undefined,
      url: undefined,
      street: [undefined, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(255)])],
      city: [undefined, Validators.compose([Validators.required, Validators.pattern(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/), Validators.minLength(2), Validators.maxLength(40)])],
      state: [undefined, Validators.required],
      zipCode: [undefined, Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-?\d{4})?$/), Validators.maxLength(20)])],
      unitNumber: [undefined, Validators.maxLength(16)]
    });
  }
}

export interface TenantDetailsComponentData {
  tenant?: Tenant;
}
