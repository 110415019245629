import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FilterRange, EnumHelper, ApplicationStage, ApplicationStageLabel, ApplicationQueryParams, LinkingStatus, IdNameData } from 'common';
import { ApplicationFilters } from '../application.model';
import { TenantService } from '../../admin/tenant/tenant.service';

@Component({
  selector: 'ifb-application-list-filter-dialog',
  templateUrl: './application-list-filter-dialog.component.html',
  styleUrls: ['./application-list-filter-dialog.component.scss']
})
export class ApplicationListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: ApplicationFilters;
  bankLinkStatusOptions = EnumHelper.getNamesAndValues(LinkingStatus);
  applicationStageOptions = EnumHelper.getMappedNamesAndValues(ApplicationStage, ApplicationStageLabel).filter(_ => _.name !== undefined);

  companies: IdNameData[] = [];

  constructor(
    public dialogRef: MatDialogRef<ApplicationListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private tenantService: TenantService) {

    this._unsubscribeAll = new Subject();
    this.filters = new ApplicationFilters(data.query);

    this.form = this.formBuilder.group({
      loanNumber: this.data.query.loanNumber || '',
      applicationNumber: this.data.query.applicationNumber || '',
      businessName: this.data.query.businessName || '',
      stage: this.data.query.stage,
      companyId: +this.data.query.companyId || undefined,
      createdOn: this.data.query.createdOn || '',
      createdOnStart: new UntypedFormControl(this.data.query.createdOnStart ? new Date(this.data.query.createdOnStart) : undefined, { validators: null, updateOn: 'blur' }),
      createdOnEnd: new UntypedFormControl(this.data.query.createdOnEnd ? new Date(this.data.query.createdOnEnd) : undefined, { validators: null, updateOn: 'blur' }),
      bankLinkStatus: +this.data.query.bankLinkStatus >= 0 ? +this.data.query.bankLinkStatus : undefined,
      lastLinkingAttemptDate: this.data.query.lastLinkingAttemptDate || '',
      lastLinkingAttemptDateFrom: new UntypedFormControl(this.data.query.lastLinkingAttemptDateFrom ? new Date(this.data.query.lastLinkingAttemptDateFrom) : '', { validators: null, updateOn: 'blur' }),
      lastLinkingAttemptDateTo: new UntypedFormControl(this.data.query.lastLinkingAttemptDateTo ? new Date(this.data.query.lastLinkingAttemptDateTo) : '', { validators: null, updateOn: 'blur' }),
      bankLinkAttempted: this.data.query.bankLinkAttempted || ''
    });
  }

  static show(dialog: MatDialog, query: ApplicationQueryParams, canFilterByEntity: boolean): Observable<any> {
    return dialog
      .open(ApplicationListFilterDialogComponent, {
        data: { query: query, canFilterByEntity: canFilterByEntity },
        closeOnNavigation: true,
      })
      .afterClosed();
  }

  ngOnInit(): void {
    this.tenantService.names().subscribe(data => this.companies = data);

    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new ApplicationFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  createdDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      createdOnStart: dataRange.from,
      createdOnEnd: dataRange.to
    });
  }

  lastLinkingAttemptDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      lastLinkingAttemptDateFrom: dataRange.from,
      lastLinkingAttemptDateTo: dataRange.to
    });
  }


}
