import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuditTrailData, AuditQueryParams, AuditQueryResult } from './audit-trail.model';
import { ResourceService } from 'common';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService {

  private readonly baseUrl = `${this.settings.audit.url}/api/audit`;

  constructor(
    private http: HttpClient,
    private resourceService: ResourceService,
    private settings: AppSettings) { }

  getById(id: number): Observable<AuditTrailData> {
    return this.http.get<AuditTrailData>(`${this.baseUrl}/${id}`);
  }

  audit(params: AuditQueryParams): Observable<AuditQueryResult> {
    return this.http.get<AuditQueryResult>(`${this.baseUrl}`, {
      params: <any>params
    });
  }

  auditDownload(params: AuditQueryParams) {
    this.resourceService.download(`${this.baseUrl}/csv`, 'audit.csv', params);
  }

  hints(params: AuditQueryParams): Observable<any> {
    return this.http.get(`${this.settings.audit.url}/api/workflowevents`, { params: <any>params});
  }
}
