import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { UserRoutingModule } from './user-routing.module';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserListFilterDialogComponent } from './user-list-filter-dialog/user-list-filter-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { CustomerExportModule } from '../../customer/customer.module';
import { UserEmailInputComponent } from './user-email-input/user-email-input.component';
import { UserFullNameInputComponent } from './user-full-name-input/user-full-name-input.component';


@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        UserRoutingModule,
        CustomerExportModule
    ],
    exports: [
        UserEmailInputComponent,
        UserFullNameInputComponent
    ],
    declarations: [
        UserDetailsComponent,
        UserListComponent,
        UserListFilterDialogComponent,
        UserEmailInputComponent,
        UserFullNameInputComponent
    ]
})
export class UserModule { }
