import { Component, ElementRef, ViewChild, HostListener, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'ifb-content-slider',
  templateUrl: './content-slider.component.html',
  styleUrls: ['./content-slider.component.scss']
})
export class ContentSliderComponent implements AfterContentChecked {

  @ViewChild('contentSliderContentContainer', { static: true })
  private contentContainerRef: ElementRef;

  @ViewChild('contentSliderContent', { static: true })
  private contentRef: ElementRef;

  ngAfterContentChecked() {
    this.stepsCalculate();
  }

  onNavStepButtonClick(event: any, stepIndex: number) {
    this.stepSelectedIndex = stepIndex;
  }

  steps: Step[] = [];

  @HostListener('window:resize')
  stepsCalculate() {
    const ceil = (x: number) => Math.ceil(x);

    const contentContainer: HTMLDivElement = this.contentContainerRef.nativeElement;
    const contentContainerWidth = contentContainer.clientWidth;
    const content: HTMLDivElement = this.contentRef.nativeElement;
    const contentWidth = content.clientWidth;
    const stepsCount = ceil(contentWidth / contentContainerWidth);
    const stepsPrevCount = this.steps.length;

    this.steps = [];
    for (let stepIndex = 0; stepIndex < stepsCount; stepIndex++) {
      this.steps.push({
        offset: stepIndex * contentContainerWidth,
      });
    }

    if (this.steps.length !== stepsPrevCount) {
      this.stepSelectedIndex = 0;
    }
  }

  get stepSelected(): Step {
    return this._stepSelectedIndex < this.steps.length
      ? this.steps[this._stepSelectedIndex]
      : undefined;
  }

  private _stepSelectedIndex = 0;

  get stepSelectedIndex(): number {
    return this._stepSelectedIndex;
  }

  set stepSelectedIndex(selectedIndex: number) {
    this._stepSelectedIndex = selectedIndex;
    const stepSelected = this.stepSelected;
    if (stepSelected) {
      const contentContainer: HTMLDivElement = this.contentContainerRef.nativeElement;
      contentContainer.scrollLeft = stepSelected.offset;
    }
  }

  stepSelectedNext(offset: number) {
    let stepSelectedIndex = this.stepSelectedIndex + offset;
    if (offset > 0) {
      stepSelectedIndex = Math.min(stepSelectedIndex, this.steps.length - 1);
    } else {
      stepSelectedIndex = Math.max(stepSelectedIndex, 0);
    }
    this.stepSelectedIndex = stepSelectedIndex;
  }

  get navVisible() {
    return this.steps.length > 1;
  }

}

interface Step {
  offset: number;
}
