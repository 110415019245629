import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SalesDashboardComponent } from './sales-dashboard.component';
import { SharedModule } from '../../shared/shared.module';
import { NewDrawsCardChartComponent } from './new-draws-card-chart/new-draws-card-chart.component';
import { ResidualDrawsCardChartComponent } from './residual-draws-card-chart/residual-draws-card-chart.component';
import { TotalDrawsCardChartComponent } from './total-draws-card-chart/total-draws-card-chart.component';
import { ProductAveragesListComponent } from './product-averages-list/product-averages-list.component';
import { AccountOnboardingListComponent } from './account-onboarding-list/account-onboarding-list.component';
import { AccountBreakdownListComponent } from './account-breakdown-list/account-breakdown-list.component';
import { DrawsLeaderboardListComponent } from './draws-leaderboard-list/draws-leaderboard-list.component';
import { AccountOnboardingChartComponent } from './account-onboarding-chart/account-onboarding-chart.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
  ],
  declarations: [
    SalesDashboardComponent,
    NewDrawsCardChartComponent,
    ResidualDrawsCardChartComponent,
    TotalDrawsCardChartComponent,
    ProductAveragesListComponent,
    AccountOnboardingListComponent,
    AccountBreakdownListComponent,
    DrawsLeaderboardListComponent,
    AccountOnboardingChartComponent
  ],
  exports: [
    SalesDashboardComponent
  ]
})
export class SalesDashboardModule { }
