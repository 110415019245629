import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import {
  AutoPaymentMode,
  AutoPaymentModeMap,
  AccountModification,
  AccountData,
  AccountAmortizationInfo,
  AccountAmortizationData,
  AccountModificationRequestDto
} from '../account.model';
import { EnumHelper, AppBarActionsService, AppBarTitleService, FormHelper, AppBarAction, MessageService, LoanStatus, LoanInfoDto, FeeType, AppPageService } from 'common';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { AccountService } from '../account.service';
import { takeUntil } from 'rxjs/operators';
import {
  AccountModificationUpdateDialogComponent
} from '../account-modification-update-dialog/account-modification-update-dialog.component';
import { UserData } from '../../user/user.model';
import { NoteCardComponent } from '../../shared/note/note-card/note-card.component';
import * as _ from 'lodash';
import { ProductData } from '../../product/product.model';
import { NoteData } from '../../shared/note/note.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'ifb-account-modification-details',
  templateUrl: './account-modification-details.component.html',
  styleUrls: ['./account-modification-details.component.scss']
})
export class AccountModificationDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  @ViewChild(NoteCardComponent) noteCard;
  @ViewChild('horizontalStepper') horizontalStepper;
  @ViewChild('verticalStepper') verticalStepper;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  stepper: MatStepper;

  verticalLayout: boolean;

  warning: string;

  modification: AccountModification = null;
  modificationForm: UntypedFormGroup;
  loanForm: UntypedFormGroup;
  data: AccountModificationDetailsComponentData;
  autoPaymentModeOptions = EnumHelper.getMappedNamesAndValues(AutoPaymentMode, AutoPaymentModeMap)
    .filter(_ => _.name !== undefined);
  feeTypeOptions = EnumHelper.getNamesAndValues(FeeType);

  get AutoPaymentMode() { return AutoPaymentMode; }
  get FeeType() { return FeeType; }

  statusOptions: LoanStatus[] = [];
  private statuses = EnumHelper.getNamesAndValues(LoanStatus);

  newAccountAmortizationInfo: AccountAmortizationData[];
  newPaymentDue: number;
  newPaymentDueDate: Date;
  numberOfPaymentsLeft: number;
  notesSnapshot: NoteData[] = [];
  currentPaymentAmount: number;
  currentPaymentDueDate: Date;

  actionButtons: any = {
    cancel: { id: 'cancel', label: 'Cancel', buttonType: 'button' },
    save: { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' },
    next: { id: 'nextStep', label: 'Next', buttonType: 'button' },
    prev: { id: 'prevStep', label: 'Back', buttonType: 'button' }
  };

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private accountService: AccountService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private appBarTitleService: AppBarTitleService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
    breakpointObserver: BreakpointObserver
  ) {

    this._unsubscribeAll = new Subject();

    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      if (result.matches)
        this.verticalLayout = true;
      else
        this.verticalLayout = false;
      this.stepper = this.verticalLayout ? this.verticalStepper : this.horizontalStepper;
    });

    this.appBarActionsService.actions = [this.actionButtons.cancel, this.actionButtons.next];

    this.modificationForm = this.formBuilder.group({
      name: undefined,
      startDate: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      endDate: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      autoPaymentMode: undefined,
      fixedPaymentAmount: undefined,
      accrueInterest: undefined,
      interestRate: undefined,
      drawFee: undefined,
      drawFeeType: undefined,
      maintenanceFee: undefined,
      maintenanceFeeType: undefined,
      note: undefined,
      active: undefined
    });

    this.loanForm = this.formBuilder.group({
      reAmortized: undefined,
      loanStatus: undefined,
      frozenUntil: undefined,
      freezeForDays: undefined,
      repaymentTerm: undefined,
    });
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.loanForm.controls.repaymentTerm.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => this.numberOfPaymentsLeft = this.getNumberOfPaymentsLeft(value));

    this.validateEndDate();
  }

  ngAfterViewChecked() {
    this.stepper = this.horizontalStepper || this.verticalStepper;
    this.cdRef.detectChanges();
  }

  validateEndDate() {
    const endDate = new Date(this.modificationForm.value.endDate);
    endDate.setSeconds(1);
    const today = new Date();
    today.setSeconds(0);
    today.setMinutes(0);
    today.setHours(0);

    this.warning = this.modificationForm.value.endDate && endDate < today ? 'The modification has a start date and/or end date in the past. You may need to update loan terms and/or transactions manually. Contact your system administrator for help.' : null;
  }

  getModification(): AccountModification {
    return {
      name: this.modificationForm.value.name,
      startDate: this.modificationForm.value.startDate,
      endDate: this.modificationForm.value.endDate,
      active: this.modificationForm.value.active,
      autoPaymentMode: this.modificationForm.value.autoPaymentMode,
      fixedPaymentAmount: this.modificationForm.value.autoPaymentMode === AutoPaymentMode.FixedAmount
        ? this.modificationForm.value.fixedPaymentAmount : null,
      accrueInterest: this.modificationForm.value.accrueInterest,
      interestRate: this.modificationForm.value.interestRate,
      drawFee: this.modificationForm.value.drawFee,
      drawFeeType: this.modificationForm.value.drawFeeType,
      maintenanceFee: this.modificationForm.value.maintenanceFee,
      maintenanceFeeType: this.modificationForm.value.maintenanceFeeType,
      updatedNotes: this.noteCard.addedNotes.length ? this.noteCard.addedNotes.map(_ => _.text) : undefined,
      activatedOn: this.modification ? this.modification.activatedOn : undefined,
      updatedBy: this.modification ? this.modification.updatedBy : undefined,
      updatedOn: this.modification ? this.modification.updatedOn : undefined
    };
  }

  onStepChange(event: any) {
    this.appBarActionsService.actions = [this.actionButtons.cancel];
    switch (event.selectedIndex) {
      case 0:
        this.appBarActionsService.actions.push(this.actionButtons.next);
        break;
      case this.stepper._steps.length - 1:
        this.appBarActionsService.actions.push(this.actionButtons.prev, this.actionButtons.save);

        if (!this.noteCard.addedNotes.length) {
          this.modificationForm.controls.note.markAsUntouched();
          this.modificationForm.controls.note.markAsPristine();
        } else {
          this.modificationForm.controls.note.markAsTouched();
          this.modificationForm.controls.note.markAsDirty();
        }

        if ((this.modificationForm.dirty || this.loanForm.dirty) && this.modificationForm.valid)
          this.appBarActionsService.enable('save', true);

        const status = this.loanForm.controls.loanStatus.pristine ? null : this.loanForm.controls.loanStatus.value;
        const freezeForDays = this.loanForm.controls.freezeForDays.pristine ? null : this.loanForm.controls.freezeForDays.value;
        const repaymentTerm = this.loanForm.controls.repaymentTerm.pristine ? null : this.loanForm.controls.repaymentTerm.value;
        const reAmortize = !!this.loanForm.controls.reAmortized.value;
        const modification = this.getModification();

        this.accountService.previewModification(this.data.account.id, modification, reAmortize, status, freezeForDays, repaymentTerm)
          .subscribe(res => {
            if (res) {
              this.newPaymentDueDate = res.newPaymentDueDate;
              this.newPaymentDue = res.newPaymentDue;
              this.newAccountAmortizationInfo = res.amortizationInfo;
            }
          });
        break;
      default:
        this.appBarActionsService.actions.push(this.actionButtons.prev, this.actionButtons.next);
        break;
    }
  }

  private dataInit(data: AccountModificationDetailsComponentData) {
    this.data = data;

    if (this.data.account) {
      this.modification = this.data.account.modification || null;
      this.appBarTitleService.title = `${this.data.account.loanNumber} - Modification`;
    }

    if (this.modification) {
      this.notesSnapshot = _.uniqBy(
        _.concat(
          this.data.account.modification.notes,
          _.flatMap(this.data.account.modificationHistory.map(i => _.flatMap(i.notes)))
        ),
        'text', 'date'
      );
    }

    this.modificationForm.reset({
      name: this.modification ? this.modification.name : undefined,
      startDate: this.modification ? this.modification.startDate : undefined,
      endDate: this.modification ? this.modification.endDate : undefined,
      autoPaymentMode: this.modification ? this.modification.autoPaymentMode : undefined,
      fixedPaymentAmount: this.modification ? this.modification.fixedPaymentAmount : undefined,
      accrueInterest: this.modification ? this.modification.accrueInterest : undefined,
      interestRate: this.modification ? this.modification.interestRate : undefined,
      drawFee: this.modification ? this.modification.drawFee : undefined,
      drawFeeType: this.modification ? this.modification.drawFeeType : undefined,
      maintenanceFee: this.modification ? this.modification.maintenanceFee : undefined,
      maintenanceFeeType: this.modification ? this.modification.maintenanceFeeType : undefined,
      active: this.modification ? (this.modification.active ? true : false) : true
    });

    this.loanForm.reset({
      loanStatus: this.data.account ? this.data.account.status : undefined,
      frozenUntil: this.data.account && this.data.account.frozenUntil !== null ? this.data.account.frozenUntil : '',
      freezeForDays: this.data.account && this.data.account.freezeForDays !== null ? this.data.account.freezeForDays : '',
    });

    const product = data.products.find(p => p.id === data.account.productId);
    this.statusOptions = _.flatMap(this.statuses.filter(i => _.includes(product.allowedLoanStatuses, i.value)));

    this.currentPaymentAmount = this.getOutstandingBalance() ? data.account.currentState.paymentAmount : 0;
    this.currentPaymentDueDate = !data.account.noAutoPayments && this.getOutstandingBalance() ? data.accountInfo.paymentDueDate : null;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  cancel() {
    this.appPageService.back();
  }

  save() {
    FormHelper.showInvalidFormFields(this.modificationForm);

    if (this.saveSub || !this.modificationForm.valid || !this.data.account)
      return;

    AccountModificationUpdateDialogComponent
      .show(this.dialog,
        this.data.account.loanNumber,
        this.modificationForm,
        this.loanForm,
        this.modification ? "Update" : "Create",
        this.noteCard.addedNotes,
        this.warning)
      .subscribe(result => {
        if (result) {
          const command: AccountModificationRequestDto = {};

          command.modification = this.getModification();

          if (!this.loanForm.controls.loanStatus.pristine)
            command.status = this.loanForm.value.loanStatus;

          if (this.loanForm.value.freezeForDays != null) {
            command.status = this.loanForm.value.loanStatus;
            command.freezeForDays = this.loanForm.value.freezeForDays;
          }

          if (this.loanForm.value.reAmortized) {
            command.reAmortize = this.loanForm.value.reAmortized;
            command.repaymentTerm = this.loanForm.value.repaymentTerm;
          }

          if (this.modification)
            this.saveSub = this.accountService.updateAccountModification(this.data.account.id, command)
              .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
          else
            this.saveSub = this.accountService.createAccountModification(this.data.account.id, command)
              .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  nextStep() {
    this.stepper.next();
  }

  prevStep() {
    this.stepper.previous();
  }

  getAutoPaymentMode(autoPaymentMode: AutoPaymentMode) {
    return AutoPaymentModeMap.get(autoPaymentMode);
  }

  getAccrueInterest(val: boolean) {
    if (val === undefined)
      return 'Do not override';
    return val ? 'Accrue interest' : 'Do not accrue interest';
  }

  getNumberOfPaymentsLeft(repaymentTerm: number): number {
    return this.accountService.calculateTotalNumberOfPayments(repaymentTerm, this.data.account.offer.paymentFrequency);
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  clear(fc: string, form: UntypedFormGroup) {
    const formControl = form.get(fc);
    formControl.reset();
    formControl.markAsTouched();
    formControl.markAsDirty();
    form.markAsDirty();
  }

  getFeeTypeFromValue(type: FeeType): string {
    return EnumHelper.getNameFromValue(FeeType, type);
  }

  getOutstandingBalance(): number {
    return this.accountService.getOutstandingBalance(this.data.account);
  }
}

export interface AccountModificationDetailsComponentData {
  account?: AccountData;
  userCurrent?: UserData;
  accountInfo: LoanInfoDto;
  accountAmortizationInfo?: AccountAmortizationInfo[];
  products: ProductData[];
}
