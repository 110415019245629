import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FilterRange } from "common";
import { PromotionsFilters } from "../../../models/promotions-filter.model";
import { PromotionListQueryParams } from "../../../models/promotions-query.model";
import { PromotionListData } from "../../models/promotion-list-data.model";
import { getFormModel } from "./promotion-list-filter-dialog-form-data";
import {
  getProductTypeOptions,
  getPromotionTypeOptions,
  getTransactionTypeOptions,
} from "../../../data/promotion-data";

@Component({
  selector: "ifb-promotion-list-filter-dialog",
  templateUrl: "./promotion-list-filter-dialog.component.html",
  styleUrls: ["./promotion-list-filter-dialog.component.scss"],
})
export class PromotionListFilterDialogComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  productTypeOptions = getProductTypeOptions;
  transactionTypeOptions = getTransactionTypeOptions;
  promotionTypeOptions = getPromotionTypeOptions;

  form: UntypedFormGroup;
  filters: PromotionsFilters;

  constructor(
    public dialogRef: MatDialogRef<PromotionListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new PromotionsFilters(data.query);
    this.form = this.formBuilder.group(getFormModel(data.query));
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.filters = new PromotionsFilters(data);
      });
  }

  static show(
    dialog: MatDialog,
    query: PromotionListQueryParams,
    routeData: PromotionListData
  ): Observable<any> {
    return dialog
      .open(PromotionListFilterDialogComponent, {
        data: {
          query,
          routeData,
        },
        closeOnNavigation: true,
      })
      .afterClosed();
  }

  createdOnDateSelected(dataRange: FilterRange<moment.Moment>): void {
    this.form.patchValue({
      createdOnFrom: dataRange.from,
      createdOnTo: dataRange.to,
    });
  }

  startDateSelected(dataRange: FilterRange<moment.Moment>): void {
    this.form.patchValue({
      startDateFrom: dataRange.from,
      startDateTo: dataRange.to,
    });
  }

  endDateSelected(dataRange: FilterRange<moment.Moment>): void {
    this.form.patchValue({
      endDateFrom: dataRange.from,
      endDateTo: dataRange.to,
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
