import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import { TokenQueryResult, TokenData, TokenQueryParams } from "./token.model";
import { TokenService } from "./token.service";
import { parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class TokenResolver implements Resolve<TokenData> {
  constructor(private service: TokenService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TokenData> | Promise<TokenData> | TokenData {
    return route.params.id === "new"
      ? undefined
      : this.service.get(parseNumber(route.params.id));
  }
}

@Injectable({
  providedIn: "root",
})
export class TokenQueryResolver implements Resolve<TokenQueryResult> {
  constructor(
    private service: TokenService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<TokenQueryResult>
    | Promise<TokenQueryResult>
    | TokenQueryResult {
    return this.service.query(new TokenQueryParams(this.queryParamsService.init(route.queryParams)));
  }
}
