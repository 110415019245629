import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AccountService } from "../account/account.service";
import { PromotionSelectOptionData } from "../admin/promotion/models/promotion.model";
import { PromotionService } from "../admin/promotion/promotion.service";
import { readOnly } from "../user/user-permission/user-permission.data";
import { UserPermissionService } from "../user/user-permission/user-permission.service";
import { TransactionService } from "./transaction.service";
import { parseNumber } from "common";


@Injectable({
    providedIn: 'root'
})
export class PromotionsToSelectResolver implements Resolve<PromotionSelectOptionData[]> {
    constructor(
      private userPermissionService: UserPermissionService,
      private promotionService: PromotionService,
      private accountService: AccountService,
      private transactionService: TransactionService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PromotionSelectOptionData[]> | Promise<PromotionSelectOptionData[]> | PromotionSelectOptionData[] {

      const accountTransactionsList = route.data["accountTransactionsList"];
      return this.userPermissionService.granted([readOnly('servicing-promotions')])
        .pipe(switchMap(res => {
          if(res) {
            const accountId = accountTransactionsList ? route.params.id : route.params.accountId;
            if(accountId) {
              return this.accountService.get(parseNumber(accountId)).pipe(map(account => {
                return { permission: res, productCode: account.productCode };
              }));
            }
            else if(route.params.id)
              return this.transactionService.get(parseNumber(route.params.id))
                .pipe(switchMap(trx => this.accountService.get(trx?.accountId)), map(account => {
                  return { permission: res, productCode: account.productCode };
                }));

            return of({ permission: res });
          }
          else
            return of(null);
        }),
        switchMap(res => res?.permission ?
          this.promotionService.getPromotionsToSelect({ productCode: res?.productCode ? res.productCode : null })
          : of(null)));
    }
}
