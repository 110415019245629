import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApplicationData } from '../application.model';
import { ApplicationService } from '../application.service';

@Component({
  selector: 'ifb-application-app-bar-title',
  templateUrl: './application-app-bar-title.component.html',
  styleUrls: ['./application-app-bar-title.component.scss']
})
export class ApplicationAppBarTitleComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: ApplicationAppBarTitleComponentData;
  title: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: ApplicationAppBarTitleComponentData) => {
        this.data = it;
        this.title = (this.data.application.entityName || "") + ' - ' + (this.data.application.applicationNumber || "");
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  applicationListNavigate() {
    this.router.navigateByUrl(this.applicationService.prevApplicationListUrl || '/application');
  }

}

export interface ApplicationAppBarTitleComponentData {
  application: ApplicationData;
}
