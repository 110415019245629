import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readWrite, readOnly } from '../../../user/user-permission/user-permission.data';

@Component({
  selector: 'ifb-business-advisor-widget',
  templateUrl: './business-advisor-widget.component.html',
  styleUrls: ['./business-advisor-widget.component.css']
})
export class BusinessAdvisorWidgetComponent implements OnInit {

  constructor(private userPermissionService: UserPermissionService) { }

  @Input()
  data: any;

  @Input()
  canEditAdvisor: boolean;

  @Output()
  edit = new EventEmitter<any>();

  servicingAdvisors = false;

  ngOnInit() {
    this.userPermissionService.granted([readOnly('servicing-advisors')])
      .subscribe(res => this.servicingAdvisors = res);
  }

  canEdit() {
    return this.servicingAdvisors && this.canEditAdvisor;
  }

  editAdvisor() {
    this.edit.emit();
  }
}
