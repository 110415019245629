import { Component, Input } from '@angular/core';
import { AutocompleteComponent, AccountHintData } from 'common';
import { Observable } from 'rxjs';
import { AccountService } from '../../account/account.service';

@Component({
  selector: 'ifb-customer-loan-input',
  templateUrl: '../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class CustomerLoanInputComponent extends AutocompleteComponent {

  @Input()
  customerId: number;

  @Input()
  excludedStatus: number;

  constructor(private accountService: AccountService) {
    super();
  }

  label = 'Account';

  optionFilter: (input: string | AccountHintData) => Observable<any[]> =
    input => this.accountService.queryHints({
      loanNumber: !!input ? (typeof input !== 'string' ? input.loanNumber : input) : null,
      excludedOwnerId: this.customerId,
      excludedStatus: this.excludedStatus,
      sortField: 'loanNumber'
    })

  optionLabelKey = 'loanNumber';
  optionLabelKey2 = 'businessName';
}
