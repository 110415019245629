import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppBarAction, AppBarActionsService } from "common";
import { Competitor, CompetitorBase } from "../../models/competitor.model";
import { CompetitorDetailsFacade } from "./competitor-details.facade";
import { CompetitorDetailsComponentData } from "./models/competitor-details-data.model";
import { UpdateFormFieldData } from "../../../shared/const/models/update-form-field-data.model";
import { CompetitorFormStatusData } from "../competitor-form/models/competitor-form-status-data.model";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifb-competitor-details",
  templateUrl: "./competitor-details.component.html",
  providers: [CompetitorDetailsFacade],
})
export class CompetitorDetailsComponent implements OnInit  {
  data: CompetitorDetailsComponentData;
  competitor$: Observable<Competitor>
  editedCompetitor: CompetitorFormStatusData;

  constructor(
    private route: ActivatedRoute,
    private facade: CompetitorDetailsFacade,
    private appBarActionsService: AppBarActionsService
  ) {}

  //RD: TODO: Change to onInit while working on LOC-7749 [Refactor] Managing actions and title in "AppBar"
  ngOnInit(): void {
    this.appBarActionsService.actions = this.facade.getActionsConfig();

    this.appBarActionsService.invoking.pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
    
    this.competitor$ = this.route.data.pipe(
      map(data => data?.competitor),
      switchMap(competitor => this.facade.init(competitor)),
      map(componentData =>{
        this.data = componentData;
        return this.data.competitor;
      })
    );
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (
      competitor: CompetitorBase,
      updatedFieldsData: UpdateFormFieldData[]
    ) => void = this[action.id].bind(this);
    actionHandler(this.editedCompetitor.value, this.editedCompetitor.updatedFields);
  }

  delete(): void {
    this.facade.delete(this.data)
  }

  save(competitor: CompetitorBase, updatedFieldsData: UpdateFormFieldData[]): void {
    this.facade.save(competitor, this.data, updatedFieldsData);
  }

  cancel(): void {
    this.facade.cancel();
  }

  onCompetitorFormChanged(formStatusData: CompetitorFormStatusData) {
    this.editedCompetitor = formStatusData;
    return this.facade.updateSaveButton(formStatusData.isValid && formStatusData.dirtyState, this.data);
  }
}
