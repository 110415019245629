<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">

      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>

      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>

      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item name="filter" (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
        </mat-menu>
      </div>

    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">

      <div class="message-box info" *ngIf="!data.applicationQueryResult?.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>

      <table mat-table matSort [dataSource]="data.applicationQueryResult?.values"
        *ngIf="data.applicationQueryResult?.totalCount" (matSortChange)="sortData($event)">

        <ng-container matColumnDef="bankLinkStatus">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell">
            <div [ngClass]="getBankLinkingStatusAvatarClass(row)">
              <mat-icon [inline]="true" class="small" matTooltip="{{getBankLinkingStatusTooltip(row)}}">
                {{getBankLinkingStatusAvatarIcon(row)}}</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="stage">
          <th mat-header-cell *matHeaderCellDef> Stage </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{formatStage(row.stage)}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.type}} </td>
        </ng-container>

        <ng-container matColumnDef="applicationType">
          <th mat-header-cell *matHeaderCellDef> Application type </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.applicationType}} </td>
        </ng-container>

        <ng-container matColumnDef="sfAppStatus">
          <th mat-header-cell *matHeaderCellDef> Salesforce status </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell">
            <ifb-application-status-icon-chip [status]="row.sfAppStatus"></ifb-application-status-icon-chip>
          </td>
        </ng-container>

        <ng-container matColumnDef="applicationNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="applicationNumber"> Application number </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.applicationNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="loanNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="loanNumber"> Loan number </th>
          <td mat-cell *matCellDef="let row" class="table-cell"> 
            <a *ngIf="canReadAccounts" [routerLink]="accountDetailsLink(row.loanId)">{{row.loanNumber}}</a>
            <span *ngIf="!canReadAccounts">{{row.loanNumber}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="businessName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="businessName"> Legal entity name </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.entityName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Created on </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.createdOn | date}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef> Created by </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.createdBy}} </td>
        </ng-container>

        <ng-container matColumnDef="updatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedOn"> Updated on </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.updatedOn | date}}
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedBy">
          <th mat-header-cell *matHeaderCellDef> Updated by </th>
          <td mat-cell *matCellDef="let row" (click)="detailsLink(row.id)" class="table-cell"> {{row.updatedBy}} </td>
        </ng-container>

        <ng-container matColumnDef="salesforceId">
          <th mat-header-cell *matHeaderCellDef> Salesforce ID </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <a *ngIf="row.uniqueId" href="{{salesforceLink(row.uniqueId)}}" target="_blank">
              {{row.uniqueId}} </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let row" class="action-icon"  ngClass.gt-md="action-icon actions-menu" ngClass.lt-lg="actions-menu-xs">
              <button mat-icon-button (click)="detailsLink(row.id, true)">
                  <mat-icon>open_in_new</mat-icon>
              </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true;" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" class="table-row"></tr>

      </table>

    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.applicationQueryResult?.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.applicationQueryResult?.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>