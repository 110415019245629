import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppBarAction, AppBarTitleService, AppBarActionsService, MessageService, FormHelper, EnumHelper, AppPageService } from 'common';
import { CustomerComponentData, CustomerInfoDto, CustomerStatus, CustomerStatusMap } from '../customer.model';
import { CustomerService } from '../customer.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { CustomerUpdateDialogComponent } from '../customer-update-dialog/customer-update-dialog.component';

@Component({
  selector: 'ifb-customer-info-details',
  templateUrl: './customer-info-details.component.html',
  styleUrls: ['./customer-info-details.component.scss']
})
export class CustomerInfoDetailsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  public canEditSensitiveData = false;

  private saveSub: Subscription;
  form: UntypedFormGroup;
  data: CustomerComponentData;

  customerStatusOptions = EnumHelper.getMappedNamesAndValues(CustomerStatus, CustomerStatusMap).filter(_ => _.name !== undefined);

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private messageService: MessageService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog) {

    this._unsubscribeAll = new Subject();

    this.formInit();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: !this.form.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
  }

  ngOnInit() {
    this.userPermissionService.granted([readWrite('servicing-customers-sensitive-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canEditSensitiveData = res;
        if (this.canEditSensitiveData) {
          // eslint-disable-next-line max-len
          this.form.controls.ssn.setValidators(Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(11)]));
          this.form.controls.ssn.enable();
        } else {
          this.form.controls.ssn.clearValidators();
          this.form.controls.ssn.disable();
        }
      });

    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  dataInit(data: CustomerComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Customer info';

    if (data.customer) {
      this.form.patchValue(data.customer);
      this.form.controls.accountType.setValue(_.trim(data.customer.accountType, '#$'));
    }

    this.form.markAsPristine();
    this.actionUpdate();

    FormHelper.showInvalidFormFields(this.form);
  }


  private formInit() {
    this.form = this.formBuilder.group({
      firstName: [undefined, Validators.compose([Validators.maxLength(64)])],
      lastName: [undefined, Validators.compose([Validators.maxLength(64)])],
      email: [undefined, Validators.compose([Validators.required, Validators.email, Validators.maxLength(256)])],
      ssn: [{ value: undefined, disabled: !this.canEditSensitiveData }],
      uniqueId: [{ value: undefined, disabled: true }],
      accountType: [{ value: undefined, disabled: true }],
      status: [undefined, Validators.required]
    });
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    CustomerUpdateDialogComponent.show(this.dialog, this.form)
      .subscribe(result => {
        if (result) {
          this.saveSub = this.customerService.update(this.data.customer.id, this.form.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler(info: CustomerInfoDto) {
    this.saveSubClear();
    this.appPageService.back();
  }
}

