<mat-card>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="card-header">
    <span class="card-title">Identity verification</span>
    <button mat-icon-button matTooltip="Edit" *ngIf="edit" aria-label="Edit" routerLink="/customer/{{customerId}}/kba">
      <mat-icon class="secondary-text">edit</mat-icon>
    </button>
  </div>

  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <span class="secondary-text" mat-line>KBA Status</span>
        <ifb-customer-kba-status-icon-chip [status]="data.status"></ifb-customer-kba-status-icon-chip>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Attempts</span>
        <p class="item-content">{{data.attemptCount}}</p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Last verification date</span>
        <p class="item-content">{{data.lastVerificationDate | date: 'medium'}}</p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Last error</span>
        <p class="item-content" title="{{data.lastError}}">{{data.lastError}}</p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Last configuration</span>
        <p class="item-content" title="{{data.lastConfigurationName}}">{{data.lastConfigurationName}}</p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>

</mat-card>
