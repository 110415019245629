<form [formGroup]="form">
  <mat-dialog-content class="mat-typography">
    <h3>Set filter</h3>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="!hiddenTransactionColumns">
      <div fxFlex class="mat-form-field" *ngIf="data.canFilterByLoan">
        <ifc-account-loan-number-input [fc]="form.get('loanNumber')"></ifc-account-loan-number-input>
      </div>
      <div fxFlex class="mat-form-field" *ngIf="data.canFilterByLoan">
        <ifb-business-name-input [fc]="form.get('businessName')"> </ifb-business-name-input>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select matInput multiple formControlName="type" placeholder="Type">
          <mat-option *ngFor="let option of transactionTypeOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
        <button type="button" *ngIf="form.get('type').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('type').setValue(null)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>ID</mat-label>
        <input matInput formControlName="id" type="number" placeholder="ID">
        <button type="button" *ngIf="form.get('id').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('id').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Amount from</mat-label>
        <input matInput formControlName="amountFrom" type="text" placeholder="Amount from" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('amountFrom').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('amountFrom').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Amount to</mat-label>
        <input matInput formControlName="amountTo" type="text" placeholder="Amount to" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('amountTo').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('amountTo').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Credit limit from</mat-label>
        <input matInput formControlName="creditLimitFrom" type="text" placeholder="Credit limit from" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('creditLimitFrom').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('creditLimitFrom').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Credit limit to</mat-label>
        <input matInput formControlName="creditLimitTo" type="text" placeholder="Credit limit to" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('creditLimitTo').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('creditLimitTo').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput multiple formControlName="status" placeholder="Status">
          <mat-option *ngFor="let option of statusOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
        <button type="button" *ngIf="form.get('status').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('status').setValue(null)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Brand</mat-label>
        <mat-select formControlName="companyId" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>{{readPromotions  ? 'Promotions' : 'No promotions permission'}}</mat-label>
        <mat-select matInput multiple formControlName="promotionIds" placeholder="Promotions">
          <mat-option *ngFor="let option of _promotionOptions" [value]="option.id" >{{option.name}}</mat-option>
        </mat-select>
        <button type="button" *ngIf="form.get('promotionIds').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('promotionIds').setValue(null)"
          [disabled]="!readPromotions">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <ifc-filter-param-date-range-input [fc]="form.get('transactionDate')" [label]="'Transaction Date'" (rangeSelected)="transactionDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('transactionDate').value === 'custom'">
      <ifc-datepicker [fc]="form.get('fromDate')" [label]="'Transaction date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('toDate')" [label]="'Transaction date to'"></ifc-datepicker>
    </div>


    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <ifc-filter-param-date-range-input [fc]="form.get('processDate')" [label]="'Processed Date'" (rangeSelected)="processDateSelected($event)">
      </ifc-filter-param-date-range-input>
      <ifc-filter-param-amount-range-input [fc]="form.get('principalAmount')" [label]="'Principal amount'" (rangeSelected)="principalAmountSelected($event)"></ifc-filter-param-amount-range-input>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('processDate').value === 'custom'">
      <ifc-datepicker [fc]="form.get('processDateFrom')" [label]="'Processed date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('processDateTo')" [label]="'Processed date to'"></ifc-datepicker>
    </div>


    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('principalAmount').value === 'custom'">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Principal amount from</mat-label>
        <input matInput formControlName="principalAmountFrom" type="text" placeholder="Principal amount from" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('principalAmountFrom').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('principalAmountFrom').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Principal amount to</mat-label>
        <input matInput formControlName="principalAmountTo" type="text" placeholder="Principal amount to" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('principalAmountTo').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('principalAmountTo').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <ifc-filter-param-amount-range-input [fc]="form.get('interestAmount')" [label]="'Interest amount'" (rangeSelected)="interestAmountSelected($event)"></ifc-filter-param-amount-range-input>
      <ifc-filter-param-amount-range-input [fc]="form.get('deferredFeeAmount')" [label]="'Deferred fee amount'" (rangeSelected)="deferredFeeAmountSelected($event)"></ifc-filter-param-amount-range-input>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('interestAmount').value === 'custom'">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Interest amount from</mat-label>
        <input matInput formControlName="interestAmountFrom" type="text" placeholder="Interest amount from" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('interestAmountFrom').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('interestAmountFrom').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Interest amount to</mat-label>
        <input matInput formControlName="interestAmountTo" type="text" placeholder="Interest amount to" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('interestAmountTo').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('interestAmountTo').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('deferredFeeAmount').value === 'custom'">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Deferred fee amount from</mat-label>
        <input matInput formControlName="deferredFeeAmountFrom" type="text" placeholder="Deferred fee amount from" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('deferredFeeAmountFrom').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('deferredFeeAmountFrom').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Deferred fee amount to</mat-label>
        <input matInput formControlName="deferredFeeAmountTo" type="text" placeholder="Deferred fee amount to" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('deferredFeeAmountTo').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('deferredFeeAmountTo').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <ifc-filter-param-amount-range-input [fc]="form.get('cashFeeAmount')" [label]="'Cash fee amount'" (rangeSelected)="cashFeeAmountSelected($event)"></ifc-filter-param-amount-range-input>
      <ifc-filter-param-amount-range-input [fc]="form.get('disbursedAmount')" [label]="'Disbursed amount'" (rangeSelected)="disbursedAmountSelected($event)"></ifc-filter-param-amount-range-input>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('cashFeeAmount').value === 'custom'">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Cash fee amount from</mat-label>
        <input matInput formControlName="cashFeeAmountFrom" type="text" placeholder="Cash fee amount from" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('cashFeeAmountFrom').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('cashFeeAmountFrom').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Cash fee amount to</mat-label>
        <input matInput formControlName="cashFeeAmountTo" type="text" placeholder="Cash fee amount to" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('cashFeeAmountTo').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('cashFeeAmountTo').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('disbursedAmount').value === 'custom'">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Disbursed amount from</mat-label>
        <input matInput formControlName="disbursedAmountFrom" type="text" placeholder="Disbursed amount from" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('disbursedAmountFrom').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('disbursedAmountFrom').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Disbursed amount to</mat-label>
        <input matInput formControlName="disbursedAmountTo" type="text" placeholder="Disbursed amount to" currencyMask [options]="{ precision: '2', allowNegative: true}">
        <button type="button" *ngIf="form.get('disbursedAmountTo').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('disbursedAmountTo').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <div mat-dialog-actions class="flex row end-center">
    <button mat-button [mat-dialog-close]="">Cancel</button>
    <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary"
      [disabled]="form.invalid">Ok</button>
  </div>
</form>
