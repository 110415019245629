<div class="ifc-card-elevation widget-content" [class.mat-elevation-z4]="true" [ngClass.gt-md]="'card lg'"
  [ngClass.md]="'card md'" [ngClass.xs]="'card xs'">

  <div class="card-header card-header-primary" [class.mat-elevation-z4]="true">

    <div fxLayoutGap="24px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">

        <div matListAvatar class="avatar circle-wrap" fxLayoutAlign="center center">
          <mat-icon class="icon">account_balance</mat-icon>
        </div>

        <div class="card-title" fxLayout="column" fxLayoutAlign="center center">
          <span class="mat-title">{{accountsGroupedByInstitution?.institution}}</span>
        </div>

      </div>
    </div>
  </div>

  <div class="card-body">
    <mat-list>

      <mat-list-item *ngFor="let account of accountsGroupedByInstitution.accounts; index as i">

        <h4 class="mat-caption" mat-list-icon>{{i+1}}</h4>
        <h4 mat-line>
          <a (click)="bankAccountDetailsLink(account?.id)">
            {{account?.routingNumber}} - {{account?.accountNumber}}
          </a>
        </h4>
        <p class="mat-caption" mat-line>{{account?.balance | currency:'$'}}</p>
        <p class="mat-caption" mat-line>{{account?.lastUpdatedDate | date}}</p>
      </mat-list-item>

    </mat-list>

  </div>

</div>