import { QueryParams, QueryResult, parseNumber } from "common";
import { OfferListItem } from "./offer.model";

export class OfferListQueryParams extends QueryParams {
  id?: number;
  offerBundleId?: number;

  constructor(params?: any)
  {
    super(params);
    this.id = parseNumber(params.id) || undefined
    this.offerBundleId = parseNumber(params.offerBundleId) || undefined;
  }
}

export interface OfferListQueryResults
  extends QueryResult<OfferListItem> {}
