import { ButtonColor, DialogActionData } from "common";
import { BrokerUserData, BrokerUserListItemData } from "./domain/models/broker-user.model";

export function getNotificationSentDialogData(brokerUser: BrokerUserListItemData | BrokerUserData): DialogActionData {
    return {
      body:
        `An email has been sent to ${brokerUser.email} with instructions to create an account and access the broker portal.`,
      title: "Email notification sent",
      buttons: [
        { label: "OK", buttonColor: ButtonColor.Primary, action: true },
      ],
    }};
