import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BankAccount } from 'common';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifb-bank-account-sync-dialog',
  templateUrl: './bank-account-sync-dialog.component.html',
  styleUrls: ['./bank-account-sync-dialog.component.css']
})
export class BankAccountSyncDialogComponent  {

  constructor(public dialogRef: MatDialogRef<BankAccountSyncDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, bankAccount: BankAccount, succeeded: boolean, error?: Error): Observable<any> {
    return dialog.open(BankAccountSyncDialogComponent, {
      width: '510px',
      data: {
        bankAccount: bankAccount,
        succeeded: succeeded,
        error: error
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

}

