import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnumHelper } from 'common';
import { IBankTransactionListFilterService } from '../bank-account-transaction-list-filter.service';
import {
  BankTransactionFilters,
  BankTransactionQueryParams,
  BankTransactionStatus,
  BankTransactionType
} from '../bank.model';

@Component({
  selector: 'ifb-bank-transaction-list-filter-dialog',
  templateUrl: './bank-transaction-list-filter-dialog.component.html',
  styleUrls: ['./bank-transaction-list-filter-dialog.component.css']
})
export class BankTransactionListFilterDialogComponent implements OnInit, OnDestroy {

  private _filterService: IBankTransactionListFilterService;
  private _unsubscribeAll: Subject<any>;
  form: UntypedFormGroup;
  filters: BankTransactionFilters;
  today = new Date();

  bankTransationStatusOptions = EnumHelper.getNamesAndValues(BankTransactionStatus);
  bankTransationTypeOptions = EnumHelper.getNamesAndValues(BankTransactionType);

  constructor(public dialogRef: MatDialogRef<BankTransactionListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this._filterService = data.bankTransactionListFilterService;
    this.filters = this._filterService.getBankTransactionFilters(data.query);
    this.form = this.formBuilder.group({
      transactionDateFrom: new UntypedFormControl(this.filters.transactionDateFrom ? new Date(this.filters.transactionDateFrom) : undefined, { validators: null, updateOn: 'blur' }),
      transactionDateTo: new UntypedFormControl(this.filters.transactionDateTo ? new Date(this.filters.transactionDateTo) : undefined, { validators: null, updateOn: 'blur' }),
      postedDateFrom: new UntypedFormControl(this.filters.postedDateFrom ? new Date(this.filters.postedDateFrom) : undefined, { validators: null, updateOn: 'blur' }),
      postedDateTo: new UntypedFormControl(this.filters.postedDateTo ? new Date(this.filters.postedDateTo) : undefined, { validators: null, updateOn: 'blur' }),
      fromAmount: this.filters.fromAmount || undefined,
      toAmount: this.filters.toAmount || undefined,
      status: this.filters.status || undefined,
      type: this.filters.type || undefined,
      category: this.filters.category || undefined,
      active: this.filters.active === true ? 'true' : (this.filters.active === false ? 'false' : '')
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new BankTransactionFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  static show(dialog: MatDialog, query: BankTransactionQueryParams, bankTransactionListFilterService: IBankTransactionListFilterService): Observable<any> {
    return dialog.open(BankTransactionListFilterDialogComponent, {
      data: {
        query,
        bankTransactionListFilterService
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

}
