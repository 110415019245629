import { Injectable } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import {
  hasValue,
  setQueryVarsFromPaginator,
} from "projects/partner/src/Tools";
import { MatTableHelper, DialogActionComponent, ButtonColor, DialogActionData } from "common";
import { BrokerUsersQueryParams, BrokerUserData} from "../../domain/models/broker-user.model";
import { MatDialog } from "@angular/material/dialog";


@Injectable()
export class BrokerUserListFacade {
  constructor(
    private router: Router,
    private dialog: MatDialog) {}

  showBrokerUserDisableDialog(brokerUser: BrokerUserData) {
    const dialogData: DialogActionData<boolean> = {
      title: "Disable user",
      body: `Do you want to disable user ${brokerUser.firstName} ${brokerUser.lastName}?`,
      buttons: [
        {
          label: "Cancel",
          buttonColor: ButtonColor.Default,
          action: false,
        },
        { label: "Ok", buttonColor: ButtonColor.Primary, action: true },
      ],
    };
    return DialogActionComponent.show(this.dialog, dialogData);
  }

  filter(paginator?: MatPaginator, sort?: MatSort) {
    const query = new BrokerUsersQueryParams();

    query.skip = undefined;

    if (hasValue(paginator)) {
      setQueryVarsFromPaginator(query, paginator);
    }

    if (hasValue(sort)) {
      query.sort = MatTableHelper.getSort(query.sort ?? "", sort);
    }

    void this.router.navigate([], {
      queryParams: query,
    });
  }
}
