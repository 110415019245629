import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { ClientQueryResult, ClientData } from "./client.data";
import { ClientService } from "./client.service";
import { UserPermissionService } from "../../user/user-permission/user-permission.service";
import { readOnly } from "../../user/user-permission/user-permission.data";
import { map, switchMap } from "rxjs/operators";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class ClientResolver implements Resolve<ClientData> {
  constructor(private service: ClientService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ClientData> | Promise<ClientData> | ClientData {
    return route.params.id === "new"
      ? undefined
      : this.service.get(parseNumber(route.params.id));
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientQueryResolver implements Resolve<ClientQueryResult> {
  constructor(
    private service: ClientService,
    private queryParamsService: QueryParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ClientQueryResult>
    | Promise<ClientQueryResult>
    | ClientQueryResult {
    return this.service.query(this.queryParamsService.init(route.queryParams));
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientAllResolver implements Resolve<ClientData[]> {
  constructor(
    private service: ClientService,
    private userPermissionService: UserPermissionService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> | Promise<ClientData[]> | any[] {
    return this.userPermissionService.granted([readOnly("admin-clients")]).pipe(
      map((res) => res),
      switchMap((res) =>
        res ? this.service.getAll({ sort: "+clientName" }) : []
      )
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientActiveResolver implements Resolve<ClientData[]> {
  constructor(
    private service: ClientService,
    private userPermissionService: UserPermissionService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> | Promise<ClientData[]> | any[] {
    return this.userPermissionService.granted([readOnly("admin-clients")]).pipe(
      map((res) => res),
      switchMap((res) =>
        res ? this.service.getAll({ active: true, sort: "+clientName" }) : []
      )
    );
  }
}
