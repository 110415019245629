<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content class="mat-typography">

  <form class="example-form" *ngIf="loans.length">
    <div fxLayout="column" fxLayoutAlign="start start">
      <mat-form-field appearance="outline" *ngFor="let loan of loans">
        <mat-label>Account</mat-label>
        <input matInput type="text" value="{{loan.description}}" [disabled]="true">
      </mat-form-field>
    </div>
  </form>

  <ifb-customer-loan-input [fc]="form.get('loanNumber')" [label]="'Search for account'" [excludedStatus]="excludedStatus"
    (autocompleteOptionSelected)="selectedLoan($event)" [customerId]="data.customerId"></ifb-customer-loan-input>

</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="save()">Ok</button>
</div>
