import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import {
  ApplicationCheckoutRequirement,
  ApplicationOfferBundleData,
} from "common";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  OfferBundleListQueryParams,
  OfferBundleListQueryResults,
} from "./models/offer-bundle-query.model";
import { OfferBundleService } from "./offer-bundle.service";

@Injectable({
  providedIn: "root",
})
export class OfferBundleListResolver
  implements Resolve<OfferBundleListQueryResults>
{
  constructor(private service: OfferBundleService, private queryParamsService: QueryParamsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<OfferBundleListQueryResults>
    | Promise<OfferBundleListQueryResults>
    | OfferBundleListQueryResults {
    return this.service.getOfferBundleList(
      this.queryParamsService.init({
        applicationId: route.params.id,
        ...route.queryParams
      } as OfferBundleListQueryParams)
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class OfferBundleDetailsResolver
  implements Resolve<ApplicationOfferBundleData>
{
  constructor(private service: OfferBundleService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ApplicationOfferBundleData>
    | Promise<ApplicationOfferBundleData>
    | ApplicationOfferBundleData {
    const offerBundleId =
      route.params.offerBundleId !== undefined
        ? route.params.offerBundleId
        : route.pathFromRoot[route.pathFromRoot.length - 2].params
            .offerBundleId;
    return this.service.getOfferBundle(offerBundleId);
  }
}

@Injectable({
  providedIn: "root",
})
export class OfferBundleCheckoutRequirementsResolver
  implements Resolve<ApplicationCheckoutRequirement[]>
{
  constructor(private service: OfferBundleService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ApplicationCheckoutRequirement[]>
    | Promise<ApplicationCheckoutRequirement[]>
    | ApplicationCheckoutRequirement[] {
    const offerBundleId =
      route.params.offerBundleId !== undefined
        ? route.params.offerBundleId
        : route.pathFromRoot[route.pathFromRoot.length - 2].params
            .offerBundleId;
    return this.service
      .getOfferBundle(offerBundleId)
      .pipe(map((data) => data?.checkoutRequirements));
  }
}
