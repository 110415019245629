import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppBarAction, AppBarActionsService, EnumHelper, FormHelper, LiquidityEvent, ResolutionStatus } from 'common';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ifb-account-liquidity-event-add',
  templateUrl: './account-liquidity-event-add.component.html',
  styleUrls: ['./account-liquidity-event-add.component.scss']
})
export class AccountLiquidityEventAddComponent implements OnInit, OnDestroy {

  resolutionStatusOptions = EnumHelper.getNamesAndValues(ResolutionStatus);
  liquidityForm: UntypedFormGroup;
  new = true;
  _unsubscribeAll: Subject<any>;
  data: LiquidityEvent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appBarActionsService: AppBarActionsService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<AccountLiquidityEventAddComponent>
    ) {

    this._unsubscribeAll = new Subject();
    const today = new Date();
    today.setMilliseconds(0);
    today.setSeconds(0);
    today.setMinutes(0);
    today.setHours(0);
    this.liquidityForm = this.formBuilder.group({
      resolutionStatus: [undefined, Validators.required],
      resolutionDate: [today, Validators.required]
    });

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'ok', label: 'Ok', disabled: !this.liquidityForm.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
   }

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
    .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
    .subscribe(this.actionDispatch.bind(this))
  }

  dataInit(data: LiquidityEvent): void {
    this.liquidityForm.patchValue({ ...data  });
  }

  isFormDataValid() {
    return this.liquidityForm.valid && this.liquidityForm.dirty;
  }

  confirm() {
    if (!this.isFormDataValid()) {
      FormHelper.showInvalidFormFields(this.liquidityForm);
      return;
    }

    this.dialogRef.close({
      resolutionStatus: this.liquidityForm.controls.resolutionStatus.value, 
      resolutionDate: this.liquidityForm.controls.resolutionDate.value
    })
  }

  static show(dialog: MatDialog, liquidityEvent: LiquidityEvent): Observable<any> {
    return dialog
      .open(AccountLiquidityEventAddComponent, {
        data: liquidityEvent,
        closeOnNavigation: true,
      })
      .afterClosed();
  }


  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}