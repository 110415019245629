<mat-form-field appearance="outline"
                class="field-wrapper">
  <mat-label>Competitor</mat-label>
  <mat-select [formControl]="competitorsSelectForm"
              placeholder="Competitor"
              [multiple]="true"
              [disabled]="disabled"
              #multiSelect>
    <mat-option>
      <ngx-mat-select-search [formControl]="competitorsFilterForm"
                             placeholderLabel="Find competitor..."
                             noEntriesFoundLabel="No matching competitor found">
      </ngx-mat-select-search>
    </mat-option>
    <button mat-button class="add-button" *ngIf="readWriteCompetitor" (click)="onClickAdd()">
      <mat-icon class="add-icon">add_circle</mat-icon>Add new competitor
    </button>
    <mat-option *ngFor="let competitor of filteredCompetitors | async"
                [value]="competitor">
      {{competitor.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
