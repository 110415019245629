<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <div class="message-box info" *ngIf="data === undefined || data === null" fxLayout="row" fxLayoutGap="12px"
    fxLayoutAlign="start center">
    <mat-icon class="icon">info</mat-icon>
    <span>No data available</span>
  </div>
  <div id="main" fxLayout="row" fxLayoutAlign="center start">
    <iframe title="cash-flow-analysis" [attr.srcDoc]="data | safeHtml" width="100%" height="100%" style="border: 0px;" *ngIf="data !== undefined && data !== null"></iframe>
  </div>
</ifc-app-scroll-container>
