import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../../shared/shared.module";
import { PromotionListComponent } from "./promotion-list/promotion-list.component";
import { PromotionRoutingModule } from "./promotion-routing.module";
import { PromotionListFilterDialogComponent } from "./promotion-list/components/promotion-list-filter-dialog/promotion-list-filter-dialog.component";
import { PromotionNameInputComponent } from "./promotion-list/components/promotion-list-filter-dialog/promotion-name-input/promotion-name-input.component";
import { PromotionDetailsComponent } from "./promotion-details/promotion-details.component";

@NgModule({
  imports: [CommonModule, SharedModule, PromotionRoutingModule],
  declarations: [
    PromotionListComponent,
    PromotionListFilterDialogComponent,
    PromotionNameInputComponent,
    PromotionDetailsComponent
  ],
})
export class PromotionModule {}
