<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <div class="layout margin flex-std"
       [@routerTransition]="'fly'">
    <form>
      <div fxLayout="row"
           fxLayout.lt-md="column"
           fxLayoutAlign="center center"
           fxLayoutGap="24px">
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Status</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.status | offerStatusLabel"
                 disabled />
        </mat-form-field>
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Offer amount</mat-label>
          <input matInput
                 [value]="data.offer.amount | currency:'USD':'symbol':'1.2-2'"
                 disabled />
        </mat-form-field>
      </div>

      <div fxLayout="row"
           fxLayout.lt-md="column"
           fxLayoutAlign="center center"
           fxLayoutGap="24px">
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Offer rate (AIR)</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.interestRate / 100 | percent:'1.2-2'"
                 disabled />
        </mat-form-field>
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Payment frequency</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.paymentFrequency | paymentFrequencyLabel"
                 disabled />
        </mat-form-field>
      </div>

      <div fxLayout="row"
           fxLayout.lt-md="column"
           fxLayoutAlign="center center"
           fxLayoutGap="24px">
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Repayment term</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.repaymentTerm"
                 disabled />
        </mat-form-field>
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Draw term</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.drawTerm"
                 disabled />
        </mat-form-field>
      </div>

      <div fxLayout="row"
           fxLayout.lt-md="column"
           fxLayoutAlign="center center"
           fxLayoutGap="24px">
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>No payment terms</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.noPaymentTerms"
                 disabled />
        </mat-form-field>
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Interest only payment terms</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.interestOnlyPaymentTerms"
                 disabled />
        </mat-form-field>
      </div>

      <div fxLayout="row"
           fxLayout.lt-md="column"
           fxLayoutAlign="center center"
           fxLayoutGap="24px">
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Freeze for days</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.freezeForDays"
                 disabled />
        </mat-form-field>
        <mat-form-field appearance="outline"
                        ngClass.xs="form-field-xs"
                        ngClass.gt-xs="form-field-md">
          <mat-label>Maximum upsell</mat-label>
          <input matInput
                 type="text"
                 [value]="data.offer.maxUpsell"
                 disabled />
        </mat-form-field>
      </div>
    </form>
  </div>
</ifc-app-scroll-container>
