import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppBarAction, MessageService, AppBarActionsService, LoanInfoDto, AppBarTitleService, AppPageService } from 'common';
import { FormHelper } from 'common';
import { MatDialog } from '@angular/material/dialog';
import { AccountData, AccountStateDto } from '../account.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from '../account.service';
import { ReAmortizeDialogComponent } from './re-amortize-dialog/re-amortize-dialog.component';
import { TransactionService } from '../../transaction/transaction.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { AccountStateBalanceUpdateDialogComponent } from '../account-state-balance-update-dialog/account-state-balance-update-dialog.component';

@Component({
  selector: 'ifb-account-state-balance-details',
  templateUrl: './account-state-balance-details.component.html',
  styleUrls: ['./account-state-balance-details.component.scss']
})
export class AccountStateBalanceDetailsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  private saveSub: Subscription;
  form: UntypedFormGroup;
  data: AccountStateDetailsComponentData;
  accountState: AccountStateDto;

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private appPageService: AppPageService,
    private appBarActionsService: AppBarActionsService,
    private messageService: MessageService,
    private transactionService: TransactionService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog) {

    this._unsubscribeAll = new Subject();

    this.formInit();

    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'reAmortize', label: 'Re-Amortize', buttonType: 'submit' },
      { id: 'save', label: 'Save', disabled: !this.form.dirty, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.userPermissionService.granted([readWrite('maintenance-maintain-loan')])
      .subscribe(res => {
        if (!res)
          this.appBarActionsService.enable('reAmortize', false);
      });

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
        this.appBarActionsService.enable('reAmortize', false);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  minExpirationDate = new Date(new Date().setDate(new Date().getDate() + 1));

  dataInit(data: AccountStateDetailsComponentData) {
    this.data = data;

    if (!data.account) return;

    // TODO: edit when other balances are implemented.
    this.accountState = data.account.maintenanceState;
    this.appBarTitleService.title = data.account ? `${data.account.loanNumber} - Maintenance balances` : 'Maintenance balances';
    // TODO End

    this.form.reset({
      principalBalance: this.accountState?.principalBalance,
      feeBalance: this.accountState?.feeBalance,
      totalFundedAmount: this.accountState?.totalFundedAmount,
      totalFees: this.accountState?.totalFees,
      accruedInterest: this.accountState?.accruedInterest,
      totalInterestCharged: this.accountState?.totalInterestCharged,
      paymentAmount: this.accountState?.paymentAmount,
      cycleStartDate: this.accountState?.cycleStartDate,
      repaymentTermOverride: data.account.repaymentTermOverride
    });

    this.transactionService.getUnprocessedByLoan(data.account.id)
      .subscribe(res => {
        if (res.length)
          this.appBarActionsService.enable('reAmortize', false);
      });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  private formInit() {
    this.form = this.formBuilder.group({
      principalBalance: [undefined, Validators.required],
      feeBalance: undefined,
      totalFundedAmount: undefined,
      totalFees: undefined,
      accruedInterest: undefined,
      totalInterestCharged: undefined,
      paymentAmount: undefined,
      repaymentTermOverride: [undefined, Validators.min(1)],
      cycleStartDate: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' })
    });
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    AccountStateBalanceUpdateDialogComponent.show(this.dialog, this.form)
      .subscribe(result => {
        if (result) {
          this.saveSub = this.accountService.updateMaintenanceState(this.data.account.id, this.form.value)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  reAmortize() {
    ReAmortizeDialogComponent.show(
      this.dialog,
      this.calculatePaymentAmount(),
      this.data.accountInfo.totalNumberOfPayments,
      this.data.accountInfo.paymentDueDate
    ).subscribe(result => {
      if (result) {
        this.saveSub = this.accountService.reAmortize(this.data.account.id)
          .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
      }
    });
  }

  calculatePaymentAmount(): number {
    return this.accountService.getPaymentAmount(this.data.account, this.data.accountInfo);
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler(state: AccountStateDto) {
    this.saveSubClear();
    this.appPageService.back();
  }
}

export interface AccountStateDetailsComponentData {
  account: AccountData;
  accountInfo: LoanInfoDto;
}
