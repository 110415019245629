import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AppBarTitleService,
  EnumHelper,
  OfferBundleStatus,
  OfferBundleStatusLabel,
} from "common";
import { readOnly } from "projects/backoffice/src/app/user/user-permission/user-permission.data";
import { UserPermissionService } from "projects/backoffice/src/app/user/user-permission/user-permission.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "ifb-offer-bundle-tabs",
  templateUrl: "./offer-bundle-tabs.component.html",
  styleUrls: ["./offer-bundle-tabs.component.scss"],
})
export class OfferBundleTabsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  navLinks = [];
  data: any;

  constructor(
    private route: ActivatedRoute,
    private appBarTitleService: AppBarTitleService,
    private userPermissionService: UserPermissionService
  ) {
    this._unsubscribeAll = new Subject();
    this.navLinks = [{ path: "details", label: "DETAILS" }];
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: unknown) => this.initData(data));

    this.userPermissionService
      .granted([readOnly("servicing-offers")])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          this.navLinks.push({ path: "offers", label: "OFFERS" });
        }
        this.navLinks.push({
          path: "checkout-requirements",
          label: "CHECKOUT REQUIREMENTS",
        });
      });
  }

  private initData(data: any) {
    this.data = data;
    this.data.appBar.closeAction = `application/${this.route.snapshot.params.id}/offer-bundles`;
    const statusLabel = this.prepareStatusLabel(data.offerBundle.status);
    this.appBarTitleService.title = `${data.offerBundle.name} - ${statusLabel}`;
  }

  private prepareStatusLabel(status: OfferBundleStatus) {
    return EnumHelper.getMappedNameFromValue(
      OfferBundleStatus,
      OfferBundleStatusLabel,
      status
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
