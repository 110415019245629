import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifb-application-status-icon-chip',
  templateUrl: './application-status-icon-chip.component.html',
  styleUrls: ['./application-status-icon-chip.component.css']
})
export class ApplicationStatusIconChipComponent {

  @Input()
  status = 0;

  statusLabels = {};
}
