<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <div class="layout margin flex-std"
       [@routerTransition]="'fly'">
    <ifb-panel>
      <ifb-card>
        <ifb-offer-details-widget [data]="data"> </ifb-offer-details-widget>
      </ifb-card>
    </ifb-panel>
  </div>
</ifc-app-scroll-container>
