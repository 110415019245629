<ifb-card>
  <mat-card class="ifc-card ">
    <div class="header">
      <ng-container *ngIf="isNPLLoan() && !accessNPLEconomics">
        <div class="layout padding">
          <div class="message layout padding-vertical">This account is frozen. Message has been sent to customer to
            contact our Legal department at
            legal@ideafinancial.com.</div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isNPLLoan() || accessNPLEconomics">
        <div fxLayout="row"
             fxLayoutAlign="space-between start"
             class="layout padding-md">
          <div class="title">Maintenance balances</div>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="18px"
             class="content">
          <div fxFlex="50">
            <ifc-bar-chart [data]="barChartData"
                                [options]="barChartOptions">
            </ifc-bar-chart>
            <div fxLayout="column"
                 fxLayoutAlign="start center"
                 fxLayoutGap="8px">
              <div class="mat-caption">{{label}}: {{accountState?.stateData.utilization / 100 | percent:'1.2-2'}}</div>
            </div>
          </div>
          <div fxLayout="column"
               fxLayoutAlign="start start"
               fxLayoutGap="24px"
               fxFlex="50">
            <div>
              <div>{{(accountState?.stateData.principalBalance | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">Principal Balance</div>
            </div>
            <div>
              <div>{{(accountState?.stateData.accruedInterest | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">Accrued interest</div>
            </div>
            <div>
              <div>{{(accountState?.stateData.paymentAmount | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">Payment amount</div>
            </div>
          </div>
        </div>
        <button *ngIf="edit"
                mat-mini-fab
                class="add-button"
                color="primary"
                routerLink="balances"
                aria-label="Edit">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
    </div>

    <mat-card-content *ngIf="!isNPLLoan() || accessNPLEconomics">
      <mat-list>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Available funds</span>
          <p class="item-content">{{(account?.loanInfo?.loanAvailableFunds | currency:'$') || 'Null'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="isLCF">
          <span class="secondary-text"
                mat-line>MSA available funds</span>
          <p class="item-content">{{(account?.loanInfo?.msaAvailableFunds | currency:'$') || 'Null'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Pay off amount</span>
          <p class="item-content">
            {{(accountState?.stateData.principalBalance + accountState?.stateData.accruedInterest  | currency:'$') || 'Null'}}
          </p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Last draw</span>
          <p class="item-content">{{accountState?.stateData.lastDrawDate | date}} -
            {{accountState?.stateData.lastDrawAmount | currency:'$'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>First draw</span>
          <p class="item-content">{{accountState?.stateData.firstDrawDate | date}} -
            {{accountState?.stateData.firstDrawAmount | currency:'$'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Last auto-payment</span>
          <p class="item-content">{{accountState?.stateData.lastAutoPaymentDate | date}} -
            {{accountState?.stateData.lastAutoPaymentAmount | currency:'$'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Last pre-payment</span>
          <p class="item-content">{{accountState?.stateData.lastManualPaymentDate | date}} -
            {{accountState?.stateData.lastManualPaymentAmount | currency:'$'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Fee balance</span>
          <p class="item-content">{{accountState?.stateData.feeBalance | currency:'$'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Total draw fees</span>
          <p class="item-content">{{accountState?.stateData.totalFees | currency:'$'}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Total interest</span>
          <span class="item-content">{{accountState?.stateData?.totalInterestCharged | currency:'$'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Total interest paid</span>
          <span class="item-content">{{accountState?.stateData?.totalInterestPaid | currency:'$'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Total principal paid</span>
          <span class="item-content">{{accountState?.stateData?.totalPrincipalPaid | currency:'$'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Cycle start date</span>
          <p class="item-content">{{accountState?.stateData.cycleStartDate | date}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Cycle start day</span>
          <p class="item-content">{{accountState?.stateData.cycleStartDay}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text no-wrap"
                mat-line>Repayment term override</span>
          <p class="item-content"
             *ngIf="account?.repaymentTermOverride">{{account?.repaymentTermOverride}} months</p>
          <p class="item-content"
             *ngIf="!account?.repaymentTermOverride">-</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text no-wrap"
                mat-line>Last re-amortization date</span>
          <p class="item-content">{{account?.lastReAmortizationDate | date}}</p>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="secondary-text no-wrap"
                mat-line>Last maintenance date</span>
          <p class="item-content">{{accountState?.lastMaintenanceDate | date}}</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ifb-card>
