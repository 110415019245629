import { Component, Input } from "@angular/core";
import { BrokerExData } from "../broker.model";

@Component({
  selector: "ifb-broker-activity-widget",
  templateUrl: "./broker-activity-widget.component.html",
  styleUrls: ["./broker-activity-widget.component.scss"],
})
export class BrokerActivityWidgetComponent {
  
  @Input()
  broker: BrokerExData;
}
