import { Component, Input } from '@angular/core';
import { EntityExData } from 'common';

@Component({
  selector: 'ifb-entity-profile-broker-widget',
  templateUrl: './entity-profile-broker-widget.component.html',
  styleUrls: ['./entity-profile-broker-widget.component.scss']
})
export class EntityProfileBrokerWidgetComponent {

  constructor() { }

  @Input()
  data: EntityExData;

  @Input()
  edit: boolean;

}
