import { ApplicationBaseData } from "common";
import { AppBarExpandedTabWithPermission } from "../app.data";
import { readOnly } from "../user/user-permission/user-permission.data";
import { NoteData } from "../shared/note/note.model";

export interface ApplicationData extends ApplicationBaseData {
    notes?: NoteData[];
}

export class ApplicationFilters {
    businessName: any;
    applicationNumber: any;
    loanNumber: any;
    stage: any;
    companyId: number;
    createdOn: Date;
    createdOnStart: Date;
    createdOnEnd: Date;
    bankLinkStatus: any;
    lastLinkingAttemptDate: Date;
    lastLinkingAttemptDateFrom: Date;
    lastLinkingAttemptDateTo: Date;
    bankLinkAttempted: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.businessName = filters.businessName || undefined;
        this.applicationNumber = filters.applicationNumber || undefined;
        this.loanNumber = filters.loanNumber || undefined;
        this.stage = filters.stage;
        this.companyId = filters.companyId;
        this.createdOn = filters.createdOn || undefined;
        this.createdOnStart = filters.createdOnStart || undefined;
        this.createdOnEnd = filters.createdOnEnd || undefined;
        this.bankLinkStatus = filters.bankLinkStatus;
        this.lastLinkingAttemptDate = filters.lastLinkingAttemptDate || undefined;
        this.lastLinkingAttemptDateFrom = filters.lastLinkingAttemptDateFrom || undefined;
        this.lastLinkingAttemptDateTo = filters.lastLinkingAttemptDateTo || undefined;
        this.bankLinkAttempted = filters.bankLinkAttempted === 'true' ? true : (filters.bankLinkAttempted === 'false' ? false : undefined);
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'createdOn' || item === 'createdOnStart') {
            this.createdOn = undefined;
            this.createdOnStart = undefined;
            this.createdOnEnd = undefined;
        }

        if (item === 'lastLinkingAttemptDate' || item === 'lastLinkingAttemptDateFrom') {
            this.lastLinkingAttemptDate = undefined;
            this.lastLinkingAttemptDateFrom = undefined;
            this.lastLinkingAttemptDateTo = undefined;
        }
    }
}

export const applicationAppBarExpandedTabs = (applicationId: number): AppBarExpandedTabWithPermission[] => [
    {
        label: 'Profile',
        url: `/application/${applicationId}/profile`,
        permission: [readOnly('servicing-applications')],
        hidden: true
    },
    {
        label: 'Offer bundles',
        url: `/application/${applicationId}/offer-bundles`,
        permission: [readOnly('servicing-offer-bundles')],
        hidden: true
    },
    {
        label: 'Bank accounts',
        url: `/application/${applicationId}/bank-accounts`,
        permission: [readOnly('servicing-bank-accounts')],
        hidden: true
    },
    {
        label: 'Bank analysis',
        url: `/application/${applicationId}/bank-analysis`,
        permission: [readOnly('servicing-bank-analysis'), readOnly('servicing-entities-sensitive-data')],
        allPermissionsRequired: true,
        hidden: true
    },
    {
        label: 'Documents',
        url: `/application/${applicationId}/documents`,
        permission: [readOnly('servicing-application-documents')],
        allPermissionsRequired: true,
        hidden: true
    }
];

export interface ApplicationHintData {
    id: number;
    applicationNumber: string;
}

export interface ApplicationHintParams {
    id?: number;
    applicationNumber?: string;
    sortField?: string;
}

export interface ApplicationRenewalData extends ApplicationRenewalRequestData {
  applicationId: number;
  renewalForLoanNumber: string;
}

export interface ApplicationRenewalRequestData {
  renewalForLoanId: number;
  renewalReady: boolean;
}
