import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, FilterParameter } from 'common';
import { AuditQueryParams, AuditFilters } from './audit-trail.model';

@Injectable({
    providedIn: 'root'
})
export class AuditTrailFilterService {

    constructor(private router: Router, private route: ActivatedRoute) { }

    filter(filters: AuditFilters, paginator?: MatPaginator, sort?: MatSort) {

        const query: AuditQueryParams = new AuditQueryParams(filters);
        // Set default to first page
        query.skip = undefined;

        query.dateFrom = query.dateFrom ? moment(query.dateFrom).format('L') : undefined;
        query.dateTo = query.dateTo ? moment(query.dateTo).format('L') : undefined;
        query.eventName = typeof query.event === 'object' ? filters.event.name : query.event;
        query.event = typeof query.event === 'object' ? filters.event.value : query.event;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        this.router.navigate([this.router.url.split('?')[0]], { replaceUrl: true, queryParams: query });
    }

    addFilterParameters(query: AuditQueryParams): FilterParameter[] {
        const filters: FilterParameter[] = [];

        if (query.event && query.eventName) {
            filters.push({
                id: 'event', name: 'Event', value: query.eventName.trim()
            });
        }

        if (query.initiator) {
            filters.push({
                id: 'initiator', name: 'Initiator', value: query.initiator.trim()
            });
        }

        if (query.dateFrom) {
            filters.push({
                id: 'dateFrom', name: 'Date',
                value: `${moment(query.dateFrom).format('L')} - ${moment(query.dateTo).format('L')}`
            });
        }

        return filters;
    }
}
