import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableHelper } from "common";
import { OfferListQueryParams } from "./models/offer-query.model";

@Injectable({
  providedIn: "root",
})
export class OfferFilterService {
  constructor(private router: Router) {}

  filter(applicationId: number, offerBundleId: number, paginator?: MatPaginator, sort?: MatSort) {
    const query: OfferListQueryParams = { skip: undefined };

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }

    this.router.navigate(["application", applicationId, "offer-bundle", offerBundleId, "offers"], {
      replaceUrl: true,
      queryParams: query,
    });
  }
}
