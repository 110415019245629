import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from '../../../account/account.service';
import { NotesSidenavComponent } from '../notes-sidenav.component';
import { UserPermissionService } from '../../../user/user-permission/user-permission.service';
import { readOnly } from '../../../user/user-permission/user-permission.data';

@Component({
  selector: 'ifb-account-notes-sidenav',
  templateUrl: '../notes-sidenav.component.html',
  styleUrls: ['../notes-sidenav.component.scss']
})
export class AccountNotesSidenavComponent extends NotesSidenavComponent implements OnInit {

  @Input()
  accountId: number;

  constructor(private accountService: AccountService, private userPermissionService: UserPermissionService) {
    super();
  }

  ngOnInit(): void {
    this.userPermissionService.granted([readOnly('servicing-account-edit-notes')])
      .subscribe(res => this.canEditNotes = res);
  }

  addNote(value: string): void {
    this.accountService.addNote(this.accountId, value).subscribe();
  }
}
