import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifp-customer-phone-status-icon-chip',
  templateUrl: './customer-phone-status-icon-chip.component.html',
})
export class CustomerPhoneStatusIconChipComponent {

  constructor() { }

  @Input()
  status = 0;

  statusLabels = {
    'unverified' : { color: 'mat-pure-red-bg', icon: 'error', label: 'Unverified' },
  };
}
