import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, delay, map, switchMap } from "rxjs/operators";
import { AccountService } from "../../account/account.service";
import { CustomErrorCode } from "./custom-error-code.enum";

export class CustomAsyncValidators {
  static checkLoanNumber(accountService: AccountService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue = control.value?.loanNumber || control.value;

      return of(control.value).pipe(
        delay(600),
        switchMap(() =>
          accountService
            .queryHints({
              loanNumber: controlValue,
              sortField: "loanNumber",
            })
            .pipe(
              map((data) => {
                const validLoanNumber = data?.some(
                  (item) => item.loanNumber === controlValue
                );
                if (!validLoanNumber) {
                  return { [CustomErrorCode.InvalidLoanNumber]: true };
                }
                return null;
              }),
              catchError(() => {
                return of({ [CustomErrorCode.InvalidLoanNumber]: true });
              })
            )
        )
      );
    };
  }
}
