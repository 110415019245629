import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, forkJoin, of } from "rxjs";
import { entityAppBarExpandedTabs } from "./entity.model";
import { EntityService } from "./entity.service";
import { map, switchMap, catchError } from "rxjs/operators";
import { UserPermissionService } from "../user/user-permission/user-permission.service";
import { AccountQueryParams, AppBarExpandedTab, EntityExData, EntityQueryParams, EntityQueryResult, parseNumber } from "common";
import { AccountService } from "../account/account.service";
import { AccountData } from "../account/account.model";
import { AuditQueryParams, AuditQueryResult } from "../shared/audit-trail/audit-trail.model";
import { AuditTrailService } from "../shared/audit-trail/audit-trail.service";
import { ApplicationService } from "../application/application.service";
import { CustomerData } from "../customer/customer.model";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Injectable({
    providedIn: 'root'
})
export class EntityResolver implements Resolve<EntityExData> {
    constructor(private _service: EntityService,
        private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EntityExData> | Promise<EntityExData> | EntityExData {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return id === 'new'
            ? undefined
            : this._service.get(parseNumber(id)).pipe(
                map(res => res),
                catchError(error => {
                    if (error.status === 404)
                        this.router.navigate(['error', '404']);
                    return of(null);
                })
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityAccountsResolver implements Resolve<AccountData[]> {
    constructor(private service: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountData[]> | Promise<AccountData[]> | AccountData[] {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return this.service.query(new AccountQueryParams(
          this.queryParamsService.init({
            entityId: id
          }))).pipe(
            map(res => res ? res.values : null)
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityForAccountResolver implements Resolve<EntityExData> {
    constructor(
        private accountService: AccountService,
        private entityService: EntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EntityExData> | Promise<EntityExData> | EntityExData {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return this.accountService.get(parseNumber(id))
            .pipe(
                map(res => res),
                switchMap(res => res ? this.entityService.get(res.entityId) : null),
                map(res => res ? res : null)
            );
    }
}
@Injectable({
    providedIn: 'root'
})
export class EntityForAccountByUniqueIdResolver implements Resolve<EntityExData> {
    constructor(
        private accountService: AccountService,
        private entityService: EntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EntityExData> | Promise<EntityExData> | EntityExData {
        const id = route.params.uniqueId !== undefined ?
            route.params.uniqueId : route.pathFromRoot[route.pathFromRoot.length - 2].params.uniqueId;
        return this.accountService.getByUniqueId(id)
            .pipe(
                map(res => res),
                switchMap(res => res ? this.entityService.get(res.entityId) : null),
                map(res => res ? res : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityAccountsResolverForLoan implements Resolve<AccountData[]> {
    constructor(private service: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountData[]> | Promise<AccountData[]> | AccountData[] {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return this.service.get(parseNumber(id))
            .pipe(
                map(res => res),
                switchMap(res => res ? this.service.query(this.queryParamsService.init({ entityId: res.entityId })) : null),
                map(res => res ? res.values : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityAccountResolverForApplication implements Resolve<AccountData[]> {
    constructor(private service: ApplicationService, private accountService: AccountService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<AccountData[]> | Promise<AccountData[]> | AccountData[] {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        return this.service.get(id)
            .pipe(
                map(res => res),
                switchMap(res => res ? this.accountService.query(this.queryParamsService.init({ entityId: res.entityId })) : null),
                map(res => res ? res.values : null)
            );
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityAppBarExpandedTabsResolver implements Resolve<{ tabs: AppBarExpandedTab[] }> {
    constructor(private permissionService: UserPermissionService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return forkJoin(
            entityAppBarExpandedTabs(parseNumber(route.params.id)).map(it => this.permissionService.visibility(it))
        ).pipe(map(tabs => ({ tabs })));
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityQueryResolver implements Resolve<EntityQueryResult> {
    constructor(private _service: EntityService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<EntityQueryResult> | Promise<EntityQueryResult> | EntityQueryResult {

        const params = new EntityQueryParams(this.queryParamsService.init(route.queryParams));

        if (params.createdOnStart) {
            params.createdOnStart = new Date(params.createdOnStart);
            params.createdOnStart.setHours(0, 0, 0, 0);
        }

        if (params.createdOnEnd) {
            params.createdOnEnd = new Date(params.createdOnEnd);
            params.createdOnEnd.setHours(23, 59, 59, 999);
        }

        if (params.updatedOnStart) {
            params.updatedOnStart = new Date(params.updatedOnStart);
            params.updatedOnStart.setHours(0, 0, 0, 0);
        }

        if (params.updatedOnEnd) {
            params.updatedOnEnd = new Date(params.updatedOnEnd);
            params.updatedOnEnd.setHours(23, 59, 59, 999);
        }

        return this._service.query(params);
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityAuditTrailResolver implements Resolve<AuditQueryResult> {
    constructor(private service: AuditTrailService, private queryParamsService: QueryParamsService	) { }

    resolve(route: ActivatedRouteSnapshot): Observable<AuditQueryResult> | Promise<AuditQueryResult> | AuditQueryResult {
        const id = route.params.id !== undefined ? route.params.id : route.pathFromRoot[route.pathFromRoot.length - 2].params.id;
        const params = new AuditQueryParams(this.queryParamsService.init({ entity: id, ...route.queryParams }));
        return this.service.audit(params);
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntityOwnersResolver implements Resolve<CustomerData[]> {
    constructor(private service: EntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<CustomerData[]> | Promise<CustomerData[]> | CustomerData[] {
        return this.service.getOwnersList(parseNumber(route.params.id));
    }
}
