import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BankAccountRole, EnumHelper, BankAccountType } from 'common';
import { BankAccountsListGroupedByInstitutionData } from '../../bank/bank.model';
import { BrokerData } from '../broker.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ifb-broker-bank-account-widget',
  templateUrl: './broker-bank-account-widget.component.html',
  styleUrls: ['./broker-bank-account-widget.component.css']
})
export class BrokerBankAccountWidgetComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  constructor(private router: Router,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  readWriteBanks = false;

  @Input()
  broker: BrokerData;

  @Input()
  accountsGroupedByInstitution: BankAccountsListGroupedByInstitutionData;

  get BankAccountRole() { return BankAccountRole; }

  ngOnInit() {
    this.userPermissionService.granted([readWrite('servicing-bank-accounts')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.readWriteBanks = res;
      });
  }

  getBankAccountType(bankAccountType: string): string {
    return bankAccountType ? EnumHelper.getNameFromValue(BankAccountType, bankAccountType) : null;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  bankAccountDetailsLink(bankAccountId: number) {
    this.router.navigate([`broker/${this.broker.id}/banks/${bankAccountId}`]);
  }

}
