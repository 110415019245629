import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BrokerExData, BrokerPricingPlanLabel, BrokerCommissionPlanLabel } from '../broker.model';

@Component({
  selector: 'ifb-broker-commission-widget',
  templateUrl: './broker-commission-widget.component.html',
  styleUrls: ['./broker-commission-widget.component.css']
})
export class BrokerCommissionInfoWidgetComponent {

  constructor(private router: Router) { }

  @Input()
  broker: BrokerExData;

  @Input()
  edit: boolean;

  detailsLink() {
    this.router.navigate([`broker/${this.broker.id}/commission`]);
  }

  formatPricingPlan(plan: string): string {
    return BrokerPricingPlanLabel.get(plan);
  }

  formatCommissionPlan(plan: string): string {
    return BrokerCommissionPlanLabel.get(plan);
  }
}
