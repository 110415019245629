import { Injectable } from '@angular/core';
import { UserQueryParams, UserQueryResult, UserData, UserHintData, UserHintParams } from './user.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private settings: AppSettings) { }

  get(id: number): Observable<UserData> {
    return this.http.get<UserData>(`${this.settings.oauth.url}/api/user/${id}`);
  }

  query(params: UserQueryParams): Observable<UserQueryResult> {
    return this.http.get<UserQueryResult>(`${this.settings.oauth.url}/api/user/query`, { params: <any>params });
  }

  queryHints(query: UserHintParams): Observable<UserHintData[]> {
    return this.http.get<UserHintData[]>(`${this.settings.oauth.url}/api/user/hints`, {
      params: <any>query
    });
  }

}
