import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBarActionsComponent, AppBarComponent, AppBarTitleComponent, AppPageComponent, AppSidenavComponent } from 'common';
import { appPageComponentDataDetail, appPageComponentDataMaster } from '../app.data';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionQueryResolver, TransactionAuditTrailResolver } from './transaction.resolver';
import { TransactionDetailsResolver, AccountTransactionDetailsResolver } from './transaction-details/transaction-details.resolver';
import { UserCurrentResolver } from '../user/user.resolver';
import { FailureReasonListResolver } from '../admin/failure-reason/failure-reason.resolver';
import { BOAuthGuard } from '../guards/oauth.guard';
import { PromotionsToSelectResolver } from './promotions-to-select.resolver';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Transactions',
  sideNavItem: 'transactions'
});
const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
    {
      path: 'transactions',
      component: AppPageComponent,
      data: appPageDataMaster,
      resolve: {
        appPage: AppPageResolver,
        appSidenav: AppSidenavResolver
      },
      children: [
        {
          path: '',
          outlet: 'sidenav-content',
          component: AppSidenavComponent
        },
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: UserMenuComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionListComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            transactionQueryResult: TransactionQueryResolver,
            failureReasons: FailureReasonListResolver,
            promotionsToSelect: PromotionsToSelectResolver
          }
        }
      ]
    },
    {
      path: 'account/:accountId/transaction/:id',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            transactionDetails: TransactionDetailsResolver,
            userCurrent: UserCurrentResolver,
            auditTrail: TransactionAuditTrailResolver,
            failureReasons: FailureReasonListResolver,
          }
        }
      ]
    },
    {
      path: 'account/:accountId/transaction/:id/admin',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          data: { admin: true },
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            transactionDetails: TransactionDetailsResolver,
            userCurrent: UserCurrentResolver,
            auditTrail: TransactionAuditTrailResolver,
            promotionsToSelect: PromotionsToSelectResolver,
            failureReasons: FailureReasonListResolver,
          }
        },
      ]
    },
    {
      path: 'account/:accountId/transaction/:id/view-mode',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          data: { viewMode: true },
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            transactionDetails: TransactionDetailsResolver,
            userCurrent: UserCurrentResolver,
            auditTrail: TransactionAuditTrailResolver,
            promotionsToSelect: PromotionsToSelectResolver,
            failureReasons: FailureReasonListResolver,
          }
        }
      ]
    },
    {
      path: 'account/:accountId/transaction/:id/embedded-mode',
      component: AppPageComponent,
      data: {
        appBar: {
          closeAction: false,
          expandable: false,
          logoHidden: true,
        },
        appBarActions: {
          menuMobile: true
        },
        appPage: {
          contentAlignment: 'center-center',
        }
      },
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          data: { viewMode: true, embeddedView: true },
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            transactionDetails: TransactionDetailsResolver,
            userCurrent: UserCurrentResolver,
            auditTrail: TransactionAuditTrailResolver
          }
        }
      ]
    },
    {
      path: 'transaction-new/:transactionType',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          resolve: {
            userCurrent: UserCurrentResolver,
            promotionsToSelect: PromotionsToSelectResolver
          }
        }
      ]
    },
    {
      path: 'transaction-new/:transactionType/:accountId',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          resolve: {
            userCurrent: UserCurrentResolver,
            transactionDetails: AccountTransactionDetailsResolver,
            promotionsToSelect: PromotionsToSelectResolver
          }
        }
      ]
    },
    {
      path: 'transaction-new-admin',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          data: { admin: true },
          resolve: {
            userCurrent: UserCurrentResolver,
            promotionsToSelect: PromotionsToSelectResolver
          }
        }
      ]
    },
    {
      path: 'transaction-new-admin/:accountId',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: TransactionDetailsComponent,
          data: { admin: true },
          resolve: {
            userCurrent: UserCurrentResolver,
            transactionDetails: AccountTransactionDetailsResolver,
            promotionsToSelect: PromotionsToSelectResolver
          }
        }
      ]
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionRoutingModule { }
