<mat-card class="ifc-card"
          [ngClass.xs]="'xs'">
  <div fxLayout="row"
       fxLayoutAlign="space-between start"
       class="card-header">
    <div class="card-title">Status</div>
  </div>

  <mat-card-content class="">
    <div fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap="18px">
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Status</span>
          <p class="item-content">{{data?.offerBundle?.status | offerBundleStatusLabel}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Updated on</span>
          <p class="item-content">{{(data?.offerBundle?.updatedOn | date) || '-'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Express checkout</span>
          <p class="item-content">{{data?.offerBundle?.expressCheckout ? 'Yes' : 'No'}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Salesforce ID</span>
          <a class="item-content"
             *ngIf="data?.offerBundle?.uniqueId"
             href="{{data?.offerBundle?.uniqueId | salesforceUrl}}"
             target="_blank"
             rel="noopener">
            {{data?.offerBundle?.uniqueId}}
          </a>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
