<mat-card class="ifc-card" [ngClass.xs]="'xs'">

  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title"> Status and activation </div>
    <button *ngIf="edit" (click)="detailsLink()" mat-icon-button aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-card-content class="layout padding-md">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">

      <mat-list>

        <mat-list-item>
          <span class="secondary-text" mat-line> Status </span>
          <p class="item-content">{{formatStatus(broker?.status)}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Created on </span>
          <p class="item-content">{{(broker?.createdOn | date) || '-'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Created by </span>
          <p class="item-content">{{broker?.createdBy || '-'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Updated on </span>
          <p class="item-content">{{(broker?.updatedOn | date) || '-'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Updated by </span>
          <p class="item-content">{{broker?.updatedBy || '-'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Contract date </span>
          <p class="item-content">{{(broker?.contractDate | date) || '-'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Active </span>
          <p class="item-content">{{broker?.active ? 'Active' : 'Inactive'}}</p>
        </mat-list-item>

      </mat-list>

    </div>

  </mat-card-content>

</mat-card>