import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AccountAmortizationData } from '../account.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ifb-account-payment-list',
  templateUrl: './account-payment-list.component.html',
  styleUrls: ['./account-payment-list.component.css']
})
export class AccountPaymentListComponent implements OnInit, OnChanges {

  constructor() { }

  dataSource = new MatTableDataSource<AccountAmortizationData>();

  @Input() data: AccountAmortizationData[];
  @Input() paginator: MatPaginator;

  ngOnInit() {
    if (this.data) {
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
    }
  }

  tableColumns: string[] = [
    'paymentNumber',
    'date',
    'type',
    'amount',
    'principalAmount',
    'interestAmount',
    'balance',
    'interestBalance',
    'totalInterest',
    'totalPrincipal',
    'totalPayback'
  ];

}
