<h1 mat-dialog-title>Update account balances?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are updating account balances:
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">

      <div *ngIf="data.form.controls.principalBalance && !data.form.controls.principalBalance.pristine"
        fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.principalBalance | currency:'$'}}</div>
        <div class="mat-caption"> Principal balance </div>
      </div>

      <div *ngIf="data.form.controls.accruedInterest && !data.form.controls.accruedInterest.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.accruedInterest | currency:'$'}}</div>
        <div class="mat-caption"> Accrued interest </div>
      </div>

      <div *ngIf="data.form.controls.totalInterestCharged && !data.form.controls.totalInterestCharged.pristine"
        fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.totalInterestCharged | currency:'$'}}</div>
        <div class="mat-caption"> Total interest </div>
      </div>

      <div *ngIf="data.form.controls.paymentAmount && !data.form.controls.paymentAmount.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.paymentAmount | currency:'$'}}</div>
        <div class="mat-caption"> Payment amount </div>
      </div>

      <div *ngIf="data.form.controls.totalFundedAmount && !data.form.controls.totalFundedAmount.pristine"
        fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.totalFundedAmount | currency:'$'}}</div>
        <div class="mat-caption"> Total funded amount </div>
      </div>

      <div *ngIf="data.form.controls.feeBalance && !data.form.controls.feeBalance.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.feeBalance | currency:'$'}}</div>
        <div class="mat-caption"> Fee balance </div>
      </div>

      <div *ngIf="data.form.controls.totalFees && !data.form.controls.totalFees.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.totalFees | currency:'$'}}</div>
        <div class="mat-caption"> Total fees </div>
      </div>

      <div *ngIf="data.form.controls.repaymentTermOverride && !data.form.controls.repaymentTermOverride.pristine"
        fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.repaymentTermOverride}}</div>
        <div class="mat-caption"> Repayment term override </div>
      </div>

      <div *ngIf="data.form.controls.cycleStartDate && !data.form.controls.cycleStartDate.pristine"
        fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.cycleStartDate | date}}</div>
        <div class="mat-caption"> Cycle start date </div>
      </div>

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>