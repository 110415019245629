import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { BankAccountTransactionListComponent } from '../../bank/bank-account-transaction-list/bank-account-transaction-list.component';
import { routerTransition } from '../../shared/animations/router.animations';
import { MatDialog } from '@angular/material/dialog';
import { AppBarActionsService, AppBarTitleService } from 'common';
import { TransactionBankTransactionListFilterService } from './transaction-bank-transaction-list-filter.service';
import { BankTransactionFilters, BankTransactionStatus, BankTransactionType } from '../../bank/bank.model';
import { AccountData } from '../../account/account.model';
import { BankTransactionsService } from '../../bank/bank-transactions.service';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-transaction-bank-transactions',
  templateUrl: '../../bank/bank-account-transaction-list/bank-account-transaction-list.component.html',
  styleUrls: ['../../bank/bank-account-transaction-list/bank-account-transaction-list.component.scss'],
  animations: routerTransition
})
export class TransactionBankTransactionsComponent extends BankAccountTransactionListComponent implements OnInit {
  private _bankTransactionsService: BankTransactionsService;
  private _filterService: TransactionBankTransactionListFilterService;

  @Input()
  account: AccountData;

  @Input()
  bankAccountId: number;

  @Input()
  transactionDateFrom: Date;

  @Input()
  transactionDateTo: Date;

  @Input()
  postedDateFrom: Date;

  @Input()
  postedDateTo: Date;

  @Input()
  fromAmount: number;

  @Input()
  toAmount: number;

  @Input()
  status: BankTransactionStatus;

  @Input()
  type: BankTransactionType;

  @Input()
  category: string;

  @Input()
  active: boolean;

  constructor(
    route: ActivatedRoute,
    dialog: MatDialog,
    bankTransactionsService: BankTransactionsService,
    bankTransactionListFilterService: TransactionBankTransactionListFilterService,
    appBarActionsService: AppBarActionsService,
    appBarTitleService: AppBarTitleService,
    queryParamsService: QueryParamsService) {
    super(route, dialog, bankTransactionsService, bankTransactionListFilterService, appBarActionsService, appBarTitleService, queryParamsService);

    this._bankTransactionsService = bankTransactionsService;
    this._filterService = bankTransactionListFilterService;
  }

  ngOnInit() {
    this.filters = new BankTransactionFilters();

    if (this.transactionDateFrom)
      this.filters.transactionDateFrom = this.transactionDateFrom;

    if (this.transactionDateTo)
      this.filters.transactionDateTo = this.transactionDateTo;

    if (this.postedDateFrom)
      this.filters.postedDateFrom = this.postedDateFrom;

    if (this.postedDateTo)
      this.filters.postedDateTo = this.postedDateTo;

    if (this.fromAmount)
      this.filters.fromAmount = this.fromAmount;

    if (this.toAmount)
      this.filters.toAmount = this.toAmount;

    if (this.status)
      this.filters.status = this.status;

    if (this.type)
      this.filters.type = this.type;

    if (this.category)
      this.filters.category = this.category;

    this.filters.active = this.active;

    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;

    this._filterService.filter(this.filters,
      this.bankAccountId,
      this.account ? this.account.entityId : null,
      this.data.account ? this.data.account.id : null,
      this.data.application ? this.data.application.id : null,
      this.paginator,
      this.sort);

    this.afterFiltering();
  }

  download() {
    this._bankTransactionsService.downloadBankTransactions(this.account.entityId, this.bankAccountId, this._filterService.getFilters(this.route.params));
  }

  afterFiltering() {
    this.activeFilters = this._filterService.getActiveFilters();
    this.refreshData();
  }

  afterSorting() {
    this.refreshData();
  }

  afterPaging() {
    this.refreshData();
  }

  private refreshData() {
    if (this.account && this.bankAccountId) {
      this.data.account = this.account;

      this
        ._bankTransactionsService
        .queryBankTransactions(this.account.entityId, this.bankAccountId, this._filterService.getFilters(this.route.params))
        .subscribe(r => this.data.bankTransactionQueryResult = r);
    }
  }
}
