<div [@routerTransition]="'fly'">

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info"
           *ngIf="!data.offerBundleListResult?.totalCount"
           fxLayout="row"
           fxLayoutGap="12px"
           fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table class="table"
             *ngIf="data.offerBundleListResult?.totalCount"
             mat-table
             matSort
             [dataSource]="data.offerBundleListResult.values"
             (matSortChange)="filter($event)"
             aria-describedby="offer bundle list">
        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="name"> Offer bundle name </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/application/{{data.application?.id}}/offer-bundle/{{row.id}}"
              class="table-cell"> {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="status"> Offer bundle status </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/application/{{data.application?.id}}/offer-bundle/{{row.id}}"
              class="table-cell">
            {{row.status | offerBundleStatusLabel}}
          </td>
        </ng-container>

        <ng-container matColumnDef="expressCheckout">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="expressCheckout"> Express checkout </th>
          <td mat-cell
              *matCellDef="let row"
              routerLink="/application/{{data.application?.id}}/offer-bundle/{{row.id}}"
              class="table-cell">
            {{row.expressCheckout ? "Yes" : "No"}} </td>
        </ng-container>

        <ng-container matColumnDef="uniqueId">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="uniqueId"> Salesforce ID </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            <a class="salesforce-url"
               *ngIf="row?.uniqueId"
               href="{{row.uniqueId | salesforceUrl}}"
               target="_blank"
               rel="noopener">
              {{row.uniqueId}}
            </a>
          </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="tableColumns"
            class="table-header"></tr>
        <tr mat-row
            *matRowDef="let row; columns: tableColumns;"
            class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.offerBundleListResult?.totalCount <= 10">
    <mat-paginator class="paginator"
                   #paginator
                   showFirstLastButtons
                   [length]="data.offerBundleListResult.totalCount"
                   [pageSizeOptions]="[10, 20, 50]"
                   (page)="filter()">
    </mat-paginator>
  </div>

</div>
