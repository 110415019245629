import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subscription, Subject } from 'rxjs';
import { FilterParameter } from 'common';
import { CustomerService } from '../customer.service';
import { CustomerQueryResult, CustomerData, CustomerFilters, CustomerQueryParams } from '../customer.model';
import { CustomerFilterService } from '../customer-filter.service';
import { CustomerListFilterDialogComponent } from '../customer-list-filter-dialog/customer-list-filter-dialog.component';
import { readOnly } from '../../user/user-permission/user-permission.data';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { AppSettings } from '../../../app.settings';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private subs: Subscription[] = [];

  filters: CustomerFilters;
  activeFilters: FilterParameter[] = [];

  canReadAccounts: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private settings: AppSettings,
    private _customerFilterService: CustomerFilterService,
    private _location: Location,
    private queryParamsService: QueryParamsService
  ) {
    this._unsubscribeAll = new Subject();
  }

  data: CustomerListComponentData;

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe((it: CustomerListComponentData) => {
        this.data = it;
      }),
      this.route.queryParams.subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.activeFilters = this._customerFilterService.addFilterParameters(this.queryParams);
      })
    ];

    this.userPermissionService.granted([readOnly('servicing-accounts')])
      .subscribe(res => this.canReadAccounts = res);

    this.filters = new CustomerFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  customerDetailsLink(customer: CustomerData, newWindow: boolean) {
    if (!this.canReadAccounts)
      return;
    this.customerService.prevCustomerListUrl = this._location.path().toString();
    const path = `/customer/${customer.id}/profile`;
    if (newWindow)
      window.open(path);
    else
      this.router.navigate([path]);
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this._customerFilterService.filter(this.filters);
    });
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  filterDialog() {
    CustomerListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this._customerFilterService.filter(result);
        }
      });
  }

  sortData(sort: Sort) {
    this._customerFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this._customerFilterService.filter(this.filters, this.paginator, this.sort);
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  queryParams: CustomerQueryParams;

  salesforceLink(customer: CustomerData) {
    return this.settings.salesforce.url + customer.uniqueId;
  }

  download(params: any) {
    this.customerService.download(params);
  }

  @ViewChild(MatSort)
  sort: MatSort;

  tableColumns: string[] = [
    'firstName',
    'lastName',
    'uniqueId',
    'email',
    // 'active',
    'loanNumber',
    'businessName',
    'createdOn',
    'updatedOn',
    'edit'
  ];

  accountDetailsLink(loanId: number) {
    if (!this.canReadAccounts)
      return;
    return `/account/${loanId}/profile`;
  }

}

export interface CustomerListComponentData {
  customerQueryResult: CustomerQueryResult;
}
