import { QueryParams, QueryResult, parseNumber } from "common";

export enum BrokerUserStatus {
  None = "none",
  Invited = "invited",
  Active = "active",
  Disabled = "disabled"
}

export const BrokerUserStatusLabel = new Map<BrokerUserStatus, string>([
  [BrokerUserStatus.None, "None"],
  [BrokerUserStatus.Invited, "Invited"],
  [BrokerUserStatus.Active, "Active"],
  [BrokerUserStatus.Disabled, "Disabled"],
]);
export enum BrokerUserRole {
  User = "user",
  Administrator = "administrator",
}

export const BrokerUserRoleLabel = new Map<string, string>([
  [BrokerUserRole.User, "User"],
  [BrokerUserRole.Administrator, "Administrator"],
]);

export interface BrokerUserData extends BrokerUserBaseData {
  email?: string;
  firstName?: string;
  lastName?: string;
  brokerId?: number;
  mobilePhone?: string;
  role?: BrokerUserRole;
}

export interface BrokerUserEditData {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  role: BrokerUserRole,
  brokerId: number;
  active: boolean
}

export enum UserUiRole {
    ADMIN = "Admin",
    USER = "Team Member",
}

export interface BrokerUserBaseData {
  id: number;
  active?: boolean;
}

export interface UpdateBrokerUserStatusRequest {
  id: number;
  active: boolean;
}

export interface UpdateBrokerUserRequest extends CreateBrokerUserRequest {
  id: number;
}

export interface CreateBrokerUserRequest {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  role: BrokerUserRole,
  brokerId: number;
  active: boolean;
}

export class BrokerUsersQueryParams extends QueryParams {
  brokerId: number;
  roles: BrokerUserRole[];

  constructor(params?: any) {
    super(params);

    params = params || {};

    this.brokerId = parseNumber(params.brokerId);
    this.roles = params.roles;
  }
}

export interface BrokerUserListItemData {
  id: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  role?: BrokerUserRole;
  active?: boolean;
  lastLoggedOnUtc: Date;
  status: BrokerUserStatus;
}

export interface BrokerUsersQueryResult
  extends QueryResult<BrokerUserListItemData> {}
