import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { ClientRoutingModule } from './client-routing.module';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientListFilterDialogComponent } from './client-list-filter-dialog/client-list-filter-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { ClientUpdateDialogComponent } from './client-update-dialog/client-update-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        ClientRoutingModule
    ],
    declarations: [
        ClientListComponent,
        ClientDetailsComponent,
        ClientListFilterDialogComponent,
        ClientUpdateDialogComponent
    ]
})
export class ClientModule { }
