import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper } from "../helpers/enum.helper";
import { ProductCode, ProductCodeLabel } from "./product.model";

@Pipe({
  name: "productCodeLabel",
})
export class ProductCodeLabelPipe implements PipeTransform {
  transform(value: ProductCode): string {
    return EnumHelper.getMappedNameFromValue(
      ProductCode,
      ProductCodeLabel,
      value
    );
  }
}
