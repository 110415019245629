import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { LogGroupByOption, LogQueryParams, LogGroupQueryResult } from '../log.model';
import { LogService } from '../log.service';

@Component({
  selector: 'ifb-log-grouped-list',
  templateUrl: './log-grouped-list.component.html',
  styleUrls: ['./log-grouped-list.component.scss']
})
export class LogGroupedListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: LogGroupedComponentData;
  queryParams: any;
  canReadWriteLog = false;
  tableColumns: string[] = ['group'];

  @Input()
  activeGroupBy: LogGroupByOption;

  @Output()
  showMessagesSelected = new EventEmitter<LogQueryParams>();

  @Output()
  archived = new EventEmitter<any>();

  constructor(
    private route: ActivatedRoute,
    private logService: LogService,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: LogGroupedComponentData) => this.data = it);

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => { this.queryParams = it; });

    this.userPermissionService.granted([readWrite('admin-logs')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canReadWriteLog = res);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getQueryParams(groupName: string, groupId: string): LogQueryParams {
    const params: LogQueryParams = {};
    params.groupBy = undefined;

    if (this.activeGroupBy === LogGroupByOption.ExceptionType || this.activeGroupBy === LogGroupByOption.ExceptionTargetSite)
      params.exceptionHashCode = groupId;
    else params[this.activeGroupBy] = groupName;

    return Object.assign({}, this.queryParams, params);
  }

  archive(groupName: string, groupId: string) {
    const params = this.getQueryParams(groupName,groupId);
    this.logService.archiveAll(params).subscribe(() => {
      this.archived.emit();
    });
  }

  showLogMessages(groupName: string, groupId: string) {
    const params = this.getQueryParams(groupName, groupId);
    this.showMessagesSelected.emit(params);
  }
}

export interface LogGroupedComponentData {
  logGroupQueryResult?: LogGroupQueryResult;
}
