import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { FilterParameter } from "common";
import { readWrite } from "../../../user/user-permission/user-permission.data";
import { UserPermissionService } from "../../../user/user-permission/user-permission.service";
import { routerTransition } from "../../../shared/animations/router.animations";
import { PromotionsFilters } from "../models/promotions-filter.model";
import { PromotionsFilterService } from "../promotions-filter.service";
import { PromotionListData } from "./models/promotion-list-data.model";
import { Subject } from "rxjs";
import { PromotionService } from "../promotion.service";
import { PromotionListFilterDialogComponent } from "./components/promotion-list-filter-dialog/promotion-list-filter-dialog.component";
import { PromotionListQueryParams } from "../models/promotions-query.model";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Component({
  selector: "ifb-promotion-list",
  templateUrl: "./promotion-list.component.html",
  styleUrls: ["./promotion-list.component.scss"],
  animations: routerTransition,
})
export class PromotionListComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<unknown>;

  filters: PromotionsFilters;
  activeFilters: FilterParameter[] = [];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  queryParams: PromotionListQueryParams;

  tableColumns: string[] = [
    "name",
    "active",
    "createdOn",
    "productType",
    "startDate",
    "endDate",
    "transactionType",
    "promotionType",
  ];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private promotionsFilterService: PromotionsFilterService,
    private promotionService: PromotionService,
    private queryParamsService: QueryParamsService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  data: PromotionListData;

  readWritePromotions: boolean;

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: PromotionListData) => {
        this.data = data;
      });
    this.route.queryParams
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((params) => {
        this.queryParams = this.queryParamsService.init(params) as PromotionListQueryParams;
        this.activeFilters = this.promotionsFilterService.addFilterParameters(
          this.queryParams
        );
      });

    this.userPermissionService
      .granted([readWrite("servicing-promotions")])
      .subscribe((res) => (this.readWritePromotions = res));

    this.filters = new PromotionsFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) {
      return;
    }

    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this.promotionsFilterService.filter(this.filters);
    });
  }

  openFilterDialog(): void {
    PromotionListFilterDialogComponent.show(
      this.dialog,
      this.queryParams,
      this.data
    ).subscribe((result) => {
      if (result) {
        this.filters = result;
        this.paginator.firstPage();
        this.promotionsFilterService.filter(result);
      }
    });
  }
  sortData(): void {
    this.promotionsFilterService.filter(
      this.filters,
      this.paginator,
      this.sort
    );
  }

  setPage(): void {
    this.promotionsFilterService.filter(
      this.filters,
      this.paginator,
      this.sort
    );
  }

  downloadPromotions(): void {
    this.promotionService.getCsv(this.queryParams);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
