import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AppBarActionsService,
  AppPageService,
  AppBarAction,
  AppBarTitleService,
  parseNumber,
  FormHelper,
  DialogActionComponent,
} from "common";
import { BrokerUserFacade } from "../../domain/+state/broker-user.facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  BrokerUserData,
  BrokerUserEditData,
  BrokerUserRole,
  UserUiRole,
} from "../../domain/models/broker-user.model";
import { UserPermissionService } from "../../../../user/user-permission/user-permission.service";
import { readWrite } from "../../../../user/user-permission/user-permission.data";
import { MessageService } from "projects/common/src/core/message/message.service";
import { MatDialog } from "@angular/material/dialog";
import { getNotificationSentDialogData } from "../../broker-users.data";

@UntilDestroy()
@Component({
  selector: "ifb-broker-user-details",
  templateUrl: "./broker-user-details.component.html",
  styleUrls: ["./broker-user-details.component.scss"],
})
export class BrokerUserDetailsComponent implements OnInit {
  form: UntypedFormGroup;
  brokerUser: BrokerUserData;
  roleOptions: { label: UserUiRole; value: BrokerUserRole }[];
  statusOptions: { label: string; value: boolean }[];
  appBarActions: AppBarAction[];
  readWriteBrokerUser: boolean;

  private brokerId: number;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private brokerUserFacade: BrokerUserFacade,
    private appBarActionsService: AppBarActionsService,
    private userPermissionService: UserPermissionService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private messageService: MessageService
  ) {
    this.form = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.maxLength(40)]],
      lastName: ["", [Validators.required, Validators.maxLength(64)]],
      mobilePhone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      email: [
        "",
        [Validators.required, Validators.email, Validators.maxLength(80)],
      ],
      role: ["", Validators.required],
    });

    this.roleOptions = [
      { label: UserUiRole.ADMIN, value: BrokerUserRole.Administrator },
      { label: UserUiRole.USER, value: BrokerUserRole.User },
    ];

    this.statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
    this.appBarActions = [
      {
        id: "cancel",
        label: "CANCEL",
        buttonType: "button",
      },
      {
        id: "save",
        label: "SAVE",
        buttonType: "button",
        buttonAppearance: "flat",
        buttonColor: "primary",
        disabled: true,
      },
    ];
  }

  ngOnInit(): void {
    this.initRouteData();
    this.initPermissions();
    this.initAppBarActions();
  }

  private initAppBarActions(): void {
    this.appBarActionsService.actions = this.appBarActions;
    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe((newStatus: string) => {
      const isEnabled = newStatus === "VALID" && this.form.dirty && this.readWriteBrokerUser;
      this.appBarActionsService.enable("save", isEnabled);
    });
  }

  private initPermissions(): void {
    this.userPermissionService
      .granted([readWrite("brokers-broker-users")])
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.readWriteBrokerUser = res));
  }

  private initRouteData(): void {
    this.brokerId = parseNumber(this.route.snapshot.paramMap.get("brokerId"));
    const userIdRaw = this.route.snapshot.paramMap.get("id");
    if (userIdRaw === "new") {
      this.appBarTitleService.title = "New User";
      return;
    }

    const userId = parseNumber(userIdRaw);
    this.brokerUserFacade
      .getBrokerUserById(userId)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.brokerUser = res;
        this.appBarTitleService.title = `${this.brokerUser.firstName} ${this.brokerUser.lastName}`;
        this.form.addControl(
          "status",
          new FormControl(this.brokerUser.active, Validators.required)
        );
        this.form.patchValue({
          firstName: this.brokerUser.firstName,
          lastName: this.brokerUser.lastName,
          mobilePhone: this.brokerUser.mobilePhone,
          email: this.brokerUser.email,
          role: this.brokerUser.role,
        });
      });
  }

  cancel() {
    this.appPageService.back();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);

    if (!this.form.valid) return;

    if (this.brokerUser) {
      this.brokerUserFacade
        .updateBrokerUser({
          id: this.brokerUser.id,
          ...this.prepBrokerUserData(),
        })
        .pipe(untilDestroyed(this))
        .subscribe(
          (next) => this.handleSuccessUpdateBrokerUser(),
          (error) => this.handleErrorUpdateBrokerUser()
        );
      return;
    }

    this.brokerUserFacade
      .createBrokerUser({ ...this.prepBrokerUserData() })
      .pipe(untilDestroyed(this))
      .subscribe(
        (next) => this.handleSuccessCreateBrokerUser(next),
        (error) => this.handleErrorCreateBrokerUser()
      );
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  private handleSuccessCreateBrokerUser(brokerUser: BrokerUserData) {
    DialogActionComponent.show(this.dialog, getNotificationSentDialogData(brokerUser)).subscribe(() => {
      void this.router.navigate([`/broker/${this.brokerId}/users`]);
    });
  }

  private handleErrorCreateBrokerUser() {
    this.messageService.error("Broker user cannot be created.");
  }

  private handleSuccessUpdateBrokerUser() {
    this.messageService.success("Broker user has been successfully updated.");
    void this.router.navigate([`/broker/${this.brokerId}/users`]);
  }

  private handleErrorUpdateBrokerUser() {
    this.messageService.error("Broker user cannot be updated.");
  }

  private prepBrokerUserData(): BrokerUserEditData {
    return {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      mobilePhone: this.form.value.mobilePhone,
      email: this.form.value.email,
      role: this.form.value.role,
      active: this.form.value.status ?? true,
      brokerId: this.brokerId,
    };
  }
}
