import { QueryParams, QueryResult, parseNumber } from "common";
import { UserPermissionData } from "../../user/user-permission/user-permission.data";

export interface ApiKeyData {
    id?: number;
    clientId?: number;
    clientName?: string;
    description?: string;
    createdBy?: string;
    createdOn?: Date;
    updatedBy?: string;
    updatedOn?: Date;
    active?: boolean;
    token?: string;
    permissions: UserPermissionData[];
}

export class ApiKeyQueryParams extends QueryParams {
    clientId?: number;
    description?: string;
    createdBy?: string;
    createdOnStart?: any;
    createdOnEnd?: any;
    updatedBy?: string;
    updatedOnStart?: any;
    updatedOnEnd?: any;
    active?: boolean;

    constructor(params: any) {
      super(params);
      params = params || {};

      this.clientId = parseNumber(params.clientId) || undefined;
      this.description = params.description || undefined;
      this.createdBy = params.createdBy || undefined;
      this.createdOnStart = params.createdOnStart || undefined;
      this.createdOnEnd = params.createdOnEnd || undefined;
      this.updatedBy = params.updatedBy || undefined;
      this.updatedOnStart = params.updatedOnStart || undefined;
      this.updatedOnEnd = params.updatedOnEnd || undefined;
      this.active = params.active || undefined;
    }
}

export interface ApiKeyQueryResult extends QueryResult<ApiKeyData> { }

export class ApiKeyFilters {
    clientId: number;
    description: string;
    createdBy: string;
    createdOn: string;
    createdOnStart: Date;
    createdOnEnd: Date;
    updatedBy: string;
    updatedOn: string;
    updatedOnStart: Date;
    updatedOnEnd: Date;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.clientId = filters.clientId || undefined;
        this.description = filters.description || undefined;
        this.createdBy =  filters.createdBy ? filters.createdBy.fullName ? filters.createdBy.fullName : filters.createdBy : undefined;
        this.createdOn = filters.createdOn || undefined;
        this.createdOnStart = filters.createdOnStart || undefined;
        this.createdOnEnd = filters.createdOnEnd || undefined;
        this.updatedBy =  filters.updatedBy ? filters.updatedBy.fullName : undefined;
        this.updatedOn = filters.updatedOn || undefined;
        this.updatedOnStart = filters.updatedOnStart || undefined;
        this.updatedOnEnd = filters.updatedOnEnd || undefined;
        this.active = filters.active === 'true' ? true : (filters.active === 'false' ? false : undefined);
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'createdOn' || item === 'createdOnStart') {
            this.createdOn = undefined;
            this.createdOnStart = undefined;
            this.createdOnEnd = undefined;
        } else if (item === 'updatedOn' || item === 'updatedOnStart') {
            this.updatedOn = undefined;
            this.updatedOnStart = undefined;
            this.updatedOnEnd = undefined;
        }
    }
}
