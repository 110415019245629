import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { BrokerUserFacade } from "./domain/+state/broker-user.facade";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class BrokerUsersGuard implements CanActivate {
    constructor(
        private router: Router,
        private facade: BrokerUserFacade,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        const id = route.params.id;

        return this.facade.getBrokerUserById(id).pipe(take(1),
            map(data => {
                const brokerId = data.brokerId;
                this.router.navigate([`broker/${brokerId}/users/${id}`]);
                return false;
            }));
    }
}