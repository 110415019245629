import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BankingAccountFilters, BankingAccountQueryParams } from '../banking.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ifb-banking-account-list-filter-dialog',
  templateUrl: './banking-account-list-filter-dialog.component.html',
  styleUrls: ['./banking-account-list-filter-dialog.component.css']
})
export class BankingAccountListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  form: UntypedFormGroup;
  filters: BankingAccountFilters;
  today = new Date();

  constructor(public dialogRef: MatDialogRef<BankingAccountListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new BankingAccountFilters(data.query);
    this.form = this.formBuilder.group({
      onlyPendingAccounts: this.data.query.onlyPendingAccounts || undefined,
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new BankingAccountFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  static show(dialog: MatDialog, query: BankingAccountQueryParams): Observable<any> {
    return dialog.open(BankingAccountListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }

}
