export class AppSettings {
  rootSite: {
    url: string;
  };
  auth: {
    oidc: {
      authority: string;
      clientId: string;
      redirectUrl: string;
    };
  };
  google: {
    auth2: {
      client_id: string;
      cookiepolicy: string;
    };
  };
  if: {
    contact: {
      phone: string;
    };
  };
  banking: {
    url: string;
  };
  loans: {
    url: string;
  };
  transaction: {
    url: string;
  };
  advisors: {
    url: string;
  };
  applications: {
    url: string;
  };
  brokers: {
    url: string;
  };
  entities: {
    url: string;
  };
  customers: {
    url: string;
  };
  audit: {
    url: string;
  };
  products: {
    url: string;
  };
  failurereasons: {
    url: string;
  };
  oauth: {
    url: string;
  };
  log: {
    url: string;
  };
  salesforce: {
    url: string;
  };
  doc: {
    url: string;
  };
  companies: {
    url: string;
  };
  promotions: {
    url: string;
  };
  competitors: {
    url: string;
  };
}
