import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SharedModule } from '../shared/shared.module';
import { ApplicationListComponent } from './application-list/application-list.component';
import { ApplicationAppBarTitleComponent } from './application-app-bar-title/application-app-bar-title.component';
import { ApplicationRoutingModule } from './application-routing.module';
import { ApplicationListFilterDialogComponent } from './application-list-filter-dialog/application-list-filter-dialog.component';
import { AccountModule } from '../account/account.module';
import { ApplicationStatusWidgetComponent } from './application-status-widget/application-status-widget.component';
import { ApplicationCompanyInfoWidgetComponent } from './application-company-info-widget/application-company-info-widget.component';
import { ApplicationOtherInfoWidgetComponent } from './application-other-info-widget/application-other-info-widget.component';
import { ApplicationProfileComponent } from './application-profile/application-profile.component';
import { CashFlowAnalysisModule } from '../shared/cash-flow-analysis/cash-flow-analysis.module';
import { ApplicationNumberInputComponent } from './application-number-input/application-number-input.component';
import { BankModule } from '../bank/bank.module';
import { ApplicationAppBarExpandedComponent } from './application-app-bar-expanded/application-app-bar-expanded.component';
import { ApplicationStatusIconChipComponent } from './application-status-icon-chip/application-status-icon-chip.component';
import { ApplicationRenewalsWidgetComponent } from './application-renewals-widget/application-renewals-widget.component';
import { ApplicationRenewalsEditComponent } from './application-renewals-widget/components/application-renewals-edit/application-renewals-edit.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ApplicationRoutingModule,
        AccountModule,
        CashFlowAnalysisModule,
        BankModule
    ],
    exports: [
        ApplicationNumberInputComponent
    ],
    declarations: [
        ApplicationListComponent,
        ApplicationAppBarTitleComponent,
        ApplicationListFilterDialogComponent,
        ApplicationStatusWidgetComponent,
        ApplicationCompanyInfoWidgetComponent,
        ApplicationOtherInfoWidgetComponent,
        ApplicationProfileComponent,
        ApplicationNumberInputComponent,
        ApplicationAppBarExpandedComponent,
        ApplicationStatusIconChipComponent,
        ApplicationRenewalsWidgetComponent,
        ApplicationRenewalsEditComponent
    ]
})
export class ApplicationModule { }
