import { Component, Inject } from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Observable } from "rxjs";
import { CompetitorFormStatusData } from "../../../competitor-form/models/competitor-form-status-data.model";

@Component({
  selector: "ifb-competitors-select-add-dialog",
  templateUrl: "./competitors-select-add-dialog.component.html",
  styleUrls: ["./competitors-select-add-dialog.component.scss"],
})
export class CompetitorsSelectAddDialogComponent {
  formData: CompetitorFormStatusData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  static show(dialog: MatDialog): Observable<any> {
    return dialog
      .open(CompetitorsSelectAddDialogComponent, {
        data: {},
        closeOnNavigation: true,
      })
      .afterClosed();
  }

  onFormStatusChanged(data: CompetitorFormStatusData) {
    this.formData = data;
  }
}
