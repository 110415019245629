import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifb-api-key-update-dialog',
  templateUrl: './api-key-update-dialog.component.html',
  styleUrls: ['./api-key-update-dialog.component.css']
})
export class ApiKeyUpdateDialogComponent {
  actionGerund: string;

  constructor(public dialogRef: MatDialogRef<ApiKeyUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.actionGerund = this.data.action.substring(0, this.data.action.length - 1) + "ing";
  }

  static show(dialog: MatDialog, form: UntypedFormGroup, action: string, clientName?: string): Observable<any> {
    return dialog.open(ApiKeyUpdateDialogComponent, {
      width: '510px',
      data: {
        clientName: clientName,
        form: form,
        action: action
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}

