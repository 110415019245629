import { Component } from "@angular/core";
import { AutocompleteComponent } from "common";
import { CompetitorService } from "projects/backoffice/src/app/competitors/competitor.service";
import { EMPTY, Observable } from "rxjs";
import { CompetitorHintData } from "../../../../models/competitor.model";

@Component({
  selector: "ifb-competitor-name-input",
  templateUrl:
    "../../../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html",
  styleUrls: [
    "../../../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css",
  ],
})
export class CompetitorNameInputComponent extends AutocompleteComponent {
  label = "Name";
  optionLabelKey = "name";

  constructor(private competitorService: CompetitorService) {
    super();
  }

  optionFilter: (input: string | CompetitorHintData) => Observable<any[]> = (
    input
  ) =>
    input
      ? this.competitorService.getHints({
          name: this.renderValueForProperInput(input),
          sortField: "name",
        })
      : EMPTY;

  private renderValueForProperInput(input: string | CompetitorHintData): string {
    return !!input
      ? this.ifStringRenderValue(input)
      : null;
  }

  private ifStringRenderValue(input: string | CompetitorHintData): string {
    return typeof input !== "string"
      ? input.name
      : input;
  }
}
