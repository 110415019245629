import { Component, Input } from '@angular/core';
import { AutocompleteComponent } from 'common';
import { Observable, EMPTY } from 'rxjs';
import { CustomerService } from '../customer.service';
import { CustomerHintData } from '../customer.model';

@Component({
  selector: 'ifb-customer-email-input',
  templateUrl: '../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class CustomerEmailInputComponent extends AutocompleteComponent {

  constructor(private customerService: CustomerService) {
    super();
  }

  @Input()
  label = 'Email';

  optionFilter: (input: string | CustomerHintData) => Observable<any[]> =
    input => input
      ? this.customerService.queryHints({
        email: !!input ? (typeof input !== 'string' ? input.email : input) : null,
        sortField: 'email'
      })
      : EMPTY;

  optionLabelKey = 'email';
}
