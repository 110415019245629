<div class="notes-card">

  <mat-form-field appearance="outline">
    <mat-label>Add a note</mat-label>
    <textarea matInput [formControl]="fc" type="text" name="note" placeholder="Add a note" (focus)="changeRows(4)" [rows]="rows"></textarea>
  </mat-form-field>

  <div *ngIf="!hideButtons" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [disabled]="!fc.value" (click)="fc.reset(); changeRows(1)">CLEAR</button>
    <button mat-button [disabled]="!fc.value" (click)="addNote()">ADD</button>
  </div>

  <div *ngIf="showNotes">
    <div class="note" *ngFor="let note of notes | sortBy:'desc':'date'">
      <div fxLayout="column" fxLayoutAlign="start start">
        <p class="mat-caption">{{note?.initiator?.fullName || note?.initiator?.FullName || 'System'}} wrote a note</p>
        <p>{{note.text}}</p>
        <p class="mat-caption">{{note.date | date: 'medium'}}</p>
      </div>
    </div>
  </div>

</div>
