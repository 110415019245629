<h1 mat-dialog-title>Update {{data.legalEntityName}}?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are updating {{data.title}} info for {{data.legalEntityName}}:
  </p>

  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">
      <div *ngIf="data.form.controls.name && !data.form.controls.name.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.name}}</div>
        <div class="mat-caption"> Legal entity name </div>
      </div>
      <div *ngIf="data.form.controls.dba && !data.form.controls.dba.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.dba}}</div>
        <div class="mat-caption"> DBA </div>
      </div>
      <div *ngIf="data.form.controls.ein && !data.form.controls.ein.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.ein}}</div>
        <div class="mat-caption"> Tax ID </div>
      </div>
      <div *ngIf="data.form.controls.brokerId && !data.form.controls.brokerId.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.brokerId}}</div>
        <div class="mat-caption"> Broker ID </div>
      </div>
      <div *ngIf="data.form.controls.line1 && !data.form.controls.line1.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.line1}}</div>
        <div class="mat-caption"> Address </div>
      </div>
      <div *ngIf="data.form.controls.line2 && !data.form.controls.line2.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.line2}}</div>
        <div class="mat-caption"> Unit/Suite/Apt # </div>
      </div>
      <div *ngIf="data.form.controls.city && !data.form.controls.city.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.city}}</div>
        <div class="mat-caption"> City </div>
      </div>
      <div *ngIf="data.form.controls.state && !data.form.controls.state.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.state}}</div>
        <div class="mat-caption"> State </div>
      </div>
      <div *ngIf="data.form.controls.zip && !data.form.controls.zip.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.zip}}</div>
        <div class="mat-caption"> Zip code </div>
      </div>
      <div *ngIf="data.form.controls.phone && !data.form.controls.phone.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.phone}}</div>
        <div class="mat-caption"> Phone </div>
      </div>
      <div *ngIf="data.form.controls.website && !data.form.controls.website.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div class="overflow-hidden">{{data.form.value.website}}</div>
        <div class="mat-caption"> Website </div>
      </div>
      <div *ngIf="data.form.controls.contactEmail && !data.form.controls.contactEmail.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div class="overflow-hidden">{{data.form.value.contactEmail}}</div>
        <div class="mat-caption"> Contact email </div>
      </div>
      <div *ngIf="data.form.controls.commissionEmail && !data.form.controls.commissionEmail.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div class="overflow-hidden">{{data.form.value.commissionEmail}}</div>
        <div class="mat-caption"> Commission email </div>
      </div>
      <div *ngIf="data.form.controls.tier && !data.form.controls.tier.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{formatTier(data.form.value.tier)}}</div>
        <div class="mat-caption"> Tier </div>
      </div>
      <div *ngIf="data.form.controls.type && !data.form.controls.type.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{formatType(data.form.value.type)}}</div>
        <div class="mat-caption"> Type </div>
      </div>
      <div *ngIf="data.form.controls.active && !data.form.controls.active.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.active ? 'Active' : 'Inactive'}}</div>
        <div class="mat-caption"> Active </div>
      </div>
      <div *ngIf="data.form.controls.status && !data.form.controls.status.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{formatStatus(data.form.value.status)}}</div>
        <div class="mat-caption"> Status </div>
      </div>
      <div *ngIf="data.form.controls.contractDate && !data.form.controls.contractDate.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.contractDate | date}}</div>
        <div class="mat-caption"> Contract date </div>
      </div>
      <div *ngIf="data.form.controls.establishedDate && !data.form.controls.establishedDate.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.establishedDate  | date}}</div>
        <div class="mat-caption"> Established date </div>
      </div>
      <div *ngIf="data.form.controls.pricingPlan && !data.form.controls.pricingPlan.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{formatPricingPlan(data.form.value.pricingPlan)}}</div>
        <div class="mat-caption"> Pricing plan </div>
      </div>
      <div *ngIf="data.form.controls.plan && !data.form.controls.plan.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{formatCommissionPlan(data.form.value.plan)}}</div>
        <div class="mat-caption"> Commission plan </div>
      </div>
      <div *ngIf="data.form.controls.initialDrawCommission && !data.form.controls.initialDrawCommission.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.initialDrawCommission}}%</div>
        <div class="mat-caption"> Initial draw commission </div>
      </div>
      <div *ngIf="data.form.controls.initialCommissionPeriod && !data.form.controls.initialCommissionPeriod.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.initialCommissionPeriod}}</div>
        <div class="mat-caption"> Initial commission period </div>
      </div>
      <div *ngIf="data.form.controls.initialCommissionCap && !data.form.controls.initialCommissionCap.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.initialCommissionCap}}%</div>
        <div class="mat-caption"> Initial commission cap </div>
      </div>
      <div *ngIf="data.form.controls.residualDrawCommission && !data.form.controls.residualDrawCommission.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.residualDrawCommission}}%</div>
        <div class="mat-caption"> Residual draw commission </div>
      </div>
      <div *ngIf="data.form.controls.residualCommissionPeriod && !data.form.controls.residualCommissionPeriod.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.residualCommissionPeriod}}</div>
        <div class="mat-caption"> Residual commission period </div>
      </div>
      <div *ngIf="data.form.controls.defaultClawbackPeriod && !data.form.controls.defaultClawbackPeriod.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.defaultClawbackPeriod}}</div>
        <div class="mat-caption"> Default clawback period </div>
      </div>
      <div *ngIf="data.form.controls.defaultPrepaymentClawbackPeriod && !data.form.controls.defaultPrepaymentClawbackPeriod.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.defaultPrepaymentClawbackPeriod}}</div>
        <div class="mat-caption"> Default prepayment clawback period </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>