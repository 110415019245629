<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are {{actionGerund | lowercase}} business advisor:
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">

      <div *ngIf="data.form.controls.firstName && !data.form.controls.firstName.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div name="firstName">{{data.form.value.firstName || 'Null'}}</div>
        <div class="mat-caption"> First name </div>
      </div>

      <div *ngIf="data.form.controls.lastName && !data.form.controls.lastName.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div name="lastName">{{data.form.value.lastName || 'Null'}}</div>
        <div class="mat-caption"> Last name </div>
      </div>

      <div *ngIf="data.form.controls.email && !data.form.controls.email.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div name="email" class="overflow-hidden">{{data.form.value.email || 'Null'}}</div>
        <div class="mat-caption"> Email </div>
      </div>

      <div *ngIf="data.form.controls.phone && !data.form.controls.phone.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div name="phone">{{data.form.value.phone || 'Null'}}</div>
        <div class="mat-caption"> Phone </div>
      </div>

      <div *ngIf="data.form.controls.active && !data.form.controls.active.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div name="active">{{data.form.value.active ? 'True' : 'False'}}</div>
        <div class="mat-caption"> Active </div>
      </div>

      <div *ngIf="data.form.controls.uniqueId && !data.form.controls.uniqueId.pristine" fxLayout="column" fxFlex="120"
        fxFlex.gt-sm="50" class="layout padding-vertical-md td">
        <div name="uniqueId">{{data.form.value.uniqueId || 'Null'}}</div>
        <div class="mat-caption"> Salesforce ID </div>
      </div>

      <div *ngIf="data.form.controls.calendarLinkUrl && !data.form.controls.calendarLinkUrl.pristine" fxLayout="column" fxFlex="120"
        fxFlex.gt-sm="50" class="layout padding-vertical-md td">
        <div name="calendarLinkUrl">{{data.form.value.calendarLinkUrl || 'Null'}}</div>
        <div class="mat-caption"> Calendar link </div>
      </div>

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>