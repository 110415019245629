<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" type="text" placeholder="First Name">
        <button type="button" *ngIf="form.controls.firstName.value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.controls.firstName.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" type="text" placeholder="Last Name">
        <button type="button" *ngIf="form.controls.lastName.value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.controls.lastName.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifb-customer-email-input [fc]="form.get('email')"></ifb-customer-email-input>
      </div>

      <div class="mat-form-field">
        <ifb-customer-ssn-input [fc]="form.get('ssn')"></ifb-customer-ssn-input>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifb-business-name-input [fc]="form.get('businessName')"> </ifb-business-name-input>
      </div>
         
      <div class="mat-form-field">
        <ifc-account-loan-number-input [fc]="form.get('loanNumber')"></ifc-account-loan-number-input>
      </div>
    </div>
    
    <mat-form-field appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select matInput formControlName="active" placeholder="Status">
          <mat-option>All</mat-option>
          <mat-option value="true">Active</mat-option>
          <mat-option value="false">Inactive</mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>