import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppSidenavComponent,
  AppBarExpandedTabsComponent,
  AppBarActionsComponent
} from 'common';
import { AccountListComponent } from './account-list/account-list.component';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../app.data';
import {
  AccountQueryResolver,
  AccountResolver,
  AccountAppBarExpandedTabsResolver,
  AccountInfoResolver,
  AccountAmortizationResolver,
  AccountUniqueIdResolver,
  AccountInfoUniqueIdResolver,
  AccountAmortizationUniqueIdResolver,
  AccountSFAppBarExpandedTabsResolver,
  AccountAuditTrailResolver,
  AccountAuditTrailByUniqueIdResolver,
  AccountModificationListResolver,
  AccountModificationListByUniqueIdResolver,
  AccountOwnersResolver,
  AccountOwnersByUniqueIdResolver,
  AccountStatementResolver,
  AccountStatementByUniqueIdResolver,
} from './account.resolver';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { AccountEconomicsComponent } from './account-economics/account-economics.component';
import { TransactionListComponent } from '../transaction/transaction-list/transaction-list.component';
import { TransactionByLoanQueryResolver, TransactionByUniqueIdQueryResolver } from '../transaction/transaction.resolver';
import { AccountAppBarExpandedComponent } from './account-app-bar-expanded/account-app-bar-expanded.component';
import { AccountAppBarTitleComponent } from './account-app-bar-title/account-app-bar-title.component';
import { AccountStateBalanceDetailsComponent } from './account-state-balance-details/account-state-balance-details.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { AccountProfileCompanyInfoComponent } from './account-profile-company-info/account-profile-company-info.component';
import { AccountOfferDetailsComponent } from './account-offer-details/account-offer-details.component';
import { AccountProfileStatusDetailsComponent } from './account-profile-status-details/account-profile-status-details.component';
import { AccountAuditTrailComponent } from './account-audit-trail/account-audit-trail.component';
import { FailureReasonListResolver } from '../admin/failure-reason/failure-reason.resolver';
import {
  BankAccountResolverForLoan,
  BankAccountListResolverForLoan,
  BankTransactionListResolverForLoan,
  BankAccountListByUniqueIdResolverForLoan,
  BankAccountAuditTrailResolver
} from '../bank/bank.resolver';
import { BankAccountListComponent } from '../bank/bank-account-list/bank-account-list.component';
import { BankAccountDetailsComponent } from '../bank/bank-account-details/bank-account-details.component';
import { EntityAccountsResolverForLoan, EntityForAccountResolver, EntityForAccountByUniqueIdResolver } from '../entity/entity.resolver';
import { BankAccountTabsComponent } from '../bank/bank-account-tabs/bank-account-tabs.component';
import { BankAccountTransactionListComponent } from '../bank/bank-account-transaction-list/bank-account-transaction-list.component';
import { BOAuthGuard } from '../guards/oauth.guard';
import { FinancialSummaryComponent } from '../shared/cash-flow-analysis/financial-summary/financial-summary.component';
import { BankAccountAuditTrailComponent } from '../bank/bank-account-audit-trail/bank-account-audit-trail.component';
import { ProductsResolver } from '../product/product.resolver';
import { AccountOtherInfoDetailsComponent } from './account-profile-other-info-details/account-profile-other-info-details.component';
import { AccountModificationListComponent } from './account-modification-list/account-modification-list.component';
import { AccountModificationDetailsComponent } from './account-modification-details/account-modification-details.component';
import { UserCurrentResolver } from '../user/user.resolver';
import { UserPermissionnNPLEconomics } from '../user/user-permission/user-permission.resolver';
import { PromotionsToSelectResolver } from '../transaction/promotions-to-select.resolver';
import { AccountLiquidityResolver } from '../litigation/account-liquidity.resolver';
import { AccountStatementsEditComponent } from './account-statements-widget/components/account-statements-edit/account-statements-edit.component';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Accounts',
  sideNavItem: 'account'
});
const appPageDataDetail = appPageComponentDataDetail();
const accountTransactionsList = { accountTransactionsList: true }

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
      {
        path: 'account',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              accountQueryResult: AccountQueryResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/profile',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,
          userCurrent: UserCurrentResolver,
          owners: AccountOwnersResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics,
          liquidityEvent: AccountLiquidityResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountProfileComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      },
      {
        path: 'account/:id/profile/edit',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountProfileCompanyInfoComponent,
            resolve: {
              account: AccountResolver,
              accountInfo: AccountInfoResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/profile/edit',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountProfileCompanyInfoComponent,
            resolve: {
              account: AccountUniqueIdResolver,
              accountInfo: AccountInfoUniqueIdResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/profile/status',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountProfileStatusDetailsComponent,
            resolve: {
              account: AccountResolver,
              accountInfo: AccountInfoResolver,
              products: ProductsResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/profile/status',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountProfileStatusDetailsComponent,
            resolve: {
              account: AccountUniqueIdResolver,
              accountInfo: AccountInfoUniqueIdResolver,
              products: ProductsResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/profile/other-info',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountOtherInfoDetailsComponent,
            resolve: {
              account: AccountResolver,
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/profile/other-info',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountOtherInfoDetailsComponent,
            resolve: {
              account: AccountUniqueIdResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/profile',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
          userCurrent: UserCurrentResolver,
          owners: AccountOwnersByUniqueIdResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountProfileComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      },
      {
        path: 'account/:id/profile/statements',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountStatementsEditComponent,
            resolve: {
              statements: AccountStatementResolver,
          },
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/profile/statements',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountStatementsEditComponent,
            resolve: {
              statements: AccountStatementByUniqueIdResolver,
          },
          }
        ]
      },
      {
        path: 'account/:id/transactions',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,
          userCurrent: UserCurrentResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics,
          liquidityEvent: AccountLiquidityResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: TransactionListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              transactionQueryResult: TransactionByLoanQueryResolver,
              failureReasons: FailureReasonListResolver,
              promotionsToSelect: PromotionsToSelectResolver
            },
            data: accountTransactionsList
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/transactions',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
          userCurrent: UserCurrentResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: TransactionListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              transactionQueryResult: TransactionByUniqueIdQueryResolver,
              failureReasons: FailureReasonListResolver,
              promotionsToSelect: PromotionsToSelectResolver
            },
            data: accountTransactionsList
          }
        ]
      },
      {
        path: 'account/:id/economics',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,
          userCurrent: UserCurrentResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountEconomicsComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/economics',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
          userCurrent: UserCurrentResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountEconomicsComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange"
          }
        ]
      },
      {
        path: 'account/:id/economics/balances',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountStateBalanceDetailsComponent,
            resolve: {
              account: AccountResolver,
              accountInfo: AccountInfoResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/economics/balances',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountStateBalanceDetailsComponent,
            resolve: {
              account: AccountUniqueIdResolver,
              accountInfo: AccountInfoUniqueIdResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/economics/offer',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountOfferDetailsComponent,
            resolve: {
              account: AccountResolver,
              accountInfo: AccountInfoResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/economics/offer',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountOfferDetailsComponent,
            resolve: {
              account: AccountUniqueIdResolver,
              accountInfo: AccountInfoUniqueIdResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/audit',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,
          userCurrent: UserCurrentResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountAuditTrailComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              auditTrail: AccountAuditTrailResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/audit',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
          userCurrent: UserCurrentResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountAuditTrailComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              auditTrail: AccountAuditTrailByUniqueIdResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/bank-account',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,
          entity: EntityForAccountResolver,
          userCurrent: UserCurrentResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: BankAccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              bankAccountQueryResult: BankAccountListResolverForLoan
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/bank-account',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
          entity: EntityForAccountByUniqueIdResolver,
          userCurrent: UserCurrentResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: BankAccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              bankAccountQueryResult: BankAccountListByUniqueIdResolverForLoan
            }
          }
        ]
      },
      {
        path: 'account/:id/bank-account/:bankAccountId',
        component: AppPageComponent,
        data: {
          appBar: {
            closeAction: 'account',
            expandable: false,
            logoHidden: true,
          },
          appBarActions: appPageDataDetail.appBarActions,
          appPage: {
            contentClass: 'fill',
            contentAlignment: 'start-start',
            contentWithoutPadding: true
          }
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BankAccountTabsComponent,
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'details' },
              {
                path: 'details',
                component: BankAccountDetailsComponent,
                data: { viewMode: true },
                resolve: {
                  bankAccount: BankAccountResolverForLoan,
                  account: AccountResolver,
                  entityLoans: EntityAccountsResolverForLoan
                }
              },
              {
                path: 'transactions',
                component: BankAccountTransactionListComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForLoan,
                  account: AccountResolver,
                  entityLoans: EntityAccountsResolverForLoan,
                  bankTransactionQueryResult: BankTransactionListResolverForLoan
                }
              },
              {
                path: 'audit',
                component: BankAccountAuditTrailComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForLoan,
                  account: AccountResolver,
                  entityLoans: EntityAccountsResolverForLoan,
                  auditTrail: BankAccountAuditTrailResolver
                }
              }
            ]
          }
        ]
      },
      {
        path: 'account/:id/bank-account/:bankAccountId/edit',
        component: AppPageComponent,
        data: {
          appBar: {
            closeAction: 'account',
            expandable: false,
            logoHidden: true,
          },
          appBarActions: appPageDataDetail.appBarActions,
          appPage: {
            contentClass: 'fill',
            contentAlignment: 'start-start',
            contentWithoutPadding: true
          }
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: BankAccountTabsComponent,
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'details' },
              {
                path: 'details',
                component: BankAccountDetailsComponent,
                resolve: {
                  bankAccount: BankAccountResolverForLoan,
                  account: AccountResolver,
                  entityLoans: EntityAccountsResolverForLoan
                }
              },
              {
                path: 'transactions',
                component: BankAccountTransactionListComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForLoan,
                  account: AccountResolver,
                  entityLoans: EntityAccountsResolverForLoan,
                  bankTransactionQueryResult: BankTransactionListResolverForLoan
                }
              },
              {
                path: 'audit',
                component: BankAccountAuditTrailComponent,
                runGuardsAndResolvers: "paramsOrQueryParamsChange",
                resolve: {
                  bankAccount: BankAccountResolverForLoan,
                  account: AccountResolver,
                  entityLoans: EntityAccountsResolverForLoan,
                  auditTrail: BankAccountAuditTrailResolver
                }
              }
            ]
          }
        ]
      },
      {
        path: 'account/:id/bank-analysis',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: FinancialSummaryComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              entity: EntityForAccountResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/bank-analysis',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: FinancialSummaryComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              entity: EntityForAccountByUniqueIdResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/modification',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AccountAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          account: AccountResolver,
          accountInfo: AccountInfoResolver,
          accountAmortizationInfo: AccountAmortizationResolver,
          userCurrent: UserCurrentResolver,
          accessNPLEconomics: UserPermissionnNPLEconomics
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AccountAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountModificationListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              accountModificationQueryResult: AccountModificationListResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/modification',
        component: AppPageComponent,
        data: {
          appBar: {
            expandable: true,
            fixed: true,
            logoHidden: true,
          },
          appPage: {
            sidenavEnabled: false
          }
        },
        resolve: {
          appBarExpandedTabs: AccountSFAppBarExpandedTabsResolver,
          account: AccountUniqueIdResolver,
          accountInfo: AccountInfoUniqueIdResolver,
          accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
          entity: EntityForAccountByUniqueIdResolver,
          userCurrent: UserCurrentResolver
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'expanded',
                component: AccountAppBarExpandedComponent,
                children: [
                  {
                    path: '',
                    outlet: 'tabs',
                    component: AppBarExpandedTabsComponent,
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: AccountModificationListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              accountModificationQueryResult: AccountModificationListByUniqueIdResolver
            }
          }
        ]
      },
      {
        path: 'account/:id/modification/details',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountModificationDetailsComponent,
            resolve: {
              account: AccountResolver,
              userCurrent: UserCurrentResolver,
              accountInfo: AccountInfoResolver,
              accountAmortizationInfo: AccountAmortizationResolver,
              products: ProductsResolver
            }
          }
        ]
      },
      {
        path: 'account/sf/:uniqueId/modification/details',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: AccountModificationDetailsComponent,
            resolve: {
              account: AccountUniqueIdResolver,
              userCurrent: UserCurrentResolver,
              accountInfo: AccountInfoUniqueIdResolver,
              accountAmortizationInfo: AccountAmortizationUniqueIdResolver,
              products: ProductsResolver
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
