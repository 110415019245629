import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdvisorQueryParams, AdvisorFilters } from '../advisor.model';

@Component({
  selector: 'ifb-advisor-list-filter-dialog',
  templateUrl: './advisor-list-filter-dialog.component.html',
  styleUrls: ['./advisor-list-filter-dialog.component.css']
})
export class AdvisorListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  form: UntypedFormGroup;
  filters: AdvisorFilters;

  constructor(public dialogRef: MatDialogRef<AdvisorListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new AdvisorFilters(data.query);
    this.form = this.formBuilder.group({
      email: this.data.query.email || '',
      fullName: this.data.query.fullName || '',
      uniqueId: this.data.query.uniqueId || '',
      active: this.data.query.active || undefined
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new AdvisorFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  static show(dialog: MatDialog, query: AdvisorQueryParams): Observable<any> {
    return dialog.open(AdvisorListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }


}
