import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CreateBrokerApiKeySuccessData } from '../../../../broker-api-key.model';

@Component({
  selector: 'ifc-broker-api-key-details-dialog',
  templateUrl: './broker-api-key-details-dialog.component.html',
  styleUrls: ['./broker-api-key-details-dialog.component.scss']
})
export class BrokerApiKeyDetailsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<BrokerApiKeyDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    copy(text: string){
      navigator.clipboard.writeText(text);
    }

    static show(dialog: MatDialog, apiKey: CreateBrokerApiKeySuccessData): Observable<any> {
      return dialog.open(BrokerApiKeyDetailsDialogComponent, {
        width: '510px',
        data: {
          apiKey: apiKey
        },
        closeOnNavigation: true,
      }).afterClosed();
    }
}
