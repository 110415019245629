import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterParameter } from 'common';
import { routerTransition } from '../../shared/animations/router.animations';
import { AdvisorQueryResult, AdvisorFilters, AdvisorQueryParams } from '../advisor.model';
import { AdvisorFilterService } from '../advisor-filter.service';
import { AdvisorListFilterDialogComponent } from '../advisor-list-filter-dialog/advisor-list-filter-dialog.component';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { AdvisorService } from '../advisor.service';
import { AppSettings } from '../../../app.settings';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-advisor-list',
  templateUrl: './advisor-list.component.html',
  styleUrls: ['./advisor-list.component.scss'],
  animations: routerTransition,
})
export class AdvisorListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  filters: AdvisorFilters;
  queryParams: AdvisorQueryParams;
  activeFilters: FilterParameter[] = [];
  readWriteAdvisor: boolean;

  constructor(
    private route: ActivatedRoute,
    private advisorService: AdvisorService,
    private advisorFilterService: AdvisorFilterService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private settings: AppSettings,
    private router: Router,
    private queryParamsService: QueryParamsService
    ) {
    this._unsubscribeAll = new Subject();
  }

  data: AdvisorListComponentData;

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: AdvisorListComponentData) => this.data = it);

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.activeFilters = this.advisorFilterService.addFilterParameters(this.queryParams);
      });

    this.userPermissionService.granted([readWrite('servicing-advisors')]).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.readWriteAdvisor = res);

    this.filters = new AdvisorFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.advisorFilterService.filter(this.filters);
    });
  }

  filterDialog() {
    AdvisorListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.advisorFilterService.filter(result);
        }
      });
  }

  sortData(sort: Sort) {
    this.advisorFilterService.filter(this.filters, this.paginator, this.sort);
  }

  setPage(event: PageEvent) {
    this.advisorFilterService.filter(this.filters, this.paginator, this.sort);
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  detailsLink(id: number) {
    if (!id) return;
    this.router.navigate([`advisor/${id}/profile`]);
  }

  salesforceLink(salesforceId: string) {
    return this.settings.salesforce.url + salesforceId;
  }

  download(params: any) {
    const queryParams = Object.assign({}, params);
    this.advisorService.download(queryParams);
  }

  tableColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'createdOn',
    'updatedOn',
    'uniqueId',
    'active'
  ];
}

export interface AdvisorListComponentData {
  advisorQueryResult: AdvisorQueryResult;
}

