import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifb-entity-info-update-dialog',
  templateUrl: './entity-info-update-dialog.component.html',
  styleUrls: ['./entity-info-update-dialog.component.css']
})
export class EntityInfoUpdateDialogComponent {

  constructor(public dialogRef: MatDialogRef<EntityInfoUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog, form: UntypedFormGroup): Observable<any> {
    return dialog.open(EntityInfoUpdateDialogComponent, {
      width: '510px',
      data: { form: form },
      closeOnNavigation: true,
    }).afterClosed();
  }

}
