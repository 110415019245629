<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Client</mat-label>
        <input *ngIf="!data.clients" matInput formControlName="clientId" type="text" placeholder="Client">
        <mat-select *ngIf="data.clients" matInput formControlName="clientId" placeholder="Client">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let client of data.clients" [value]="client.id">{{client.name}}</mat-option>
        </mat-select>
        <button type="button" *ngIf="form.get('clientId').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('clientId').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" type="text" placeholder="Description">
        <button type="button" *ngIf="form.get('description').value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.get('description').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifb-user-fullname-input [fc]="form.get('createdBy')" [label]="'Created by'"></ifb-user-fullname-input>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
          <mat-option>All</mat-option>
          <mat-option value="true">Active</mat-option>
          <mat-option value="false">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <ifc-filter-param-date-range-input [fc]="form.get('createdOn')" [label]="'Created on'"
        (rangeSelected)="createdDateSelected($event)">
      </ifc-filter-param-date-range-input>
      <ifc-filter-param-date-range-input [fc]="form.get('expiredOn')" [label]="'Expired on'"
        (rangeSelected)="expiredDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('createdOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('createdOnStart')" [label]="'Created date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('createdOnEnd')" [label]="'Created date to'"></ifc-datepicker>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('expiredOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('expiresAtStart')" [label]="'Expiration date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('expiresAtEnd')" [label]="'Expiration date to'"></ifc-datepicker>
    </div>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" color="primary">Ok</button>
</div>
