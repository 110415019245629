import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { OAuthService } from "common";
import { AppSettings } from "../../app.settings";

@Injectable({
    providedIn: 'root',
})
export class BOAuthGuard implements CanActivate {

    constructor(private oauthService: OAuthService, @Inject(DOCUMENT) private document: Document, private settings: AppSettings) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if(!this.oauthService.authenticated) {
            const authority = this.settings.auth.oidc.authority;
            const clientId = encodeURIComponent(this.settings.auth.oidc.clientId);
            const authenticationUrl = encodeURIComponent(this.settings.auth.oidc.redirectUrl);
            const state = encodeURIComponent(JSON.stringify({
                signInUrl: this.document.location.protocol + '//' + this.document.location.host + '/callback',
                redirectUrl: (this.document.location.pathname != '/sign-out' ? this.document.location.pathname : '/home') + this.document.location.search
            }));
            const scopes = encodeURIComponent(['openid', 'profile', 'offline_access', 'user.read.all', 'group.read.all'].join(' '));
            this.document.location.href = `${authority}oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${authenticationUrl}&state=${state}&scope=${scopes}&response_type=code&response_mode=query`;
        }

        return !!this.oauthService.authenticated;
    }
}
