import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnumHelper } from 'common';
import { BankingTransactionFilters, BankingTransactionQueryParams, BankingTransactionStatus, BankingTransactionType } from '../banking.model';

@Component({
  selector: 'ifb-banking-transaction-list-filter-dialog',
  templateUrl: './banking-transaction-list-filter-dialog.component.html',
  styleUrls: ['./banking-transaction-list-filter-dialog.component.css']
})
export class BankingTransactionListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  form: UntypedFormGroup;
  filters: BankingTransactionFilters;
  today = new Date();

  bankingTransationStatusOptions = EnumHelper.getNamesAndValues(BankingTransactionStatus);
  bankingTransationTypeOptions = EnumHelper.getNamesAndValues(BankingTransactionType);

  constructor(public dialogRef: MatDialogRef<BankingTransactionListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new BankingTransactionFilters(data.query);
    this.form = this.formBuilder.group({
      fromDate: new UntypedFormControl(this.data.query.fromDate ? new Date(this.data.query.fromDate) : undefined, { validators: null, updateOn: 'blur' }),
      toDate: new UntypedFormControl(this.data.query.toDate ? new Date(this.data.query.toDate) : undefined, { validators: null, updateOn: 'blur' }),
      includePending: this.data.query.includePending || undefined,
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new BankingTransactionFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  static show(dialog: MatDialog, query: BankingTransactionQueryParams): Observable<any> {
    return dialog.open(BankingTransactionListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }

  onDateChange() {
    const control = this.form.get('fromDate');
    if (!(control.value instanceof Date))
      control.setValue(undefined);
  }

}
