<ifc-app-scroll-container class="ifc-app-scroll-container-center">

  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <form [formGroup]="form" ngClass.xs="layout fill">

      <div *ngIf="data.account?.productCode === productCode['Case Financing']" fxLayout="row"
        fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs"
        [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput type="text" formControlName="description" rows="11"></textarea>
        </mat-form-field>
        <div fxLayout="column" fxLayoutAlign="start start">
          <mat-form-field appearance="outline">
            <mat-label>Case number</mat-label>
            <input matInput type="text" formControlName="externalId">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Policy number</mat-label>
            <input matInput type="text" formControlName="policyNumber">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Policy limit</mat-label>
            <input matInput formControlName="policyLimit" currencyMask
              [options]="{ precision: '2', allowNegative: true}">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Loan number</mat-label>
          <input matInput type="text" formControlName="loanNumber">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Salesforce Loan ID</mat-label>
          <input matInput type="text" formControlName="uniqueId">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" [ngClass.lt-lg]="'form-field-lg'">
        <mat-form-field appearance="outline">
          <mat-label>Application number</mat-label>
          <input matInput type="text" formControlName="applicationNumber">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Salesforce Application ID</mat-label>
          <input matInput type="text" formControlName="applicationUniqueId">
        </mat-form-field>
      </div>

      <div style="padding-bottom: 50px;" class="flex row end-center">
        <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
      </div>

    </form>

  </div>

</ifc-app-scroll-container>
