import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';
import { UserHintData } from '../user.model';
import { AutocompleteComponent } from 'common';

@Component({
  selector: 'ifb-user-email-input',
  templateUrl: '../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class UserEmailInputComponent extends AutocompleteComponent {

  constructor(private userService: UserService) {
    super();
  }

  label = 'Email';

  optionFilter: (input: string | UserHintData) => Observable<any[]> =
    input => this.userService.queryHints({
      email: !!input ? (typeof input !== 'string' ? input.email : input) : null,
      sortField: 'email'
    })

  optionLabelKey = 'username';
}
