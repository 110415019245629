import { Injectable } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { DocumentQueryParams, DocumentFilters, MatTableHelper } from "common";

@Injectable({
    providedIn: 'root'
})
export class DocumentsFilterService {

    constructor(
        private router: Router
    ) { }

    filter(filters: DocumentFilters, paginator?: MatPaginator, sort?: MatSort) {
        const query = new DocumentQueryParams(filters);

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        this.router.navigate([this.router.url.split('?')[0]], { replaceUrl: true, queryParams: query });
    }
}
