import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { LogFilters, LogQueryParams, LogFilterOptions } from '../log.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { FilterRange } from 'common';
import * as moment from 'moment';
import { AccountService } from '../../account/account.service';
import { EntityService } from '../../entity/entity.service';
import { CustomerService } from '../../customer/customer.service';

@Component({
  selector: 'ifb-log-list-filter-dialog',
  templateUrl: './log-list-filter-dialog.component.html',
  styleUrls: ['./log-list-filter-dialog.component.css']
})
export class LogListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: LogFilters;
  customerInputLabel: string;

  constructor(public dialogRef: MatDialogRef<LogListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private entityService: EntityService,
    private customerService: CustomerService) {
    this._unsubscribeAll = new Subject();
    this.filters = new LogFilters(data.query);
    this.form = this.formBuilder.group({
      id: this.data.query.id || '',
      timestamp: this.data.query.timestamp || '',
      timestampStart: new UntypedFormControl(this.data.query.timestampStart ? new Date(this.data.query.timestampStart) : undefined, { validators: null, updateOn: 'blur' }),
      timestampEnd: new UntypedFormControl(this.data.query.timestampEnd ? new Date(this.data.query.timestampEnd) : undefined, { validators: null, updateOn: 'blur' }),
      level: this.data.query.level || '',
      endpoint: this.data.query.endpoint || '',
      hostName: this.data.query.hostName || '',
      logger: this.data.query.logger || '',
      errorId: this.data.query.errorId || '',
      correlationId: this.data.query.correlationId || '',
      principalId: this.data.query.principalId || '',
      principalName: this.data.query.principalName || '',
      entity: this.data.query.entity ? this.getBusinessNameData() : '',
      loan: this.data.query.loan ? this.getLoanNumberData() : '',
      customer: this.data.query.customer ? this.getEmailData() : '',
      active: this.data.query.active || undefined
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new LogFilters(data);
      });

    this.customerInputLabel = "Customer email";
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  static show(dialog: MatDialog, query: LogQueryParams, options: LogFilterOptions): Observable<any> {
    return dialog.open(LogListFilterDialogComponent, {
      data: {
        query: query,
        options: options
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

  dateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      timestampStart: dataRange.from,
      timestampEnd: dataRange.to
    });
  }

  getLoanNumberData(): void {
    this.accountService.queryHints({
      id: this.data.query.loan,
      sortField: 'loanNumber'
    }).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.form.controls.loan.setValue(res[0]);
      });
  }

  getBusinessNameData(): void {
    this.entityService.queryHints({
      id: this.data.query.entity,
      sortField: 'businessName'
    }).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.form.controls.entity.setValue(res[0]);
      });
  }

  getEmailData(): void {
    this.customerService.queryHints({
      id: this.data.query.customer,
      sortField: 'email'
    }).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.form.controls.customer.setValue(res[0]);
      });
  }

}
