import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "projects/backoffice/src/app.settings";
import { LiquidityEvent, ResolutionStatus } from "common";

@Injectable({
  providedIn: "root",
})
export class LitigationService {
  private readonly baseUrl = `${this.settings.applications.url}/api/litigations`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings
  ) {}

  getLiquidityEventForLoan(loanId: number): Observable<LiquidityEvent> {
    return this.http.get<LiquidityEvent>(`${this.settings.loans.url}/api/account/${loanId}/liquidity-event`);
  }

  saveLiquidityEvent(litigationId: number, loanId: number, resolutionStatus: ResolutionStatus, resolutionDate: Date) : Observable<void> {
    return this.http.patch<any>(`${this.baseUrl}/${litigationId}/loan/${loanId}/resolution`, { status: resolutionStatus, date: resolutionDate})
  }

  deleteLiquidityEvent(litigationId: number, loanId: number): Observable<void> {
    return this.http.delete<any>(`${this.baseUrl}/${litigationId}/loan/${loanId}/resolution`)
  }
}
