<ifb-account-notes-sidenav [accountId]="data.account?.id" [notes]="data.account?.notes" [initiator]="data.userCurrent"
  [saveOnDemand]="true" [innerSidenav]="true">
  <ifc-app-scroll-container [widgetsWithTopHeader]="true">
    <div class="layout margin" [@routerTransition]="'fly'">
      <ifb-panel>
        <ifb-card>
          <ifb-account-state-widget [accountState]="accountStateWidgetData" [account]="data.account"
            [edit]="canEditBalances" [label]="'Utilization'">
          </ifb-account-state-widget>
        </ifb-card>
        <ifb-card *ngIf="canReadOffer">
          <ifb-account-offer-widget [data]="data" [edit]="canEditOffer"></ifb-account-offer-widget>
        </ifb-card>
      </ifb-panel>
    </div>
  </ifc-app-scroll-container>
</ifb-account-notes-sidenav>
