import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { ApplicationOffer } from "common";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { Observable } from "rxjs";
import {
  OfferListQueryParams,
  OfferListQueryResults,
} from "./models/offer-query.model";
import { OfferService } from "./offer.service";

@Injectable({
  providedIn: "root",
})
export class OfferListResolver implements Resolve<OfferListQueryResults> {
  constructor(private service: OfferService, private queryParamsService: QueryParamsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<OfferListQueryResults>
    | Promise<OfferListQueryResults>
    | OfferListQueryResults {
    const offerBundleId =
      route.params.offerBundleId !== undefined
        ? route.params.offerBundleId
        : route.pathFromRoot[route.pathFromRoot.length - 2].params
            .offerBundleId;
    return this.service.getOfferList(new OfferListQueryParams(
      this.queryParamsService.init({
        offerBundleId,
        ...route.queryParams
      }))
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class OfferDetailsResolver implements Resolve<ApplicationOffer> {
  constructor(private service: OfferService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ApplicationOffer>
    | Promise<ApplicationOffer>
    | ApplicationOffer {
    const offerId =
      route.params.offerId !== undefined
        ? route.params.offerId
        : route.pathFromRoot[route.pathFromRoot.length - 2].params.offerId;
    return this.service.getOffer(offerId);
  }
}
