import { QueryParams, QueryResult, parseNumber } from "common";

export interface TokenData {
    id?: number;
    clientId?: number;
    description?: string;
    createdBy?: string;
    createdOn?: Date;
    expiresAt?: Date;
    active?: boolean;
    token?: string;
}

export class TokenQueryParams extends QueryParams {
    clientId?: number;
    description?: string;
    createdBy?: string;
    createdOnStart?: any;
    createdOnEnd?: any;
    expiresAtStart?: any;
    expiresAtEnd?: any;
    active?: boolean;

    constructor(params?: any) {
      super(params);

      params = params || {};
      this.clientId = parseNumber(params.clientId) || undefined;
      this.description = params.description || undefined;
      this.createdBy =  params.createdBy || undefined;
      this.createdOnStart = params.createdOnStart || undefined;
      this.createdOnEnd = params.createdOnEnd || undefined;
      this.expiresAtStart = params.expiresAtStart || undefined;
      this.expiresAtEnd = params.expiresAtEnd || undefined;
    }
}

export interface TokenQueryResult extends QueryResult<TokenData> { }

export class TokenFilters {
    clientId: number;
    description: string;
    createdBy: string;
    createdOn: string;
    createdOnStart: Date;
    createdOnEnd: Date;
    expiredOn: string;
    expiresAtStart: Date;
    expiresAtEnd: Date;
    active: boolean;

    constructor(filters?) {
        filters = filters || {};
        this.clientId = filters.clientId || undefined;
        this.description = filters.description || undefined;
        this.createdBy =  filters.createdBy ? filters.createdBy.fullName ? filters.createdBy.fullName : filters.createdBy : undefined;
        this.createdOn = filters.createdOn || undefined;
        this.createdOnStart = filters.createdOnStart || undefined;
        this.createdOnEnd = filters.createdOnEnd || undefined;
        this.expiredOn = filters.expiredOn || undefined;
        this.expiresAtStart = filters.expiresAtStart || undefined;
        this.expiresAtEnd = filters.expiresAtEnd || undefined;
        this.active = filters.active === 'true' ? true : (filters.active === 'false' ? false : undefined);
    }

    remove(item: string) {
        this[item] = undefined;
        if (item === 'createdOn' || item === 'createdOnStart') {
            this.createdOn = undefined;
            this.createdOnStart = undefined;
            this.createdOnEnd = undefined;
        } else if (item === 'expiredOn' || item === 'expiresAtStart') {
            this.expiredOn = undefined;
            this.expiresAtStart = undefined;
            this.expiresAtEnd = undefined;
        }
    }
}
