<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md padding-bottom-0">
    <div class="card-title">Other info</div>
  </div>
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <span class="secondary-text" mat-line>Broker</span>
        <p class="item-content">
          <a routerLink="/broker/{{data?.application?.brokerId}}/profile">
            {{data?.application?.brokerName}}
          </a>
        </p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>First agent</span>
        <p class="item-content">{{data?.application?.firstAgent}}</p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Assigned agent</span>
        <p class="item-content">{{data?.application?.assignedAgent}}</p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Loan number</span>
        <p class="item-content">
          <a routerLink="/account/{{data?.application?.loanId}}/profile">
            {{data?.application?.loanNumber}}
          </a>
        </p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <span class="secondary-text" mat-line>Salesforce ID</span>
        <a class="item-content" *ngIf="data?.application?.uniqueId"
          href="{{salesforceLink(data?.application?.uniqueId)}}" target="_blank">
          {{data?.application?.uniqueId}}
        </a>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>