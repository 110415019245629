<div [@routerTransition]="'fly'">

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info"
           *ngIf="!data.offerListResult?.totalCount"
           fxLayout="row"
           fxLayoutGap="12px"
           fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table class="table"
             *ngIf="data.offerListResult?.totalCount"
             mat-table
             matSort
             [dataSource]="data.offerListResult.values"
             (matSortChange)="sortData($event)"
             aria-describedby="offer list">
        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="name"> Offer name </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="productCode">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="productCode"> Offer product </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.productCode | productCodeLabel}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="status"> Offer status </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.status | offerStatusLabel}}
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="amount"> Offer amount </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.amount | currency:'$'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentFrequency">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="paymentFrequency"> Offer payment frequency </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.paymentFrequency | paymentFrequencyLabel}} </td>
        </ng-container>

        <ng-container matColumnDef="repaymentTerm">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="repaymentTerm"> Repayment term </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.repaymentTerm}} </td>
        </ng-container>

        <ng-container matColumnDef="drawTerm">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="drawTerm"> Draw term </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.drawTerm}} </td>
        </ng-container>

        <ng-container matColumnDef="offerRate">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="offerRate"> Offer rate (AIR) </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell"
              routerLink="/application/{{applicationId}}/offer-bundle/{{offerBundleId}}/offer/{{row.id}}">
            {{row.offerRate | number:'1.2-2'}}% </td>
        </ng-container>

        <ng-container matColumnDef="uniqueId">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="uniqueId"> Salesforce ID </th>
          <td mat-cell
              *matCellDef="let row"
              class="table-cell">
            <a class="salesforce-url"
               *ngIf="row?.uniqueId"
               href="{{row.uniqueId | salesforceUrl}}"
               target="_blank"
               rel="noopener">
              {{row.uniqueId}}
            </a> </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="tableColumns"
            class="table-header"></tr>
        <tr mat-row
            *matRowDef="let row; columns: tableColumns;"
            class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.offerListResult?.totalCount <= 10">
    <mat-paginator class="paginator"
                   #paginator
                   showFirstLastButtons
                   [length]="data.offerListResult.totalCount"
                   [pageSizeOptions]="[10, 20, 50]"
                   (page)="setPage()">
    </mat-paginator>
  </div>

</div>
