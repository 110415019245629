import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "common";
import { AppSettings } from "projects/backoffice/src/app.settings";
import { Observable } from "rxjs";
import {
  BrokerUserData,
  BrokerUserEditData,
  BrokerUsersQueryParams,
  BrokerUsersQueryResult,
} from "../domain/models/broker-user.model";

@Injectable({
  providedIn: "root",
})
export class BrokerUsersRemote {
  private readonly brokerUserUrl = `${this.settings.brokers.url}/api/user`;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private settings: AppSettings
  ) {}

  query(params: BrokerUsersQueryParams): Observable<BrokerUsersQueryResult> {
    return this.http.get<BrokerUsersQueryResult>(`${this.brokerUserUrl}`, {
      headers: this.configService.environment.cache.longLived,
      params: <any>params,
    });
  }

  create(brokerUser: BrokerUserEditData): Observable<BrokerUserData> {
    return this.http.put<BrokerUserData>(`${this.brokerUserUrl}/team-invitation`, brokerUser);
  }

  update(
    brokerUser: BrokerUserData
  ): Observable<BrokerUserData> {
    return this.http.patch<BrokerUserData>(
      `${this.brokerUserUrl}/${brokerUser.id}`,
      brokerUser
    );
  }

  get(id: number): Observable<BrokerUserData> {
    return this.http.get<BrokerUserData>(`${this.brokerUserUrl}/${id}`);
  }

  resendInvitation(id: number): Observable<any> {
    return this.http.post<BrokerUserData>(`${this.brokerUserUrl}/resend-invitation/${id}`, {});
  }
}
