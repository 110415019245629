import {
  EnumHelper,
  ProductCode,
  ProductCodeLabel,
  PromotionType,
  PromotionTypeLabel,
  TransactionTypeString,
  TransactionTypeStringLabel,
} from "common";

export const getTransactionTypeOptions =
  EnumHelper.getMappedNamesAndValuesFromValues(
    TransactionTypeString,
    TransactionTypeStringLabel,
    [TransactionTypeString.Draw, TransactionTypeString.Payment]
  );

export const getProductTypeOptions = EnumHelper.getMappedNamesAndValues(
  ProductCode,
  ProductCodeLabel
);

export const getPromotionTypeOptions = EnumHelper.getMappedNamesAndValues(
  PromotionType,
  PromotionTypeLabel
);
