import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { FilterParameter } from "common";
import { readWrite } from "../../../user/user-permission/user-permission.data";
import { UserPermissionService } from "../../../user/user-permission/user-permission.service";
import { routerTransition } from "../../../shared/animations/router.animations";
import { CompetitorsFilters } from "../../models/competitors-filter.model";
import { CompetitorsFilterService } from "../../competitors-filter.service";
import { CompetitorListData } from "./models/competitor-list-data.model";
import { CompetitorListFilterDialogComponent } from "./components/competitor-list-filter-dialog/competitor-list-filter-dialog.component";
import { CompetitorListQueryParams } from "../../models/competitors-query.model";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifb-competitor-list",
  templateUrl: "./competitor-list.component.html",
  styleUrls: ["./competitor-list.component.scss"],
  animations: routerTransition,
})
export class CompetitorListComponent implements OnInit {
  filters: CompetitorsFilters;
  activeFilters: FilterParameter[] = [];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  queryParams: CompetitorListQueryParams;

  tableColumns: string[] = [
    "name",
    "active",
    "apiName",
    "description",
    "address"
  ];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private competitorsFilterService: CompetitorsFilterService,
    private queryParamsService: QueryParamsService,
  ) {
  }

  data: CompetitorListData;

  readWriteCompetitors: boolean;

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: CompetitorListData) => {
        this.data = data;
      });
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.queryParams = this.queryParamsService.init(params) as CompetitorListQueryParams;
        this.activeFilters = this.competitorsFilterService.addFilterParameters(
          this.queryParams
        );
      });

    this.userPermissionService
      .granted([readWrite("admin-competitors")])
      .subscribe((res) => (this.readWriteCompetitors = res));

    this.filters = new CompetitorsFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) {
      return;
    }

    activefilters.forEach((item) => {
      this.filters.remove(item.id);
      this.competitorsFilterService.filter(this.filters);
    });
  }

  openFilterDialog(): void {
    CompetitorListFilterDialogComponent.show(
      this.dialog,
      this.queryParams,
      this.data
    ).subscribe((result) => {
      if (result) {
        this.filters = result;
        this.paginator.firstPage();
        this.competitorsFilterService.filter(result);
      }
    });
  }

  queryWithFilter(): void {
    this.competitorsFilterService.filter(
      this.filters,
      this.paginator,
      this.sort
    );
  }
}
