<button mat-icon-button [matMenuTriggerFor]="userMenu">
  <mat-icon>settings</mat-icon>
</button>
<mat-menu #userMenu="matMenu">
  <button mat-menu-item routerLink="/about">
    <mat-icon>info</mat-icon>
    <span>About</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/sign-out">
    <mat-icon>directions_run</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

