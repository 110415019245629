import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appPageComponentDataMaster } from '../app.data';
import { AppPageComponent, AppSidenavComponent, AppBarComponent, AppBarTitleComponent } from 'common';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import {
  BankingCustomerListResolver,
  BankingAccountListResolver,
  BankingAccountDetailsResolver,
  BankingCustomerDetailsResolver,
  BankingTransactionListResolver
} from './banking.resolver';
import { BankingCustomerListComponent } from './banking-customer-list/banking-customer-list.component';
import { BankingAccountListComponent } from './banking-account-list/banking-account-list.component';
import { BankingTransactionListComponent } from './banking-transaction-list/banking-transaction-list.component';
// eslint-disable-next-line max-len
import { BankingCustomerAppBarTitleComponent } from './banking-customer-app-bar-title/banking-customer-app-bar-title.component';
import { BOAuthGuard } from '../guards/oauth.guard';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Banking customers',
  sideNavItem: 'banking'
});

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
      {
        path: 'banking/:provider/customers',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              }
            ]
          },
          {
            path: '',
            component: BankingCustomerListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              bankCustomerQueryResult: BankingCustomerListResolver
            }
          }
        ]
      },
      {
        path: 'banking/:provider/customers/:bankCustomerId/accounts',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          bankingCustomer: BankingCustomerDetailsResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: BankingCustomerAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              }
            ]
          },
          {
            path: '',
            component: BankingAccountListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              bankingAccountQueryResult: BankingAccountListResolver
            }
          }
        ]
      },
      {
        path: 'banking/:provider/customers/:bankCustomerId/accounts/:bankingAccountId/transactions',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
          bankingAccount: BankingAccountDetailsResolver,
          bankingCustomer: BankingCustomerDetailsResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: BankingCustomerAppBarTitleComponent
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              }
            ]
          },
          {
            path: '',
            component: BankingTransactionListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              bankTransactionQueryResult: BankingTransactionListResolver
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BankingRoutingModule { }
