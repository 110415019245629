<ifb-card>
  <mat-card class="ifc-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md padding-bottom-0">
      <div class="card-title">New draws</div>
      <div fxFlex="25">
        <mat-select [(ngModel)]="currentMonth" placeholder="Month" (selectionChange)="filterByMonth($event.value)">
          <mat-option *ngFor="let i of months" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
    </div>
    <mat-card-content class="content bottom-0">
      <ifb-vertical-bar-chart [options]="chartData.options" [view]="chartData.view" [data]="chartData.data">
      </ifb-vertical-bar-chart>
    </mat-card-content>
  </mat-card>
</ifb-card>
