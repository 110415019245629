import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterRange } from '../../../filter/filter.data';

@Component({
  selector: 'ifc-filter-param-amount-range-input',
  templateUrl: './filter-param-amount-range-input.component.html',
  styleUrls: ['./filter-param-amount-range-input.component.css']
})
export class FilterParamAmountRangeInputComponent {

  @Input()
  label: string;

  @Input()
  fc: UntypedFormControl;

  @Output()
  rangeSelected = new EventEmitter<any>();

  amountRange: FilterRange<number> = {};

  constructor() { }

  setDateRange(value: string) {
    switch (value) {
      case 'notZero':
        this.amountRange.except = 0;
        this.amountRange.from = undefined;
        this.amountRange.to = undefined;
        break;

      case 'belowZero':
        this.amountRange.except = undefined;
        this.amountRange.from = undefined;
        this.amountRange.to = 0;
        break;

      case 'aboveZero':
        this.amountRange.except = undefined;
        this.amountRange.from = 0;
        this.amountRange.to = undefined;
        break;

      case 'custom':
      default:
        this.amountRange.except = undefined;
        this.amountRange.from = undefined;
        this.amountRange.to = undefined;
        break;
    }

    this.rangeSelected.emit(this.amountRange);
  }
}
