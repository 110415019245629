import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FilterRange } from 'common';
import { TokenFilters, TokenQueryParams } from '../token.model';
import { ClientData } from '../../../auth/client/client.data';

@Component({
  selector: 'ifb-token-list-filter-dialog',
  templateUrl: './token-list-filter-dialog.component.html',
  styleUrls: ['./token-list-filter-dialog.component.css']
})
export class TokenListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: TokenFilters;

  constructor(public dialogRef: MatDialogRef<TokenListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder) {

    this._unsubscribeAll = new Subject();
    this.filters = new TokenFilters(data.query);


      this.form = this.formBuilder.group({
        clientId: this.data.query.clientId || '',
        description: this.data.query.description || '',
        createdBy: this.data.query.createdBy || '',
        createdOn: this.data.query.createdOn || '',
        createdOnStart: new UntypedFormControl(this.data.query.createdOnStart ? new Date(this.data.query.createdOnStart) : '', { validators: null, updateOn: 'blur' }),
        createdOnEnd: new UntypedFormControl(this.data.query.createdOnEnd ? new Date(this.data.query.createdOnEnd) : '', { validators: null, updateOn: 'blur' }),
        expiredOn: this.data.query.expiredOn || '',
        expiresAtStart: new UntypedFormControl(this.data.query.expiresAtStart ? new Date(this.data.query.expiresAtStart) : '', { validators: null, updateOn: 'blur' }),
        expiresAtEnd: new UntypedFormControl(this.data.query.expiresAtEnd ? new Date(this.data.query.expiresAtEnd) : '', { validators: null, updateOn: 'blur' }),
        active: this.data.query.active || '',
      });
  }

  static show(dialog: MatDialog, query: TokenQueryParams, clients: ClientData[]): Observable<any> {
    return dialog.open(TokenListFilterDialogComponent, {
      data: { query, clients },
      closeOnNavigation: true,
    }).afterClosed();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new TokenFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  createdDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      createdOnStart: dataRange.from,
      createdOnEnd: dataRange.to
    });
  }

  expiredDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      expiresAtStart: dataRange.from,
      expiresAtEnd: dataRange.to
    });
  }

}
