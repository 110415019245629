import { Component, OnInit } from '@angular/core';
import { AccountStatementsEditFacade } from './account-statements-edit.facade';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountStatementsEditData } from './model/account-statements-edit-data.model';
import { ActivatedRoute } from '@angular/router';
import { AppBarAction, AppBarActionsService, AppBarTitleService, FormHelper } from 'common';
import { AccountService } from '../../../account.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getActionsConfig, getStatementsFormModel } from './data/account-statements-edit-data';
import { UserPermissionService } from 'projects/backoffice/src/app/user/user-permission/user-permission.service';
import { readOnly, readWrite } from 'projects/backoffice/src/app/user/user-permission/user-permission.data';
import { TransactionTimePickerDialogComponent } from 'projects/backoffice/src/app/transaction/transaction-time-picker-dialog/transaction-time-picker-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { formatDate } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'ifb-account-statements-edit',
  templateUrl: './account-statements-edit.component.html',
  styleUrls: ['./account-statements-edit.component.scss'],
  providers: [AccountStatementsEditFacade],

})
export class AccountStatementsEditComponent implements OnInit {

  readonly EXPIRATION_SHIFT_IN_MS = 26 * 3600 * 1000;

  statementsForm: UntypedFormGroup;
  data: AccountStatementsEditData;
  editStatementsPermission: boolean;
  viewStatementsPermission: boolean;
  minStatementGenerationExpirationDate: Date;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private userPermissionService: UserPermissionService,
    private facade: AccountStatementsEditFacade,    
    private dialog: MatDialog,
    private accountService: AccountService
  ) {
    this.initData();
  }

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.updateData.bind(this));

    this.statementsForm.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(this.updateActionButtons.bind(this));

    this.statementsForm.controls.generateStatements.valueChanges
      .subscribe(value => {
        this.updateGenerateStatementsExpirationFields(value);
      }
  );

    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
  }

  private updateGenerateStatementsExpirationFields(value: any) {
    if (value) {
      this.statementsForm.controls.generateStatementsExpirationDate.setValidators(Validators.required);
      this.statementsForm.controls.generateStatementsExpirationTime.setValidators(Validators.required);
      const defaultExpirationDate = this.getDefaultExpirationDate();
      this.statementsForm.controls.generateStatementsExpirationDate.setValue(defaultExpirationDate);
      this.statementsForm.controls.generateStatementsExpirationTime.setValue(formatDate(defaultExpirationDate, 'shortTime', 'en_US'));
    } else {
      this.statementsForm.controls.generateStatementsExpirationDate.clearValidators();
      this.statementsForm.controls.generateStatementsExpirationTime.clearValidators();
      this.statementsForm.controls.generateStatementsExpirationDate.setValue(null);
      this.statementsForm.controls.generateStatementsExpirationTime.setValue(null);
    }
  }

  private initData() {
    this.statementsForm = this.formBuilder.group(
      getStatementsFormModel()
    );

    this.userPermissionService
    .granted([readWrite("servicing-account-documents-generation")])
    .subscribe((res) => (this.editStatementsPermission = res));  

    this.userPermissionService
    .granted([readOnly("servicing-account-documents-generation")])
    .subscribe((res) => (this.viewStatementsPermission = res));

    this.appBarActionsService.actions = getActionsConfig;
  }

  private updateData(data: AccountStatementsEditData) {
    this.data = data;

    let expirationDate = this.data.statements?.generateStatementsExpirationDate;
    if(!expirationDate) {
      expirationDate = this.getDefaultExpirationDate();
      this.minStatementGenerationExpirationDate = expirationDate;
    }
    else {
      const today = new Date();
       if(today > new Date(this.data.statements?.generateStatementsExpirationDate))
         this.minStatementGenerationExpirationDate = this.data.statements?.generateStatementsExpirationDate;
       else
        this.minStatementGenerationExpirationDate = this.getDefaultExpirationDate();
    }

    const formData = {
      alligatorMatch: this.data.statements?.alligatorMatch,
      generateStatements: this.data.statements?.generateStatements,
      generateStatementsExpirationDate: expirationDate,
      generateStatementsExpirationTime: expirationDate
        ? formatDate(expirationDate, 'shortTime', 'en_US')
        : undefined
    };
    this.statementsForm.patchValue(formData);
    this.appBarTitleService.title = "Statements";

    this.statementsForm.markAsPristine();
    this.updateActionButtons();
  }

  updateActionButtons() {
    if (!this.statementsForm.pending && this.statementsForm.dirty) {
      this.facade.updateActionButtons(this.statementsForm.valid);
    }
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  cancel(): void {
    this.facade.cancel();
  }

  save() {
    if (!this.statementsForm.valid) {
      FormHelper.showInvalidFormFields(this.statementsForm);
      return;
    }

    if (!this.statementsForm.dirty) {
      this.cancel();
      return;
    }

    this.facade.save(this.data.statements?.loanId, this.statementsForm);
  }

  pickTime(date: string, time: string) {
    const dateFormControl = this.statementsForm.get(date);
    const timeFormControl = this.statementsForm.get(time);
    const dialogRef = this.dialog.open(TransactionTimePickerDialogComponent, {
      width: '350px',
      data: {
        date: dateFormControl.value
      },
      closeOnNavigation: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        dateFormControl.setValue(res);
        timeFormControl.setValue(res);
        timeFormControl.markAsDirty();
        this.statementsForm.markAsDirty();
      }
    });
  }

  clear(fc: string, correspondingFc?: string) {
    const formControl = this.statementsForm.get(fc);

    if (correspondingFc) {
      const correspondingFormControl = this.statementsForm.get(correspondingFc);
      correspondingFormControl.reset();
    }

    formControl.reset();
    formControl.markAsTouched();
    formControl.markAsDirty();
    this.statementsForm.markAsDirty();
  }

  private getDefaultExpirationDate() {
    return new Date(new Date().getTime() + this.EXPIRATION_SHIFT_IN_MS);
  }
}
