<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <form [formGroup]="form">
    <ifc-app-card>
      <ifb-card>
        <div class="flex column app-card-content">

          <mat-form-field *ngIf="data.clients" appearance="outline" formControlGroup="client">
            <mat-label>Client</mat-label>
            <mat-select matInput formControlName="clientId" placeholder="Client">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let client of data.clients" [value]="client.id">{{client.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" type="text" placeholder="Description" specialIsAlphaNumeric
              [readonly]="!readWriteApiKey">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select matInput formControlName="active" placeholder="Status" [disabled]="!readWriteApiKey">
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Inactive</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="flex row end-center">
            <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
          </div>
        </div>
      </ifb-card>

      <aside class="flex column app-card-aside">
        <ifb-card>
          <h2 class="mat-display-1 layout margin-bottom">Api Key</h2>

          <div *ngIf="data.apiKey && data.apiKey.id" class="layout">
            <div class="label">OAuth2 Client ID:</div>
            <mat-form-field appearance="outline" class="layout" style="width: 100%;">
              <input #id matInput formControlName="id" type="text" [readonly]="true">
            </mat-form-field>

            <div class="flex row end-center">
              <button mat-button type="button" [disabled]="!form.get('id').value" (click)="copy(id)">Copy</button>
            </div>
          </div>

          <div *ngIf="data.apiKey && data.apiKey.id" class="layout">
            <div class="label">OAuth2 Client Secret:</div>
            <mat-form-field appearance="outline" class="layout" style="width: 100%;">
              <input #secret matInput formControlName="secret" type="text" [readonly]="true">
            </mat-form-field>

            <div class="flex row end-center">
              <button mat-button type="button" [disabled]="!form.get('secret').value" (click)="copy(secret)">Copy</button>
            </div>
          </div>

          <div *ngIf="data.apiKey && data.apiKey.createdBy" class="layout padding-top">
            <div class="value">{{data.apiKey.createdBy}}</div>
            <p class="label">Created by</p>
          </div>

          <div *ngIf="data.apiKey && data.apiKey.createdOn" class="layout">
            <div class="value">{{data.apiKey.createdOn | date: 'medium'}}</div>
            <p class="label">Created on</p>
          </div>

          <div *ngIf="data.apiKey && data.apiKey.updatedBy" class="layout padding-top">
            <div class="value">{{data.apiKey.updatedBy}}</div>
            <p class="label">Updated by</p>
          </div>

          <div *ngIf="data.apiKey && data.apiKey.updatedOn" class="layout">
            <div class="value">{{data.apiKey.updatedOn | date: 'medium'}}</div>
            <p class="label">Updated on</p>
          </div>
        </ifb-card>
      </aside>
    </ifc-app-card>
  </form>
</ifc-app-scroll-container>
