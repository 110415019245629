<mat-card class="ifc-card"
          [ngClass.xs]="'xs'">
  <div fxLayout="row"
       fxLayoutAlign="space-between start"
       class="card-header">
    <div class="card-title">{{data.offer.productName}}</div>
  </div>

  <mat-card-content class="">
    <div fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap="18px">
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Offer bundle name</span>
          <a class="item-content"
             routerLink="/application/{{data.application?.id}}/offer-bundle/{{data.offerBundle?.id}}">{{data.offerBundle.name}}</a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Salesforce offer bundle ID</span>
          <a class="item-content"
             *ngIf="data?.offerBundle?.uniqueId"
             href="{{data?.offerBundle?.uniqueId | salesforceUrl}}"
             target="_blank"
             rel="noopener">
            {{data?.offerBundle?.uniqueId}}
          </a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Renewal for loan</span>
          <ng-container *ngIf="!!data.application?.renewalForLoanId; else noLoan">
            <a class="item-content"
               routerLink="/account/{{data.application.renewalForLoanId}}/profile">{{data.application.renewalForLoanNumber}}</a>
          </ng-container>
          <ng-template #noLoan>---</ng-template>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
