import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { FailureReasonRoutingModule } from './failure-reason-routing.module';
import { FailureReasonListComponent } from './failure-reason-list/failure-reason-list.component';
import { FailureReasonDetailsComponent } from './failure-reason-details/failure-reason-details.component';
import { FailureReasonListFilterDialogComponent } from './failure-reason-list-filter-dialog/failure-reason-list-filter-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { UserModule } from '../user/user.module';
import { FailureReasonUpdateDialogComponent } from './failure-reason-update-dialog/failure-reason-update-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        UserModule,
        FailureReasonRoutingModule
    ],
    declarations: [
        FailureReasonListComponent,
        FailureReasonDetailsComponent,
        FailureReasonListFilterDialogComponent,
        FailureReasonUpdateDialogComponent
    ]
})
export class FailureReasonModule { }
