import { AppBarExpandedTabWithPermission } from '../app.data';
import { readOnly } from '../user/user-permission/user-permission.data';

export const adminAppBarExpandedTabs: AppBarExpandedTabWithPermission[] = [
  {
    label: 'Clients',
    url: '/admin/client',
    query: { active: true },
    permission: [readOnly('admin-clients')],
    hidden: true
  },
  {
    label: 'API Keys',
    url: '/admin/apikey',
    query: { active: true },
    permission: [readOnly('admin-api-keys')],
    hidden: true
  },
  {
    label: 'Users',
    url: '/admin/user',
    query: { active: true },
    permission: [readOnly('admin-users')],
    hidden: true
  },
  {
    label: 'Roles',
    url: '/admin/role',
    query: { active: true },
    permission: [readOnly('admin-roles')],
    hidden: true
  },
  {
    label: 'Tokens',
    url: '/admin/token',
    query: { active: true },
    permission: [readOnly('admin-tokens')],
    hidden: true
  },
  {
    label: 'Failure Reasons',
    url: '/admin/failurereason',
    query: { active: true },
    permission: [readOnly('admin-failure-reasons')],
    hidden: true
  },
  {
    label: 'Tenants',
    url: '/admin/tenants',
    permission: [readOnly('admin-tenants')],
    hidden: true
  },
  {
    label: 'Features',
    url: '/admin/features',
    permission: [readOnly('admin-feature-flags')],
    hidden: true
  },
  {
    label: 'Promotions',
    url: '/admin/promotions',
    query: { active: true },
    permission: [readOnly('servicing-promotions')],
    hidden: true
  },
  {
    label: 'Competitors',
    url: '/admin/competitors',
    query: { active: true },
    permission: [readOnly('admin-competitors')],
    hidden: true
  }
];
