import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ifc-app-bar-expanded-tabs',
  templateUrl: './app-bar-expanded-tabs.component.html',
  styleUrls: ['./app-bar-expanded-tabs.component.css']
})
export class AppBarExpandedTabsComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  constructor(private route: ActivatedRoute, private router: Router) { }

  get activeUrl() {
    const tab = this.getCurrentTab();
    return tab ? tab.url : this.tabs[0].url;
  }

  data: AppBarExpandedTabsComponentData;

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(
        (it: AppBarExpandedTabsComponentData) => {
          this.data = it;
        })
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  private getCurrentTab(): AppBarExpandedTab {
    return this.tabs.find(t => t.url === location.pathname);
  }

  get tabs() {
    if (
      !this.data ||
      !this.data.appBarExpandedTabs ||
      !this.data.appBarExpandedTabs.tabs ||
      !this.data.appBarExpandedTabs.tabs.length
    )
      return [];

    return this.data.appBarExpandedTabs.tabs.filter(it => !it.hidden 
      && (!it.loanStatusNotAllowed?.includes(this.data?.account?.status) || this.data?.accessNPLEconomics));
  }
}

export interface AppBarExpandedTab {
  hidden?: boolean;
  label: string;
  url: string;
  query?: any;
  loanStatusNotAllowed?: any[]
}

export interface AppBarExpandedTabsComponentData {
  account?: any;
  accessNPLEconomics?: boolean;
  appBarExpandedTabs: {
    tabs: AppBarExpandedTab[];
  };
}
