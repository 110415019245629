import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BOAuthGuard } from "../../guards/oauth.guard";
import {
  AppPageComponent,
  AppSidenavComponent,
  AppBarComponent,
  AppBarExpandedTabsComponent,
  AppBarExpandedComponent,
  AppBarTitleComponent,
  AppBarActionsComponent,
} from "common";
import {
  appPageComponentDataDetail,
  appPageComponentDataMaster,
} from "../../app.data";
import { BrokerUserDetailsComponent } from "./features/broker-user-details/broker-user-details.component";
import { BrokerUserListComponent } from "./features/broker-user-list/broker-user-list.component";
import { UserMenuComponent } from "../../user/user-menu/user-menu.component";
import { BrokerAppBarTitleComponent } from "../../broker/broker-app-bar-title/broker-app-bar-title.component";
import { BrokerAppBarExpandedTabsResolver } from "../../broker/broker.resolver";
import { AppPageResolver, AppSidenavResolver } from "../../app.resolver";
import { BrokerUserDummyComponent } from "./features/broker-user-dummy/broker-user-dummy.component";
import { BrokerUsersGuard } from "./broker-users.guard";

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: "Broker users",
  sideNavItem: "broker user",
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: "",
    canActivate: [BOAuthGuard],
    children: [
      {
        path: "broker/:id/users",
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: BrokerAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver,
        },
        children: [
          {
            path: "",
            outlet: "sidenav-content",
            component: AppSidenavComponent,
          },
          {
            path: "",
            component: AppBarComponent,
            outlet: "app-bar",
            children: [
              {
                path: "",
                outlet: "title",
                component: BrokerAppBarTitleComponent,
              },
              {
                path: "",
                outlet: "right",
                component: UserMenuComponent,
              },
              {
                path: "",
                outlet: "expanded",
                component: AppBarExpandedComponent,
                children: [
                  {
                    path: "",
                    outlet: "tabs",
                    component: AppBarExpandedTabsComponent,
                  },
                ],
              },
            ],
          },
          {
            path: "",
            component: BrokerUserListComponent,
          },
        ],
      },
      {
        path: "broker/:brokerId/users/:id",
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: "",
            component: AppBarComponent,
            outlet: "app-bar",
            children: [
              {
                path: "",
                outlet: "title",
                component: AppBarTitleComponent,
              },
              {
                path: "",
                outlet: "right",
                component: AppBarActionsComponent,
              },
            ],
          },
          {
            path: "",
            component: BrokerUserDetailsComponent,
          },
        ],
      },
      {
        path: "broker-users/:id", //TODO it is workaround, should be changed in https://ideafinancial.atlassian.net/browse/RYNO-10698
        canActivate: [BrokerUsersGuard],
        component: BrokerUserDummyComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BrokerUsersRoutingModule {}
