import { ChartConfiguration } from "chart.js";

export const getBarChartData = (
  data: number[],
  labels: string[]
): ChartConfiguration<"bar">["data"] => {
  return {
    datasets: [
      {
        data,
        borderColor: "#adc2de",
        backgroundColor: "#ffffff",
      },
    ],
    labels,
  };
};

export const getBarChartOptions: ChartConfiguration<"bar">["options"] = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return ` ${tooltipItem.formattedValue}%`;
        },
      },
    },
  },
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 14,
      left: 12,
      right: 56,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: true,
      ticks: {
        callback: function (label) {
          return `${label}%`;
        },
        color: "white",
      },
      min: 0,
      max: 100,
      grid: {
        drawBorder: true,
        color: "#9db5da",
        borderColor: "#9db5da",
      },
    },
  },
};
