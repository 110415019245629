import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { TransactionService } from "../transaction.service";
import { AccountService } from "../../account/account.service";
import { TransactionDetailsData } from "./transaction-details.data";
import { parseNumber } from "common";

@Injectable({
  providedIn: "root",
})
export class TransactionDetailsResolver
  implements Resolve<TransactionDetailsData>
{
  constructor(
    private router: Router,
    private txService: TransactionService,
    private accountService: AccountService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<TransactionDetailsData>
    | Promise<TransactionDetailsData>
    | TransactionDetailsData {
    const id = parseNumber(route.params.id);

    if (!id) {
      this.router.navigate(["error", "404"]);
    }

    return this.txService.get(id).pipe(
      flatMap((transactionData) => {
        if (!transactionData) this.router.navigate(["error", "404"]);

        const accountId = transactionData.accountId;

        return forkJoin([
          this.accountService.getWithRenewal(accountId),
          this.accountService.getInfo(accountId),
          this.accountService.getOwnersList(accountId),
        ]).pipe(
          map((results) => ({
            transactions: [transactionData],
            account: results[0],
            accountInfo: results[1],
            owners: results[2],
          }))
        );
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class AccountTransactionDetailsResolver
  implements Resolve<TransactionDetailsData>
{
  constructor(private accountService: AccountService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<TransactionDetailsData>
    | Promise<TransactionDetailsData>
    | TransactionDetailsData {
      const accountId = parseNumber(route.params.accountId);
    if (!accountId) this.router.navigate(["error", "404"]);

    return forkJoin([
      this.accountService.get(accountId),
      this.accountService.getInfo(accountId),
      this.accountService.getOwnersList(accountId),
    ]).pipe(
      map((results) => ({
        account: results[0],
        accountInfo: results[1],
        owners: results[2],
      }))
    );
  }
}
