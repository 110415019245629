import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationData } from '../application.model';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { UserData } from '../../user/user.model';
import { routerTransition } from '../../shared/animations/router.animations';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { BusinessAdvisorPickerComponent } from '../../shared/business-advisor/business-advisor-picker/business-advisor-picker.component';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationService } from '../application.service';
import { CustomerData } from '../../customer/customer.model';
import { AppSettings } from '../../../app.settings';

@Component({
  selector: 'ifb-application-profile',
  templateUrl: './application-profile.component.html',
  styleUrls: ['./application-profile.component.scss'],
  animations: routerTransition
})
export class ApplicationProfileComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  data: ApplicationProfileComponentData;
  canEditAdvisor: boolean;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private applicationService: ApplicationService, 
    private settings: AppSettings,
    private userPermissionService: UserPermissionService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: ApplicationProfileComponentData) => this.data = it);

    this.userPermissionService.granted([readWrite('servicing-applications')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canEditAdvisor = res);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  salesforceLink(salesforceRecordId: string) {
    return this.settings.salesforce.url + salesforceRecordId;
  }

  changeAdvisor() {
    BusinessAdvisorPickerComponent.show(this.dialog)
      .subscribe(res => {
        if (res && res.id) {
          this.applicationService.updateAdvisor(this.data.application.id, res.id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
              this.applicationService.get(this.data.application.id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(app => { this.data.application = app; });
            });
        } else return;
      });
  }

}

export interface ApplicationProfileComponentData {
  application: ApplicationData;
  userCurrent?: UserData;
  owners?: CustomerData[];
}
