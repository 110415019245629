import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AppBarActionsComponent,
  AppBarComponent,
  AppBarExpandedTabsComponent,
  AppBarTitleComponent,
  AppPageComponent,
  AppSidenavComponent,
} from "common";
import { appPageComponentDataDetail, appPageComponentDataMaster } from "../app.data";
import { AppPageResolver, AppSidenavResolver } from "../app.resolver";
import { UserMenuComponent } from "../user/user-menu/user-menu.component";
import { AdminAppBarExpandedTabsResolver } from "../admin/admin.resolver";
import { CompetitorListComponent } from "./components/competitor-list/competitor-list.component";
import { BOAuthGuard } from "../guards/oauth.guard";

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: "Admin",
  sideNavItem: "admin",
});
import { CompetitorDetailsComponent } from "./components/competitor-details/competitor-details.component";
import { CompetitorListResolver, CompetitorResolver } from "./competitor.resolver";

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: "admin/competitors",
    component: AppPageComponent,
    data: appPageDataMaster,
    canActivate: [BOAuthGuard],
    resolve: {
      appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
      appPage: AppPageResolver,
      appSidenav: AppSidenavResolver,
    },
    children: [
      {
        path: "",
        outlet: "sidenav-content",
        component: AppSidenavComponent,
      },
      {
        path: "",
        component: AppBarComponent,
        outlet: "app-bar",
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: UserMenuComponent,
          },
          {
            path: "",
            outlet: "expanded",
            component: AppBarExpandedTabsComponent,
          },
        ],
      },
      {
        path: "",
        component: CompetitorListComponent,
        runGuardsAndResolvers: "paramsOrQueryParamsChange",
        resolve: {
          competitorListResult: CompetitorListResolver,
        },
      },
    ],
  },
  {
    path: 'admin/competitors/:id',
    component: AppPageComponent,
    data: appPageDataDetail,
    canActivate: [BOAuthGuard],
    children: [
      {
        path: '',
        component: AppBarComponent,
        outlet: 'app-bar',
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: '',
        component: CompetitorDetailsComponent,
        resolve: {
          competitor: CompetitorResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompetitorRoutingModule {}
