<div class="content layout" fxLayout="column" fxLayoutAlign="center center" fxFlexFill.lt-lg
  ngClass.lt-lg="fit-content">

  <form [formGroup]="form" ngClass.xs="layout fill">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Legal entity name </mat-label>
        <input matInput type="text" name="legaEntityName" formControlName="name">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> DBA </mat-label>
        <input matInput type="text" name="dba" formControlName="dba">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Address </mat-label>
        <input matInput type="text" name="line1" formControlName="line1">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Unit/Suite/Apt # </mat-label>
        <input matInput type="text" name="line2" formControlName="line2">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">

      <div ngClass.xs="form-field-xs" ngClass.gt-xs="mat-form-field-md">

        <mat-form-field appearance="outline">
          <mat-label> City </mat-label>
          <input matInput type="text" name="city" formControlName="city">
        </mat-form-field>

      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
        ngClass.xs="form-field-xs" ngClass.gt-xs="mat-form-field-xs">

        <mat-form-field appearance="outline">
          <mat-label> State </mat-label>
          <mat-select matInput formControlName="state" name="state" placeholder="State">
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{state.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Zipcode </mat-label>
          <input matInput type="text" name="zip" formControlName="zip">
        </mat-form-field>

      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Primary phone </mat-label>
        <input matInput type="text" customMask="(000)000-0000" name="phone" formControlName="phone">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Tax ID </mat-label>
        <input *ngIf="canEditSensitiveData" matInput customMask="00-0000000" type="text" name="ein"  formControlName="ein">
        <input *ngIf="!canEditSensitiveData" matInput type="text" name="ein" formControlName="ein">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-xl">

      <mat-form-field appearance="outline">
        <mat-label> Website </mat-label>
        <input matInput type="text" name="website" formControlName="website">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Contact email </mat-label>
        <input matInput type="email" name="contactEmail" formControlName="contactEmail">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column"
      ngClass.xs="form-field-xs" ngClass.gt-xs="mat-form-field-md">

      <mat-form-field appearance="outline">
        <mat-label> Commission email </mat-label>
        <input matInput type="email" name="commissionEmail" formControlName="commissionEmail">
      </mat-form-field>

    </div>

  </form>

</div>
