import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FilterRange, EnumHelper, ObjectHelper, LoanStatus, IdNameData, AccountActivationStage, AccountFilters, AccountQueryParams } from 'common';
import {
  ProductCode,
  AccountModificationFlagFilter,
  AccountModificationFlagFilterMap,
  AccountOfferPaymentFrequencies
} from '../account.model';
import { AccountService } from '../account.service';
import { TenantService } from '../../admin/tenant/tenant.service';

@Component({
  selector: 'ifb-account-list-filter-dialog',
  templateUrl: './account-list-filter-dialog.component.html',
  styleUrls: ['./account-list-filter-dialog.component.scss']
})
export class AccountListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  form: UntypedFormGroup;
  filters: AccountFilters;

  statusOptions = EnumHelper.getNamesAndValues(LoanStatus);
  activationStageOptions = EnumHelper.getNamesAndValues(AccountActivationStage);
  productTypeOptions = EnumHelper.getNamesAndValues(ProductCode);
  paymentFrequencyOptions = EnumHelper.getNamesAndValues(AccountOfferPaymentFrequencies);
  modificationFlagOptions = EnumHelper.getMappedNamesAndValues(AccountModificationFlagFilter, AccountModificationFlagFilterMap);

  createdOnRange: FilterRange<moment.Moment> = {};
  renewalOnRange: FilterRange<moment.Moment> = {};
  upcomingPaymentOnRange: FilterRange<moment.Moment> = {};

  companies: IdNameData[] = [];

  constructor(
    public dialogRef: MatDialogRef<AccountListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private tenantService: TenantService) {

    this._unsubscribeAll = new Subject();
    this.filters = new AccountFilters(data.query);

    this.form = this.formBuilder.group({
      loanNumber: this.data.query.loanNumber || '',
      applicationNumber: this.data.query.applicationNumber || '',
      businessName: this.data.query.businessName || '',
      createdOn: this.data.query.createdOn || '',
      createdOnFrom: new UntypedFormControl(this.data.query.createdOnFrom ? new Date(this.data.query.createdOnFrom) : undefined, { validators: null, updateOn: 'blur' }),
      createdOnTo: new UntypedFormControl(this.data.query.createdOnTo ? new Date(this.data.query.createdOnTo) : undefined, { validators: null, updateOn: 'blur' }),
      renewalOn: this.data.query.renewalOn || '',
      renewalOnFrom: new UntypedFormControl(this.data.query.renewalOnFrom ? new Date(this.data.query.renewalOnFrom) : undefined, { validators: null, updateOn: 'blur' }),
      renewalOnTo: new UntypedFormControl(this.data.query.renewalOnTo ? new Date(this.data.query.renewalOnTo) : undefined, { validators: null, updateOn: 'blur' }),
      status: +this.data.query.status >= 0 ? +this.data.query.status : undefined,
      activationStage: +this.data.query.activationStage >= 0 ? +this.data.query.activationStage : undefined,
      active: this.data.query.active || undefined,
      productCode: this.data.query.productCode || undefined,
      description: this.data.query.description || '',
      companyId: +this.data.query.companyId || undefined,
      modificationFlags: { value: ObjectHelper.makeArray(this.data.query.modificationFlags) || '', disabled: false },
      minBalance: this.data.query.minBalance || undefined,
      maxBalance: this.data.query.maxBalance || undefined,     
      minPaymentAmount: this.data.query.minPaymentAmount || undefined,
      maxPaymentAmount: this.data.query.maxPaymentAmount || undefined,
      paymentFrequency: +this.data.query.paymentFrequency >= 0 ? +this.data.query.paymentFrequency : undefined,
      upcomingPaymentDateOn: this.data.query.upcomingPaymentDateOn || '',
      minUpcomingPaymentDate: new UntypedFormControl(this.data.query.minUpcomingPaymentDate ? new Date(this.data.query.minUpcomingPaymentDate) : undefined, { validators: null, updateOn: 'blur' }),
      maxUpcomingPaymentDate:  new UntypedFormControl(this.data.query.maxUpcomingPaymentDate ? new Date(this.data.query.maxUpcomingPaymentDate) : undefined, { validators: null, updateOn: 'blur' })
    });
  }

  static show(dialog: MatDialog, query: AccountQueryParams, canFilterByEntity: boolean): Observable<any> {
    return dialog
      .open(AccountListFilterDialogComponent, {
        data: { query: query, canFilterByEntity: canFilterByEntity },
        closeOnNavigation: true,
      })
      .afterClosed();
  }

  ngOnInit(): void {
    this.createdOnRange.from = this.filters.createdOnFrom;
    this.createdOnRange.to = this.filters.createdOnTo;
    this.renewalOnRange.from = this.filters.renewalOnFrom;
    this.renewalOnRange.to = this.filters.renewalOnTo;
    this.upcomingPaymentOnRange.from = this.filters.minUpcomingPaymentDate;
    this.upcomingPaymentOnRange.to = this.filters.maxUpcomingPaymentDate;

    this.tenantService.names().subscribe(data => this.companies = data);

    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new AccountFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dateRangeSet(range: FilterRange<moment.Moment>, value: string) {
    switch (value) {
      case 'week':
        range.from = moment().subtract(7, 'days');
        range.to = moment();
        break;

      case 'month':
        range.from = moment().subtract(1, 'months');
        range.to = moment();
        break;

      case 'custom':
        break;

      default:
        range.from = undefined;
        range.to = undefined;
        break;
    }

    this.form.patchValue({
      createdOnFrom: this.createdOnRange.from,
      createdOnTo: this.createdOnRange.to,
      renewalOnFrom: this.renewalOnRange.from,
      renewalOnTo: this.renewalOnRange.to,
      minUpcomingPaymentDate: this.upcomingPaymentOnRange.from,
      maxUpcomingPaymentDate: this.upcomingPaymentOnRange.to
    });
  }



  createdDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      createdOnFrom: dataRange.from,
      createdOnTo: dataRange.to
    });
  }

  renewalDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      renewalOnFrom: dataRange.from,
      renewalOnTo: dataRange.to
    });
  }

  upcomingPaymentDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      minUpcomingPaymentDate: dataRange.from,
      maxUpcomingPaymentDate: dataRange.to
    });
  }
}
