import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "projects/backoffice/src/app.settings";
import {
  OfferBundleListQueryParams,
  OfferBundleListQueryResults,
} from "./models/offer-bundle-query.model";
import { ApplicationOfferBundleData } from "common";

@Injectable({
  providedIn: "root",
})
export class OfferBundleService {
  private readonly baseUrl = `${this.settings.applications.url}/api/offer-bundle`;

  constructor(private http: HttpClient, private settings: AppSettings) {}

  getOfferBundleList(
    params: OfferBundleListQueryParams
  ): Observable<OfferBundleListQueryResults> {
    return this.http.get<OfferBundleListQueryResults>(`${this.baseUrl}/list`, {
      params: <any>params,
    });
  }

  getOfferBundle(id: number): Observable<ApplicationOfferBundleData> {
    return this.http.get<ApplicationOfferBundleData>(`${this.baseUrl}/${id}`);
  }
}
