<h1 mat-dialog-title>{{data.action}} api key?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are {{actionGerund | lowercase}} api key:
  </p>
  <div class="content">
    <div fxLayout="row wrap" fxFlex="100">

      <div *ngIf="data.form.controls.client && !data.form.controls.client.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.clientName || 'Null'}}</div>
        <div class="mat-caption"> Client </div>
      </div>

      <div *ngIf="data.form.controls.expiresAt && !data.form.controls.expiresAt.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.expiresAt | date:'short'}}</div>
        <div class="mat-caption"> Expires at </div>
      </div>

      <div *ngIf="data.form.controls.active && !data.form.controls.active.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.active ? 'True' : 'False'}}</div>
        <div class="mat-caption"> Active </div>
      </div>
      
      <div *ngIf="data.form.controls.description && !data.form.controls.description.pristine" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.description || 'Null'}}</div>
        <div class="mat-caption"> Description </div>
      </div>

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>