import { UntypedFormGroup } from "@angular/forms";
import {
  EnumHelper,
  FeeType,
  FeeTypeLabel,
  LoanStatus,
  LoanStatusUniqueLabel,
  ProductCode,
  ProductCodeLabel,
  PromotionType,
  PromotionTypeLabel,
  TransactionTypeString,
  TransactionTypeStringLabel,
} from "common";
import { TransactionLimitType } from "../../enums/transaction-limit-type.enum";
import {
  LinkAccountType,
  LinkAccountTypeLabel,
} from "../../enums/linked-account-type.enum";
import { UpdateFormFieldType } from "projects/backoffice/src/app/shared/const/enums/update-form-field-type.enum";
import { UpdateFormFieldData } from "projects/backoffice/src/app/shared/const/models/update-form-field-data.model";

export const updatedFieldsData = (
  promotionForm: UntypedFormGroup,
  conditionsForm: UntypedFormGroup
): UpdateFormFieldData[] => {
  if (!promotionForm?.controls || !conditionsForm?.controls) {
    return [];
  }
  const promotion = promotionForm.controls;
  const conditions = conditionsForm.controls;

  return [
    {
      label: "Name",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !promotion.name?.pristine,
      value: promotion.name?.value,
    },
    {
      label: "Product type",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !promotion.productCode?.pristine,
      value: EnumHelper.getMappedNameFromValue(
        ProductCode,
        ProductCodeLabel,
        promotion.productCode?.value
      ),
    },
    {
      label: "Description",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !promotion.description?.pristine,
      value: promotion.description?.value,
    },
    {
      label: "Start date",
      fieldType: UpdateFormFieldType.Date,
      isChanged: !promotion.startDate?.pristine,
      value: promotion.startDate?.value,
    },
    {
      label: "End date",
      fieldType: UpdateFormFieldType.Date,
      isChanged: !promotion.endDate?.pristine,
      value: promotion.endDate?.value,
    },
    {
      label: "Transaction type",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !promotion.transactionType?.pristine,
      value: EnumHelper.getMappedNameFromValue(
        TransactionTypeString,
        TransactionTypeStringLabel,
        promotion.transactionType?.value
      ),
    },
    {
      label: "Promotion type",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !promotion.promotionType?.pristine,
      value: EnumHelper.getMappedNameFromValue(
        PromotionType,
        PromotionTypeLabel,
        promotion.promotionType?.value
      ),
    },
    {
      label: "Draw fee reduction type",
      fieldType: UpdateFormFieldType.Text,
      isChanged:
        promotion.promotionType?.value === PromotionType.DrawFeeReduction &&
        !promotion.reductionType?.pristine,
      value: EnumHelper.getMappedNameFromValue(
        FeeType,
        FeeTypeLabel,
        promotion.reductionType?.value
      ),
    },
    {
      label: "Draw fee reduction value",
      fieldType: UpdateFormFieldType.TextWithSuffix,
      isChanged:
        promotion.promotionType?.value === PromotionType.DrawFeeReduction &&
        !promotion.reductionValue?.pristine,
      value: promotion.reductionValue?.value,
      suffix: promotion.reductionType?.value === FeeType.Percentage ? "%" : "$",
    },
    {
      label: "Draw fee override type",
      fieldType: UpdateFormFieldType.Text,
      isChanged:
        promotion.promotionType?.value === PromotionType.DrawFeeOverride &&
        !promotion.reductionType?.pristine,
      value: EnumHelper.getMappedNameFromValue(
        FeeType,
        FeeTypeLabel,
        promotion.reductionType?.value
      ),
    },
    {
      label: "Draw fee override value",
      fieldType: UpdateFormFieldType.TextWithSuffix,
      isChanged:
        promotion.promotionType?.value === PromotionType.DrawFeeOverride &&
        !promotion.reductionValue?.pristine,
      value: promotion.reductionValue?.value,
      suffix: promotion.reductionType?.value === FeeType.Percentage ? "%" : "$",
    },
    {
      label: "Interest rate discount type",
      fieldType: UpdateFormFieldType.Text,
      isChanged:
        promotion.promotionType?.value ===
          PromotionType.PayoffInterestReduction &&
        !promotion.reductionType?.pristine,
      value: EnumHelper.getMappedNameFromValue(
        FeeType,
        FeeTypeLabel,
        promotion.reductionType?.value
      ),
    },
    {
      label: "Interest rate discount value",
      fieldType: UpdateFormFieldType.TextWithSuffix,
      isChanged:
        promotion.promotionType?.value ===
          PromotionType.PayoffInterestReduction &&
        !promotion.reductionValue?.pristine,
      value: promotion.reductionValue?.value,
      suffix: promotion.reductionType?.value === FeeType.Percentage ? "%" : "$",
    },
    {
      label: "Active",
      fieldType: UpdateFormFieldType.Boolean,
      isChanged: !promotion.active?.pristine,
      value: promotion.active?.value,
    },
    {
      label: "Promo code",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.promoCode?.pristine,
      value: conditions.promoCode?.value,
    },
    {
      label: "Transaction limit per loan",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.transactionsPerLoanLimitType?.pristine,
      value: EnumHelper.getNameFromValue(
        TransactionLimitType,
        conditions.transactionsPerLoanLimitType?.value
      ),
    },
    {
      label: "Transaction limit",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.transactionsPerLoanLimit?.pristine &&
        conditions.transactionsPerLoanLimitType?.value === TransactionLimitType.Custom,
      value: conditions.transactionsPerLoanLimit?.value,
    },
    {
      label: "Loan utilization from",
      fieldType: UpdateFormFieldType.TextWithSuffix,
      isChanged: !conditions.loanUtilizationMin?.pristine,
      value: conditions.loanUtilizationMin?.value,
      suffix: "%",
    },
    {
      label: "Loan utilization to",
      fieldType: UpdateFormFieldType.TextWithSuffix,
      isChanged: !conditions.loanUtilizationMax?.pristine,
      value: conditions.loanUtilizationMax?.value,
      suffix: "%",
    },
    {
      label: "Loan balance from",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.loanBalanceMin?.pristine,
      value: conditions.loanBalanceMin?.value,
    },
    {
      label: "Loan balance to",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.loanBalanceMax?.pristine,
      value: conditions.loanBalanceMax?.value,
    },
    {
      label: "Amount from",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.transactionAmountMin?.pristine,
      value: conditions.transactionAmountMin?.value,
    },
    {
      label: "Amount to",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.transactionAmountMax?.pristine,
      value: conditions.transactionAmountMax?.value,
    },
    {
      label: "Loan status",
      fieldType: UpdateFormFieldType.TextArray,
      isChanged: !conditions.loanStatuses?.pristine,
      value: EnumHelper.getMappedNamesFromValues(
        LoanStatus,
        LoanStatusUniqueLabel,
        conditions.loanStatuses?.value
      ),
    },
    {
      label: "Priority",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.priority?.pristine,
      value: conditions.priority?.value,
    },
    {
      label: "Customer message data",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.message?.pristine,
      value: conditions.message?.value,
    },
    {
      label: "Linked account",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.linkedAccounts?.pristine,
      value: EnumHelper.getMappedNameFromValue(
        LinkAccountType,
        LinkAccountTypeLabel,
        conditions.linkedAccounts?.value
      ),
    },
    {
      label: "Has litigation cost protection policy",
      fieldType: UpdateFormFieldType.Text,
      isChanged: !conditions.hasLcpPolicy.pristine,
      value: conditions.hasLcpPolicy.value
    },
    {
      label: "Exclusive promotion",
      fieldType: UpdateFormFieldType.Boolean,
      isChanged: !conditions.exclusivePromotion?.pristine,
      value: conditions.exclusivePromotion?.value,
    },
  ];
};
