import { Component, Input, ViewChild } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MatPaginator } from "@angular/material/paginator";
import { BrokerApiKeyListActionsInternalService } from "../../actions/broker-api-key-actions.internal.service";
import {
  BrokerApiKeyData,
  BrokerApiKeyListInputData,
  BrokerApiKeysActiveLabel,
} from "../../broker-api-key.model";

@UntilDestroy()
@Component({
  selector: "ifc-broker-api-key-list",
  templateUrl: "./broker-api-key-list.component.html",
  styleUrls: ["./broker-api-key-list.component.scss"]
})
export class BrokerApiKeyListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private _inputData: BrokerApiKeyListInputData;

  @Input()
  get inputData(): BrokerApiKeyListInputData {
    return this._inputData;
  }

  set inputData(data: BrokerApiKeyListInputData) {
    this._inputData = data;

    this.paginator.pageSize = this._inputData?.filterData?.limit;
    this.paginator.pageIndex =
      this._inputData?.filterData?.skip / this._inputData?.filterData?.limit;
  }

  tableColumns = [
    "name",
    "description",
    "createdBy",
    "createdOn",
    "updatedBy",
    "updatedOn",
    "active",
    "clientId",
    "overflow",
  ];

  readonly BrokerApiKeysActiveLabel = BrokerApiKeysActiveLabel;

  constructor(
    private readonly actionService: BrokerApiKeyListActionsInternalService
  ) {}

  onFilter() {
    this.actionService.filter(this.paginator);
  }

  onDeleteClick(id: number) {
    this.actionService.delete(id);
  }

  onStatusChangeClick(row: BrokerApiKeyData) {
    this.actionService.statusChanged(row);
  }

  onEditClick(id: number) {
    this.actionService.edit(id);
  }
}
