import { Component, OnInit } from '@angular/core';
import { AccountOnboarding } from '../../../account/account.model';
import { AccountService } from '../../../account/account.service';

@Component({
  selector: 'ifb-account-onboarding-list',
  templateUrl: './account-onboarding-list.component.html',
  styleUrls: ['./account-onboarding-list.component.scss']
})
export class AccountOnboardingListComponent implements OnInit {

  data: AccountOnboarding;

  constructor(private _accountService: AccountService) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this._accountService.onboardingInfo()
      .subscribe((data) => this.data = data);
  }

}
