import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { BankAccountsListGroupedByInstitutionData } from '../../bank/bank.model';
import { BrokerData } from '../broker.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';

@Component({
  selector: 'ifb-broker-bank-account-list',
  templateUrl: './broker-bank-account-list.component.html',
  styleUrls: ['./broker-bank-account-list.component.scss']
})
export class BrokerBankAccountListComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;

  canReadWriteBank: boolean;

  constructor(
    private userPermissionService: UserPermissionService,
    private router: Router,
    private route: ActivatedRoute) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.userPermissionService.granted([readWrite('servicing-bank-accounts')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canReadWriteBank = res);
  }

  data: BrokerBankAccountListComponentData;

  dataInit(data: BrokerBankAccountListComponentData) {
    this.data = data;
  }

  addBankAccountLink() {
    this.router.navigate([`broker/${this.data.broker.id}/banks/new`]);
  }
}

export interface BrokerBankAccountListComponentData {
  bankAccountsListGroupedByInstitution?: BankAccountsListGroupedByInstitutionData[];
  broker?: BrokerData;
}
