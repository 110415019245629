<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div fxHide.xs class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
        <button *ngIf="readWriteToken" mat-icon-button routerLink="/admin/token/new" class="secondary-text" matTooltip="Add">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <div fxHide fxShow.xs>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" class="secondary-text more-actions-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="filterDialog()">
            <mat-icon>filter_list</mat-icon>
            <span>Filter</span>
          </button>
          <button mat-menu-item routerLink="/admin/token/new">
            <mat-icon>add_circle</mat-icon>
            <span>Add</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info" *ngIf="!data.tokenQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table *ngIf="data.tokenQueryResult.totalCount" mat-table matSort [dataSource]="data.tokenQueryResult.values"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="client"> Client </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/token/{{row.id}}" class="table-cell"> {{row.client}}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="description"> Description </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/token/{{row.id}}" class="table-cell">
            {{row.description}} </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy"> Created by </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/token/{{row.id}}" class="table-cell"> {{row.createdBy}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="createdOn"> Created on </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/token/{{row.id}}" class="table-cell">
            {{row.createdOn | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="expiresAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="expiresAt"> Expires at </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/token/{{row.id}}" class="table-cell">
            {{row.expiresAt | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="active"> Status </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/token/{{row.id}}" class="table-cell">
            {{row.active ? 'Active' : 'Inactive'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="token">
          <th mat-header-cell *matHeaderCellDef> Token </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="!tokenRevealed[row.id]" class="table-cell" (click)="tokenReveal(row.id)">
              Click to reveal
            </span>
            <span *ngIf="tokenRevealed[row.id]">
              {{tokenRevealed[row.id].token}}
            </span>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="actions" style="width: 50px;"> </th>
          <td mat-cell *matCellDef="let row" class="table-cell" routerLink="/admin/token/{{row.id}}">
            <button mat-icon-button class="action-edit">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.tokenQueryResult.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.tokenQueryResult.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>
