import { Pipe, PipeTransform } from "@angular/core";
import { EnumHelper } from "../../helpers/enum.helper";
import {
  CheckoutRequirementStatus,
  CheckoutRequirementStatusLabel,
} from "../application.model";

@Pipe({
  name: "checkoutRequirementStatusLabel",
})
export class CheckoutRequirementStatusLabelPipe implements PipeTransform {
  transform(value: CheckoutRequirementStatus): string {
    return EnumHelper.getMappedNameFromValue(
      CheckoutRequirementStatus,
      CheckoutRequirementStatusLabel,
      value
    );
  }
}
