import { Component } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { AutocompleteComponent } from "common";
import { EntityHintData } from "../../entity/entity.model";
import { EntityService } from "../../entity/entity.service";

@Component({
  selector: "ifb-business-name-input",
  templateUrl:
    "../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html",
  styleUrls: [
    "../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css",
  ],
})
export class BusinessNameInputComponent extends AutocompleteComponent {
  constructor(private entityService: EntityService) {
    super();
  }

  label = "Company name";

  optionFilter: (input: string | EntityHintData) => Observable<any[]> = (
    input
  ) =>
    input
      ? this.entityService.queryHints({
          businessName: !!input
            ? typeof input !== "string"
              ? input.businessName
              : input
            : null,
          sortField: "businessName",
        })
      : EMPTY;

  optionLabelKey = "businessName";
}
