import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../customer.service';
import { Subject } from 'rxjs';
import { CustomerComponentData } from '../customer.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ifb-customer-app-bar-title',
  templateUrl: './customer-app-bar-title.component.html',
  styleUrls: ['./customer-app-bar-title.component.scss']
})
export class CustomerAppBarTitleComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  title: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService) {
    this._unsubscribeAll = new Subject();
  }

  backToCustomerList() {
    this.router.navigateByUrl(this.customerService.prevCustomerListUrl || '/customer');
  }

  backToCustomerDetails() {
    this.router.navigateByUrl(`/customer/${this.data.customer.id}/profile`);
  }

  data: CustomerComponentData;

  dataSet(data: CustomerComponentData) {
    this.data = data;
    this.title = data.customer ? `${data.customer.firstName || ''} ${data.customer.lastName || ''}` : '';
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataSet.bind(this));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
