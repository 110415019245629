<ifb-application-notes-sidenav [applicationId]="data.application?.id" [notes]="data.application?.notes"
  [initiator]="data.userCurrent" [saveOnDemand]="true" [innerSidenav]="true">

  <ifc-app-scroll-container [widgetsWithTopHeader]="true">
    <div class="layout margin flex-std" [@routerTransition]="'fly'">
      <ifb-panel>
        <ifb-card>
          <ifb-application-status-widget [data]="data"> </ifb-application-status-widget>
          <ng-container *ngIf="data?.application?.type === 'Renewal'; else ownersWidget">
            <ifb-application-renewals-widget [data]="data"></ifb-application-renewals-widget>
          </ng-container>
        </ifb-card>

        <ifb-card>
          <ifb-application-company-info-widget [data]="data"></ifb-application-company-info-widget>
          <ng-container *ngIf="data?.application?.type === 'Renewal'">
            <ng-container *ngTemplateOutlet="ownersWidget"></ng-container>
          </ng-container>
        </ifb-card>

        <ifb-card>
          <ifb-application-other-info-widget [data]="data"></ifb-application-other-info-widget>
          <ifb-business-advisor-widget [data]="data.application" [canEditAdvisor]="canEditAdvisor"
            (edit)="changeAdvisor()"></ifb-business-advisor-widget>
        </ifb-card>

        <ng-template #ownersWidget>
          <ifb-owners-widget [owners]="data.owners"></ifb-owners-widget>
        </ng-template>
      </ifb-panel>
    </div>
  </ifc-app-scroll-container>

</ifb-application-notes-sidenav>
