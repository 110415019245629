import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDocumentService, DocumentFilters, MessageService } from 'common';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';
import { takeUntil } from 'rxjs/operators';
import { routerTransition } from '../../shared/animations/router.animations';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { DocumentsFilterService } from './document-filter.service';
import { DocumentListComponent, ApplicationDocumentListComponentData } from './document-list-base.component';

@Component({
  selector: 'ifb-application-document-list',
  templateUrl: './application-document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  animations: routerTransition
})
export class ApplicationDocumentListComponent extends DocumentListComponent<ApplicationDocumentService> implements OnInit, OnDestroy {

  override data: ApplicationDocumentListComponentData;

  constructor(
    route: ActivatedRoute,
    router: Router,
    userPermissionService: UserPermissionService,
    documentService: ApplicationDocumentService,
    documentsFilterService: DocumentsFilterService,
    messageService: MessageService,
    dialog: MatDialog,
    queryParamsService: QueryParamsService) {
      super(route, router, documentService, documentsFilterService, messageService, dialog, userPermissionService, queryParamsService);
  }

  override ngOnInit() {
    this.route.data.pipe(takeUntil(this.unsubscribeAll))
      .subscribe((it: ApplicationDocumentListComponentData) => {
        this.data = it;
        this.dataSource.data = this.data && this.data.documentQueryResult ? this.data.documentQueryResult.values : [];
      });

    this.onInitBase('servicing-application-documents');
  }

  override filter() {
    this.documentsFilterService.filter(new DocumentFilters(this.queryParams), this.paginator, this.sort);
  }
}
