import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableHelper, FilterParameter } from 'common';
import { TokenFilters, TokenQueryParams } from './token.model';

@Injectable({
    providedIn: 'root'
})
export class TokenFilterService {

    constructor(
        private router: Router
    ) { }

    filter(filters: TokenFilters, paginator?: MatPaginator, sort?: MatSort) {
        const query = new TokenQueryParams(filters);

        // Set default to first page
        query.skip = undefined;

        query.createdOnStart = filters.createdOnStart ? moment(filters.createdOnStart).format('L') : undefined;
        query.createdOnEnd = filters.createdOnEnd ? moment(filters.createdOnEnd).format('L') : undefined;
        query.expiresAtStart = filters.expiresAtStart ? moment(filters.expiresAtStart).format('L') : undefined;
        query.expiresAtEnd = filters.expiresAtEnd ? moment(filters.expiresAtEnd).format('L') : undefined;

        if (paginator) {
            query.limit = paginator.pageSize;
            query.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            query.sort = MatTableHelper.getSort(query.sort, sort);
        }


        this.router.navigate(['admin', 'token'], { replaceUrl: true, queryParams: query });

    }

    addFilterParameters(query: TokenQueryParams): FilterParameter[] {
        // Refresh filters chip list
        const filters: FilterParameter[] = [];

        if (query.clientId) {
            filters.push({
                id: 'clientId', name: 'Client', value: query.clientId
            });
        }
        if (query.description) {
            filters.push({
                id: 'description', name: 'Description', value: query.description.trim()
            });
        }
        if (query.createdBy) {
            filters.push({
                id: 'createdBy', name: 'Created by', value: query.createdBy.trim()
            });
        }
        if (query.createdOnStart) {
            filters.push({
                id: 'createdOnStart', name: 'Created on',
                value: `${moment(query.createdOnStart).format('L')} - ${moment(query.createdOnEnd).format('L')}`
            });
        }
        if (query.expiresAtStart) {
            filters.push({
                id: 'expiresAtStart', name: 'Expiration on',
                value: `${moment(query.expiresAtStart).format('L')} - ${moment(query.expiresAtEnd).format('L')}`
            });
        }
        if (query.active) {
            filters.push({
                id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
            });
        }

        return filters;
    }
}
