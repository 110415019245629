import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { TransactionActionCommand } from "../transaction/transaction.model";
import { AppSettings } from "../../app.settings";
import { LookupType, TransactionPreviewResponse } from "../account/account.model";
import { BankAccountRole } from "common";

@Injectable({
    providedIn: 'root'
})
export class LoanService {

    constructor(private http: HttpClient, private settings: AppSettings) { }

    draw(id: number, amount: number, notes: any, date: Date, initiatorType: string, initiatorId: string, failureReasons?: string[]): Observable<any> {
        const url = `${this.settings.transaction.url}/api/transactions/draw/${id}`;
        return this.http.post(url, { amount, notes, date, failureReasons, initiatorType, initiatorId });
    }

    drawPreview(id: number, amount: number, initiatorType: string, initiatorId: string): Observable<TransactionPreviewResponse> {
        return this.http.get<TransactionPreviewResponse>(`${this.settings.transaction.url}/api/transactions/draw/${id}`, { params: { amount, initiatorType, initiatorId } });
    }

    pay(id: number, amount: number, notes: any, date: Date, initiatorType: string, initiatorId: string): Observable<any> {
        const url = `${this.settings.transaction.url}/api/transactions/pay/${id}`;
        return this.http.post(url, { amount, notes, date, initiatorType, initiatorId });
    }

    payPreview(id: number, amount: number, initiatorType: string, initiatorId: string): Observable<TransactionPreviewResponse> {
        return this.http.get<TransactionPreviewResponse>(`${this.settings.transaction.url}/api/transactions/pay/${id}`, { params: { amount, initiatorType, initiatorId } });
    }

    transaction(loanId: number, command: TransactionActionCommand): Observable<any> {
        const url = `${this.settings.transaction.url}/api/transactions/${loanId}`;
        return this.http.post(url, command);
    }

    lookup(requestedLookup: LookupType, value?: string): Observable<any> {
        const params = new HttpParams()
            .set('requestedLookup', requestedLookup.toString())
            .set('value', value || '');
        return this.http.get(`${this.settings.loans.url}/api/account/lookup`, { params: params })
            .pipe(map(res => res));
    }

    updateTransferBankAccount(id: number, bankAccountId: number, bankAccountRole: BankAccountRole) {
        const url = `${this.settings.loans.url}/api/account/${id}/transfer-bank?role=${bankAccountRole}`;
        return this.http.put(url, { bankAccountId });
    }
}
