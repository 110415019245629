import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { routerTransition } from "projects/backoffice/src/app/shared/animations/router.animations";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OfferBundleDetailsData } from "./models/offer-bundle-details-data.model";

@Component({
  selector: "ifb-offer-bundle-details",
  templateUrl: "./offer-bundle-details.component.html",
  animations: routerTransition,
})
export class OfferBundleDetailsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();

  data: OfferBundleDetailsData;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: OfferBundleDetailsData) => {
        this.data = data;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
