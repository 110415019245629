export class AdvisorFilters {
  fullName: any;
  constructor(filters?) {
    filters = filters || {};
    this.fullName = filters.fullName || undefined;
  }
}

export interface AdvisorHintData {
  fullName: string;
}

export interface AdvisorHintParams {
  sortField?: string;
  fullName?: string;
}
