import { Component } from '@angular/core';
import { AutocompleteComponent } from 'common';
import { Observable } from 'rxjs';
import { AuditTrailService } from '../audit-trail.service';
import { AuditTrailEventHint } from '../audit-trail.model';

@Component({
  selector: 'ifb-audit-trial-event-input',
  templateUrl: '../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class AuditTrialEventInputComponent extends AutocompleteComponent {

  constructor(private service: AuditTrailService) {
    super();
  }

  label = 'Event';

  optionFilter: (input: string | AuditTrailEventHint) => Observable<any[]> =
    input => this.service.hints({
      eventName: !!input ? (typeof input !== 'string' ? input.name : input) : null
    })

  optionLabelKey = 'name';
}
