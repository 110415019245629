<mat-nav-list>
    <ng-container *ngFor="let item of items; trackBy:trackByFn">
      <ng-container *ngIf="item.subitems">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a
                  mat-list-item
                  [routerLink]="item.url"
                  [queryParams]="item.query"
                  [ngClass]="{ selected: item.selected }"
                  (click)="$event.stopPropagation(); emitItemSelectedEvent()"
                >
                  <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
                  <span mat-line>
                    <label>{{ item.label }}</label>
                  </span>
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span *ngFor="let subitem of item.subitems; trackBy: trackByFn">
              <a
                mat-list-item
                [routerLink]="subitem.url"
                [queryParams]="subitem.query"
                [ngClass]="{ selected: subitem.selected }"
                (click)="$event.stopPropagation(); emitItemSelectedEvent()"
              >
                <span mat-line>
                  <label>{{ subitem.label }}</label>
                </span>
              </a>
            </span>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
      <ng-container *ngIf="!item.subitems">
        <a
          mat-list-item
          [routerLink]="item.url"
          [queryParams]="item.query"
          [ngClass]="{ selected: item.selected }"
          (click)="emitItemSelectedEvent()"
        >
          <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
          <span mat-line>
            <label>{{ item.label }}</label>
          </span>
        </a>
      </ng-container>
    </ng-container>
  </mat-nav-list>
