import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import {
  AppBarTitleService,
  AppBarActionsService,
  AppBarAction,
  FormHelper,
  MessageService,
  EnumHelper,
  EntityStatus,
  AppPageService
} from 'common';
import { BrokerExData } from '../broker.model';
import { BrokerService } from '../broker.service';
import { BrokerUpdateDialogComponent } from '../broker-update-dialog/broker-update-dialog.component';

@Component({
  selector: 'ifb-broker-status-details',
  templateUrl: './broker-status-details.component.html',
  styleUrls: ['./broker-status-details.component.scss']
})
export class BrokerStatusDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  form: UntypedFormGroup;

  brokerStatusOptions = EnumHelper.getNamesAndValues(EntityStatus);

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private brokerService: BrokerService,
    private messageService: MessageService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.form = this.formBuilder.group({
      status: undefined,
      contractDate: undefined,
      createdOn: { value: undefined, disabled: true },
      createdBy: { value: undefined, disabled: true },
      updatedOn: { value: undefined, disabled: true },
      updatedBy: { value: undefined, disabled: true },
      active: undefined
    });
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  data: BrokerStatusDetailsComponentData;
  dataInit(data: BrokerStatusDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Status and activation';

    this.form.reset({
      status: this.data.broker.status,
      contractDate: this.data.broker.contractDate,
      createdOn: this.data.broker.createdOn,
      createdBy: this.data.broker.createdBy,
      updatedOn: this.data.broker.updatedOn,
      updatedBy: this.data.broker.updatedBy,
      active: this.data.broker.active
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

      BrokerUpdateDialogComponent.show(this.dialog, this.form, this.data.broker.name, 'status and activation')
      .subscribe(result => {
        if (result) {
          const updateCommand = {
            status: this.form.value.status,
            contractDate: this.form.value.contractDate,
            active: this.form.value.active
          };
          this.saveSub = this.brokerService.updateStatus(this.data.broker.id, updateCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }
}

export interface BrokerStatusDetailsComponentData {
  broker?: BrokerExData;
}

