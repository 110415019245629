import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerFilters, CustomerQueryParams } from '../customer.model';


@Component({
  selector: 'ifb-customer-list-filter-dialog',
  templateUrl: './customer-list-filter-dialog.component.html',
  styleUrls: ['./customer-list-filter-dialog.component.css']
})
export class CustomerListFilterDialogComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  form: UntypedFormGroup;
  filters: CustomerFilters;

  constructor(public dialogRef: MatDialogRef<CustomerListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this._unsubscribeAll = new Subject();
    this.filters = new CustomerFilters(data.query);
    this.form = this.formBuilder.group({
      email: this.data.query.email || '',
      firstName: this.data.query.firstName || '',
      lastName: this.data.query.lastName || '',
      loanNumber: this.data.query.loanNumber || '',
      businessName: this.data.query.businessName || '',
      active: this.data.query.active || undefined,
      ssn: this.data.query.ssn || '',
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.filters = new CustomerFilters(data);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  static show(dialog: MatDialog, query: CustomerQueryParams): Observable<any> {
    return dialog.open(CustomerListFilterDialogComponent, {
      data: { query },
      closeOnNavigation: true,
    }).afterClosed();
  }



}
