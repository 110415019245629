<mat-sidenav-container>

  <mat-sidenav #sidenav mode="side" [(opened)]="notesOpened" position="end" class="notes-sidenav mat-elevation-z8"
    ngClass.lt-md="notes-sidenav-sm" style="overflow: hidden">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md mat-light-blue-bg">
      <div><span class="mat-title secondary-text">Notes</span></div>
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon class="secondary-text">keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <ifc-app-scroll-container [innerSidenav]="innerSidenav" [innerSidenavAndList]="innerSidenavAndList"
      [innerSidenavAndTabbed]="innerSidenavAndTabbed">
      <div class="layout padding-md">
        <ifb-note-card [fc]="noteInput" [notes]="notes" [initiator]="initiator" [saveOnDemand]="saveOnDemand"
          (save)="addNote($event)" [ngClass.lt-lg]="'layout margin-top'">
        </ifb-note-card>
      </div>
    </ifc-app-scroll-container>
  </mat-sidenav>

  <mat-sidenav-content>
    <button *ngIf="canEditNotes && !notesOpened && !hiddenButton" mat-icon-button
      class="mat-elevation-z2 notes-sidenav-button" (click)="sidenav.toggle()">
      <mat-icon class="secondary-text">rate_review</mat-icon>
    </button>
    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>
