import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SharedModule } from '../shared/shared.module';
import { AccountDocumentListComponent } from '../document/document-list/account-document-list.component';
import { AccountDocumentsUploadComponent } from '../document/document-upload/account-documents-upload.component';
import { ApplicationDocumentsUploadComponent } from '../document/document-upload/application-documents-upload.component';
import { ApplicationDocumentListComponent } from './document-list/application-document-list.component';
import { DocumentRoutingModule } from './document-routing.module';
import { UppyAngularDashboardModule } from '@uppy/angular';
import { AccountDocumentDetailsComponent } from './document-details/account-document-details.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        UppyAngularDashboardModule,
        DocumentRoutingModule
    ],
    declarations: [
        AccountDocumentListComponent,
        AccountDocumentsUploadComponent,
        ApplicationDocumentListComponent,
        ApplicationDocumentsUploadComponent,
        AccountDocumentDetailsComponent
    ]
})
export class DocumentModule { }
