import { Component } from '@angular/core';
import { routerTransition } from '../../shared/animations/router.animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditTrailFilterService } from '../../shared/audit-trail/audit-trail-filter.services';
import { AuditTrailComponent } from '../../shared/audit-trail/audit-trail-list/audit-trail.component';
import { AuditTrailData } from '../../shared/audit-trail/audit-trail.model';
import { AuditTrailService } from '../../shared/audit-trail/audit-trail.service';
import { MatDialog } from '@angular/material/dialog';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-entity-audit-trail',
  templateUrl: '../../shared/audit-trail/audit-trail-list/audit-trail.component.html',
  styleUrls: ['../../shared/audit-trail/audit-trail-list/audit-trail.component.scss'],
  animations: routerTransition
})
export class EntityAuditTrailComponent extends AuditTrailComponent {

  innerTabbed = true;

  constructor(
    public route: ActivatedRoute,
    public _auditTrailFilterService: AuditTrailFilterService,
    private auditService: AuditTrailService,
    private router: Router,
    public dialog: MatDialog,
    public queryParamsService: QueryParamsService) {
    super(dialog, route, _auditTrailFilterService, queryParamsService);
  }

  download(): void {
    if (this.route.snapshot.params.id) {
      const params = this.queryParamsService.init({ entity: this.route.snapshot.params.id, ...this.queryParams });
      this.auditService.auditDownload(params);
    }
  }

  accountAuditDetailsLink(audit: AuditTrailData, newWindow: boolean = false) {
    const path = `/audit/${audit.id}`;

    if (newWindow)
      window.open(path);
    else
      this.router.navigate([path]);
  }

}
