<mat-dialog-content class="mat-typography">
    <h3>Set filter</h3>
    <form [formGroup]="form">
  
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
  
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" type="text" placeholder="Name">
          <button type="button" *ngIf="form.get('name').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('name').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>DBA</mat-label>
          <input matInput formControlName="dba" type="text" placeholder="DBA">
          <button type="button" *ngIf="form.get('dba').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('dba').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
  
        <mat-form-field appearance="outline">
          <mat-label>Tax ID</mat-label>
          <input matInput formControlName="ein" type="text" placeholder="Tax ID">
          <button type="button" *ngIf="form.get('ein').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('ein').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
        <!-- Commented temporarily - LOC-1199 -->
        <!-- <mat-form-field appearance="outline">
          <mat-label>Salesforce ID</mat-label>
          <input matInput formControlName="uniqueId" type="text" placeholder="Salesforce ID">
          <button type="button" *ngIf="form.get('uniqueId').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('uniqueId').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field> -->

        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select matInput multiple formControlName="types" placeholder="Type">
            <mat-option *ngFor="let t of brokerTypes" [value]="t.value">{{t.name}}</mat-option>
          </mat-select>
          <button type="button" *ngIf="form.get('types').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('types').setValue(null)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">     
  
        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select matInput multiple formControlName="statuses" placeholder="Status">
            <mat-option *ngFor="let s of entityStatuses" [value]="s.value">{{s.name}}</mat-option>
          </mat-select>
          <button type="button" *ngIf="form.get('statuses').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('statuses').setValue(null)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Pricing plan</mat-label>
          <mat-select matInput multiple formControlName="pricingPlans" placeholder="Pricing plan">
            <mat-option *ngFor="let p of brokerPricingPlans" [value]="p.value">{{p.name}}</mat-option>
          </mat-select>
          <button type="button" *ngIf="form.get('pricingPlans').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('pricingPlans').setValue(null)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
  
        <mat-form-field appearance="outline">
          <mat-label>Tier</mat-label>
          <mat-select matInput multiple formControlName="tiers" placeholder="Tier">
            <mat-option *ngFor="let t of brokerTiers" [value]="t.value">{{t.name}}</mat-option>
          </mat-select>
          <button type="button" *ngIf="form.get('tiers').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('tiers').setValue(null)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
          
        <mat-form-field appearance="outline">
          <mat-label>Contact email</mat-label>
          <input matInput formControlName="contactEmail" type="text" placeholder="Contact email">
          <button type="button" *ngIf="form.get('contactEmail').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('contactEmail').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
  
        <mat-form-field appearance="outline">
          <mat-label>Commission email</mat-label>
          <input matInput formControlName="commissionEmail" type="text" placeholder="Commission email">
          <button type="button" *ngIf="form.get('commissionEmail').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('commissionEmail').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
          
        <mat-form-field appearance="outline">
          <mat-label>Web site</mat-label>
          <input matInput formControlName="webSite" type="text" placeholder="Web site">
          <button type="button" *ngIf="form.get('webSite').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('webSite').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
  
        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone" type="text" placeholder="Phone">
          <button type="button" *ngIf="form.get('phone').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('phone').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <ifc-filter-param-date-range-input [fc]="form.get('establishedDate')" [label]="'Established date'" (rangeSelected)="establishedDateSelected($event)">
        </ifc-filter-param-date-range-input>
  
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('establishedDate').value === 'custom'">
        <ifc-datepicker [fc]="form.get('establishedDateStart')" [label]="'Established date from'"></ifc-datepicker>
        <ifc-datepicker [fc]="form.get('establishedDateEnd')" [label]="'Established date to'"></ifc-datepicker>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">        
        <ifc-filter-param-date-range-input [fc]="form.get('contractDate')" [label]="'Contract date'" (rangeSelected)="contractDateSelected($event)">
        </ifc-filter-param-date-range-input>

        <ifc-filter-param-date-range-input [fc]="form.get('createdOn')" [label]="'Created on'" (rangeSelected)="createdDateSelected($event)">
        </ifc-filter-param-date-range-input>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('contractDate').value === 'custom'">
        <ifc-datepicker [fc]="form.get('contractDateStart')" [label]="'Contract date from'"></ifc-datepicker>
        <ifc-datepicker [fc]="form.get('contractDateEnd')" [label]="'Contract date to'"></ifc-datepicker>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('createdOn').value === 'custom'">
        <ifc-datepicker [fc]="form.get('createdOnStart')" [label]="'Created date from'"></ifc-datepicker>
        <ifc-datepicker [fc]="form.get('createdOnEnd')" [label]="'Created date to'"></ifc-datepicker>
      </div>
  
      <!-- Commented temporarily - LOC-1199 -->
     <!-- <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
        
        <div class="mat-form-field">
          <ifb-user-fullname-input [fc]="form.get('createdBy')" [label]="'Created by'"></ifb-user-fullname-input>
        </div>   
      </div>-->
  
      <!-- <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
        <div class="mat-form-field">
          <ifb-user-fullname-input [fc]="form.get('updatedBy')" [label]="'Updated by'"></ifb-user-fullname-input>
        </div>
  
        <ifc-filter-param-date-range-input [fc]="form.get('updatedOn')" [label]="'Updated on'" (rangeSelected)="updatedDateSelected($event)">
        </ifc-filter-param-date-range-input>
      </div>
  
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('updatedOn').value === 'custom'">
        <ifc-datepicker [fc]="form.get('updatedOnStart')" [label]="'Updated date from'"></ifc-datepicker>
        <ifc-datepicker [fc]="form.get('updatedOnEnd')" [label]="'Updated date to'"></ifc-datepicker>
      </div> -->
  
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
          <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select matInput formControlName="active" placeholder="Status">
            <mat-option>All</mat-option>
            <mat-option value="true">Active</mat-option>
            <mat-option value="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
    </form>
  </mat-dialog-content>
  
  <div mat-dialog-actions class="flex row end-center">
    <button mat-button [mat-dialog-close]="">Cancel</button>
    <button mat-button [mat-dialog-close]="filters" color="primary">Ok</button>
  </div>
  