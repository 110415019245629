<mat-form-field appearance="outline" fxFlexFill>
  <mat-label>Status</mat-label>
  <mat-select matInput [formControl]="fcStatus" placeholder="Status">
    <mat-option *ngFor="let option of allowedLoanStatuses" [value]="option.value">
      {{option.name | startCase}}
    </mat-option>
  </mat-select>
</mat-form-field>

<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
  <mat-form-field *ngIf="fcStatus.value === loanStatus.Frozen" appearance="outline" fxFlex="50">
    <mat-label>Freeze for days</mat-label>
    <input matInput type="text" [formControl]="fcFreezeForDays" maxlength="4" numbersOnly inputmode="numeric">
  </mat-form-field>

  <ifc-datepicker *ngIf="fcStatus.value === loanStatus.Frozen" [fc]="fcFrozenUntil" [label]="'Frozen until'" fxFlex="50">
  </ifc-datepicker>
</div>
