import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { readOnly } from './user-permission.data';
import { UserPermissionService } from './user-permission.service';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionnNPLEconomics implements Resolve<boolean> {
  constructor(private userPermissionService: UserPermissionService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
      : Observable<any> | Promise<boolean> | boolean {
      return this.userPermissionService.granted([readOnly('servicing-account-npl-economics')]);
  }
}
