import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableHelper, FilterParameter } from 'common';
import { AdvisorFilters, AdvisorQueryParams } from './advisor.model';

@Injectable({
  providedIn: 'root'
})
export class AdvisorFilterService {

  constructor(private router: Router) { }

  filter(filters: AdvisorFilters, paginator?: MatPaginator, sort?: MatSort) {
    const query: AdvisorQueryParams = filters;

    query.uniqueId = typeof query.uniqueId === 'object' ? filters.uniqueId.uniqueId : query.uniqueId;
    query.fullName = typeof query.fullName === 'object' ? filters.fullName.fullName : query.fullName;
    query.email = typeof query.email === 'object' ? filters.email.email : query.email;
    query.active = typeof query.active === 'object' ? filters.active.active : query.active;

    // Set default to first page
    query.skip = undefined;

    if (paginator) {
      query.limit = paginator.pageSize;
      query.skip = paginator.pageIndex * paginator.pageSize;
    }

    if (sort && sort.active) {
      query.sort = MatTableHelper.getSort(query.sort, sort);
    }


    this.router.navigate(['advisor'], { replaceUrl: true, queryParams: query });
  }

  addFilterParameters(query: AdvisorQueryParams): FilterParameter[] {
    const filters: FilterParameter[] = [];

    if (query.uniqueId) {
      filters.push({
        id: 'uniqueId', name: 'Unique ID', value: query.uniqueId.trim()
      });
    }
    if (query.email) {
      filters.push({
        id: 'email', name: 'Email', value: query.email.trim()
      });
    }
    if (query.fullName) {
      filters.push({
        id: 'fullName', name: 'Full Name', value: query.fullName.trim()
      });
    }
    if (query.active) {
      filters.push({
        id: 'active', name: 'Status', value: query.active.toString() === 'true' ? 'Active' : 'Inactive'
      });
    }

    return filters;
  }
}

