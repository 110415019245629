import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifb-account-statements-update-dialog',
  templateUrl: './account-statements-update-dialog.component.html',
  styleUrls: ['./account-statements-update-dialog.component.scss']
})
export class AccountStatementsUpdateDialogComponent {

  constructor(public dialogRef: MatDialogRef<AccountStatementsUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog): Observable<any> {
    return dialog.open(AccountStatementsUpdateDialogComponent, {
      width: '510px',
      closeOnNavigation: true,
    }).afterClosed();
  }

}
