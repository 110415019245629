import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBarComponent, AppBarExpandedTabsComponent, AppBarTitleComponent, AppPageComponent, AppSidenavComponent } from 'common';
import { appPageComponentDataDetail, appPageComponentDataMaster } from '../../app.data';
import { AppPageResolver, AppSidenavResolver } from '../../app.resolver';
import { ClientAllResolver } from '../../auth/client/client.resolver';
import { UserMenuComponent } from '../../user/user-menu/user-menu.component';
import { AdminAppBarExpandedTabsResolver } from '../admin.resolver';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserQueryResolver, UserResolver } from './user.resolver';
import { BOAuthGuard } from '../../guards/oauth.guard';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Admin',
  sideNavItem: 'admin'
});
const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: "",
    canActivate: [BOAuthGuard],
    children: [
      {
        path: 'admin/user',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedTabsComponent
              }
            ]
          },
          {
            path: '',
            component: UserListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              userQueryResult: UserQueryResolver,
              clients: ClientAllResolver,
            }
          }
        ]
      },
      {
        path: 'admin/user/:id',
        component: AppPageComponent,
        data: {
          appBar: appPageDataDetail.appBar,
          appBarActions: appPageDataDetail.appBarActions
        },
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              }
            ]
          },
          {
            path: '',
            component: UserDetailsComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              user: UserResolver
            },
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
