<div id="main" fxLayout="column" fxLayoutAlign="center center">

  <form [formGroup]="form" ngClass.xs="layout fill">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs"
      ngClass.gt-xs="form-field-xl">
      <mat-form-field appearance="outline">
        <mat-label>Legal entity name</mat-label>
        <input matInput type="text" formControlName="entityName">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>DBA</mat-label>
        <input matInput type="text" formControlName="entityDBA">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs"
      ngClass.gt-xs="form-field-xl">
      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input matInput type="text" formControlName="line1">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Unit/Suite/Apt #</mat-label>
        <input matInput type="text" formControlName="line2">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column">
      <div ngClass.xs="form-field-xs" ngClass.gt-xs="mat-form-field-md">
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="city">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs"
        ngClass.gt-xs="mat-form-field-xs">
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <mat-select matInput formControlName="state" placeholder="State">
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{state.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Zipcode</mat-label>
          <input matInput type="text" formControlName="zip">
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap.gt-xs="24px" fxLayout.xs="column" ngClass.xs="form-field-xs"
      ngClass.gt-xs="form-field-xl">
      <mat-form-field appearance="outline">
        <mat-label>Primary phone</mat-label>
        <input matInput type="text" customMask="(000) 000-0000" formControlName="entityPhone">
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="canReadSensitiveData">
        <mat-label>Tax ID</mat-label>
        <input *ngIf="canEditSensitiveData" matInput type="text" customMask="00-0000000" formControlName="taxId">
        <input *ngIf="!canEditSensitiveData" matInput type="text" formControlName="taxId">
      </mat-form-field>
    </div>

  </form>
</div>
