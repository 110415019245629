import { Injectable } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { Observable, Subject } from "rxjs";
import { BrokerApiKeyData, BrokerApiKeyEditData, CreateBrokerApiKeySuccessData } from "../broker-api-key.model";

@Injectable()
export class BrokerApiKeyListActionsInternalService {
  constructor() {}

  private deleteSubject: Subject<number> = new Subject();
  delete$: Observable<number> = this.deleteSubject.asObservable();

  private filterSubject: Subject<MatPaginator> = new Subject();
  filter$: Observable<MatPaginator> = this.filterSubject.asObservable();

  private statusChangedSubject: Subject<BrokerApiKeyData> = new Subject();
  statusChanged$: Observable<BrokerApiKeyData> =
    this.statusChangedSubject.asObservable();

  private saveSubject: Subject<BrokerApiKeyEditData> = new Subject();
  save$: Observable<BrokerApiKeyEditData> = this.saveSubject.asObservable();

  private markCreateAsCompltedSubject: Subject<CreateBrokerApiKeySuccessData> =
    new Subject();
  markCreateAsComplted$: Observable<CreateBrokerApiKeySuccessData> =
    this.markCreateAsCompltedSubject.asObservable();

  private editSubject: Subject<number> = new Subject();
  edit$: Observable<number> = this.editSubject.asObservable();

  delete(id: number): void {
    this.deleteSubject.next(id);
  }

  filter(paginator: MatPaginator): void {
    this.filterSubject.next(paginator);
  }

  statusChanged(row: BrokerApiKeyData): void {
    this.statusChangedSubject.next(row);
  }

  save(data: BrokerApiKeyEditData): void {
    this.saveSubject.next(data);
  }

  markCreateAsCompleted(data: CreateBrokerApiKeySuccessData): void {
    this.markCreateAsCompltedSubject.next(data);
  }

  edit(id: number): void {
    this.editSubject.next(id);
  }
}
