import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService, ResourceService } from "common";
import {
  PromotionListQueryParams,
  PromotionListQueryResults,
  PromotionsHintsQueryParams,
  PromotionsToSelectQueryParams,
} from "./models/promotions-query.model";
import {
  Promotion,
  PromotionBase,
  PromotionHintData,
  PromotionSelectOptionData,
} from "./models/promotion.model";
import { AppSettings } from "projects/backoffice/src/app.settings";

@Injectable({
  providedIn: "root",
})
export class PromotionService {
  private readonly baseUrl = `${this.settings.promotions.url}/api/promotions`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private resourceService: ResourceService,
    private configService: ConfigService
  ) {}

  getPromotion(id: number): Observable<Promotion> {
    return this.http.get<Promotion>(`${this.baseUrl}/${id}`);
  }

  getPromotionList(
    params: PromotionListQueryParams
  ): Observable<PromotionListQueryResults> {
    return this.http.get<PromotionListQueryResults>(`${this.baseUrl}`, {
      params: <any>params,
    });
  }

  getCsv(params: PromotionListQueryParams) {
    this.resourceService.download(
      `${this.baseUrl}/csv`,
      "promotions.csv",
      params
    );
  }

  getHints(
    params: PromotionsHintsQueryParams
  ): Observable<PromotionHintData[]> {
    return this.http.get<PromotionHintData[]>(`${this.baseUrl}/hints`, {
      params: <any>params,
    });
  }

  getPromotionsToSelect(
    params: PromotionsToSelectQueryParams
  ): Observable<PromotionSelectOptionData[]> {
    return this.http.get<PromotionSelectOptionData[]>(`${this.baseUrl}/promotions-to-select`, {
      params: <any>params,
      headers: this.configService.environment.cache.mediumLived
    });
  }

  updatePromotion(id: number, data: PromotionBase): Observable<Promotion> {
    return this.http.put<Promotion>(`${this.baseUrl}/${id}`, data);
  }

  addPromotion(data: PromotionBase): Observable<Promotion> {
    return this.http.post<Promotion>(`${this.baseUrl}`, data);
  }

  deletePromotion(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
