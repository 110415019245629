<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="text" placeholder="Name">
        <button type="button" *ngIf="form.get('name').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('name').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Usage</mat-label>
        <mat-select matInput formControlName="usage" placeholder="Usage">
          <mat-option *ngFor="let u of usage" [value]="u.value">{{formatUsage(u.value)}}</mat-option>
        </mat-select>
        <button type="button" *ngIf="form.get('usage').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('usage').setValue(null)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">

      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput formControlName="code" type="text" placeholder="Code">
        <button type="button" *ngIf="form.get('code').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('code').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>External code</mat-label>
        <input matInput formControlName="externalCode" type="text" placeholder="External code">
        <button type="button" *ngIf="form.get('externalCode').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('externalCode').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      
      <div class="mat-form-field">
        <ifb-user-fullname-input [fc]="form.get('createdBy')" [label]="'Created by'"></ifb-user-fullname-input>
      </div>

      <ifc-filter-param-date-range-input [fc]="form.get('createdOn')" [label]="'Created on'" (rangeSelected)="createdDateSelected($event)">
      </ifc-filter-param-date-range-input>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('createdOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('createdOnStart')" [label]="'Created date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('createdOnEnd')" [label]="'Created date to'"></ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifb-user-fullname-input [fc]="form.get('updatedBy')" [label]="'Updated by'"></ifb-user-fullname-input>
      </div>

      <ifc-filter-param-date-range-input [fc]="form.get('updatedOn')" [label]="'Updated on'" (rangeSelected)="updatedDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('updatedOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('updatedOnStart')" [label]="'Updated date from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('updatedOnEnd')" [label]="'Updated date to'"></ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
        <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
          <mat-option>All</mat-option>
          <mat-option value="true">Active</mat-option>
          <mat-option value="false">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" color="primary">Ok</button>
</div>
