import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BankAccount, BankAccountHelper, BankAccountRole } from 'common';

@Component({
  selector: 'ifb-bank-account-cannot-delete-dialog',
  templateUrl: './bank-account-cannot-delete-dialog.component.html',
  styleUrls: ['./bank-account-cannot-delete-dialog.component.css']
})
export class BankAccountCannotDeleteDialogComponent {
  bankAccountNumber: string;

  constructor(public dialogRef: MatDialogRef<BankAccountCannotDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bankAccountNumber = BankAccountHelper.sanitizeBankAccountNumber(this.data.bankAccount.accountNumber);
  }

  static show(dialog: MatDialog, bankAccount: BankAccount): Observable<any> {
    return dialog.open(BankAccountCannotDeleteDialogComponent, {
      width: '460px',
      data: {
        bankAccount: bankAccount
      },
      closeOnNavigation: true,
    }).afterClosed();
  }

  getBankAccountRole(): string {
    const role = this.data.bankAccount.entityRole;
    switch (role) {
      case BankAccountRole.Payment:
        return "payment";
      case BankAccountRole.Disbursement:
        return "disbursement";
      case BankAccountRole.PaymentAndDisbursement:
        return "payment and disbursement";
      default:
        return "transfer";
    }
  }
}
