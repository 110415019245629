import { Component, OnInit, OnDestroy, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { DialogConfirm, BankAccountType, ObservableHelper, FilterParameter } from 'common';
import { BankingAccount, BankingAccountFilters, BankingAccountQueryParams, BankingAccountQueryResult, BankingAccountStatus } from '../banking.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readOnly, readWrite } from '../../user/user-permission/user-permission.data';
import { BankingService } from '../banking.service';
import { BankingAccountListFilterService } from '../banking-account-list-filter-dialog/banking-account-list-filter.service';
// eslint-disable-next-line max-len
import { BankingAccountListFilterDialogComponent } from '../banking-account-list-filter-dialog/banking-account-list-filter-dialog.component';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-banking-account-list',
  templateUrl: './banking-account-list.component.html',
  styleUrls: ['./banking-account-list.component.css']
})
export class BankingAccountListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private subs: Subscription[] = [];

  filters: BankingAccountFilters;
  activeFilters: FilterParameter[] = [];
  queryParams: BankingAccountQueryParams;
  data: BankAccountListComponentData;
  canReadBankingTransactions = false;
  canWriteBankingAccount = false;
  bankingCustomerId = null;
  provider: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private bankingAccountListFilterService: BankingAccountListFilterService,
    private bankingService: BankingService,
    private userPermissionService: UserPermissionService,
    private queryParamsService: QueryParamsService
  ) {
    this._unsubscribeAll = new Subject();
  }

  get BankingAccountStatus() { return BankingAccountStatus; }
  get onlyPendingAccounts(): boolean { return this.queryParams.onlyPendingAccounts; }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.route.queryParams.subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.activeFilters = this.bankingAccountListFilterService.addFilterParameters(this.queryParams);
      }),
      this.userPermissionService.granted([readOnly('servicing-synced-transaction')])
        .subscribe(res => this.canReadBankingTransactions = res),
      this.userPermissionService.granted([readWrite('servicing-synced-account')])
        .subscribe(res => this.canWriteBankingAccount = res)
    ];

    this.filters = new BankingAccountFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.bankingAccountListFilterService.filter(this.provider, this.filters, this.bankingCustomerId);
    });
  }

  ngOnDestroy() {
    ObservableHelper.unsubscribeAll(this.subs);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  filterDialog() {
    BankingAccountListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.bankingAccountListFilterService.filter(this.provider, result, this.bankingCustomerId);
        }
      });
  }

  private dataInit(data: BankAccountListComponentData) {
    this.data = data;
    this.bankingCustomerId = this.route.snapshot.paramMap.get("bankCustomerId");
    this.provider = this.route.snapshot.paramMap.get("provider");
  }

  deleteCustomerSub: Subscription;

  deleteCustomer() {
    DialogConfirm.show(this.dialog, `Delete customer?`).subscribe(result => {
      if (result) {
        this.deleteCustomerSub = this.bankingService.deleteCustomer(this.provider, this.bankingCustomerId).subscribe(() => {
          this.router.navigate([`/banking/${this.provider}/customers`]);
        });
      }
    });
  }

  deleteAccountSub: Subscription;

  deleteAccount(bankingAccountId: string) {
    DialogConfirm.show(this.dialog, `Delete account?`).subscribe(result => {
      if (result)
        this.deleteAccountSub = this.bankingService.deleteAccount(this.provider, this.bankingCustomerId, bankingAccountId)
          .subscribe(() => { this.fetchData(); });
    });
  }

  fetchData() {
    this.bankingService.getAccounts(this.provider, this.bankingCustomerId, this.queryParams)
      .subscribe(res => this.data.bankingAccountQueryResult = res);
  }

  setPage(event: PageEvent) {
    this.bankingAccountListFilterService
      .filter(this.provider, this.filters, this.bankingCustomerId, this.paginator);
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  bankTransactionListLink(bankingAccount: BankingAccount) {
    if (!this.canReadBankingTransactions || bankingAccount.status === BankingAccountStatus.Pending)
      return;
    else if (this.canReadBankingTransactions) {
      this.router.navigate([`/banking/${this.provider}/customers/${bankingAccount.customerId}/accounts/${bankingAccount.id}/transactions`]);
    }
  }

  achNumbers = {};

  achNumbersReveal(bankingAccount: BankingAccount) {
    this.bankingService.getAchNumbers(this.provider, bankingAccount.customerId, bankingAccount.id)
      .subscribe(ach => {
        if (ach)
          this.achNumbers[bankingAccount.id] = ach;
      });
  }

  achNumbersAvailable(type: BankAccountType) {
    return type === BankAccountType['Checking']
      || type === BankAccountType['Savings'];
  }

  tableColumns: string[] = [
    'routingNumber',
    'accountNumber',
    'type',
    'name',
    'currency',
    'balance',
    'balanceDate',
    'status',
    'institutionName',
    'aggregationSuccessDate',
    'aggregationAttemptDate',
    'delete'
  ];
}

export interface BankAccountListComponentData {
  bankingAccountQueryResult: BankingAccountQueryResult;
}
