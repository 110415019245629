<ifc-app-scroll-container>

  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <form [formGroup]="form" ngClass.xs="layout fill">

      <ifc-app-card>
        <div class="flex column app-card-content">
          <mat-form-field appearance="outline">
            <mat-label>Principal balance</mat-label>
            <input matInput currencyMask type="text" formControlName="principalBalance">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Accrued interest</mat-label>
            <input matInput currencyMask [options]="{ allowNegative: false, precision: 16 }" type="text"
              formControlName="accruedInterest">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Total interest</mat-label>
            <input matInput currencyMask [options]="{ allowNegative: false, precision: 16 }" type="text"
              formControlName="totalInterestCharged">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Payment amount</mat-label>
            <input matInput currencyMask type="text" formControlName="paymentAmount">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Total funded amount</mat-label>
            <input matInput currencyMask type="text" formControlName="totalFundedAmount">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Fee balance</mat-label>
            <input matInput currencyMask type="text" formControlName="feeBalance">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Total fees</mat-label>
            <input matInput currencyMask type="text" formControlName="totalFees">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Repayment term override</mat-label>
            <input matInput type="text" formControlName="repaymentTermOverride" numbersOnly>
          </mat-form-field>

          <ifc-datepicker [fc]="form.get('cycleStartDate')" [label]="'Cycle start date'"></ifc-datepicker>
        </div>

        <aside fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="start start" style="margin: 0 12px !important">
          <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="24px" fxFlexFill>
            <div fxFlex="50">
              <div>{{(data.accountInfo.availableFunds | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">Available funds</div>
            </div>
            <div fxFlex="50">
              <div>{{(accountState?.principalBalance + accountState?.accruedInterest  | currency:'$') || 'Null'}}
              </div>
              <div class="mat-caption">Pay off amount</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxFlexFill>
            <div fxFlex="50">
              <div>{{(accountState?.lastDrawAmount | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">Last draw amount</div>
            </div>
            <div fxFlex="50">
              <div>{{(accountState?.lastDrawDate | date) || 'Null'}}</div>
              <div class="mat-caption">Last draw date</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxFlexFill>
            <div fxFlex="50">
              <div>{{(accountState?.firstDrawAmount | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">First draw amount</div>
            </div>
            <div fxFlex="50">
              <div>{{(accountState?.firstDrawDate | date) || 'Null'}}</div>
              <div class="mat-caption">First draw date</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxFlexFill>
            <div fxFlex="50">
              <div>{{(accountState?.lastAutoPaymentAmount | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">Last auto-payment amount</div>
            </div>
            <div fxFlex="50">
              <div>{{(accountState?.lastAutoPaymentDate | date) || 'Null'}}</div>
              <div class="mat-caption">Last auto-payment date</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxFlexFill>
            <div fxFlex="50">
              <div>{{(accountState?.lastManualPaymentAmount | currency:'$') || 'Null'}}</div>
              <div class="mat-caption">Last pre-payment amount</div>
            </div>
            <div fxFlex="50">
              <div>{{(accountState?.lastManualPaymentDate | date) || 'Null'}}</div>
              <div class="mat-caption">Last pre-payment date</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" fxFlexFill>
            <div fxFlex="50">
              <div>{{(data.account.lastReAmortizationDate | date) || 'Null'}}</div>
              <div class="mat-caption">Last re-amortization date</div>
            </div>
            <div fxFlex="50">
              <div>{{(data.account.lastMaintenanceDate | date) || 'Null'}}</div>
              <div class="mat-caption">Last maintenance date</div>
            </div>
          </div>
        </aside>
      </ifc-app-card>
    </form>

  </div>

</ifc-app-scroll-container>
