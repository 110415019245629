<h1 mat-dialog-title>{{data.action}} {{objectName}}?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are {{actionGerund | lowercase}} {{objectName}}:
  </p>
  <div class="content">
    <div fxLayout="row wrap"
         fxFlex="100">
      <ng-container *ngFor="let fieldData of data?.updatedFieldsData">
        <ng-container *ngTemplateOutlet="field; context: fieldData"></ng-container>
      </ng-container>
      <ng-template #field
                   let-fieldType="fieldType"
                   let-isChanged="isChanged"
                   let-label="label"
                   let-value="value"
                   let-suffix="suffix">
        <div *ngIf="isChanged"
             fxLayout="column"
             fxFlex="100"
             fxFlex.gt-sm="50"
             class="layout padding-vertical-md">
          <div [ngSwitch]="fieldType">
            <div *ngSwitchCase="FieldType.Date">{{value | date:'shortDate'}}</div>
            <div *ngSwitchCase="FieldType.Boolean">{{value ? 'True' : 'False'}}</div>
            <div *ngSwitchCase="FieldType.TextWithSuffix">{{value}}{{suffix}}</div>
            <div *ngSwitchCase="FieldType.TextArray">
              <ng-container *ngFor="let text of value; let index = index">
                <span>{{text}}</span>
                <span *ngIf="index !== (value?.length -1)">, </span>
              </ng-container>
            </div>
            <div *ngSwitchDefault>{{value}}</div>
          </div>
          <div class="mat-caption">{{label}}</div>
        </div>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions
     class="flex row end-center">
  <button mat-button
          [mat-dialog-close]="false">Cancel</button>
  <button mat-button
          [mat-dialog-close]="true"
          color="primary">Ok</button>
</div>
