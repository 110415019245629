import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QueryResult } from 'common';
import {
  BankingCustomerQueryParams,
  BankingCustomerQueryResult,
  BankingTransactionQueryResult,
  BankingTransactionQueryParams,
  BankingCustomer,
  BankingAccount,
  BankingAccountAchNumbers,
  BankingAccountQueryParams
} from './banking.model';
import { Observable } from 'rxjs';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class BankingService {

  private readonly baseUrl = `${this.settings.banking.url}/api/direct`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings
  ) { }

  getCustomers(provider: string, params: BankingCustomerQueryParams)
    : Observable<BankingCustomerQueryResult> {
    return this.http.get<BankingCustomerQueryResult>(`${this.baseUrl}/${provider}/customers`, { params: <HttpParams>(<unknown>params) });
  }

  getCustomer(provider: string, bankingCustomerId: string)
    : Observable<BankingCustomer> {
    return this.http.get<BankingCustomer>(`${this.baseUrl}/${provider}/customers/${bankingCustomerId}`);
  }

  deleteCustomer(provider: string, bankingCustomerId: string)
    : Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${provider}/customers/${bankingCustomerId}`);
  }


  getAccounts(provider: string, bankingCustomerId: string, params: BankingAccountQueryParams)
    : Observable<QueryResult<BankingAccount>> {
    return this.http.get<QueryResult<BankingAccount>>(`${this.baseUrl}/${provider}/customers/${bankingCustomerId}/accounts`, { params: <HttpParams>(<unknown>params) });
  }

  getAccount(provider: string, bankingCustomerId: string, bankingAccountId: string)
    : Observable<BankingAccount> {
    return this.http.get<BankingAccount>(`${this.baseUrl}/${provider}/customers/${bankingCustomerId}/accounts/${bankingAccountId}`);
  }

  getAchNumbers(provider: string, bankingCustomerId: string, bankingAccountId: string)
    : Observable<BankingAccountAchNumbers> {
    return this.http.get<BankingAccountAchNumbers>(`${this.baseUrl}/${provider}/customers/${bankingCustomerId}/accounts/${bankingAccountId}/ach-numbers`);
  }

  deleteAccount(provider: string, bankingCustomerId: string, bankingAccountId: string)
    : Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${provider}/customers/${bankingCustomerId}/accounts/${bankingAccountId}`);
  }
  

  getTransactions(provider: string, bankingCustomerId: string, bankingAccountId: string, params: BankingTransactionQueryParams)
    : Observable<BankingTransactionQueryResult> {
    return this.http.get<BankingTransactionQueryResult>(`${this.baseUrl}/${provider}/customers/${bankingCustomerId}/accounts/${bankingAccountId}/transactions`, 
      { params: <HttpParams>(<unknown>params) });
  }
}
