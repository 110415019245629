import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EntityData } from 'common';
import { EntityService } from '../entity.service';

@Component({
  selector: 'ifb-entity-app-bar-title',
  templateUrl: './entity-app-bar-title.component.html',
  styleUrls: ['./entity-app-bar-title.component.scss']
})
export class EntityAppBarTitleComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  title: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private entityService: EntityService) { }

  entityListNavigate() {
    this.router.navigateByUrl(this.entityService.prevEntityListUrl || '/company');
  }

  data;

  dataSet(data: AccountAppBarTitleComponentData) {
    this.data = data;
    this.title = data.entity.name ? `${data.entity.name}` : '';
  }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataSet.bind(this))
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

}

export interface AccountAppBarTitleComponentData {
  entity: EntityData;
}
