import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccountModificationQueryResult,
  AccountData,
  AutoPaymentMode,
  AutoPaymentModeMap,
  AccountModificationSnapshot
} from '../account.model';
import { takeUntil } from 'rxjs/operators';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { QueryParams, FeeType } from 'common';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { routerTransition } from '../../shared/animations/router.animations';
import { UserData } from '../../user/user.model';
import * as _ from 'lodash';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-account-modification-list',
  templateUrl: './account-modification-list.component.html',
  styleUrls: ['./account-modification-list.component.scss'],
  animations: routerTransition
})
export class AccountModificationListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  canUpdateModification = false;
  queryParams: any;
  data: AccountModificationListComponentData;
  displayColumns: string[] = [
    'placeholder',
    'startDate',
    'endDate',
    'active',
    'activatedOn',
    'autoPaymentMode',
    'interestRate',
    'accureInterest',
    'reAmortize',
    'paymentAmount',
    'repaymentTerm',
    'drawFee',
    'updatedOn',
    'notes'
  ];

  get FeeType() { return FeeType; }

  getAutopaymentMode(mode: AutoPaymentMode): string {
    return AutoPaymentModeMap.get(mode);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userPermissionService: UserPermissionService,
    private queryParamsService: QueryParamsService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((it: AccountModificationListComponentData) => {

        this.data = it;
        const modification = this.data.account ? this.data.account.modification as AccountModificationSnapshot : null;

        if (this.data.accountModificationQueryResult.totalCount > 1) {
          if (modification)
            modification.paymentAmount = this.data.accountModificationQueryResult.values[0].paymentAmount;
          this.data.accountModificationQueryResult.values.unshift({ label: 'Modification log', isGroup: true });
          _.remove(this.data.accountModificationQueryResult.values, modification);
        }

        if (this.data.accountModificationQueryResult.totalCount && modification) {
          if (this.data.accountModificationQueryResult.totalCount > 1)
            this.data.accountModificationQueryResult.values.unshift(modification);
          this.data.accountModificationQueryResult.values.unshift({ label: 'Modification', isGroup: true });
        }
      });

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(it => { this.queryParams = this.queryParamsService.init(it); });

    this.userPermissionService.granted([readWrite('servicing-account-modification')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.canUpdateModification = res);

    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  isGroup(index, item): boolean {
    return item && item.isGroup;
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  setPage(event: PageEvent) {
    const query: QueryParams = {};
    query.limit = this.paginator.pageSize;
    query.skip = this.paginator.pageIndex * this.paginator.pageSize;

    this.router.navigate([`account/${this.data.account.id}/modification`], { replaceUrl: true, queryParams: query });
  }
}

export interface AccountModificationListComponentData {
  account?: AccountData;
  accountModificationQueryResult: AccountModificationQueryResult;
  userCurrent?: UserData;
}
