import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SharedModule } from '../shared/shared.module';

import { BankAccountListComponent } from './bank-account-list/bank-account-list.component';
import { BankAccountDetailsComponent } from './bank-account-details/bank-account-details.component';
import { BankAccountUpdateDialogComponent } from './bank-account-update-dialog/bank-account-update-dialog.component';
import { BankAccountLinkStatusIconChipComponent } from './bank-account-link-status-icon-chip/bank-account-link-status-icon-chip.component';
// eslint-disable-next-line max-len
import { BankAccountSetTransferAccountDialogComponent } from './bank-account-set-transfer-account-dialog/bank-account-set-transfer-account-dialog.component';
import { BankAccountDeleteDialogComponent } from './bank-account-delete-dialog/bank-account-delete-dialog.component';
import { BankAccountCannotDeleteDialogComponent } from './bank-account-cannot-delete-dialog/bank-account-cannot-delete-dialog.component';
import { BankAccountTransactionListComponent } from './bank-account-transaction-list/bank-account-transaction-list.component';
// eslint-disable-next-line max-len
import { BankTransactionListFilterDialogComponent } from './bank-transaction-list-filter-dialog/bank-transaction-list-filter-dialog.component';
import { BankAccountTabsComponent } from './bank-account-tabs/bank-account-tabs.component';
import { RouterModule } from '@angular/router';
import { BankTransactionListFilterService, BankTransactionFilterService } from './bank-account-transaction-list-filter.service';
import { BankLinkingInfoWidgetComponent } from './bank-linking-info-widget/bank-linking-info-widget.component';
import { BankAccountAuditTrailComponent } from './bank-account-audit-trail/bank-account-audit-trail.component';
import { AccountModule } from '../account/account.module';
import { BankAccountSyncDialogComponent } from './bank-account-sync-dialog/bank-account-sync-dialog.component';
import { BankAccountVerificationIconChipComponent } from './bank-account-verification-icon-chip/bank-account-verification-icon-chip.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        AccountModule
    ],
    exports: [
        BankLinkingInfoWidgetComponent,
        BankAccountLinkStatusIconChipComponent,
        BankAccountVerificationIconChipComponent
    ],
    declarations: [
        BankAccountListComponent,
        BankTransactionListFilterDialogComponent,
        BankAccountTransactionListComponent,
        BankAccountDetailsComponent,
        BankAccountUpdateDialogComponent,
        BankAccountLinkStatusIconChipComponent,
        BankAccountSetTransferAccountDialogComponent,
        BankAccountDeleteDialogComponent,
        BankAccountCannotDeleteDialogComponent,
        BankAccountTabsComponent,
        BankLinkingInfoWidgetComponent,
        BankAccountAuditTrailComponent,
        BankAccountSyncDialogComponent,
        BankAccountVerificationIconChipComponent
    ],
    providers: [
        { provide: BankTransactionFilterService, useClass: BankTransactionListFilterService }
    ]
})
export class BankModule { }
