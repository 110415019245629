<ifb-card>
  <mat-card class="ifc-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md padding-bottom-0">
      <div class="card-title">Account breakdown</div>
    </div>
    <mat-card-content class="layout padding-top">
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Pending</span>
          <span class="item-content">{{data?.pending}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Open</span>
          <span class="item-content">{{data?.open}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Closed</span>
          <span class="item-content">{{data?.closed}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Frozen</span>
          <span class="item-content">{{data?.frozen}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Pending renewal</span>
          <span class="item-content">{{data?.pendingRenewal}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Pending closure</span>
          <span class="item-content">{{data?.pendingClosure}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Declined</span>
          <span class="item-content">{{data?.declined}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Renewal declined</span>
          <span class="item-content">{{data?.renewalDeclined}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Delinquent</span>
          <span class="item-content">{{data?.delinquent}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Payment relief</span>
          <span class="item-content">{{data?.paymentRelief}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Workout</span>
          <span class="item-content">{{data?.workout}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text" mat-line>Write-off</span>
          <span class="item-content">{{data?.writeOff}}</span>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ifb-card>