import { Component } from '@angular/core';
import { AutocompleteComponent } from 'common';
import { EMPTY, Observable } from 'rxjs';
import { ApplicationService } from '../application.service';
import { ApplicationHintData } from '../application.model';

@Component({
  selector: 'ifb-application-number-input',
  templateUrl: '../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class ApplicationNumberInputComponent extends AutocompleteComponent {

  constructor(private applicationService: ApplicationService) {
    super();
  }

  label = 'Application number';

  optionFilter: (input: string | ApplicationHintData) => Observable<any[]> =
    input => input
      ? this.applicationService.queryHints({
        applicationNumber: !!input ? (typeof input !== 'string' ? input.applicationNumber : input) : null,
        sortField: 'applicationNumber'
      })
      : EMPTY;

  optionLabelKey = 'applicationNumber';
}
