import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppBarTitleService, AppBarActionsService, AppBarAction, FormHelper, MessageService, QueryParams, states, AppPageService } from 'common';
import { BrokerExData } from '../broker.model';
import { BrokerService } from '../broker.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readWrite } from '../../user/user-permission/user-permission.data';
import { BrokerUpdateDialogComponent } from '../broker-update-dialog/broker-update-dialog.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'ifb-broker-company-details',
  templateUrl: './broker-company-details.component.html',
  styleUrls: ['./broker-company-details.component.scss']
})
export class BrokerCompanyDetailsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private saveSub: Subscription;

  get states() { return states; }

  readWriteBrokers = false;
  form: UntypedFormGroup;
  canEditSensitiveData: boolean;
  confirmDialogRef: any;
  data: BrokerCompanyDetailsComponentData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private brokerService: BrokerService,
    private userPermissionService: UserPermissionService,
    private messageService: MessageService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private appBarTitleService: AppBarTitleService) {
    this._unsubscribeAll = new Subject();
    this.appBarActionsService.actions = [
      { id: 'delete', label: 'Delete', disabled: true, buttonType: 'button' },
      { id: 'cancel', label: 'Cancel', buttonType: 'button' },
      { id: 'save', label: 'Save', disabled: true, buttonType: 'submit', buttonAppearance: 'flat', buttonColor: 'primary' }
    ];

    this.form = this.formBuilder.group({
      name: [undefined, Validators.required],
      dba: undefined,
      line1: [undefined, Validators.required],
      line2: undefined,
      city: [undefined, Validators.required],
      state: undefined,
      zip: [undefined, Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-?\d{4})?$/)])],
      phone: undefined,
      ein: [{ value: undefined, disabled: !this.canEditSensitiveData }],
      website: undefined,
      contactEmail: [undefined, Validators.email],
      commissionEmail: [undefined, Validators.email]
    });
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.userPermissionService.granted([readWrite('brokers-brokers-sensitive-data')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.canEditSensitiveData = res;
        if (this.canEditSensitiveData) {
          this.form.controls.ein.setValidators(Validators.compose([Validators.minLength(9), Validators.maxLength(10)]));
          this.form.controls.ein.enable();
        } else {
          this.form.controls.ein.clearValidators();
          this.form.controls.ein.disable();
        }
      });

    this.appBarActionsService.invoking.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.userPermissionService.granted([readWrite('brokers-brokers')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res && this.data.broker.active)
          this.appBarActionsService.enable('delete', true);
      });

      this.userPermissionService.granted([readWrite('brokers-brokers')])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.readWriteBrokers = res;
      });

    this.form.statusChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionUpdate.bind(this));

    this.onChanges();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty && this.readWriteBrokers) {
        this.appBarActionsService.enable('save', true);
      }
    });
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void = this[action.id].bind(this);
    actionHandler(action);
  }

  private actionUpdate() {
    this.appBarActionsService.label('save', 'Save');
  }

  cancel() {
    this.appPageService.back();
  }

  dataInit(data: BrokerCompanyDetailsComponentData) {
    this.data = data;
    this.appBarTitleService.title = 'Company info';

    this.form.reset({
      name: this.data.broker.name,
      dba: this.data.broker.dba,
      line1: this.data.broker.address.line1,
      line2: this.data.broker.address.line2,
      city: this.data.broker.address.city,
      state: this.data.broker.address.state,
      zip: this.data.broker.address.zip,
      phone: this.data.broker.phone,
      ein: this.data.broker.ein,
      website: this.data.broker.webSite,
      contactEmail: this.data.broker.contactEmail,
      commissionEmail: this.data.broker.commissionEmail
    });

    this.form.markAsPristine();
    this.actionUpdate();
  }

  delete() {
    if (!this.data.broker) return;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '510px',
      disableClose: false
    });

    this.confirmDialogRef.componentInstance
      .confirmMessage = `Delete ${this.data.broker.name} broker?`;

    this.confirmDialogRef.afterClosed().subscribe(res => {
      if (res)
        this.brokerService.delete(this.data.broker.id)
          .subscribe(() => this.deleteSuccessHandler());
    });
  }

  save() {
    FormHelper.showInvalidFormFields(this.form);
    if (this.saveSub)
      return;
    if (!this.form.valid)
      return;

    BrokerUpdateDialogComponent.show(this.dialog, this.form, this.data.broker.name, 'company')
      .subscribe(result => {
        if (result) {
          const updateCommand = {
            name: this.form.value.name,
            dba: this.form.value.dba,
            ein: this.form.value.ein,
            address: {
              line1: this.form.value.line1,
              line2: this.form.value.line2,
              city: this.form.value.city,
              state: this.form.value.state,
              zip: this.form.value.zip,
            },
            phone: this.form.value.phone,
            website: this.form.value.website,
            contactEmail: this.form.value.contactEmail,
            commissionEmail: this.form.value.commissionEmail
          };
          this.saveSub = this.brokerService.updateCompany(this.data.broker.id, updateCommand)
            .subscribe(this.saveSuccessHandler.bind(this), this.saveErrorHandler.bind(this));
        }
      });
  }

  private saveSubClear() {
    if (this.saveSub)
      this.saveSub.unsubscribe();
    this.saveSub = null;
  }

  private saveErrorHandler(error: any) {
    this.saveSubClear();
    this.messageService.error(error);
  }

  private saveSuccessHandler() {
    this.saveSubClear();
    this.appPageService.back();
  }

  deleteSuccessHandler() {
    const query: QueryParams = {
      active: true
    };
    this.router.navigate(['broker'], { replaceUrl: true, queryParams: query });
  }

}

export interface BrokerCompanyDetailsComponentData {
  advisor: any;
  broker?: BrokerExData;
}
