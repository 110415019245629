import { Component, OnInit, ViewChild, OnDestroy, Inject, forwardRef } from '@angular/core';
import { Sort, MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subscription, Subject } from 'rxjs';
import { ObservableHelper, AppBarActionsService, EnumHelper, AppBarTitleService, BankAccountHelper, BankAccount, BankAccountType, EntityData, FilterParameter } from 'common';
// eslint-disable-next-line max-len
import { BankTransactionListFilterDialogComponent } from '../bank-transaction-list-filter-dialog/bank-transaction-list-filter-dialog.component';
import { IBankTransactionListFilterService, BankTransactionFilterService } from '../bank-account-transaction-list-filter.service';
import { ApplicationData } from '../../application/application.model';
import { AccountData } from '../../account/account.model';
import {
  BankTransactionQueryResult,
  BankTransactionQueryParams,
  BankTransactionFilters
} from '../bank.model';
import { BankTransactionsService } from '../bank-transactions.service';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-bank-account-transaction-list',
  templateUrl: './bank-account-transaction-list.component.html',
  styleUrls: ['./bank-account-transaction-list.component.scss']
})
export class BankAccountTransactionListComponent implements OnInit, OnDestroy {

  protected _unsubscribeAll: Subject<any>;
  protected subs: Subscription[] = [];

  filters: BankTransactionFilters;
  activeFilters: FilterParameter[] = [];
  protected queryParams: BankTransactionQueryParams;
  data: BankTransactionListComponentData = {
    bankTransactionQueryResult: undefined,
    account: undefined,
    entity: undefined,
    application: undefined,
    bankAccount: undefined
  };
  bankAccountId: number;
  accountType: string;

  constructor(
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    private bankTransactionsService: BankTransactionsService,
    @Inject(forwardRef(() => BankTransactionFilterService))
    private bankTransactionListFilterService: IBankTransactionListFilterService,
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private queryParamsService: QueryParamsService) {
    this.appBarActionsService.actions = [];
    this._unsubscribeAll = new Subject();
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.route.queryParams.subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.activeFilters = this.bankTransactionListFilterService.addFilterParameters(this.queryParams);
      })
    ];

    this.accountType = this.data.bankAccount ? EnumHelper.getNameFromValue(BankAccountType, this.data.bankAccount.accountType) : null;

    this.appBarTitleService.title = this.data.bankAccount
      ? (this.data.bankAccount.bankName ? this.data.bankAccount.bankName : '')
      + ' - ' + (this.accountType ? this.accountType : '') + ' '
      + BankAccountHelper.sanitizeBankAccountNumber(this.data.bankAccount.accountNumber)
      : 'Add new bank account';

    this.filters = new BankTransactionFilters(this.queryParams);
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.bankTransactionListFilterService
        .filter(this.filters,
          this.data.bankAccount ? this.data.bankAccount.id : null,
          this.entityId,
          this.data.account ? this.data.account.id : null,
          this.data.application ? this.data.application.id : null,
          this.paginator,
          this.sort);

      this.afterFiltering();
    });
  }

  ngOnDestroy() {
    ObservableHelper.unsubscribeAll(this.subs);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  get entityId() {
    if (this.data.entity)
      return this.data.entity.id;
    else if (this.data.account)
      return this.data.account.entityId;
    else if (this.data.application)
      return this.data.application.entityId;
  }

  filterDialog() {
    BankTransactionListFilterDialogComponent.show(this.dialog, this.queryParams, this.bankTransactionListFilterService)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.bankTransactionListFilterService
            .filter(this.filters,
              this.data.bankAccount ? this.data.bankAccount.id : null,
              this.entityId,
              this.data.account ? this.data.account.id : null,
              this.data.application ? this.data.application.id : null,
              this.paginator,
              this.sort);

          this.afterFiltering();
        }
      });
  }

  afterFiltering() {
  }

  afterSorting() {
  }

  afterPaging() {
  }

  dataInit(data: BankTransactionListComponentData) {
    this.data = data;
  }

  sortData(sort: Sort) {
    this.bankTransactionListFilterService
      .filter(this.filters,
        this.data.bankAccount ? this.data.bankAccount.id : null,
        this.entityId,
        this.data.account ? this.data.account.id : null,
        this.data.application ? this.data.application.id : null,
        this.paginator,
        this.sort);

    this.afterSorting();
  }

  setPage(event: PageEvent) {
    this.bankTransactionListFilterService
      .filter(this.filters,
        this.data.bankAccount ? this.data.bankAccount.id : null,
        this.entityId,
        this.data.account ? this.data.account.id : null,
        this.data.application ? this.data.application.id : null,
        this.paginator,
        this.sort);

    this.afterPaging();
  }

  download() {
    this.bankTransactionsService.downloadBankTransactions(this.entityId, this.data.bankAccount ? this.data.bankAccount.id : null, this.queryParams);
  }

  tableColumns: string[] = [
    'transactionDate',
    'postedDate',
    'amount',
    'status',
    'type',
    'description',
    'category',
    'memo',
    'id'
  ];
}

export interface BankTransactionListComponentData {
  bankTransactionQueryResult: BankTransactionQueryResult;
  account: AccountData;
  entity: EntityData;
  application: ApplicationData;
  bankAccount: BankAccount;
}
