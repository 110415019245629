<mat-card class="ifc-card"
          [ngClass.xs]="'xs'">
  <div fxLayout="row"
       fxLayoutAlign="space-between start"
       class="card-header">
    <div class="card-title">Application</div>
  </div>

  <mat-card-content class="">
    <div fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap="18px">
      <mat-list class="activation-list">
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Application name</span>
          <a class="item-content"
             routerLink="/application/{{data.application?.id}}/profile">{{data?.application?.entityName}}</a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Application number</span>
          <p class="item-content">{{data?.application?.applicationNumber}}</p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span class="secondary-text"
                mat-line>Salesforce Application ID</span>
          <a class="item-content"
             *ngIf="data?.application?.uniqueId"
             href="{{data?.application?.uniqueId | salesforceUrl}}"
             target="_blank"
             rel="noopener">
            {{data?.application?.uniqueId}}
          </a>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
