<form [formGroup]="form">
  <ifc-app-card>
    <ifb-card>
      <div class="flex column">

        <mat-form-field appearance="outline">
          <mat-label>Client</mat-label>
          <input matInput formControlName="name" type="text" placeholder="Client">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>OIDC audience</mat-label>
          <input matInput formControlName="oidcAudience" type="text" placeholder="OIDC audience">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>OIDC login allowed</mat-label>
          <mat-select matInput formControlName="oidcLoginAllowed" placeholder="OIDC login allowed">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select matInput formControlName="active" placeholder="Status">
            <mat-option [value]="true">Active</mat-option>
            <mat-option [value]="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="actions flex row end-center">
          <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
        </div>
      </div>
    </ifb-card>


    <aside class="flex column">
      <ifb-card>
        <div class="client-id">
          {{form.get('id').value}}
        </div>
        <div class="client-id-label">
          Client ID
        </div>
      </ifb-card>
    </aside>

  </ifc-app-card>
</form>