<mat-card
  class="ifc-card"
  [ngClass.gt-md]="'card lg'"
  [ngClass.md]="'card md'"
  [ngClass.xs]="'xs'"
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="layout padding-md card-header"
  >
    <div class="card-title">API Keys</div>
  </div>

  <mat-card-content class="layout padding-md">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">
      <mat-list>
        <mat-list-item>
          <span class="secondary-text" mat-line> Enabled </span>
          <mat-checkbox
            [(ngModel)]="broker.apiEnabled"
            [disabled]="!canEdit"
            (change)="update($event.checked)"
            color="primary"
          ></mat-checkbox>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
