<div [@routerTransition]="'fly'">

    <div class="filter-toolbar"></div>

    <ifc-app-scroll-container [tabbed]="true">
        <div class="table-container">

            <div class="message-box info" *ngIf="!data.tenantListResult.length" fxLayout="row" fxLayoutGap="12px"
                fxLayoutAlign="start center">
                <mat-icon class="icon">info</mat-icon>
                <span class="padding-bottom-md">No data found</span>
            </div>

            <table *ngIf="data.tenantListResult.length" mat-table matSort [dataSource]="dataSource">

                <ng-container matColumnDef="overridden">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row" routerLink="/admin/tenants/{{row.id}}" class="table-cell">
                        <div  *ngIf="row.overridesFeatureFlag" class="chip mat-orange-bg">
                            <mat-icon>info_outlined</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let row" routerLink="/admin/tenants/{{row.id}}" class="table-cell"> {{row.name}} </td>
                </ng-container>

                <ng-container matColumnDef="dba">
                    <th mat-header-cell *matHeaderCellDef> DBA </th>
                    <td mat-cell *matCellDef="let row" routerLink="/admin/tenants/{{row.id}}" class="table-cell"> {{row.dba}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell *matCellDef="let row" routerLink="/admin/tenants/{{row.id}}" class="table-cell"> {{row.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let row" routerLink="/admin/tenants/{{row.id}}" class="table-cell"> {{row.infoEmail}} </td>
                </ng-container>

                <ng-container matColumnDef="url">
                    <th mat-header-cell *matHeaderCellDef> URL </th>
                    <td mat-cell *matCellDef="let row" routerLink="/admin/tenants/{{row.id}}" class="table-cell"> {{row.url}} </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Address </th>
                    <td mat-cell *matCellDef="let row" routerLink="/admin/tenants/{{row.id}}" class="table-cell">
                        {{row.headquartersAddress?.name}} {{ row.headquartersAddress | address }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tableColumns" class="table-header"></tr>
                <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
            </table>
        </div>
    </ifc-app-scroll-container>

    <div [hidden]="data.tenantListResult.length <= 10">
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
    </div>

</div>
