import { Pipe, PipeTransform } from '@angular/core';
import { AccountStatementsData } from '../../account.model';

@Pipe({
  name: 'accountStatementGenerationLabel'
})
export class AccountStatementGenerationLabelPipe implements PipeTransform {

  transform(value: AccountStatementsData): string {
    if(value.generateStatements === null || value.generateStatements === undefined)
      return 'Auto';
    else if(value.generateStatements === false)
      return "No";
    else if(value.generateStatements === true && new Date(value.generateStatementsExpirationDate) < new Date())
      return "Yes (expired)";
    else if (value.generateStatements === true)
      return "Yes";
  }
}
