<mat-card class="mat-card mat-focus-indicator ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title">Liquidity event</div>
    <button [disabled]="data.resolutionDate" mat-icon-button (click)="addLiquidityEventDialog()" aria-label="Add">
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>

  <mat-card-content class="mat-card-content layout padding-bottom-md" *ngIf="data.resolutionDate">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="layout padding-md card-header">
      <mat-list>
          <mat-list-item>
              <h4 mat-line>Resolution</h4>
              <p mat-line>{{resolutionStatusName}}, {{data?.resolutionDate | date : 'shortDate' }}</p>
            </mat-list-item>
      </mat-list>
      <button mat-icon-button aria-label="Add" (click)="delete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
