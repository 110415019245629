import { Injectable } from "@angular/core";
import { BrokerApiKeyQueryParams, BrokerApiKeyQueryResult, BrokerApiKeyData, BrokerApiKeyEditData, BrokerApiKeyRemote, CreateBrokerApiKeySuccessData, CreateBrokerApiKeyData } from "common";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BrokerApiKeysRemote {

  constructor(
    private readonly remote: BrokerApiKeyRemote
  ) {}

  query(params: BrokerApiKeyQueryParams): Observable<BrokerApiKeyQueryResult> {
    return this.remote.query(params);
  }

  get(id: number): Observable<BrokerApiKeyData> {
    return this.remote.get(id);
  }

  create(brokerApiKey: CreateBrokerApiKeyData): Observable<CreateBrokerApiKeySuccessData> {
    return this.remote.create(brokerApiKey);
  }

  update(id: number, brokerApiKey: BrokerApiKeyEditData): Observable<BrokerApiKeyData> {
    return this.remote.put(id, brokerApiKey);
  }

  delete(id: number): Observable<BrokerApiKeyData> {
    return this.remote.delete(id);
  } 
}
