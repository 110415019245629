<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>

  <form [formGroup]="form">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifc-account-loan-number-input [fc]="form.get('loanNumber')"></ifc-account-loan-number-input>
      </div>
      <div class="mat-form-field">
        <ifc-account-application-number-input [fc]="form.get('applicationNumber')">
        </ifc-account-application-number-input>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifb-business-name-input [disabled]="!data.canFilterByEntity" [fc]="form.get('businessName')" class="mat-form-field"></ifb-business-name-input>
      </div>

      <ifc-filter-param-date-range-input [fc]="form.get('createdOn')" [label]="'Created on'" (rangeSelected)="createdDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('createdOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('createdOnFrom')" [label]="'Created on from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('createdOnTo')" [label]="'Created on to'"></ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" placeholder="All">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let option of statusOptions" [value]="option.value">{{option.name | startCase}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ifc-filter-param-date-range-input [fc]="form.get('renewalOn')" [label]="'Renewal date'" (rangeSelected)="renewalDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('renewalOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('renewalOnFrom')" [label]="'Renewal on from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('renewalOnTo')" [label]="'Renewal on to'"></ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Activation stage</mat-label>
        <mat-select formControlName="activationStage" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of activationStageOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
            <mat-option>All</mat-option>
            <mat-option value="true">Active</mat-option>
            <mat-option value="false">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Product type</mat-label>
        <mat-select formControlName="productCode" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of productTypeOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="mat-form-field">
        <ifc-account-case-name-input [fc]="form.get('description')">
        </ifc-account-case-name-input>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Brand</mat-label>
        <mat-select formControlName="companyId" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Loan modification</mat-label>
        <mat-select matInput multiple formControlName="modificationFlags" placeholder="Loan modification">
          <mat-option *ngFor="let option of modificationFlagOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
        <button type="button" *ngIf="form.get('modificationFlags').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.get('modificationFlags').setValue(null)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Balance from</mat-label>
        <input matInput type="text" currencyMask formControlName="minBalance">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Balance to</mat-label>
        <input matInput type="text" currencyMask formControlName="maxBalance">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Payment amount from</mat-label>
        <input matInput type="text" currencyMask formControlName="minPaymentAmount">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Payment amount to</mat-label>
        <input matInput type="text" currencyMask formControlName="maxPaymentAmount">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Payment frequency</mat-label>
        <mat-select formControlName="paymentFrequency" placeholder="All">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let option of paymentFrequencyOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <ifc-filter-param-date-range-input [fc]="form.get('upcomingPaymentDateOn')" [label]="'Upcoming payment on'"
        (rangeSelected)="upcomingPaymentDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px" *ngIf="form.get('upcomingPaymentDateOn').value === 'custom'">
      <ifc-datepicker [fc]="form.get('minUpcomingPaymentDate')" [label]="'Upcoming payment on from'"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('maxUpcomingPaymentDate')" [label]="'Upcoming payment on to'"></ifc-datepicker>
    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>