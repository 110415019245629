<div id="main"
     fxLayout="column"
     fxLayoutAlign="start start">

  <nav mat-tab-nav-bar>
    <a class="tab-link"
       *ngFor="let link of navLinks"
       mat-tab-link
       #rla="routerLinkActive"
       [routerLink]="link.path"
       [queryParams]="link.query"
       routerLinkActive="active">
      {{link.label}}
    </a>
  </nav>

  <ifc-app-scroll-container>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </ifc-app-scroll-container>

</div>
