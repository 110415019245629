<ifc-app-scroll-container>
  <form [formGroup]="renewalsForm">
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <div class="renewal-loan-field">
        <ifc-account-loan-number-input
          [fc]="renewalsForm.get('renewalForLoanNumber')"
          [errors]="formErrorData"
          label="Renewal for loan">
        </ifc-account-loan-number-input>
      </div>
    </div>

    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutGap="24px">
      <mat-form-field appearance="outline"
                      ngClass.xs="form-field-xs"
                      ngClass.gt-xs="form-field-md">
        <mat-label>Renewal ready</mat-label>
        <mat-select matInput
                    formControlName="renewalReady"
                    placeholder="Renewal ready">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</ifc-app-scroll-container>
<div class="actions">
  <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
</div>
