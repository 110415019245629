import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppSidenavComponent,
  AppBarExpandedTabsComponent,
  AppBarActionsComponent
} from 'common';
import { ClientListComponent } from './client-list/client-list.component';
import { UserMenuComponent } from '../../user/user-menu/user-menu.component';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../../app.data';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientQueryResolver, ClientResolver } from './client.resolver';
import { AppPageResolver, AppSidenavResolver } from '../../app.resolver';
import { AdminAppBarExpandedTabsResolver } from '../../admin/admin.resolver';
import { BOAuthGuard } from 'projects/backoffice/src/app/guards/oauth.guard';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Admin',
  sideNavItem: 'admin'
});
const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
    {
      path: 'admin/client',
      component: AppPageComponent,
      data: appPageDataMaster,
      resolve: {
        appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
        appPage: AppPageResolver,
        appSidenav: AppSidenavResolver
      },
      children: [
        {
          path: '',
          outlet: 'sidenav-content',
          component: AppSidenavComponent
        },
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: UserMenuComponent,
            },
            {
              path: '',
              outlet: 'expanded',
              component: AppBarExpandedTabsComponent
            }
          ]
        },
        {
          path: '',
          component: ClientListComponent,
          runGuardsAndResolvers: "paramsOrQueryParamsChange",
          resolve: {
            clientQueryResult: ClientQueryResolver
          }
        }
      ]
    },
    {
      path: 'admin/client/:id',
      component: AppPageComponent,
      data: appPageDataDetail,
      children: [
        {
          path: '',
          component: AppBarComponent,
          outlet: 'app-bar',
          children: [
            {
              path: '',
              outlet: 'title',
              component: AppBarTitleComponent,
            },
            {
              path: '',
              outlet: 'right',
              component: AppBarActionsComponent,
            }
          ]
        },
        {
          path: '',
          component: ClientDetailsComponent,
          resolve: {
            client: ClientResolver
          }
        }
      ]
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
