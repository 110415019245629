<mat-card class="ifc-card" [ngClass.gt-md]="'card lg'" [ngClass.md]="'card md'" [ngClass.xs]="'xs'">

    <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
        <div class="card-title"> Broker Activity </div>
    </div>

    <mat-card-content class="layout padding-md">
    
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">
        
        <mat-list>

            <mat-list-item>
                <span class="secondary-text" mat-line> Total users </span>
                <p class="item-content">{{broker?.usersCount}}</p>
            </mat-list-item>

            <mat-divider></mat-divider>
        
            <mat-list-item>
                <span class="secondary-text" mat-line> Total submissions </span>
                <p class="item-content">{{broker?.applicationsCountTotal}}</p>
            </mat-list-item>

            <mat-divider></mat-divider>
        
            <mat-list-item>
                <span class="secondary-text" mat-line> Submissions last 7 days </span>
                <p class="item-content">{{broker?.applicationsCountLast7Days}}</p>
            </mat-list-item>

            <mat-divider></mat-divider>
        
            <mat-list-item>
                <span class="secondary-text" mat-line> Submissions last 30 days </span>
                <p class="item-content">{{broker?.applicationsCountLast30Days}}</p>
            </mat-list-item>
        
        </mat-list>
    
        </div>
    
    </mat-card-content>
  
</mat-card>