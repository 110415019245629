<div [@routerTransition]="'fly'">

  <div class="filter-toolbar"></div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">

      <div class="message-box info" *ngIf="!data.featureFlagListResult?.length" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span class="padding-bottom-md">No data found</span>
      </div>

      <table *ngIf="data.featureFlagListResult?.length" mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef> Code </th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{row.code}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{row.description}} </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef> Created on </th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{row.createdOn | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="override">
          <th mat-header-cell *matHeaderCellDef> Override </th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <mat-form-field floatLabel="never">
              <mat-label>Override</mat-label>
              <mat-select [(value)]="row.override" (selectionChange)="updateForTenant(row)">
                <mat-option [value]="true"> True </mat-option>
                <mat-option [value]="false"> False </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <mat-slide-toggle *ngIf="tenantId" color="primary" [disabled]="!row.override"
              checked="{{row.override ? row.valueForTenant : row.value}}" (change)="updateForTenant(row, $event)">
            </mat-slide-toggle>
            <mat-slide-toggle *ngIf="!tenantId" color="primary" checked="{{row.value}}"
              (change)="update($event, row.id)">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.featureFlagListResult?.length <= 10">
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
  </div>

</div>