import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { EnumHelper, ApplicationStage, ApplicationStageLabel, FilterParameter, ApplicationQueryParams, LinkingStatus, ApplicationQueryResult, EntityData } from 'common';
import { routerTransition } from '../../shared/animations/router.animations';
import { ApplicationFilters, ApplicationData } from '../application.model';
import { ApplicationListFilterDialogComponent } from '../application-list-filter-dialog/application-list-filter-dialog.component';
import { ApplicationFilterService } from '../application-filter.service';
import { BankLinkStatusLabels } from '../../bank/bank.model';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readOnly } from '../../user/user-permission/user-permission.data';
import { CustomerData } from '../../customer/customer.model';
import { ApplicationService } from '../application.service';
import { AppSettings } from '../../../app.settings';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
  animations: routerTransition,
})
export class ApplicationListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  filters: ApplicationFilters;
  queryParams: ApplicationQueryParams;
  activeFilters: FilterParameter[] = [];
  data: ApplicationListComponentData;
  canReadAccounts: boolean;
  entityId: number;
  customerId: number;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService,
    private applicationService: ApplicationService,
    private applicationFilterService: ApplicationFilterService,
    private settings: AppSettings,
    private _location: Location,
    private queryParamsService: QueryParamsService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));

    this.userPermissionService.granted([readOnly('servicing-accounts')])
      .subscribe(res => this.canReadAccounts = res);

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(params => {
        this.queryParams = new ApplicationQueryParams(this.queryParamsService.init(params));
        this.activeFilters = this.applicationFilterService.addFilterParameters(this.queryParams);
      });

    this.filters = new ApplicationFilters(this.queryParams);

    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  private dataInit(data: ApplicationListComponentData) {
    this.data = data;
    this.entityId = this.data.entity ? this.data.entity.id : null;
    this.customerId = this.data.customer ? this.data.customer.id : null;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.applicationFilterService.filter(this.filters, null, null, this.entityId, this.customerId);
    });
  }

  filterDialog() {
    ApplicationListFilterDialogComponent.show(this.dialog, this.queryParams, this.entityId ? false : true)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.filter();
        }
      });
  }

  getBankLinkingStatusAvatarIcon(application: ApplicationData) {
    if (application && application.bankLinkStatus !== null && application.bankLinkStatus !== undefined)
      return BankLinkStatusLabels[application.bankLinkStatus].icon;
    else return BankLinkStatusLabels[LinkingStatus.Unlinked].icon;
  }

  getBankLinkingStatusAvatarClass(application: ApplicationData) {
    if (application && application.bankLinkStatus !== null && application.bankLinkStatus !== undefined)
      return 'avatar medium ' + BankLinkStatusLabels[application.bankLinkStatus].color;
    else return 'avatar medium ' + BankLinkStatusLabels[LinkingStatus.Unlinked].color;
  }

  getBankLinkingStatusTooltip(application: ApplicationData) {
    return !application || !application.bankLinkStatus
      ? _.startCase(EnumHelper.getNameFromValue(LinkingStatus, LinkingStatus.Unlinked))
      : _.startCase(EnumHelper.getNameFromValue(LinkingStatus, +application.bankLinkStatus));
  }

  formatStage(stage: ApplicationStage): string {
    return ApplicationStageLabel.get(stage);
  }

  sortData(sort: Sort) {
    this.filter();
  }

  setPage(event: PageEvent) {
    this.filter();
  }

  filter() {
    this.applicationFilterService.filter(this.filters, this.paginator, this.sort, this.entityId, this.customerId);
  }

  salesforceLink(salesforceId: string) {
    return this.settings.salesforce.url + salesforceId;
  }

  detailsLink(id: string, newWindow: boolean) {
    this.applicationService.prevApplicationListUrl = this._location.path().toString();
    const path = `/application/${id}/profile`;
    if (newWindow)
      window.open(path);
    else
      this.router.navigate([path]);
  }

  accountDetailsLink(id: string) {
    return `/account/${id}/profile`;
  }

  getDisplayedColumns(): string[] {
    return this.tableColumns
      .filter(cd => this.data.entity && this.data.entity.id ? cd.showInCompanyTab : true)
      .map(cd => cd.def);
  }

  tableColumns = [
    { def: 'bankLinkStatus', showInCompanyTab: true },
    { def: 'createdOn', showInCompanyTab: true },
    { def: 'businessName', showInCompanyTab: false },
    { def: 'sfAppStatus', showInCompanyTab: true },
    { def: 'stage', showInCompanyTab: true },
    { def: 'type', showInCompanyTab: true },
    { def: 'applicationType', showInCompanyTab: true },
    { def: 'applicationNumber', showInCompanyTab: true },
    { def: 'loanNumber', showInCompanyTab: true },
    { def: 'createdBy', showInCompanyTab: true },
    { def: 'updatedOn', showInCompanyTab: true },
    { def: 'updatedBy', showInCompanyTab: true },
    { def: 'salesforceId', showInCompanyTab: true },
    { def: 'edit', showInCompanyTab: true }
  ];

}

export interface ApplicationListComponentData {
  entity?: EntityData;
  customer?: CustomerData;
  applicationQueryResult: ApplicationQueryResult;
}
