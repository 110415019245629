<mat-dialog-content class="mat-typography">
  <h3>Set filter</h3>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <ifc-datepicker [fc]="form.get('transactionDateFrom')" [label]="'Transaction date from'"
        [max]="form.get('transactionDateTo').value || today"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('transactionDateTo')" [label]="'Transaction date to'"
        [min]="form.get('transactionDateFrom').value || null"></ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <ifc-datepicker [fc]="form.get('postedDateFrom')" [label]="'Transaction posted date from'"
        [max]="form.get('postedDateTo').value || today"></ifc-datepicker>
      <ifc-datepicker [fc]="form.get('postedDateTo')" [label]="'Transaction posted date to'"
        [min]="form.get('postedDateFrom').value || null"></ifc-datepicker>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Amount from</mat-label>
        <input matInput formControlName="fromAmount" type="text" placeholder="Amount from" currencyMask [options]="{ allowNegative: true }">
        <button type="button" *ngIf="form.get('fromAmount').value" mat-button matSuffix mat-icon-button
          aria-label="Clear" (click)="form.get('fromAmount').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="" fxFlex="50">
        <mat-label>Amount to</mat-label>
        <input matInput formControlName="toAmount" type="text" placeholder="Amount to" currencyMask [options]="{ allowNegative: true }">
        <button type="button" *ngIf="form.get('toAmount').value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="form.get('toAmount').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" placeholder="All">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let option of bankTransationStatusOptions" [value]="option.value">
            {{option.name | startCase}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" placeholder="All">
          <mat-option>All</mat-option>
          <mat-option *ngFor="let option of bankTransationTypeOptions" [value]="option.value">
            {{option.name | startCase}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <input matInput formControlName="category" type="text" placeholder="Category">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput formControlName="active" placeholder="Status">
            <mat-option>All</mat-option>
            <mat-option value="true">Active</mat-option>
            <mat-option value="false">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
      
    </div>

  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="filters" cdkFocusInitial color="primary">Ok</button>
</div>