import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifb-account-alligator-data-icon-chip',
  templateUrl: './account-alligator-data-icon-chip.component.html',
  styleUrls: ['./account-alligator-data-icon-chip.component.scss']
})
export class AccountAlligatorDataIconChipComponent {
  
  @Input()
  alligatorDataSync = false;

  alligatorDataSyncLabels = {
    'true': { color: 'mat-green-bg', icon: 'check_circle', label: 'Match' },
    'false': { color: 'mat-red-bg', icon: 'sync_problem', label: 'Mismatch' },
  };
}
