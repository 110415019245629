import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Observable } from "rxjs";
import { CompetitorsFilters } from "../../../../models/competitors-filter.model";
import { CompetitorListQueryParams } from "../../../../models/competitors-query.model";
import { CompetitorListData } from "../../models/competitor-list-data.model";
import { getFormModel } from "./competitor-list-filter-dialog-form-data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifb-competitor-list-filter-dialog",
  templateUrl: "./competitor-list-filter-dialog.component.html",
  styleUrls: ["./competitor-list-filter-dialog.component.scss"],
})
export class CompetitorListFilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  filters: CompetitorsFilters;

  constructor(
    public dialogRef: MatDialogRef<CompetitorListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    this.filters = new CompetitorsFilters(data.query);
    this.form = this.formBuilder.group(getFormModel(data.query));
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.filters = new CompetitorsFilters(data);
      });
  }

  static show(
    dialog: MatDialog,
    query: CompetitorListQueryParams,
    routeData: CompetitorListData
  ): Observable<any> {
    return dialog
      .open(CompetitorListFilterDialogComponent, {
        data: {
          query,
          routeData,
        },
        closeOnNavigation: true,
      })
      .afterClosed();
  }
}
