import { AfterViewInit, Directive, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  AppBarTitleService,
  AppBarActionsService,
  AppBarAction,
  ConfigService,
  OAuthService,
  DocumentCategory,
  EnumHelper,
  DocumentCategoryLabel,
  AppPageService,
} from "common";
import { UserPermissionService } from "../../user/user-permission/user-permission.service";
import {
  UserPermissionId,
  readWrite,
} from "../../user/user-permission/user-permission.data";
import { takeUntil } from "rxjs/operators";
import { Uppy } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import {
  getUppyDashboardOptions,
  getUppyInstance,
  updateUppyEndpoint,
} from "./document-upload-uppy-config";

@Directive()
export abstract class DocumentsUploadBaseComponent
  implements OnDestroy, AfterViewInit
{
  canUpload: boolean = false;
  valid: boolean = false;
  uppyConfig: any;
  categories = EnumHelper.getMappedNamesAndValues(
    DocumentCategory,
    DocumentCategoryLabel
  );
  selectedCategory = DocumentCategory.Other;

  uppy: Uppy;
  uppyDashboardOptions: DashboardOptions;

  protected _unsubscribeAll: Subject<any> = new Subject();
  private submittingValue: boolean = false;

  constructor(
    protected route: ActivatedRoute,
    protected appBarTitleService: AppBarTitleService,
    protected appBarActionsService: AppBarActionsService,
    protected appPageService: AppPageService,
    protected userPermissionService: UserPermissionService,
    protected configService: ConfigService,
    protected authService: OAuthService
  ) {
    this.appBarActionsService.actions = [
      { id: "cancel", label: "Cancel", buttonType: "button" },
      {
        id: "submit",
        label: "Submit",
        disabled: this.valid,
        buttonType: "submit",
        buttonAppearance: "flat",
        buttonColor: "primary",
      },
    ];

    this.appBarTitleService.title = "Upload files";
  }

  protected set submitting(value: boolean) {
    if (this.submittingValue !== value) {
      this.submittingValue = value;
      this.appBarRefresh();
    }
  }

  protected get submitting(): boolean {
    return this.submittingValue;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.uppy.on("file-added", () => {
      this.appBarRefresh();
    });
    this.uppy.on("file-removed", () => {
      this.appBarRefresh();
    });
    this.uppy.on("complete", () => {
      this.submitting = false;
    });
  }

  protected ngOnInitBase(userPermissionId: UserPermissionId) {
    this.appBarActionsService.invoking
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.actionDispatch.bind(this));

    this.userPermissionService
      .granted([readWrite(userPermissionId)])
      .subscribe((res) => {
        this.canUpload = res;
        this.appBarRefresh();
      });
  }

  onCategoryChange(): void {
    this.updateUppyEndpoint();
    this.appBarRefresh();
  }

  cancel(): void {
    this.appPageService.back();
  }

  submit(): void {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.uppy.getState()?.error ? this.uppy.retryAll() : this.uppy.upload();
  }

  protected initUppy(endpoint: string): void {
    this.uppy = getUppyInstance(endpoint, this.authService.accessToken);
    this.uppyDashboardOptions = getUppyDashboardOptions();
  }

  protected abstract setupUppy();
  protected abstract updateUppyEndpoint();

  protected updateUppyEndpointBase(endpoint: string) {
    updateUppyEndpoint(this.uppy, endpoint);
  }

  protected actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  protected appBarRefresh(): void {
    const files = this.uppy?.getFiles();
    this.valid =
      this.canUpload &&
      this.selectedCategory &&
      files != null &&
      files.some((f) => !f.progress.uploadComplete) &&
      !this.submitting;
    this.appBarActionsService.enable("submit", this.valid);
  }
}
