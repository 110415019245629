import { Component } from '@angular/core';

@Component({
  selector: 'ifb-log-app-bar-title',
  templateUrl: './log-app-bar-title.component.html',
  styleUrls: ['./log-app-bar-title.component.scss']
})
export class LogAppBarTitleComponent {

  constructor() { }

}
