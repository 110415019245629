import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppPageComponent,
  AppSidenavComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppBarActionsComponent,
  AppBarExpandedTabsComponent
} from 'common';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../../app.data';
import { UserMenuComponent } from '../../user/user-menu/user-menu.component';
import { ApiKeyListComponent } from './api-key-list/api-key-list.component';
import { ApiKeyDetailsComponent } from './api-key-details/api-key-details.component';
import { ApiKeyQueryResolver, ApiKeyResolver } from './api-key.resolver';
import { AppPageResolver, AppSidenavResolver } from '../../app.resolver';
import { ClientAllResolver, ClientActiveResolver } from '../../auth/client/client.resolver';
import { AdminAppBarExpandedTabsResolver } from '../admin.resolver';
import { BOAuthGuard } from '../../guards/oauth.guard';

const appPageDataMaster = appPageComponentDataMaster({
  appBarTitle: 'Admin',
  sideNavItem: 'admin'
});
const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: '',
    canActivate: [BOAuthGuard],
    children: [
      {
        path: 'admin/apikey',
        component: AppPageComponent,
        data: appPageDataMaster,
        resolve: {
          appBarExpandedTabs: AdminAppBarExpandedTabsResolver,
          appPage: AppPageResolver,
          appSidenav: AppSidenavResolver
        },
        children: [
          {
            path: '',
            outlet: 'sidenav-content',
            component: AppSidenavComponent
          },
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: UserMenuComponent,
              },
              {
                path: '',
                outlet: 'expanded',
                component: AppBarExpandedTabsComponent
              }
            ]
          },
          {
            path: '',
            component: ApiKeyListComponent,
            runGuardsAndResolvers: "paramsOrQueryParamsChange",
            resolve: {
              apiKeyQueryResult: ApiKeyQueryResolver,
              clients: ClientAllResolver
            }
          }
        ]
      },
      {
        path: 'admin/apikey/:id',
        component: AppPageComponent,
        data: appPageDataDetail,
        children: [
          {
            path: '',
            component: AppBarComponent,
            outlet: 'app-bar',
            children: [
              {
                path: '',
                outlet: 'title',
                component: AppBarTitleComponent,
              },
              {
                path: '',
                outlet: 'right',
                component: AppBarActionsComponent,
              }
            ]
          },
          {
            path: '',
            component: ApiKeyDetailsComponent,
            resolve: {
              apiKey: ApiKeyResolver,
              clients: ClientActiveResolver
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApiKeyRoutingModule { }
