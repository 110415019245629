<h1 mat-dialog-title>Create broker?</h1>

<mat-dialog-content>
  <div class="content" fxLayout="row wrap" fxFlex="100">
    <div *ngIf="!data.form.controls.name.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.name}}</div>
      <div class="mat-caption"> Legal entity name </div>
    </div>
    <div *ngIf="!data.form.controls.dba.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.dba}}</div>
      <div class="mat-caption"> DBA </div>
    </div>
    <div *ngIf="!data.form.controls.ein.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.ein}}</div>
      <div class="mat-caption"> Tax ID </div>
    </div>
    <div *ngIf="!data.form.controls.brokerId.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.brokerId}}</div>
      <div class="mat-caption"> Broker ID </div>
    </div>
    <div *ngIf="!data.form.controls.tier.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{formatTier(data.form.value.tier)}}</div>
      <div class="mat-caption"> Tier </div>
    </div>
    <div *ngIf="!data.form.controls.type.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{formatType(data.form.value.type)}}</div>
      <div class="mat-caption"> Type </div>
    </div>
    <div *ngIf="!data.form.controls.phone.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.phone}}</div>
      <div class="mat-caption"> Phone </div>
    </div>
    <div *ngIf="!data.form.controls.contactEmail.pristine" fxLayout="column"
      fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div class="overflow-hidden">{{data.form.value.contactEmail}}</div>
      <div class="mat-caption"> Contact email </div>
    </div>
    <div *ngIf="!data.form.controls.commissionEmail.pristine" fxLayout="column"
      fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div class="overflow-hidden">{{data.form.value.commissionEmail}}</div>
      <div class="mat-caption"> Commission email </div>
    </div>
    <div *ngIf="!data.form.controls.website.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div class="overflow-hidden">{{data.form.value.website}}</div>
      <div class="mat-caption"> Website </div>
    </div>
    <div *ngIf="!data.form.controls.line1.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.line1}}</div>
      <div class="mat-caption"> Address </div>
    </div>
    <div *ngIf="!data.form.controls.line2.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.line2}}</div>
      <div class="mat-caption"> Unit/Suite/Apt # </div>
    </div>
    <div *ngIf="!data.form.controls.city.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.city}}</div>
      <div class="mat-caption"> City </div>
    </div>
    <div *ngIf="!data.form.controls.state.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.state}}</div>
      <div class="mat-caption"> State </div>
    </div>
    <div *ngIf="!data.form.controls.zip.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.zip}}</div>
      <div class="mat-caption"> Zip code </div>
    </div>
    <div *ngIf="!data.form.controls.status.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{formatStatus(data.form.value.status)}}</div>
      <div class="mat-caption"> Status </div>
    </div>
    <div *ngIf="!data.form.controls.active.pristine" fxLayout="column" fxFlex="100"
      fxFlex.gt-sm="50" class="layout padding-vertical-md">
      <div>{{data.form.value.active ? 'Active' : 'Inactive'}}</div>
      <div class="mat-caption"> Active </div>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</div>