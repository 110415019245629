import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPageComponent, AppBarComponent, AppBarTitleComponent, AppBarActionsComponent } from 'common';
import { appPageComponentDataDetail } from '../../app.data';
import { AuditTrailDetailComponent } from './audit-trail-detail/audit-trail-detail.component';
import { AuditTrailResolver } from './audit-trail.resolver';
import { BOAuthGuard } from '../../guards/oauth.guard';

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
  {
    path: 'audit/:id',
    component: AppPageComponent,
    canActivate: [BOAuthGuard],
    data: appPageDataDetail,
    children: [
      {
        path: '',
        component: AppBarComponent,
        outlet: 'app-bar',
        children: [
          {
            path: '',
            outlet: 'title',
            component: AppBarTitleComponent,
          },
          {
            path: '',
            outlet: 'right',
            component: AppBarActionsComponent,
          }
        ]
      },
      {
        path: '',
        component: AuditTrailDetailComponent,
        resolve: {
          auditTrail: AuditTrailResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditTrailRoutingModule { }
