<mat-card class="ifc-card" [ngClass.xs]="'xs'">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md">
    <div class="card-title">Owners</div>
  </div>

  <mat-card-content class="layout padding-bottom-md">
    <mat-accordion>

      <mat-expansion-panel class="mat-elevation-z0" *ngFor="let owner of owners; index as i">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <div fxLayout="row">
              <div class="index caption">
                {{i+1}}
              </div>
              <div fxLayout="column">
                <div (click)="$event.stopPropagation()">
                  <a [routerLink]="customerDetailsUri(owner)">
                    {{owner.firstName}} {{owner.lastName}}
                  </a>
                </div>
                <div (click)="$event.stopPropagation()">
                  <a class="caption" href="mailto:{{owner.email}}">{{owner.email}}</a>
                </div>
                <div class="mat-caption">KBA {{kbaStatus[owner.kba.status]}}</div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="column" class="expanded-list" fxLayoutGap="34px">
          <div fxLayout="column" class="expanded-list-item">
            <div *ngIf="owner.uniqueId">
              <a href="{{salesforceLink(owner.uniqueId)}}" target="_blank">{{owner.uniqueId}}</a>
            </div>
            <div *ngIf="!owner.uniqueId">-</div>
            <div class="mat-caption">Salesforce Owner ID</div>
          </div>

          <div fxLayout="column">
            <div>{{owner.ssn || '-'}}</div>
            <div class="mat-caption">SSN</div>
          </div>

          <div fxLayout="column">
            <div>{{(owner.updatedOn | date) || '-'}}</div>
            <div class="mat-caption">Updated on</div>
          </div>

          <div fxLayout="column">
            <div>{{(owner.createdOn | date) || '-'}}</div>
            <div class="mat-caption">Created on</div>
          </div>

        </div>

      </mat-expansion-panel>

    </mat-accordion>
  </mat-card-content>
</mat-card>