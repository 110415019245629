import { Component, Input } from '@angular/core';
import { LineChartOptions } from '../charts.model';

@Component({
  selector: 'ifb-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent {

  @Input()
  options: LineChartOptions;

  @Input()
  view: string[];

  @Input()
  data: any;

  constructor() { }

}
