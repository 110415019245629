<div class="dd-container" ngClass.xl="xl" ngClass.lg="xl" ngClass.xs="sm">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Signed by</mat-label>
                <input matInput placeholder="Signed by" [value]="signature?.signer?.fullName" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Date and time signed</mat-label>
                <input matInput placeholder="Date and time signed" [value]="signature?.signedAtUtc  | date: 'medium'"
                    disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Signer IP address</mat-label>
                <input matInput placeholder="Signer IP address" [value]="signature?.ip" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Geolocation resolution status</mat-label>
                <input matInput placeholder="Geolocation resolution status" [value]="signature?.status | titlecase"
                    disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Geolocation resolution date and time</mat-label>
                <input matInput placeholder="Geolocation resolution date and time"
                    [value]="signature?.resolvedAt  | date: 'medium'" disabled />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" [value]="signature?.signer?.email" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline" style="visibility: hidden;" [fxShow.xs]="false">
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Signer resolved IP address</mat-label>
                <input matInput placeholder="Signer resolved IP address" [value]="signature?.resolvedIP" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Resolution error</mat-label>
                <input matInput placeholder="Resolution error" [value]="signature?.error" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Resolution method</mat-label>
                <input matInput placeholder="Resolution method" [value]="signature?.method | titlecase" disabled />
            </mat-form-field>
        </div>
    </div>
    <div class="sub-section">Geolocation</div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
        <div class="column">
            <mat-form-field appearance="outline">
                <mat-label>Latitude, Longitude</mat-label>
                <input matInput placeholder="Latitude, Longitude" [value]="getLatLong()" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Time Zone</mat-label>
                <input matInput placeholder="Time zone" [value]="getTimeZone()" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Document hash</mat-label>
                <input matInput placeholder="Document hash" [value]="signature?.documentHash" disabled />
            </mat-form-field>
        </div>
        <div class="column">
            <mat-form-field appearance="outline">
                <mat-label>City, State, Zip, Country</mat-label>
                <input matInput placeholder="City, State, Zip, Country" [value]="getAddress()" disabled />
            </mat-form-field>
            <mat-form-field appearance="outline" style="visibility: hidden;" [fxShow.xs]="false">
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Signature Status</mat-label>
                <input matInput placeholder="Signature Status"
                    [value]="signature?.signatureVerificationStatus  | titlecase" disabled />
            </mat-form-field>
        </div>
    </div>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>HostName / ASN / Organization</mat-label>
            <input matInput placeholder="HostName / ASN / Organization" [value]="getHostAsnOrg()" disabled />
        </mat-form-field>
    </div>
</div>