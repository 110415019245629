<h1 mat-dialog-title>Update transaction?</h1>
<mat-dialog-content>
  <p class="secondary-text sub-title">
    You are updating a transaction for {{data.form.value.loanNumber?.loanNumber}}:
  </p>

  <div *ngIf="!data.status" class="content">
    <div fxLayout="row wrap" fxFlex="100">
      <div *ngIf="!data.form.controls.loanNumber.pristine || !data.form.controls.entityName.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.loanNumber?.loanNumber}}</div>
        <div class="mat-caption">Loan Number</div>
      </div>
      <div *ngIf="!data.form.controls.entityName.pristine || !data.form.controls.loanNumber.pristine" fxLayout="column"
        fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.entityName?.businessName}}</div>
        <div class="mat-caption">Legal entity name</div>
      </div>
      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{type}}</div>
        <div class="mat-caption">Type</div>
      </div>
      <div *ngIf="!data.form.controls.amount.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.amount | currency}}</div>
        <div class="mat-caption">Amount</div>
      </div>
      <div *ngIf="!data.form.controls.status.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{status}}</div>
        <div class="mat-caption">Status</div>
      </div>
      <div *ngIf="!data.form.controls.competitors.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{competitors}}</div>
        <div class="mat-caption">Competitor</div>
      </div>
      <div *ngIf="!data.form.controls.date.pristine || !data.form.controls.time.pristine || data.transactionNew" fxLayout="column" fxFlex="100"
        fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{data.form.value.date | date: 'short'}}</div>
        <div class="mat-caption">Transaction date</div>
      </div>
      <div *ngIf="!data.form.controls.underwritingDate.pristine || !data.form.controls.underwritingTime.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data.form.value.underwritingDate | date: 'short') || 'null'}}</div>
        <div class="mat-caption">UW date</div>
      </div>
      <div *ngIf="!data.form.controls.financeDate.pristine || !data.form.controls.financeTime.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data.form.value.financeDate | date: 'short') || 'null'}}</div>
        <div class="mat-caption">Finance date</div>
      </div>
      <div *ngIf="!data.form.controls.processDate.pristine || !data.form.controls.processTime.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data.form.value.processDate | date: 'short') || 'null'}}</div>
        <div class="mat-caption">Processed date</div>
      </div>
      <div *ngIf="!data.form.controls.completeDate.pristine || !data.form.controls.completeTime.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data.form.value.completeDate | date: 'short') || 'null'}}</div>
        <div class="mat-caption">Complete date</div>
      </div>
      <div *ngIf="!data.form.controls.cancellationDate.pristine || !data.form.controls.cancellationTime.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div>{{(data.form.value.cancellationDate | date: 'short') || 'null'}}</div>
        <div class="mat-caption">Cancellation date</div>
      </div>
      <div *ngIf="!data.form.controls.subtransactions.pristine" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="layout padding-vertical-md">
        <div class="mat-caption margin-bottom-5">Subtransactions:</div>
        <div *ngFor="let subtransaction of data.transaction.subtransactions">
          {{subtransaction.amount | currency}} - {{getSubtransactionTypeName(subtransaction.type)}}
        </div>
      </div>
      <div *ngIf="!data.form.controls.note.pristine" fxLayout="column" fxFlex="100" class="layout padding-vertical-md">
        <div class="mat-caption margin-bottom-5">Notes:</div>
        <div class="notes-list" *ngFor="let note of data.notes">
          <div>
            <span class="note-content">{{note.text}}</span> <br />
            <span class="mat-caption">{{note.date | date: 'medium'}}</span>
            <br /><br />
          </div>
        </div>
      </div>
      <div *ngIf="!data.form.controls.promotion.pristine" fxLayout="column" class="layout padding-vertical-md">
        <div>{{promotions}}</div>
        <div class="mat-caption">Promotions</div>
      </div>
    </div>
  </div>

  <div *ngIf="data.status" class="content" fxLayout="column">

    <div fxLayout="column" class="layout padding-vertical-md">
      <div>{{statusUpdated}}</div>
      <div class="mat-caption">Status</div>
    </div>

    <div *ngIf="data.status === TransactionStatus.Rejected" fxLayout="column" class="layout padding-vertical-md failureReasons">
      <p class="mat-caption">To proceed, select reject reason(s).</p>
      <ifb-transaction-reject-input [fc]="data.form.get('reasons')" [usage]="FailureReasonUsage.ResidualUnderwriting"></ifb-transaction-reject-input>
    </div>

    <div *ngIf="data.status === TransactionStatus.Declined" fxLayout="column" class="layout padding-vertical-md failureReasons">
      <p class="mat-caption">To proceed, select reject reason(s).</p>
      <ifb-transaction-reject-input [fc]="data.form.get('reasons')" [usage]="FailureReasonUsage.NACHAResponse"></ifb-transaction-reject-input>
    </div>

    <div *ngIf="!data.form.controls.note.pristine" fxLayout="column" class="layout padding-vertical-md">
      <div class="mat-caption margin-bottom-5">Notes:</div>
      <div class="notes-list" *ngFor="let note of data.notes">
        <div>
          <span class="note-content">{{note.text}}</span> <br />
          <span class="mat-caption">{{note.date | date: 'medium'}}</span>
          <br /><br />
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="save()">Update</button>
</div>
