import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { formatCurrency } from "@angular/common";
import moment from "moment";
import { EnumHelper, MatTableHelper, FilterParameter } from "common";
import { IBankTransactionListFilterService } from "../../bank/bank-account-transaction-list-filter.service";
import { BankTransactionFilters, BankTransactionQueryParams, BankTransactionStatus, BankTransactionType } from "../../bank/bank.model";

@Injectable({
    providedIn: 'root',
})
export class TransactionBankTransactionListFilterService implements IBankTransactionListFilterService {
    private _filters: BankTransactionFilters = new BankTransactionFilters();
    private _rest: {
        skip: any;
        limit: any;
        sort: any;
    } = {
            skip: undefined,
            limit: 20,
            sort: undefined
        };

    getFilters(params: Params) {
        return Object.assign({}, this._filters, this._rest);
    }

    getBankTransactionFilters(query: any): BankTransactionFilters {
        return this._filters;
    }

    filter(
        filters: BankTransactionFilters,
        bankAccountId: number,
        companyId: number,
        accountId?: number,
        applicationId?: number,
        paginator?: MatPaginator,
        sort?: MatSort) {

        this._filters = filters;


        this._rest.skip = undefined;

        if (paginator) {
            this._rest.limit = paginator.pageSize;
            this._rest.skip = paginator.pageIndex * paginator.pageSize;
        }

        if (sort && sort.active) {
            this._rest.sort = MatTableHelper.getSort(this._rest.sort, sort);
        }

    }

    addFilterParameters(query: BankTransactionQueryParams): FilterParameter[] {
        this._filters = new BankTransactionFilters();
        this._filters.transactionDateFrom = query.transactionDateFrom;
        this._filters.transactionDateTo = query.transactionDateTo;
        this._filters.fromAmount = query.fromAmount;
        this._filters.toAmount = query.toAmount;
        this._filters.postedDateFrom = query.postedDateFrom;
        this._filters.postedDateTo = query.postedDateTo;
        this._filters.postedDateTo = query.postedDateTo;
        this._filters.type = query.type;
        this._filters.category = query.category;
        this._filters.active = query.active;


        return this.getActiveFilters();
    }

    getActiveFilters(): FilterParameter[] {
        const filters: FilterParameter[] = [];

        if (this._filters.transactionDateFrom) {
            filters.push({
                id: 'transactionDateFrom',
                name: 'Transaction date from',
                value: `${moment(this._filters.transactionDateFrom).format('L')}`
            });
        }

        if (this._filters.transactionDateTo) {
            filters.push({
                id: 'transactionDateTo',
                name: 'Transaction date to',
                value: `${moment(this._filters.transactionDateTo).format('L')}`
            });
        }

        if (this._filters.fromAmount) {
            filters.push({
                id: 'fromAmount',
                name: 'From amount',
                value: formatCurrency(this._filters.fromAmount, 'en_US', '$')
            });
        }

        if (this._filters.toAmount) {
            filters.push({
                id: 'toAmount',
                name: 'To amount',
                value: formatCurrency(this._filters.toAmount, 'en_US', '$')
            });
        }

        if (this._filters.postedDateFrom) {
            filters.push({
                id: 'postedDateFrom',
                name: 'Posted date from',
                value: `${moment(this._filters.postedDateFrom).format('L')}`
            });
        }

        if (this._filters.postedDateTo) {
            filters.push({
                id: 'postedDateTo',
                name: 'Posted date to',
                value: `${moment(this._filters.postedDateTo).format('L')}`
            });
        }

        if (this._filters.status) {
            filters.push({
                id: 'status',
                name: 'Status',
                value: EnumHelper.getNameFromValue(BankTransactionStatus, this._filters.status)
            });
        }

        if (this._filters.type) {
            filters.push({
                id: 'type',
                name: 'Type',
                value: EnumHelper.getNameFromValue(BankTransactionType, this._filters.type)
            });
        }

        if (this._filters.category) {
            filters.push({
                id: 'category',
                name: 'Category',
                value: this._filters.category
            });
        }

        return filters;
    }
}
