import { Injectable } from "@angular/core";
import {
  ApiKeyQueryParams,
  ApiKeyQueryResult,
  ApiKeyData,
} from "./api-key.model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppSettings } from "../../../app.settings";

@Injectable({
  providedIn: "root",
})
export class ApiKeyService {
  constructor(private http: HttpClient, private settings: AppSettings) {}

  get(id: number): Observable<ApiKeyData> {
    return this.http.get<ApiKeyData>(
      `${this.settings.oauth.url}/api/apikey/${id}`
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(
      `${this.settings.oauth.url}/api/apikey/${id}`
    );
  }

  update(id: number, apiKey: ApiKeyData): Observable<ApiKeyData> {
    return this.http.put<ApiKeyData>(
      `${this.settings.oauth.url}/api/apikey/${id}`,
      apiKey
    );
  }

  query(params: ApiKeyQueryParams): Observable<ApiKeyQueryResult> {
    return this.http.get<ApiKeyQueryResult>(
      `${this.settings.oauth.url}/api/apikey/query`,
      { params: <any>params }
    );
  }

  create(apiKey: ApiKeyData): Observable<ApiKeyData> {
    return this.http.post<ApiKeyData>(
      `${this.settings.oauth.url}/api/apikey`,
      apiKey
    );
  }
}
