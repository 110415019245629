import { Pipe, PipeTransform } from '@angular/core';
import { AccountData } from '../../account.model';

@Pipe({
  name: 'alligatorDataTooltip'
})
export class AlligatorDataTooltipPipe implements PipeTransform {

  transform(account: AccountData): string {
    if (!account?.alligatorMatch) {
      return "Alligator data mismatch";
    }
    return "Alligator data match";
  }
}
