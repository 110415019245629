<ifc-app-scroll-container [widgetsWithTopHeader]="true">
  <form [formGroup]="form">
    <ifc-app-card>
      <ifb-card>
        <div class="flex column app-card-content">

          <mat-form-field appearance="outline">
            <mat-label>Code</mat-label>
            <input matInput formControlName="code" type="text" placeholder="Code" specialIsAlphaNumeric maxlength="8"
              [readonly]="!readWriteFailureReason">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>External code</mat-label>
            <input matInput formControlName="externalCode" type="text" placeholder="External code" specialIsAlphaNumeric
              [readonly]="!readWriteFailureReason">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" type="text" placeholder="Name" specialIsAlphaNumeric
              [readonly]="!readWriteFailureReason">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" rows="4" placeholder="Description" specialIsAlphaNumeric
              [readonly]="!readWriteFailureReason"></textarea>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Usage</mat-label>
            <mat-select matInput formControlName="usage" placeholder="Usage" multiple>
              <mat-option *ngFor="let u of usage" [value]="u.value">{{formatUsage(u.value)}}</mat-option>
            </mat-select>
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="start start" class="layout margin-bottom">
            <mat-checkbox appearance="outline" color="primary" formControlName="allowToSpecifyCompetitors">Allow to specify competitors</mat-checkbox>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select matInput formControlName="active" placeholder="Status">
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Inactive</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="flex row end-center">
            <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
          </div>
        </div>
      </ifb-card>


      <aside class="flex column app-card-aside">
        <ifb-card>
          <h2 class="mat-display-1 layout margin-bottom">Failure reason</h2>

          <div *ngIf="data.failureReason && data.failureReason.createdBy" class="layout padding-top">
            <div class="value">{{data.failureReason.createdBy}}</div>
            <p class="label">Created by</p>
          </div>

          <div *ngIf="data.failureReason && data.failureReason.createdOn" class="layout">
            <div class="value">{{data.failureReason.createdOn | date: 'medium'}}</div>
            <p class="label">Created on</p>
          </div>

          <div *ngIf="data.failureReason && data.failureReason.updatedBy" class="layout">
            <div class="value">{{data.failureReason.updatedBy}}</div>
            <p class="label">Updated by</p>
          </div>

          <div *ngIf="data.failureReason && data.failureReason.updatedOn" class="layout">
            <div class="value">{{data.failureReason.updatedOn | date: 'medium'}}</div>
            <p class="label">Updated on</p>
          </div>
        </ifb-card>

      </aside>
    </ifc-app-card>
  </form>
</ifc-app-scroll-container>
