<h1 mat-dialog-title>Confirm override?</h1>
<mat-dialog-content>
  <p class="secondary-text">
    Manually overriding will change the current status of statement generation. The new status will return to it's original status after set expired date and time
  </p>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">CANCEL</button>
  <button mat-button [mat-dialog-close]="true" color="primary">CONFIRM</button>
</div>