import { NgModule } from '@angular/core';
import { CommonModule } from 'common';

import { UserRoleRoutingModule } from './user-role-routing.module';
import { UserRoleDetailsComponent } from './user-role-details/user-role-details.component';
import { UserRoleListComponent } from './user-role-list/user-role-list.component';
import { UserRoleListFilterDialogComponent } from './user-role-list-filter-dialog/user-role-list-filter-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { UserRoleAssigneesListComponent } from './user-role-assignees-list/user-role-assignees-list.component';
import { UserRoleUpdateDialogComponent } from './user-role-update-dialog/user-role-update-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        UserRoleRoutingModule
    ],
    declarations: [
        UserRoleDetailsComponent,
        UserRoleListComponent,
        UserRoleListFilterDialogComponent,
        UserRoleAssigneesListComponent,
        UserRoleUpdateDialogComponent
    ]
})
export class UserRoleModule { }
