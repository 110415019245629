<div class="content-slider">
  <div class="content-slider-content-nav">
    <div class="content-slider-content-nav-container">
      <button mat-icon-button (click)="stepSelectedNext(-1)" [ngClass]="{'content-slider-content-nav-left': true, 'content-slider-content-nav-left--visible': navVisible && stepSelectedIndex > 0}">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div class="content-slider-content-container" #contentSliderContentContainer>
      <div class="content-slider-content" #contentSliderContent>
        <ng-content></ng-content>
      </div>
    </div>
    <div class="content-slider-content-nav-container">
      <button mat-icon-button (click)="stepSelectedNext(1)" [ngClass]="{'content-slider-content-nav-right': true, 'content-slider-content-nav-right--visible': navVisible && stepSelectedIndex < steps.length - 1}">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>