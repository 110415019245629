import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { Log, LogQueryResult, LogGroupQueryResult, LogFilterOptions, LogDetailsData, LogQueryParams } from "./log.model";
import { LogService } from "./log.service";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Injectable({
    providedIn: 'root'
})
export class LogListResolver implements Resolve<LogQueryResult> {
    constructor(private service: LogService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<LogQueryResult> | Promise<LogQueryResult> | LogQueryResult {
        const params = new LogQueryParams(this.queryParamsService.init(route.queryParams));
        return params.groupBy ? of(null) : this.service.query(params);
    }
}

@Injectable({
    providedIn: 'root'
})
export class LogGropedResolver implements Resolve<LogGroupQueryResult> {
    constructor(private service: LogService, private queryParamsService: QueryParamsService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<LogGroupQueryResult> | Promise<LogGroupQueryResult> | LogGroupQueryResult {
        const params = new LogQueryParams(this.queryParamsService.init(route.queryParams));
        return params.groupBy ? this.service.grouped(params) : of(null);
    }
}

@Injectable({
    providedIn: 'root'
})
export class LogResolver implements Resolve<LogDetailsData> {
    constructor(private service: LogService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<LogDetailsData> | Promise<LogDetailsData> | LogDetailsData {
        return this.service.get(route.params.id);
    }
}

@Injectable({
    providedIn: 'root'
})
export class LogFilterOptionsResolver implements Resolve<LogFilterOptions> {
    constructor(private service: LogService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<LogFilterOptions> | Promise<LogFilterOptions> | LogFilterOptions {
        return this.service.getFilterOptions();
    }
}
