<mat-card class="ifc-card" [ngClass.gt-md]="'card lg'" [ngClass.md]="'card md'" [ngClass.xs]="'card xs'">

  <div fxLayout="row" fxLayoutAlign="space-between start" class="layout padding-md card-header">
    <div class="card-title"> Other info </div>
    <button *ngIf="edit" (click)="detailsLink()" mat-icon-button aria-label="Edit">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <mat-card-content class="layout padding-md">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="18px">

      <mat-list>

        <mat-list-item>
          <span class="secondary-text" mat-line> Broker ID </span>
          <p class="item-content">{{broker?.brokerId}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Type </span>
          <p class="item-content">{{formatType(broker?.type)}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Tier </span>
          <p class="item-content">{{formatTier(broker?.tier)}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Established date </span>
          <p class="item-content">{{(broker?.establishedDate | date) || '-'}}</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
          <span class="secondary-text" mat-line> Salesforce account ID </span>
          <p class="item-content">
            <a *ngIf="broker?.uniqueId" href="{{salesforceLink(broker?.uniqueId)}}" target="_blank">{{broker?.uniqueId}}</a>
          </p>
        </mat-list-item>

      </mat-list>

    </div>

  </mat-card-content>

</mat-card>