import { Component } from '@angular/core';
import { routerTransition } from '../../shared/animations/router.animations';
import { AuditTrailComponent } from '../../shared/audit-trail/audit-trail-list/audit-trail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditTrailFilterService } from '../../shared/audit-trail/audit-trail-filter.services';
import { AuditQueryParams, AuditTrailData } from '../../shared/audit-trail/audit-trail.model';
import { AuditTrailService } from '../../shared/audit-trail/audit-trail.service';
import { AppBarTitleService, parseNumber } from 'common';
import { MatDialog } from '@angular/material/dialog';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-bank-account-audit-trail',
  templateUrl: '../../shared/audit-trail/audit-trail-list/audit-trail.component.html',
  styleUrls: ['../../shared/audit-trail/audit-trail-list/audit-trail.component.scss'],
  animations: routerTransition
})
export class BankAccountAuditTrailComponent extends AuditTrailComponent {

  innerTabbed = true;

  constructor(
    public route: ActivatedRoute,
    public _auditTrailFilterService: AuditTrailFilterService,
    private appBarTitleService: AppBarTitleService,
    private auditService: AuditTrailService,
    private router: Router,
    public dialog: MatDialog,
    public queryParamsService: QueryParamsService) {
    super(dialog, route, _auditTrailFilterService, queryParamsService);
    this.appBarTitleService.title = 'Audit trail';
  }

  download(): void {
    let bankAccountId: number;
    this.route.pathFromRoot[this.route.pathFromRoot.length - 2].params
      .subscribe(_ => bankAccountId = _.bankAccountId);
    if (!bankAccountId)
      return;
    const params = new AuditQueryParams(this.queryParamsService.init(this.queryParams));
    params.bankAccount = parseNumber(bankAccountId);
    this.auditService.auditDownload(params);
  }

  accountAuditDetailsLink(audit: AuditTrailData, newWindow: boolean = false) {
    const path = `/audit/${audit.id}`;

    if (newWindow)
      window.open(path);
    else
      this.router.navigate([path]);
  }

}
