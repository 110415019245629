import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SharedModule } from '../../shared/shared.module';
import { UserModule } from '../user/user.module';
import { FeatureFlagListComponent } from './feature-flag-list/feature-flag-list.component';
import { FeatureFlagRoutingModule } from './feature-flag-routing.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    UserModule,
    FeatureFlagRoutingModule
  ],
  exports: [
    FeatureFlagListComponent
  ],
  declarations: [
    FeatureFlagListComponent
  ]
})
export class FeatureFlagModule { }
