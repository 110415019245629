<mat-dialog-content class="mat-typography">
  <h1>Assign business advisor</h1>

  <form [formGroup]="form">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifb-business-advisor-input [fc]="form.get('fullName')"></ifb-business-advisor-input>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="">Cancel</button>
  <button mat-button [mat-dialog-close]="form.get('fullName').value" color="primary">Ok</button>
</div>