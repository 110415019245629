import { Component } from "@angular/core";
import { AutocompleteComponent } from "common";
import { EMPTY, Observable } from "rxjs";
import { PromotionHintData } from "../../../../models/promotion.model";
import { PromotionService } from "../../../../promotion.service";

@Component({
  selector: "ifb-promotion-name-input",
  templateUrl:
    "../../../../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html",
  styleUrls: [
    "../../../../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css",
  ],
})
export class PromotionNameInputComponent extends AutocompleteComponent {
  // TODO[promotions] refactor? composition over inheritance..

  label = "Name";
  optionLabelKey = "name";

  constructor(private promotionService: PromotionService) {
    super();
  }

  optionFilter: (input: string | PromotionHintData) => Observable<any[]> = (
    input
  ) =>
    input
      ? this.promotionService.getHints({
          name: !!input
            ? typeof input !== "string"
              ? input.name
              : input
            : null,
          sortField: "name",
        })
      : EMPTY;
}
