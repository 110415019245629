<div id="main" fxLayout="column" fxLayoutAlign="start center">

  <div id="title" class="upload-title">
    Upload PDF files below
  </div>

  <uppy-dashboard [uppy]="uppy" [props]="uppyDashboardOptions"></uppy-dashboard>

  <div id="footer">

    <mat-form-field appearance="outline">
      <mat-label>Category</mat-label>
      <mat-select [(ngModel)]="selectedCategory" (selectionChange)="onCategoryChange()">
        <mat-option *ngFor="let category of categories" [value]="category.value">{{category.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-button (click)="cancel()">CANCEL</button>
      <button mat-flat-button color="primary" [disabled]="!valid" (click)="submit()">SUBMIT</button>
    </div>

  </div>

</div>
