import { NgModule } from '@angular/core';
import { ClientModule } from './client/client.module';

@NgModule({
  imports: [
    ClientModule
  ],
  exports: [
    ClientModule
  ],
  declarations: []
})
export class AuthModule { }
