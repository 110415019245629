<div [@routerTransition]="'fly'">

  <div class="filter-toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="table-top">
      <ifc-filters-active-list class="layout margin-sm" [filters]="activeFilters"
        (filterRemoved)="activeFilterRemoved($event)"></ifc-filters-active-list>
      <div class="layout padding-right-md">
        <button mat-icon-button (click)="filterDialog()" class="secondary-text" matTooltip="Filter">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <ifc-app-scroll-container [tabbed]="true">
    <div class="table-container">
      <div class="message-box info" *ngIf="!data.roleQueryResult.totalCount" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <mat-icon class="icon">info</mat-icon>
        <span>No data found</span>
      </div>
      <table *ngIf="data.roleQueryResult.totalCount" mat-table matSort [dataSource]="data.roleQueryResult.values" (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Role </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/role/{{row.id}}" class="table-cell"> {{row.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="client"> Client </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/role/{{row.id}}" class="table-cell"> {{row.client}}
          </td>
        </ng-container>

        <ng-container matColumnDef="externalId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="externalId"> External ID </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/role/{{row.id}}" class="table-cell">
            {{row.externalId}}
          </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="active"> Status </th>
          <td mat-cell *matCellDef="let row" routerLink="/admin/role/{{row.id}}" class="table-cell"> {{row.active ?
            'Active' : 'Inactive'}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="actions" style="width: 50px;"> </th>
          <td mat-cell *matCellDef="let row" class="table-cell" routerLink="/admin/role/{{row.id}}">
            <button mat-icon-button class="action-edit">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
      </table>
    </div>
  </ifc-app-scroll-container>

  <div [hidden]="data.roleQueryResult.totalCount <= 10">
    <mat-paginator #paginator showFirstLastButtons [length]="data.roleQueryResult.totalCount"
      [pageSizeOptions]="[10, 20, 50]" (page)="setPage($event)"></mat-paginator>
  </div>

</div>
