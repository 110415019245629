import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { DialogConfirm, ObservableHelper, FilterParameter } from 'common';
import { BankingCustomerQueryResult, BankingCustomerFilters, BankingCustomerQueryParams } from '../banking.model';
import { BankingService } from '../banking.service';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { readOnly, readWrite } from '../../user/user-permission/user-permission.data';
import { BankingCustomerListFilterService } from '../banking-customer-list-filter-dialog/banking-customer-list-filter.service';
import { BankingCustomerListFilterDialogComponent } from '../banking-customer-list-filter-dialog/banking-customer-list-filter-dialog.component';
import { QueryParamsService } from 'projects/common/src/lib/query/query-params.service';

@Component({
  selector: 'ifb-banking-customer-list',
  templateUrl: './banking-customer-list.component.html',
  styleUrls: ['./banking-customer-list.component.css']
})
export class BankingCustomerListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  private subs: Subscription[] = [];

  filters: BankingCustomerFilters;
  activeFilters: FilterParameter[] = [];
  queryParams: BankingCustomerQueryParams;
  data: BankingCustomerListComponentData;
  canDeleteBankCustomer = false;
  canReadBankingAccount = false;
  provider: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private bankingCustomerListFilterService: BankingCustomerListFilterService,
    private bankingService: BankingService,
    private queryParamsService: QueryParamsService
  ) { this._unsubscribeAll = new Subject(); }

  ngOnInit() {
    this.subs = [
      this.route.data.subscribe(this.dataInit.bind(this)),
      this.route.queryParams.subscribe(it => {
        this.queryParams = this.queryParamsService.init(it);
        this.activeFilters = this.bankingCustomerListFilterService.addFilterParameters(this.queryParams);
        this.filters = new BankingCustomerFilters(this.queryParams);
      }),
      this.userPermissionService.granted([readWrite('servicing-delete-synced-customer')])
        .subscribe(res => this.canDeleteBankCustomer = res),
      this.userPermissionService.granted([readOnly('servicing-synced-account')])
        .subscribe(res => this.canReadBankingAccount = res)
    ];
    this.paginator.pageSize = this.queryParams.limit;
    this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;
  }

  deleteSub: Subscription;

  delete(customerId: string) {
    if (!customerId)
      return;

    DialogConfirm.show(this.dialog, `Delete customer?`).subscribe(result => {
      if (result) {
        this.deleteSub = this.bankingService.deleteCustomer(this.provider, customerId).subscribe(() => {
          this.fetchData();
        });
      }
    });
  }

  fetchData() {
    this.bankingService.getCustomers(this.provider, this.queryParams)
      .subscribe(res => this.data.bankCustomerQueryResult = res);
  }

  activeFilterRemoved(activefilters: FilterParameter[]): void {
    if (!activefilters.length) return;
    activefilters.forEach(item => {
      this.filters.remove(item.id);
      this.bankingCustomerListFilterService.filter(this.provider, this.filters);
    });
  }

  ngOnDestroy() {
    ObservableHelper.unsubscribeAll(this.subs);
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  filterDialog() {
    BankingCustomerListFilterDialogComponent.show(this.dialog, this.queryParams)
      .subscribe(result => {
        if (result) {
          this.filters = result;
          this.paginator.firstPage();
          this.bankingCustomerListFilterService.filter(this.provider, result);
        }
      });
  }

  bankCustomerAccountListLink(bankingCustomerId: string) {
    if (!bankingCustomerId || bankingCustomerId === undefined || !this.canReadBankingAccount)
      return;
    this.router.navigate([`banking/${this.provider}/customers/${bankingCustomerId}/accounts`]);
  }

  private dataInit(data: BankingCustomerListComponentData) {
    this.data = data;
    this.provider = this.route.snapshot.paramMap.get("provider");
  }

  setPage(event: PageEvent) {
    this.bankingCustomerListFilterService.filter(this.provider, this.filters, this.paginator);
  }

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  tableColumns: string[] = [
    'createdDate',
    'username',
    'id',
    'name',
    'bank',
    'delete'
  ];
}

export interface BankingCustomerListComponentData {
  bankCustomerQueryResult: BankingCustomerQueryResult;
}
