import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { EnumHelper } from 'common';
import { FailureReasonUsage } from '../failure-reason.model';

@Component({
  selector: 'ifb-failure-reason-update-dialog',
  templateUrl: './failure-reason-update-dialog.component.html',
  styleUrls: ['./failure-reason-update-dialog.component.css']
})
export class FailureReasonUpdateDialogComponent {
  actionGerund: string;
  usages: string[] = [];

  constructor(public dialogRef: MatDialogRef<FailureReasonUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.actionGerund = this.data.action.substring(0, this.data.action.length - 1) + "ing";
    this.data.form.value.usage.forEach(element => {
      this.usages.push(EnumHelper.getNameFromValue(FailureReasonUsage, element));
    });
  }

  static show(dialog: MatDialog, form: UntypedFormGroup, action: string): Observable<any> {
    return dialog.open(FailureReasonUpdateDialogComponent, {
      width: '510px',
      data: {
        form: form,
        action: action
      },
      closeOnNavigation: true,
    }).afterClosed();
  }
}

