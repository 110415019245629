import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appPageComponentDataMaster, appPageComponentDataDetail } from '../app.data';
import { BOAuthGuard } from '../guards/oauth.guard';
import {
    AppPageComponent,
    AppSidenavComponent,
    AppBarComponent,
    AppBarExpandedTabsComponent,
    AppBarTitleComponent,
    AppBarActionsComponent,
    AppBarExpandedComponent
} from 'common';
import { AppPageResolver, AppSidenavResolver } from '../app.resolver';
import { UserMenuComponent } from '../user/user-menu/user-menu.component';
import { LogAppBarTitleComponent } from './log-app-bar-title/log-app-bar-title.component';
import { LogListResolver, LogGropedResolver, LogFilterOptionsResolver, LogResolver } from './log.resolver';
import { LogShellComponent } from './log-shell/log-shell.component';
import { LogDetailsComponent } from './log-details/log-details.component';

const appPageDataMaster = appPageComponentDataMaster({
    appBarTitle: 'Logs',
    sideNavItem: 'log'
});

const appPageDataDetail = appPageComponentDataDetail();

const routes: Routes = [
    {
        path: '',
        canActivate: [BOAuthGuard],
        children: [
            {
                path: 'logs',
                component: AppPageComponent,
                data: appPageDataMaster,
                resolve: {
                    appPage: AppPageResolver,
                    appSidenav: AppSidenavResolver,
                },
                children: [
                    {
                        path: '',
                        outlet: 'sidenav-content',
                        component: AppSidenavComponent
                    },
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: LogAppBarTitleComponent
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: UserMenuComponent,
                            },
                            {
                                path: '',
                                outlet: 'expanded',
                                component: AppBarExpandedComponent,
                                children: [
                                    {
                                        path: '',
                                        outlet: 'tabs',
                                        component: AppBarExpandedTabsComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '',
                        component: LogShellComponent,
                        runGuardsAndResolvers: "paramsOrQueryParamsChange",
                        resolve: {
                            logQueryResult: LogListResolver,
                            logGroupQueryResult: LogGropedResolver,
                            logFilterOptions: LogFilterOptionsResolver
                        }
                    }
                ]
            },
            {
                path: 'logs/:id',
                component: AppPageComponent,
                canActivate: [BOAuthGuard],
                data: appPageDataDetail,
                children: [
                    {
                        path: '',
                        component: AppBarComponent,
                        outlet: 'app-bar',
                        children: [
                            {
                                path: '',
                                outlet: 'title',
                                component: AppBarTitleComponent,
                            },
                            {
                                path: '',
                                outlet: 'right',
                                component: AppBarActionsComponent,
                            }
                        ]
                    },
                    {
                        path: '',
                        component: LogDetailsComponent,
                        resolve: {
                            details: LogResolver
                        }
                    }
                ]
            }
        ]

    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LogRoutingModule { }
