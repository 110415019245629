import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService, AppBarActionsService, ConfigService, OAuthService, AppPageService, parseNumber } from 'common';
import { takeUntil } from 'rxjs/operators';
import { UserPermissionService } from '../../user/user-permission/user-permission.service';
import { DocumentsUploadBaseComponent } from './documents-upload-base.component';

@Component({
  selector: 'ifb-account-documents-upload',
  templateUrl: './documents-upload-base.component.html',
  styleUrls: ['./documents-upload-base.component.scss']
})

export class AccountDocumentsUploadComponent extends DocumentsUploadBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private loanId: number;
  private loanUniqueId: string;

  constructor(
    protected route: ActivatedRoute,
    protected appBarTitleService: AppBarTitleService,
    protected appBarActionsService: AppBarActionsService,
    protected appPageService: AppPageService,
    protected userPermissionService: UserPermissionService,
    protected configService: ConfigService,
    protected authService: OAuthService
  ) {
    super(route, appBarTitleService, appBarActionsService, appPageService, userPermissionService, configService, authService);
  }

   ngOnInit() {
    this.route.params.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(params => {
        this.loanId = parseNumber(params.id);
        this.loanUniqueId = params.uniqueId;
        this.setupUppy();
      });

    this.ngOnInitBase('servicing-account-documents');
  }

  protected override setupUppy(): void {
    this.initUppy(this.prepareUppyEndpoint());
  }

  protected override updateUppyEndpoint(): void {
    this.updateUppyEndpointBase(this.prepareUppyEndpoint());
  }

  private prepareUppyEndpoint(): string {
    const endpoint = `${this.configService.environment.loans.url}/api/files/`;

    if (this.loanUniqueId) {
      return (endpoint + `sf/${this.loanUniqueId}/${this.selectedCategory}`);
    }
    return (endpoint + `${this.loanId}/${this.selectedCategory}`);
  }
}
