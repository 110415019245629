import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "projects/backoffice/src/app.settings";
import { ResourceService } from "common";
import { CompetitorListQueryParams, CompetitorListQueryResults, CompetitorsHintsQueryParams } from "./models/competitors-query.model";
import { Competitor, CompetitorBase, CompetitorHintData } from "./models/competitor.model";

@Injectable({
  providedIn: "root",
})
export class CompetitorService {

  private readonly baseUrl = `${this.settings.competitors.url}/api/competitors`;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private resourceService: ResourceService
  ) {}

  getCompetitorList(
    params: CompetitorListQueryParams
  ): Observable<CompetitorListQueryResults> {
    return this.http.get<CompetitorListQueryResults>(`${this.baseUrl}`, {
      params: <any>params,
    });
  }

  getCsv(params: CompetitorListQueryParams) {
    this.resourceService.download(
      `${this.baseUrl}/csv`,
      "Competitors.csv",
      params
    );
  }

  getHints(
    params: CompetitorsHintsQueryParams
  ): Observable<CompetitorHintData[]> {
    return this.http.get<CompetitorHintData[]>(`${this.baseUrl}/hints`, {
      params: <any>params,
    });
  }

  getCompetitor(id: number): Observable<Competitor> {
    return this.http.get<Competitor>(`${this.baseUrl}/${id}`);
  }

  updateCompetitor(id: number, data: CompetitorBase): Observable<Competitor> {
    return this.http.put<Competitor>(`${this.baseUrl}/${id}`, data);
  }

  addCompetitor(data: CompetitorBase): Observable<Competitor> {
    return this.http.post<Competitor>(`${this.baseUrl}`, data);
  }

  deleteCompetitor(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
