import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { Sort, MatSort } from "@angular/material/sort";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from 'lodash';
import { EntityData, EntityExData, EntityQueryParams, EntityQueryResult, EntityStatus, EntityType, LinkingStatus, EnumHelper, FilterParameter } from "common";
import { routerTransition } from "../../shared/animations/router.animations";
import { UserPermissionService } from "../../user/user-permission/user-permission.service";
import { readOnly, readWrite } from "../../user/user-permission/user-permission.data";
import { EntityFilters, EntityTypeLabel, EntityStatusLabel } from "../entity.model";
import { EntityFilterService } from "../entity-filter.service";
import { EntityListFilterDialogComponent } from "../entity-list-filter-dialog/entity-list-filter-dialog.component";
import { EntityService } from "../entity.service";
import { BrokerData } from "../../broker/broker.model";
import { AdvisorData } from "../../advisor/advisor.model";
import { BankLinkStatusLabels } from "../../bank/bank.model";
import { AppSettings } from "../../../app.settings";
import { QueryParamsService } from "projects/common/src/lib/query/query-params.service";

@Component({
    selector: 'ifb-entity-list',
    templateUrl: './entity-list.component.html',
    styleUrls: ['./entity-list.component.scss'],
    animations: routerTransition,
})
export class EntityListComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    deleteSub: Subscription;
    filters: EntityFilters;
    activeFilters: FilterParameter[] = [];
    queryParams: EntityQueryParams;
    data: EntityListComponentData;
    readWriteEntity: boolean;
    canReadBankAccountVerificationStatus: boolean;

    brokerId: number;
    advisorId: number;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    constructor(
        private _route: ActivatedRoute,
        private _userPermissionService: UserPermissionService,
        private _dialog: MatDialog,
        private _entityFilterService: EntityFilterService,
        private router: Router,
        private settings: AppSettings,
        private entityService: EntityService,
        private queryParamsService: QueryParamsService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this._route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((it: EntityListComponentData) => this.data = it);

        this._route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
            this.queryParams = new EntityQueryParams(this.queryParamsService.init(params));
            this.activeFilters = this._entityFilterService.addFilterParameters(this.queryParams);
        });

        this._userPermissionService.granted([readWrite('servicing-entities')]).subscribe(res => this.readWriteEntity = res);
        this._userPermissionService.granted([readOnly('servicing-bank-accounts-verification')])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => this.canReadBankAccountVerificationStatus = res);

        this.filters = new EntityFilters(this.queryParams);
        this.paginator.pageSize = this.queryParams.limit;
        this.paginator.pageIndex = this.queryParams.skip / this.queryParams.limit;

        this.brokerId = this.data.broker ? this.data.broker.id : null;
        this.advisorId = this.data.advisor ? this.data.advisor.id : null;
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    download(params: EntityQueryParams) {
        const queryParams = Object.assign({}, params);

        if (queryParams.createdOnStart) {
            queryParams.createdOnStart = new Date(queryParams.createdOnStart);
            queryParams.createdOnStart.setHours(0, 0, 0, 0);
        }

        if (queryParams.createdOnEnd) {
            queryParams.createdOnEnd = new Date(queryParams.createdOnEnd);
            queryParams.createdOnEnd.setHours(23, 59, 59, 999);
        }

        if (queryParams.updatedOnStart) {
            queryParams.updatedOnStart = new Date(queryParams.updatedOnStart);
            queryParams.updatedOnStart.setHours(0, 0, 0, 0);
        }

        if (queryParams.updatedOnEnd) {
            queryParams.updatedOnEnd = new Date(queryParams.updatedOnEnd);
            queryParams.updatedOnEnd.setHours(23, 59, 59, 999);
        }

        queryParams.brokerId = this.brokerId;
        queryParams.advisorId = this.advisorId;
        this.entityService.download(queryParams);
    }

    activeFilterRemoved(activefilters: FilterParameter[]): void {
        if (!activefilters.length) return;

        activefilters.forEach(item => {
            this.filters.remove(item.id);
            this._entityFilterService.filter(this.filters, null, null, this.brokerId, this.advisorId);
        });
    }

    formatType(type: EntityType): string {
        return EntityTypeLabel.get(type);
    }

    formatStatus(status: EntityStatus): string {
        return EntityStatusLabel.get(status);
    }

    salesforceLink(salesforceId: string) {
        return this.settings.salesforce.url + salesforceId;
    }

    profileLink(entity: EntityExData) {
        return `/company/${entity.id}/profile`;
    }

    companyDetailsLink(entity: EntityExData, newWindow: boolean) {
        const path = `/company/${entity.id}/profile`;
        if (newWindow)
            window.open(path);
        else
            this.router.navigate([path]);
    }

    filterDialog() {
        EntityListFilterDialogComponent
            .show(this._dialog, this.queryParams)
            .subscribe(result => {
                if (result) {
                    this.filters = result;
                    this.paginator.firstPage();
                    this._entityFilterService.filter(result, null, null, this.brokerId, this.advisorId);
                }
            });
    }

    getBankLinkingStatusAvatarIcon(entity: EntityData) {
        if (entity && entity.bankLinkStatus !== null)
            return BankLinkStatusLabels[entity.bankLinkStatus].icon;
        else return BankLinkStatusLabels[LinkingStatus.Unlinked].icon;
    }

    getBankLinkingStatusAvatarClass(entity: EntityData) {
        if (entity && entity.bankLinkStatus !== null)
            return 'avatar medium ' + BankLinkStatusLabels[entity.bankLinkStatus].color;
        else return 'avatar medium ' + BankLinkStatusLabels[LinkingStatus.Unlinked].color;
    }

    getBankLinkingStatusTooltip(entity: EntityData) {
        return !entity || !entity.bankLinkStatus
            ? _.startCase(EnumHelper.getNameFromValue(LinkingStatus, LinkingStatus.Unlinked))
            : _.startCase(EnumHelper.getNameFromValue(LinkingStatus, +entity.bankLinkStatus));
    }

    getBankVerificationAvatarIcon(entity: EntityData) {
        return entity.isVerified ? 'done_all' : 'error_outline';
    }

    getBankVerificationAvatarClass(entity: EntityData) {
        return entity.isVerified ? 'avatar medium mat-indigo-bg' : 'avatar medium mat-red-bg';
    }

    getBankVerificationTooltip(entity: EntityData) {
        return entity.isVerified ? 'Bank account verified' : 'Bank account unverified';
    }

    sortData(sort: Sort) {
        this._entityFilterService.filter(this.filters, this.paginator, this.sort, this.brokerId, this.advisorId);
    }

    setPage(event: PageEvent) {
        this._entityFilterService.filter(this.filters, this.paginator, this.sort, this.brokerId, this.advisorId);
    }

    getDisplayedColumns(): string[] {
        return this.tableColumns
            .filter(cd => this.advisorId ? cd.showInnerTab : true)
            .map(cd => cd.def);
    }

    tableColumns = [
        { def: 'bankLinkStatus', showInnerTab: true },
        { def: 'verificationStatus', showInnerTab: true },
        { def: 'createdOn', showInnerTab: true },
        { def: 'name', showInnerTab: true },
        { def: 'number', showInnerTab: true },
        { def: 'status', showInnerTab: true },
        { def: 'phone', showInnerTab: true },
        { def: 'email', showInnerTab: true },
        { def: 'totalPrincipalBalance', showInnerTab: true },
        { def: 'msaOfferAmount', showInnerTab: true },
        { def: 'msaAvailableBalance', showInnerTab: true },
        { def: 'advisorName', showInnerTab: false },
        { def: 'createdBy', showInnerTab: true },
        { def: 'updatedBy', showInnerTab: true },
        { def: 'updatedOn', showInnerTab: true },
        { def: 'uniqueId', showInnerTab: true },
        { def: 'actions', showInnerTab: true }
    ];
}

export interface EntityListComponentData {
    broker?: BrokerData;
    advisor?: AdvisorData;
    entityQueryResult: EntityQueryResult;
}
